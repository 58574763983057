import React from 'react';
import { ChangeEvent } from 'react';

import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { Skeleton, TextField } from '@mui/material';

import { IMaskInput } from 'react-imask';

type TelephoneFieldAdapterPropTypes = {
  disabled?: boolean,
  name: string,
  label: string,
  loading?: boolean,
  onChange?: (e: ChangeEvent) => void,
  validate?: FieldValidator<any>
};

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={
          [{
            mask: '(00) 0000-0000'
          }, {
            mask: '(00) 00000-0000'
          }]
        }
        inputRef={ref as any}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } })
        }}
        overwrite
      />
    );
  },
);

const TelephoneMaskedInput = ({ disabled, input, label, meta, name, customOnChange }: any) => {

  const { onChange, ...props } = input

  return (
    <TextField
      {...props}
      disabled={disabled}
      label={label}
      name={name}
      error={meta.touched && meta.error ? true : false}
      FormHelperTextProps={{ className: 'helper-text' }}
      helperText={meta.touched ? meta.error : ''}
      fullWidth
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        input.onChange(e.target.value);
        if (customOnChange)
          customOnChange(e);
      }}
      InputProps={{
        inputComponent: TextMaskCustom
      }}
    />)

};

function TelephoneFieldAdapter({ disabled = false, name, label, loading = false, onChange, validate }: TelephoneFieldAdapterPropTypes) {
  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          name={name}
          label={label}
          validate={validate}
          onChange={onChange}
          render={({ input, meta }) => (
            <TelephoneMaskedInput
              disabled={disabled}
              input={input}
              label={label}
              meta={meta}
              name={name}
              customOnChange={onChange}
            />
          )}
        />
      )}
    </div>
  )
}

export { TelephoneFieldAdapter };