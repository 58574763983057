import useViewModel from './viewModel'
import {Box, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import {PlanGeneralView} from "./pages/General";
import {PlanCheckoutView} from "./pages/Checkout";

function PlanView() {
  const {
    plan,
    plansLoading,
    plansLoadingError,
    tabSelected,
    formattedPlan,
    onTabChange
  } = useViewModel()

  return (
      <div className='plan-view'>

        <div className='view-header'>
          <div className='view-title'>
              {!plansLoading && <Typography variant='h3' fontWeight={700}>
                  {formattedPlan?.name}
              </Typography>}
              {plansLoading && <Skeleton/>}
          </div>
        </div>

        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
              value={tabSelected}
              onChange={(event, value) => {onTabChange(value)}}
          >

            <Tab
                label='Visão Geral'
                id='general'
                aria-controls='tabpanel-general'
            />
            {plan && plan.hash && <Tab
                label='Checkout'
                id='checkout'
                aria-controls='tabpanel-plans'
            />}

          </Tabs>
        </Box>

        <div
            role='tabpanel'
            hidden={tabSelected !== 0}
            id={'tabpanel-general'}
            aria-labelledby={'general'}
        >

            <PlanGeneralView
                loading={plansLoading}
                loadingError={plansLoadingError}
                planData={plan}
            />

        </div>

        <div
            role='tabpanel'
            hidden={tabSelected !== 1}
            id={'tabpanel-checkout'}
            aria-labelledby={'checkout'}
        >
          <PlanCheckoutView
            planData={plan}
          />
        </div>
      </div>
  );
}

export { PlanView }