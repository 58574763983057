import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'

import { notAuthenticatedRedirect } from '../../utils/auth';

import {
  PlanRevenueType,
  PlanRevenueDisplayType,
  getPlanRevenue,
  formatPlanRevenueToDisplay,
  getReceiptRevenue,
  formatReceiptRevenueToDisplay,
  ReceiptRevenueType,
  ReceiptRevenueDisplayType,
  getActiveCustomers,
  ActiveCustomerType,
  ActiveCustomerDisplayType,
  formatActiveCustomersToDisplay,
  getChurnedCustomers,
  ChurnedCustomerType,
  ChurnedCustomerDisplayType,
  formatChurnedCustomersToDisplay,
  CashierFlowType,
  CashierFlowDisplayType,
  formatCashierFlowToDisplay,
  getCashierFlow,
} from './services';
import { ApiError } from 'utils/CustomError';
import { FilterType } from 'pages/AccountsReceivables/services';

export default function MetricsViewModel() {

  const [planRevenue, setPlanRevenue] = useState<Array<PlanRevenueType>>();
  const [formattedPlanRevenue, setFormattedPlanRevenue] = useState<PlanRevenueDisplayType | null>();
  const [formattedSelectedPlanRevenue, setFormattedSelectedPlanRevenue] = useState<PlanRevenueDisplayType | null>();
  const [selectedPlans, setSelectedPlans] = useState<Array<string>>([])

  const [receiptRevenue, setReceiptRevenue] = useState<Array<ReceiptRevenueType>>();
  const [formattedReceiptRevenue, setFormattedReceiptRevenue] = useState<ReceiptRevenueDisplayType | null>();

  const [activeCustomer, setActiveCustomer] = useState<Array<ActiveCustomerType>>();
  const [formattedActiveCustomer, setFormattedActiveCustomer] = useState<ActiveCustomerDisplayType | null>();

  const [churnedCustomer, setChurnedCustomer] = useState<Array<ChurnedCustomerType>>();
  const [formattedChurnedCustomer, setFormattedChurnedCustomer] = useState<ChurnedCustomerDisplayType | null>();

  const [cashierFlow, setCashierFlow] = useState<Array<CashierFlowType>>();
  const [formattedCashierFlow, setFormattedCashierFlow] = useState<CashierFlowDisplayType | null>();

  // loading states
  const [planRevenueLoading, setPlanRevenueLoading] = useState(true);
  const [planRevenueLoadingError, setPlanRevenueLoadingError] = useState(false);
  const [receiptRevenueLoading, setReceiptRevenueLoading] = useState(true);
  const [receiptRevenueLoadingError, setReceiptRevenueLoadingError] = useState(false);
  const [activeCustomerLoading, setActiveCustomerLoading] = useState(true);
  const [activeCustomerLoadingError, setActiveCustomerLoadingError] = useState(false);
  const [churnedCustomerLoading, setChurnedCustomerLoading] = useState(true);
  const [churnedCustomerLoadingError, setChurnedCustomerLoadingError] = useState(false);
  const [cashierFlowLoading, setCashierFlowLoading] = useState(true);
  const [cashierFlowLoadingError, setCashierFlowLoadingError] = useState(false);

  // filter
  const [effectiveDateFilter, setEffectiveDateFilter] = useState<FilterType[]>([{
    field: 'period',
    comparator: 'gte',
    value: dayjs().subtract(90, 'days').date(1).format('YYYY-MM-DD')
  }, {
    field: 'period',
    comparator: 'lte',
    value: dayjs().endOf('month').format('YYYY-MM-DD')
    }]);
  
  const [formattedEffectiveDateFilter, setFormattedEffectiveDateFilter] = useState<Array<string>>()

  const minDateFilter = dayjs().subtract(180, 'days').date(1).format('YYYY-MM-DD')

  const onGetPlanRevenueHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setPlanRevenueLoadingError(true)

      setPlanRevenue([]);
      setFormattedPlanRevenue(null)
    } else {
      setPlanRevenueLoadingError(true)

      setPlanRevenue([]);
      setFormattedPlanRevenue(null)
    }
  }

  const onGetReceiptRevenueHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setReceiptRevenueLoadingError(true)

      setReceiptRevenue([]);
      setFormattedReceiptRevenue(null)
    } else {
      setReceiptRevenueLoadingError(true)

      setReceiptRevenue([]);
      setFormattedReceiptRevenue(null)
    }
  }

  const onGetActiveCustomerHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setActiveCustomerLoadingError(true)

      setActiveCustomer([]);
      setFormattedActiveCustomer(null)
    } else {
      setActiveCustomerLoadingError(true)

      setActiveCustomer([]);
      setFormattedActiveCustomer(null)
    }
  }

  const onGetChurnedCustomerHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setChurnedCustomerLoadingError(true)

      setChurnedCustomer([]);
      setFormattedChurnedCustomer(null)
    } else {
      setChurnedCustomerLoadingError(true)

      setChurnedCustomer([]);
      setFormattedChurnedCustomer(null)
    }
  }

  const onGetCashierFlowHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCashierFlowLoadingError(true)

      setCashierFlow([]);
      setFormattedCashierFlow(null)
    } else {
      setCashierFlowLoadingError(true)

      setCashierFlow([]);
      setFormattedCashierFlow(null)
    }
  }

  useEffect(() => {
    setFormattedEffectiveDateFilter([
      dayjs(effectiveDateFilter[0].value).locale('pt-br').format('MMMM/YYYY'),
      dayjs(effectiveDateFilter[1].value).locale('pt-br').format('MMMM/YYYY')
    ]) 
  }, [effectiveDateFilter])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {

      setPlanRevenueLoading(true);
      setPlanRevenueLoadingError(false)

      const response = await getPlanRevenue([effectiveDateFilter[0], effectiveDateFilter[1]], abortController.signal);

      if (response.errors) {
        onGetPlanRevenueHandleError(response.errors)
      } else {
        setPlanRevenueLoadingError(false)
        setPlanRevenue(response as Array<PlanRevenueType>);
        setFormattedPlanRevenue(formatPlanRevenueToDisplay(response))

        // Plans filter
        setSelectedPlans(response.slice(0, 5).map((plan:PlanRevenueType) => plan.name))
      }

      setPlanRevenueLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [effectiveDateFilter])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {

      setReceiptRevenueLoading(true);
      setReceiptRevenueLoadingError(false)

      const response = await getReceiptRevenue([effectiveDateFilter[0], effectiveDateFilter[1]], abortController.signal);

      if (response.errors) {
        onGetReceiptRevenueHandleError(response.errors)
      } else {
        setReceiptRevenueLoadingError(false)
        setReceiptRevenue(response as Array<ReceiptRevenueType>);
        setFormattedReceiptRevenue(formatReceiptRevenueToDisplay(response))
      }

      setReceiptRevenueLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [effectiveDateFilter])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {

      setActiveCustomerLoading(true);
      setActiveCustomerLoadingError(false)

      const response = await getActiveCustomers([effectiveDateFilter[0], effectiveDateFilter[1]], abortController.signal);

      if (response.errors) {
        onGetActiveCustomerHandleError(response.errors)
      } else {
        setActiveCustomerLoadingError(false)
        setActiveCustomer(response as Array<ActiveCustomerType>);
        setFormattedActiveCustomer(formatActiveCustomersToDisplay(response))
      }

      setActiveCustomerLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [effectiveDateFilter])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {

      setChurnedCustomerLoading(true);
      setChurnedCustomerLoadingError(false)

      const response = await getChurnedCustomers([effectiveDateFilter[0], effectiveDateFilter[1]], abortController.signal);

      if (response.errors) {
        onGetChurnedCustomerHandleError(response.errors)
      } else {
        setChurnedCustomerLoadingError(false)
        setChurnedCustomer(response as Array<ChurnedCustomerType>);
        setFormattedChurnedCustomer(formatChurnedCustomersToDisplay(response))
      }

      setChurnedCustomerLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [effectiveDateFilter])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {

      setCashierFlowLoading(true);
      setCashierFlowLoadingError(false)

      const response = await getCashierFlow([effectiveDateFilter[0], effectiveDateFilter[1]], abortController.signal);

      if (response.errors) {
        onGetCashierFlowHandleError(response.errors)
      } else {
        setCashierFlowLoadingError(false)
        setCashierFlow(response as Array<CashierFlowType>);
        setFormattedCashierFlow(formatCashierFlowToDisplay(response))
      }

      setCashierFlowLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [effectiveDateFilter])

  const applyEffectiveDateFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    const newDateFilter = [...effectiveDateFilter];

    newDateFilter[0].value = startDate ? startDate.date(1).format('YYYY-MM-DD') : '';
    newDateFilter[1].value = endDate ? endDate.endOf('month').format('YYYY-MM-DD') : '';

    setEffectiveDateFilter(newDateFilter);
  }

  const onSelectPlansFilter = (selected: Array<string>|string) => {
    const filter = typeof selected === 'string' ? selected.split(',') : selected
    setSelectedPlans(filter)
  }

  useEffect(() => {
    const filteredPlans = planRevenue?.filter((plan: PlanRevenueType) =>
      selectedPlans.indexOf(plan.name) > -1
    )

    const formattedSelectedPlanRevenue = formatPlanRevenueToDisplay(filteredPlans ?? [])
    setFormattedSelectedPlanRevenue(formattedSelectedPlanRevenue)
  }, [selectedPlans, planRevenue])

  return {
    formattedEffectiveDateFilter,
    planRevenue,
    formattedPlanRevenue,
    formattedSelectedPlanRevenue,
    selectedPlans,
    receiptRevenue,
    formattedReceiptRevenue,
    activeCustomer,
    formattedActiveCustomer,
    churnedCustomer,
    formattedChurnedCustomer,
    cashierFlow,
    formattedCashierFlow,
    planRevenueLoading,
    planRevenueLoadingError,
    receiptRevenueLoading,
    receiptRevenueLoadingError,
    activeCustomerLoading,
    activeCustomerLoadingError,
    churnedCustomerLoading,
    churnedCustomerLoadingError,
    cashierFlowLoading,
    cashierFlowLoadingError,
    effectiveDateFilter,
    minDateFilter,
    applyEffectiveDateFilter,
    onSelectPlansFilter
  };
}