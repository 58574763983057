import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Popover, Typography } from '@mui/material';
import React from 'react';

import useViewModel, { OptionTypes } from './viewModel';

type SearchCheckboxFilterPropTypes = {
  id: string,
  initialSelected?: Array<OptionTypes>,
  buttonLabel: string,
  disabled?: boolean,
  options: Array<OptionTypes>,
  search: string,
  onApplyFilter: (optionsSelected: Array<OptionTypes>) => void,
  onSearchChange: (search: string) => void
}

function SearchCheckboxFilter({
  id,
  initialSelected,
  buttonLabel,
  disabled = false,
  options,
  search,
  onApplyFilter,
  onSearchChange,
}: SearchCheckboxFilterPropTypes) {
  const { isOpen, optionsSelected, searchString, onChange, onClearFilter, onClick, onTextFieldChange } = useViewModel({ id, initialSelected, search });
  const buttonRef = React.useRef(null);

  return (
    <div className='filter'>
      <Button variant='outlined' onClick={onClick} size='small' disabled={disabled} color='secondary' ref={buttonRef}>
        <Typography fontWeight={700} variant='caption'>
          {buttonLabel}
        </Typography>
      </Button>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div
          className='filter-modal'
        >
          <FormGroup>

            <TextField
              label='Buscar'
              value={searchString}
              size='small'
              onChange={(e) => {
                onTextFieldChange(e.currentTarget.value);
                onSearchChange(e.currentTarget.value)
              }}
            />

            {
              optionsSelected.map(option => (
                <FormControlLabel
                  key={option.value}
                  label={option.label}
                  control={
                    <Checkbox
                      checked={true}
                      onChange={() => {
                        onChange(option);
                      }}
                    />
                  }
                />
              ))
            }

            {
              options.map(option => {
                if (!optionsSelected.includes(option))
                  return (
                    <FormControlLabel
                      key={option.value}
                      label={option.label}
                      control={
                        <Checkbox
                          checked={false}
                          onChange={() => {
                            onChange(option);
                          }}
                        />
                      }
                    />
                  )

                else return null;
              })
            }
          </FormGroup>

          <hr />

          <div className='buttons-container'>
            <Button
              variant='outlined'
              size='small'
              onClick={onClearFilter}
            >
              <Typography fontWeight={700} variant='caption'>
                Limpar Filtro
              </Typography>
            </Button>

            <Button
              variant='contained'
              size='small'
              onClick={() => { onApplyFilter(optionsSelected) }}
            >
              <Typography fontWeight={700} variant='caption'>
                Aplicar Filtro
              </Typography>
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export { SearchCheckboxFilter };