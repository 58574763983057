import { Typography } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LabelIcon from '@mui/icons-material/Label';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import DiscountIcon from '@mui/icons-material/Discount';

import { ConfigurationItem } from 'components/ConfigurationItem'

import './styles.scss'

function ConfigurationPage() {
  return (
    <div className='configuration-page'>
      <Typography variant='h1' className='configuration-title'>Configurações</Typography>

      <ConfigurationItem
        icon={<SettingsIcon color='primary' />}
        title='Sistema'
        description='Gerencie configurações do sistema'
        link='/configurations/system'
      />
      <ConfigurationItem
        icon={<PeopleIcon color='primary' />}
        title='Usuários'
        description='Adicione e gerencie os usuários que podem acessar o sistema'
        link='/configurations/users'
      />
      <ConfigurationItem
        icon={<ApartmentIcon color='primary' />}
        title='Sua empresa'
        description='Atualize os dados da sua empresa'
        link='/configurations/company'
      />
      <ConfigurationItem
        icon={<AccountBalanceIcon color='primary' />}
        title='Contas bancárias'
        description='Gerencie suas contas bancárias e os caixas da sua empresa'
        link='/configurations/cashiers-and-bank-accounts'
      />
      <ConfigurationItem
        icon={<LabelIcon color='primary' />}
        title='Tags de clientes'
        description='Gerencie suas tags de clientes'
        link='/configurations/tags'
      />
      <ConfigurationItem
        icon={<DiscountIcon color='primary' />}
        title='Cupons de clientes'
        description='Gerencie seus cupons'
        link='/configurations/coupons'
      />
    </div>
  )
}

export { ConfigurationPage }