import { useState } from "react";

type ViewModelPropTypes = {
  link: string
}

export default function DisplayInfoLinkViewModel({ link }: ViewModelPropTypes) {
  const [isTooltipOpen, setTooltipOpen] = useState(false)

  const handleOpen = async () => {
    await navigator.clipboard.writeText(link)
    setTooltipOpen(true)
  }
  const handleClose = () => {
    setTooltipOpen(false)
  }

  return { isTooltipOpen, handleClose, handleOpen }
}