import dayjs from 'dayjs';
import { CustomError, api } from 'utils/axios';

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type SaleType = {
  customer_name: string,
  key: string,
  period_end: string,
  period_start: string,
  plan_name: string,
  product_name: string,
  status: string,
  tags: Array<string>,
}

type SaleDisplayType = {
  customer_name: string,
  key: string,
  period_end: string,
  period_start: string,
  product_name: string,
  status: string,
  tags: string,
}

const parseSales = async (response: { data: any, meta: any }) => {
  const sales = response.data.map((data: any) => {
    return {
      customer_name: data.attributes.customer_name,
      key: data.attributes.key,
      period_end: data.attributes.period_end.date,
      period_start: data.attributes.period_start.date,
      product_name: data.attributes.product_name,
      status: data.attributes.status,
      tags: data.attributes.tags ? data.attributes.tags : [],
    }
  });

  return {
    sales,
    pagination: {
      totalSales: response.meta.pagination.total
    }
  }
}

const getSales = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get( `/api-cashone/clients/${id}/sales-spot`, {
      params,
      signal
    });

    return parseSales(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}


const formatSalesToDisplay = (sales: Array<SaleType>): Array<SaleDisplayType> => {

  return sales.map((sale: SaleType) => {
    return {
      customer_name: sale.customer_name,
      key: sale.key,
      period_start: dayjs(sale.period_start).format('DD/MM/YYYY'),
      period_end: dayjs(sale.period_end).format('DD/MM/YYYY'),
      status: sale.status === 'ACTIVE' ? 'ATIVO' :
        sale.status === 'TERMINATED' || sale.status === 'STANDBY_CANCEL' ? 'CANCELADO' :
          sale.status === 'EXPIRED' || sale.status === 'STANDBY_EXPIRE' ? 'EXPIRADO' : '-',
      product_name: sale.product_name,
      tags: sale.tags ? sale.tags.join(', ') : '',
    }
  })

}

export { getSales, formatSalesToDisplay };

export type { PaginationType, SortType, SaleType, SaleDisplayType };