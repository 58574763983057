import dayjs from "dayjs";

import { SalePayloadType } from "recoil/createSale/states";

import { formatMoney, parseToFloat } from "utils/moneyFormatt";
import { formatPercent } from 'utils/percentFormat';

const bundlePayloadToRecoil = (values: Record<string, any>): SalePayloadType => {

  return {
    payment_terms: {
      first_payment_term_type: values.payment_terms.first_payment_term_type,
      first_payment_term_value: values.payment_terms.first_payment_term_value ?
        parseInt(values.payment_terms.first_payment_term_value) :
        undefined,
      payment_term_type: values.payment_terms.payment_term_type,
      payment_term_value: values.payment_terms.payment_term_value ?
        parseInt(values.payment_terms.payment_term_value) :
        undefined,
    },
    taxes: {
      withhold: values.taxes.withhold?.length ? true : false,
      rate: values.taxes.rate ? parseToFloat(values.taxes.rate) / 100 : undefined,
      withhold_in_receipt: values.taxes.withhold_in_receipt?.length ? true : false,
      rate_in_receipt: values.taxes.rate_in_receipt ? parseToFloat(values.taxes.rate_in_receipt) / 100 : undefined,
    },
    plan_value: values.plan_value ? parseToFloat(values.plan_value) : undefined,
    product_value: values.product_value ? parseToFloat(values.product_value) : undefined,
    create_receipt: values.create_receipt?.length ? true : false,
    auto_receipt_run: values.auto_receipt_run === '1' ? true : false,
    description: values.description,
    start_date: values.start_date,
    initial_term: values.initial_term ? parseInt(values.initial_term) : undefined,
    freq: values.freq,
    auto_renewal: values.auto_renewal?.length ? true : false,
    installments: values.installments ? values.installments : undefined,
    has_installments: values.has_installments === '1' ? true : false,
  }
}

const formatSalePayloadToDisplay = (values: SalePayloadType) => {

  let response: any = {
    payment_terms: {},
  }

  response.product_value = formatMoney(values.product_value ?? 0)
  response.plan_value = formatMoney(values.plan_value ?? 0)
  response.description = values.description
  response.freq = values.freq
  response.initial_term = values.initial_term
  response.auto_renewal = values.auto_renewal ? ['1'] : []
  response.start_date = values.start_date ? values.start_date : dayjs()

  if (values.payment_terms) {
    response.payment_terms = {
      payment_term_type: values.payment_terms?.payment_term_type,
      payment_term_value: values.payment_terms?.payment_term_value?.toString(),
      first_payment_term_type: values.payment_terms?.first_payment_term_type,
      first_payment_term_value: values.payment_terms?.first_payment_term_value?.toString()
    }

    if (values.payment_terms.payment_term_type && values.payment_terms.payment_term_value === undefined)
      response.payment_terms.payment_term_value = '0'

    if (values.payment_terms.first_payment_term_type && values.payment_terms.first_payment_term_value === undefined)
      response.payment_terms.first_payment_term_value = '0'
  }

  // Initial values, if they're empty
  if (values.has_installments === undefined)
    response.has_installments = '0';
  else
    response.has_installments = values.has_installments ? '1' : '0';
  if (values.auto_receipt_run === undefined)
    response.auto_receipt_run = '1';
  else
    response.auto_receipt_run = values.auto_receipt_run ? '1' : '0'

  if (values.create_receipt === undefined)
    response.create_receipt = ['1']
  else
    response.create_receipt = values.create_receipt ? ['1'] : [];

  if (!values.payment_terms?.payment_term_type) {
    response.payment_terms.payment_term_type = 'NET'
  }
  if (typeof values.payment_terms?.payment_term_value !== 'number') {
    response.payment_terms.payment_term_value = '5'
  }
  if (!values.payment_terms?.first_payment_term_type) {
    response.payment_terms.first_payment_term_type = response.payment_terms.payment_term_type
  }
  if (typeof values.payment_terms?.first_payment_term_value !== 'number') {
    response.payment_terms.first_payment_term_value = response.payment_terms.payment_term_value
  }

  if (values.taxes) {
    response.taxes = {
      withhold: values.taxes?.withhold ? ['1'] : [],
      withhold_in_receipt: values.taxes?.withhold_in_receipt ? ['1'] : []
    }

    if (values.taxes.rate)
      response.taxes.rate = formatPercent(values.taxes?.rate ?? 0)

    if (values.taxes.rate_in_receipt)
      response.taxes.rate_in_receipt = formatPercent(values.taxes?.rate_in_receipt ?? 0)
  }

  if (values.installments) {
    response.installments = values.installments;
    response.formattedInstallments = values.installments ?
      values.installments.map((i) => {
        return `${formatMoney(i.total_value)} vencendo em ${i.date.format('DD/MM/YYYY')}`
      }) : undefined;
  }
  else
    response.installments = []

  return response
}


export { bundlePayloadToRecoil, formatSalePayloadToDisplay }