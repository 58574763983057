import { useState } from 'react';

import { useCreateSaleActions } from 'recoil/createSale/actions';

type ViewModelPropTypes = {
  onClose: () => void
}

export default function CreateSaleModalViewModel({
  onClose
}: ViewModelPropTypes) {
  const [page, setPage] = useState(0)

  const { resetPayload } = useCreateSaleActions()

  const onBack = () => {
    setPage(page - 1)
  }

  const onNext = () => {
    setPage(page + 1)
  }

  const onCloseModal = () => {
    setPage(0)
    resetPayload()
    onClose()
  }

  return {
    page,
    onBack,
    onNext,
    onCloseModal
  }
}