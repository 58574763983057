import dayjs from 'dayjs'
import { CustomError, api } from 'utils/axios'
import { formatMoney } from 'utils/moneyFormatt'

type DetailsType = {
  id: string,
  key: string,
  status: string,
  customer: string,
  total: number,
  due_date: string,
  company_name: string,
  company_logo: string,
  created_at: string,
  updated_at: string,
}

type DetailsDisplayType = {
  id: string,
  key: string,
  status: string,
  customer: string,
  total: string,
  due_date: string,
  company_name: string,
  company_logo: string,
  created_at: string,
  updated_at: string,
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    key: response.data.attributes.key,
    status: response.data.attributes.status,
    customer: response.data.attributes.customer_name,
    total: response.data.attributes.total,
    due_date: response.data.attributes.due_date.date,
    company_name: response.data.attributes.company_name,
    company_logo: response.data.attributes.company_logo,
    created_at: response.data.attributes.created_at.date,
    updated_at: response.data.attributes.updated_at.date,
  }
}

const getDetails = async (key: string, hash: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(
      `/api-cashone/accounts/receivables/${key}`,
      {
        headers: {
          Authorization: `Bearer ${hash}`
        },
        signal
      });

    return parseDetails(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {

  const ACCOUNT_RECEIVABLE_STATUS: any = {
    'DRAFT': 'RASCUNHO',
    'PENDING': 'Pendente',
    'PAID': 'Pago',
    'TRANSFERRED': 'Transferido',
    'EXPIRED': 'Expirado',
    'ERROR': 'Erro',
    'CANCELLED': 'Cancelado',
    'PARTIALLY_PAID': 'Parcialmente pago'
  }

  return {
    id: details.id,
    key: details.key,
    status: ACCOUNT_RECEIVABLE_STATUS[details.status].toUpperCase(),
    customer: details.customer,
    total: formatMoney(details.total),
    due_date: dayjs(details.due_date).format('DD/MM/YYYY'),
    company_name: details.company_name,
    company_logo: details.company_logo,
    created_at: dayjs(details.created_at).format('DD/MM/YYYY'),
    updated_at: dayjs(details.updated_at).format('DD/MM/YYYY'),
  }
}

const bundleCreditCardPayload = (key: string, values: Record<string, any>) => {
  const translateBrand = {
    'american-express': 'American Express',
    'diners-club': 'Diners Club',
    'discover': 'Discover',
    'mastercard': 'Mastercard',
    'visa': 'Visa'
  }

  type translateBrandEnum = keyof typeof translateBrand;

  return {
    account_receivable_key: key,
    holder_name: values.holder_name,
    digits: values.digits.replaceAll(' ', ''),
    security_code: values.security_code,
    expiration_month: values.expiration_month,
    expiration_year: values.expiration_year,
    brand: translateBrand[values.brand as translateBrandEnum],
  }
}

const postCreditCard = async (key: string, hash: string, values: Record<string, any>): Promise<any> => {
  try {
    const response = await api.post('/api-cashone/credit-card/charge', bundleCreditCardPayload(key, values), {
      headers: {
        Authorization: `Bearer ${hash}`
      }
    })

    return response;

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getDetails, formatDetailsToDisplay, postCreditCard }

export type { DetailsType, DetailsDisplayType }