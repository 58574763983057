import { CustomError, api } from 'utils/axios';

const uploadFile = async (file: File): Promise<any> => {
  try {
    const response = await api.post('/api-cashone/clients/details/files', {
      attachments: file
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data.data.attributes.url;

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { uploadFile }