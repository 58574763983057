import { Box, LinearProgress, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import useViewModel from './viewModel';
import { DateRangeFilter } from 'components/Filters/DateRangeFilter';

import './styles.scss';

function Metrics() {
  const {
    formattedEffectiveDateFilter,
    formattedPlanRevenue,
    formattedSelectedPlanRevenue,
    selectedPlans,
    formattedReceiptRevenue,
    formattedActiveCustomer,
    formattedChurnedCustomer,
    formattedCashierFlow,
    effectiveDateFilter,
    activeCustomerLoading,
    activeCustomerLoadingError,
    churnedCustomerLoading,
    churnedCustomerLoadingError,
    receiptRevenueLoading,
    receiptRevenueLoadingError,
    cashierFlowLoading,
    cashierFlowLoadingError,
    planRevenueLoading,
    planRevenueLoadingError,
    minDateFilter,
    applyEffectiveDateFilter,
    onSelectPlansFilter
  } = useViewModel();

  return (
    <div className='metrics-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Métricas
          </Typography>
        </div>

        <div className='filters-container'>
            Mostrando dados para o período de {formattedEffectiveDateFilter !== undefined ? formattedEffectiveDateFilter[0] : '-'} a {formattedEffectiveDateFilter !== undefined ? formattedEffectiveDateFilter[1] : '-'}
          <div className='filters'>
            <DateRangeFilter
              id='effective-date'
              buttonLabel='Alterar período'
              initialStartDate={effectiveDateFilter[0].value}
              initialEndDate={effectiveDateFilter[1].value}
              onlyMonth={true}
              onApplyFilter={applyEffectiveDateFilter}
              minDate={minDateFilter}
            />
          </div>
        </div>

      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> </Box>

      <div className='metric-container'>
        <div className='metric-item'>
          <Typography variant='h4' fontWeight={700} className='metric-title'>Clientes ativos</Typography>
          {activeCustomerLoading && <>
            <LinearProgress color='secondary' />
            <BarChart
            height={300}
            series={[
              { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              ]}
            xAxis={[{ scaleType: 'band', data: ['-', '-', '-', '-', '-', '-'] }]}
          /></>}

          {!activeCustomerLoading && !activeCustomerLoadingError && formattedActiveCustomer &&
            <BarChart
              height={300}
              series={[
                { data: formattedActiveCustomer.data, color: '#8FBC8F' }
              ]}
              xAxis={[{ scaleType: 'band', data: formattedActiveCustomer.label }]}
              axisHighlight={{ x: 'band', y: 'line' }}
            />
          }

          {!activeCustomerLoading && activeCustomerLoadingError &&
            <BarChart
            height={300}
            series={[]}
            xAxis={[{ scaleType: 'band', data: ['Ops, não conseguimos carregar esse gráfico'] }]}
            />
          }
        </div>

        <div className='metric-item'>
          <Typography variant='h4' fontWeight={700} className='metric-title'>Churn de clientes</Typography>
          {churnedCustomerLoading && <>
            <LinearProgress color='secondary' />
            <BarChart
              height={300}
              series={[
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              ]}
              xAxis={[{ scaleType: 'band', data: ['-', '-', '-', '-', '-', '-'] }]}
          /></>}
          
          {!churnedCustomerLoading && !churnedCustomerLoadingError && formattedChurnedCustomer &&
            <BarChart
              height={300}
              series={[
                { data: formattedChurnedCustomer.data, color: '#8FBC8F' }
              ]}
              xAxis={[{ scaleType: 'band', data: formattedChurnedCustomer.label }]}
              axisHighlight={{ x: 'band', y: 'line' }}
            />
          }

          {!churnedCustomerLoading && churnedCustomerLoadingError &&
            <BarChart
              height={300}
              series={[]}
              xAxis={[{ scaleType: 'band', data: ['Ops, não conseguimos carregar esse gráfico'] }]}
            />
          }
        </div>
      </div>

      <div className='metric-container'>
        <div className='metric-item'>
          <Typography variant='h4' fontWeight={700} className='metric-title'>Faturamento por nota fiscal emitida</Typography>

          {receiptRevenueLoading && <>
            <LinearProgress color='secondary' />
            <BarChart
              height={300}
              series={[
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              ]}
              xAxis={[{ scaleType: 'band', data: ['-', '-', '-', '-', '-', '-'] }]}
          /></>}

          {!receiptRevenueLoading && !receiptRevenueLoadingError && formattedReceiptRevenue &&
            <BarChart
              height={300}
              series={[
                { data: formattedReceiptRevenue.data, color: '#8FBC8F', valueFormatter: formattedReceiptRevenue.valueFormatter }
              ]}
              xAxis={[{ scaleType: 'band', data: formattedReceiptRevenue.label }]}
              axisHighlight={{ x: 'band', y: 'line' }}
            />
          }

          {!receiptRevenueLoading && receiptRevenueLoadingError &&
            <BarChart
              height={300}
              series={[]}
              xAxis={[{ scaleType: 'band', data: ['Ops, não conseguimos carregar esse gráfico'] }]}
            />
          }
        </div>

        <div className='metric-item'>
          <Typography variant='h4' fontWeight={700} className='metric-title'>Receita total (entradas no caixa)</Typography>

          {cashierFlowLoading && <>
            <LinearProgress color='secondary' />
            <BarChart
              height={300}
              series={[
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              ]}
              xAxis={[{ scaleType: 'band', data: ['-', '-', '-', '-', '-', '-'] }]}
            /></>}

          {!cashierFlowLoading && !cashierFlowLoadingError && formattedCashierFlow &&
            <BarChart
              height={300}
              series={[
                { data: formattedCashierFlow.data, color: '#8FBC8F', valueFormatter: formattedCashierFlow.valueFormatter }
              ]}
              xAxis={[{ scaleType: 'band', data: formattedCashierFlow.label }]}
              axisHighlight={{ x: 'band', y: 'line' }}
            />
          }

          {!cashierFlowLoading && cashierFlowLoadingError &&
            <BarChart
              height={300}
              series={[]}
              xAxis={[{ scaleType: 'band', data: ['Ops, não conseguimos carregar esse gráfico'] }]}
            />
          }
        </div>

      </div>

      <div className='metric-container'>
        <div className='metric-item'>
          <Typography variant='h4' fontWeight={700} className='metric-title'>Faturamento por plano</Typography>

          <FormControl className='selector' sx={{ m: 1, width: 500 }}>
            <InputLabel id='selected-plans'>Selecionar planos</InputLabel>
            <Select
              labelId='selected-plans'
              id='selected-plans'
              multiple
              disabled={planRevenueLoading}
              value={selectedPlans}
              onChange={(event) => {
                onSelectPlansFilter(event.target.value)
              }}
              input={<OutlinedInput label='Selecionar planos' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={
                {
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 500,
                    },
                  },
                }
              }
            >
              {formattedPlanRevenue?.series.map((plan:any) => (
                <MenuItem key={plan.label} value={plan.label}>
                  <Checkbox checked={selectedPlans.indexOf(plan.label) > -1} />
                  <ListItemText primary={plan.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {planRevenueLoading && <>
            <LinearProgress color='secondary' />
            <BarChart
              height={300}
              series={[
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
              ]}
              xAxis={[{ scaleType: 'band', data: ['-', '-', '-', '-', '-', '-'] }]}
            /></>}

          {!planRevenueLoading && !planRevenueLoadingError && formattedSelectedPlanRevenue &&
            <BarChart
              height={300}
              series={formattedSelectedPlanRevenue.series}
              xAxis={[{ scaleType: 'band', data: formattedSelectedPlanRevenue.label !== null ? formattedSelectedPlanRevenue.label : [] }]}
              slotProps={{
                legend: {
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
              },
              }}
              axisHighlight={{ x: 'band', y: 'line' }}
            />
          }

          {!planRevenueLoading && planRevenueLoadingError &&
            <BarChart
              height={300}
              series={[]}
              xAxis={[{ scaleType: 'band', data: ['Ops, não conseguimos carregar esse gráfico'] }]}
            />
          }
        </div>
      </div>

    </div>
  );
}

export { Metrics };