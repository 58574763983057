import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { PasswordFieldAdapter } from 'components/FormComponents/Fields/PasswordFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';
import { TelephoneFieldAdapter } from 'components/FormComponents/Fields/TelephoneFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss';
import './styles.scss'

type EditUserModalPropTypes = {
  userData: {
    id: string,
    name: string,
    email: string,
    phone: string,
  },
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function EditUserModal({ userData, open, onClose, onSubmit }: EditUserModalPropTypes) {
  const {
    editLoading,
    editError,
    formError,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ userId: userData.id, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='edit-user-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            initialValues={{ ...userData }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Editar Usuário
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>

                  <Flexbox size={12}>
                    <TextFieldAdapter
                      label={'Nome'}
                      name='name'
                      disabled={editLoading}
                    />
                  </Flexbox>

                  <Flexbox size={12}>
                    <Flexbox size={6}>
                      <TextFieldAdapter
                        label='Email'
                        name='email'
                        disabled={editLoading}
                      />
                    </Flexbox>

                    <Flexbox size={6}>
                      <TelephoneFieldAdapter
                        label='Telefone'
                        name='phone'
                        disabled={editLoading}
                      />
                    </Flexbox>
                  </Flexbox>

                  <Flexbox size={6}>
                    <PasswordFieldAdapter
                      label='Nova senha'
                      name='password'
                      disabled={editLoading}
                    />
                  </Flexbox>

                  <div className='password-hint'>
                    <Typography variant='caption' color='gray'>
                      *Só preencha campo de nova senha se quiser mudar a senha <br />
                      Dica para senha segura: <br />
                      - Deve conter pelo menos 12 caracteres <br />
                      - Deve conter pelo menos uma letra maiúscula <br />
                      - Deve conter pelo menos um número <br />
                      - Deve conter pelo menos um caractere especial
                    </Typography>
                  </div>
                </div>

                <div className='modal-footer'>
                  {editLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' onClick={onClose} disabled={editLoading}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={editLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(editError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Usuário atualizado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { EditUserModal }