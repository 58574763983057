import { MenuItem } from './MenuItem'
import { CollapseMenuItem } from './CollapseMenuItem';
import { Typography, IconButton } from '@mui/material';
import { auth } from 'utils/auth';
import Avatar from 'react-avatar';

import useViewModel from './viewModel';

import LogoPath from '../../../images/logo-cash-white.png';
import { ReactComponent as HomeIcon } from '../../../images/icons/home.svg'
import { ReactComponent as ShoppingBagIcon } from '../../../images/icons/shopping-bag.svg'
import { ReactComponent as WalletIcon } from '../../../images/icons/wallet.svg'
import { ReactComponent as GearIcon } from '../../../images/icons/gear.svg'
import { ReactComponent as GraphicIcon } from '../../../images/icons/graphic.svg'

import './styles.scss'

type SidebarPropTypes = {
  onLogout: () => void
}

function Sidebar({ onLogout }: SidebarPropTypes) {
  const { billingSubItems, currentUrl, registerSubItems, onClick } = useViewModel()

  return (
    <div className='sidebar-menu'>

      <div className='logo'>
        <img src={LogoPath} alt='Logo' />
      </div>

      <div className='item-list'>

        <MenuItem
          icon={
            <HomeIcon
              width='20px'
              height='20px'
              stroke='white'
            />
          }
          title='Início'
          onClick={() => { onClick('/') }}
        />

        <CollapseMenuItem
          currentUrl={currentUrl}
          icon={
            <ShoppingBagIcon
              width='20px'
              height='20px'
              stroke='white'
            />
          }
          title='Cadastro'
          items={registerSubItems}
          onSubitemClick={onClick}
        />

        <CollapseMenuItem
          currentUrl={currentUrl}
          icon={
            <WalletIcon
              width='20px'
              height='20px'
              stroke='white'
            />
          }
          title='Cobranças'
          items={billingSubItems}
          onSubitemClick={onClick}
        />

        <MenuItem
          icon={
            <GraphicIcon
              width='20px'
              height='20px'
              stroke='white'
            />
          }
          title='Métricas'
          onClick={() => { onClick('/metrics') }}
        />

      </div>

      <div className='configurations'>
        <div className='avatar'>
          <Avatar
            name={auth.user?.name}
            size='36'
            round={true} />
        </div>

        <div className='profile'>
          <div className='name'>
            <Typography variant='caption' fontWeight={'bold'}>
              {auth.user?.name ? auth.user.name : ''}
            </Typography>
          </div>
          <div className='email'>
            <Typography variant='caption'>
              {auth.user?.email ? auth.user.email : ''}
            </Typography>
          </div>

            <div className='logout-container' onClick={() => onLogout()}>
                <Typography variant='caption' className='logout-text'>
                    Logout
                </Typography>

                <div className='version-text'>
                    Versão 3.1.3
                </div>
            </div>
        </div>

          <div className='icon'>

              <IconButton
                  aria-label="delete"
            color="primary"
            onClick={() => { onClick('/configurations') }}>
            <GearIcon
              width='24px'
              height='24px'
              stroke='white'
            />
          </IconButton>

        </div>
      </div>

    </div>
  )
}

export { Sidebar }