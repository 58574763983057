import {CheckoutOrderPayloadType} from 'recoil/checkout/states';
import {apiCheckout} from 'utils/axios';
import {STATES} from "../../../../utils/constants/states";
import {CustomCheckoutError} from "../../../../utils/CustomError";

type ClientPayload = {
    type: string,
    name?: string,
    first_name?: string,
    last_name?: string,
    document: string,
    email?: string,
    phone: string,
    address: {
        street: string,
        number: string,
        complement?: string,
        neighbourhood: string,
        city: {
            value: number,
            label: string,
        },
        postal_code?: string,
        state_abbreviation?: string
    },
    regime?: {
        razao_social: string,
        optante_simples_nacional: Array<string>
    }
}

type BundledClientPayload = {
    type: string,
    product_hash: string,
    first_name?: string,
    last_name?: string,
    name?: string,
    company_name?: string,
    razao_social?: string,
    document?: string,
    cpf?: string|null,
    cnpj?: string,
    optante_simples_nacional?: boolean,
    work_email: string,
    work_phone: string,
    country: string,
    postal_code: string,
    state_province: string,
    state_abbreviation: string,
    city: string,
    ibge_code: number,
    neighbourhood: string,
    address:string,
    number:string,
    complement:string,
    captcha: string
}

const bundlePayload = (payload: ClientPayload, productHash: string, captcha: string|null): BundledClientPayload => {
    let response : BundledClientPayload = {
        type: payload.type,
        product_hash: productHash,
        work_email: payload.email ? payload.email : '',
        work_phone: payload.phone,
        country: 'Brasil',
        postal_code: payload.address.postal_code ? payload.address.postal_code : '',
        state_province: STATES[payload.address.state_abbreviation ? payload.address.state_abbreviation : ''],
        state_abbreviation: payload.address.state_abbreviation ? payload.address.state_abbreviation : '',
        city: payload.address.city.label,
        ibge_code: payload.address.city.value,
        neighbourhood: payload.address.neighbourhood,
        address: payload.address.street,
        number: payload.address.number,
        complement: payload.address.complement ? payload.address.complement : '',
        captcha: captcha ?? '',
        document: payload.document,
    }

    if (payload.type === 'brlCompanyRegime') {
        response.razao_social = payload.regime?.razao_social
        response.optante_simples_nacional = payload.regime?.optante_simples_nacional[0] === '1'
        response.cnpj = payload.document
        response.cpf = null
        response.name = payload.name
        response.company_name = payload.name
        response.first_name = ''
        response.last_name = ''
    } else {
        response.first_name = payload.first_name
        response.last_name = payload.last_name
        response.name = payload.first_name + ' ' + payload.last_name
        response.cpf = payload.document
    }

    return response
}

const postClient = async (payload: ClientPayload, productHash: string, captcha: string|null): Promise<any> => {
    try {
        return await apiCheckout.post('/customers', bundlePayload(payload, productHash, captcha))
    } catch (error) {
        const errorResponse = (error as CustomCheckoutError).errors
        if (errorResponse)
            return { errors: errorResponse };

        return { errors: [] };
    }
}

const bundlePayloadToRecoil = (values: Record<string, any>, captcha?: string, hash?: string, checkoutPayload?: CheckoutOrderPayloadType): CheckoutOrderPayloadType => {
    const customer = {
        hash: hash,
        type: values.type,
        document: values.document,
        registration: values.document,
        email: values.email ? values.email : null,
        phone: values.phone,
        name: values.name,
        first_name: values.first_name,
        last_name: values.last_name,
        razao_social: values.regime?.razao_social,
        optante_simples_nacional: values.regime?.optante_simples_nacional ? (values.regime.optante_simples_nacional[0] === '1') : false,
        address: {
            postal_code: values.address?.postal_code,
            city: {
                value: values.address?.city.value,
                label: values.address?.city.label
            },
            state: values.address?.state_abbreviation,
            neighbourhood: values.address?.neighbourhood,
            street: values.address?.street,
            number: values.address?.number,
            complement: values.address?.complement,
        }
    }

    return {
        customer: customer,
        captcha: captcha
    }
}

const formatCheckoutCustomerPayloadToDisplay = (values: CheckoutOrderPayloadType) => {
    return {
        ...values.customer,
        type: values.customer?.type ? values.customer.type : 'brlCompanyRegime',
        email: values.customer?.email ? values.customer?.email : null,
        regime: {
            razao_social: values.customer?.razao_social,
            optante_simples_nacional: values.customer?.optante_simples_nacional ? ['1'] : []
        },
        address: {
            ...values.customer?.address,
            state_abbreviation: values.customer?.address?.state
        }
    }
}

export {
    postClient,
    bundlePayload,
    bundlePayloadToRecoil,
    formatCheckoutCustomerPayloadToDisplay
}

export type {
    ClientPayload,
    BundledClientPayload
}