import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useViewModel from './viewModel'

type ActionButtonTablePropTypes = {
  options: {
    id: string,
    label: string,
    disabled?: boolean,
  }[],
  onClickOption: (id: string) => void
}

function ActionButtonTable({
  options,
  onClickOption
}: ActionButtonTablePropTypes) {
  const { anchorEl, open, close, handleClick } = useViewModel()

  return (
    <>
      <IconButton className='action-button-table' onClick={handleClick}>
        <MoreVertIcon className='action-button-table' />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          (e as any).preventDefault()
          close()
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            disabled={option.disabled}
            onClick={(e) => {
              e.preventDefault()
              onClickOption(option.id);
              close()
            }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export { ActionButtonTable }