import { Alert, Button, Skeleton, Snackbar, Typography } from '@mui/material'
import { Form } from 'react-final-form'

import useViewModel from './viewModel'

import './styles.scss'
import { CheckboxFieldAdapter } from 'components/FormComponents/Fields/CheckboxFieldAdapter'
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter'
import { ApiErrorView } from 'components/ApiErrorView'

function System() {
  const {
    editError,
    formattedSystem,
    formError,
    openSnackbar,
    systemLoading,
    systemLoadingError,
    handleSubmit,
    onCancel,
    onCloseSnackbar,
  } = useViewModel()

  return (
    <div className='configuration-system'>
      <Typography variant='h1' className='configuration-title'>Configurações do Sistema</Typography>

      {systemLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as configurações do sistema. Tente recarregar a página.' />
      }

      {!systemLoadingError &&
        <Form
          onSubmit={handleSubmit}
          initialValues={formattedSystem}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='config'>
                <div className='config-label'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Lembrete para contas a receber por boleto</Typography>
                  <Typography variant='caption' className='section-subtitle'>Enviar email com lembrete para clientes que possuem contas a receber pendentes ou expirados. Essas opções têm efeito somente para boletos.</Typography>
                </div>

                {systemLoading ? (
                  <div className='config-loading'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  <CheckboxFieldAdapter
                    name='boleto_reminder'
                    disabled={systemLoading}
                    options={[
                      {
                        label: 'Enviar email 4, 7 e 10 dias após o boleto expirar',
                        value: 'boleto_reminder'
                      },
                      {
                        label: 'Enviar email no dia do boleto expirar',
                        value: 'boleto_reminder_on_due_date'
                      },
                      {
                        label: 'Enviar email 3 dias antes do boleto expirar',
                        value: 'boleto_reminder_three_days_before_due_date'
                      },
                      {
                        label: 'Enviar email 5 dias antes do boleto expirar',
                        value: 'boleto_reminder_five_days_before_due_date'
                      },
                    ]}
                  />
                )}
              </div>

              <div className='config'>
                <div className='config-label'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Lembrete para assinaturas</Typography>
                  <Typography variant='caption' className='section-subtitle'>Enviar email com lembrete para clientes que possuem assinaturas recorrentes que estão prestes a expirar. Isso é útil quando há renovação ou reajuste de preços e o cliente precisa ser comunicado com antecedência.</Typography>
                </div>

                {systemLoading ? (
                  <div className='config-loading'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  <CheckboxFieldAdapter
                    name='expiring_subscription_plan_reminder'
                    disabled={systemLoading}
                    options={[
                      {
                        label: 'Enviar email no dia da assinatura expirar',
                        value: 'expiring_subscription_plan_reminder'
                      },
                      {
                        label: 'Enviar email 15 dias antes da assinatura expirar',
                        value: 'expiring_subscription_plan_reminder_15_days'
                      },
                      {
                        label: 'Enviar email 30 dias antes da assinatura expirar',
                        value: 'expiring_subscription_plan_reminder_30_days'
                      },
                    ]}
                  />
                )}
              </div>

              <div className='config'>
                <div className='config-label cco-config'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Cópia oculta de emails</Typography>
                  <Typography variant='caption' className='section-subtitle'>Você pode receber como cópia oculta (cco) todos os emails enviados para os seus clientes. Isso é útil para verificar se os emails estão sendo enviados corretamente, ou então para manter um histórico próprio com todos os emails disparados em nome da sua empresa.</Typography>
                </div>

                <div className='text-field-container'>

                  {systemLoading ? (
                    <div className='config-loading'>
                      <Skeleton />
                    </div>
                  ) : (
                    <TextFieldAdapter
                      name='blind_carbon_copy_email'
                      label='Email'
                      disabled={systemLoading}
                    />
                  )}

                </div>
              </div>

              <div className='config'>
                <div className='config-label'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Emails enviados aos clientes</Typography>
                  <Typography variant='caption' className='section-subtitle'>Você pode escolher quais emails são enviados aos seus clientes.</Typography>
                </div>

                {systemLoading ? (
                  <div className='config-loading'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  <CheckboxFieldAdapter
                    name='customer_email'
                    disabled={systemLoading}
                    options={[
                      {
                        label: 'Enviar email da fatura quando ela for aprovada',
                        value: 'invoice_delivery_preference'
                      },
                      {
                        label: 'Enviar email de conta a receber quando ele estiver disponível para pagamento (boleto, pix, cartão de crédito, depósito bancário)',
                        value: 'payment_delivery_preference'
                      },
                      {
                        label: 'Enviar email quando a nota fiscal for autorizada na prefeitura',
                        value: 'receipt_delivery_preference'
                      },
                    ]}
                  />
                )}
              </div>

              <div className='config-buttons-container'>
                <Button disabled={systemLoading} variant='outlined' onClick={onCancel}>Cancelar</Button>
                <Button type='submit' disabled={systemLoading} variant='contained'>Salvar</Button>
              </div>
            </form>
          )}
        />
      }

      {(editError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Configurações do sistema foram atualizadas!
          </Alert>
        </Snackbar>
      }
    </div>
  )
}

export { System }