import { Alert, Button, Paper, Snackbar, Typography } from '@mui/material';
import { Form } from 'react-final-form';

import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import { required } from 'utils/FieldValidations';

import LogoPath from 'images/logo-cash.png'

import useViewModel from './viewModel'

import './styles.scss'

function ForgotPassword() {
  const {
    openSnackbar,
    passwordLoading,
    passwordLoadingError,
    onCloseSnackbar,
    onSubmit,
    redirectLogin
  } = useViewModel()

  return (
    <div className='forgot-password-page'>
      <div className='logo'>
        <img src={LogoPath} alt='Logo' />
      </div>

      <Paper className='form-box'>

        <div className='text-container'>
          <Typography variant='h4' fontWeight={700} className='title'>Esqueci minha senha</Typography>
          <Typography variant='body1'>Digite seu endereço de email e lhe enviaremos instruções de como alterar sua senha.</Typography>
        </div>

        <Form
          onSubmit={(values) => { onSubmit({ email: values.email }) }}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className='form-container'
            >

              <div className='text-input'>
                <TextFieldAdapter
                  name='email'
                  label='Email'
                  validate={required}
                  type='email'
                />
              </div>

              <div className='buttons-container'>
                <Button size='large' variant='contained' type='submit' disabled={passwordLoading}>
                  <Typography variant='body1' fontWeight={700}>
                    Enviar instruções
                  </Typography>
                </Button>
                <Button size='large' fullWidth disabled={passwordLoading} onClick={() => { redirectLogin() }}>
                  <Typography variant='body1' fontWeight={700}>
                    Voltar para tela de login
                  </Typography>
                </Button>
              </div>

            </form>
          )}
        />
      </Paper>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
        {!passwordLoadingError ? (

          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Dentro de alguns instantes você receberá um email com as instruções.
          </Alert>
        ) : (
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        )
        }
      </Snackbar>
    </div>
  );
}

export { ForgotPassword }