import './styles.scss'
import useViewModel from './viewModel';
import {Form} from "react-final-form";
import {Button, Typography} from "@mui/material";
import React from "react";
import {RadioFieldAdapter} from "../../../../components/FormComponents/Fields/RadioFieldAdapter";
import {Flexbox} from "../../../../components/Flexbox";
import {CreditCardDigitsFieldAdapter} from "../../../../components/FormComponents/Fields/CreditCardDigitsFieldAdapter";
import {TextFieldAdapter} from "../../../../components/FormComponents/Fields/TextFieldAdapter";
import {SelectFieldAdapter} from "../../../../components/FormComponents/Fields/SelectFieldAdapter";

import { ReactComponent as AmexIcon } from 'images/icons/amex.svg'
import { ReactComponent as DinersIcon } from 'images/icons/diners.svg'
import { ReactComponent as DiscoverIcon } from 'images/icons/discover.svg'
import { ReactComponent as MastercardIcon } from 'images/icons/mastercard.svg'
import { ReactComponent as VisaIcon } from 'images/icons/visa.svg'

type PaymentMethodStepPropTypes = {
    availablePaymentOptions?: Array<string>
    onBack: () => void
    onNext: () => void
}

function PaymentMethodStep({
    availablePaymentOptions,
    onBack,
    onNext
}: PaymentMethodStepPropTypes) {
    const {
        monthOptions,
        yearOptions,
        paymentMethodOptions,
        brand,
        formattedCheckoutOrderPayload,
        onDigitsChange,
        formValidate,
        onSubmit
    } = useViewModel({availablePaymentOptions, onBack, onNext})

    return <Form
        onSubmit={onSubmit}
        validate={formValidate}
        initialValues={formattedCheckoutOrderPayload}
        render={({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>

                <div className='section-title'>
                    <Typography variant='h3' fontWeight={700} color='primary'>Forma de pagamento</Typography>
                </div>

                <div className='payment-method-container'>
                    <RadioFieldAdapter
                        label=''
                        name='payment_method'
                        options={paymentMethodOptions}
                        row={true}
                        defaultValue='BOLETO'
                    />
                </div>

                {
                    values.payment_method === 'CREDIT_CARD' && <>
                        <Flexbox size={12}>
                            <CreditCardDigitsFieldAdapter
                                brandIcon={(
                                    <>
                                        {brand === 'american-express' && <AmexIcon/>}
                                        {brand === 'diners-club' && <DinersIcon/>}
                                        {brand === 'discover' && <DiscoverIcon/>}
                                        {brand === 'mastercard' && <MastercardIcon/>}
                                        {brand === 'visa' && <VisaIcon/>}
                                    </>
                                )}
                                name='digits'
                                label='Número do cartão'
                                disabled={false}
                                onChange={(e) => {
                                    onDigitsChange(e.target.value)
                                }}
                            />

                        </Flexbox>

                        <Flexbox size={12}>
                            <TextFieldAdapter
                                name='holder_name'
                                label='Nome impresso no cartão'
                                disabled={false}
                            />
                        </Flexbox>

                        <Flexbox size={12}>
                            <Flexbox size={4}>
                                <SelectFieldAdapter
                                    label='Mês'
                                    name='expiration_month'
                                    options={monthOptions}
                                    disabled={false}
                                />
                            </Flexbox>
                            <Flexbox size={4}>
                                <SelectFieldAdapter
                                    label='Ano'
                                    name='expiration_year'
                                    options={yearOptions}
                                    disabled={false}
                                />
                            </Flexbox>
                            <Flexbox size={4}>
                                <TextFieldAdapter
                                    label='CVC'
                                    name='security_code'
                                    inputProps={{ type: 'number', className: 'hide-input-controls', min: 0 }}
                                    disabled={false}
                                />
                            </Flexbox>
                        </Flexbox>
                    </>
                }

                <div className='footer'>
                    <Button variant='outlined' onClick={onBack}>Voltar</Button>
                    <Button variant='contained' type='submit'>Próximo</Button>
                </div>
            </form>
        )}
    />
}

export { PaymentMethodStep }