import { CustomError, api } from 'utils/axios';

type SystemType = {
  key: string,
  value: string
}[]

type SystemToDisplayType = {
  boleto_reminder: string[],
  expiring_subscription_plan_reminder: string[],
  blind_carbon_copy_email?: string,
  customer_email: string[],
  hide_invoice_group_cancelled: string[]
}

type SystemBundle = {
  boleto_reminder: boolean,
  boleto_reminder_on_due_date: boolean,
  boleto_reminder_three_days_before_due_date: boolean,
  boleto_reminder_five_days_before_due_date: boolean,
  expiring_subscription_plan_reminder: boolean,
  expiring_subscription_plan_reminder_15_days: boolean,
  expiring_subscription_plan_reminder_30_days: boolean,
  blind_carbon_copy_email: string,
  hide_invoice_group_cancelled: boolean,
  invoice_delivery_preference: boolean,
  payment_delivery_preference: boolean,
  receipt_delivery_preference: boolean
}

const parseSystem = (response: any) => {

  const system = response.data.map((parameter: any) => {
    return {
      key: parameter.attributes.key,
      value: parameter.attributes.value,
    }
  });

  return { system }
}

const getSystemConfiguration = async (signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get('/api-cashone/settings/system', { signal });

    return parseSystem(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const bundleSystem = (values: SystemToDisplayType): SystemBundle => {
  return {
    blind_carbon_copy_email: values.blind_carbon_copy_email ? values.blind_carbon_copy_email : '',
    hide_invoice_group_cancelled: !!values.hide_invoice_group_cancelled.length,
    boleto_reminder: values.boleto_reminder.includes('boleto_reminder'),
    boleto_reminder_on_due_date: values.boleto_reminder.includes('boleto_reminder_on_due_date'),
    boleto_reminder_three_days_before_due_date: values.boleto_reminder.includes('boleto_reminder_three_days_before_due_date'),
    boleto_reminder_five_days_before_due_date: values.boleto_reminder.includes('boleto_reminder_five_days_before_due_date'),
    expiring_subscription_plan_reminder: values.expiring_subscription_plan_reminder.includes('expiring_subscription_plan_reminder'),
    expiring_subscription_plan_reminder_15_days: values.expiring_subscription_plan_reminder.includes('expiring_subscription_plan_reminder_15_days'),
    expiring_subscription_plan_reminder_30_days: values.expiring_subscription_plan_reminder.includes('expiring_subscription_plan_reminder_30_days'),
    invoice_delivery_preference: values.customer_email.includes('invoice_delivery_preference'),
    payment_delivery_preference: values.customer_email.includes('payment_delivery_preference'),
    receipt_delivery_preference: values.customer_email.includes('receipt_delivery_preference')
  }
}

const patchSystemConfiguration = async (values: SystemToDisplayType): Promise<any> => {
  try {
    const response = await api.patch('/api-cashone/settings/system', bundleSystem(values))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatSystemToDisplay = (system: SystemType): SystemToDisplayType => {
  const formattedSystem: any = {
    boleto_reminder: [],
    expiring_subscription_plan_reminder: [],
    hide_invoice_group_cancelled: [],
    blind_carbon_copy_email: '',
    customer_email: []
  }

  system.forEach((parameter) => {
    if (parameter.key.includes('boleto_reminder')) {
      if (parameter.value === '1')
        formattedSystem.boleto_reminder.push(parameter.key)
    }

    else if (parameter.key.includes('expiring_subscription_plan_reminder')) {
      if (parameter.value === '1')
        formattedSystem.expiring_subscription_plan_reminder.push(parameter.key)
    }

    else if (parameter.key.includes('hide_invoice_group_cancelled')) {
      if (parameter.value === '1')
        formattedSystem.hide_invoice_group_cancelled.push('1')
    }
      
    else if (parameter.key.includes('invoice_delivery_preference') ||
      parameter.key.includes('payment_delivery_preference') ||
      parameter.key.includes('receipt_delivery_preference')) {
      if (parameter.value === 'EMAIL' || parameter.value === 'BOTH')
        formattedSystem.customer_email.push(parameter.key)
    }

    else
      formattedSystem[parameter.key] = parameter.value;
  });

  return formattedSystem;
}

export { getSystemConfiguration, formatSystemToDisplay, patchSystemConfiguration }

export type { SystemToDisplayType, SystemType }