import { useState } from "react";

type ViewModelPropTypes = {
  url: string
}

export default function DetailAttachmentViewModel({ url }: ViewModelPropTypes) {
  const [isTooltipOpen, setTooltipOpen] = useState(false)

  const handleOpen = async () => {
    await navigator.clipboard.writeText(url)
    setTooltipOpen(true)
  }
  const handleClose = () => {
    setTooltipOpen(false)
  }

  return { isTooltipOpen, handleClose, handleOpen }
}