import {apiCheckout, CustomCheckoutError } from "../../../../utils/axios";

type OrderType = {
    hash: string,
    status: string,
    payment_description?: string
}

type OrderTypeToDisplay = {
    status: string,
    boleto_url?: string
    credit_card_description?: string,
    pix_qr_code?: string
}

const parseOrder = (data: any): OrderType => {
    return {
        hash: data.hash,
        status: data.status,
        payment_description: data.payment_description
    };
}

const getOrder = async (orderHash: string, signal: AbortSignal): Promise<any> => {
    try {
        const response = await apiCheckout.get('/orders/' + orderHash, { signal });
        return parseOrder(response.data);

    } catch (error) {
        const errorResponse = (error as CustomCheckoutError).errors
        if (errorResponse)
            return { errors: errorResponse };

        return { errors: [] };
    }
}

const formatOrderToDisplay = (input: OrderType): OrderTypeToDisplay => {

    let status = input.status
    switch (input.status) {
        case 'PENDING_PAYMENT' : status = 'PAGAMENTO PENDENTE'; break;
        case 'PROCESSING' : status = 'PROCESSANDO'; break;
        case 'RECEIVED' : status = 'PEDIDO RECEBIDO'; break;
        case 'ERROR' : status = 'ERRO'; break;
        case 'COMPLETED' : status = 'COMPLETO'; break;
    }

    let response :OrderTypeToDisplay = {
        status: status,
        boleto_url: undefined,
        credit_card_description: undefined,
        pix_qr_code: undefined
    }

    if (input.payment_description?.startsWith('https://')) {
        response.boleto_url = input.payment_description
    } else if (/^[0-9]{10}.+$/.test(input?.payment_description ?? '')) {
        response.pix_qr_code = input.payment_description
    } else {
        response.credit_card_description = input.payment_description
    }

    return response
}

export {getOrder, formatOrderToDisplay}

export type {OrderType, OrderTypeToDisplay}