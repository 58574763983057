import { useEffect, useState } from 'react';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';

import { formatSystemToDisplay, getSystemConfiguration, patchSystemConfiguration, SystemToDisplayType, SystemType } from './services';
import { useNavigate } from 'react-router-dom';

export default function SystemViewModel() {
  const [system, setSystem] = useState<SystemType>()
  const [formattedSystem, setFormattedSystem] = useState<SystemToDisplayType>()

  const [systemLoading, setSystemLoading] = useState(true);
  const [systemLoadingError, setSystemLoadingError] = useState(false);

  const [editError, setEditError] = useState(false)
  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate()

  const onGetSystemConfigurationHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setSystemLoadingError(true)

      setSystem([]);
      setFormattedSystem({} as any)
    } else {
      setSystemLoadingError(true)

      setSystem([]);
      setFormattedSystem({} as any)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setSystemLoading(true);
      setSystemLoadingError(false)

      const response = await getSystemConfiguration(abortController.signal);

      if (response.errors) {
        onGetSystemConfigurationHandleError(response.errors)
      } else {
        setSystemLoadingError(false)
        setSystem(response.system);
        setFormattedSystem(formatSystemToDisplay(response.system))
      }

      setSystemLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }
  }, [])


  const onPatchSystemConfigurationHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setSystemLoading(true)
    const response = await patchSystemConfiguration(values as SystemToDisplayType)

    if (response.errors)
      onPatchSystemConfigurationHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)
    }

    setSystemLoading(false);
  }

  const onCancel = () => {
    navigate('/configurations')
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return {
    editError,
    formError,
    openSnackbar,
    formattedSystem,
    system,
    systemLoading,
    systemLoadingError,
    handleSubmit,
    onCancel,
    onCloseSnackbar,
  }
}