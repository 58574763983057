import { useEffect, useState } from 'react'

import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

import { getDescription, patchDescription } from './services'

type InvoicesReceiptsType = {
  id: string,
}

type ViewModelPropTypes = {
  invoicesReceiptsData: InvoicesReceiptsType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function ChangeDescriptionARViewModel({ invoicesReceiptsData, open, onClose, onSubmit }: ViewModelPropTypes) {
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceError, setInvoiceError] = useState(false)
  const [getError, setGetError] = useState(false)

  const [initialDescription, setInitialDescription] = useState('')

  const [openSnackbar, setOpenSnackbar] = useState(false);


  const onGetDescriptionHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setGetError(true)
    } else {
      setGetError(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setInvoiceLoading(true);

      if (open && invoicesReceiptsData.id) {
        const response = await getDescription(invoicesReceiptsData.id, abortController.signal);

        if (response.errors)
          onGetDescriptionHandleError(response.errors)

        else if (!abortController.signal.aborted) {
          setInitialDescription(response)
        }
      }

      setInvoiceLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [invoicesReceiptsData.id, open])

  const onPatchDescriptionHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setInvoiceError(true)
      setOpenSnackbar(true)
    } else {
      setInvoiceError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setInvoiceLoading(true)

    const response = await patchDescription(invoicesReceiptsData.id, values)

    if (response.errors)
      onPatchDescriptionHandleError(response.errors)

    else {
      setInvoiceError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setInvoiceLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    getError,
    initialDescription,
    invoiceError,
    invoiceLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { InvoicesReceiptsType }