import { useEffect, useMemo, useState } from 'react';

import { ClientsType, TagsType } from '../../../pages/Clients/services';

type ViewModelParamsTypes = {
  clients: ClientsType[],
  clientsSelected: number[],
  tags: TagsType[],
  onSubmitEdit: (addTags: string[], removeTags: string[]) => Promise<void>
}

type TagsFromClientsType = {
  [Identifier: string]: number
}

export default function ClientsTagsViewModel({ clients, clientsSelected, tags, onSubmitEdit }: ViewModelParamsTypes) {
  const [isOpen, setOpen] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [tagsFromClients, setTagsFromClients] = useState<TagsFromClientsType>({});

  const initialTagsFromClients = useMemo(() => {
    const clientsTags: any = {};

    for (const index of clientsSelected) {
      for (const tag of clients[index].tags) {
        if (clientsTags[tag])
          clientsTags[tag]++;
        else
          clientsTags[tag] = 1;
      }
    }

    return clientsTags;
  }, [clients, clientsSelected])

  useEffect(() => {
    setTagsFromClients({ ...initialTagsFromClients })
  }, [initialTagsFromClients])

  const hasChanges = () => {
    const keys = Object.keys(tagsFromClients);

    for (const key of keys) {
      if (!tagsFromClients[key] && !initialTagsFromClients[key])
        continue

      if (tagsFromClients[key] !== initialTagsFromClients[key])
        return true
    }

    return false
  }

  const onButtonClick = () => {
    setOpen(!isOpen);
  }

  const onChangeCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  }

  const onChangeToChecked = (tag: TagsType) => {
    if (tagsFromClients[tag.label]) {
      tagsFromClients[tag.label] = clientsSelected.length;
    }
    else {
      if (initialTagsFromClients[tag.label] > 0
        && initialTagsFromClients[tag.label] < clientsSelected.length) {
        tagsFromClients[tag.label] = initialTagsFromClients[tag.label]
      }
      else {
        tagsFromClients[tag.label] = clientsSelected.length;
      }
    }
    setTagsFromClients({ ...tagsFromClients })
  }

  const onChangeToEmpty = (tag: TagsType) => {
    tagsFromClients[tag.label] = 0;
    setTagsFromClients({ ...tagsFromClients });
  }

  const onCheckboxChange = (value: boolean, tag: TagsType) => {
    if (value) {
      onChangeToChecked(tag)
    } else {
      onChangeToEmpty(tag);
    }
  }

  const onSubmitEditHandler = async () => {
    setOpen(false);

    const addTags = [] as string[];
    const removeTags = [] as string[];

    for (const tag of tags) {
      if (initialTagsFromClients[tag.label] !== tagsFromClients[tag.label]) {
        if (tagsFromClients[tag.label] === 0)
          removeTags.push(tag.value)
        else
          addTags.push(tag.value)
      }
    }

    await onSubmitEdit(addTags, removeTags);
  }

  return {
    initialTagsFromClients,
    isOpen,
    showCreateModal,
    tagsFromClients,
    hasChanges,
    onButtonClick,
    onChangeCreateModal,
    onChangeToChecked,
    onChangeToEmpty,
    onCheckboxChange,
    onSubmitEditHandler,
  }
}