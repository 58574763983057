import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton } from '@mui/material';

import { TableHead } from '../Table/TableHead';
import { TableCell } from '../Table/TableCell';

import '../styles.scss'

type TablePropTypes = {
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  isLoading: boolean,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function EmailListTable({
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rows,
  rowsPerPage,
  total,
  isLoading,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {
  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAsc={isAsc}
          isAnySelected={false}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>
          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headCells.map((cell, index) => (
                <TableCell key={index}><Skeleton /></TableCell>
              ))}
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {
                headCells.map((cell, index) => {

                  if (cell.id === 'status') {

                    let statusClass = ''
                    if (row[cell.id] === 'PROCESSANDO') {
                      statusClass = 'label-status-blue'
                    } else if (row[cell.id] === 'ENVIADO') {
                      statusClass = 'label-status-green'
                    } else if (row[cell.id] === 'ABERTO') {
                      statusClass = 'label-status-green'
                    } else if (row[cell.id] === 'ENVIANDO') {
                      statusClass = 'label-status-yellow'
                    } else if (row[cell.id] === 'ERRO') {
                      statusClass = 'label-status-red'
                    }

                    return (
                      <TableCell className='table-cell' key={index} align='left'>
                        <span className={`label-status ${statusClass}`}>
                          {row[cell.id]}
                        </span>
                      </TableCell>
                    )

                  } else {
                    return (
                      <TableCell className='table-cell' key={index} align='left'>
                        {row[cell.id]}
                      </TableCell>
                    )
                  }
                })
              }
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer >
  );
}

export { EmailListTable };