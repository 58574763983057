import { useLocation, useNavigate } from 'react-router';

export default function SidebarViewModel() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = (url: string) => {
    navigate(url);
  }

  const registerSubItems = [
    { title: 'Clientes', url: '/clients' },
    { title: 'Fornecedores', url: '/suppliers' },
    { title: 'Produtos', url: '/products' },
  ]

  const billingSubItems = [
    { title: 'Vendas', url: '/sales' },
    { title: 'Faturas', url: '/invoices-groups' },
    { title: 'Contas a receber', url: '/accounts-receivables' },
    { title: 'Notas fiscais', url: '/invoices-receipts' },
    { title: 'Extratos', url: '/cashier-statements' },
  ]

  return { billingSubItems, currentUrl: location.pathname, registerSubItems, onClick };
}