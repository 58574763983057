import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { SalePayloadType } from "recoil/createSale/states";
import { CustomError } from "utils/CustomError";
import { api } from "utils/axios";
import { formatMoney, parseToFloat } from "utils/moneyFormatt";
import { formatPercent } from 'utils/percentFormat';

type CouponOption = {
  value: {
    coupon_id: string,
    coupon_name: string
    coupon_type: string
    coupon_value: number
  },
  label: string
}

const bundlePayloadToRecoil = (values: Record<string, any>): SalePayloadType => {
  let couponData;
  if (values.coupon_option) {
    couponData = {
      coupon_id: values.coupon_option.value.coupon_id,
      coupon_type: values.coupon_option.value.coupon_type,
      coupon_value: values.coupon_option.value.coupon_value,
      coupon_name: values.coupon_option.value.coupon_name,
    }
  }

  return {
    payment_terms: {
      first_payment_term_type: values.payment_terms.first_payment_term_type,
      first_payment_term_value: values.payment_terms.first_payment_term_value ?
        parseInt(values.payment_terms.first_payment_term_value) :
        undefined,
      payment_term_type: values.payment_terms.payment_term_type,
      payment_term_value: values.payment_terms.payment_term_value ?
        parseInt(values.payment_terms.payment_term_value) :
        undefined,
    },
    taxes: {
      withhold: values.taxes.withhold?.length ? true : false,
      rate: values.taxes.rate ? parseToFloat(values.taxes.rate) / 100 : undefined,
      withhold_in_receipt: values.taxes.withhold_in_receipt?.length ? true : false,
      rate_in_receipt: values.taxes.rate_in_receipt ? parseToFloat(values.taxes.rate_in_receipt) / 100 : undefined,
    },
    plan_value: values.plan_value ? parseToFloat(values.plan_value) : undefined,
    product_value: values.product_value ? parseToFloat(values.product_value) : undefined,
    create_receipt: values.create_receipt?.length ? true : false,
    auto_receipt_run: values.auto_receipt_run === '1' ? true : false,
    description: values.description,
    start_date: values.start_date,
    initial_term: values.initial_term ? parseInt(values.initial_term) : undefined,
    freq: values.freq,
    auto_renewal: values.auto_renewal?.length ? true : false,
    installments: values.installments ? values.installments : undefined,
    has_installments: values.has_installments === '1' ? true : false,
    ...couponData,
  }
}

const formatSalePayloadToDisplay = (values: SalePayloadType) => {

  let response: any = {
    payment_terms: {},
  }

  response.product_value = formatMoney(values.product_value ?? 0)
  response.plan_value = formatMoney(values.plan_value ?? 0)
  response.description = values.description
  response.freq = values.freq
  response.initial_term = values.initial_term
  response.auto_renewal = values.auto_renewal ? ['1'] : []
  response.start_date = values.start_date ? values.start_date : dayjs()

  if (values.payment_terms) {
    response.payment_terms = {
      payment_term_type: values.payment_terms?.payment_term_type,
      payment_term_value: values.payment_terms?.payment_term_value?.toString(),
      first_payment_term_type: values.payment_terms?.first_payment_term_type,
      first_payment_term_value: values.payment_terms?.first_payment_term_value?.toString()
    }

    if (values.payment_terms.payment_term_type && values.payment_terms.payment_term_value === undefined)
      response.payment_terms.payment_term_value = '0'

    if (values.payment_terms.first_payment_term_type && values.payment_terms.first_payment_term_value === undefined)
      response.payment_terms.first_payment_term_value = '0'
  }

  if (values.coupon_id) {
    response.coupon_option = {
      label: values.coupon_name,
      value: {
        coupon_id: values.coupon_id,
        coupon_name: values.coupon_name,
        coupon_value: values.coupon_value,
        coupon_type: values.coupon_type
      }
    }
  }

  // Initial values, if they're empty
  if (values.has_installments === undefined)
    response.has_installments = '0';
  else
    response.has_installments = values.has_installments ? '1' : '0';
  if (values.auto_receipt_run === undefined)
    response.auto_receipt_run = '1';
  else
    response.auto_receipt_run = values.auto_receipt_run ? '1' : '0'

  if (values.create_receipt === undefined)
    response.create_receipt = ['1']
  else
    response.create_receipt = values.create_receipt ? ['1'] : [];

  if (!values.payment_terms?.payment_term_type) {
    response.payment_terms.payment_term_type = 'NET'
  }
  if (typeof values.payment_terms?.payment_term_value !== 'number') {
    response.payment_terms.payment_term_value = '5'
  }
  if (!values.payment_terms?.first_payment_term_type) {
    response.payment_terms.first_payment_term_type = response.payment_terms.payment_term_type
  }
  if (typeof values.payment_terms?.first_payment_term_value !== 'number') {
    response.payment_terms.first_payment_term_value = response.payment_terms.payment_term_value
  }

  if (values.taxes) {
    response.taxes = {
      withhold: values.taxes?.withhold ? ['1'] : [],
      withhold_in_receipt: values.taxes?.withhold_in_receipt ? ['1'] : []
    }

    if (values.taxes.rate)
      response.taxes.rate = formatPercent(values.taxes?.rate ?? 0)

    if (values.taxes.rate_in_receipt)
      response.taxes.rate_in_receipt = formatPercent(values.taxes?.rate_in_receipt ?? 0)
  }

  if (values.installments)
    response.installments = values.installments
  else
    response.installments = []

  return response
}

const parseCoupons = async (response: { data: any[] }) => {
  return response.data.map(data => ({
    value: {
      coupon_id: data.id,
      coupon_name: data.attributes.name,
      coupon_type: data.attributes.type,
      coupon_value: data.attributes.value
    },
    label: data.attributes.name
  }))
}

const getCoupons = async (search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {
    q: search
  };

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/coupons', {
      params,
      signal
    });

    return parseCoupons(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatNewCouponToDisplay = (response: AxiosResponse | CustomError) => {
  if ((response as CustomError).errors)
    return null

  else
    return {
      value: {
        coupon_id: (response as AxiosResponse).data.data.id,
        coupon_name: (response as AxiosResponse).data.data.attributes.name,
        coupon_type: (response as AxiosResponse).data.data.attributes.type,
        coupon_value: (response as AxiosResponse).data.data.attributes.value
      },
      label: (response as AxiosResponse).data.data.attributes.name
    }
}

export { bundlePayloadToRecoil, formatSalePayloadToDisplay, getCoupons, formatNewCouponToDisplay }

export type { CouponOption }