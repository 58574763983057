import { useEffect, useState } from 'react';

import { PlanType, PlanTypeToDisplay, getPlan, formatPlanToDisplay } from './services';
import { notAuthenticatedRedirect } from 'utils/auth';
import {useParams} from "react-router-dom";
import {ApiError} from "../../../../../../../../utils/CustomError";

export default function ViewPlanViewModel() {

  const [plansLoading, setPlanLoading] = useState(true)
  const [plansLoadingError, setPlanLoadingError] = useState(false)

  const [plan, setPlan] = useState<PlanType | undefined>(undefined);
  const [formattedPlan, setFormattedPlan] = useState<PlanTypeToDisplay | undefined>(undefined);
  const [tabSelected, setTabSelected] = useState<number>(0);
  const params = useParams();

  const onTabChange = (value: number) => {
    setTabSelected(value);
  }

  const onGetPlanHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setPlanLoadingError(true)
      setPlan(undefined);
    } else {
      setPlanLoadingError(true)
      setPlan(undefined);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setPlanLoading(true);

      const response = await getPlan(params?.product ?? '', params?.id ?? '', abortController.signal);

      if (response.errors) {
        onGetPlanHandleError(response.errors)
      } else {
        setPlan(response)
      }

      setPlanLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [params])

  useEffect(() => {
    if (plan)
      setFormattedPlan(formatPlanToDisplay(plan))
  }, [plan])

  return {
    plan,
    tabSelected,
    plansLoading,
    plansLoadingError,
    formattedPlan,
    onTabChange
  }
}