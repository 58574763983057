import { Skeleton, Typography } from "@mui/material";

import './styles.scss'

type DisplayInfoTextPropTypes = {
  title: string,
  text: string,
  isLoading?: boolean
}

function DisplayInfoText({ title, text, isLoading = false }: DisplayInfoTextPropTypes) {
  return (
    <div className='display-info-text'>
      <div className='title'>
        <Typography variant='caption'>
          {title}
        </Typography>
      </div>
      <div className='text'>
        <Typography fontWeight={700}>
          {isLoading ? (
            <Skeleton />
          ) : text}
        </Typography>
      </div>
    </div>
  );
}

export { DisplayInfoText }