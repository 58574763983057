import { useEffect, useState } from 'react'

import { AccountReceivablePayload, postAccountReceivable, ClientType, getClient } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type AccountsReceivablesType = {
  id: string,
  key: string,
  customer_id: string
}

type ViewModelPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CreateAccountReceivableModalViewModel({ open, accountReceivableData, onClose, onSubmit }: ViewModelPropTypes) {

  const [client, setClient] = useState<ClientType>()

  const [accountReceivableLoading, setAccountReceivableLoading] = useState(false)
  const [accountReceivableError, setAccountReceivableError] = useState(false)

  const [clientLoading, setClientLoading] = useState(true)
  const [clientLoadingError, setClientLoadingError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onGetClientHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientLoadingError(true)
      setClient(undefined);
    } else {
      setClientLoadingError(true)
      setClient(undefined);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setClientLoading(true);

      if (accountReceivableData.customer_id && open) {
        const response = await getClient(accountReceivableData.customer_id, abortController.signal);

        if (response.errors) {
          onGetClientHandleError(response.errors)
        } else {
          setClient(response)
        }
      }

      setClientLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [accountReceivableData, open])

  const onPostAccountReceivableHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setAccountReceivableError(true)
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setAccountReceivableError(true)
      setOpenSnackbar(true)
    } else {
      setAccountReceivableError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setAccountReceivableLoading(true)
    const response = await postAccountReceivable(accountReceivableData.id, values as AccountReceivablePayload)

    if (response.errors)
      onPostAccountReceivableHandleError(response.errors)

    else {
      setAccountReceivableError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setAccountReceivableLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.emails || values.emails.length === 0) {
      errors.emails = 'Obrigatório'
    } else {
      values.emails.forEach((email: string) => {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
          errors.emails = 'Existem emails inválidos'
      })
    }

    return errors
  }

  return {
    accountReceivableLoading,
    accountReceivableError,
    clientLoading,
    clientLoadingError,
    formError,
    openSnackbar,
    client,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}

export type { AccountsReceivablesType }