import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, Skeleton } from '@mui/material';
import { FieldValidator } from 'final-form';
import { Field, FieldRenderProps } from 'react-final-form';

type SelectFieldAdapterTypes = {
  name: string,
  label: string,
  loading?: boolean,
  options: Array<{
    value: string,
    label: string
  }>,
  onChange?: (e: SelectChangeEvent) => void,
  validate?: FieldValidator<any>,
} & Omit<SelectProps, 'onChange'>

const componentField = ({ input, meta, ...rest }: FieldRenderProps<any>) => (
  <FormControl
    error={meta.touched && meta.error ? true : false}
    fullWidth
  >
    <InputLabel>{rest.label}</InputLabel>
    <Select
      {...input}
      {...rest}
      error={meta.touched && meta.error ? true : false}
      MenuProps={{ style: { maxHeight: 300 } }}
      onChange={(e: SelectChangeEvent) => {
        input.onChange(e);
        if (rest.onChange)
          rest.onChange(e);
      }}
    >
      {rest.options.map((option: { value: string, label: string }, index: number) => (
        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
      ))}
    </Select>
    <FormHelperText className='helper-text'>{meta.touched ? meta.error : ''}</FormHelperText>
  </FormControl>
);

function SelectFieldAdapter({ name, label, loading, options, onChange, validate, ...rest }: SelectFieldAdapterTypes) {
  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          name={name}
          label={label}
          options={options}
          validate={validate}
          component={componentField}
          onChange={onChange}
          {...rest}
        />
      )}
    </div>
  )
}

export { SelectFieldAdapter };