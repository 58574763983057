import { Box, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import { ProductSpotGeneralView } from './pages/General';

import useViewModel from './viewModel'

import './styles.scss'
import {ProductCheckoutView} from "./pages/Checkout";
import {ProductType} from "./pages/Checkout/services";

function ProductSpotView() {
  const {
    productLoading,
    productLoadingError,
    product,
    tabSelected,
    onTabChange
  } = useViewModel();

  return (
      <div className='product-spot-view'>

          <div className='view-header'>
              <div className='view-title'>

                  {!productLoading ? (

                      <Typography variant='h3' fontWeight={700}>
                          {product?.name}
                      </Typography>

                  ) : (
                      <Skeleton/>
                  )}
              </div>
          </div>

          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <Tabs
                  value={tabSelected}
                  onChange={(event, value) => {
                      onTabChange(value)
                  }}
              >

                  <Tab
                      label='Visão Geral'
                      id='general'
                      aria-controls='tabpanel-general'
                  />

                  {product?.checkout_product_hash && <Tab
                      label='Checkout'
                      id='checkout'
                      aria-controls='tabpanel-checkout'
                  />}

              </Tabs>
          </Box>

          <div
              role='tabpanel'
              hidden={tabSelected !== 0}
              id={'tabpanel-general'}
              aria-labelledby={'general'}
          >
              <ProductSpotGeneralView
                  product={product}
                  loading={productLoading}
                  loadingError={productLoadingError}
              />
          </div>

          <div
              role='tabpanel'
              hidden={tabSelected !== 1}
              id={'tabpanel-checkout'}
              aria-labelledby={'checkout'}
          >
              <ProductCheckoutView
                  productData={product as ProductType}
              />
          </div>

      </div>
  );
}

export {ProductSpotView}