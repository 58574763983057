import { formatMoney } from 'utils/moneyFormatt';

type PlanType = {
  id: string,
  name: string,
  hash: string,
  freq: string,
  initial_term: number,
  value: number,
  payment_term_type: string,
  payment_term_value: number
}

type PlanDisplayType = {
  id: string,
  name: string,
  freq: string,
  initial_term: string,
  value: string
  payment_term: string
}

const formatPlanToDisplay = (plan: PlanType): PlanDisplayType => {
  const translateFreq = {
    'YEARLY': 'Por ano',
    'SEMIANNUAL': 'Por semestre',
    'QUARTERLY': 'Por trimestre',
    'MONTHLY': 'Por mês',
  }

  type FreqEnum = keyof typeof translateFreq;

  let payment_term = ''
  if (plan.payment_term_type === 'FIXED')
    payment_term = `Todo dia ${plan.payment_term_value}`
  else
    payment_term = `Até ${plan.payment_term_value} dias após a cobrança`

  return {
    id: plan.id,
    name: plan.name,
    freq: translateFreq[plan.freq as FreqEnum],
    initial_term: `${plan.initial_term} meses`,
    value: formatMoney(plan.value),
    payment_term: payment_term
  }
}



export { formatPlanToDisplay };

export type { PlanType, PlanDisplayType }