import { useState } from 'react';

import { useOpenedFilters } from '../../../recoil/openedFilters/actions';

type OptionTypes = {
  label: string,
  value: string,
}

type ViewModelParamsTypes = {
  id: string,
  initialSelected?: Array<OptionTypes>,
  search: string,
}

export default function SearchCheckboxFilterViewModel({ id, initialSelected, search }: ViewModelParamsTypes) {
  const [optionsSelected, setOptionsSelected] = useState<Array<OptionTypes>>(initialSelected ? initialSelected : []);
  const [searchString, setSearchString] = useState<string>(search);

  const { openedFilter, onCloseFilter, onOpenFilter } = useOpenedFilters();

  const isOpen = openedFilter === id;

  const onClick = () => {
    if (isOpen)
      onCloseFilter();
    else
      onOpenFilter(id)
  };

  const onChange = (target: OptionTypes) => {
    if (optionsSelected.find(option => option.value === target.value))
      setOptionsSelected(optionsSelected.filter(option => option.value !== target.value))

    else {
      optionsSelected.push(target);
      setOptionsSelected([...optionsSelected]);
    }
  };

  const onTextFieldChange = (value: string) => {
    setSearchString(value);
  }

  const onClearFilter = () => {
    setOptionsSelected([]);
  }

  return {
    isOpen,
    optionsSelected,
    searchString,
    openedFilter,
    onChange,
    onClearFilter,
    onClick,
    onTextFieldChange,
    onCloseFilter,
    onOpenFilter
  };
}

export type { OptionTypes };