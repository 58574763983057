import { useEffect, useState } from 'react'

import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

import { PlanToDisplayType, PlanType, getPlan, formatPlanToDisplay, patchPlan } from './services'
import { ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  planId: string,
  productId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EditPlanModalViewModel({ planId, productId, onClose, onSubmit }: ViewModelPropTypes) {

  const [plan, setPlan] = useState<PlanType>()
  const [formattedPlan, setFormattedPlan] = useState<PlanToDisplayType>()

  const [planLoading, setPlanLoading] = useState(true)
  const [planLoadingError, setPlanLoadingError] = useState(false)

  const [editError, setEditError] = useState(false)
  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const freqOptions = [
    { label: 'Por mês', value: 'MONTHLY' },
    { label: 'Trimestral', value: 'QUARTERLY' },
    { label: 'Semestral', value: 'SEMIANNUAL' },
    { label: 'Anual', value: 'YEARLY' },
  ]

  const paymentTermTypeOptions = [
    { label: 'Dia fixo do mês', value: 'FIXED' },
    { label: 'Dias após emissão', value: 'NET' },
  ]

  const paymentTermValueOptions = [...Array(28)].map((value, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
  paymentTermValueOptions.unshift({ label: 'Último dia do mês', value: '0' })

  const onGetPlanHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setPlanLoadingError(true)
    } else {
      setPlanLoadingError(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setPlanLoading(true);

      if (planId && productId) {

        const response = await getPlan(planId, productId, abortController.signal);

        if (response.errors) {
          onGetPlanHandleError(response.errors)
        }

        else {
          setPlan(response)
          setFormattedPlan(formatPlanToDisplay(response))
          setPlanLoadingError(false)
        }
      }

      setPlanLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [planId, productId])

  const onPatchPlanHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setPlanLoading(true)
    const response = await patchPlan(planId, productId, values as PlanToDisplayType)

    if (response.errors)
      onPatchPlanHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setPlanLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.freq)
      errors.freq = 'Obrigatório'

    if (!values.initial_term)
      errors.initial_term = 'Obrigatório'

    if (values.initial_term < 1)
      errors.initial_term = 'Termo de contrato deve ser maior que 1 mês'

    if (!values.payment_term_type)
      errors.payment_term_type = 'Obrigatório'

    if (!values.payment_term_value)
      errors.payment_term_value = 'Obrigatório'

    if (values.payment_term_value < 0)
      errors.payment_term_value = 'Dia não é válido'

    if (!values.value)
      errors.value = 'Obrigatório'

    return errors
  }


  return {
    editError,
    formattedPlan,
    formError,
    freqOptions,
    openSnackbar,
    plan,
    planLoading,
    planLoadingError,
    paymentTermTypeOptions,
    paymentTermValueOptions,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  }
}