import { useState, useEffect } from "react";
import dayjs from 'dayjs';

import { parseToFloat } from 'utils/moneyFormatt'
import { formatToDisplay } from "./services";

export default function InstallmentsViewModel() {

  const [installmentList, setInstallmentList] = useState<any[]>([])
  const [formattedInstallmentList, setFormattedInstallmentList] = useState<string[]>([])

  const [creatingNewInstallment, setCreatingNewInstallment] = useState(false)
  const [newInstallmentValue, setNewInstallmentValue] = useState<number | null>(null)
  const [newInstallmentDate, setNewInstallmentDate] = useState<dayjs.Dayjs | null>(null)

  useEffect(() => {
  }, [installmentList])

  const onChange = () => {
    setInstallmentList([...installmentList, { total_value: newInstallmentValue, date: newInstallmentDate }])
    setFormattedInstallmentList(formatToDisplay([...installmentList, { total_value: newInstallmentValue, date: newInstallmentDate }]))

    setNewInstallmentValue(null)
    setNewInstallmentDate(null)
    setCreatingNewInstallment(false)
    return [...installmentList, { total_value: newInstallmentValue, date: newInstallmentDate }];
  }

  const onStartCreatingNewInstallment =() => {
    setCreatingNewInstallment(true)
  }

  const onChangeTotalValueNewInstallment = (totalValue: any) => {
    setNewInstallmentValue(parseToFloat(totalValue))
  }

  const onChangeDateNewInstallment = (date: any) => {
    setNewInstallmentDate(date)
  }

  const onDeleteInstallment = (index: number) => {
    var copyInstallmentList = [...installmentList];
    copyInstallmentList.splice(index, 1);

    setInstallmentList(copyInstallmentList);
    setFormattedInstallmentList(formatToDisplay(copyInstallmentList))

    return copyInstallmentList;
  }

  return {
    installmentList,
    formattedInstallmentList,
    newInstallmentValue,
    newInstallmentDate,
    creatingNewInstallment,
    onChange,
    onDeleteInstallment,
    onChangeTotalValueNewInstallment,
    onChangeDateNewInstallment,
    onStartCreatingNewInstallment,
    formatToDisplay
  }
}