import { CustomError, api } from 'utils/axios';


type DetailsType = {
  id: string,
  name: string,
  document: string,
  status: string,
  emails: string[],
  phones: string[],
  description: string,
  address?: {
    street: string,
    number: string,
    complement?: string,
    neighbourhood: string,
    city: string,
    postal_code: string,
    state_abbreviation: string,
    country: string,
  }
}

type DetailsDisplayType = {
  id: string,
  name: string,
  document: string,
  status: string,
  emails: string,
  phones: string,
  description: string,
  address?: {
    street: string,
    number: string,
    complement?: string,
    neighbourhood: string,
    city: string,
    postal_code: string,
    state_abbreviation: string,
    country: string,
  }
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    name: response.data.attributes.name,
    document: response.data.attributes.document,
    status: response.data.attributes.status,
    emails: response.data.attributes.emails,
    phones: response.data.attributes.phones,
    description: response.data.attributes.description,
    address: response.data.attributes.address
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/suppliers/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  let address = undefined

  if (details.address) {
    address = {
      ...details.address,
      city: details.address.state_abbreviation ? `${details.address.city} / ${details.address.state_abbreviation}` : details.address.city,
      complement: details.address.complement ? details.address.complement : 'N/A',
    }
  } else {
    address = {
      street: 'N/A',
      number: 'N/A',
      complement: 'N/A',
      neighbourhood: 'N/A',
      city: 'N/A',
      postal_code: 'N/A',
      state_abbreviation: 'N/A',
      country: 'N/A',
    }
  }

  return {
    id: details.id,
    name: details.name,
    document: details.document,
    status: details.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
    emails: details.emails.length ? details.emails.join(', ') : 'N/A',
    phones: details.phones.length ? details.phones.join(', ') : 'N/A',
    description: details.description,
    address: address
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }