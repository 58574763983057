import { ReactNode } from 'react';
import './styles.scss';

type FlexboxPropTypes = {
  children: ReactNode,
  size: number,
  column?: boolean
}

function Flexbox({ size, column = false, children }: FlexboxPropTypes) {
  return (
    <div className={`flexbox-container size-${size} ${column ? 'column' : ''}`}>
      {children}
    </div>
  )
}

export { Flexbox };