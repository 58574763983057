const formatMoney = (value: number, currency: string = 'BRL'): string => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: currency,
    });

  return formatter.format(value)
}

const parseToFloat = (value: string) => {
  const newValue = value.replaceAll('.', '').replaceAll(',', '.')
  return parseFloat(newValue)
}

const parseToString = (value: number) => {
  return value.toString().replaceAll('.', ',')
}

export { formatMoney, parseToFloat, parseToString };