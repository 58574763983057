import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { AccountsReceivablesDisplayType, AccountsReceivablesType, SortType, formatARsToDisplay, getARs } from './services';
import { ApiError } from 'utils/CustomError';

type ViewModelProps = {
  clientId: string
}

export default function AccountsReceivablesViewModel({ clientId }: ViewModelProps) {
  const [accountsReceivables, setAccountsReceivables] = useState<Array<AccountsReceivablesType>>();
  const [formattedARs, setFormattedARs] = useState<Array<AccountsReceivablesDisplayType>>();

  const [ARsLoading, setARsLoading] = useState(true);
  const [ARsLoadingError, setARsLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // totals
  const [totalARs, setTotalARs] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'total', label: 'Cobrança' },
    { id: 'due_date', label: 'Vencimento' },
    { id: 'net_value', label: 'Valor transferido' },
    { id: 'transfer_date', label: 'Data da transferência' },
    { id: 'status', label: 'Status' },
  ]


  const onGetARsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setARsLoadingError(true)

      setAccountsReceivables([]);
      setFormattedARs([])
      setTotalARs(0);
    } else {
      setARsLoadingError(true)

      setAccountsReceivables([]);
      setFormattedARs([])
      setTotalARs(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setARsLoading(true);

      const response = await getARs(clientId, sort, { page, limit: rowsPerPage }, abortController.signal);

      if (response.errors) {
        onGetARsHandleError(response.errors)
      } else {
        setAccountsReceivables(response.accountsReceivables as Array<AccountsReceivablesType>);
        setFormattedARs(formatARsToDisplay(response.accountsReceivables))

        setARsLoadingError(false)

        if (response.totals)
          setTotalARs(response.totals.totalARs)
      }

      setARsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }
  }, [clientId, page, rowsPerPage, sort]);


  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    accountsReceivables,
    ARsLoading,
    ARsLoadingError,
    formattedARs,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalARs,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  }
}