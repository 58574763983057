import { useEffect, useState } from 'react';
import { FormApi, ValidationErrors } from 'final-form';
import { AxiosResponse } from 'axios';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';

import { useCreateSaleActions } from 'recoil/createSale/actions';

import { ClientOption, PlanOption, ProductOption, bundlePayloadToRecoil, formatNewClientToDisplay, formatSalePayloadToDisplay, getClients, getPlans, getProducts } from './services';

type viewModelPropTypes = {
  type: string,
  onNext: () => void
}

export default function ChooseStepViewModel({ type, onNext }: viewModelPropTypes) {
  const { salePayload, setSalePayload } = useCreateSaleActions()

  const [formattedSalePayload, setFormattedSalePayload] = useState<any>()

  const [products, setProducts] = useState<ProductOption[]>()
  const [productsLoading, setProductsLoading] = useState(true)
  const [searchProducts, setSearchProducts] = useState<string>('')
  const [productsLoadingError, setProductsLoadingError] = useState(false)

  const [selectedProductId, setSelectedProductId] = useState<string>('')

  const [plans, setPlans] = useState<PlanOption[]>()
  const [plansLoading, setPlansLoading] = useState(false)
  const [searchPlans, setSearchPlans] = useState<string>('')
  const [plansLoadingError, setPlansLoadingError] = useState(false)

  const [clients, setClients] = useState<ClientOption[]>()
  const [clientsLoading, setClientsLoading] = useState(true)
  const [searchClients, setSearchClients] = useState<string>('')
  const [clientsLoadingError, setClientsLoadingError] = useState(false)

  const [openClientModal, setOpenClientModal] = useState(false)
  const [clientType, setClientType] = useState('')


  const onGetClientsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientsLoadingError(true)

      setClients([]);
    } else {
      setClientsLoadingError(true)

      setClients([]);
    }
  }

  const onGetPlansHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setPlansLoadingError(true)

      setPlans([]);
    } else {
      setPlansLoadingError(true)

      setPlans([]);
    }
  }

  const onGetProductsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setProductsLoadingError(true)

      setProducts([]);
    } else {
      setProductsLoadingError(true)

      setProducts([]);
    }
  }

  useEffect(() => {
    const formatted = formatSalePayloadToDisplay(salePayload)
    setFormattedSalePayload(formatted)
    setSelectedProductId(salePayload.product_id ?? '')

    if (salePayload.product_id)
      setProducts([formatted.product_option])

    if (salePayload.plan_id)
      setPlans([formatted.plan_option])

    if (salePayload.customer_id)
      setClients([formatted.client_option])

  }, [salePayload])

  useEffect(() => {
    if (salePayload.customer_id && !searchClients) {
      setClientsLoading(false);
      return
    }

    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {

        setClientsLoading(true);

        const response = await getClients(searchClients, abortController.signal)
        if (response.errors) {
          onGetClientsHandleError(response.errors)
        } else {
          setClientsLoadingError(false)
          setClients(response)
        }

        setClientsLoading(false || abortController.signal.aborted);
      })()
    }, 500)

    return () => {
      clearTimeout(debounce)
      abortController.abort()
    }
  }, [searchClients, salePayload])

  useEffect(() => {
    if (salePayload.plan_id && !searchPlans) {
      setPlansLoading(false);
      return
    }

    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      if (type === 'RECURRING' && selectedProductId) {
        (async () => {

          setPlansLoading(true);

          const response = await getPlans(selectedProductId, searchPlans, abortController.signal)

          if (response.errors) {
            onGetPlansHandleError(response.errors)
          } else {
            setProductsLoadingError(false)
            setPlans(response)
          }

          setPlansLoading(false || abortController.signal.aborted);
        })()
      }
    }, 500)

    return () => {
      clearTimeout(debounce)
      abortController.abort()
    }
  }, [searchPlans, selectedProductId, type, salePayload])

  useEffect(() => {
    if (salePayload.product_id && !searchProducts) {
      setProductsLoading(false);
      return
    }

    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {
        setProductsLoading(true);

        const response = await getProducts(searchProducts, type, abortController.signal)

        if (response.errors) {
          onGetProductsHandleError(response.errors)
        } else {
          setPlansLoadingError(false)
          setProducts(response)
        }

        setProductsLoading(false || abortController.signal.aborted);
      })()
    }, 500)

    return () => {
      clearTimeout(debounce)
      abortController.abort()
    }
  }, [searchProducts, type, salePayload])


  const onClientSearchChange = (search: string) => {
    setSearchClients(search);
  }

  const onPlanSearchChange = (search: string) => {
    setSearchPlans(search);
  }

  const onProductSearchChange = (search: string) => {
    setSearchProducts(search);
  }

  const onProductChange = (product: ProductOption, form: FormApi) => {
    if (product)
      setSelectedProductId(product.value.product_id)
    else
      setSelectedProductId('')

    if (type === 'RECURRING') {
      const field = form.getFieldState('plan_option')
      field?.change(null)
    }
  }

  const onSubmit = (values: Record<string, any>) => {
    setSalePayload({ ...salePayload, ...bundlePayloadToRecoil(values, salePayload) })
    onNext()
  }

  const onOpenClientModal = (type: string) => {
    setClientType(type)
    setOpenClientModal(true)
  }

  const onCloseClientModal = () => {
    setClientType('')
    setOpenClientModal(false)
  }

  const onNewClient = (response: AxiosResponse, form: FormApi) => {
    const newOption = formatNewClientToDisplay(response)

    if (newOption) {
      setClients([newOption])

      const field = form.getFieldState('client_option')
      field?.change(newOption)
    }
  }

  const formValidate = (values: Record<string, any>): ValidationErrors => {
    const errors: ValidationErrors = {}

    if (!values.product_option)
      errors.product_option = 'Obrigatório'

    if (type === 'RECURRING')
      if (!values.plan_option)
        errors.plan_option = 'Obrigatório'

    if (!values.client_option)
      errors.client_option = 'Obrigatório'

    return errors
  }

  return {
    clients,
    clientsLoading,
    clientsLoadingError,
    clientType,
    openClientModal,
    plans,
    plansLoading,
    plansLoadingError,
    products,
    productsLoading,
    productsLoadingError,
    salePayload,
    searchClients,
    searchPlans,
    searchProducts,
    selectedProductId,
    formattedSalePayload,
    formValidate,
    onClientSearchChange,
    onCloseClientModal,
    onNewClient,
    onPlanSearchChange,
    onProductChange,
    onProductSearchChange,
    onOpenClientModal,
    onSubmit,
  }
} 