import {api, CustomError} from 'utils/axios'
import {STATES} from 'utils/constants/states'

type CityType = {
  attributes: {
    name: string,
  },
  id: string
}

type ParsedCityType = {
  id: string,
  label: string,
}

type SupplierPayload = {
  name: string,
  document: string,
  description?: string,
  emails?: string[],
  phones?: string[],
  address: {
    street: string,
    number: string,
    complement?: string,
    neighbourhood: string,
    city: {
      id: string,
      label: string,
    } | string,
    postal_code?: string,
    state_abbreviation?: string,
    state_province?: string,
    country?: string
  }
}

type ParsedSupplierPayload = {
  name: string,
  document: string,
  description: string,
  emails: string[],
  phones: string[],
  address?: {
    street: string,
    number: string,
    complement: string,
    neighbourhood: string,
    brl_city_id: string,
    city: string,
    postal_code?: string,
    state_province: string,
    state_abbreviation: string,
    country?: string
  }
}

const bundlePayload = (payload: SupplierPayload): ParsedSupplierPayload => {
  let address = undefined

  if (payload.address?.street &&
      payload.address?.number &&
      payload.address?.neighbourhood &&
      payload.address?.postal_code &&
      payload.address?.state_abbreviation) {

    address = {
      ...payload.address,
      city: typeof payload.address?.city === 'string' ? payload.address?.city : payload.address.city.label,
      brl_city_id: typeof payload.address?.city === 'string' ? '' : payload.address?.city.id,
      state_province: payload.address?.state_province ? payload.address?.state_province : STATES[payload.address?.state_abbreviation ? payload.address.state_abbreviation : ''],
      state_abbreviation: payload.address?.state_abbreviation ? payload.address?.state_abbreviation : '',
      complement: payload.address?.complement ? payload.address?.complement : '',
      postal_code: payload.address?.postal_code ? payload.address?.postal_code : '',
    }
  }

  return {
    ...payload,
    description: payload.description ? payload.description : '',
    phones: payload.phones ? payload.phones : [],
    emails: payload.emails ? payload.emails : [],
    address: address
  }
}

const postSupplier = async (payload: SupplierPayload): Promise<any> => {
  try {
    return await api.post('/api-cashone/suppliers', bundlePayload(payload))

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const parseCities = (cities: CityType[]): ParsedCityType[] =>
  cities.map((city) => ({
    id: city.id,
    label: city.attributes.name
  }));

const getCities = async (search: string, state: string, signal: AbortSignal): Promise<ParsedCityType[] | any> => {
  try {
    const response = await api.get(`/api/taxes/brl/cities?limit=10&q=${search}&filter[uf_abbreviation]=eq:${state}`, {
      signal
    });

    return parseCities(response.data.data);
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
};

export { getCities, postSupplier }

export type { SupplierPayload }