import dayjs from 'dayjs';
import { CustomError, api } from 'utils/axios';
import { EMAIL_TYPES } from 'utils/constants/emailTypes';

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type EmailType = {
  title: string,
  status: string,
  recipient: string,
  read_at: string,
  created_at: string,
}

type EmailDisplayType = {
  title: string,
  status: string,
  recipient: string,
  read_at: string,
  created_at: string,
}

const parseEmails = async (response: { data: any, meta: any }) => {
  const emails = response.data.map((data: any) => {
    return {
      title: data.attributes.title,
      status: data.attributes.status,
      recipient: data.attributes.recipient,
      read_at: data.attributes.read_at?.date,
      created_at: data.attributes.created_at.date,
    }
  });

  return {
    emails,
    pagination: {
      totalEmails: response.meta.pagination.total
    }
  }
}

const getEmails = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get(`/api-cashone/clients/${id}/mails`, {
      params,
      signal
    });

    return parseEmails(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatEmailsToDisplay = (emails: Array<EmailType>): Array<EmailDisplayType> => {

  const translateStatus = {
    'PROCESSING': 'PROCESSANDO',
    'SENDING': 'ENVIANDO',
    'SENT': 'ENVIADO',
    'OPENED': 'ABERTO',
    'ERROR': 'ERRO',
  }

  type StatusEnum = keyof typeof translateStatus;

  return emails.map((email: EmailType) => {
    return {
      title: EMAIL_TYPES[email.title],
      status: translateStatus[email.status as StatusEnum],
      recipient: email.recipient,
      read_at: email.read_at ? dayjs(email.read_at).format('DD/MM/YYYY') : '',
      created_at: dayjs(email.created_at).format('DD/MM/YYYY'),
    }
  })

}

export { getEmails, formatEmailsToDisplay };

export type { PaginationType, SortType, EmailType, EmailDisplayType };