import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { Skeleton, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';

type TextFieldAdapterPropTypes = {
  name: string,
  label: string,
  loading?: boolean,
  onChange?: (e: ChangeEvent) => void,
  validate?: FieldValidator<any>
} & TextFieldProps;

const componentField = ({ input, meta, ...rest }: FieldRenderProps<any>) => (
  <TextField
    {...input}
    {...rest}
    error={meta.touched && meta.error ? true : false}
    FormHelperTextProps={{ className: 'helper-text' }}
    helperText={meta.touched ? meta.error : ''}
    fullWidth
    onChange={(e: ChangeEvent) => {
      input.onChange(e);
      if (rest.onChange)
        rest.onChange(e);
    }}
  />
);

function TextFieldAdapter({ name, label, loading = false, onChange, validate, ...rest }: TextFieldAdapterPropTypes) {

  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={componentField as any}
          name={name}
          label={label}
          validate={validate}
          onChange={onChange}
          {...rest}
        />
      )}
    </div>
  )
}

export { TextFieldAdapter };