import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { getCoupons, formatCouponsToDisplay, SortType, CouponsType, CouponsDisplayType, FilterType } from './services';
import { ApiError } from 'utils/CustomError';

export default function CouponsViewModel() {
  const [coupons, setCoupons] = useState<Array<CouponsType>>();
  const [formattedCoupons, setFormattedCoupons] = useState<Array<CouponsDisplayType>>();
  const [searchCoupons, setSearchCoupons] = useState<string>('');

  const [couponLoading, setCouponLoading] = useState(true);
  const [couponLoadingError, setCouponLoadingError] = useState(false);

  const [openCreateCoupon, setOpenCreateCoupon] = useState(false);

  // filters
  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: ['ACTIVE']
  });

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCoupons, setTotalCoupons] = useState(0);

  const [actionIndex, setActionIndex] = useState<number>()

  const [openEdit, setOpenEdit] = useState(false)
  const [openActivate, setOpenActivate] = useState(false)
  const [openDeactivate, setOpenDeactivate] = useState(false)

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'name', label: 'Nome' },
    { id: 'code', label: 'Código' },
    { id: 'type', label: 'Tipo' },
    { id: 'value', label: 'Valor' },
    { id: 'redemptions', label: 'Utilizações' },
    { id: 'status', label: 'Status' },
  ];

  const statusOptions = [
    { label: 'Ativos', value: 'ACTIVE' },
    { label: 'Inativos', value: 'INACTIVE' },
  ];

  const onGetCouponsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCouponLoadingError(true)

      setCoupons([]);
      setFormattedCoupons([])
      setTotalCoupons(0);
    } else {
      setCouponLoadingError(true)

      setCoupons([]);
      setFormattedCoupons([])
      setTotalCoupons(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setCouponLoading(true);
      setCouponLoadingError(false)

      const response = await getCoupons([statusFilter], searchCoupons, sort, { page, limit: rowsPerPage }, abortController.signal);
      if (response.errors) {
        onGetCouponsHandleError(response.errors)
      } else {
        setCouponLoadingError(false)
        setCoupons(response.coupons as Array<CouponsType>);
        setFormattedCoupons(formatCouponsToDisplay(response.coupons))

        setTotalCoupons(response.pagination.totalCoupons);
      }

      setCouponLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [page, rowsPerPage, sort, statusFilter, searchCoupons])


  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'edit':
        setActionIndex(index)
        setOpenEdit(true)
        break;

      case 'activate':
        setActionIndex(index)
        setOpenActivate(true)
        break;

      case 'deactivate':
        setActionIndex(index)
        setOpenDeactivate(true)
        break;
    }
  }

  const onCouponSearchChange = (search: string) => {
    setPage(1);
    setSearchCoupons(search);
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  const onCloseModal = () => {
    setOpenCreateCoupon(false)
  }

  const onOpenModal = () => {
    setOpenCreateCoupon(true)
  }

  const onCloseEdit = () => {
    setOpenEdit(false)
  }

  const onCloseActivate = () => {
    setOpenActivate(false)
  }

  const onCloseDeactivate = () => {
    setOpenDeactivate(false)
  }

  return {
    actionIndex,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    statusFilter,
    statusOptions,
    sort,
    searchCoupons,
    totalCoupons,
    tableHeadCells,
    couponLoading,
    couponLoadingError,
    coupons,
    formattedCoupons,
    openCreateCoupon,
    openEdit,
    openActivate,
    openDeactivate,
    applyStatusFilter,
    handleTableRowActions,
    onCouponSearchChange,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
    onOpenModal,
    onCloseEdit,
    onCloseModal,
    onCloseActivate,
    onCloseDeactivate
  };

}