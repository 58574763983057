import { CustomError, api } from 'utils/axios';
import dayjs from 'dayjs';

import { formatMoney } from 'utils/moneyFormatt';

type DetailsType = {
  id: string,
  status: string,
  customer: {
    id: number,
    name: string
  },
  total_value: number,
  processed_date: string,
  payment_status: string,
  account_receivable: {
    id: string,
    key: string
  }[],
  original_url: string,
  receipt_url: string,
  description: string,
  receipt_number: string,
}

type DetailsDisplayType = {
  id: string,
  status: string,
  customer: {
    id: number,
    name: string
  },
  total_value: string,
  processed_date: string,
  payment_status: string,
  account_receivable: string,
  account_receivable_id: Array<string>
  original_url: string,
  receipt_url: string,
  description: string,
  receipt_number: string,
}

const parseDetails = (response: { data: any }) => {
  return {
    id: response.data.id,
    status: response.data.attributes.status,
    customer: {
      id: response.data.attributes.customer_id,
      name: response.data.attributes.customer_name,
    },
    total_value: response.data.attributes.total_value,
    processed_date: response.data.attributes.processed_date?.date,
    payment_status: response.data.attributes.payment_status,
    account_receivable: response.data.attributes.account_receivable,
    original_url: response.data.attributes.original_url,
    receipt_url: response.data.attributes.receipt_url,
    description: response.data.attributes.description,
    receipt_number: response.data.attributes.receipt_number,
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/invoices-receipts/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  const translateStatus = {
    'DRAFT': 'RASCUNHO',
    'AUTHORIZED': 'AUTORIZADO',
    'CANCELLED': 'CANCELADO',
    'CANCELLING': 'CANCELANDO',
    'ERROR': 'ERRO',
    'PENDING': 'PENDENTE',
    'PROCESSING': 'PROCESSANDO'
  }

  const translatePaymentStatus = {
    'CANCELED': 'Cancelado',
    'EXPIRED': 'Expirado',
    'FAILED': 'Falhou',
    'PAID': 'Pago',
    'PENDING': 'Pendente',
  }

  type StatusEnum = keyof typeof translateStatus;
  type PaymentStatusEnum = keyof typeof translatePaymentStatus

  return {
    id: details.id,
    status: translateStatus[details.status as StatusEnum],
    customer: details.customer,
    total_value: formatMoney(details.total_value),
    processed_date: details.processed_date ? dayjs(details.processed_date).format('DD/MM/YYYY') : 'N/A',
    payment_status: translatePaymentStatus[details.payment_status as PaymentStatusEnum],
    account_receivable: details.account_receivable.map(ar => `${ar.key}`).join(', '),
    account_receivable_id: details.account_receivable.map(ar => `${ar.id}`),
    original_url: details.original_url,
    receipt_url: details.receipt_url,
    description: details.description ? details.description : 'N/A',
    receipt_number: details.receipt_number ? details.receipt_number : 'Ainda não emitida',
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }