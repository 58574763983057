import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';

import { getCashierStatement, formatCashierStatementToDisplay, CashierStatementType, CashierStatementDisplayType, SortType, FilterType, getBanks, BanksType, TotalsType, TotalsDisplayType, formatTotalsToDisplay } from './services';

export default function CashierStatementGroupViewModel() {
  const [cashiers, setCashiers] = useState<Array<CashierStatementType>>();
  const [formattedCashiers, setFormattedCahiers] = useState<Array<CashierStatementDisplayType>>();

  const [searchDescription, setSearchDescription] = useState('')

  const [banks, setBanks] = useState<BanksType[]>()
  const [searchBanks, setSearchBanks] = useState('')
  const [banksLoading, setBanksLoading] = useState(false)

  const [cashiersLoading, setCashiersLoading] = useState(true);
  const [cashiersLoadingError, setCashiersLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  const [effectiveDateFilter, setEffectiveDateFilter] = useState<FilterType[]>([{
    field: 'effective_date',
    comparator: 'gte',
    value: dayjs().subtract(30, 'days').format('YYYY-MM-DD')
  }, {
    field: 'effective_date',
    comparator: 'lte',
    value: dayjs().format('YYYY-MM-DD')
  }]);

  const [bankFilter, setBankFilter] = useState<FilterType>({
    field: 'cashier_id',
    comparator: 'in',
    value: []
  });

  const [lowerValueFilter, setLowerValueFilter] = useState<FilterType>({
    field: 'value',
    comparator: 'gte',
    value: null
  });

  const [upperValueFilter, setUpperValueFilter] = useState<FilterType>({
    field: 'value',
    comparator: 'lte',
    value: null
  });

  //totals
  const [totalizers, setTotalizers] = useState<TotalsType>();
  const [formattedTotalizers, setFormattedTotalizers] = useState<TotalsDisplayType>();


  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCashiers, setTotalCashiers] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'cashier_name', label: 'Conta' },
    { id: 'value', label: 'Valor' },
    { id: 'effective_date', label: 'Data' },
    { id: 'description', label: 'Descrição' },
  ];


  const onGetCashierStatementHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCashiersLoadingError(true)

      setCashiers([]);
      setFormattedCahiers([])
      setTotalCashiers(0);
    } else {
      setCashiersLoadingError(true)

      setCashiers([]);
      setFormattedCahiers([])
      setTotalCashiers(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setCashiersLoading(true);

      const response = await getCashierStatement(
        [
          effectiveDateFilter[0],
          effectiveDateFilter[1],
          bankFilter,
          lowerValueFilter,
          upperValueFilter,
        ],
        searchDescription,
        sort,
        { page, limit: rowsPerPage },
        abortController.signal
      );

      if (response.errors) {
        onGetCashierStatementHandleError(response.errors)
      } else {
        setCashiers(response.cashiers as Array<CashierStatementType>);
        setFormattedCahiers(formatCashierStatementToDisplay(response.cashiers))

        if (response.pagination)
          setTotalCashiers(response.pagination.totalCashiers);

        if (response.totals) {
          setTotalizers(response.totals)
          setFormattedTotalizers(formatTotalsToDisplay(response.totals))
        }
      }

      setCashiersLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort();
    }

  }, [bankFilter, effectiveDateFilter, lowerValueFilter, page, rowsPerPage, searchDescription, sort, upperValueFilter]);


  const onGetBanksHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCashiersLoadingError(true)

      setBanks([]);
    } else {
      setCashiersLoadingError(true)

      setBanks([]);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {
        setBanksLoading(true);

        const response = await getBanks(searchBanks, abortController.signal);

        if (response.errors) {
          onGetBanksHandleError(response.errors)
        }
        else {
          setBanks(response as Array<BanksType>);
        }

        setBanksLoading(false);
      })();
    }, 500)

    return () => {
      abortController.abort();
      clearTimeout(debounce);
    }
  }, [searchBanks]);


  const applyBankFilter = (optionsSelected: Array<{ value: string, label: string }>) => {
    setPage(1);
    setBankFilter({ ...bankFilter, value: optionsSelected.map(option => option.value) });
  }

  const applyEffectiveDateFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...effectiveDateFilter];

    newDateFilter[0].value = startDate ? startDate.format('YYYY-MM-DD') : '';
    newDateFilter[1].value = endDate ? endDate.format('YYYY-MM-DD') : '';

    setEffectiveDateFilter(newDateFilter);
  }

  const applyValueFilter = (lowerValue?: number, upperValue?: number) => {
    setPage(1);
    setLowerValueFilter({ ...lowerValueFilter, value: lowerValue });
    setUpperValueFilter({ ...upperValueFilter, value: upperValue });
  }

  const onBanksSearchChange = (search: string) => {
    setSearchBanks(search)
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onDescriptionSearchChange = (search: string) => {
    setPage(1);
    setSearchDescription(search);
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }


  return {
    bankFilter,
    banks,
    banksLoading,
    cashiers,
    cashiersLoading,
    cashiersLoadingError,
    effectiveDateFilter,
    formattedCashiers,
    formattedTotalizers,
    lowerValueFilter,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    searchDescription,
    searchBanks,
    sort,
    tableHeadCells,
    totalCashiers,
    upperValueFilter,
    totalizers,
    applyBankFilter,
    applyEffectiveDateFilter,
    applyValueFilter,
    onBanksSearchChange,
    onChangeSort,
    onDescriptionSearchChange,
    onPageChange,
    onRowsPerPageChange,
  };
}