import { Box, Modal, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { ConfigStep } from './pages/ConfigStep';
import { PaymentStep } from './pages/PaymentStep';
import { SummaryStep } from './pages/SummaryStep';

import { ApiErrorView } from 'components/ApiErrorView';

import useViewModel from './viewModel'

type EditSaleModalPropTypes = {
  saleData: {
    id: string,
    key: string
  },
  open: boolean,
  type: string,
  onClose: () => void
}

function EditSaleModal({
  saleData,
  open,
  type,
  onClose
}: EditSaleModalPropTypes) {
  const {
    hasInstallments,
    loading,
    loadingError,
    page,
    onBack,
    onNext,
    onCloseModal
  } = useViewModel({ id: saleData.id, open, onClose })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onCloseModal}
      >
        <Box component={Paper} className='edit-sale-modal custom-modal'>
          <div className='modal-header'>
            <Typography variant='h2'>
              Editar {type === 'RECURRING' ? 'assinatura' : 'venda spot'}
            </Typography>

            <div className='modal-close-button' onClick={onCloseModal}>
              <CloseIcon />
            </div>
          </div>

          {hasInstallments &&
            <ApiErrorView title='Uma venda parcelada não pode ser editada.' />
          }

          {loadingError &&
            <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as informações da venda.' />
          }

          {!hasInstallments && !loadingError && page === 0 &&
            <ConfigStep
              loading={loading}
              type={type}
              onBack={onCloseModal}
              onNext={onNext}
            />
          }

          {!hasInstallments && !loadingError && page === 1 &&
            <PaymentStep
              onBack={onBack}
              onNext={onNext}
            />
          }

          {!hasInstallments && !loadingError && page === 2 &&
            <SummaryStep
              type={type}
              onBack={onBack}
              onNext={onCloseModal}
            />
          }

        </Box>
      </Modal>
    </>
  )
}

export { EditSaleModal }