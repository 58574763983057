import { Box, Modal, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { ChooseStep } from './pages/ChooseStep';
import { ConfigStep } from './pages/ConfigStep';
import { PaymentStep } from './pages/PaymentStep';
import { SummaryStep } from './pages/SummaryStep';

import useViewModel from './viewModel'

type CreateSaleModalPropTypes = {
  open: boolean,
  type: string,
  onClose: () => void
}

function CreateSaleModal({
  open,
  type,
  onClose
}: CreateSaleModalPropTypes) {
  const {
    page,
    onBack,
    onNext,
    onCloseModal
  } = useViewModel({ onClose })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onCloseModal}
      >
        <Box component={Paper} className='create-sale-modal custom-modal'>
          <div className='modal-header'>
            <Typography variant='h2'>
              Nova {type === 'RECURRING' ? 'assinatura' : 'venda spot'}
            </Typography>

            <div className='modal-close-button' onClick={onCloseModal}>
              <CloseIcon />
            </div>
          </div>

          {page === 0 &&
            <ChooseStep
              type={type}
              onBack={onCloseModal}
              onNext={onNext}
            />
          }

          {page === 1 &&
            <ConfigStep
              type={type}
              onBack={onBack}
              onNext={onNext}
            />
          }

          {page === 2 &&
            <PaymentStep
              onBack={onBack}
              onNext={onNext}
            />
          }

          {page === 3 &&
            <SummaryStep
              type={type}
              onBack={onBack}
              onNext={onCloseModal}
            />
          }

        </Box>
      </Modal>
    </>
  )
}

export { CreateSaleModal }