import { useEffect, useState } from 'react'

import { getClient, emailInvoiceGroup, ClientType, InvoiceGroupPayload } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type InvoiceGroupsType = {
  id: string,
  customer_id: string
}

type ViewModelPropTypes = {
  invoiceGroupData: InvoiceGroupsType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EmailInvoiceViewModel({ invoiceGroupData, onClose, onSubmit }: ViewModelPropTypes) {
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceError, setInvoiceError] = useState(false)

  const [client, setClient] = useState<ClientType>()
  const [clientLoading, setClientLoading] = useState(true)
  const [clientLoadingError, setClientLoadingError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onGetClientHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientLoadingError(true)
      setClient(undefined);
    } else {
      setClientLoadingError(true)
      setClient(undefined);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setClientLoading(true);

      if (invoiceGroupData.customer_id) {
        const response = await getClient(invoiceGroupData.customer_id, abortController.signal);

        if (response.errors) {
          onGetClientHandleError(response.errors)
        } else {
          setClient(response)
        }
      }

      setClientLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [invoiceGroupData])

  const onPostEmailHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setInvoiceError(true)
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setInvoiceError(true)
      setOpenSnackbar(true)
    } else {
      setInvoiceError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setInvoiceLoading(true)

    const response = await emailInvoiceGroup(invoiceGroupData.id, values as InvoiceGroupPayload)

    if (response.errors)
      onPostEmailHandleError(response.errors)

    else {
      setInvoiceError(false)
      setOpenSnackbar(true)
      setFormError(false)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setInvoiceLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.emails || values.emails.length === 0) {
      errors.emails = 'Obrigatório'
    } else {
      values.emails.forEach((email: string) => {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
          errors.emails = 'Existem emails inválidos'
      })
    }

    return errors
  }

  return {
    invoiceLoading,
    invoiceError,
    clientLoading,
    clientLoadingError,
    formError,
    openSnackbar,
    client,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}

export type { InvoiceGroupsType }