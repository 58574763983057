import { SalePayloadType } from 'recoil/createSale/states';

import { CustomError, api } from 'utils/axios';
import { formatMoney } from 'utils/moneyFormatt';
import { formatPercent } from 'utils/percentFormat';

type SalePayloadDisplayType = {
  product_name: string,
  plan_name?: string,
  subtotal: string,
  discount: string,
  customer_name: string,
  installments?: string[],
  start_date?: string,
  initial_term?: string,
  auto_renewal: string,
  freq?: string,
  payment_term: string,
  first_payment_term: string,
  receipt: string,
  taxes: {
    iss: string,
    iss_in_receipt: string,
  },
  description: string,
  payment_method: string,
  total: string
}

type BundleSalePayload = {
  charge_value: number,
  initial_term: number | null,
  auto_renewal: boolean,
  create_receipt: boolean,
  auto_receipt_run: boolean,
  description: string,
  payment_method_type: string,
  payment_method_id: string | null,
  payment_terms: {
    first_payment_term_type: string | null,
    first_payment_term_value: number | null,
    payment_term_type: string,
    payment_term_value: number
  },
  taxes: {
    withhold: boolean,
    rate: number | null,
    withhold_in_receipt: boolean,
    rate_in_receipt: number | null
  }
}

const bundleSalePayload = (type: string, salePayload: SalePayloadType): BundleSalePayload => {

  let charge_value: number;
  if (type === 'recurring')
    charge_value = salePayload.plan_value ? salePayload.plan_value : 0
  else
    charge_value = salePayload.product_value ? salePayload.product_value : 0

  let payment_terms: any = {
    payment_term_type: salePayload.payment_terms?.payment_term_type ? salePayload.payment_terms?.payment_term_type : '',
    payment_term_value: salePayload.payment_terms?.payment_term_value ? salePayload.payment_terms?.payment_term_value : 0,
    first_payment_term_type: salePayload.payment_terms?.payment_term_type ? salePayload.payment_terms?.payment_term_type : '',
    first_payment_term_value: salePayload.payment_terms?.payment_term_value ? salePayload.payment_terms?.payment_term_value : 0,
  }
  if (type === 'recurring') {
    payment_terms.first_payment_term_type = salePayload.payment_terms?.first_payment_term_type ? salePayload.payment_terms?.first_payment_term_type : ''
    payment_terms.first_payment_term_value = salePayload.payment_terms?.first_payment_term_value ? salePayload.payment_terms?.first_payment_term_value : 0
  }

  return {
    charge_value,
    initial_term: salePayload.initial_term ? salePayload.initial_term : 0,
    auto_renewal: salePayload.auto_renewal ? true : false,
    create_receipt: salePayload.create_receipt ? true : false,
    auto_receipt_run: salePayload.auto_receipt_run ? true : false,
    description: salePayload.description ? salePayload.description : '',
    payment_method_type: salePayload.payment_method_type ? salePayload.payment_method_type : '',
    payment_method_id: salePayload.payment_method_id ? salePayload.payment_method_id : null,
    payment_terms,
    taxes: {
      withhold: salePayload.taxes?.withhold ? true : false,
      rate: salePayload.taxes?.rate ? salePayload.taxes?.rate : 0,
      withhold_in_receipt: salePayload.taxes?.withhold_in_receipt ? true : false,
      rate_in_receipt: salePayload.taxes?.rate_in_receipt ? salePayload.taxes?.rate_in_receipt : 0,
    }
  }
}

const patchSale = async (type: string, salePayload: SalePayloadType): Promise<any> => {
  try {
    const lowerCaseType = type.toLowerCase()

    const response = await api.patch(`/api-cashone/sales-${lowerCaseType}/${salePayload.sale_id}`,
      bundleSalePayload(lowerCaseType, salePayload)
    );

    return response;

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatSalePayloadToDisplay = (salePayload: SalePayloadType): SalePayloadDisplayType => {

  let formattedValue = '';
  let value = 0;
  let formattedDiscount = 'N/A';
  let discount = 0;

  if (salePayload.product_value) {
    value = salePayload.product_value;
  }

  if (salePayload.plan_value) {
    value = salePayload.plan_value;
  }

  formattedValue = formatMoney(value)

  if (salePayload.coupon_id) {
    switch (salePayload.coupon_type) {
      case 'FIXED':
        discount = salePayload.coupon_value ? salePayload.coupon_value : 0
        formattedDiscount = formatMoney(salePayload.coupon_value ? salePayload.coupon_value : 0)
        break;

      case 'PERCENTAGE':
        discount = value * (salePayload.coupon_value ? salePayload.coupon_value : 0)
        formattedDiscount = formatMoney(value * (salePayload.coupon_value ? salePayload.coupon_value : 0))
        break;
    }
  }


  let payment_term = '';
  if (salePayload?.payment_terms?.payment_term_type) {
    if (salePayload.payment_terms.payment_term_type === 'FIXED') {
      if (salePayload.payment_terms.payment_term_value === 0)
        payment_term = `Todo último dia do mês`
      else
        payment_term = `Todo dia ${salePayload.payment_terms.payment_term_value}`
    }
    else
      payment_term = `Até ${salePayload.payment_terms?.payment_term_value} dias após a cobrança`
  }

  let first_payment_term = '';
  if (salePayload?.payment_terms?.first_payment_term_type) {
    if (salePayload?.payment_terms?.first_payment_term_type === 'FIXED') {
      if (salePayload.payment_terms.first_payment_term_value === 0)
        first_payment_term = `Todo último dia do mês`
      else
        first_payment_term = `Todo dia ${salePayload?.payment_terms.first_payment_term_value}`
    }
    else
      first_payment_term = `Até ${salePayload?.payment_terms?.first_payment_term_value} dias após a cobrança`
  }


  let receipt;
  if (salePayload.create_receipt) {
    if (salePayload.auto_receipt_run)
      receipt = 'Sim, automaticamente com a cobrança'
    else
      receipt = 'Sim, manualmente'
  }
  else
    receipt = 'Não'


  let iss = '';
  if (salePayload.taxes?.withhold)
    iss = `Sim, usar alíquota de ${formatPercent(salePayload.taxes.rate ? salePayload.taxes.rate : 0)}`
  else
    iss = 'Não'

  let iss_in_receipt = '';
  if (salePayload.taxes?.withhold_in_receipt)
    iss_in_receipt = `Sim, usar alíquota de ${formatPercent(salePayload.taxes.rate_in_receipt ? salePayload.taxes.rate_in_receipt : 0)}`
  else
    iss_in_receipt = 'Não'

  let initial_term = '';
  if (salePayload.initial_term !== undefined)
    if (salePayload.initial_term === 0)
      initial_term = 'Indeterminado'
    else
      initial_term = `${salePayload.initial_term} meses`


  const translateFreq = {
    'YEARLY': 'Anual',
    'SEMIANNUAL': 'Semestral',
    'QUARTERLY': 'Trimestral',
    'MONTHLY': 'Mensal',
  }
  type freqEnum = keyof typeof translateFreq;

  let paymentMethod = '';
  switch (salePayload.payment_method_type) {
    case 'BOLETO': paymentMethod = 'Boleto'; break;
    case 'PIX': paymentMethod = 'Pix'; break;
    case 'CREDIT_CARD': paymentMethod = 'Cartão de crédito'; break;
    case 'BANK_DEPOSIT': paymentMethod = `Depósito bancário`; break;
  }

  return {
    customer_name: salePayload.customer_name ? salePayload.customer_name : '',
    product_name: salePayload.product_name ? salePayload.product_name : '',
    plan_name: salePayload.plan_name ? salePayload.plan_name : '',
    subtotal: formattedValue,
    discount: formattedDiscount,
    installments: salePayload.installments ?
      salePayload.installments.map((i) => {
        return `${formatMoney(i.total_value)} vencendo em ${i.date.format('DD/MM/YYYY')}`
      }) : undefined,
    start_date: salePayload.start_date ? salePayload.start_date.format('DD/MM/YYYY') : '',
    initial_term,
    auto_renewal: salePayload.auto_renewal ? 'Sim' : 'Não',
    freq: salePayload.freq ? translateFreq[salePayload.freq as freqEnum] : '',
    payment_term,
    first_payment_term,
    receipt,
    taxes: {
      iss,
      iss_in_receipt,
    },
    description: salePayload.description ? salePayload.description : 'N/A',
    payment_method: paymentMethod,
    total: formatMoney(value - discount),
  }
}

export {
  bundleSalePayload,
  formatSalePayloadToDisplay,
  patchSale,
}