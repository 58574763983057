import { Alert, Button, CircularProgress, Snackbar, Typography } from '@mui/material';

import { Flexbox } from 'components/Flexbox';
import { DisplayInfoText } from 'components/Info/DisplayInfoText';

import useViewModel from './viewModel'

import './styles.scss'

type SummaryStepPropTypes = {
  type: string,
  onBack: () => void
  onNext: () => void,
}

function SummaryStep({
  type,
  onBack,
  onNext
}: SummaryStepPropTypes) {
  const {
    formattedSalePayload,
    openSnackbar,
    saleLoading,
    saleLoadingError,
    salePayload,
    onCloseSnackbar,
    onSubmit
  } = useViewModel({ type, onNext })

  return (
    <>
      <div className='modal-body summary-step'>
        <Typography variant='h4' fontWeight={700} className='summary-subtitle' color='primary'>Resumo</Typography>

        <div className='info-row'>
          <Flexbox size={12}>
            <Flexbox size={4}>
              <DisplayInfoText
                title='Cliente'
                text={formattedSalePayload.customer_name}
              />
            </Flexbox>

            {type === 'RECURRING' &&
              <Flexbox size={4}>
                <DisplayInfoText
                  title='Plano'
                  text={`${formattedSalePayload.product_name} - ${formattedSalePayload.plan_name}`}
                />
              </Flexbox>
            }

            {type === 'SPOT' &&
              <Flexbox size={4}>
                <DisplayInfoText
                  title='Produto'
                  text={`${formattedSalePayload.product_name}`}
                />
              </Flexbox>
            }

            <Flexbox size={4}>
              <DisplayInfoText
                title='Método de pagamento'
                text={formattedSalePayload.payment_method}
              />
            </Flexbox>
          </Flexbox>
        </div>

        <div className='info-row'>
          <Flexbox size={12}>
            <Flexbox size={4}>
              <DisplayInfoText
                title='Valor'
                text={formattedSalePayload.total}
              />
            </Flexbox>
          </Flexbox>
        </div>

        {type === 'RECURRING' &&
          <div className='info-row'>
            <Flexbox size={12}>
              <Flexbox size={4}>
                <DisplayInfoText
                  title='Início'
                  text={formattedSalePayload.start_date ? formattedSalePayload.start_date : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  title='Duração'
                  text={formattedSalePayload.initial_term ? formattedSalePayload.initial_term : ''}
                />
              </Flexbox>

              <Flexbox size={4}>
                <DisplayInfoText
                  title='Frequência da cobrança'
                  text={formattedSalePayload.freq ? formattedSalePayload.freq : ''}
                />
              </Flexbox>
            </Flexbox>
          </div>
        }

        {type === 'RECURRING' && salePayload.freq !== '0' &&
          <div className='info-row'>
            <Flexbox size={12}>
              <DisplayInfoText
                title='Renovação automática'
                text={formattedSalePayload.auto_renewal}
              />
            </Flexbox>
          </div>
        }

        {type === 'RECURRING' &&
          <div className='info-row'>
            <Flexbox size={12}>
              <Flexbox size={6}>
                <DisplayInfoText
                  title='Vencimento do primeiro pagamento'
                  text={formattedSalePayload.first_payment_term}
                />
              </Flexbox>
              <Flexbox size={6}>
                <DisplayInfoText
                  title='Vencimento dos demais pagamentos'
                  text={formattedSalePayload.payment_term}
                />
              </Flexbox>
            </Flexbox>
          </div>
        }

        {type === 'SPOT' &&
          <div className='info-row'>
            <Flexbox size={12}>
              <DisplayInfoText
                title='Vencimento do pagamento'
                text={formattedSalePayload.payment_term}
              />
            </Flexbox>
          </div>
        }

        <div className='info-row'>
          <Flexbox size={12}>
            <DisplayInfoText
              title='Gerar nota fiscal'
              text={formattedSalePayload.receipt}
            />
          </Flexbox>
        </div>

        <div className='info-row'>
          <Flexbox size={12}>
            <Flexbox size={6}>
              <DisplayInfoText
                title='Retenção de ISS na cobrança'
                text={formattedSalePayload.taxes.iss}
              />
            </Flexbox>
            <Flexbox size={6}>
              <DisplayInfoText
                title='Retenção de ISS na nota fiscal'
                text={formattedSalePayload.taxes.iss_in_receipt}
              />
            </Flexbox>
          </Flexbox>
        </div>

        <div className='info-row'>
          <Flexbox size={12}>
            <DisplayInfoText
              title='Descrição para fatura e nota fiscal'
              text={formattedSalePayload.description}
            />
          </Flexbox>
        </div>

      </div>
      <div className='modal-footer'>
        {saleLoading && <CircularProgress className='loading' color='primary' size={20} />}
        <Button variant='outlined' onClick={onBack} disabled={saleLoading}>Voltar</Button>
        <Button variant='contained' onClick={onSubmit} disabled={saleLoading}>Atualizar</Button>
      </div>

      {saleLoadingError &&
        <Snackbar open={true} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }
      {!saleLoadingError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Venda atualizada!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { SummaryStep }