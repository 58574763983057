const CITIES: any = {
        ALL_CITIES: [
            {
                name: 'Acrelândia',
                uf: 'AC',
                code: 1200013,
            },
            {
                name: 'Assis Brasil',
                uf: 'AC',
                code: 1200054,
            },
            {
                name: 'Brasiléia',
                uf: 'AC',
                code: 1200104,
            },
            {
                name: 'Bujari',
                uf: 'AC',
                code: 1200138,
            },
            {
                name: 'Capixaba',
                uf: 'AC',
                code: 1200179,
            },
            {
                name: 'Cruzeiro do Sul',
                uf: 'AC',
                code: 1200203,
            },
            {
                name: 'Epitaciolândia',
                uf: 'AC',
                code: 1200252,
            },
            {
                name: 'Feijó',
                uf: 'AC',
                code: 1200302,
            },
            {
                name: 'Jordão',
                uf: 'AC',
                code: 1200328,
            },
            {
                name: 'Mâncio Lima',
                uf: 'AC',
                code: 1200336,
            },
            {
                name: 'Manoel Urbano',
                uf: 'AC',
                code: 1200344,
            },
            {
                name: 'Marechal Thaumaturgo',
                uf: 'AC',
                code: 1200351,
            },
            {
                name: 'Plácido de Castro',
                uf: 'AC',
                code: 1200385,
            },
            {
                name: 'Porto Acre',
                uf: 'AC',
                code: 1200807,
            },
            {
                name: 'Porto Walter',
                uf: 'AC',
                code: 1200393,
            },
            {
                name: 'Rio Branco',
                uf: 'AC',
                code: 1200401,
            },
            {
                name: 'Rodrigues Alves',
                uf: 'AC',
                code: 1200427,
            },
            {
                name: 'Santa Rosa do Purus',
                uf: 'AC',
                code: 1200435,
            },
            {
                name: 'Sena Madureira',
                uf: 'AC',
                code: 1200500,
            },
            {
                name: 'Senador Guiomard',
                uf: 'AC',
                code: 1200450,
            },
            {
                name: 'Tarauacá',
                uf: 'AC',
                code: 1200609,
            },
            {
                name: 'Xapuri',
                uf: 'AC',
                code: 1200708,
            },
            {
                name: 'Água Branca',
                uf: 'AL',
                code: 2700102,
            },
            {
                name: 'Anadia',
                uf: 'AL',
                code: 2700201,
            },
            {
                name: 'Arapiraca',
                uf: 'AL',
                code: 2700300,
            },
            {
                name: 'Atalaia',
                uf: 'AL',
                code: 2700409,
            },
            {
                name: 'Barra de Santo Antônio',
                uf: 'AL',
                code: 2700508,
            },
            {
                name: 'Barra de São Miguel',
                uf: 'AL',
                code: 2700607,
            },
            {
                name: 'Batalha',
                uf: 'AL',
                code: 2700706,
            },
            {
                name: 'Belém',
                uf: 'AL',
                code: 2700805,
            },
            {
                name: 'Belo Monte',
                uf: 'AL',
                code: 2700904,
            },
            {
                name: 'Boca da Mata',
                uf: 'AL',
                code: 2701001,
            },
            {
                name: 'Branquinha',
                uf: 'AL',
                code: 2701100,
            },
            {
                name: 'Cacimbinhas',
                uf: 'AL',
                code: 2701209,
            },
            {
                name: 'Cajueiro',
                uf: 'AL',
                code: 2701308,
            },
            {
                name: 'Campestre',
                uf: 'AL',
                code: 2701357,
            },
            {
                name: 'Campo Alegre',
                uf: 'AL',
                code: 2701407,
            },
            {
                name: 'Campo Grande',
                uf: 'AL',
                code: 2701506,
            },
            {
                name: 'Canapi',
                uf: 'AL',
                code: 2701605,
            },
            {
                name: 'Capela',
                uf: 'AL',
                code: 2701704,
            },
            {
                name: 'Carneiros',
                uf: 'AL',
                code: 2701803,
            },
            {
                name: 'Chã Preta',
                uf: 'AL',
                code: 2701902,
            },
            {
                name: 'Coité do Nóia',
                uf: 'AL',
                code: 2702009,
            },
            {
                name: 'Colônia Leopoldina',
                uf: 'AL',
                code: 2702108,
            },
            {
                name: 'Coqueiro Seco',
                uf: 'AL',
                code: 2702207,
            },
            {
                name: 'Coruripe',
                uf: 'AL',
                code: 2702306,
            },
            {
                name: 'Craíbas',
                uf: 'AL',
                code: 2702355,
            },
            {
                name: 'Delmiro Gouveia',
                uf: 'AL',
                code: 2702405,
            },
            {
                name: 'Dois Riachos',
                uf: 'AL',
                code: 2702504,
            },
            {
                name: 'Estrela de Alagoas',
                uf: 'AL',
                code: 2702553,
            },
            {
                name: 'Feira Grande',
                uf: 'AL',
                code: 2702603,
            },
            {
                name: 'Feliz Deserto',
                uf: 'AL',
                code: 2702702,
            },
            {
                name: 'Flexeiras',
                uf: 'AL',
                code: 2702801,
            },
            {
                name: 'Girau do Ponciano',
                uf: 'AL',
                code: 2702900,
            },
            {
                name: 'Ibateguara',
                uf: 'AL',
                code: 2703007,
            },
            {
                name: 'Igaci',
                uf: 'AL',
                code: 2703106,
            },
            {
                name: 'Igreja Nova',
                uf: 'AL',
                code: 2703205,
            },
            {
                name: 'Inhapi',
                uf: 'AL',
                code: 2703304,
            },
            {
                name: 'Jacaré dos Homens',
                uf: 'AL',
                code: 2703403,
            },
            {
                name: 'Jacuípe',
                uf: 'AL',
                code: 2703502,
            },
            {
                name: 'Japaratinga',
                uf: 'AL',
                code: 2703601,
            },
            {
                name: 'Jaramataia',
                uf: 'AL',
                code: 2703700,
            },
            {
                name: 'Jequiá da Praia',
                uf: 'AL',
                code: 2703759,
            },
            {
                name: 'Joaquim Gomes',
                uf: 'AL',
                code: 2703809,
            },
            {
                name: 'Jundiá',
                uf: 'AL',
                code: 2703908,
            },
            {
                name: 'Junqueiro',
                uf: 'AL',
                code: 2704005,
            },
            {
                name: 'Lagoa da Canoa',
                uf: 'AL',
                code: 2704104,
            },
            {
                name: 'Limoeiro de Anadia',
                uf: 'AL',
                code: 2704203,
            },
            {
                name: 'Maceió',
                uf: 'AL',
                code: 2704302,
            },
            {
                name: 'Major Isidoro',
                uf: 'AL',
                code: 2704401,
            },
            {
                name: 'Mar Vermelho',
                uf: 'AL',
                code: 2704906,
            },
            {
                name: 'Maragogi',
                uf: 'AL',
                code: 2704500,
            },
            {
                name: 'Maravilha',
                uf: 'AL',
                code: 2704609,
            },
            {
                name: 'Marechal Deodoro',
                uf: 'AL',
                code: 2704708,
            },
            {
                name: 'Maribondo',
                uf: 'AL',
                code: 2704807,
            },
            {
                name: 'Mata Grande',
                uf: 'AL',
                code: 2705002,
            },
            {
                name: 'Matriz de Camaragibe',
                uf: 'AL',
                code: 2705101,
            },
            {
                name: 'Messias',
                uf: 'AL',
                code: 2705200,
            },
            {
                name: 'Minador do Negrão',
                uf: 'AL',
                code: 2705309,
            },
            {
                name: 'Monteirópolis',
                uf: 'AL',
                code: 2705408,
            },
            {
                name: 'Murici',
                uf: 'AL',
                code: 2705507,
            },
            {
                name: 'Novo Lino',
                uf: 'AL',
                code: 2705606,
            },
            {
                name: "Olho d'Água das Flores",
                uf: 'AL',
                code: 2705705,
            },
            {
                name: "Olho d'Água do Casado",
                uf: 'AL',
                code: 2705804,
            },
            {
                name: "Olho d'Água Grande",
                uf: 'AL',
                code: 2705903,
            },
            {
                name: 'Olivença',
                uf: 'AL',
                code: 2706000,
            },
            {
                name: 'Ouro Branco',
                uf: 'AL',
                code: 2706109,
            },
            {
                name: 'Palestina',
                uf: 'AL',
                code: 2706208,
            },
            {
                name: 'Palmeira dos Índios',
                uf: 'AL',
                code: 2706307,
            },
            {
                name: 'Pão de Açúcar',
                uf: 'AL',
                code: 2706406,
            },
            {
                name: 'Pariconha',
                uf: 'AL',
                code: 2706422,
            },
            {
                name: 'Paripueira',
                uf: 'AL',
                code: 2706448,
            },
            {
                name: 'Passo de Camaragibe',
                uf: 'AL',
                code: 2706505,
            },
            {
                name: 'Paulo Jacinto',
                uf: 'AL',
                code: 2706604,
            },
            {
                name: 'Penedo',
                uf: 'AL',
                code: 2706703,
            },
            {
                name: 'Piaçabuçu',
                uf: 'AL',
                code: 2706802,
            },
            {
                name: 'Pilar',
                uf: 'AL',
                code: 2706901,
            },
            {
                name: 'Pindoba',
                uf: 'AL',
                code: 2707008,
            },
            {
                name: 'Piranhas',
                uf: 'AL',
                code: 2707107,
            },
            {
                name: 'Poço das Trincheiras',
                uf: 'AL',
                code: 2707206,
            },
            {
                name: 'Porto Calvo',
                uf: 'AL',
                code: 2707305,
            },
            {
                name: 'Porto de Pedras',
                uf: 'AL',
                code: 2707404,
            },
            {
                name: 'Porto Real do Colégio',
                uf: 'AL',
                code: 2707503,
            },
            {
                name: 'Quebrangulo',
                uf: 'AL',
                code: 2707602,
            },
            {
                name: 'Rio Largo',
                uf: 'AL',
                code: 2707701,
            },
            {
                name: 'Roteiro',
                uf: 'AL',
                code: 2707800,
            },
            {
                name: 'Santa Luzia do Norte',
                uf: 'AL',
                code: 2707909,
            },
            {
                name: 'Santana do Ipanema',
                uf: 'AL',
                code: 2708006,
            },
            {
                name: 'Santana do Mundaú',
                uf: 'AL',
                code: 2708105,
            },
            {
                name: 'São Brás',
                uf: 'AL',
                code: 2708204,
            },
            {
                name: 'São José da Laje',
                uf: 'AL',
                code: 2708303,
            },
            {
                name: 'São José da Tapera',
                uf: 'AL',
                code: 2708402,
            },
            {
                name: 'São Luís do Quitunde',
                uf: 'AL',
                code: 2708501,
            },
            {
                name: 'São Miguel dos Campos',
                uf: 'AL',
                code: 2708600,
            },
            {
                name: 'São Miguel dos Milagres',
                uf: 'AL',
                code: 2708709,
            },
            {
                name: 'São Sebastião',
                uf: 'AL',
                code: 2708808,
            },
            {
                name: 'Satuba',
                uf: 'AL',
                code: 2708907,
            },
            {
                name: 'Senador Rui Palmeira',
                uf: 'AL',
                code: 2708956,
            },
            {
                name: "Tanque d'Arca",
                uf: 'AL',
                code: 2709004,
            },
            {
                name: 'Taquarana',
                uf: 'AL',
                code: 2709103,
            },
            {
                name: 'Teotônio Vilela',
                uf: 'AL',
                code: 2709152,
            },
            {
                name: 'Traipu',
                uf: 'AL',
                code: 2709202,
            },
            {
                name: 'União dos Palmares',
                uf: 'AL',
                code: 2709301,
            },
            {
                name: 'Viçosa',
                uf: 'AL',
                code: 2709400,
            },
            {
                name: 'Alvarães',
                uf: 'AM',
                code: 1300029,
            },
            {
                name: 'Amaturá',
                uf: 'AM',
                code: 1300060,
            },
            {
                name: 'Anamã',
                uf: 'AM',
                code: 1300086,
            },
            {
                name: 'Anori',
                uf: 'AM',
                code: 1300102,
            },
            {
                name: 'Apuí',
                uf: 'AM',
                code: 1300144,
            },
            {
                name: 'Atalaia do Norte',
                uf: 'AM',
                code: 1300201,
            },
            {
                name: 'Autazes',
                uf: 'AM',
                code: 1300300,
            },
            {
                name: 'Barcelos',
                uf: 'AM',
                code: 1300409,
            },
            {
                name: 'Barreirinha',
                uf: 'AM',
                code: 1300508,
            },
            {
                name: 'Benjamin Constant',
                uf: 'AM',
                code: 1300607,
            },
            {
                name: 'Beruri',
                uf: 'AM',
                code: 1300631,
            },
            {
                name: 'Boa Vista do Ramos',
                uf: 'AM',
                code: 1300680,
            },
            {
                name: 'Boca do Acre',
                uf: 'AM',
                code: 1300706,
            },
            {
                name: 'Borba',
                uf: 'AM',
                code: 1300805,
            },
            {
                name: 'Caapiranga',
                uf: 'AM',
                code: 1300839,
            },
            {
                name: 'Canutama',
                uf: 'AM',
                code: 1300904,
            },
            {
                name: 'Carauari',
                uf: 'AM',
                code: 1301001,
            },
            {
                name: 'Careiro',
                uf: 'AM',
                code: 1301100,
            },
            {
                name: 'Careiro da Várzea',
                uf: 'AM',
                code: 1301159,
            },
            {
                name: 'Coari',
                uf: 'AM',
                code: 1301209,
            },
            {
                name: 'Codajás',
                uf: 'AM',
                code: 1301308,
            },
            {
                name: 'Eirunepé',
                uf: 'AM',
                code: 1301407,
            },
            {
                name: 'Envira',
                uf: 'AM',
                code: 1301506,
            },
            {
                name: 'Fonte Boa',
                uf: 'AM',
                code: 1301605,
            },
            {
                name: 'Guajará',
                uf: 'AM',
                code: 1301654,
            },
            {
                name: 'Humaitá',
                uf: 'AM',
                code: 1301704,
            },
            {
                name: 'Ipixuna',
                uf: 'AM',
                code: 1301803,
            },
            {
                name: 'Iranduba',
                uf: 'AM',
                code: 1301852,
            },
            {
                name: 'Itacoatiara',
                uf: 'AM',
                code: 1301902,
            },
            {
                name: 'Itamarati',
                uf: 'AM',
                code: 1301951,
            },
            {
                name: 'Itapiranga',
                uf: 'AM',
                code: 1302009,
            },
            {
                name: 'Japurá',
                uf: 'AM',
                code: 1302108,
            },
            {
                name: 'Juruá',
                uf: 'AM',
                code: 1302207,
            },
            {
                name: 'Jutaí',
                uf: 'AM',
                code: 1302306,
            },
            {
                name: 'Lábrea',
                uf: 'AM',
                code: 1302405,
            },
            {
                name: 'Manacapuru',
                uf: 'AM',
                code: 1302504,
            },
            {
                name: 'Manaquiri',
                uf: 'AM',
                code: 1302553,
            },
            {
                name: 'Manaus',
                uf: 'AM',
                code: 1302603,
            },
            {
                name: 'Manicoré',
                uf: 'AM',
                code: 1302702,
            },
            {
                name: 'Maraã',
                uf: 'AM',
                code: 1302801,
            },
            {
                name: 'Maués',
                uf: 'AM',
                code: 1302900,
            },
            {
                name: 'Nhamundá',
                uf: 'AM',
                code: 1303007,
            },
            {
                name: 'Nova Olinda do Norte',
                uf: 'AM',
                code: 1303106,
            },
            {
                name: 'Novo Airão',
                uf: 'AM',
                code: 1303205,
            },
            {
                name: 'Novo Aripuanã',
                uf: 'AM',
                code: 1303304,
            },
            {
                name: 'Parintins',
                uf: 'AM',
                code: 1303403,
            },
            {
                name: 'Pauini',
                uf: 'AM',
                code: 1303502,
            },
            {
                name: 'Presidente Figueiredo',
                uf: 'AM',
                code: 1303536,
            },
            {
                name: 'Rio Preto da Eva',
                uf: 'AM',
                code: 1303569,
            },
            {
                name: 'Santa Isabel do Rio Negro',
                uf: 'AM',
                code: 1303601,
            },
            {
                name: 'Santo Antônio do Içá',
                uf: 'AM',
                code: 1303700,
            },
            {
                name: 'São Gabriel da Cachoeira',
                uf: 'AM',
                code: 1303809,
            },
            {
                name: 'São Paulo de Olivença',
                uf: 'AM',
                code: 1303908,
            },
            {
                name: 'São Sebastião do Uatumã',
                uf: 'AM',
                code: 1303957,
            },
            {
                name: 'Silves',
                uf: 'AM',
                code: 1304005,
            },
            {
                name: 'Tabatinga',
                uf: 'AM',
                code: 1304062,
            },
            {
                name: 'Tapauá',
                uf: 'AM',
                code: 1304104,
            },
            {
                name: 'Tefé',
                uf: 'AM',
                code: 1304203,
            },
            {
                name: 'Tonantins',
                uf: 'AM',
                code: 1304237,
            },
            {
                name: 'Uarini',
                uf: 'AM',
                code: 1304260,
            },
            {
                name: 'Urucará',
                uf: 'AM',
                code: 1304302,
            },
            {
                name: 'Urucurituba',
                uf: 'AM',
                code: 1304401,
            },
            {
                name: 'Amapá',
                uf: 'AP',
                code: 1600105,
            },
            {
                name: 'Calçoene',
                uf: 'AP',
                code: 1600204,
            },
            {
                name: 'Cutias',
                uf: 'AP',
                code: 1600212,
            },
            {
                name: 'Ferreira Gomes',
                uf: 'AP',
                code: 1600238,
            },
            {
                name: 'Itaubal',
                uf: 'AP',
                code: 1600253,
            },
            {
                name: 'Laranjal do Jari',
                uf: 'AP',
                code: 1600279,
            },
            {
                name: 'Macapá',
                uf: 'AP',
                code: 1600303,
            },
            {
                name: 'Mazagão',
                uf: 'AP',
                code: 1600402,
            },
            {
                name: 'Oiapoque',
                uf: 'AP',
                code: 1600501,
            },
            {
                name: 'Pedra Branca do Amapari',
                uf: 'AP',
                code: 1600154,
            },
            {
                name: 'Porto Grande',
                uf: 'AP',
                code: 1600535,
            },
            {
                name: 'Pracuúba',
                uf: 'AP',
                code: 1600550,
            },
            {
                name: 'Santana',
                uf: 'AP',
                code: 1600600,
            },
            {
                name: 'Serra do Navio',
                uf: 'AP',
                code: 1600055,
            },
            {
                name: 'Tartarugalzinho',
                uf: 'AP',
                code: 1600709,
            },
            {
                name: 'Vitória do Jari',
                uf: 'AP',
                code: 1600808,
            },
            {
                name: 'Abaíra',
                uf: 'BA',
                code: 2900108,
            },
            {
                name: 'Abaré',
                uf: 'BA',
                code: 2900207,
            },
            {
                name: 'Acajutiba',
                uf: 'BA',
                code: 2900306,
            },
            {
                name: 'Adustina',
                uf: 'BA',
                code: 2900355,
            },
            {
                name: 'Água Fria',
                uf: 'BA',
                code: 2900405,
            },
            {
                name: 'Aiquara',
                uf: 'BA',
                code: 2900603,
            },
            {
                name: 'Alagoinhas',
                uf: 'BA',
                code: 2900702,
            },
            {
                name: 'Alcobaça',
                uf: 'BA',
                code: 2900801,
            },
            {
                name: 'Almadina',
                uf: 'BA',
                code: 2900900,
            },
            {
                name: 'Amargosa',
                uf: 'BA',
                code: 2901007,
            },
            {
                name: 'Amélia Rodrigues',
                uf: 'BA',
                code: 2901106,
            },
            {
                name: 'América Dourada',
                uf: 'BA',
                code: 2901155,
            },
            {
                name: 'Anagé',
                uf: 'BA',
                code: 2901205,
            },
            {
                name: 'Andaraí',
                uf: 'BA',
                code: 2901304,
            },
            {
                name: 'Andorinha',
                uf: 'BA',
                code: 2901353,
            },
            {
                name: 'Angical',
                uf: 'BA',
                code: 2901403,
            },
            {
                name: 'Anguera',
                uf: 'BA',
                code: 2901502,
            },
            {
                name: 'Antas',
                uf: 'BA',
                code: 2901601,
            },
            {
                name: 'Antônio Cardoso',
                uf: 'BA',
                code: 2901700,
            },
            {
                name: 'Antônio Gonçalves',
                uf: 'BA',
                code: 2901809,
            },
            {
                name: 'Aporá',
                uf: 'BA',
                code: 2901908,
            },
            {
                name: 'Apuarema',
                uf: 'BA',
                code: 2901957,
            },
            {
                name: 'Araças',
                uf: 'BA',
                code: 2902054,
            },
            {
                name: 'Aracatu',
                uf: 'BA',
                code: 2902005,
            },
            {
                name: 'Araci',
                uf: 'BA',
                code: 2902104,
            },
            {
                name: 'Aramari',
                uf: 'BA',
                code: 2902203,
            },
            {
                name: 'Arataca',
                uf: 'BA',
                code: 2902252,
            },
            {
                name: 'Aratuípe',
                uf: 'BA',
                code: 2902302,
            },
            {
                name: 'Aurelino Leal',
                uf: 'BA',
                code: 2902401,
            },
            {
                name: 'Baianópolis',
                uf: 'BA',
                code: 2902500,
            },
            {
                name: 'Baixa Grande',
                uf: 'BA',
                code: 2902609,
            },
            {
                name: 'Banzaê',
                uf: 'BA',
                code: 2902658,
            },
            {
                name: 'Barra',
                uf: 'BA',
                code: 2902708,
            },
            {
                name: 'Barra da Estiva',
                uf: 'BA',
                code: 2902807,
            },
            {
                name: 'Barra do Choça',
                uf: 'BA',
                code: 2902906,
            },
            {
                name: 'Barra do Mendes',
                uf: 'BA',
                code: 2903003,
            },
            {
                name: 'Barra do Rocha',
                uf: 'BA',
                code: 2903102,
            },
            {
                name: 'Barreiras',
                uf: 'BA',
                code: 2903201,
            },
            {
                name: 'Barro Alto',
                uf: 'BA',
                code: 2903235,
            },
            {
                name: 'Barro Preto',
                uf: 'BA',
                code: 2903300,
            },
            {
                name: 'Barrocas',
                uf: 'BA',
                code: 2903276,
            },
            {
                name: 'Belmonte',
                uf: 'BA',
                code: 2903409,
            },
            {
                name: 'Belo Campo',
                uf: 'BA',
                code: 2903508,
            },
            {
                name: 'Biritinga',
                uf: 'BA',
                code: 2903607,
            },
            {
                name: 'Boa Nova',
                uf: 'BA',
                code: 2903706,
            },
            {
                name: 'Boa Vista do Tupim',
                uf: 'BA',
                code: 2903805,
            },
            {
                name: 'Bom Jesus da Lapa',
                uf: 'BA',
                code: 2903904,
            },
            {
                name: 'Bom Jesus da Serra',
                uf: 'BA',
                code: 2903953,
            },
            {
                name: 'Boninal',
                uf: 'BA',
                code: 2904001,
            },
            {
                name: 'Bonito',
                uf: 'BA',
                code: 2904050,
            },
            {
                name: 'Boquira',
                uf: 'BA',
                code: 2904100,
            },
            {
                name: 'Botuporã',
                uf: 'BA',
                code: 2904209,
            },
            {
                name: 'Brejões',
                uf: 'BA',
                code: 2904308,
            },
            {
                name: 'Brejolândia',
                uf: 'BA',
                code: 2904407,
            },
            {
                name: 'Brotas de Macaúbas',
                uf: 'BA',
                code: 2904506,
            },
            {
                name: 'Brumado',
                uf: 'BA',
                code: 2904605,
            },
            {
                name: 'Buerarema',
                uf: 'BA',
                code: 2904704,
            },
            {
                name: 'Buritirama',
                uf: 'BA',
                code: 2904753,
            },
            {
                name: 'Caatiba',
                uf: 'BA',
                code: 2904803,
            },
            {
                name: 'Cabaceiras do Paraguaçu',
                uf: 'BA',
                code: 2904852,
            },
            {
                name: 'Cachoeira',
                uf: 'BA',
                code: 2904902,
            },
            {
                name: 'Caculé',
                uf: 'BA',
                code: 2905008,
            },
            {
                name: 'Caém',
                uf: 'BA',
                code: 2905107,
            },
            {
                name: 'Caetanos',
                uf: 'BA',
                code: 2905156,
            },
            {
                name: 'Caetité',
                uf: 'BA',
                code: 2905206,
            },
            {
                name: 'Cafarnaum',
                uf: 'BA',
                code: 2905305,
            },
            {
                name: 'Cairu',
                uf: 'BA',
                code: 2905404,
            },
            {
                name: 'Caldeirão Grande',
                uf: 'BA',
                code: 2905503,
            },
            {
                name: 'Camacan',
                uf: 'BA',
                code: 2905602,
            },
            {
                name: 'Camaçari',
                uf: 'BA',
                code: 2905701,
            },
            {
                name: 'Camamu',
                uf: 'BA',
                code: 2905800,
            },
            {
                name: 'Campo Alegre de Lourdes',
                uf: 'BA',
                code: 2905909,
            },
            {
                name: 'Campo Formoso',
                uf: 'BA',
                code: 2906006,
            },
            {
                name: 'Canápolis',
                uf: 'BA',
                code: 2906105,
            },
            {
                name: 'Canarana',
                uf: 'BA',
                code: 2906204,
            },
            {
                name: 'Canavieiras',
                uf: 'BA',
                code: 2906303,
            },
            {
                name: 'Candeal',
                uf: 'BA',
                code: 2906402,
            },
            {
                name: 'Candeias',
                uf: 'BA',
                code: 2906501,
            },
            {
                name: 'Candiba',
                uf: 'BA',
                code: 2906600,
            },
            {
                name: 'Cândido Sales',
                uf: 'BA',
                code: 2906709,
            },
            {
                name: 'Cansanção',
                uf: 'BA',
                code: 2906808,
            },
            {
                name: 'Canudos',
                uf: 'BA',
                code: 2906824,
            },
            {
                name: 'Capela do Alto Alegre',
                uf: 'BA',
                code: 2906857,
            },
            {
                name: 'Capim Grosso',
                uf: 'BA',
                code: 2906873,
            },
            {
                name: 'Caraíbas',
                uf: 'BA',
                code: 2906899,
            },
            {
                name: 'Caravelas',
                uf: 'BA',
                code: 2906907,
            },
            {
                name: 'Cardeal da Silva',
                uf: 'BA',
                code: 2907004,
            },
            {
                name: 'Carinhanha',
                uf: 'BA',
                code: 2907103,
            },
            {
                name: 'Casa Nova',
                uf: 'BA',
                code: 2907202,
            },
            {
                name: 'Castro Alves',
                uf: 'BA',
                code: 2907301,
            },
            {
                name: 'Catolândia',
                uf: 'BA',
                code: 2907400,
            },
            {
                name: 'Catu',
                uf: 'BA',
                code: 2907509,
            },
            {
                name: 'Caturama',
                uf: 'BA',
                code: 2907558,
            },
            {
                name: 'Central',
                uf: 'BA',
                code: 2907608,
            },
            {
                name: 'Chorrochó',
                uf: 'BA',
                code: 2907707,
            },
            {
                name: 'Cícero Dantas',
                uf: 'BA',
                code: 2907806,
            },
            {
                name: 'Cipó',
                uf: 'BA',
                code: 2907905,
            },
            {
                name: 'Coaraci',
                uf: 'BA',
                code: 2908002,
            },
            {
                name: 'Cocos',
                uf: 'BA',
                code: 2908101,
            },
            {
                name: 'Conceição da Feira',
                uf: 'BA',
                code: 2908200,
            },
            {
                name: 'Conceição do Almeida',
                uf: 'BA',
                code: 2908309,
            },
            {
                name: 'Conceição do Coité',
                uf: 'BA',
                code: 2908408,
            },
            {
                name: 'Conceição do Jacuípe',
                uf: 'BA',
                code: 2908507,
            },
            {
                name: 'Conde',
                uf: 'BA',
                code: 2908606,
            },
            {
                name: 'Condeúba',
                uf: 'BA',
                code: 2908705,
            },
            {
                name: 'Contendas do Sincorá',
                uf: 'BA',
                code: 2908804,
            },
            {
                name: 'Coração de Maria',
                uf: 'BA',
                code: 2908903,
            },
            {
                name: 'Cordeiros',
                uf: 'BA',
                code: 2909000,
            },
            {
                name: 'Coribe',
                uf: 'BA',
                code: 2909109,
            },
            {
                name: 'Coronel João Sá',
                uf: 'BA',
                code: 2909208,
            },
            {
                name: 'Correntina',
                uf: 'BA',
                code: 2909307,
            },
            {
                name: 'Cotegipe',
                uf: 'BA',
                code: 2909406,
            },
            {
                name: 'Cravolândia',
                uf: 'BA',
                code: 2909505,
            },
            {
                name: 'Crisópolis',
                uf: 'BA',
                code: 2909604,
            },
            {
                name: 'Cristópolis',
                uf: 'BA',
                code: 2909703,
            },
            {
                name: 'Cruz das Almas',
                uf: 'BA',
                code: 2909802,
            },
            {
                name: 'Curaçá',
                uf: 'BA',
                code: 2909901,
            },
            {
                name: 'Dário Meira',
                uf: 'BA',
                code: 2910008,
            },
            {
                name: "Dias d'Ávila",
                uf: 'BA',
                code: 2910057,
            },
            {
                name: 'Dom Basílio',
                uf: 'BA',
                code: 2910107,
            },
            {
                name: 'Dom Macedo Costa',
                uf: 'BA',
                code: 2910206,
            },
            {
                name: 'Elísio Medrado',
                uf: 'BA',
                code: 2910305,
            },
            {
                name: 'Encruzilhada',
                uf: 'BA',
                code: 2910404,
            },
            {
                name: 'Entre Rios',
                uf: 'BA',
                code: 2910503,
            },
            {
                name: 'Érico Cardoso',
                uf: 'BA',
                code: 2900504,
            },
            {
                name: 'Esplanada',
                uf: 'BA',
                code: 2910602,
            },
            {
                name: 'Euclides da Cunha',
                uf: 'BA',
                code: 2910701,
            },
            {
                name: 'Eunápolis',
                uf: 'BA',
                code: 2910727,
            },
            {
                name: 'Fátima',
                uf: 'BA',
                code: 2910750,
            },
            {
                name: 'Feira da Mata',
                uf: 'BA',
                code: 2910776,
            },
            {
                name: 'Feira de Santana',
                uf: 'BA',
                code: 2910800,
            },
            {
                name: 'Filadélfia',
                uf: 'BA',
                code: 2910859,
            },
            {
                name: 'Firmino Alves',
                uf: 'BA',
                code: 2910909,
            },
            {
                name: 'Floresta Azul',
                uf: 'BA',
                code: 2911006,
            },
            {
                name: 'Formosa do Rio Preto',
                uf: 'BA',
                code: 2911105,
            },
            {
                name: 'Gandu',
                uf: 'BA',
                code: 2911204,
            },
            {
                name: 'Gavião',
                uf: 'BA',
                code: 2911253,
            },
            {
                name: 'Gentio do Ouro',
                uf: 'BA',
                code: 2911303,
            },
            {
                name: 'Glória',
                uf: 'BA',
                code: 2911402,
            },
            {
                name: 'Gongogi',
                uf: 'BA',
                code: 2911501,
            },
            {
                name: 'Governador Mangabeira',
                uf: 'BA',
                code: 2911600,
            },
            {
                name: 'Guajeru',
                uf: 'BA',
                code: 2911659,
            },
            {
                name: 'Guanambi',
                uf: 'BA',
                code: 2911709,
            },
            {
                name: 'Guaratinga',
                uf: 'BA',
                code: 2911808,
            },
            {
                name: 'Heliópolis',
                uf: 'BA',
                code: 2911857,
            },
            {
                name: 'Iaçu',
                uf: 'BA',
                code: 2911907,
            },
            {
                name: 'Ibiassucê',
                uf: 'BA',
                code: 2912004,
            },
            {
                name: 'Ibicaraí',
                uf: 'BA',
                code: 2912103,
            },
            {
                name: 'Ibicoara',
                uf: 'BA',
                code: 2912202,
            },
            {
                name: 'Ibicuí',
                uf: 'BA',
                code: 2912301,
            },
            {
                name: 'Ibipeba',
                uf: 'BA',
                code: 2912400,
            },
            {
                name: 'Ibipitanga',
                uf: 'BA',
                code: 2912509,
            },
            {
                name: 'Ibiquera',
                uf: 'BA',
                code: 2912608,
            },
            {
                name: 'Ibirapitanga',
                uf: 'BA',
                code: 2912707,
            },
            {
                name: 'Ibirapuã',
                uf: 'BA',
                code: 2912806,
            },
            {
                name: 'Ibirataia',
                uf: 'BA',
                code: 2912905,
            },
            {
                name: 'Ibitiara',
                uf: 'BA',
                code: 2913002,
            },
            {
                name: 'Ibititá',
                uf: 'BA',
                code: 2913101,
            },
            {
                name: 'Ibotirama',
                uf: 'BA',
                code: 2913200,
            },
            {
                name: 'Ichu',
                uf: 'BA',
                code: 2913309,
            },
            {
                name: 'Igaporã',
                uf: 'BA',
                code: 2913408,
            },
            {
                name: 'Igrapiúna',
                uf: 'BA',
                code: 2913457,
            },
            {
                name: 'Iguaí',
                uf: 'BA',
                code: 2913507,
            },
            {
                name: 'Ilhéus',
                uf: 'BA',
                code: 2913606,
            },
            {
                name: 'Inhambupe',
                uf: 'BA',
                code: 2913705,
            },
            {
                name: 'Ipecaetá',
                uf: 'BA',
                code: 2913804,
            },
            {
                name: 'Ipiaú',
                uf: 'BA',
                code: 2913903,
            },
            {
                name: 'Ipirá',
                uf: 'BA',
                code: 2914000,
            },
            {
                name: 'Ipupiara',
                uf: 'BA',
                code: 2914109,
            },
            {
                name: 'Irajuba',
                uf: 'BA',
                code: 2914208,
            },
            {
                name: 'Iramaia',
                uf: 'BA',
                code: 2914307,
            },
            {
                name: 'Iraquara',
                uf: 'BA',
                code: 2914406,
            },
            {
                name: 'Irará',
                uf: 'BA',
                code: 2914505,
            },
            {
                name: 'Irecê',
                uf: 'BA',
                code: 2914604,
            },
            {
                name: 'Itabela',
                uf: 'BA',
                code: 2914653,
            },
            {
                name: 'Itaberaba',
                uf: 'BA',
                code: 2914703,
            },
            {
                name: 'Itabuna',
                uf: 'BA',
                code: 2914802,
            },
            {
                name: 'Itacaré',
                uf: 'BA',
                code: 2914901,
            },
            {
                name: 'Itaeté',
                uf: 'BA',
                code: 2915007,
            },
            {
                name: 'Itagi',
                uf: 'BA',
                code: 2915106,
            },
            {
                name: 'Itagibá',
                uf: 'BA',
                code: 2915205,
            },
            {
                name: 'Itagimirim',
                uf: 'BA',
                code: 2915304,
            },
            {
                name: 'Itaguaçu da Bahia',
                uf: 'BA',
                code: 2915353,
            },
            {
                name: 'Itaju do Colônia',
                uf: 'BA',
                code: 2915403,
            },
            {
                name: 'Itajuípe',
                uf: 'BA',
                code: 2915502,
            },
            {
                name: 'Itamaraju',
                uf: 'BA',
                code: 2915601,
            },
            {
                name: 'Itamari',
                uf: 'BA',
                code: 2915700,
            },
            {
                name: 'Itambé',
                uf: 'BA',
                code: 2915809,
            },
            {
                name: 'Itanagra',
                uf: 'BA',
                code: 2915908,
            },
            {
                name: 'Itanhém',
                uf: 'BA',
                code: 2916005,
            },
            {
                name: 'Itaparica',
                uf: 'BA',
                code: 2916104,
            },
            {
                name: 'Itapé',
                uf: 'BA',
                code: 2916203,
            },
            {
                name: 'Itapebi',
                uf: 'BA',
                code: 2916302,
            },
            {
                name: 'Itapetinga',
                uf: 'BA',
                code: 2916401,
            },
            {
                name: 'Itapicuru',
                uf: 'BA',
                code: 2916500,
            },
            {
                name: 'Itapitanga',
                uf: 'BA',
                code: 2916609,
            },
            {
                name: 'Itaquara',
                uf: 'BA',
                code: 2916708,
            },
            {
                name: 'Itarantim',
                uf: 'BA',
                code: 2916807,
            },
            {
                name: 'Itatim',
                uf: 'BA',
                code: 2916856,
            },
            {
                name: 'Itiruçu',
                uf: 'BA',
                code: 2916906,
            },
            {
                name: 'Itiúba',
                uf: 'BA',
                code: 2917003,
            },
            {
                name: 'Itororó',
                uf: 'BA',
                code: 2917102,
            },
            {
                name: 'Ituaçu',
                uf: 'BA',
                code: 2917201,
            },
            {
                name: 'Ituberá',
                uf: 'BA',
                code: 2917300,
            },
            {
                name: 'Iuiú',
                uf: 'BA',
                code: 2917334,
            },
            {
                name: 'Jaborandi',
                uf: 'BA',
                code: 2917359,
            },
            {
                name: 'Jacaraci',
                uf: 'BA',
                code: 2917409,
            },
            {
                name: 'Jacobina',
                uf: 'BA',
                code: 2917508,
            },
            {
                name: 'Jaguaquara',
                uf: 'BA',
                code: 2917607,
            },
            {
                name: 'Jaguarari',
                uf: 'BA',
                code: 2917706,
            },
            {
                name: 'Jaguaripe',
                uf: 'BA',
                code: 2917805,
            },
            {
                name: 'Jandaíra',
                uf: 'BA',
                code: 2917904,
            },
            {
                name: 'Jequié',
                uf: 'BA',
                code: 2918001,
            },
            {
                name: 'Jeremoabo',
                uf: 'BA',
                code: 2918100,
            },
            {
                name: 'Jiquiriçá',
                uf: 'BA',
                code: 2918209,
            },
            {
                name: 'Jitaúna',
                uf: 'BA',
                code: 2918308,
            },
            {
                name: 'João Dourado',
                uf: 'BA',
                code: 2918357,
            },
            {
                name: 'Juazeiro',
                uf: 'BA',
                code: 2918407,
            },
            {
                name: 'Jucuruçu',
                uf: 'BA',
                code: 2918456,
            },
            {
                name: 'Jussara',
                uf: 'BA',
                code: 2918506,
            },
            {
                name: 'Jussari',
                uf: 'BA',
                code: 2918555,
            },
            {
                name: 'Jussiape',
                uf: 'BA',
                code: 2918605,
            },
            {
                name: 'Lafaiete Coutinho',
                uf: 'BA',
                code: 2918704,
            },
            {
                name: 'Lagoa Real',
                uf: 'BA',
                code: 2918753,
            },
            {
                name: 'Laje',
                uf: 'BA',
                code: 2918803,
            },
            {
                name: 'Lajedão',
                uf: 'BA',
                code: 2918902,
            },
            {
                name: 'Lajedinho',
                uf: 'BA',
                code: 2919009,
            },
            {
                name: 'Lajedo do Tabocal',
                uf: 'BA',
                code: 2919058,
            },
            {
                name: 'Lamarão',
                uf: 'BA',
                code: 2919108,
            },
            {
                name: 'Lapão',
                uf: 'BA',
                code: 2919157,
            },
            {
                name: 'Lauro de Freitas',
                uf: 'BA',
                code: 2919207,
            },
            {
                name: 'Lençóis',
                uf: 'BA',
                code: 2919306,
            },
            {
                name: 'Licínio de Almeida',
                uf: 'BA',
                code: 2919405,
            },
            {
                name: 'Livramento de Nossa Senhora',
                uf: 'BA',
                code: 2919504,
            },
            {
                name: 'Luís Eduardo Magalhães',
                uf: 'BA',
                code: 2919553,
            },
            {
                name: 'Macajuba',
                uf: 'BA',
                code: 2919603,
            },
            {
                name: 'Macarani',
                uf: 'BA',
                code: 2919702,
            },
            {
                name: 'Macaúbas',
                uf: 'BA',
                code: 2919801,
            },
            {
                name: 'Macururé',
                uf: 'BA',
                code: 2919900,
            },
            {
                name: 'Madre de Deus',
                uf: 'BA',
                code: 2919926,
            },
            {
                name: 'Maetinga',
                uf: 'BA',
                code: 2919959,
            },
            {
                name: 'Maiquinique',
                uf: 'BA',
                code: 2920007,
            },
            {
                name: 'Mairi',
                uf: 'BA',
                code: 2920106,
            },
            {
                name: 'Malhada',
                uf: 'BA',
                code: 2920205,
            },
            {
                name: 'Malhada de Pedras',
                uf: 'BA',
                code: 2920304,
            },
            {
                name: 'Manoel Vitorino',
                uf: 'BA',
                code: 2920403,
            },
            {
                name: 'Mansidão',
                uf: 'BA',
                code: 2920452,
            },
            {
                name: 'Maracás',
                uf: 'BA',
                code: 2920502,
            },
            {
                name: 'Maragogipe',
                uf: 'BA',
                code: 2920601,
            },
            {
                name: 'Maraú',
                uf: 'BA',
                code: 2920700,
            },
            {
                name: 'Marcionílio Souza',
                uf: 'BA',
                code: 2920809,
            },
            {
                name: 'Mascote',
                uf: 'BA',
                code: 2920908,
            },
            {
                name: 'Mata de São João',
                uf: 'BA',
                code: 2921005,
            },
            {
                name: 'Matina',
                uf: 'BA',
                code: 2921054,
            },
            {
                name: 'Medeiros Neto',
                uf: 'BA',
                code: 2921104,
            },
            {
                name: 'Miguel Calmon',
                uf: 'BA',
                code: 2921203,
            },
            {
                name: 'Milagres',
                uf: 'BA',
                code: 2921302,
            },
            {
                name: 'Mirangaba',
                uf: 'BA',
                code: 2921401,
            },
            {
                name: 'Mirante',
                uf: 'BA',
                code: 2921450,
            },
            {
                name: 'Monte Santo',
                uf: 'BA',
                code: 2921500,
            },
            {
                name: 'Morpará',
                uf: 'BA',
                code: 2921609,
            },
            {
                name: 'Morro do Chapéu',
                uf: 'BA',
                code: 2921708,
            },
            {
                name: 'Mortugaba',
                uf: 'BA',
                code: 2921807,
            },
            {
                name: 'Mucugê',
                uf: 'BA',
                code: 2921906,
            },
            {
                name: 'Mucuri',
                uf: 'BA',
                code: 2922003,
            },
            {
                name: 'Mulungu do Morro',
                uf: 'BA',
                code: 2922052,
            },
            {
                name: 'Mundo Novo',
                uf: 'BA',
                code: 2922102,
            },
            {
                name: 'Muniz Ferreira',
                uf: 'BA',
                code: 2922201,
            },
            {
                name: 'Muquém de São Francisco',
                uf: 'BA',
                code: 2922250,
            },
            {
                name: 'Muritiba',
                uf: 'BA',
                code: 2922300,
            },
            {
                name: 'Mutuípe',
                uf: 'BA',
                code: 2922409,
            },
            {
                name: 'Nazaré',
                uf: 'BA',
                code: 2922508,
            },
            {
                name: 'Nilo Peçanha',
                uf: 'BA',
                code: 2922607,
            },
            {
                name: 'Nordestina',
                uf: 'BA',
                code: 2922656,
            },
            {
                name: 'Nova Canaã',
                uf: 'BA',
                code: 2922706,
            },
            {
                name: 'Nova Fátima',
                uf: 'BA',
                code: 2922730,
            },
            {
                name: 'Nova Ibiá',
                uf: 'BA',
                code: 2922755,
            },
            {
                name: 'Nova Itarana',
                uf: 'BA',
                code: 2922805,
            },
            {
                name: 'Nova Redenção',
                uf: 'BA',
                code: 2922854,
            },
            {
                name: 'Nova Soure',
                uf: 'BA',
                code: 2922904,
            },
            {
                name: 'Nova Viçosa',
                uf: 'BA',
                code: 2923001,
            },
            {
                name: 'Novo Horizonte',
                uf: 'BA',
                code: 2923035,
            },
            {
                name: 'Novo Triunfo',
                uf: 'BA',
                code: 2923050,
            },
            {
                name: 'Olindina',
                uf: 'BA',
                code: 2923100,
            },
            {
                name: 'Oliveira dos Brejinhos',
                uf: 'BA',
                code: 2923209,
            },
            {
                name: 'Ouriçangas',
                uf: 'BA',
                code: 2923308,
            },
            {
                name: 'Ourolândia',
                uf: 'BA',
                code: 2923357,
            },
            {
                name: 'Palmas de Monte Alto',
                uf: 'BA',
                code: 2923407,
            },
            {
                name: 'Palmeiras',
                uf: 'BA',
                code: 2923506,
            },
            {
                name: 'Paramirim',
                uf: 'BA',
                code: 2923605,
            },
            {
                name: 'Paratinga',
                uf: 'BA',
                code: 2923704,
            },
            {
                name: 'Paripiranga',
                uf: 'BA',
                code: 2923803,
            },
            {
                name: 'Pau Brasil',
                uf: 'BA',
                code: 2923902,
            },
            {
                name: 'Paulo Afonso',
                uf: 'BA',
                code: 2924009,
            },
            {
                name: 'Pé de Serra',
                uf: 'BA',
                code: 2924058,
            },
            {
                name: 'Pedrão',
                uf: 'BA',
                code: 2924108,
            },
            {
                name: 'Pedro Alexandre',
                uf: 'BA',
                code: 2924207,
            },
            {
                name: 'Piatã',
                uf: 'BA',
                code: 2924306,
            },
            {
                name: 'Pilão Arcado',
                uf: 'BA',
                code: 2924405,
            },
            {
                name: 'Pindaí',
                uf: 'BA',
                code: 2924504,
            },
            {
                name: 'Pindobaçu',
                uf: 'BA',
                code: 2924603,
            },
            {
                name: 'Pintadas',
                uf: 'BA',
                code: 2924652,
            },
            {
                name: 'Piraí do Norte',
                uf: 'BA',
                code: 2924678,
            },
            {
                name: 'Piripá',
                uf: 'BA',
                code: 2924702,
            },
            {
                name: 'Piritiba',
                uf: 'BA',
                code: 2924801,
            },
            {
                name: 'Planaltino',
                uf: 'BA',
                code: 2924900,
            },
            {
                name: 'Planalto',
                uf: 'BA',
                code: 2925006,
            },
            {
                name: 'Poções',
                uf: 'BA',
                code: 2925105,
            },
            {
                name: 'Pojuca',
                uf: 'BA',
                code: 2925204,
            },
            {
                name: 'Ponto Novo',
                uf: 'BA',
                code: 2925253,
            },
            {
                name: 'Porto Seguro',
                uf: 'BA',
                code: 2925303,
            },
            {
                name: 'Potiraguá',
                uf: 'BA',
                code: 2925402,
            },
            {
                name: 'Prado',
                uf: 'BA',
                code: 2925501,
            },
            {
                name: 'Presidente Dutra',
                uf: 'BA',
                code: 2925600,
            },
            {
                name: 'Presidente Jânio Quadros',
                uf: 'BA',
                code: 2925709,
            },
            {
                name: 'Presidente Tancredo Neves',
                uf: 'BA',
                code: 2925758,
            },
            {
                name: 'Queimadas',
                uf: 'BA',
                code: 2925808,
            },
            {
                name: 'Quijingue',
                uf: 'BA',
                code: 2925907,
            },
            {
                name: 'Quixabeira',
                uf: 'BA',
                code: 2925931,
            },
            {
                name: 'Rafael Jambeiro',
                uf: 'BA',
                code: 2925956,
            },
            {
                name: 'Remanso',
                uf: 'BA',
                code: 2926004,
            },
            {
                name: 'Retirolândia',
                uf: 'BA',
                code: 2926103,
            },
            {
                name: 'Riachão das Neves',
                uf: 'BA',
                code: 2926202,
            },
            {
                name: 'Riachão do Jacuípe',
                uf: 'BA',
                code: 2926301,
            },
            {
                name: 'Riacho de Santana',
                uf: 'BA',
                code: 2926400,
            },
            {
                name: 'Ribeira do Amparo',
                uf: 'BA',
                code: 2926509,
            },
            {
                name: 'Ribeira do Pombal',
                uf: 'BA',
                code: 2926608,
            },
            {
                name: 'Ribeirão do Largo',
                uf: 'BA',
                code: 2926657,
            },
            {
                name: 'Rio de Contas',
                uf: 'BA',
                code: 2926707,
            },
            {
                name: 'Rio do Antônio',
                uf: 'BA',
                code: 2926806,
            },
            {
                name: 'Rio do Pires',
                uf: 'BA',
                code: 2926905,
            },
            {
                name: 'Rio Real',
                uf: 'BA',
                code: 2927002,
            },
            {
                name: 'Rodelas',
                uf: 'BA',
                code: 2927101,
            },
            {
                name: 'Ruy Barbosa',
                uf: 'BA',
                code: 2927200,
            },
            {
                name: 'Salinas da Margarida',
                uf: 'BA',
                code: 2927309,
            },
            {
                name: 'Salvador',
                uf: 'BA',
                code: 2927408,
            },
            {
                name: 'Santa Bárbara',
                uf: 'BA',
                code: 2927507,
            },
            {
                name: 'Santa Brígida',
                uf: 'BA',
                code: 2927606,
            },
            {
                name: 'Santa Cruz Cabrália',
                uf: 'BA',
                code: 2927705,
            },
            {
                name: 'Santa Cruz da Vitória',
                uf: 'BA',
                code: 2927804,
            },
            {
                name: 'Santa Inês',
                uf: 'BA',
                code: 2927903,
            },
            {
                name: 'Santa Luzia',
                uf: 'BA',
                code: 2928059,
            },
            {
                name: 'Santa Maria da Vitória',
                uf: 'BA',
                code: 2928109,
            },
            {
                name: 'Santa Rita de Cássia',
                uf: 'BA',
                code: 2928406,
            },
            {
                name: 'Santa Teresinha',
                uf: 'BA',
                code: 2928505,
            },
            {
                name: 'Santaluz',
                uf: 'BA',
                code: 2928000,
            },
            {
                name: 'Santana',
                uf: 'BA',
                code: 2928208,
            },
            {
                name: 'Santanópolis',
                uf: 'BA',
                code: 2928307,
            },
            {
                name: 'Santo Amaro',
                uf: 'BA',
                code: 2928604,
            },
            {
                name: 'Santo Antônio de Jesus',
                uf: 'BA',
                code: 2928703,
            },
            {
                name: 'Santo Estêvão',
                uf: 'BA',
                code: 2928802,
            },
            {
                name: 'São Desidério',
                uf: 'BA',
                code: 2928901,
            },
            {
                name: 'São Domingos',
                uf: 'BA',
                code: 2928950,
            },
            {
                name: 'São Felipe',
                uf: 'BA',
                code: 2929107,
            },
            {
                name: 'São Félix',
                uf: 'BA',
                code: 2929008,
            },
            {
                name: 'São Félix do Coribe',
                uf: 'BA',
                code: 2929057,
            },
            {
                name: 'São Francisco do Conde',
                uf: 'BA',
                code: 2929206,
            },
            {
                name: 'São Gabriel',
                uf: 'BA',
                code: 2929255,
            },
            {
                name: 'São Gonçalo dos Campos',
                uf: 'BA',
                code: 2929305,
            },
            {
                name: 'São José da Vitória',
                uf: 'BA',
                code: 2929354,
            },
            {
                name: 'São José do Jacuípe',
                uf: 'BA',
                code: 2929370,
            },
            {
                name: 'São Miguel das Matas',
                uf: 'BA',
                code: 2929404,
            },
            {
                name: 'São Sebastião do Passé',
                uf: 'BA',
                code: 2929503,
            },
            {
                name: 'Sapeaçu',
                uf: 'BA',
                code: 2929602,
            },
            {
                name: 'Sátiro Dias',
                uf: 'BA',
                code: 2929701,
            },
            {
                name: 'Saubara',
                uf: 'BA',
                code: 2929750,
            },
            {
                name: 'Saúde',
                uf: 'BA',
                code: 2929800,
            },
            {
                name: 'Seabra',
                uf: 'BA',
                code: 2929909,
            },
            {
                name: 'Sebastião Laranjeiras',
                uf: 'BA',
                code: 2930006,
            },
            {
                name: 'Senhor do Bonfim',
                uf: 'BA',
                code: 2930105,
            },
            {
                name: 'Sento Sé',
                uf: 'BA',
                code: 2930204,
            },
            {
                name: 'Serra do Ramalho',
                uf: 'BA',
                code: 2930154,
            },
            {
                name: 'Serra Dourada',
                uf: 'BA',
                code: 2930303,
            },
            {
                name: 'Serra Preta',
                uf: 'BA',
                code: 2930402,
            },
            {
                name: 'Serrinha',
                uf: 'BA',
                code: 2930501,
            },
            {
                name: 'Serrolândia',
                uf: 'BA',
                code: 2930600,
            },
            {
                name: 'Simões Filho',
                uf: 'BA',
                code: 2930709,
            },
            {
                name: 'Sítio do Mato',
                uf: 'BA',
                code: 2930758,
            },
            {
                name: 'Sítio do Quinto',
                uf: 'BA',
                code: 2930766,
            },
            {
                name: 'Sobradinho',
                uf: 'BA',
                code: 2930774,
            },
            {
                name: 'Souto Soares',
                uf: 'BA',
                code: 2930808,
            },
            {
                name: 'Tabocas do Brejo Velho',
                uf: 'BA',
                code: 2930907,
            },
            {
                name: 'Tanhaçu',
                uf: 'BA',
                code: 2931004,
            },
            {
                name: 'Tanque Novo',
                uf: 'BA',
                code: 2931053,
            },
            {
                name: 'Tanquinho',
                uf: 'BA',
                code: 2931103,
            },
            {
                name: 'Taperoá',
                uf: 'BA',
                code: 2931202,
            },
            {
                name: 'Tapiramutá',
                uf: 'BA',
                code: 2931301,
            },
            {
                name: 'Teixeira de Freitas',
                uf: 'BA',
                code: 2931350,
            },
            {
                name: 'Teodoro Sampaio',
                uf: 'BA',
                code: 2931400,
            },
            {
                name: 'Teofilândia',
                uf: 'BA',
                code: 2931509,
            },
            {
                name: 'Teolândia',
                uf: 'BA',
                code: 2931608,
            },
            {
                name: 'Terra Nova',
                uf: 'BA',
                code: 2931707,
            },
            {
                name: 'Tremedal',
                uf: 'BA',
                code: 2931806,
            },
            {
                name: 'Tucano',
                uf: 'BA',
                code: 2931905,
            },
            {
                name: 'Uauá',
                uf: 'BA',
                code: 2932002,
            },
            {
                name: 'Ubaíra',
                uf: 'BA',
                code: 2932101,
            },
            {
                name: 'Ubaitaba',
                uf: 'BA',
                code: 2932200,
            },
            {
                name: 'Ubatã',
                uf: 'BA',
                code: 2932309,
            },
            {
                name: 'Uibaí',
                uf: 'BA',
                code: 2932408,
            },
            {
                name: 'Umburanas',
                uf: 'BA',
                code: 2932457,
            },
            {
                name: 'Una',
                uf: 'BA',
                code: 2932507,
            },
            {
                name: 'Urandi',
                uf: 'BA',
                code: 2932606,
            },
            {
                name: 'Uruçuca',
                uf: 'BA',
                code: 2932705,
            },
            {
                name: 'Utinga',
                uf: 'BA',
                code: 2932804,
            },
            {
                name: 'Valença',
                uf: 'BA',
                code: 2932903,
            },
            {
                name: 'Valente',
                uf: 'BA',
                code: 2933000,
            },
            {
                name: 'Várzea da Roça',
                uf: 'BA',
                code: 2933059,
            },
            {
                name: 'Várzea do Poço',
                uf: 'BA',
                code: 2933109,
            },
            {
                name: 'Várzea Nova',
                uf: 'BA',
                code: 2933158,
            },
            {
                name: 'Varzedo',
                uf: 'BA',
                code: 2933174,
            },
            {
                name: 'Vera Cruz',
                uf: 'BA',
                code: 2933208,
            },
            {
                name: 'Vereda',
                uf: 'BA',
                code: 2933257,
            },
            {
                name: 'Vitória da Conquista',
                uf: 'BA',
                code: 2933307,
            },
            {
                name: 'Wagner',
                uf: 'BA',
                code: 2933406,
            },
            {
                name: 'Wanderley',
                uf: 'BA',
                code: 2933455,
            },
            {
                name: 'Wenceslau Guimarães',
                uf: 'BA',
                code: 2933505,
            },
            {
                name: 'Xique-Xique',
                uf: 'BA',
                code: 2933604,
            },
            {
                name: 'Abaiara',
                uf: 'CE',
                code: 2300101,
            },
            {
                name: 'Acarape',
                uf: 'CE',
                code: 2300150,
            },
            {
                name: 'Acaraú',
                uf: 'CE',
                code: 2300200,
            },
            {
                name: 'Acopiara',
                uf: 'CE',
                code: 2300309,
            },
            {
                name: 'Aiuaba',
                uf: 'CE',
                code: 2300408,
            },
            {
                name: 'Alcântaras',
                uf: 'CE',
                code: 2300507,
            },
            {
                name: 'Altaneira',
                uf: 'CE',
                code: 2300606,
            },
            {
                name: 'Alto Santo',
                uf: 'CE',
                code: 2300705,
            },
            {
                name: 'Amontada',
                uf: 'CE',
                code: 2300754,
            },
            {
                name: 'Antonina do Norte',
                uf: 'CE',
                code: 2300804,
            },
            {
                name: 'Apuiarés',
                uf: 'CE',
                code: 2300903,
            },
            {
                name: 'Aquiraz',
                uf: 'CE',
                code: 2301000,
            },
            {
                name: 'Aracati',
                uf: 'CE',
                code: 2301109,
            },
            {
                name: 'Aracoiaba',
                uf: 'CE',
                code: 2301208,
            },
            {
                name: 'Ararendá',
                uf: 'CE',
                code: 2301257,
            },
            {
                name: 'Araripe',
                uf: 'CE',
                code: 2301307,
            },
            {
                name: 'Aratuba',
                uf: 'CE',
                code: 2301406,
            },
            {
                name: 'Arneiroz',
                uf: 'CE',
                code: 2301505,
            },
            {
                name: 'Assaré',
                uf: 'CE',
                code: 2301604,
            },
            {
                name: 'Aurora',
                uf: 'CE',
                code: 2301703,
            },
            {
                name: 'Baixio',
                uf: 'CE',
                code: 2301802,
            },
            {
                name: 'Banabuiú',
                uf: 'CE',
                code: 2301851,
            },
            {
                name: 'Barbalha',
                uf: 'CE',
                code: 2301901,
            },
            {
                name: 'Barreira',
                uf: 'CE',
                code: 2301950,
            },
            {
                name: 'Barro',
                uf: 'CE',
                code: 2302008,
            },
            {
                name: 'Barroquinha',
                uf: 'CE',
                code: 2302057,
            },
            {
                name: 'Baturité',
                uf: 'CE',
                code: 2302107,
            },
            {
                name: 'Beberibe',
                uf: 'CE',
                code: 2302206,
            },
            {
                name: 'Bela Cruz',
                uf: 'CE',
                code: 2302305,
            },
            {
                name: 'Boa Viagem',
                uf: 'CE',
                code: 2302404,
            },
            {
                name: 'Brejo Santo',
                uf: 'CE',
                code: 2302503,
            },
            {
                name: 'Camocim',
                uf: 'CE',
                code: 2302602,
            },
            {
                name: 'Campos Sales',
                uf: 'CE',
                code: 2302701,
            },
            {
                name: 'Canindé',
                uf: 'CE',
                code: 2302800,
            },
            {
                name: 'Capistrano',
                uf: 'CE',
                code: 2302909,
            },
            {
                name: 'Caridade',
                uf: 'CE',
                code: 2303006,
            },
            {
                name: 'Cariré',
                uf: 'CE',
                code: 2303105,
            },
            {
                name: 'Caririaçu',
                uf: 'CE',
                code: 2303204,
            },
            {
                name: 'Cariús',
                uf: 'CE',
                code: 2303303,
            },
            {
                name: 'Carnaubal',
                uf: 'CE',
                code: 2303402,
            },
            {
                name: 'Cascavel',
                uf: 'CE',
                code: 2303501,
            },
            {
                name: 'Catarina',
                uf: 'CE',
                code: 2303600,
            },
            {
                name: 'Catunda',
                uf: 'CE',
                code: 2303659,
            },
            {
                name: 'Caucaia',
                uf: 'CE',
                code: 2303709,
            },
            {
                name: 'Cedro',
                uf: 'CE',
                code: 2303808,
            },
            {
                name: 'Chaval',
                uf: 'CE',
                code: 2303907,
            },
            {
                name: 'Choró',
                uf: 'CE',
                code: 2303931,
            },
            {
                name: 'Chorozinho',
                uf: 'CE',
                code: 2303956,
            },
            {
                name: 'Coreaú',
                uf: 'CE',
                code: 2304004,
            },
            {
                name: 'Crateús',
                uf: 'CE',
                code: 2304103,
            },
            {
                name: 'Crato',
                uf: 'CE',
                code: 2304202,
            },
            {
                name: 'Croatá',
                uf: 'CE',
                code: 2304236,
            },
            {
                name: 'Cruz',
                uf: 'CE',
                code: 2304251,
            },
            {
                name: 'Deputado Irapuan Pinheiro',
                uf: 'CE',
                code: 2304269,
            },
            {
                name: 'Ererê',
                uf: 'CE',
                code: 2304277,
            },
            {
                name: 'Eusébio',
                uf: 'CE',
                code: 2304285,
            },
            {
                name: 'Farias Brito',
                uf: 'CE',
                code: 2304301,
            },
            {
                name: 'Forquilha',
                uf: 'CE',
                code: 2304350,
            },
            {
                name: 'Fortaleza',
                uf: 'CE',
                code: 2304400,
            },
            {
                name: 'Fortim',
                uf: 'CE',
                code: 2304459,
            },
            {
                name: 'Frecheirinha',
                uf: 'CE',
                code: 2304509,
            },
            {
                name: 'General Sampaio',
                uf: 'CE',
                code: 2304608,
            },
            {
                name: 'Graça',
                uf: 'CE',
                code: 2304657,
            },
            {
                name: 'Granja',
                uf: 'CE',
                code: 2304707,
            },
            {
                name: 'Granjeiro',
                uf: 'CE',
                code: 2304806,
            },
            {
                name: 'Groaíras',
                uf: 'CE',
                code: 2304905,
            },
            {
                name: 'Guaiúba',
                uf: 'CE',
                code: 2304954,
            },
            {
                name: 'Guaraciaba do Norte',
                uf: 'CE',
                code: 2305001,
            },
            {
                name: 'Guaramiranga',
                uf: 'CE',
                code: 2305100,
            },
            {
                name: 'Hidrolândia',
                uf: 'CE',
                code: 2305209,
            },
            {
                name: 'Horizonte',
                uf: 'CE',
                code: 2305233,
            },
            {
                name: 'Ibaretama',
                uf: 'CE',
                code: 2305266,
            },
            {
                name: 'Ibiapina',
                uf: 'CE',
                code: 2305308,
            },
            {
                name: 'Ibicuitinga',
                uf: 'CE',
                code: 2305332,
            },
            {
                name: 'Icapuí',
                uf: 'CE',
                code: 2305357,
            },
            {
                name: 'Icó',
                uf: 'CE',
                code: 2305407,
            },
            {
                name: 'Iguatu',
                uf: 'CE',
                code: 2305506,
            },
            {
                name: 'Independência',
                uf: 'CE',
                code: 2305605,
            },
            {
                name: 'Ipaporanga',
                uf: 'CE',
                code: 2305654,
            },
            {
                name: 'Ipaumirim',
                uf: 'CE',
                code: 2305704,
            },
            {
                name: 'Ipu',
                uf: 'CE',
                code: 2305803,
            },
            {
                name: 'Ipueiras',
                uf: 'CE',
                code: 2305902,
            },
            {
                name: 'Iracema',
                uf: 'CE',
                code: 2306009,
            },
            {
                name: 'Irauçuba',
                uf: 'CE',
                code: 2306108,
            },
            {
                name: 'Itaiçaba',
                uf: 'CE',
                code: 2306207,
            },
            {
                name: 'Itaitinga',
                uf: 'CE',
                code: 2306256,
            },
            {
                name: 'Itapagé',
                uf: 'CE',
                code: 2306306,
            },
            {
                name: 'Itapipoca',
                uf: 'CE',
                code: 2306405,
            },
            {
                name: 'Itapiúna',
                uf: 'CE',
                code: 2306504,
            },
            {
                name: 'Itarema',
                uf: 'CE',
                code: 2306553,
            },
            {
                name: 'Itatira',
                uf: 'CE',
                code: 2306603,
            },
            {
                name: 'Jaguaretama',
                uf: 'CE',
                code: 2306702,
            },
            {
                name: 'Jaguaribara',
                uf: 'CE',
                code: 2306801,
            },
            {
                name: 'Jaguaribe',
                uf: 'CE',
                code: 2306900,
            },
            {
                name: 'Jaguaruana',
                uf: 'CE',
                code: 2307007,
            },
            {
                name: 'Jardim',
                uf: 'CE',
                code: 2307106,
            },
            {
                name: 'Jati',
                uf: 'CE',
                code: 2307205,
            },
            {
                name: 'Jijoca de Jericoacoara',
                uf: 'CE',
                code: 2307254,
            },
            {
                name: 'Juazeiro do Norte',
                uf: 'CE',
                code: 2307304,
            },
            {
                name: 'Jucás',
                uf: 'CE',
                code: 2307403,
            },
            {
                name: 'Lavras da Mangabeira',
                uf: 'CE',
                code: 2307502,
            },
            {
                name: 'Limoeiro do Norte',
                uf: 'CE',
                code: 2307601,
            },
            {
                name: 'Madalena',
                uf: 'CE',
                code: 2307635,
            },
            {
                name: 'Maracanaú',
                uf: 'CE',
                code: 2307650,
            },
            {
                name: 'Maranguape',
                uf: 'CE',
                code: 2307700,
            },
            {
                name: 'Marco',
                uf: 'CE',
                code: 2307809,
            },
            {
                name: 'Martinópole',
                uf: 'CE',
                code: 2307908,
            },
            {
                name: 'Massapê',
                uf: 'CE',
                code: 2308005,
            },
            {
                name: 'Mauriti',
                uf: 'CE',
                code: 2308104,
            },
            {
                name: 'Meruoca',
                uf: 'CE',
                code: 2308203,
            },
            {
                name: 'Milagres',
                uf: 'CE',
                code: 2308302,
            },
            {
                name: 'Milhã',
                uf: 'CE',
                code: 2308351,
            },
            {
                name: 'Miraíma',
                uf: 'CE',
                code: 2308377,
            },
            {
                name: 'Missão Velha',
                uf: 'CE',
                code: 2308401,
            },
            {
                name: 'Mombaça',
                uf: 'CE',
                code: 2308500,
            },
            {
                name: 'Monsenhor Tabosa',
                uf: 'CE',
                code: 2308609,
            },
            {
                name: 'Morada Nova',
                uf: 'CE',
                code: 2308708,
            },
            {
                name: 'Moraújo',
                uf: 'CE',
                code: 2308807,
            },
            {
                name: 'Morrinhos',
                uf: 'CE',
                code: 2308906,
            },
            {
                name: 'Mucambo',
                uf: 'CE',
                code: 2309003,
            },
            {
                name: 'Mulungu',
                uf: 'CE',
                code: 2309102,
            },
            {
                name: 'Nova Olinda',
                uf: 'CE',
                code: 2309201,
            },
            {
                name: 'Nova Russas',
                uf: 'CE',
                code: 2309300,
            },
            {
                name: 'Novo Oriente',
                uf: 'CE',
                code: 2309409,
            },
            {
                name: 'Ocara',
                uf: 'CE',
                code: 2309458,
            },
            {
                name: 'Orós',
                uf: 'CE',
                code: 2309508,
            },
            {
                name: 'Pacajus',
                uf: 'CE',
                code: 2309607,
            },
            {
                name: 'Pacatuba',
                uf: 'CE',
                code: 2309706,
            },
            {
                name: 'Pacoti',
                uf: 'CE',
                code: 2309805,
            },
            {
                name: 'Pacujá',
                uf: 'CE',
                code: 2309904,
            },
            {
                name: 'Palhano',
                uf: 'CE',
                code: 2310001,
            },
            {
                name: 'Palmácia',
                uf: 'CE',
                code: 2310100,
            },
            {
                name: 'Paracuru',
                uf: 'CE',
                code: 2310209,
            },
            {
                name: 'Paraipaba',
                uf: 'CE',
                code: 2310258,
            },
            {
                name: 'Parambu',
                uf: 'CE',
                code: 2310308,
            },
            {
                name: 'Paramoti',
                uf: 'CE',
                code: 2310407,
            },
            {
                name: 'Pedra Branca',
                uf: 'CE',
                code: 2310506,
            },
            {
                name: 'Penaforte',
                uf: 'CE',
                code: 2310605,
            },
            {
                name: 'Pentecoste',
                uf: 'CE',
                code: 2310704,
            },
            {
                name: 'Pereiro',
                uf: 'CE',
                code: 2310803,
            },
            {
                name: 'Pindoretama',
                uf: 'CE',
                code: 2310852,
            },
            {
                name: 'Piquet Carneiro',
                uf: 'CE',
                code: 2310902,
            },
            {
                name: 'Pires Ferreira',
                uf: 'CE',
                code: 2310951,
            },
            {
                name: 'Poranga',
                uf: 'CE',
                code: 2311009,
            },
            {
                name: 'Porteiras',
                uf: 'CE',
                code: 2311108,
            },
            {
                name: 'Potengi',
                uf: 'CE',
                code: 2311207,
            },
            {
                name: 'Potiretama',
                uf: 'CE',
                code: 2311231,
            },
            {
                name: 'Quiterianópolis',
                uf: 'CE',
                code: 2311264,
            },
            {
                name: 'Quixadá',
                uf: 'CE',
                code: 2311306,
            },
            {
                name: 'Quixelô',
                uf: 'CE',
                code: 2311355,
            },
            {
                name: 'Quixeramobim',
                uf: 'CE',
                code: 2311405,
            },
            {
                name: 'Quixeré',
                uf: 'CE',
                code: 2311504,
            },
            {
                name: 'Redenção',
                uf: 'CE',
                code: 2311603,
            },
            {
                name: 'Reriutaba',
                uf: 'CE',
                code: 2311702,
            },
            {
                name: 'Russas',
                uf: 'CE',
                code: 2311801,
            },
            {
                name: 'Saboeiro',
                uf: 'CE',
                code: 2311900,
            },
            {
                name: 'Salitre',
                uf: 'CE',
                code: 2311959,
            },
            {
                name: 'Santa Quitéria',
                uf: 'CE',
                code: 2312205,
            },
            {
                name: 'Santana do Acaraú',
                uf: 'CE',
                code: 2312007,
            },
            {
                name: 'Santana do Cariri',
                uf: 'CE',
                code: 2312106,
            },
            {
                name: 'São Benedito',
                uf: 'CE',
                code: 2312304,
            },
            {
                name: 'São Gonçalo do Amarante',
                uf: 'CE',
                code: 2312403,
            },
            {
                name: 'São João do Jaguaribe',
                uf: 'CE',
                code: 2312502,
            },
            {
                name: 'São Luís do Curu',
                uf: 'CE',
                code: 2312601,
            },
            {
                name: 'Senador Pompeu',
                uf: 'CE',
                code: 2312700,
            },
            {
                name: 'Senador Sá',
                uf: 'CE',
                code: 2312809,
            },
            {
                name: 'Sobral',
                uf: 'CE',
                code: 2312908,
            },
            {
                name: 'Solonópole',
                uf: 'CE',
                code: 2313005,
            },
            {
                name: 'Tabuleiro do Norte',
                uf: 'CE',
                code: 2313104,
            },
            {
                name: 'Tamboril',
                uf: 'CE',
                code: 2313203,
            },
            {
                name: 'Tarrafas',
                uf: 'CE',
                code: 2313252,
            },
            {
                name: 'Tauá',
                uf: 'CE',
                code: 2313302,
            },
            {
                name: 'Tejuçuoca',
                uf: 'CE',
                code: 2313351,
            },
            {
                name: 'Tianguá',
                uf: 'CE',
                code: 2313401,
            },
            {
                name: 'Trairi',
                uf: 'CE',
                code: 2313500,
            },
            {
                name: 'Tururu',
                uf: 'CE',
                code: 2313559,
            },
            {
                name: 'Ubajara',
                uf: 'CE',
                code: 2313609,
            },
            {
                name: 'Umari',
                uf: 'CE',
                code: 2313708,
            },
            {
                name: 'Umirim',
                uf: 'CE',
                code: 2313757,
            },
            {
                name: 'Uruburetama',
                uf: 'CE',
                code: 2313807,
            },
            {
                name: 'Uruoca',
                uf: 'CE',
                code: 2313906,
            },
            {
                name: 'Varjota',
                uf: 'CE',
                code: 2313955,
            },
            {
                name: 'Várzea Alegre',
                uf: 'CE',
                code: 2314003,
            },
            {
                name: 'Viçosa do Ceará',
                uf: 'CE',
                code: 2314102,
            },
            {
                name: 'Brasília',
                uf: 'DF',
                code: 5300108,
            },
            {
                name: 'Afonso Cláudio',
                uf: 'ES',
                code: 3200102,
            },
            {
                name: 'Água Doce do Norte',
                uf: 'ES',
                code: 3200169,
            },
            {
                name: 'Águia Branca',
                uf: 'ES',
                code: 3200136,
            },
            {
                name: 'Alegre',
                uf: 'ES',
                code: 3200201,
            },
            {
                name: 'Alfredo Chaves',
                uf: 'ES',
                code: 3200300,
            },
            {
                name: 'Alto Rio Novo',
                uf: 'ES',
                code: 3200359,
            },
            {
                name: 'Anchieta',
                uf: 'ES',
                code: 3200409,
            },
            {
                name: 'Apiacá',
                uf: 'ES',
                code: 3200508,
            },
            {
                name: 'Aracruz',
                uf: 'ES',
                code: 3200607,
            },
            {
                name: 'Atilio Vivacqua',
                uf: 'ES',
                code: 3200706,
            },
            {
                name: 'Baixo Guandu',
                uf: 'ES',
                code: 3200805,
            },
            {
                name: 'Barra de São Francisco',
                uf: 'ES',
                code: 3200904,
            },
            {
                name: 'Boa Esperança',
                uf: 'ES',
                code: 3201001,
            },
            {
                name: 'Bom Jesus do Norte',
                uf: 'ES',
                code: 3201100,
            },
            {
                name: 'Brejetuba',
                uf: 'ES',
                code: 3201159,
            },
            {
                name: 'Cachoeiro de Itapemirim',
                uf: 'ES',
                code: 3201209,
            },
            {
                name: 'Cariacica',
                uf: 'ES',
                code: 3201308,
            },
            {
                name: 'Castelo',
                uf: 'ES',
                code: 3201407,
            },
            {
                name: 'Colatina',
                uf: 'ES',
                code: 3201506,
            },
            {
                name: 'Conceição da Barra',
                uf: 'ES',
                code: 3201605,
            },
            {
                name: 'Conceição do Castelo',
                uf: 'ES',
                code: 3201704,
            },
            {
                name: 'Divino de São Lourenço',
                uf: 'ES',
                code: 3201803,
            },
            {
                name: 'Domingos Martins',
                uf: 'ES',
                code: 3201902,
            },
            {
                name: 'Dores do Rio Preto',
                uf: 'ES',
                code: 3202009,
            },
            {
                name: 'Ecoporanga',
                uf: 'ES',
                code: 3202108,
            },
            {
                name: 'Fundão',
                uf: 'ES',
                code: 3202207,
            },
            {
                name: 'Governador Lindenberg',
                uf: 'ES',
                code: 3202256,
            },
            {
                name: 'Guaçuí',
                uf: 'ES',
                code: 3202306,
            },
            {
                name: 'Guarapari',
                uf: 'ES',
                code: 3202405,
            },
            {
                name: 'Ibatiba',
                uf: 'ES',
                code: 3202454,
            },
            {
                name: 'Ibiraçu',
                uf: 'ES',
                code: 3202504,
            },
            {
                name: 'Ibitirama',
                uf: 'ES',
                code: 3202553,
            },
            {
                name: 'Iconha',
                uf: 'ES',
                code: 3202603,
            },
            {
                name: 'Irupi',
                uf: 'ES',
                code: 3202652,
            },
            {
                name: 'Itaguaçu',
                uf: 'ES',
                code: 3202702,
            },
            {
                name: 'Itapemirim',
                uf: 'ES',
                code: 3202801,
            },
            {
                name: 'Itarana',
                uf: 'ES',
                code: 3202900,
            },
            {
                name: 'Iúna',
                uf: 'ES',
                code: 3203007,
            },
            {
                name: 'Jaguaré',
                uf: 'ES',
                code: 3203056,
            },
            {
                name: 'Jerônimo Monteiro',
                uf: 'ES',
                code: 3203106,
            },
            {
                name: 'João Neiva',
                uf: 'ES',
                code: 3203130,
            },
            {
                name: 'Laranja da Terra',
                uf: 'ES',
                code: 3203163,
            },
            {
                name: 'Linhares',
                uf: 'ES',
                code: 3203205,
            },
            {
                name: 'Mantenópolis',
                uf: 'ES',
                code: 3203304,
            },
            {
                name: 'Marataízes',
                uf: 'ES',
                code: 3203320,
            },
            {
                name: 'Marechal Floriano',
                uf: 'ES',
                code: 3203346,
            },
            {
                name: 'Marilândia',
                uf: 'ES',
                code: 3203353,
            },
            {
                name: 'Mimoso do Sul',
                uf: 'ES',
                code: 3203403,
            },
            {
                name: 'Montanha',
                uf: 'ES',
                code: 3203502,
            },
            {
                name: 'Mucurici',
                uf: 'ES',
                code: 3203601,
            },
            {
                name: 'Muniz Freire',
                uf: 'ES',
                code: 3203700,
            },
            {
                name: 'Muqui',
                uf: 'ES',
                code: 3203809,
            },
            {
                name: 'Nova Venécia',
                uf: 'ES',
                code: 3203908,
            },
            {
                name: 'Pancas',
                uf: 'ES',
                code: 3204005,
            },
            {
                name: 'Pedro Canário',
                uf: 'ES',
                code: 3204054,
            },
            {
                name: 'Pinheiros',
                uf: 'ES',
                code: 3204104,
            },
            {
                name: 'Piúma',
                uf: 'ES',
                code: 3204203,
            },
            {
                name: 'Ponto Belo',
                uf: 'ES',
                code: 3204252,
            },
            {
                name: 'Presidente Kennedy',
                uf: 'ES',
                code: 3204302,
            },
            {
                name: 'Rio Bananal',
                uf: 'ES',
                code: 3204351,
            },
            {
                name: 'Rio Novo do Sul',
                uf: 'ES',
                code: 3204401,
            },
            {
                name: 'Santa Leopoldina',
                uf: 'ES',
                code: 3204500,
            },
            {
                name: 'Santa Maria de Jetibá',
                uf: 'ES',
                code: 3204559,
            },
            {
                name: 'Santa Teresa',
                uf: 'ES',
                code: 3204609,
            },
            {
                name: 'São Domingos do Norte',
                uf: 'ES',
                code: 3204658,
            },
            {
                name: 'São Gabriel da Palha',
                uf: 'ES',
                code: 3204708,
            },
            {
                name: 'São José do Calçado',
                uf: 'ES',
                code: 3204807,
            },
            {
                name: 'São Mateus',
                uf: 'ES',
                code: 3204906,
            },
            {
                name: 'São Roque do Canaã',
                uf: 'ES',
                code: 3204955,
            },
            {
                name: 'Serra',
                uf: 'ES',
                code: 3205002,
            },
            {
                name: 'Sooretama',
                uf: 'ES',
                code: 3205010,
            },
            {
                name: 'Vargem Alta',
                uf: 'ES',
                code: 3205036,
            },
            {
                name: 'Venda Nova do Imigrante',
                uf: 'ES',
                code: 3205069,
            },
            {
                name: 'Viana',
                uf: 'ES',
                code: 3205101,
            },
            {
                name: 'Vila Pavão',
                uf: 'ES',
                code: 3205150,
            },
            {
                name: 'Vila Valério',
                uf: 'ES',
                code: 3205176,
            },
            {
                name: 'Vila Velha',
                uf: 'ES',
                code: 3205200,
            },
            {
                name: 'Vitória',
                uf: 'ES',
                code: 3205309,
            },
            {
                name: 'Abadia de Goiás',
                uf: 'GO',
                code: 5200050,
            },
            {
                name: 'Abadiânia',
                uf: 'GO',
                code: 5200100,
            },
            {
                name: 'Acreúna',
                uf: 'GO',
                code: 5200134,
            },
            {
                name: 'Adelândia',
                uf: 'GO',
                code: 5200159,
            },
            {
                name: 'Água Fria de Goiás',
                uf: 'GO',
                code: 5200175,
            },
            {
                name: 'Água Limpa',
                uf: 'GO',
                code: 5200209,
            },
            {
                name: 'Águas Lindas de Goiás',
                uf: 'GO',
                code: 5200258,
            },
            {
                name: 'Alexânia',
                uf: 'GO',
                code: 5200308,
            },
            {
                name: 'Aloândia',
                uf: 'GO',
                code: 5200506,
            },
            {
                name: 'Alto Horizonte',
                uf: 'GO',
                code: 5200555,
            },
            {
                name: 'Alto Paraíso de Goiás',
                uf: 'GO',
                code: 5200605,
            },
            {
                name: 'Alvorada do Norte',
                uf: 'GO',
                code: 5200803,
            },
            {
                name: 'Amaralina',
                uf: 'GO',
                code: 5200829,
            },
            {
                name: 'Americano do Brasil',
                uf: 'GO',
                code: 5200852,
            },
            {
                name: 'Amorinópolis',
                uf: 'GO',
                code: 5200902,
            },
            {
                name: 'Anápolis',
                uf: 'GO',
                code: 5201108,
            },
            {
                name: 'Anhanguera',
                uf: 'GO',
                code: 5201207,
            },
            {
                name: 'Anicuns',
                uf: 'GO',
                code: 5201306,
            },
            {
                name: 'Aparecida de Goiânia',
                uf: 'GO',
                code: 5201405,
            },
            {
                name: 'Aparecida do Rio Doce',
                uf: 'GO',
                code: 5201454,
            },
            {
                name: 'Aporé',
                uf: 'GO',
                code: 5201504,
            },
            {
                name: 'Araçu',
                uf: 'GO',
                code: 5201603,
            },
            {
                name: 'Aragarças',
                uf: 'GO',
                code: 5201702,
            },
            {
                name: 'Aragoiânia',
                uf: 'GO',
                code: 5201801,
            },
            {
                name: 'Araguapaz',
                uf: 'GO',
                code: 5202155,
            },
            {
                name: 'Arenópolis',
                uf: 'GO',
                code: 5202353,
            },
            {
                name: 'Aruanã',
                uf: 'GO',
                code: 5202502,
            },
            {
                name: 'Aurilândia',
                uf: 'GO',
                code: 5202601,
            },
            {
                name: 'Avelinópolis',
                uf: 'GO',
                code: 5202809,
            },
            {
                name: 'Baliza',
                uf: 'GO',
                code: 5203104,
            },
            {
                name: 'Barro Alto',
                uf: 'GO',
                code: 5203203,
            },
            {
                name: 'Bela Vista de Goiás',
                uf: 'GO',
                code: 5203302,
            },
            {
                name: 'Bom Jardim de Goiás',
                uf: 'GO',
                code: 5203401,
            },
            {
                name: 'Bom Jesus de Goiás',
                uf: 'GO',
                code: 5203500,
            },
            {
                name: 'Bonfinópolis',
                uf: 'GO',
                code: 5203559,
            },
            {
                name: 'Bonópolis',
                uf: 'GO',
                code: 5203575,
            },
            {
                name: 'Brazabrantes',
                uf: 'GO',
                code: 5203609,
            },
            {
                name: 'Britânia',
                uf: 'GO',
                code: 5203807,
            },
            {
                name: 'Buriti Alegre',
                uf: 'GO',
                code: 5203906,
            },
            {
                name: 'Buriti de Goiás',
                uf: 'GO',
                code: 5203939,
            },
            {
                name: 'Buritinópolis',
                uf: 'GO',
                code: 5203962,
            },
            {
                name: 'Cabeceiras',
                uf: 'GO',
                code: 5204003,
            },
            {
                name: 'Cachoeira Alta',
                uf: 'GO',
                code: 5204102,
            },
            {
                name: 'Cachoeira de Goiás',
                uf: 'GO',
                code: 5204201,
            },
            {
                name: 'Cachoeira Dourada',
                uf: 'GO',
                code: 5204250,
            },
            {
                name: 'Caçu',
                uf: 'GO',
                code: 5204300,
            },
            {
                name: 'Caiapônia',
                uf: 'GO',
                code: 5204409,
            },
            {
                name: 'Caldas Novas',
                uf: 'GO',
                code: 5204508,
            },
            {
                name: 'Caldazinha',
                uf: 'GO',
                code: 5204557,
            },
            {
                name: 'Campestre de Goiás',
                uf: 'GO',
                code: 5204607,
            },
            {
                name: 'Campinaçu',
                uf: 'GO',
                code: 5204656,
            },
            {
                name: 'Campinorte',
                uf: 'GO',
                code: 5204706,
            },
            {
                name: 'Campo Alegre de Goiás',
                uf: 'GO',
                code: 5204805,
            },
            {
                name: 'Campo Limpo de Goiás',
                uf: 'GO',
                code: 5204854,
            },
            {
                name: 'Campos Belos',
                uf: 'GO',
                code: 5204904,
            },
            {
                name: 'Campos Verdes',
                uf: 'GO',
                code: 5204953,
            },
            {
                name: 'Carmo do Rio Verde',
                uf: 'GO',
                code: 5205000,
            },
            {
                name: 'Castelândia',
                uf: 'GO',
                code: 5205059,
            },
            {
                name: 'Catalão',
                uf: 'GO',
                code: 5205109,
            },
            {
                name: 'Caturaí',
                uf: 'GO',
                code: 5205208,
            },
            {
                name: 'Cavalcante',
                uf: 'GO',
                code: 5205307,
            },
            {
                name: 'Ceres',
                uf: 'GO',
                code: 5205406,
            },
            {
                name: 'Cezarina',
                uf: 'GO',
                code: 5205455,
            },
            {
                name: 'Chapadão do Céu',
                uf: 'GO',
                code: 5205471,
            },
            {
                name: 'Cidade Ocidental',
                uf: 'GO',
                code: 5205497,
            },
            {
                name: 'Cocalzinho de Goiás',
                uf: 'GO',
                code: 5205513,
            },
            {
                name: 'Colinas do Sul',
                uf: 'GO',
                code: 5205521,
            },
            {
                name: 'Córrego do Ouro',
                uf: 'GO',
                code: 5205703,
            },
            {
                name: 'Corumbá de Goiás',
                uf: 'GO',
                code: 5205802,
            },
            {
                name: 'Corumbaíba',
                uf: 'GO',
                code: 5205901,
            },
            {
                name: 'Cristalina',
                uf: 'GO',
                code: 5206206,
            },
            {
                name: 'Cristianópolis',
                uf: 'GO',
                code: 5206305,
            },
            {
                name: 'Crixás',
                uf: 'GO',
                code: 5206404,
            },
            {
                name: 'Cromínia',
                uf: 'GO',
                code: 5206503,
            },
            {
                name: 'Cumari',
                uf: 'GO',
                code: 5206602,
            },
            {
                name: 'Damianópolis',
                uf: 'GO',
                code: 5206701,
            },
            {
                name: 'Damolândia',
                uf: 'GO',
                code: 5206800,
            },
            {
                name: 'Davinópolis',
                uf: 'GO',
                code: 5206909,
            },
            {
                name: 'Diorama',
                uf: 'GO',
                code: 5207105,
            },
            {
                name: 'Divinópolis de Goiás',
                uf: 'GO',
                code: 5208301,
            },
            {
                name: 'Doverlândia',
                uf: 'GO',
                code: 5207253,
            },
            {
                name: 'Edealina',
                uf: 'GO',
                code: 5207352,
            },
            {
                name: 'Edéia',
                uf: 'GO',
                code: 5207402,
            },
            {
                name: 'Estrela do Norte',
                uf: 'GO',
                code: 5207501,
            },
            {
                name: 'Faina',
                uf: 'GO',
                code: 5207535,
            },
            {
                name: 'Fazenda Nova',
                uf: 'GO',
                code: 5207600,
            },
            {
                name: 'Firminópolis',
                uf: 'GO',
                code: 5207808,
            },
            {
                name: 'Flores de Goiás',
                uf: 'GO',
                code: 5207907,
            },
            {
                name: 'Formosa',
                uf: 'GO',
                code: 5208004,
            },
            {
                name: 'Formoso',
                uf: 'GO',
                code: 5208103,
            },
            {
                name: 'Gameleira de Goiás',
                uf: 'GO',
                code: 5208152,
            },
            {
                name: 'Goianápolis',
                uf: 'GO',
                code: 5208400,
            },
            {
                name: 'Goiandira',
                uf: 'GO',
                code: 5208509,
            },
            {
                name: 'Goianésia',
                uf: 'GO',
                code: 5208608,
            },
            {
                name: 'Goiânia',
                uf: 'GO',
                code: 5208707,
            },
            {
                name: 'Goianira',
                uf: 'GO',
                code: 5208806,
            },
            {
                name: 'Goiás',
                uf: 'GO',
                code: 5208905,
            },
            {
                name: 'Goiatuba',
                uf: 'GO',
                code: 5209101,
            },
            {
                name: 'Gouvelândia',
                uf: 'GO',
                code: 5209150,
            },
            {
                name: 'Guapó',
                uf: 'GO',
                code: 5209200,
            },
            {
                name: 'Guaraíta',
                uf: 'GO',
                code: 5209291,
            },
            {
                name: 'Guarani de Goiás',
                uf: 'GO',
                code: 5209408,
            },
            {
                name: 'Guarinos',
                uf: 'GO',
                code: 5209457,
            },
            {
                name: 'Heitoraí',
                uf: 'GO',
                code: 5209606,
            },
            {
                name: 'Hidrolândia',
                uf: 'GO',
                code: 5209705,
            },
            {
                name: 'Hidrolina',
                uf: 'GO',
                code: 5209804,
            },
            {
                name: 'Iaciara',
                uf: 'GO',
                code: 5209903,
            },
            {
                name: 'Inaciolândia',
                uf: 'GO',
                code: 5209937,
            },
            {
                name: 'Indiara',
                uf: 'GO',
                code: 5209952,
            },
            {
                name: 'Inhumas',
                uf: 'GO',
                code: 5210000,
            },
            {
                name: 'Ipameri',
                uf: 'GO',
                code: 5210109,
            },
            {
                name: 'Ipiranga de Goiás',
                uf: 'GO',
                code: 5210158,
            },
            {
                name: 'Iporá',
                uf: 'GO',
                code: 5210208,
            },
            {
                name: 'Israelândia',
                uf: 'GO',
                code: 5210307,
            },
            {
                name: 'Itaberaí',
                uf: 'GO',
                code: 5210406,
            },
            {
                name: 'Itaguari',
                uf: 'GO',
                code: 5210562,
            },
            {
                name: 'Itaguaru',
                uf: 'GO',
                code: 5210604,
            },
            {
                name: 'Itajá',
                uf: 'GO',
                code: 5210802,
            },
            {
                name: 'Itapaci',
                uf: 'GO',
                code: 5210901,
            },
            {
                name: 'Itapirapuã',
                uf: 'GO',
                code: 5211008,
            },
            {
                name: 'Itapuranga',
                uf: 'GO',
                code: 5211206,
            },
            {
                name: 'Itarumã',
                uf: 'GO',
                code: 5211305,
            },
            {
                name: 'Itauçu',
                uf: 'GO',
                code: 5211404,
            },
            {
                name: 'Itumbiara',
                uf: 'GO',
                code: 5211503,
            },
            {
                name: 'Ivolândia',
                uf: 'GO',
                code: 5211602,
            },
            {
                name: 'Jandaia',
                uf: 'GO',
                code: 5211701,
            },
            {
                name: 'Jaraguá',
                uf: 'GO',
                code: 5211800,
            },
            {
                name: 'Jataí',
                uf: 'GO',
                code: 5211909,
            },
            {
                name: 'Jaupaci',
                uf: 'GO',
                code: 5212006,
            },
            {
                name: 'Jesúpolis',
                uf: 'GO',
                code: 5212055,
            },
            {
                name: 'Joviânia',
                uf: 'GO',
                code: 5212105,
            },
            {
                name: 'Jussara',
                uf: 'GO',
                code: 5212204,
            },
            {
                name: 'Lagoa Santa',
                uf: 'GO',
                code: 5212253,
            },
            {
                name: 'Leopoldo de Bulhões',
                uf: 'GO',
                code: 5212303,
            },
            {
                name: 'Luziânia',
                uf: 'GO',
                code: 5212501,
            },
            {
                name: 'Mairipotaba',
                uf: 'GO',
                code: 5212600,
            },
            {
                name: 'Mambaí',
                uf: 'GO',
                code: 5212709,
            },
            {
                name: 'Mara Rosa',
                uf: 'GO',
                code: 5212808,
            },
            {
                name: 'Marzagão',
                uf: 'GO',
                code: 5212907,
            },
            {
                name: 'Matrinchã',
                uf: 'GO',
                code: 5212956,
            },
            {
                name: 'Maurilândia',
                uf: 'GO',
                code: 5213004,
            },
            {
                name: 'Mimoso de Goiás',
                uf: 'GO',
                code: 5213053,
            },
            {
                name: 'Minaçu',
                uf: 'GO',
                code: 5213087,
            },
            {
                name: 'Mineiros',
                uf: 'GO',
                code: 5213103,
            },
            {
                name: 'Moiporá',
                uf: 'GO',
                code: 5213400,
            },
            {
                name: 'Monte Alegre de Goiás',
                uf: 'GO',
                code: 5213509,
            },
            {
                name: 'Montes Claros de Goiás',
                uf: 'GO',
                code: 5213707,
            },
            {
                name: 'Montividiu',
                uf: 'GO',
                code: 5213756,
            },
            {
                name: 'Montividiu do Norte',
                uf: 'GO',
                code: 5213772,
            },
            {
                name: 'Morrinhos',
                uf: 'GO',
                code: 5213806,
            },
            {
                name: 'Morro Agudo de Goiás',
                uf: 'GO',
                code: 5213855,
            },
            {
                name: 'Mossâmedes',
                uf: 'GO',
                code: 5213905,
            },
            {
                name: 'Mozarlândia',
                uf: 'GO',
                code: 5214002,
            },
            {
                name: 'Mundo Novo',
                uf: 'GO',
                code: 5214051,
            },
            {
                name: 'Mutunópolis',
                uf: 'GO',
                code: 5214101,
            },
            {
                name: 'Nazário',
                uf: 'GO',
                code: 5214408,
            },
            {
                name: 'Nerópolis',
                uf: 'GO',
                code: 5214507,
            },
            {
                name: 'Niquelândia',
                uf: 'GO',
                code: 5214606,
            },
            {
                name: 'Nova América',
                uf: 'GO',
                code: 5214705,
            },
            {
                name: 'Nova Aurora',
                uf: 'GO',
                code: 5214804,
            },
            {
                name: 'Nova Crixás',
                uf: 'GO',
                code: 5214838,
            },
            {
                name: 'Nova Glória',
                uf: 'GO',
                code: 5214861,
            },
            {
                name: 'Nova Iguaçu de Goiás',
                uf: 'GO',
                code: 5214879,
            },
            {
                name: 'Nova Roma',
                uf: 'GO',
                code: 5214903,
            },
            {
                name: 'Nova Veneza',
                uf: 'GO',
                code: 5215009,
            },
            {
                name: 'Novo Brasil',
                uf: 'GO',
                code: 5215207,
            },
            {
                name: 'Novo Gama',
                uf: 'GO',
                code: 5215231,
            },
            {
                name: 'Novo Planalto',
                uf: 'GO',
                code: 5215256,
            },
            {
                name: 'Orizona',
                uf: 'GO',
                code: 5215306,
            },
            {
                name: 'Ouro Verde de Goiás',
                uf: 'GO',
                code: 5215405,
            },
            {
                name: 'Ouvidor',
                uf: 'GO',
                code: 5215504,
            },
            {
                name: 'Padre Bernardo',
                uf: 'GO',
                code: 5215603,
            },
            {
                name: 'Palestina de Goiás',
                uf: 'GO',
                code: 5215652,
            },
            {
                name: 'Palmeiras de Goiás',
                uf: 'GO',
                code: 5215702,
            },
            {
                name: 'Palmelo',
                uf: 'GO',
                code: 5215801,
            },
            {
                name: 'Palminópolis',
                uf: 'GO',
                code: 5215900,
            },
            {
                name: 'Panamá',
                uf: 'GO',
                code: 5216007,
            },
            {
                name: 'Paranaiguara',
                uf: 'GO',
                code: 5216304,
            },
            {
                name: 'Paraúna',
                uf: 'GO',
                code: 5216403,
            },
            {
                name: 'Perolândia',
                uf: 'GO',
                code: 5216452,
            },
            {
                name: 'Petrolina de Goiás',
                uf: 'GO',
                code: 5216809,
            },
            {
                name: 'Pilar de Goiás',
                uf: 'GO',
                code: 5216908,
            },
            {
                name: 'Piracanjuba',
                uf: 'GO',
                code: 5217104,
            },
            {
                name: 'Piranhas',
                uf: 'GO',
                code: 5217203,
            },
            {
                name: 'Pirenópolis',
                uf: 'GO',
                code: 5217302,
            },
            {
                name: 'Pires do Rio',
                uf: 'GO',
                code: 5217401,
            },
            {
                name: 'Planaltina',
                uf: 'GO',
                code: 5217609,
            },
            {
                name: 'Pontalina',
                uf: 'GO',
                code: 5217708,
            },
            {
                name: 'Porangatu',
                uf: 'GO',
                code: 5218003,
            },
            {
                name: 'Porteirão',
                uf: 'GO',
                code: 5218052,
            },
            {
                name: 'Portelândia',
                uf: 'GO',
                code: 5218102,
            },
            {
                name: 'Posse',
                uf: 'GO',
                code: 5218300,
            },
            {
                name: 'Professor Jamil',
                uf: 'GO',
                code: 5218391,
            },
            {
                name: 'Quirinópolis',
                uf: 'GO',
                code: 5218508,
            },
            {
                name: 'Rialma',
                uf: 'GO',
                code: 5218607,
            },
            {
                name: 'Rianápolis',
                uf: 'GO',
                code: 5218706,
            },
            {
                name: 'Rio Quente',
                uf: 'GO',
                code: 5218789,
            },
            {
                name: 'Rio Verde',
                uf: 'GO',
                code: 5218805,
            },
            {
                name: 'Rubiataba',
                uf: 'GO',
                code: 5218904,
            },
            {
                name: 'Sanclerlândia',
                uf: 'GO',
                code: 5219001,
            },
            {
                name: 'Santa Bárbara de Goiás',
                uf: 'GO',
                code: 5219100,
            },
            {
                name: 'Santa Cruz de Goiás',
                uf: 'GO',
                code: 5219209,
            },
            {
                name: 'Santa Fé de Goiás',
                uf: 'GO',
                code: 5219258,
            },
            {
                name: 'Santa Helena de Goiás',
                uf: 'GO',
                code: 5219308,
            },
            {
                name: 'Santa Isabel',
                uf: 'GO',
                code: 5219357,
            },
            {
                name: 'Santa Rita do Araguaia',
                uf: 'GO',
                code: 5219407,
            },
            {
                name: 'Santa Rita do Novo Destino',
                uf: 'GO',
                code: 5219456,
            },
            {
                name: 'Santa Rosa de Goiás',
                uf: 'GO',
                code: 5219506,
            },
            {
                name: 'Santa Tereza de Goiás',
                uf: 'GO',
                code: 5219605,
            },
            {
                name: 'Santa Terezinha de Goiás',
                uf: 'GO',
                code: 5219704,
            },
            {
                name: 'Santo Antônio da Barra',
                uf: 'GO',
                code: 5219712,
            },
            {
                name: 'Santo Antônio de Goiás',
                uf: 'GO',
                code: 5219738,
            },
            {
                name: 'Santo Antônio do Descoberto',
                uf: 'GO',
                code: 5219753,
            },
            {
                name: 'São Domingos',
                uf: 'GO',
                code: 5219803,
            },
            {
                name: 'São Francisco de Goiás',
                uf: 'GO',
                code: 5219902,
            },
            {
                name: "São João d'Aliança",
                uf: 'GO',
                code: 5220009,
            },
            {
                name: 'São João da Paraúna',
                uf: 'GO',
                code: 5220058,
            },
            {
                name: 'São Luís de Montes Belos',
                uf: 'GO',
                code: 5220108,
            },
            {
                name: 'São Luíz do Norte',
                uf: 'GO',
                code: 5220157,
            },
            {
                name: 'São Miguel do Araguaia',
                uf: 'GO',
                code: 5220207,
            },
            {
                name: 'São Miguel do Passa Quatro',
                uf: 'GO',
                code: 5220264,
            },
            {
                name: 'São Patrício',
                uf: 'GO',
                code: 5220280,
            },
            {
                name: 'São Simão',
                uf: 'GO',
                code: 5220405,
            },
            {
                name: 'Senador Canedo',
                uf: 'GO',
                code: 5220454,
            },
            {
                name: 'Serranópolis',
                uf: 'GO',
                code: 5220504,
            },
            {
                name: 'Silvânia',
                uf: 'GO',
                code: 5220603,
            },
            {
                name: 'Simolândia',
                uf: 'GO',
                code: 5220686,
            },
            {
                name: "Sítio d'Abadia",
                uf: 'GO',
                code: 5220702,
            },
            {
                name: 'Taquaral de Goiás',
                uf: 'GO',
                code: 5221007,
            },
            {
                name: 'Teresina de Goiás',
                uf: 'GO',
                code: 5221080,
            },
            {
                name: 'Terezópolis de Goiás',
                uf: 'GO',
                code: 5221197,
            },
            {
                name: 'Três Ranchos',
                uf: 'GO',
                code: 5221304,
            },
            {
                name: 'Trindade',
                uf: 'GO',
                code: 5221403,
            },
            {
                name: 'Trombas',
                uf: 'GO',
                code: 5221452,
            },
            {
                name: 'Turvânia',
                uf: 'GO',
                code: 5221502,
            },
            {
                name: 'Turvelândia',
                uf: 'GO',
                code: 5221551,
            },
            {
                name: 'Uirapuru',
                uf: 'GO',
                code: 5221577,
            },
            {
                name: 'Uruaçu',
                uf: 'GO',
                code: 5221601,
            },
            {
                name: 'Uruana',
                uf: 'GO',
                code: 5221700,
            },
            {
                name: 'Urutaí',
                uf: 'GO',
                code: 5221809,
            },
            {
                name: 'Valparaíso de Goiás',
                uf: 'GO',
                code: 5221858,
            },
            {
                name: 'Varjão',
                uf: 'GO',
                code: 5221908,
            },
            {
                name: 'Vianópolis',
                uf: 'GO',
                code: 5222005,
            },
            {
                name: 'Vicentinópolis',
                uf: 'GO',
                code: 5222054,
            },
            {
                name: 'Vila Boa',
                uf: 'GO',
                code: 5222203,
            },
            {
                name: 'Vila Propício',
                uf: 'GO',
                code: 5222302,
            },
            {
                name: 'Açailândia',
                uf: 'MA',
                code: 2100055,
            },
            {
                name: 'Afonso Cunha',
                uf: 'MA',
                code: 2100105,
            },
            {
                name: 'Água Doce do Maranhão',
                uf: 'MA',
                code: 2100154,
            },
            {
                name: 'Alcântara',
                uf: 'MA',
                code: 2100204,
            },
            {
                name: 'Aldeias Altas',
                uf: 'MA',
                code: 2100303,
            },
            {
                name: 'Altamira do Maranhão',
                uf: 'MA',
                code: 2100402,
            },
            {
                name: 'Alto Alegre do Maranhão',
                uf: 'MA',
                code: 2100436,
            },
            {
                name: 'Alto Alegre do Pindaré',
                uf: 'MA',
                code: 2100477,
            },
            {
                name: 'Alto Parnaíba',
                uf: 'MA',
                code: 2100501,
            },
            {
                name: 'Amapá do Maranhão',
                uf: 'MA',
                code: 2100550,
            },
            {
                name: 'Amarante do Maranhão',
                uf: 'MA',
                code: 2100600,
            },
            {
                name: 'Anajatuba',
                uf: 'MA',
                code: 2100709,
            },
            {
                name: 'Anapurus',
                uf: 'MA',
                code: 2100808,
            },
            {
                name: 'Apicum-Açu',
                uf: 'MA',
                code: 2100832,
            },
            {
                name: 'Araguanã',
                uf: 'MA',
                code: 2100873,
            },
            {
                name: 'Araioses',
                uf: 'MA',
                code: 2100907,
            },
            {
                name: 'Arame',
                uf: 'MA',
                code: 2100956,
            },
            {
                name: 'Arari',
                uf: 'MA',
                code: 2101004,
            },
            {
                name: 'Axixá',
                uf: 'MA',
                code: 2101103,
            },
            {
                name: 'Bacabal',
                uf: 'MA',
                code: 2101202,
            },
            {
                name: 'Bacabeira',
                uf: 'MA',
                code: 2101251,
            },
            {
                name: 'Bacuri',
                uf: 'MA',
                code: 2101301,
            },
            {
                name: 'Bacurituba',
                uf: 'MA',
                code: 2101350,
            },
            {
                name: 'Balsas',
                uf: 'MA',
                code: 2101400,
            },
            {
                name: 'Barão de Grajaú',
                uf: 'MA',
                code: 2101509,
            },
            {
                name: 'Barra do Corda',
                uf: 'MA',
                code: 2101608,
            },
            {
                name: 'Barreirinhas',
                uf: 'MA',
                code: 2101707,
            },
            {
                name: 'Bela Vista do Maranhão',
                uf: 'MA',
                code: 2101772,
            },
            {
                name: 'Belágua',
                uf: 'MA',
                code: 2101731,
            },
            {
                name: 'Benedito Leite',
                uf: 'MA',
                code: 2101806,
            },
            {
                name: 'Bequimão',
                uf: 'MA',
                code: 2101905,
            },
            {
                name: 'Bernardo do Mearim',
                uf: 'MA',
                code: 2101939,
            },
            {
                name: 'Boa Vista do Gurupi',
                uf: 'MA',
                code: 2101970,
            },
            {
                name: 'Bom Jardim',
                uf: 'MA',
                code: 2102002,
            },
            {
                name: 'Bom Jesus das Selvas',
                uf: 'MA',
                code: 2102036,
            },
            {
                name: 'Bom Lugar',
                uf: 'MA',
                code: 2102077,
            },
            {
                name: 'Brejo',
                uf: 'MA',
                code: 2102101,
            },
            {
                name: 'Brejo de Areia',
                uf: 'MA',
                code: 2102150,
            },
            {
                name: 'Buriti',
                uf: 'MA',
                code: 2102200,
            },
            {
                name: 'Buriti Bravo',
                uf: 'MA',
                code: 2102309,
            },
            {
                name: 'Buriticupu',
                uf: 'MA',
                code: 2102325,
            },
            {
                name: 'Buritirana',
                uf: 'MA',
                code: 2102358,
            },
            {
                name: 'Cachoeira Grande',
                uf: 'MA',
                code: 2102374,
            },
            {
                name: 'Cajapió',
                uf: 'MA',
                code: 2102408,
            },
            {
                name: 'Cajari',
                uf: 'MA',
                code: 2102507,
            },
            {
                name: 'Campestre do Maranhão',
                uf: 'MA',
                code: 2102556,
            },
            {
                name: 'Cândido Mendes',
                uf: 'MA',
                code: 2102606,
            },
            {
                name: 'Cantanhede',
                uf: 'MA',
                code: 2102705,
            },
            {
                name: 'Capinzal do Norte',
                uf: 'MA',
                code: 2102754,
            },
            {
                name: 'Carolina',
                uf: 'MA',
                code: 2102804,
            },
            {
                name: 'Carutapera',
                uf: 'MA',
                code: 2102903,
            },
            {
                name: 'Caxias',
                uf: 'MA',
                code: 2103000,
            },
            {
                name: 'Cedral',
                uf: 'MA',
                code: 2103109,
            },
            {
                name: 'Central do Maranhão',
                uf: 'MA',
                code: 2103125,
            },
            {
                name: 'Centro do Guilherme',
                uf: 'MA',
                code: 2103158,
            },
            {
                name: 'Centro Novo do Maranhão',
                uf: 'MA',
                code: 2103174,
            },
            {
                name: 'Chapadinha',
                uf: 'MA',
                code: 2103208,
            },
            {
                name: 'Cidelândia',
                uf: 'MA',
                code: 2103257,
            },
            {
                name: 'Codó',
                uf: 'MA',
                code: 2103307,
            },
            {
                name: 'Coelho Neto',
                uf: 'MA',
                code: 2103406,
            },
            {
                name: 'Colinas',
                uf: 'MA',
                code: 2103505,
            },
            {
                name: 'Conceição do Lago-Açu',
                uf: 'MA',
                code: 2103554,
            },
            {
                name: 'Coroatá',
                uf: 'MA',
                code: 2103604,
            },
            {
                name: 'Cururupu',
                uf: 'MA',
                code: 2103703,
            },
            {
                name: 'Davinópolis',
                uf: 'MA',
                code: 2103752,
            },
            {
                name: 'Dom Pedro',
                uf: 'MA',
                code: 2103802,
            },
            {
                name: 'Duque Bacelar',
                uf: 'MA',
                code: 2103901,
            },
            {
                name: 'Esperantinópolis',
                uf: 'MA',
                code: 2104008,
            },
            {
                name: 'Estreito',
                uf: 'MA',
                code: 2104057,
            },
            {
                name: 'Feira Nova do Maranhão',
                uf: 'MA',
                code: 2104073,
            },
            {
                name: 'Fernando Falcão',
                uf: 'MA',
                code: 2104081,
            },
            {
                name: 'Formosa da Serra Negra',
                uf: 'MA',
                code: 2104099,
            },
            {
                name: 'Fortaleza dos Nogueiras',
                uf: 'MA',
                code: 2104107,
            },
            {
                name: 'Fortuna',
                uf: 'MA',
                code: 2104206,
            },
            {
                name: 'Godofredo Viana',
                uf: 'MA',
                code: 2104305,
            },
            {
                name: 'Gonçalves Dias',
                uf: 'MA',
                code: 2104404,
            },
            {
                name: 'Governador Archer',
                uf: 'MA',
                code: 2104503,
            },
            {
                name: 'Governador Edison Lobão',
                uf: 'MA',
                code: 2104552,
            },
            {
                name: 'Governador Eugênio Barros',
                uf: 'MA',
                code: 2104602,
            },
            {
                name: 'Governador Luiz Rocha',
                uf: 'MA',
                code: 2104628,
            },
            {
                name: 'Governador Newton Bello',
                uf: 'MA',
                code: 2104651,
            },
            {
                name: 'Governador Nunes Freire',
                uf: 'MA',
                code: 2104677,
            },
            {
                name: 'Graça Aranha',
                uf: 'MA',
                code: 2104701,
            },
            {
                name: 'Grajaú',
                uf: 'MA',
                code: 2104800,
            },
            {
                name: 'Guimarães',
                uf: 'MA',
                code: 2104909,
            },
            {
                name: 'Humberto de Campos',
                uf: 'MA',
                code: 2105005,
            },
            {
                name: 'Icatu',
                uf: 'MA',
                code: 2105104,
            },
            {
                name: 'Igarapé do Meio',
                uf: 'MA',
                code: 2105153,
            },
            {
                name: 'Igarapé Grande',
                uf: 'MA',
                code: 2105203,
            },
            {
                name: 'Imperatriz',
                uf: 'MA',
                code: 2105302,
            },
            {
                name: 'Itaipava do Grajaú',
                uf: 'MA',
                code: 2105351,
            },
            {
                name: 'Itapecuru Mirim',
                uf: 'MA',
                code: 2105401,
            },
            {
                name: 'Itinga do Maranhão',
                uf: 'MA',
                code: 2105427,
            },
            {
                name: 'Jatobá',
                uf: 'MA',
                code: 2105450,
            },
            {
                name: 'Jenipapo dos Vieiras',
                uf: 'MA',
                code: 2105476,
            },
            {
                name: 'João Lisboa',
                uf: 'MA',
                code: 2105500,
            },
            {
                name: 'Joselândia',
                uf: 'MA',
                code: 2105609,
            },
            {
                name: 'Junco do Maranhão',
                uf: 'MA',
                code: 2105658,
            },
            {
                name: 'Lago da Pedra',
                uf: 'MA',
                code: 2105708,
            },
            {
                name: 'Lago do Junco',
                uf: 'MA',
                code: 2105807,
            },
            {
                name: 'Lago dos Rodrigues',
                uf: 'MA',
                code: 2105948,
            },
            {
                name: 'Lago Verde',
                uf: 'MA',
                code: 2105906,
            },
            {
                name: 'Lagoa do Mato',
                uf: 'MA',
                code: 2105922,
            },
            {
                name: 'Lagoa Grande do Maranhão',
                uf: 'MA',
                code: 2105963,
            },
            {
                name: 'Lajeado Novo',
                uf: 'MA',
                code: 2105989,
            },
            {
                name: 'Lima Campos',
                uf: 'MA',
                code: 2106003,
            },
            {
                name: 'Loreto',
                uf: 'MA',
                code: 2106102,
            },
            {
                name: 'Luís Domingues',
                uf: 'MA',
                code: 2106201,
            },
            {
                name: 'Magalhães de Almeida',
                uf: 'MA',
                code: 2106300,
            },
            {
                name: 'Maracaçumé',
                uf: 'MA',
                code: 2106326,
            },
            {
                name: 'Marajá do Sena',
                uf: 'MA',
                code: 2106359,
            },
            {
                name: 'Maranhãozinho',
                uf: 'MA',
                code: 2106375,
            },
            {
                name: 'Mata Roma',
                uf: 'MA',
                code: 2106409,
            },
            {
                name: 'Matinha',
                uf: 'MA',
                code: 2106508,
            },
            {
                name: 'Matões',
                uf: 'MA',
                code: 2106607,
            },
            {
                name: 'Matões do Norte',
                uf: 'MA',
                code: 2106631,
            },
            {
                name: 'Milagres do Maranhão',
                uf: 'MA',
                code: 2106672,
            },
            {
                name: 'Mirador',
                uf: 'MA',
                code: 2106706,
            },
            {
                name: 'Miranda do Norte',
                uf: 'MA',
                code: 2106755,
            },
            {
                name: 'Mirinzal',
                uf: 'MA',
                code: 2106805,
            },
            {
                name: 'Monção',
                uf: 'MA',
                code: 2106904,
            },
            {
                name: 'Montes Altos',
                uf: 'MA',
                code: 2107001,
            },
            {
                name: 'Morros',
                uf: 'MA',
                code: 2107100,
            },
            {
                name: 'Nina Rodrigues',
                uf: 'MA',
                code: 2107209,
            },
            {
                name: 'Nova Colinas',
                uf: 'MA',
                code: 2107258,
            },
            {
                name: 'Nova Iorque',
                uf: 'MA',
                code: 2107308,
            },
            {
                name: 'Nova Olinda do Maranhão',
                uf: 'MA',
                code: 2107357,
            },
            {
                name: "Olho d'Água das Cunhãs",
                uf: 'MA',
                code: 2107407,
            },
            {
                name: 'Olinda Nova do Maranhão',
                uf: 'MA',
                code: 2107456,
            },
            {
                name: 'Paço do Lumiar',
                uf: 'MA',
                code: 2107506,
            },
            {
                name: 'Palmeirândia',
                uf: 'MA',
                code: 2107605,
            },
            {
                name: 'Paraibano',
                uf: 'MA',
                code: 2107704,
            },
            {
                name: 'Parnarama',
                uf: 'MA',
                code: 2107803,
            },
            {
                name: 'Passagem Franca',
                uf: 'MA',
                code: 2107902,
            },
            {
                name: 'Pastos Bons',
                uf: 'MA',
                code: 2108009,
            },
            {
                name: 'Paulino Neves',
                uf: 'MA',
                code: 2108058,
            },
            {
                name: 'Paulo Ramos',
                uf: 'MA',
                code: 2108108,
            },
            {
                name: 'Pedreiras',
                uf: 'MA',
                code: 2108207,
            },
            {
                name: 'Pedro do Rosário',
                uf: 'MA',
                code: 2108256,
            },
            {
                name: 'Penalva',
                uf: 'MA',
                code: 2108306,
            },
            {
                name: 'Peri Mirim',
                uf: 'MA',
                code: 2108405,
            },
            {
                name: 'Peritoró',
                uf: 'MA',
                code: 2108454,
            },
            {
                name: 'Pindaré-Mirim',
                uf: 'MA',
                code: 2108504,
            },
            {
                name: 'Pinheiro',
                uf: 'MA',
                code: 2108603,
            },
            {
                name: 'Pio XII',
                uf: 'MA',
                code: 2108702,
            },
            {
                name: 'Pirapemas',
                uf: 'MA',
                code: 2108801,
            },
            {
                name: 'Poção de Pedras',
                uf: 'MA',
                code: 2108900,
            },
            {
                name: 'Porto Franco',
                uf: 'MA',
                code: 2109007,
            },
            {
                name: 'Porto Rico do Maranhão',
                uf: 'MA',
                code: 2109056,
            },
            {
                name: 'Presidente Dutra',
                uf: 'MA',
                code: 2109106,
            },
            {
                name: 'Presidente Juscelino',
                uf: 'MA',
                code: 2109205,
            },
            {
                name: 'Presidente Médici',
                uf: 'MA',
                code: 2109239,
            },
            {
                name: 'Presidente Sarney',
                uf: 'MA',
                code: 2109270,
            },
            {
                name: 'Presidente Vargas',
                uf: 'MA',
                code: 2109304,
            },
            {
                name: 'Primeira Cruz',
                uf: 'MA',
                code: 2109403,
            },
            {
                name: 'Raposa',
                uf: 'MA',
                code: 2109452,
            },
            {
                name: 'Riachão',
                uf: 'MA',
                code: 2109502,
            },
            {
                name: 'Ribamar Fiquene',
                uf: 'MA',
                code: 2109551,
            },
            {
                name: 'Rosário',
                uf: 'MA',
                code: 2109601,
            },
            {
                name: 'Sambaíba',
                uf: 'MA',
                code: 2109700,
            },
            {
                name: 'Santa Filomena do Maranhão',
                uf: 'MA',
                code: 2109759,
            },
            {
                name: 'Santa Helena',
                uf: 'MA',
                code: 2109809,
            },
            {
                name: 'Santa Inês',
                uf: 'MA',
                code: 2109908,
            },
            {
                name: 'Santa Luzia',
                uf: 'MA',
                code: 2110005,
            },
            {
                name: 'Santa Luzia do Paruá',
                uf: 'MA',
                code: 2110039,
            },
            {
                name: 'Santa Quitéria do Maranhão',
                uf: 'MA',
                code: 2110104,
            },
            {
                name: 'Santa Rita',
                uf: 'MA',
                code: 2110203,
            },
            {
                name: 'Santana do Maranhão',
                uf: 'MA',
                code: 2110237,
            },
            {
                name: 'Santo Amaro do Maranhão',
                uf: 'MA',
                code: 2110278,
            },
            {
                name: 'Santo Antônio dos Lopes',
                uf: 'MA',
                code: 2110302,
            },
            {
                name: 'São Benedito do Rio Preto',
                uf: 'MA',
                code: 2110401,
            },
            {
                name: 'São Bento',
                uf: 'MA',
                code: 2110500,
            },
            {
                name: 'São Bernardo',
                uf: 'MA',
                code: 2110609,
            },
            {
                name: 'São Domingos do Azeitão',
                uf: 'MA',
                code: 2110658,
            },
            {
                name: 'São Domingos do Maranhão',
                uf: 'MA',
                code: 2110708,
            },
            {
                name: 'São Félix de Balsas',
                uf: 'MA',
                code: 2110807,
            },
            {
                name: 'São Francisco do Brejão',
                uf: 'MA',
                code: 2110856,
            },
            {
                name: 'São Francisco do Maranhão',
                uf: 'MA',
                code: 2110906,
            },
            {
                name: 'São João Batista',
                uf: 'MA',
                code: 2111003,
            },
            {
                name: 'São João do Carú',
                uf: 'MA',
                code: 2111029,
            },
            {
                name: 'São João do Paraíso',
                uf: 'MA',
                code: 2111052,
            },
            {
                name: 'São João do Soter',
                uf: 'MA',
                code: 2111078,
            },
            {
                name: 'São João dos Patos',
                uf: 'MA',
                code: 2111102,
            },
            {
                name: 'São José de Ribamar',
                uf: 'MA',
                code: 2111201,
            },
            {
                name: 'São José dos Basílios',
                uf: 'MA',
                code: 2111250,
            },
            {
                name: 'São Luís',
                uf: 'MA',
                code: 2111300,
            },
            {
                name: 'São Luís Gonzaga do Maranhão',
                uf: 'MA',
                code: 2111409,
            },
            {
                name: 'São Mateus do Maranhão',
                uf: 'MA',
                code: 2111508,
            },
            {
                name: 'São Pedro da Água Branca',
                uf: 'MA',
                code: 2111532,
            },
            {
                name: 'São Pedro dos Crentes',
                uf: 'MA',
                code: 2111573,
            },
            {
                name: 'São Raimundo das Mangabeiras',
                uf: 'MA',
                code: 2111607,
            },
            {
                name: 'São Raimundo do Doca Bezerra',
                uf: 'MA',
                code: 2111631,
            },
            {
                name: 'São Roberto',
                uf: 'MA',
                code: 2111672,
            },
            {
                name: 'São Vicente Ferrer',
                uf: 'MA',
                code: 2111706,
            },
            {
                name: 'Satubinha',
                uf: 'MA',
                code: 2111722,
            },
            {
                name: 'Senador Alexandre Costa',
                uf: 'MA',
                code: 2111748,
            },
            {
                name: 'Senador La Rocque',
                uf: 'MA',
                code: 2111763,
            },
            {
                name: 'Serrano do Maranhão',
                uf: 'MA',
                code: 2111789,
            },
            {
                name: 'Sítio Novo',
                uf: 'MA',
                code: 2111805,
            },
            {
                name: 'Sucupira do Norte',
                uf: 'MA',
                code: 2111904,
            },
            {
                name: 'Sucupira do Riachão',
                uf: 'MA',
                code: 2111953,
            },
            {
                name: 'Tasso Fragoso',
                uf: 'MA',
                code: 2112001,
            },
            {
                name: 'Timbiras',
                uf: 'MA',
                code: 2112100,
            },
            {
                name: 'Timon',
                uf: 'MA',
                code: 2112209,
            },
            {
                name: 'Trizidela do Vale',
                uf: 'MA',
                code: 2112233,
            },
            {
                name: 'Tufilândia',
                uf: 'MA',
                code: 2112274,
            },
            {
                name: 'Tuntum',
                uf: 'MA',
                code: 2112308,
            },
            {
                name: 'Turiaçu',
                uf: 'MA',
                code: 2112407,
            },
            {
                name: 'Turilândia',
                uf: 'MA',
                code: 2112456,
            },
            {
                name: 'Tutóia',
                uf: 'MA',
                code: 2112506,
            },
            {
                name: 'Urbano Santos',
                uf: 'MA',
                code: 2112605,
            },
            {
                name: 'Vargem Grande',
                uf: 'MA',
                code: 2112704,
            },
            {
                name: 'Viana',
                uf: 'MA',
                code: 2112803,
            },
            {
                name: 'Vila Nova dos Martírios',
                uf: 'MA',
                code: 2112852,
            },
            {
                name: 'Vitória do Mearim',
                uf: 'MA',
                code: 2112902,
            },
            {
                name: 'Vitorino Freire',
                uf: 'MA',
                code: 2113009,
            },
            {
                name: 'Zé Doca',
                uf: 'MA',
                code: 2114007,
            },
            {
                name: 'Abadia dos Dourados',
                uf: 'MG',
                code: 3100104,
            },
            {
                name: 'Abaeté',
                uf: 'MG',
                code: 3100203,
            },
            {
                name: 'Abre Campo',
                uf: 'MG',
                code: 3100302,
            },
            {
                name: 'Acaiaca',
                uf: 'MG',
                code: 3100401,
            },
            {
                name: 'Açucena',
                uf: 'MG',
                code: 3100500,
            },
            {
                name: 'Água Boa',
                uf: 'MG',
                code: 3100609,
            },
            {
                name: 'Água Comprida',
                uf: 'MG',
                code: 3100708,
            },
            {
                name: 'Aguanil',
                uf: 'MG',
                code: 3100807,
            },
            {
                name: 'Águas Formosas',
                uf: 'MG',
                code: 3100906,
            },
            {
                name: 'Águas Vermelhas',
                uf: 'MG',
                code: 3101003,
            },
            {
                name: 'Aimorés',
                uf: 'MG',
                code: 3101102,
            },
            {
                name: 'Aiuruoca',
                uf: 'MG',
                code: 3101201,
            },
            {
                name: 'Alagoa',
                uf: 'MG',
                code: 3101300,
            },
            {
                name: 'Albertina',
                uf: 'MG',
                code: 3101409,
            },
            {
                name: 'Além Paraíba',
                uf: 'MG',
                code: 3101508,
            },
            {
                name: 'Alfenas',
                uf: 'MG',
                code: 3101607,
            },
            {
                name: 'Alfredo Vasconcelos',
                uf: 'MG',
                code: 3101631,
            },
            {
                name: 'Almenara',
                uf: 'MG',
                code: 3101706,
            },
            {
                name: 'Alpercata',
                uf: 'MG',
                code: 3101805,
            },
            {
                name: 'Alpinópolis',
                uf: 'MG',
                code: 3101904,
            },
            {
                name: 'Alterosa',
                uf: 'MG',
                code: 3102001,
            },
            {
                name: 'Alto Caparaó',
                uf: 'MG',
                code: 3102050,
            },
            {
                name: 'Alto Jequitibá',
                uf: 'MG',
                code: 3153509,
            },
            {
                name: 'Alto Rio Doce',
                uf: 'MG',
                code: 3102100,
            },
            {
                name: 'Alvarenga',
                uf: 'MG',
                code: 3102209,
            },
            {
                name: 'Alvinópolis',
                uf: 'MG',
                code: 3102308,
            },
            {
                name: 'Alvorada de Minas',
                uf: 'MG',
                code: 3102407,
            },
            {
                name: 'Amparo do Serra',
                uf: 'MG',
                code: 3102506,
            },
            {
                name: 'Andradas',
                uf: 'MG',
                code: 3102605,
            },
            {
                name: 'Andrelândia',
                uf: 'MG',
                code: 3102803,
            },
            {
                name: 'Angelândia',
                uf: 'MG',
                code: 3102852,
            },
            {
                name: 'Antônio Carlos',
                uf: 'MG',
                code: 3102902,
            },
            {
                name: 'Antônio Dias',
                uf: 'MG',
                code: 3103009,
            },
            {
                name: 'Antônio Prado de Minas',
                uf: 'MG',
                code: 3103108,
            },
            {
                name: 'Araçaí',
                uf: 'MG',
                code: 3103207,
            },
            {
                name: 'Aracitaba',
                uf: 'MG',
                code: 3103306,
            },
            {
                name: 'Araçuaí',
                uf: 'MG',
                code: 3103405,
            },
            {
                name: 'Araguari',
                uf: 'MG',
                code: 3103504,
            },
            {
                name: 'Arantina',
                uf: 'MG',
                code: 3103603,
            },
            {
                name: 'Araponga',
                uf: 'MG',
                code: 3103702,
            },
            {
                name: 'Araporã',
                uf: 'MG',
                code: 3103751,
            },
            {
                name: 'Arapuá',
                uf: 'MG',
                code: 3103801,
            },
            {
                name: 'Araújos',
                uf: 'MG',
                code: 3103900,
            },
            {
                name: 'Araxá',
                uf: 'MG',
                code: 3104007,
            },
            {
                name: 'Arceburgo',
                uf: 'MG',
                code: 3104106,
            },
            {
                name: 'Arcos',
                uf: 'MG',
                code: 3104205,
            },
            {
                name: 'Areado',
                uf: 'MG',
                code: 3104304,
            },
            {
                name: 'Argirita',
                uf: 'MG',
                code: 3104403,
            },
            {
                name: 'Aricanduva',
                uf: 'MG',
                code: 3104452,
            },
            {
                name: 'Arinos',
                uf: 'MG',
                code: 3104502,
            },
            {
                name: 'Astolfo Dutra',
                uf: 'MG',
                code: 3104601,
            },
            {
                name: 'Ataléia',
                uf: 'MG',
                code: 3104700,
            },
            {
                name: 'Augusto de Lima',
                uf: 'MG',
                code: 3104809,
            },
            {
                name: 'Baependi',
                uf: 'MG',
                code: 3104908,
            },
            {
                name: 'Baldim',
                uf: 'MG',
                code: 3105004,
            },
            {
                name: 'Bambuí',
                uf: 'MG',
                code: 3105103,
            },
            {
                name: 'Bandeira',
                uf: 'MG',
                code: 3105202,
            },
            {
                name: 'Bandeira do Sul',
                uf: 'MG',
                code: 3105301,
            },
            {
                name: 'Barão de Cocais',
                uf: 'MG',
                code: 3105400,
            },
            {
                name: 'Barão de Monte Alto',
                uf: 'MG',
                code: 3105509,
            },
            {
                name: 'Barbacena',
                uf: 'MG',
                code: 3105608,
            },
            {
                name: 'Barra Longa',
                uf: 'MG',
                code: 3105707,
            },
            {
                name: 'Barroso',
                uf: 'MG',
                code: 3105905,
            },
            {
                name: 'Bela Vista de Minas',
                uf: 'MG',
                code: 3106002,
            },
            {
                name: 'Belmiro Braga',
                uf: 'MG',
                code: 3106101,
            },
            {
                name: 'Belo Horizonte',
                uf: 'MG',
                code: 3106200,
            },
            {
                name: 'Belo Oriente',
                uf: 'MG',
                code: 3106309,
            },
            {
                name: 'Belo Vale',
                uf: 'MG',
                code: 3106408,
            },
            {
                name: 'Berilo',
                uf: 'MG',
                code: 3106507,
            },
            {
                name: 'Berizal',
                uf: 'MG',
                code: 3106655,
            },
            {
                name: 'Bertópolis',
                uf: 'MG',
                code: 3106606,
            },
            {
                name: 'Betim',
                uf: 'MG',
                code: 3106705,
            },
            {
                name: 'Bias Fortes',
                uf: 'MG',
                code: 3106804,
            },
            {
                name: 'Bicas',
                uf: 'MG',
                code: 3106903,
            },
            {
                name: 'Biquinhas',
                uf: 'MG',
                code: 3107000,
            },
            {
                name: 'Boa Esperança',
                uf: 'MG',
                code: 3107109,
            },
            {
                name: 'Bocaina de Minas',
                uf: 'MG',
                code: 3107208,
            },
            {
                name: 'Bocaiúva',
                uf: 'MG',
                code: 3107307,
            },
            {
                name: 'Bom Despacho',
                uf: 'MG',
                code: 3107406,
            },
            {
                name: 'Bom Jardim de Minas',
                uf: 'MG',
                code: 3107505,
            },
            {
                name: 'Bom Jesus da Penha',
                uf: 'MG',
                code: 3107604,
            },
            {
                name: 'Bom Jesus do Amparo',
                uf: 'MG',
                code: 3107703,
            },
            {
                name: 'Bom Jesus do Galho',
                uf: 'MG',
                code: 3107802,
            },
            {
                name: 'Bom Repouso',
                uf: 'MG',
                code: 3107901,
            },
            {
                name: 'Bom Sucesso',
                uf: 'MG',
                code: 3108008,
            },
            {
                name: 'Bonfim',
                uf: 'MG',
                code: 3108107,
            },
            {
                name: 'Bonfinópolis de Minas',
                uf: 'MG',
                code: 3108206,
            },
            {
                name: 'Bonito de Minas',
                uf: 'MG',
                code: 3108255,
            },
            {
                name: 'Borda da Mata',
                uf: 'MG',
                code: 3108305,
            },
            {
                name: 'Botelhos',
                uf: 'MG',
                code: 3108404,
            },
            {
                name: 'Botumirim',
                uf: 'MG',
                code: 3108503,
            },
            {
                name: 'Brás Pires',
                uf: 'MG',
                code: 3108701,
            },
            {
                name: 'Brasilândia de Minas',
                uf: 'MG',
                code: 3108552,
            },
            {
                name: 'Brasília de Minas',
                uf: 'MG',
                code: 3108602,
            },
            {
                name: 'Braúnas',
                uf: 'MG',
                code: 3108800,
            },
            {
                name: 'Brazópolis',
                uf: 'MG',
                code: 3108909,
            },
            {
                name: 'Brumadinho',
                uf: 'MG',
                code: 3109006,
            },
            {
                name: 'Bueno Brandão',
                uf: 'MG',
                code: 3109105,
            },
            {
                name: 'Buenópolis',
                uf: 'MG',
                code: 3109204,
            },
            {
                name: 'Bugre',
                uf: 'MG',
                code: 3109253,
            },
            {
                name: 'Buritis',
                uf: 'MG',
                code: 3109303,
            },
            {
                name: 'Buritizeiro',
                uf: 'MG',
                code: 3109402,
            },
            {
                name: 'Cabeceira Grande',
                uf: 'MG',
                code: 3109451,
            },
            {
                name: 'Cabo Verde',
                uf: 'MG',
                code: 3109501,
            },
            {
                name: 'Cachoeira da Prata',
                uf: 'MG',
                code: 3109600,
            },
            {
                name: 'Cachoeira de Minas',
                uf: 'MG',
                code: 3109709,
            },
            {
                name: 'Cachoeira de Pajeú',
                uf: 'MG',
                code: 3102704,
            },
            {
                name: 'Cachoeira Dourada',
                uf: 'MG',
                code: 3109808,
            },
            {
                name: 'Caetanópolis',
                uf: 'MG',
                code: 3109907,
            },
            {
                name: 'Caeté',
                uf: 'MG',
                code: 3110004,
            },
            {
                name: 'Caiana',
                uf: 'MG',
                code: 3110103,
            },
            {
                name: 'Cajuri',
                uf: 'MG',
                code: 3110202,
            },
            {
                name: 'Caldas',
                uf: 'MG',
                code: 3110301,
            },
            {
                name: 'Camacho',
                uf: 'MG',
                code: 3110400,
            },
            {
                name: 'Camanducaia',
                uf: 'MG',
                code: 3110509,
            },
            {
                name: 'Cambuí',
                uf: 'MG',
                code: 3110608,
            },
            {
                name: 'Cambuquira',
                uf: 'MG',
                code: 3110707,
            },
            {
                name: 'Campanário',
                uf: 'MG',
                code: 3110806,
            },
            {
                name: 'Campanha',
                uf: 'MG',
                code: 3110905,
            },
            {
                name: 'Campestre',
                uf: 'MG',
                code: 3111002,
            },
            {
                name: 'Campina Verde',
                uf: 'MG',
                code: 3111101,
            },
            {
                name: 'Campo Azul',
                uf: 'MG',
                code: 3111150,
            },
            {
                name: 'Campo Belo',
                uf: 'MG',
                code: 3111200,
            },
            {
                name: 'Campo do Meio',
                uf: 'MG',
                code: 3111309,
            },
            {
                name: 'Campo Florido',
                uf: 'MG',
                code: 3111408,
            },
            {
                name: 'Campos Altos',
                uf: 'MG',
                code: 3111507,
            },
            {
                name: 'Campos Gerais',
                uf: 'MG',
                code: 3111606,
            },
            {
                name: 'Cana Verde',
                uf: 'MG',
                code: 3111903,
            },
            {
                name: 'Canaã',
                uf: 'MG',
                code: 3111705,
            },
            {
                name: 'Canápolis',
                uf: 'MG',
                code: 3111804,
            },
            {
                name: 'Candeias',
                uf: 'MG',
                code: 3112000,
            },
            {
                name: 'Cantagalo',
                uf: 'MG',
                code: 3112059,
            },
            {
                name: 'Caparaó',
                uf: 'MG',
                code: 3112109,
            },
            {
                name: 'Capela Nova',
                uf: 'MG',
                code: 3112208,
            },
            {
                name: 'Capelinha',
                uf: 'MG',
                code: 3112307,
            },
            {
                name: 'Capetinga',
                uf: 'MG',
                code: 3112406,
            },
            {
                name: 'Capim Branco',
                uf: 'MG',
                code: 3112505,
            },
            {
                name: 'Capinópolis',
                uf: 'MG',
                code: 3112604,
            },
            {
                name: 'Capitão Andrade',
                uf: 'MG',
                code: 3112653,
            },
            {
                name: 'Capitão Enéas',
                uf: 'MG',
                code: 3112703,
            },
            {
                name: 'Capitólio',
                uf: 'MG',
                code: 3112802,
            },
            {
                name: 'Caputira',
                uf: 'MG',
                code: 3112901,
            },
            {
                name: 'Caraí',
                uf: 'MG',
                code: 3113008,
            },
            {
                name: 'Caranaíba',
                uf: 'MG',
                code: 3113107,
            },
            {
                name: 'Carandaí',
                uf: 'MG',
                code: 3113206,
            },
            {
                name: 'Carangola',
                uf: 'MG',
                code: 3113305,
            },
            {
                name: 'Caratinga',
                uf: 'MG',
                code: 3113404,
            },
            {
                name: 'Carbonita',
                uf: 'MG',
                code: 3113503,
            },
            {
                name: 'Careaçu',
                uf: 'MG',
                code: 3113602,
            },
            {
                name: 'Carlos Chagas',
                uf: 'MG',
                code: 3113701,
            },
            {
                name: 'Carmésia',
                uf: 'MG',
                code: 3113800,
            },
            {
                name: 'Carmo da Cachoeira',
                uf: 'MG',
                code: 3113909,
            },
            {
                name: 'Carmo da Mata',
                uf: 'MG',
                code: 3114006,
            },
            {
                name: 'Carmo de Minas',
                uf: 'MG',
                code: 3114105,
            },
            {
                name: 'Carmo do Cajuru',
                uf: 'MG',
                code: 3114204,
            },
            {
                name: 'Carmo do Paranaíba',
                uf: 'MG',
                code: 3114303,
            },
            {
                name: 'Carmo do Rio Claro',
                uf: 'MG',
                code: 3114402,
            },
            {
                name: 'Carmópolis de Minas',
                uf: 'MG',
                code: 3114501,
            },
            {
                name: 'Carneirinho',
                uf: 'MG',
                code: 3114550,
            },
            {
                name: 'Carrancas',
                uf: 'MG',
                code: 3114600,
            },
            {
                name: 'Carvalhópolis',
                uf: 'MG',
                code: 3114709,
            },
            {
                name: 'Carvalhos',
                uf: 'MG',
                code: 3114808,
            },
            {
                name: 'Casa Grande',
                uf: 'MG',
                code: 3114907,
            },
            {
                name: 'Cascalho Rico',
                uf: 'MG',
                code: 3115003,
            },
            {
                name: 'Cássia',
                uf: 'MG',
                code: 3115102,
            },
            {
                name: 'Cataguases',
                uf: 'MG',
                code: 3115300,
            },
            {
                name: 'Catas Altas',
                uf: 'MG',
                code: 3115359,
            },
            {
                name: 'Catas Altas da Noruega',
                uf: 'MG',
                code: 3115409,
            },
            {
                name: 'Catuji',
                uf: 'MG',
                code: 3115458,
            },
            {
                name: 'Catuti',
                uf: 'MG',
                code: 3115474,
            },
            {
                name: 'Caxambu',
                uf: 'MG',
                code: 3115508,
            },
            {
                name: 'Cedro do Abaeté',
                uf: 'MG',
                code: 3115607,
            },
            {
                name: 'Central de Minas',
                uf: 'MG',
                code: 3115706,
            },
            {
                name: 'Centralina',
                uf: 'MG',
                code: 3115805,
            },
            {
                name: 'Chácara',
                uf: 'MG',
                code: 3115904,
            },
            {
                name: 'Chalé',
                uf: 'MG',
                code: 3116001,
            },
            {
                name: 'Chapada do Norte',
                uf: 'MG',
                code: 3116100,
            },
            {
                name: 'Chapada Gaúcha',
                uf: 'MG',
                code: 3116159,
            },
            {
                name: 'Chiador',
                uf: 'MG',
                code: 3116209,
            },
            {
                name: 'Cipotânea',
                uf: 'MG',
                code: 3116308,
            },
            {
                name: 'Claraval',
                uf: 'MG',
                code: 3116407,
            },
            {
                name: 'Claro dos Poções',
                uf: 'MG',
                code: 3116506,
            },
            {
                name: 'Cláudio',
                uf: 'MG',
                code: 3116605,
            },
            {
                name: 'Coimbra',
                uf: 'MG',
                code: 3116704,
            },
            {
                name: 'Coluna',
                uf: 'MG',
                code: 3116803,
            },
            {
                name: 'Comendador Gomes',
                uf: 'MG',
                code: 3116902,
            },
            {
                name: 'Comercinho',
                uf: 'MG',
                code: 3117009,
            },
            {
                name: 'Conceição da Aparecida',
                uf: 'MG',
                code: 3117108,
            },
            {
                name: 'Conceição da Barra de Minas',
                uf: 'MG',
                code: 3115201,
            },
            {
                name: 'Conceição das Alagoas',
                uf: 'MG',
                code: 3117306,
            },
            {
                name: 'Conceição das Pedras',
                uf: 'MG',
                code: 3117207,
            },
            {
                name: 'Conceição de Ipanema',
                uf: 'MG',
                code: 3117405,
            },
            {
                name: 'Conceição do Mato Dentro',
                uf: 'MG',
                code: 3117504,
            },
            {
                name: 'Conceição do Pará',
                uf: 'MG',
                code: 3117603,
            },
            {
                name: 'Conceição do Rio Verde',
                uf: 'MG',
                code: 3117702,
            },
            {
                name: 'Conceição dos Ouros',
                uf: 'MG',
                code: 3117801,
            },
            {
                name: 'Cônego Marinho',
                uf: 'MG',
                code: 3117836,
            },
            {
                name: 'Confins',
                uf: 'MG',
                code: 3117876,
            },
            {
                name: 'Congonhal',
                uf: 'MG',
                code: 3117900,
            },
            {
                name: 'Congonhas',
                uf: 'MG',
                code: 3118007,
            },
            {
                name: 'Congonhas do Norte',
                uf: 'MG',
                code: 3118106,
            },
            {
                name: 'Conquista',
                uf: 'MG',
                code: 3118205,
            },
            {
                name: 'Conselheiro Lafaiete',
                uf: 'MG',
                code: 3118304,
            },
            {
                name: 'Conselheiro Pena',
                uf: 'MG',
                code: 3118403,
            },
            {
                name: 'Consolação',
                uf: 'MG',
                code: 3118502,
            },
            {
                name: 'Contagem',
                uf: 'MG',
                code: 3118601,
            },
            {
                name: 'Coqueiral',
                uf: 'MG',
                code: 3118700,
            },
            {
                name: 'Coração de Jesus',
                uf: 'MG',
                code: 3118809,
            },
            {
                name: 'Cordisburgo',
                uf: 'MG',
                code: 3118908,
            },
            {
                name: 'Cordislândia',
                uf: 'MG',
                code: 3119005,
            },
            {
                name: 'Corinto',
                uf: 'MG',
                code: 3119104,
            },
            {
                name: 'Coroaci',
                uf: 'MG',
                code: 3119203,
            },
            {
                name: 'Coromandel',
                uf: 'MG',
                code: 3119302,
            },
            {
                name: 'Coronel Fabriciano',
                uf: 'MG',
                code: 3119401,
            },
            {
                name: 'Coronel Murta',
                uf: 'MG',
                code: 3119500,
            },
            {
                name: 'Coronel Pacheco',
                uf: 'MG',
                code: 3119609,
            },
            {
                name: 'Coronel Xavier Chaves',
                uf: 'MG',
                code: 3119708,
            },
            {
                name: 'Córrego Danta',
                uf: 'MG',
                code: 3119807,
            },
            {
                name: 'Córrego do Bom Jesus',
                uf: 'MG',
                code: 3119906,
            },
            {
                name: 'Córrego Fundo',
                uf: 'MG',
                code: 3119955,
            },
            {
                name: 'Córrego Novo',
                uf: 'MG',
                code: 3120003,
            },
            {
                name: 'Couto de Magalhães de Minas',
                uf: 'MG',
                code: 3120102,
            },
            {
                name: 'Crisólita',
                uf: 'MG',
                code: 3120151,
            },
            {
                name: 'Cristais',
                uf: 'MG',
                code: 3120201,
            },
            {
                name: 'Cristália',
                uf: 'MG',
                code: 3120300,
            },
            {
                name: 'Cristiano Otoni',
                uf: 'MG',
                code: 3120409,
            },
            {
                name: 'Cristina',
                uf: 'MG',
                code: 3120508,
            },
            {
                name: 'Crucilândia',
                uf: 'MG',
                code: 3120607,
            },
            {
                name: 'Cruzeiro da Fortaleza',
                uf: 'MG',
                code: 3120706,
            },
            {
                name: 'Cruzília',
                uf: 'MG',
                code: 3120805,
            },
            {
                name: 'Cuparaque',
                uf: 'MG',
                code: 3120839,
            },
            {
                name: 'Curral de Dentro',
                uf: 'MG',
                code: 3120870,
            },
            {
                name: 'Curvelo',
                uf: 'MG',
                code: 3120904,
            },
            {
                name: 'Datas',
                uf: 'MG',
                code: 3121001,
            },
            {
                name: 'Delfim Moreira',
                uf: 'MG',
                code: 3121100,
            },
            {
                name: 'Delfinópolis',
                uf: 'MG',
                code: 3121209,
            },
            {
                name: 'Delta',
                uf: 'MG',
                code: 3121258,
            },
            {
                name: 'Descoberto',
                uf: 'MG',
                code: 3121308,
            },
            {
                name: 'Desterro de Entre Rios',
                uf: 'MG',
                code: 3121407,
            },
            {
                name: 'Desterro do Melo',
                uf: 'MG',
                code: 3121506,
            },
            {
                name: 'Diamantina',
                uf: 'MG',
                code: 3121605,
            },
            {
                name: 'Diogo de Vasconcelos',
                uf: 'MG',
                code: 3121704,
            },
            {
                name: 'Dionísio',
                uf: 'MG',
                code: 3121803,
            },
            {
                name: 'Divinésia',
                uf: 'MG',
                code: 3121902,
            },
            {
                name: 'Divino',
                uf: 'MG',
                code: 3122009,
            },
            {
                name: 'Divino das Laranjeiras',
                uf: 'MG',
                code: 3122108,
            },
            {
                name: 'Divinolândia de Minas',
                uf: 'MG',
                code: 3122207,
            },
            {
                name: 'Divinópolis',
                uf: 'MG',
                code: 3122306,
            },
            {
                name: 'Divisa Alegre',
                uf: 'MG',
                code: 3122355,
            },
            {
                name: 'Divisa Nova',
                uf: 'MG',
                code: 3122405,
            },
            {
                name: 'Divisópolis',
                uf: 'MG',
                code: 3122454,
            },
            {
                name: 'Dom Bosco',
                uf: 'MG',
                code: 3122470,
            },
            {
                name: 'Dom Cavati',
                uf: 'MG',
                code: 3122504,
            },
            {
                name: 'Dom Joaquim',
                uf: 'MG',
                code: 3122603,
            },
            {
                name: 'Dom Silvério',
                uf: 'MG',
                code: 3122702,
            },
            {
                name: 'Dom Viçoso',
                uf: 'MG',
                code: 3122801,
            },
            {
                name: 'Dona Eusébia',
                uf: 'MG',
                code: 3122900,
            },
            {
                name: 'Dores de Campos',
                uf: 'MG',
                code: 3123007,
            },
            {
                name: 'Dores de Guanhães',
                uf: 'MG',
                code: 3123106,
            },
            {
                name: 'Dores do Indaiá',
                uf: 'MG',
                code: 3123205,
            },
            {
                name: 'Dores do Turvo',
                uf: 'MG',
                code: 3123304,
            },
            {
                name: 'Doresópolis',
                uf: 'MG',
                code: 3123403,
            },
            {
                name: 'Douradoquara',
                uf: 'MG',
                code: 3123502,
            },
            {
                name: 'Durandé',
                uf: 'MG',
                code: 3123528,
            },
            {
                name: 'Elói Mendes',
                uf: 'MG',
                code: 3123601,
            },
            {
                name: 'Engenheiro Caldas',
                uf: 'MG',
                code: 3123700,
            },
            {
                name: 'Engenheiro Navarro',
                uf: 'MG',
                code: 3123809,
            },
            {
                name: 'Entre Folhas',
                uf: 'MG',
                code: 3123858,
            },
            {
                name: 'Entre Rios de Minas',
                uf: 'MG',
                code: 3123908,
            },
            {
                name: 'Ervália',
                uf: 'MG',
                code: 3124005,
            },
            {
                name: 'Esmeraldas',
                uf: 'MG',
                code: 3124104,
            },
            {
                name: 'Espera Feliz',
                uf: 'MG',
                code: 3124203,
            },
            {
                name: 'Espinosa',
                uf: 'MG',
                code: 3124302,
            },
            {
                name: 'Espírito Santo do Dourado',
                uf: 'MG',
                code: 3124401,
            },
            {
                name: 'Estiva',
                uf: 'MG',
                code: 3124500,
            },
            {
                name: 'Estrela Dalva',
                uf: 'MG',
                code: 3124609,
            },
            {
                name: 'Estrela do Indaiá',
                uf: 'MG',
                code: 3124708,
            },
            {
                name: 'Estrela do Sul',
                uf: 'MG',
                code: 3124807,
            },
            {
                name: 'Eugenópolis',
                uf: 'MG',
                code: 3124906,
            },
            {
                name: 'Ewbank da Câmara',
                uf: 'MG',
                code: 3125002,
            },
            {
                name: 'Extrema',
                uf: 'MG',
                code: 3125101,
            },
            {
                name: 'Fama',
                uf: 'MG',
                code: 3125200,
            },
            {
                name: 'Faria Lemos',
                uf: 'MG',
                code: 3125309,
            },
            {
                name: 'Felício dos Santos',
                uf: 'MG',
                code: 3125408,
            },
            {
                name: 'Felisburgo',
                uf: 'MG',
                code: 3125606,
            },
            {
                name: 'Felixlândia',
                uf: 'MG',
                code: 3125705,
            },
            {
                name: 'Fernandes Tourinho',
                uf: 'MG',
                code: 3125804,
            },
            {
                name: 'Ferros',
                uf: 'MG',
                code: 3125903,
            },
            {
                name: 'Fervedouro',
                uf: 'MG',
                code: 3125952,
            },
            {
                name: 'Florestal',
                uf: 'MG',
                code: 3126000,
            },
            {
                name: 'Formiga',
                uf: 'MG',
                code: 3126109,
            },
            {
                name: 'Formoso',
                uf: 'MG',
                code: 3126208,
            },
            {
                name: 'Fortaleza de Minas',
                uf: 'MG',
                code: 3126307,
            },
            {
                name: 'Fortuna de Minas',
                uf: 'MG',
                code: 3126406,
            },
            {
                name: 'Francisco Badaró',
                uf: 'MG',
                code: 3126505,
            },
            {
                name: 'Francisco Dumont',
                uf: 'MG',
                code: 3126604,
            },
            {
                name: 'Francisco Sá',
                uf: 'MG',
                code: 3126703,
            },
            {
                name: 'Franciscópolis',
                uf: 'MG',
                code: 3126752,
            },
            {
                name: 'Frei Gaspar',
                uf: 'MG',
                code: 3126802,
            },
            {
                name: 'Frei Inocêncio',
                uf: 'MG',
                code: 3126901,
            },
            {
                name: 'Frei Lagonegro',
                uf: 'MG',
                code: 3126950,
            },
            {
                name: 'Fronteira',
                uf: 'MG',
                code: 3127008,
            },
            {
                name: 'Fronteira dos Vales',
                uf: 'MG',
                code: 3127057,
            },
            {
                name: 'Fruta de Leite',
                uf: 'MG',
                code: 3127073,
            },
            {
                name: 'Frutal',
                uf: 'MG',
                code: 3127107,
            },
            {
                name: 'Funilândia',
                uf: 'MG',
                code: 3127206,
            },
            {
                name: 'Galiléia',
                uf: 'MG',
                code: 3127305,
            },
            {
                name: 'Gameleiras',
                uf: 'MG',
                code: 3127339,
            },
            {
                name: 'Glaucilândia',
                uf: 'MG',
                code: 3127354,
            },
            {
                name: 'Goiabeira',
                uf: 'MG',
                code: 3127370,
            },
            {
                name: 'Goianá',
                uf: 'MG',
                code: 3127388,
            },
            {
                name: 'Gonçalves',
                uf: 'MG',
                code: 3127404,
            },
            {
                name: 'Gonzaga',
                uf: 'MG',
                code: 3127503,
            },
            {
                name: 'Gouveia',
                uf: 'MG',
                code: 3127602,
            },
            {
                name: 'Governador Valadares',
                uf: 'MG',
                code: 3127701,
            },
            {
                name: 'Grão Mogol',
                uf: 'MG',
                code: 3127800,
            },
            {
                name: 'Grupiara',
                uf: 'MG',
                code: 3127909,
            },
            {
                name: 'Guanhães',
                uf: 'MG',
                code: 3128006,
            },
            {
                name: 'Guapé',
                uf: 'MG',
                code: 3128105,
            },
            {
                name: 'Guaraciaba',
                uf: 'MG',
                code: 3128204,
            },
            {
                name: 'Guaraciama',
                uf: 'MG',
                code: 3128253,
            },
            {
                name: 'Guaranésia',
                uf: 'MG',
                code: 3128303,
            },
            {
                name: 'Guarani',
                uf: 'MG',
                code: 3128402,
            },
            {
                name: 'Guarará',
                uf: 'MG',
                code: 3128501,
            },
            {
                name: 'Guarda-Mor',
                uf: 'MG',
                code: 3128600,
            },
            {
                name: 'Guaxupé',
                uf: 'MG',
                code: 3128709,
            },
            {
                name: 'Guidoval',
                uf: 'MG',
                code: 3128808,
            },
            {
                name: 'Guimarânia',
                uf: 'MG',
                code: 3128907,
            },
            {
                name: 'Guiricema',
                uf: 'MG',
                code: 3129004,
            },
            {
                name: 'Gurinhatã',
                uf: 'MG',
                code: 3129103,
            },
            {
                name: 'Heliodora',
                uf: 'MG',
                code: 3129202,
            },
            {
                name: 'Iapu',
                uf: 'MG',
                code: 3129301,
            },
            {
                name: 'Ibertioga',
                uf: 'MG',
                code: 3129400,
            },
            {
                name: 'Ibiá',
                uf: 'MG',
                code: 3129509,
            },
            {
                name: 'Ibiaí',
                uf: 'MG',
                code: 3129608,
            },
            {
                name: 'Ibiracatu',
                uf: 'MG',
                code: 3129657,
            },
            {
                name: 'Ibiraci',
                uf: 'MG',
                code: 3129707,
            },
            {
                name: 'Ibirité',
                uf: 'MG',
                code: 3129806,
            },
            {
                name: 'Ibitiúra de Minas',
                uf: 'MG',
                code: 3129905,
            },
            {
                name: 'Ibituruna',
                uf: 'MG',
                code: 3130002,
            },
            {
                name: 'Icaraí de Minas',
                uf: 'MG',
                code: 3130051,
            },
            {
                name: 'Igarapé',
                uf: 'MG',
                code: 3130101,
            },
            {
                name: 'Igaratinga',
                uf: 'MG',
                code: 3130200,
            },
            {
                name: 'Iguatama',
                uf: 'MG',
                code: 3130309,
            },
            {
                name: 'Ijaci',
                uf: 'MG',
                code: 3130408,
            },
            {
                name: 'Ilicínea',
                uf: 'MG',
                code: 3130507,
            },
            {
                name: 'Imbé de Minas',
                uf: 'MG',
                code: 3130556,
            },
            {
                name: 'Inconfidentes',
                uf: 'MG',
                code: 3130606,
            },
            {
                name: 'Indaiabira',
                uf: 'MG',
                code: 3130655,
            },
            {
                name: 'Indianópolis',
                uf: 'MG',
                code: 3130705,
            },
            {
                name: 'Ingaí',
                uf: 'MG',
                code: 3130804,
            },
            {
                name: 'Inhapim',
                uf: 'MG',
                code: 3130903,
            },
            {
                name: 'Inhaúma',
                uf: 'MG',
                code: 3131000,
            },
            {
                name: 'Inimutaba',
                uf: 'MG',
                code: 3131109,
            },
            {
                name: 'Ipaba',
                uf: 'MG',
                code: 3131158,
            },
            {
                name: 'Ipanema',
                uf: 'MG',
                code: 3131208,
            },
            {
                name: 'Ipatinga',
                uf: 'MG',
                code: 3131307,
            },
            {
                name: 'Ipiaçu',
                uf: 'MG',
                code: 3131406,
            },
            {
                name: 'Ipuiúna',
                uf: 'MG',
                code: 3131505,
            },
            {
                name: 'Iraí de Minas',
                uf: 'MG',
                code: 3131604,
            },
            {
                name: 'Itabira',
                uf: 'MG',
                code: 3131703,
            },
            {
                name: 'Itabirinha',
                uf: 'MG',
                code: 3131802,
            },
            {
                name: 'Itabirito',
                uf: 'MG',
                code: 3131901,
            },
            {
                name: 'Itacambira',
                uf: 'MG',
                code: 3132008,
            },
            {
                name: 'Itacarambi',
                uf: 'MG',
                code: 3132107,
            },
            {
                name: 'Itaguara',
                uf: 'MG',
                code: 3132206,
            },
            {
                name: 'Itaipé',
                uf: 'MG',
                code: 3132305,
            },
            {
                name: 'Itajubá',
                uf: 'MG',
                code: 3132404,
            },
            {
                name: 'Itamarandiba',
                uf: 'MG',
                code: 3132503,
            },
            {
                name: 'Itamarati de Minas',
                uf: 'MG',
                code: 3132602,
            },
            {
                name: 'Itambacuri',
                uf: 'MG',
                code: 3132701,
            },
            {
                name: 'Itambé do Mato Dentro',
                uf: 'MG',
                code: 3132800,
            },
            {
                name: 'Itamogi',
                uf: 'MG',
                code: 3132909,
            },
            {
                name: 'Itamonte',
                uf: 'MG',
                code: 3133006,
            },
            {
                name: 'Itanhandu',
                uf: 'MG',
                code: 3133105,
            },
            {
                name: 'Itanhomi',
                uf: 'MG',
                code: 3133204,
            },
            {
                name: 'Itaobim',
                uf: 'MG',
                code: 3133303,
            },
            {
                name: 'Itapagipe',
                uf: 'MG',
                code: 3133402,
            },
            {
                name: 'Itapecerica',
                uf: 'MG',
                code: 3133501,
            },
            {
                name: 'Itapeva',
                uf: 'MG',
                code: 3133600,
            },
            {
                name: 'Itatiaiuçu',
                uf: 'MG',
                code: 3133709,
            },
            {
                name: 'Itaú de Minas',
                uf: 'MG',
                code: 3133758,
            },
            {
                name: 'Itaúna',
                uf: 'MG',
                code: 3133808,
            },
            {
                name: 'Itaverava',
                uf: 'MG',
                code: 3133907,
            },
            {
                name: 'Itinga',
                uf: 'MG',
                code: 3134004,
            },
            {
                name: 'Itueta',
                uf: 'MG',
                code: 3134103,
            },
            {
                name: 'Ituiutaba',
                uf: 'MG',
                code: 3134202,
            },
            {
                name: 'Itumirim',
                uf: 'MG',
                code: 3134301,
            },
            {
                name: 'Iturama',
                uf: 'MG',
                code: 3134400,
            },
            {
                name: 'Itutinga',
                uf: 'MG',
                code: 3134509,
            },
            {
                name: 'Jaboticatubas',
                uf: 'MG',
                code: 3134608,
            },
            {
                name: 'Jacinto',
                uf: 'MG',
                code: 3134707,
            },
            {
                name: 'Jacuí',
                uf: 'MG',
                code: 3134806,
            },
            {
                name: 'Jacutinga',
                uf: 'MG',
                code: 3134905,
            },
            {
                name: 'Jaguaraçu',
                uf: 'MG',
                code: 3135001,
            },
            {
                name: 'Jaíba',
                uf: 'MG',
                code: 3135050,
            },
            {
                name: 'Jampruca',
                uf: 'MG',
                code: 3135076,
            },
            {
                name: 'Janaúba',
                uf: 'MG',
                code: 3135100,
            },
            {
                name: 'Januária',
                uf: 'MG',
                code: 3135209,
            },
            {
                name: 'Japaraíba',
                uf: 'MG',
                code: 3135308,
            },
            {
                name: 'Japonvar',
                uf: 'MG',
                code: 3135357,
            },
            {
                name: 'Jeceaba',
                uf: 'MG',
                code: 3135407,
            },
            {
                name: 'Jenipapo de Minas',
                uf: 'MG',
                code: 3135456,
            },
            {
                name: 'Jequeri',
                uf: 'MG',
                code: 3135506,
            },
            {
                name: 'Jequitaí',
                uf: 'MG',
                code: 3135605,
            },
            {
                name: 'Jequitibá',
                uf: 'MG',
                code: 3135704,
            },
            {
                name: 'Jequitinhonha',
                uf: 'MG',
                code: 3135803,
            },
            {
                name: 'Jesuânia',
                uf: 'MG',
                code: 3135902,
            },
            {
                name: 'Joaíma',
                uf: 'MG',
                code: 3136009,
            },
            {
                name: 'Joanésia',
                uf: 'MG',
                code: 3136108,
            },
            {
                name: 'João Monlevade',
                uf: 'MG',
                code: 3136207,
            },
            {
                name: 'João Pinheiro',
                uf: 'MG',
                code: 3136306,
            },
            {
                name: 'Joaquim Felício',
                uf: 'MG',
                code: 3136405,
            },
            {
                name: 'Jordânia',
                uf: 'MG',
                code: 3136504,
            },
            {
                name: 'José Gonçalves de Minas',
                uf: 'MG',
                code: 3136520,
            },
            {
                name: 'José Raydan',
                uf: 'MG',
                code: 3136553,
            },
            {
                name: 'Josenópolis',
                uf: 'MG',
                code: 3136579,
            },
            {
                name: 'Juatuba',
                uf: 'MG',
                code: 3136652,
            },
            {
                name: 'Juiz de Fora',
                uf: 'MG',
                code: 3136702,
            },
            {
                name: 'Juramento',
                uf: 'MG',
                code: 3136801,
            },
            {
                name: 'Juruaia',
                uf: 'MG',
                code: 3136900,
            },
            {
                name: 'Juvenília',
                uf: 'MG',
                code: 3136959,
            },
            {
                name: 'Ladainha',
                uf: 'MG',
                code: 3137007,
            },
            {
                name: 'Lagamar',
                uf: 'MG',
                code: 3137106,
            },
            {
                name: 'Lagoa da Prata',
                uf: 'MG',
                code: 3137205,
            },
            {
                name: 'Lagoa dos Patos',
                uf: 'MG',
                code: 3137304,
            },
            {
                name: 'Lagoa Dourada',
                uf: 'MG',
                code: 3137403,
            },
            {
                name: 'Lagoa Formosa',
                uf: 'MG',
                code: 3137502,
            },
            {
                name: 'Lagoa Grande',
                uf: 'MG',
                code: 3137536,
            },
            {
                name: 'Lagoa Santa',
                uf: 'MG',
                code: 3137601,
            },
            {
                name: 'Lajinha',
                uf: 'MG',
                code: 3137700,
            },
            {
                name: 'Lambari',
                uf: 'MG',
                code: 3137809,
            },
            {
                name: 'Lamim',
                uf: 'MG',
                code: 3137908,
            },
            {
                name: 'Laranjal',
                uf: 'MG',
                code: 3138005,
            },
            {
                name: 'Lassance',
                uf: 'MG',
                code: 3138104,
            },
            {
                name: 'Lavras',
                uf: 'MG',
                code: 3138203,
            },
            {
                name: 'Leandro Ferreira',
                uf: 'MG',
                code: 3138302,
            },
            {
                name: 'Leme do Prado',
                uf: 'MG',
                code: 3138351,
            },
            {
                name: 'Leopoldina',
                uf: 'MG',
                code: 3138401,
            },
            {
                name: 'Liberdade',
                uf: 'MG',
                code: 3138500,
            },
            {
                name: 'Lima Duarte',
                uf: 'MG',
                code: 3138609,
            },
            {
                name: 'Limeira do Oeste',
                uf: 'MG',
                code: 3138625,
            },
            {
                name: 'Lontra',
                uf: 'MG',
                code: 3138658,
            },
            {
                name: 'Luisburgo',
                uf: 'MG',
                code: 3138674,
            },
            {
                name: 'Luislândia',
                uf: 'MG',
                code: 3138682,
            },
            {
                name: 'Luminárias',
                uf: 'MG',
                code: 3138708,
            },
            {
                name: 'Luz',
                uf: 'MG',
                code: 3138807,
            },
            {
                name: 'Machacalis',
                uf: 'MG',
                code: 3138906,
            },
            {
                name: 'Machado',
                uf: 'MG',
                code: 3139003,
            },
            {
                name: 'Madre de Deus de Minas',
                uf: 'MG',
                code: 3139102,
            },
            {
                name: 'Malacacheta',
                uf: 'MG',
                code: 3139201,
            },
            {
                name: 'Mamonas',
                uf: 'MG',
                code: 3139250,
            },
            {
                name: 'Manga',
                uf: 'MG',
                code: 3139300,
            },
            {
                name: 'Manhuaçu',
                uf: 'MG',
                code: 3139409,
            },
            {
                name: 'Manhumirim',
                uf: 'MG',
                code: 3139508,
            },
            {
                name: 'Mantena',
                uf: 'MG',
                code: 3139607,
            },
            {
                name: 'Mar de Espanha',
                uf: 'MG',
                code: 3139805,
            },
            {
                name: 'Maravilhas',
                uf: 'MG',
                code: 3139706,
            },
            {
                name: 'Maria da Fé',
                uf: 'MG',
                code: 3139904,
            },
            {
                name: 'Mariana',
                uf: 'MG',
                code: 3140001,
            },
            {
                name: 'Marilac',
                uf: 'MG',
                code: 3140100,
            },
            {
                name: 'Mário Campos',
                uf: 'MG',
                code: 3140159,
            },
            {
                name: 'Maripá de Minas',
                uf: 'MG',
                code: 3140209,
            },
            {
                name: 'Marliéria',
                uf: 'MG',
                code: 3140308,
            },
            {
                name: 'Marmelópolis',
                uf: 'MG',
                code: 3140407,
            },
            {
                name: 'Martinho Campos',
                uf: 'MG',
                code: 3140506,
            },
            {
                name: 'Martins Soares',
                uf: 'MG',
                code: 3140530,
            },
            {
                name: 'Mata Verde',
                uf: 'MG',
                code: 3140555,
            },
            {
                name: 'Materlândia',
                uf: 'MG',
                code: 3140605,
            },
            {
                name: 'Mateus Leme',
                uf: 'MG',
                code: 3140704,
            },
            {
                name: 'Mathias Lobato',
                uf: 'MG',
                code: 3171501,
            },
            {
                name: 'Matias Barbosa',
                uf: 'MG',
                code: 3140803,
            },
            {
                name: 'Matias Cardoso',
                uf: 'MG',
                code: 3140852,
            },
            {
                name: 'Matipó',
                uf: 'MG',
                code: 3140902,
            },
            {
                name: 'Mato Verde',
                uf: 'MG',
                code: 3141009,
            },
            {
                name: 'Matozinhos',
                uf: 'MG',
                code: 3141108,
            },
            {
                name: 'Matutina',
                uf: 'MG',
                code: 3141207,
            },
            {
                name: 'Medeiros',
                uf: 'MG',
                code: 3141306,
            },
            {
                name: 'Medina',
                uf: 'MG',
                code: 3141405,
            },
            {
                name: 'Mendes Pimentel',
                uf: 'MG',
                code: 3141504,
            },
            {
                name: 'Mercês',
                uf: 'MG',
                code: 3141603,
            },
            {
                name: 'Mesquita',
                uf: 'MG',
                code: 3141702,
            },
            {
                name: 'Minas Novas',
                uf: 'MG',
                code: 3141801,
            },
            {
                name: 'Minduri',
                uf: 'MG',
                code: 3141900,
            },
            {
                name: 'Mirabela',
                uf: 'MG',
                code: 3142007,
            },
            {
                name: 'Miradouro',
                uf: 'MG',
                code: 3142106,
            },
            {
                name: 'Miraí',
                uf: 'MG',
                code: 3142205,
            },
            {
                name: 'Miravânia',
                uf: 'MG',
                code: 3142254,
            },
            {
                name: 'Moeda',
                uf: 'MG',
                code: 3142304,
            },
            {
                name: 'Moema',
                uf: 'MG',
                code: 3142403,
            },
            {
                name: 'Monjolos',
                uf: 'MG',
                code: 3142502,
            },
            {
                name: 'Monsenhor Paulo',
                uf: 'MG',
                code: 3142601,
            },
            {
                name: 'Montalvânia',
                uf: 'MG',
                code: 3142700,
            },
            {
                name: 'Monte Alegre de Minas',
                uf: 'MG',
                code: 3142809,
            },
            {
                name: 'Monte Azul',
                uf: 'MG',
                code: 3142908,
            },
            {
                name: 'Monte Belo',
                uf: 'MG',
                code: 3143005,
            },
            {
                name: 'Monte Carmelo',
                uf: 'MG',
                code: 3143104,
            },
            {
                name: 'Monte Formoso',
                uf: 'MG',
                code: 3143153,
            },
            {
                name: 'Monte Santo de Minas',
                uf: 'MG',
                code: 3143203,
            },
            {
                name: 'Monte Sião',
                uf: 'MG',
                code: 3143401,
            },
            {
                name: 'Montes Claros',
                uf: 'MG',
                code: 3143302,
            },
            {
                name: 'Montezuma',
                uf: 'MG',
                code: 3143450,
            },
            {
                name: 'Morada Nova de Minas',
                uf: 'MG',
                code: 3143500,
            },
            {
                name: 'Morro da Garça',
                uf: 'MG',
                code: 3143609,
            },
            {
                name: 'Morro do Pilar',
                uf: 'MG',
                code: 3143708,
            },
            {
                name: 'Munhoz',
                uf: 'MG',
                code: 3143807,
            },
            {
                name: 'Muriaé',
                uf: 'MG',
                code: 3143906,
            },
            {
                name: 'Mutum',
                uf: 'MG',
                code: 3144003,
            },
            {
                name: 'Muzambinho',
                uf: 'MG',
                code: 3144102,
            },
            {
                name: 'Nacip Raydan',
                uf: 'MG',
                code: 3144201,
            },
            {
                name: 'Nanuque',
                uf: 'MG',
                code: 3144300,
            },
            {
                name: 'Naque',
                uf: 'MG',
                code: 3144359,
            },
            {
                name: 'Natalândia',
                uf: 'MG',
                code: 3144375,
            },
            {
                name: 'Natércia',
                uf: 'MG',
                code: 3144409,
            },
            {
                name: 'Nazareno',
                uf: 'MG',
                code: 3144508,
            },
            {
                name: 'Nepomuceno',
                uf: 'MG',
                code: 3144607,
            },
            {
                name: 'Ninheira',
                uf: 'MG',
                code: 3144656,
            },
            {
                name: 'Nova Belém',
                uf: 'MG',
                code: 3144672,
            },
            {
                name: 'Nova Era',
                uf: 'MG',
                code: 3144706,
            },
            {
                name: 'Nova Lima',
                uf: 'MG',
                code: 3144805,
            },
            {
                name: 'Nova Módica',
                uf: 'MG',
                code: 3144904,
            },
            {
                name: 'Nova Ponte',
                uf: 'MG',
                code: 3145000,
            },
            {
                name: 'Nova Porteirinha',
                uf: 'MG',
                code: 3145059,
            },
            {
                name: 'Nova Resende',
                uf: 'MG',
                code: 3145109,
            },
            {
                name: 'Nova Serrana',
                uf: 'MG',
                code: 3145208,
            },
            {
                name: 'Nova União',
                uf: 'MG',
                code: 3136603,
            },
            {
                name: 'Novo Cruzeiro',
                uf: 'MG',
                code: 3145307,
            },
            {
                name: 'Novo Oriente de Minas',
                uf: 'MG',
                code: 3145356,
            },
            {
                name: 'Novorizonte',
                uf: 'MG',
                code: 3145372,
            },
            {
                name: 'Olaria',
                uf: 'MG',
                code: 3145406,
            },
            {
                name: "Olhos-d'Água",
                uf: 'MG',
                code: 3145455,
            },
            {
                name: 'Olímpio Noronha',
                uf: 'MG',
                code: 3145505,
            },
            {
                name: 'Oliveira',
                uf: 'MG',
                code: 3145604,
            },
            {
                name: 'Oliveira Fortes',
                uf: 'MG',
                code: 3145703,
            },
            {
                name: 'Onça de Pitangui',
                uf: 'MG',
                code: 3145802,
            },
            {
                name: 'Oratórios',
                uf: 'MG',
                code: 3145851,
            },
            {
                name: 'Orizânia',
                uf: 'MG',
                code: 3145877,
            },
            {
                name: 'Ouro Branco',
                uf: 'MG',
                code: 3145901,
            },
            {
                name: 'Ouro Fino',
                uf: 'MG',
                code: 3146008,
            },
            {
                name: 'Ouro Preto',
                uf: 'MG',
                code: 3146107,
            },
            {
                name: 'Ouro Verde de Minas',
                uf: 'MG',
                code: 3146206,
            },
            {
                name: 'Padre Carvalho',
                uf: 'MG',
                code: 3146255,
            },
            {
                name: 'Padre Paraíso',
                uf: 'MG',
                code: 3146305,
            },
            {
                name: 'Pai Pedro',
                uf: 'MG',
                code: 3146552,
            },
            {
                name: 'Paineiras',
                uf: 'MG',
                code: 3146404,
            },
            {
                name: 'Pains',
                uf: 'MG',
                code: 3146503,
            },
            {
                name: 'Paiva',
                uf: 'MG',
                code: 3146602,
            },
            {
                name: 'Palma',
                uf: 'MG',
                code: 3146701,
            },
            {
                name: 'Palmópolis',
                uf: 'MG',
                code: 3146750,
            },
            {
                name: 'Papagaios',
                uf: 'MG',
                code: 3146909,
            },
            {
                name: 'Pará de Minas',
                uf: 'MG',
                code: 3147105,
            },
            {
                name: 'Paracatu',
                uf: 'MG',
                code: 3147006,
            },
            {
                name: 'Paraguaçu',
                uf: 'MG',
                code: 3147204,
            },
            {
                name: 'Paraisópolis',
                uf: 'MG',
                code: 3147303,
            },
            {
                name: 'Paraopeba',
                uf: 'MG',
                code: 3147402,
            },
            {
                name: 'Passa Quatro',
                uf: 'MG',
                code: 3147600,
            },
            {
                name: 'Passa Tempo',
                uf: 'MG',
                code: 3147709,
            },
            {
                name: 'Passa-Vinte',
                uf: 'MG',
                code: 3147808,
            },
            {
                name: 'Passabém',
                uf: 'MG',
                code: 3147501,
            },
            {
                name: 'Passos',
                uf: 'MG',
                code: 3147907,
            },
            {
                name: 'Patis',
                uf: 'MG',
                code: 3147956,
            },
            {
                name: 'Patos de Minas',
                uf: 'MG',
                code: 3148004,
            },
            {
                name: 'Patrocínio',
                uf: 'MG',
                code: 3148103,
            },
            {
                name: 'Patrocínio do Muriaé',
                uf: 'MG',
                code: 3148202,
            },
            {
                name: 'Paula Cândido',
                uf: 'MG',
                code: 3148301,
            },
            {
                name: 'Paulistas',
                uf: 'MG',
                code: 3148400,
            },
            {
                name: 'Pavão',
                uf: 'MG',
                code: 3148509,
            },
            {
                name: 'Peçanha',
                uf: 'MG',
                code: 3148608,
            },
            {
                name: 'Pedra Azul',
                uf: 'MG',
                code: 3148707,
            },
            {
                name: 'Pedra Bonita',
                uf: 'MG',
                code: 3148756,
            },
            {
                name: 'Pedra do Anta',
                uf: 'MG',
                code: 3148806,
            },
            {
                name: 'Pedra do Indaiá',
                uf: 'MG',
                code: 3148905,
            },
            {
                name: 'Pedra Dourada',
                uf: 'MG',
                code: 3149002,
            },
            {
                name: 'Pedralva',
                uf: 'MG',
                code: 3149101,
            },
            {
                name: 'Pedras de Maria da Cruz',
                uf: 'MG',
                code: 3149150,
            },
            {
                name: 'Pedrinópolis',
                uf: 'MG',
                code: 3149200,
            },
            {
                name: 'Pedro Leopoldo',
                uf: 'MG',
                code: 3149309,
            },
            {
                name: 'Pedro Teixeira',
                uf: 'MG',
                code: 3149408,
            },
            {
                name: 'Pequeri',
                uf: 'MG',
                code: 3149507,
            },
            {
                name: 'Pequi',
                uf: 'MG',
                code: 3149606,
            },
            {
                name: 'Perdigão',
                uf: 'MG',
                code: 3149705,
            },
            {
                name: 'Perdizes',
                uf: 'MG',
                code: 3149804,
            },
            {
                name: 'Perdões',
                uf: 'MG',
                code: 3149903,
            },
            {
                name: 'Periquito',
                uf: 'MG',
                code: 3149952,
            },
            {
                name: 'Pescador',
                uf: 'MG',
                code: 3150000,
            },
            {
                name: 'Piau',
                uf: 'MG',
                code: 3150109,
            },
            {
                name: 'Piedade de Caratinga',
                uf: 'MG',
                code: 3150158,
            },
            {
                name: 'Piedade de Ponte Nova',
                uf: 'MG',
                code: 3150208,
            },
            {
                name: 'Piedade do Rio Grande',
                uf: 'MG',
                code: 3150307,
            },
            {
                name: 'Piedade dos Gerais',
                uf: 'MG',
                code: 3150406,
            },
            {
                name: 'Pimenta',
                uf: 'MG',
                code: 3150505,
            },
            {
                name: "Pingo-d'Água",
                uf: 'MG',
                code: 3150539,
            },
            {
                name: 'Pintópolis',
                uf: 'MG',
                code: 3150570,
            },
            {
                name: 'Piracema',
                uf: 'MG',
                code: 3150604,
            },
            {
                name: 'Pirajuba',
                uf: 'MG',
                code: 3150703,
            },
            {
                name: 'Piranga',
                uf: 'MG',
                code: 3150802,
            },
            {
                name: 'Piranguçu',
                uf: 'MG',
                code: 3150901,
            },
            {
                name: 'Piranguinho',
                uf: 'MG',
                code: 3151008,
            },
            {
                name: 'Pirapetinga',
                uf: 'MG',
                code: 3151107,
            },
            {
                name: 'Pirapora',
                uf: 'MG',
                code: 3151206,
            },
            {
                name: 'Piraúba',
                uf: 'MG',
                code: 3151305,
            },
            {
                name: 'Pitangui',
                uf: 'MG',
                code: 3151404,
            },
            {
                name: 'Piumhi',
                uf: 'MG',
                code: 3151503,
            },
            {
                name: 'Planura',
                uf: 'MG',
                code: 3151602,
            },
            {
                name: 'Poço Fundo',
                uf: 'MG',
                code: 3151701,
            },
            {
                name: 'Poços de Caldas',
                uf: 'MG',
                code: 3151800,
            },
            {
                name: 'Pocrane',
                uf: 'MG',
                code: 3151909,
            },
            {
                name: 'Pompéu',
                uf: 'MG',
                code: 3152006,
            },
            {
                name: 'Ponte Nova',
                uf: 'MG',
                code: 3152105,
            },
            {
                name: 'Ponto Chique',
                uf: 'MG',
                code: 3152131,
            },
            {
                name: 'Ponto dos Volantes',
                uf: 'MG',
                code: 3152170,
            },
            {
                name: 'Porteirinha',
                uf: 'MG',
                code: 3152204,
            },
            {
                name: 'Porto Firme',
                uf: 'MG',
                code: 3152303,
            },
            {
                name: 'Poté',
                uf: 'MG',
                code: 3152402,
            },
            {
                name: 'Pouso Alegre',
                uf: 'MG',
                code: 3152501,
            },
            {
                name: 'Pouso Alto',
                uf: 'MG',
                code: 3152600,
            },
            {
                name: 'Prados',
                uf: 'MG',
                code: 3152709,
            },
            {
                name: 'Prata',
                uf: 'MG',
                code: 3152808,
            },
            {
                name: 'Pratápolis',
                uf: 'MG',
                code: 3152907,
            },
            {
                name: 'Pratinha',
                uf: 'MG',
                code: 3153004,
            },
            {
                name: 'Presidente Bernardes',
                uf: 'MG',
                code: 3153103,
            },
            {
                name: 'Presidente Juscelino',
                uf: 'MG',
                code: 3153202,
            },
            {
                name: 'Presidente Kubitschek',
                uf: 'MG',
                code: 3153301,
            },
            {
                name: 'Presidente Olegário',
                uf: 'MG',
                code: 3153400,
            },
            {
                name: 'Prudente de Morais',
                uf: 'MG',
                code: 3153608,
            },
            {
                name: 'Quartel Geral',
                uf: 'MG',
                code: 3153707,
            },
            {
                name: 'Queluzito',
                uf: 'MG',
                code: 3153806,
            },
            {
                name: 'Raposos',
                uf: 'MG',
                code: 3153905,
            },
            {
                name: 'Raul Soares',
                uf: 'MG',
                code: 3154002,
            },
            {
                name: 'Recreio',
                uf: 'MG',
                code: 3154101,
            },
            {
                name: 'Reduto',
                uf: 'MG',
                code: 3154150,
            },
            {
                name: 'Resende Costa',
                uf: 'MG',
                code: 3154200,
            },
            {
                name: 'Resplendor',
                uf: 'MG',
                code: 3154309,
            },
            {
                name: 'Ressaquinha',
                uf: 'MG',
                code: 3154408,
            },
            {
                name: 'Riachinho',
                uf: 'MG',
                code: 3154457,
            },
            {
                name: 'Riacho dos Machados',
                uf: 'MG',
                code: 3154507,
            },
            {
                name: 'Ribeirão das Neves',
                uf: 'MG',
                code: 3154606,
            },
            {
                name: 'Ribeirão Vermelho',
                uf: 'MG',
                code: 3154705,
            },
            {
                name: 'Rio Acima',
                uf: 'MG',
                code: 3154804,
            },
            {
                name: 'Rio Casca',
                uf: 'MG',
                code: 3154903,
            },
            {
                name: 'Rio do Prado',
                uf: 'MG',
                code: 3155108,
            },
            {
                name: 'Rio Doce',
                uf: 'MG',
                code: 3155009,
            },
            {
                name: 'Rio Espera',
                uf: 'MG',
                code: 3155207,
            },
            {
                name: 'Rio Manso',
                uf: 'MG',
                code: 3155306,
            },
            {
                name: 'Rio Novo',
                uf: 'MG',
                code: 3155405,
            },
            {
                name: 'Rio Paranaíba',
                uf: 'MG',
                code: 3155504,
            },
            {
                name: 'Rio Pardo de Minas',
                uf: 'MG',
                code: 3155603,
            },
            {
                name: 'Rio Piracicaba',
                uf: 'MG',
                code: 3155702,
            },
            {
                name: 'Rio Pomba',
                uf: 'MG',
                code: 3155801,
            },
            {
                name: 'Rio Preto',
                uf: 'MG',
                code: 3155900,
            },
            {
                name: 'Rio Vermelho',
                uf: 'MG',
                code: 3156007,
            },
            {
                name: 'Ritápolis',
                uf: 'MG',
                code: 3156106,
            },
            {
                name: 'Rochedo de Minas',
                uf: 'MG',
                code: 3156205,
            },
            {
                name: 'Rodeiro',
                uf: 'MG',
                code: 3156304,
            },
            {
                name: 'Romaria',
                uf: 'MG',
                code: 3156403,
            },
            {
                name: 'Rosário da Limeira',
                uf: 'MG',
                code: 3156452,
            },
            {
                name: 'Rubelita',
                uf: 'MG',
                code: 3156502,
            },
            {
                name: 'Rubim',
                uf: 'MG',
                code: 3156601,
            },
            {
                name: 'Sabará',
                uf: 'MG',
                code: 3156700,
            },
            {
                name: 'Sabinópolis',
                uf: 'MG',
                code: 3156809,
            },
            {
                name: 'Sacramento',
                uf: 'MG',
                code: 3156908,
            },
            {
                name: 'Salinas',
                uf: 'MG',
                code: 3157005,
            },
            {
                name: 'Salto da Divisa',
                uf: 'MG',
                code: 3157104,
            },
            {
                name: 'Santa Bárbara',
                uf: 'MG',
                code: 3157203,
            },
            {
                name: 'Santa Bárbara do Leste',
                uf: 'MG',
                code: 3157252,
            },
            {
                name: 'Santa Bárbara do Monte Verde',
                uf: 'MG',
                code: 3157278,
            },
            {
                name: 'Santa Bárbara do Tugúrio',
                uf: 'MG',
                code: 3157302,
            },
            {
                name: 'Santa Cruz de Minas',
                uf: 'MG',
                code: 3157336,
            },
            {
                name: 'Santa Cruz de Salinas',
                uf: 'MG',
                code: 3157377,
            },
            {
                name: 'Santa Cruz do Escalvado',
                uf: 'MG',
                code: 3157401,
            },
            {
                name: 'Santa Efigênia de Minas',
                uf: 'MG',
                code: 3157500,
            },
            {
                name: 'Santa Fé de Minas',
                uf: 'MG',
                code: 3157609,
            },
            {
                name: 'Santa Helena de Minas',
                uf: 'MG',
                code: 3157658,
            },
            {
                name: 'Santa Juliana',
                uf: 'MG',
                code: 3157708,
            },
            {
                name: 'Santa Luzia',
                uf: 'MG',
                code: 3157807,
            },
            {
                name: 'Santa Margarida',
                uf: 'MG',
                code: 3157906,
            },
            {
                name: 'Santa Maria de Itabira',
                uf: 'MG',
                code: 3158003,
            },
            {
                name: 'Santa Maria do Salto',
                uf: 'MG',
                code: 3158102,
            },
            {
                name: 'Santa Maria do Suaçuí',
                uf: 'MG',
                code: 3158201,
            },
            {
                name: 'Santa Rita de Caldas',
                uf: 'MG',
                code: 3159209,
            },
            {
                name: 'Santa Rita de Ibitipoca',
                uf: 'MG',
                code: 3159407,
            },
            {
                name: 'Santa Rita de Jacutinga',
                uf: 'MG',
                code: 3159308,
            },
            {
                name: 'Santa Rita de Minas',
                uf: 'MG',
                code: 3159357,
            },
            {
                name: 'Santa Rita do Itueto',
                uf: 'MG',
                code: 3159506,
            },
            {
                name: 'Santa Rita do Sapucaí',
                uf: 'MG',
                code: 3159605,
            },
            {
                name: 'Santa Rosa da Serra',
                uf: 'MG',
                code: 3159704,
            },
            {
                name: 'Santa Vitória',
                uf: 'MG',
                code: 3159803,
            },
            {
                name: 'Santana da Vargem',
                uf: 'MG',
                code: 3158300,
            },
            {
                name: 'Santana de Cataguases',
                uf: 'MG',
                code: 3158409,
            },
            {
                name: 'Santana de Pirapama',
                uf: 'MG',
                code: 3158508,
            },
            {
                name: 'Santana do Deserto',
                uf: 'MG',
                code: 3158607,
            },
            {
                name: 'Santana do Garambéu',
                uf: 'MG',
                code: 3158706,
            },
            {
                name: 'Santana do Jacaré',
                uf: 'MG',
                code: 3158805,
            },
            {
                name: 'Santana do Manhuaçu',
                uf: 'MG',
                code: 3158904,
            },
            {
                name: 'Santana do Paraíso',
                uf: 'MG',
                code: 3158953,
            },
            {
                name: 'Santana do Riacho',
                uf: 'MG',
                code: 3159001,
            },
            {
                name: 'Santana dos Montes',
                uf: 'MG',
                code: 3159100,
            },
            {
                name: 'Santo Antônio do Amparo',
                uf: 'MG',
                code: 3159902,
            },
            {
                name: 'Santo Antônio do Aventureiro',
                uf: 'MG',
                code: 3160009,
            },
            {
                name: 'Santo Antônio do Grama',
                uf: 'MG',
                code: 3160108,
            },
            {
                name: 'Santo Antônio do Itambé',
                uf: 'MG',
                code: 3160207,
            },
            {
                name: 'Santo Antônio do Jacinto',
                uf: 'MG',
                code: 3160306,
            },
            {
                name: 'Santo Antônio do Monte',
                uf: 'MG',
                code: 3160405,
            },
            {
                name: 'Santo Antônio do Retiro',
                uf: 'MG',
                code: 3160454,
            },
            {
                name: 'Santo Antônio do Rio Abaixo',
                uf: 'MG',
                code: 3160504,
            },
            {
                name: 'Santo Hipólito',
                uf: 'MG',
                code: 3160603,
            },
            {
                name: 'Santos Dumont',
                uf: 'MG',
                code: 3160702,
            },
            {
                name: 'São Bento Abade',
                uf: 'MG',
                code: 3160801,
            },
            {
                name: 'São Brás do Suaçuí',
                uf: 'MG',
                code: 3160900,
            },
            {
                name: 'São Domingos das Dores',
                uf: 'MG',
                code: 3160959,
            },
            {
                name: 'São Domingos do Prata',
                uf: 'MG',
                code: 3161007,
            },
            {
                name: 'São Félix de Minas',
                uf: 'MG',
                code: 3161056,
            },
            {
                name: 'São Francisco',
                uf: 'MG',
                code: 3161106,
            },
            {
                name: 'São Francisco de Paula',
                uf: 'MG',
                code: 3161205,
            },
            {
                name: 'São Francisco de Sales',
                uf: 'MG',
                code: 3161304,
            },
            {
                name: 'São Francisco do Glória',
                uf: 'MG',
                code: 3161403,
            },
            {
                name: 'São Geraldo',
                uf: 'MG',
                code: 3161502,
            },
            {
                name: 'São Geraldo da Piedade',
                uf: 'MG',
                code: 3161601,
            },
            {
                name: 'São Geraldo do Baixio',
                uf: 'MG',
                code: 3161650,
            },
            {
                name: 'São Gonçalo do Abaeté',
                uf: 'MG',
                code: 3161700,
            },
            {
                name: 'São Gonçalo do Pará',
                uf: 'MG',
                code: 3161809,
            },
            {
                name: 'São Gonçalo do Rio Abaixo',
                uf: 'MG',
                code: 3161908,
            },
            {
                name: 'São Gonçalo do Rio Preto',
                uf: 'MG',
                code: 3125507,
            },
            {
                name: 'São Gonçalo do Sapucaí',
                uf: 'MG',
                code: 3162005,
            },
            {
                name: 'São Gotardo',
                uf: 'MG',
                code: 3162104,
            },
            {
                name: 'São João Batista do Glória',
                uf: 'MG',
                code: 3162203,
            },
            {
                name: 'São João da Lagoa',
                uf: 'MG',
                code: 3162252,
            },
            {
                name: 'São João da Mata',
                uf: 'MG',
                code: 3162302,
            },
            {
                name: 'São João da Ponte',
                uf: 'MG',
                code: 3162401,
            },
            {
                name: 'São João das Missões',
                uf: 'MG',
                code: 3162450,
            },
            {
                name: 'São João del Rei',
                uf: 'MG',
                code: 3162500,
            },
            {
                name: 'São João do Manhuaçu',
                uf: 'MG',
                code: 3162559,
            },
            {
                name: 'São João do Manteninha',
                uf: 'MG',
                code: 3162575,
            },
            {
                name: 'São João do Oriente',
                uf: 'MG',
                code: 3162609,
            },
            {
                name: 'São João do Pacuí',
                uf: 'MG',
                code: 3162658,
            },
            {
                name: 'São João do Paraíso',
                uf: 'MG',
                code: 3162708,
            },
            {
                name: 'São João Evangelista',
                uf: 'MG',
                code: 3162807,
            },
            {
                name: 'São João Nepomuceno',
                uf: 'MG',
                code: 3162906,
            },
            {
                name: 'São Joaquim de Bicas',
                uf: 'MG',
                code: 3162922,
            },
            {
                name: 'São José da Barra',
                uf: 'MG',
                code: 3162948,
            },
            {
                name: 'São José da Lapa',
                uf: 'MG',
                code: 3162955,
            },
            {
                name: 'São José da Safira',
                uf: 'MG',
                code: 3163003,
            },
            {
                name: 'São José da Varginha',
                uf: 'MG',
                code: 3163102,
            },
            {
                name: 'São José do Alegre',
                uf: 'MG',
                code: 3163201,
            },
            {
                name: 'São José do Divino',
                uf: 'MG',
                code: 3163300,
            },
            {
                name: 'São José do Goiabal',
                uf: 'MG',
                code: 3163409,
            },
            {
                name: 'São José do Jacuri',
                uf: 'MG',
                code: 3163508,
            },
            {
                name: 'São José do Mantimento',
                uf: 'MG',
                code: 3163607,
            },
            {
                name: 'São Lourenço',
                uf: 'MG',
                code: 3163706,
            },
            {
                name: 'São Miguel do Anta',
                uf: 'MG',
                code: 3163805,
            },
            {
                name: 'São Pedro da União',
                uf: 'MG',
                code: 3163904,
            },
            {
                name: 'São Pedro do Suaçuí',
                uf: 'MG',
                code: 3164100,
            },
            {
                name: 'São Pedro dos Ferros',
                uf: 'MG',
                code: 3164001,
            },
            {
                name: 'São Romão',
                uf: 'MG',
                code: 3164209,
            },
            {
                name: 'São Roque de Minas',
                uf: 'MG',
                code: 3164308,
            },
            {
                name: 'São Sebastião da Bela Vista',
                uf: 'MG',
                code: 3164407,
            },
            {
                name: 'São Sebastião da Vargem Alegre',
                uf: 'MG',
                code: 3164431,
            },
            {
                name: 'São Sebastião do Anta',
                uf: 'MG',
                code: 3164472,
            },
            {
                name: 'São Sebastião do Maranhão',
                uf: 'MG',
                code: 3164506,
            },
            {
                name: 'São Sebastião do Oeste',
                uf: 'MG',
                code: 3164605,
            },
            {
                name: 'São Sebastião do Paraíso',
                uf: 'MG',
                code: 3164704,
            },
            {
                name: 'São Sebastião do Rio Preto',
                uf: 'MG',
                code: 3164803,
            },
            {
                name: 'São Sebastião do Rio Verde',
                uf: 'MG',
                code: 3164902,
            },
            {
                name: 'São Thomé das Letras',
                uf: 'MG',
                code: 3165206,
            },
            {
                name: 'São Tiago',
                uf: 'MG',
                code: 3165008,
            },
            {
                name: 'São Tomás de Aquino',
                uf: 'MG',
                code: 3165107,
            },
            {
                name: 'São Vicente de Minas',
                uf: 'MG',
                code: 3165305,
            },
            {
                name: 'Sapucaí-Mirim',
                uf: 'MG',
                code: 3165404,
            },
            {
                name: 'Sardoá',
                uf: 'MG',
                code: 3165503,
            },
            {
                name: 'Sarzedo',
                uf: 'MG',
                code: 3165537,
            },
            {
                name: 'Sem-Peixe',
                uf: 'MG',
                code: 3165560,
            },
            {
                name: 'Senador Amaral',
                uf: 'MG',
                code: 3165578,
            },
            {
                name: 'Senador Cortes',
                uf: 'MG',
                code: 3165602,
            },
            {
                name: 'Senador Firmino',
                uf: 'MG',
                code: 3165701,
            },
            {
                name: 'Senador José Bento',
                uf: 'MG',
                code: 3165800,
            },
            {
                name: 'Senador Modestino Gonçalves',
                uf: 'MG',
                code: 3165909,
            },
            {
                name: 'Senhora de Oliveira',
                uf: 'MG',
                code: 3166006,
            },
            {
                name: 'Senhora do Porto',
                uf: 'MG',
                code: 3166105,
            },
            {
                name: 'Senhora dos Remédios',
                uf: 'MG',
                code: 3166204,
            },
            {
                name: 'Sericita',
                uf: 'MG',
                code: 3166303,
            },
            {
                name: 'Seritinga',
                uf: 'MG',
                code: 3166402,
            },
            {
                name: 'Serra Azul de Minas',
                uf: 'MG',
                code: 3166501,
            },
            {
                name: 'Serra da Saudade',
                uf: 'MG',
                code: 3166600,
            },
            {
                name: 'Serra do Salitre',
                uf: 'MG',
                code: 3166808,
            },
            {
                name: 'Serra dos Aimorés',
                uf: 'MG',
                code: 3166709,
            },
            {
                name: 'Serrania',
                uf: 'MG',
                code: 3166907,
            },
            {
                name: 'Serranópolis de Minas',
                uf: 'MG',
                code: 3166956,
            },
            {
                name: 'Serranos',
                uf: 'MG',
                code: 3167004,
            },
            {
                name: 'Serro',
                uf: 'MG',
                code: 3167103,
            },
            {
                name: 'Sete Lagoas',
                uf: 'MG',
                code: 3167202,
            },
            {
                name: 'Setubinha',
                uf: 'MG',
                code: 3165552,
            },
            {
                name: 'Silveirânia',
                uf: 'MG',
                code: 3167301,
            },
            {
                name: 'Silvianópolis',
                uf: 'MG',
                code: 3167400,
            },
            {
                name: 'Simão Pereira',
                uf: 'MG',
                code: 3167509,
            },
            {
                name: 'Simonésia',
                uf: 'MG',
                code: 3167608,
            },
            {
                name: 'Sobrália',
                uf: 'MG',
                code: 3167707,
            },
            {
                name: 'Soledade de Minas',
                uf: 'MG',
                code: 3167806,
            },
            {
                name: 'Tabuleiro',
                uf: 'MG',
                code: 3167905,
            },
            {
                name: 'Taiobeiras',
                uf: 'MG',
                code: 3168002,
            },
            {
                name: 'Taparuba',
                uf: 'MG',
                code: 3168051,
            },
            {
                name: 'Tapira',
                uf: 'MG',
                code: 3168101,
            },
            {
                name: 'Tapiraí',
                uf: 'MG',
                code: 3168200,
            },
            {
                name: 'Taquaraçu de Minas',
                uf: 'MG',
                code: 3168309,
            },
            {
                name: 'Tarumirim',
                uf: 'MG',
                code: 3168408,
            },
            {
                name: 'Teixeiras',
                uf: 'MG',
                code: 3168507,
            },
            {
                name: 'Teófilo Otoni',
                uf: 'MG',
                code: 3168606,
            },
            {
                name: 'Timóteo',
                uf: 'MG',
                code: 3168705,
            },
            {
                name: 'Tiradentes',
                uf: 'MG',
                code: 3168804,
            },
            {
                name: 'Tiros',
                uf: 'MG',
                code: 3168903,
            },
            {
                name: 'Tocantins',
                uf: 'MG',
                code: 3169000,
            },
            {
                name: 'Tocos do Moji',
                uf: 'MG',
                code: 3169059,
            },
            {
                name: 'Toledo',
                uf: 'MG',
                code: 3169109,
            },
            {
                name: 'Tombos',
                uf: 'MG',
                code: 3169208,
            },
            {
                name: 'Três Corações',
                uf: 'MG',
                code: 3169307,
            },
            {
                name: 'Três Marias',
                uf: 'MG',
                code: 3169356,
            },
            {
                name: 'Três Pontas',
                uf: 'MG',
                code: 3169406,
            },
            {
                name: 'Tumiritinga',
                uf: 'MG',
                code: 3169505,
            },
            {
                name: 'Tupaciguara',
                uf: 'MG',
                code: 3169604,
            },
            {
                name: 'Turmalina',
                uf: 'MG',
                code: 3169703,
            },
            {
                name: 'Turvolândia',
                uf: 'MG',
                code: 3169802,
            },
            {
                name: 'Ubá',
                uf: 'MG',
                code: 3169901,
            },
            {
                name: 'Ubaí',
                uf: 'MG',
                code: 3170008,
            },
            {
                name: 'Ubaporanga',
                uf: 'MG',
                code: 3170057,
            },
            {
                name: 'Uberaba',
                uf: 'MG',
                code: 3170107,
            },
            {
                name: 'Uberlândia',
                uf: 'MG',
                code: 3170206,
            },
            {
                name: 'Umburatiba',
                uf: 'MG',
                code: 3170305,
            },
            {
                name: 'Unaí',
                uf: 'MG',
                code: 3170404,
            },
            {
                name: 'União de Minas',
                uf: 'MG',
                code: 3170438,
            },
            {
                name: 'Uruana de Minas',
                uf: 'MG',
                code: 3170479,
            },
            {
                name: 'Urucânia',
                uf: 'MG',
                code: 3170503,
            },
            {
                name: 'Urucuia',
                uf: 'MG',
                code: 3170529,
            },
            {
                name: 'Vargem Alegre',
                uf: 'MG',
                code: 3170578,
            },
            {
                name: 'Vargem Bonita',
                uf: 'MG',
                code: 3170602,
            },
            {
                name: 'Vargem Grande do Rio Pardo',
                uf: 'MG',
                code: 3170651,
            },
            {
                name: 'Varginha',
                uf: 'MG',
                code: 3170701,
            },
            {
                name: 'Varjão de Minas',
                uf: 'MG',
                code: 3170750,
            },
            {
                name: 'Várzea da Palma',
                uf: 'MG',
                code: 3170800,
            },
            {
                name: 'Varzelândia',
                uf: 'MG',
                code: 3170909,
            },
            {
                name: 'Vazante',
                uf: 'MG',
                code: 3171006,
            },
            {
                name: 'Verdelândia',
                uf: 'MG',
                code: 3171030,
            },
            {
                name: 'Veredinha',
                uf: 'MG',
                code: 3171071,
            },
            {
                name: 'Veríssimo',
                uf: 'MG',
                code: 3171105,
            },
            {
                name: 'Vermelho Novo',
                uf: 'MG',
                code: 3171154,
            },
            {
                name: 'Vespasiano',
                uf: 'MG',
                code: 3171204,
            },
            {
                name: 'Viçosa',
                uf: 'MG',
                code: 3171303,
            },
            {
                name: 'Vieiras',
                uf: 'MG',
                code: 3171402,
            },
            {
                name: 'Virgem da Lapa',
                uf: 'MG',
                code: 3171600,
            },
            {
                name: 'Virgínia',
                uf: 'MG',
                code: 3171709,
            },
            {
                name: 'Virginópolis',
                uf: 'MG',
                code: 3171808,
            },
            {
                name: 'Virgolândia',
                uf: 'MG',
                code: 3171907,
            },
            {
                name: 'Visconde do Rio Branco',
                uf: 'MG',
                code: 3172004,
            },
            {
                name: 'Volta Grande',
                uf: 'MG',
                code: 3172103,
            },
            {
                name: 'Wenceslau Braz',
                uf: 'MG',
                code: 3172202,
            },
            {
                name: 'Água Clara',
                uf: 'MS',
                code: 5000203,
            },
            {
                name: 'Alcinópolis',
                uf: 'MS',
                code: 5000252,
            },
            {
                name: 'Amambai',
                uf: 'MS',
                code: 5000609,
            },
            {
                name: 'Anastácio',
                uf: 'MS',
                code: 5000708,
            },
            {
                name: 'Anaurilândia',
                uf: 'MS',
                code: 5000807,
            },
            {
                name: 'Angélica',
                uf: 'MS',
                code: 5000856,
            },
            {
                name: 'Antônio João',
                uf: 'MS',
                code: 5000906,
            },
            {
                name: 'Aparecida do Taboado',
                uf: 'MS',
                code: 5001003,
            },
            {
                name: 'Aquidauana',
                uf: 'MS',
                code: 5001102,
            },
            {
                name: 'Aral Moreira',
                uf: 'MS',
                code: 5001243,
            },
            {
                name: 'Bandeirantes',
                uf: 'MS',
                code: 5001508,
            },
            {
                name: 'Bataguassu',
                uf: 'MS',
                code: 5001904,
            },
            {
                name: 'Batayporã',
                uf: 'MS',
                code: 5002001,
            },
            {
                name: 'Bela Vista',
                uf: 'MS',
                code: 5002100,
            },
            {
                name: 'Bodoquena',
                uf: 'MS',
                code: 5002159,
            },
            {
                name: 'Bonito',
                uf: 'MS',
                code: 5002209,
            },
            {
                name: 'Brasilândia',
                uf: 'MS',
                code: 5002308,
            },
            {
                name: 'Caarapó',
                uf: 'MS',
                code: 5002407,
            },
            {
                name: 'Camapuã',
                uf: 'MS',
                code: 5002605,
            },
            {
                name: 'Campo Grande',
                uf: 'MS',
                code: 5002704,
            },
            {
                name: 'Caracol',
                uf: 'MS',
                code: 5002803,
            },
            {
                name: 'Cassilândia',
                uf: 'MS',
                code: 5002902,
            },
            {
                name: 'Chapadão do Sul',
                uf: 'MS',
                code: 5002951,
            },
            {
                name: 'Corguinho',
                uf: 'MS',
                code: 5003108,
            },
            {
                name: 'Coronel Sapucaia',
                uf: 'MS',
                code: 5003157,
            },
            {
                name: 'Corumbá',
                uf: 'MS',
                code: 5003207,
            },
            {
                name: 'Costa Rica',
                uf: 'MS',
                code: 5003256,
            },
            {
                name: 'Coxim',
                uf: 'MS',
                code: 5003306,
            },
            {
                name: 'Deodápolis',
                uf: 'MS',
                code: 5003454,
            },
            {
                name: 'Dois Irmãos do Buriti',
                uf: 'MS',
                code: 5003488,
            },
            {
                name: 'Douradina',
                uf: 'MS',
                code: 5003504,
            },
            {
                name: 'Dourados',
                uf: 'MS',
                code: 5003702,
            },
            {
                name: 'Eldorado',
                uf: 'MS',
                code: 5003751,
            },
            {
                name: 'Fátima do Sul',
                uf: 'MS',
                code: 5003801,
            },
            {
                name: 'Figueirão',
                uf: 'MS',
                code: 5003900,
            },
            {
                name: 'Glória de Dourados',
                uf: 'MS',
                code: 5004007,
            },
            {
                name: 'Guia Lopes da Laguna',
                uf: 'MS',
                code: 5004106,
            },
            {
                name: 'Iguatemi',
                uf: 'MS',
                code: 5004304,
            },
            {
                name: 'Inocência',
                uf: 'MS',
                code: 5004403,
            },
            {
                name: 'Itaporã',
                uf: 'MS',
                code: 5004502,
            },
            {
                name: 'Itaquiraí',
                uf: 'MS',
                code: 5004601,
            },
            {
                name: 'Ivinhema',
                uf: 'MS',
                code: 5004700,
            },
            {
                name: 'Japorã',
                uf: 'MS',
                code: 5004809,
            },
            {
                name: 'Jaraguari',
                uf: 'MS',
                code: 5004908,
            },
            {
                name: 'Jardim',
                uf: 'MS',
                code: 5005004,
            },
            {
                name: 'Jateí',
                uf: 'MS',
                code: 5005103,
            },
            {
                name: 'Juti',
                uf: 'MS',
                code: 5005152,
            },
            {
                name: 'Ladário',
                uf: 'MS',
                code: 5005202,
            },
            {
                name: 'Laguna Carapã',
                uf: 'MS',
                code: 5005251,
            },
            {
                name: 'Maracaju',
                uf: 'MS',
                code: 5005400,
            },
            {
                name: 'Miranda',
                uf: 'MS',
                code: 5005608,
            },
            {
                name: 'Mundo Novo',
                uf: 'MS',
                code: 5005681,
            },
            {
                name: 'Naviraí',
                uf: 'MS',
                code: 5005707,
            },
            {
                name: 'Nioaque',
                uf: 'MS',
                code: 5005806,
            },
            {
                name: 'Nova Alvorada do Sul',
                uf: 'MS',
                code: 5006002,
            },
            {
                name: 'Nova Andradina',
                uf: 'MS',
                code: 5006200,
            },
            {
                name: 'Novo Horizonte do Sul',
                uf: 'MS',
                code: 5006259,
            },
            {
                name: 'Paraíso das Águas',
                uf: 'MS',
                code: 5006275,
            },
            {
                name: 'Paranaíba',
                uf: 'MS',
                code: 5006309,
            },
            {
                name: 'Paranhos',
                uf: 'MS',
                code: 5006358,
            },
            {
                name: 'Pedro Gomes',
                uf: 'MS',
                code: 5006408,
            },
            {
                name: 'Ponta Porã',
                uf: 'MS',
                code: 5006606,
            },
            {
                name: 'Porto Murtinho',
                uf: 'MS',
                code: 5006903,
            },
            {
                name: 'Ribas do Rio Pardo',
                uf: 'MS',
                code: 5007109,
            },
            {
                name: 'Rio Brilhante',
                uf: 'MS',
                code: 5007208,
            },
            {
                name: 'Rio Negro',
                uf: 'MS',
                code: 5007307,
            },
            {
                name: 'Rio Verde de Mato Grosso',
                uf: 'MS',
                code: 5007406,
            },
            {
                name: 'Rochedo',
                uf: 'MS',
                code: 5007505,
            },
            {
                name: 'Santa Rita do Pardo',
                uf: 'MS',
                code: 5007554,
            },
            {
                name: 'São Gabriel do Oeste',
                uf: 'MS',
                code: 5007695,
            },
            {
                name: 'Selvíria',
                uf: 'MS',
                code: 5007802,
            },
            {
                name: 'Sete Quedas',
                uf: 'MS',
                code: 5007703,
            },
            {
                name: 'Sidrolândia',
                uf: 'MS',
                code: 5007901,
            },
            {
                name: 'Sonora',
                uf: 'MS',
                code: 5007935,
            },
            {
                name: 'Tacuru',
                uf: 'MS',
                code: 5007950,
            },
            {
                name: 'Taquarussu',
                uf: 'MS',
                code: 5007976,
            },
            {
                name: 'Terenos',
                uf: 'MS',
                code: 5008008,
            },
            {
                name: 'Três Lagoas',
                uf: 'MS',
                code: 5008305,
            },
            {
                name: 'Vicentina',
                uf: 'MS',
                code: 5008404,
            },
            {
                name: 'Acorizal',
                uf: 'MT',
                code: 5100102,
            },
            {
                name: 'Água Boa',
                uf: 'MT',
                code: 5100201,
            },
            {
                name: 'Alta Floresta',
                uf: 'MT',
                code: 5100250,
            },
            {
                name: 'Alto Araguaia',
                uf: 'MT',
                code: 5100300,
            },
            {
                name: 'Alto Boa Vista',
                uf: 'MT',
                code: 5100359,
            },
            {
                name: 'Alto Garças',
                uf: 'MT',
                code: 5100409,
            },
            {
                name: 'Alto Paraguai',
                uf: 'MT',
                code: 5100508,
            },
            {
                name: 'Alto Taquari',
                uf: 'MT',
                code: 5100607,
            },
            {
                name: 'Apiacás',
                uf: 'MT',
                code: 5100805,
            },
            {
                name: 'Araguaiana',
                uf: 'MT',
                code: 5101001,
            },
            {
                name: 'Araguainha',
                uf: 'MT',
                code: 5101209,
            },
            {
                name: 'Araputanga',
                uf: 'MT',
                code: 5101258,
            },
            {
                name: 'Arenápolis',
                uf: 'MT',
                code: 5101308,
            },
            {
                name: 'Aripuanã',
                uf: 'MT',
                code: 5101407,
            },
            {
                name: 'Barão de Melgaço',
                uf: 'MT',
                code: 5101605,
            },
            {
                name: 'Barra do Bugres',
                uf: 'MT',
                code: 5101704,
            },
            {
                name: 'Barra do Garças',
                uf: 'MT',
                code: 5101803,
            },
            {
                name: 'Bom Jesus do Araguaia',
                uf: 'MT',
                code: 5101852,
            },
            {
                name: 'Brasnorte',
                uf: 'MT',
                code: 5101902,
            },
            {
                name: 'Cáceres',
                uf: 'MT',
                code: 5102504,
            },
            {
                name: 'Campinápolis',
                uf: 'MT',
                code: 5102603,
            },
            {
                name: 'Campo Novo do Parecis',
                uf: 'MT',
                code: 5102637,
            },
            {
                name: 'Campo Verde',
                uf: 'MT',
                code: 5102678,
            },
            {
                name: 'Campos de Júlio',
                uf: 'MT',
                code: 5102686,
            },
            {
                name: 'Canabrava do Norte',
                uf: 'MT',
                code: 5102694,
            },
            {
                name: 'Canarana',
                uf: 'MT',
                code: 5102702,
            },
            {
                name: 'Carlinda',
                uf: 'MT',
                code: 5102793,
            },
            {
                name: 'Castanheira',
                uf: 'MT',
                code: 5102850,
            },
            {
                name: 'Chapada dos Guimarães',
                uf: 'MT',
                code: 5103007,
            },
            {
                name: 'Cláudia',
                uf: 'MT',
                code: 5103056,
            },
            {
                name: 'Cocalinho',
                uf: 'MT',
                code: 5103106,
            },
            {
                name: 'Colíder',
                uf: 'MT',
                code: 5103205,
            },
            {
                name: 'Colniza',
                uf: 'MT',
                code: 5103254,
            },
            {
                name: 'Comodoro',
                uf: 'MT',
                code: 5103304,
            },
            {
                name: 'Confresa',
                uf: 'MT',
                code: 5103353,
            },
            {
                name: "Conquista D'Oeste",
                uf: 'MT',
                code: 5103361,
            },
            {
                name: 'Cotriguaçu',
                uf: 'MT',
                code: 5103379,
            },
            {
                name: 'Cuiabá',
                uf: 'MT',
                code: 5103403,
            },
            {
                name: 'Curvelândia',
                uf: 'MT',
                code: 5103437,
            },
            {
                name: 'Denise',
                uf: 'MT',
                code: 5103452,
            },
            {
                name: 'Diamantino',
                uf: 'MT',
                code: 5103502,
            },
            {
                name: 'Dom Aquino',
                uf: 'MT',
                code: 5103601,
            },
            {
                name: 'Feliz Natal',
                uf: 'MT',
                code: 5103700,
            },
            {
                name: "Figueirópolis D'Oeste",
                uf: 'MT',
                code: 5103809,
            },
            {
                name: 'Gaúcha do Norte',
                uf: 'MT',
                code: 5103858,
            },
            {
                name: 'General Carneiro',
                uf: 'MT',
                code: 5103908,
            },
            {
                name: "Glória D'Oeste",
                uf: 'MT',
                code: 5103957,
            },
            {
                name: 'Guarantã do Norte',
                uf: 'MT',
                code: 5104104,
            },
            {
                name: 'Guiratinga',
                uf: 'MT',
                code: 5104203,
            },
            {
                name: 'Indiavaí',
                uf: 'MT',
                code: 5104500,
            },
            {
                name: 'Ipiranga do Norte',
                uf: 'MT',
                code: 5104526,
            },
            {
                name: 'Itanhangá',
                uf: 'MT',
                code: 5104542,
            },
            {
                name: 'Itaúba',
                uf: 'MT',
                code: 5104559,
            },
            {
                name: 'Itiquira',
                uf: 'MT',
                code: 5104609,
            },
            {
                name: 'Jaciara',
                uf: 'MT',
                code: 5104807,
            },
            {
                name: 'Jangada',
                uf: 'MT',
                code: 5104906,
            },
            {
                name: 'Jauru',
                uf: 'MT',
                code: 5105002,
            },
            {
                name: 'Juara',
                uf: 'MT',
                code: 5105101,
            },
            {
                name: 'Juína',
                uf: 'MT',
                code: 5105150,
            },
            {
                name: 'Juruena',
                uf: 'MT',
                code: 5105176,
            },
            {
                name: 'Juscimeira',
                uf: 'MT',
                code: 5105200,
            },
            {
                name: "Lambari D'Oeste",
                uf: 'MT',
                code: 5105234,
            },
            {
                name: 'Lucas do Rio Verde',
                uf: 'MT',
                code: 5105259,
            },
            {
                name: 'Luciara',
                uf: 'MT',
                code: 5105309,
            },
            {
                name: 'Marcelândia',
                uf: 'MT',
                code: 5105580,
            },
            {
                name: 'Matupá',
                uf: 'MT',
                code: 5105606,
            },
            {
                name: "Mirassol d'Oeste",
                uf: 'MT',
                code: 5105622,
            },
            {
                name: 'Nobres',
                uf: 'MT',
                code: 5105903,
            },
            {
                name: 'Nortelândia',
                uf: 'MT',
                code: 5106000,
            },
            {
                name: 'Nossa Senhora do Livramento',
                uf: 'MT',
                code: 5106109,
            },
            {
                name: 'Nova Bandeirantes',
                uf: 'MT',
                code: 5106158,
            },
            {
                name: 'Nova Brasilândia',
                uf: 'MT',
                code: 5106208,
            },
            {
                name: 'Nova Canaã do Norte',
                uf: 'MT',
                code: 5106216,
            },
            {
                name: 'Nova Guarita',
                uf: 'MT',
                code: 5108808,
            },
            {
                name: 'Nova Lacerda',
                uf: 'MT',
                code: 5106182,
            },
            {
                name: 'Nova Marilândia',
                uf: 'MT',
                code: 5108857,
            },
            {
                name: 'Nova Maringá',
                uf: 'MT',
                code: 5108907,
            },
            {
                name: 'Nova Monte Verde',
                uf: 'MT',
                code: 5108956,
            },
            {
                name: 'Nova Mutum',
                uf: 'MT',
                code: 5106224,
            },
            {
                name: 'Nova Nazaré',
                uf: 'MT',
                code: 5106174,
            },
            {
                name: 'Nova Olímpia',
                uf: 'MT',
                code: 5106232,
            },
            {
                name: 'Nova Santa Helena',
                uf: 'MT',
                code: 5106190,
            },
            {
                name: 'Nova Ubiratã',
                uf: 'MT',
                code: 5106240,
            },
            {
                name: 'Nova Xavantina',
                uf: 'MT',
                code: 5106257,
            },
            {
                name: 'Novo Horizonte do Norte',
                uf: 'MT',
                code: 5106273,
            },
            {
                name: 'Novo Mundo',
                uf: 'MT',
                code: 5106265,
            },
            {
                name: 'Novo Santo Antônio',
                uf: 'MT',
                code: 5106315,
            },
            {
                name: 'Novo São Joaquim',
                uf: 'MT',
                code: 5106281,
            },
            {
                name: 'Paranaíta',
                uf: 'MT',
                code: 5106299,
            },
            {
                name: 'Paranatinga',
                uf: 'MT',
                code: 5106307,
            },
            {
                name: 'Pedra Preta',
                uf: 'MT',
                code: 5106372,
            },
            {
                name: 'Peixoto de Azevedo',
                uf: 'MT',
                code: 5106422,
            },
            {
                name: 'Planalto da Serra',
                uf: 'MT',
                code: 5106455,
            },
            {
                name: 'Poconé',
                uf: 'MT',
                code: 5106505,
            },
            {
                name: 'Pontal do Araguaia',
                uf: 'MT',
                code: 5106653,
            },
            {
                name: 'Ponte Branca',
                uf: 'MT',
                code: 5106703,
            },
            {
                name: 'Pontes e Lacerda',
                uf: 'MT',
                code: 5106752,
            },
            {
                name: 'Porto Alegre do Norte',
                uf: 'MT',
                code: 5106778,
            },
            {
                name: 'Porto dos Gaúchos',
                uf: 'MT',
                code: 5106802,
            },
            {
                name: 'Porto Esperidião',
                uf: 'MT',
                code: 5106828,
            },
            {
                name: 'Porto Estrela',
                uf: 'MT',
                code: 5106851,
            },
            {
                name: 'Poxoréo',
                uf: 'MT',
                code: 5107008,
            },
            {
                name: 'Primavera do Leste',
                uf: 'MT',
                code: 5107040,
            },
            {
                name: 'Querência',
                uf: 'MT',
                code: 5107065,
            },
            {
                name: 'Reserva do Cabaçal',
                uf: 'MT',
                code: 5107156,
            },
            {
                name: 'Ribeirão Cascalheira',
                uf: 'MT',
                code: 5107180,
            },
            {
                name: 'Ribeirãozinho',
                uf: 'MT',
                code: 5107198,
            },
            {
                name: 'Rio Branco',
                uf: 'MT',
                code: 5107206,
            },
            {
                name: 'Rondolândia',
                uf: 'MT',
                code: 5107578,
            },
            {
                name: 'Rondonópolis',
                uf: 'MT',
                code: 5107602,
            },
            {
                name: 'Rosário Oeste',
                uf: 'MT',
                code: 5107701,
            },
            {
                name: 'Salto do Céu',
                uf: 'MT',
                code: 5107750,
            },
            {
                name: 'Santa Carmem',
                uf: 'MT',
                code: 5107248,
            },
            {
                name: 'Santa Cruz do Xingu',
                uf: 'MT',
                code: 5107743,
            },
            {
                name: 'Santa Rita do Trivelato',
                uf: 'MT',
                code: 5107768,
            },
            {
                name: 'Santa Terezinha',
                uf: 'MT',
                code: 5107776,
            },
            {
                name: 'Santo Afonso',
                uf: 'MT',
                code: 5107263,
            },
            {
                name: 'Santo Antônio do Leste',
                uf: 'MT',
                code: 5107792,
            },
            {
                name: 'Santo Antônio do Leverger',
                uf: 'MT',
                code: 5107800,
            },
            {
                name: 'São Félix do Araguaia',
                uf: 'MT',
                code: 5107859,
            },
            {
                name: 'São José do Povo',
                uf: 'MT',
                code: 5107297,
            },
            {
                name: 'São José do Rio Claro',
                uf: 'MT',
                code: 5107305,
            },
            {
                name: 'São José do Xingu',
                uf: 'MT',
                code: 5107354,
            },
            {
                name: 'São José dos Quatro Marcos',
                uf: 'MT',
                code: 5107107,
            },
            {
                name: 'São Pedro da Cipa',
                uf: 'MT',
                code: 5107404,
            },
            {
                name: 'Sapezal',
                uf: 'MT',
                code: 5107875,
            },
            {
                name: 'Serra Nova Dourada',
                uf: 'MT',
                code: 5107883,
            },
            {
                name: 'Sinop',
                uf: 'MT',
                code: 5107909,
            },
            {
                name: 'Sorriso',
                uf: 'MT',
                code: 5107925,
            },
            {
                name: 'Tabaporã',
                uf: 'MT',
                code: 5107941,
            },
            {
                name: 'Tangará da Serra',
                uf: 'MT',
                code: 5107958,
            },
            {
                name: 'Tapurah',
                uf: 'MT',
                code: 5108006,
            },
            {
                name: 'Terra Nova do Norte',
                uf: 'MT',
                code: 5108055,
            },
            {
                name: 'Tesouro',
                uf: 'MT',
                code: 5108105,
            },
            {
                name: 'Torixoréu',
                uf: 'MT',
                code: 5108204,
            },
            {
                name: 'União do Sul',
                uf: 'MT',
                code: 5108303,
            },
            {
                name: 'Vale de São Domingos',
                uf: 'MT',
                code: 5108352,
            },
            {
                name: 'Várzea Grande',
                uf: 'MT',
                code: 5108402,
            },
            {
                name: 'Vera',
                uf: 'MT',
                code: 5108501,
            },
            {
                name: 'Vila Bela da Santíssima Trindade',
                uf: 'MT',
                code: 5105507,
            },
            {
                name: 'Vila Rica',
                uf: 'MT',
                code: 5108600,
            },
            {
                name: 'Abaetetuba',
                uf: 'PA',
                code: 1500107,
            },
            {
                name: 'Abel Figueiredo',
                uf: 'PA',
                code: 1500131,
            },
            {
                name: 'Acará',
                uf: 'PA',
                code: 1500206,
            },
            {
                name: 'Afuá',
                uf: 'PA',
                code: 1500305,
            },
            {
                name: 'Água Azul do Norte',
                uf: 'PA',
                code: 1500347,
            },
            {
                name: 'Alenquer',
                uf: 'PA',
                code: 1500404,
            },
            {
                name: 'Almeirim',
                uf: 'PA',
                code: 1500503,
            },
            {
                name: 'Altamira',
                uf: 'PA',
                code: 1500602,
            },
            {
                name: 'Anajás',
                uf: 'PA',
                code: 1500701,
            },
            {
                name: 'Ananindeua',
                uf: 'PA',
                code: 1500800,
            },
            {
                name: 'Anapu',
                uf: 'PA',
                code: 1500859,
            },
            {
                name: 'Augusto Corrêa',
                uf: 'PA',
                code: 1500909,
            },
            {
                name: 'Aurora do Pará',
                uf: 'PA',
                code: 1500958,
            },
            {
                name: 'Aveiro',
                uf: 'PA',
                code: 1501006,
            },
            {
                name: 'Bagre',
                uf: 'PA',
                code: 1501105,
            },
            {
                name: 'Baião',
                uf: 'PA',
                code: 1501204,
            },
            {
                name: 'Bannach',
                uf: 'PA',
                code: 1501253,
            },
            {
                name: 'Barcarena',
                uf: 'PA',
                code: 1501303,
            },
            {
                name: 'Belém',
                uf: 'PA',
                code: 1501402,
            },
            {
                name: 'Belterra',
                uf: 'PA',
                code: 1501451,
            },
            {
                name: 'Benevides',
                uf: 'PA',
                code: 1501501,
            },
            {
                name: 'Bom Jesus do Tocantins',
                uf: 'PA',
                code: 1501576,
            },
            {
                name: 'Bonito',
                uf: 'PA',
                code: 1501600,
            },
            {
                name: 'Bragança',
                uf: 'PA',
                code: 1501709,
            },
            {
                name: 'Brasil Novo',
                uf: 'PA',
                code: 1501725,
            },
            {
                name: 'Brejo Grande do Araguaia',
                uf: 'PA',
                code: 1501758,
            },
            {
                name: 'Breu Branco',
                uf: 'PA',
                code: 1501782,
            },
            {
                name: 'Breves',
                uf: 'PA',
                code: 1501808,
            },
            {
                name: 'Bujaru',
                uf: 'PA',
                code: 1501907,
            },
            {
                name: 'Cachoeira do Arari',
                uf: 'PA',
                code: 1502004,
            },
            {
                name: 'Cachoeira do Piriá',
                uf: 'PA',
                code: 1501956,
            },
            {
                name: 'Cametá',
                uf: 'PA',
                code: 1502103,
            },
            {
                name: 'Canaã dos Carajás',
                uf: 'PA',
                code: 1502152,
            },
            {
                name: 'Capanema',
                uf: 'PA',
                code: 1502202,
            },
            {
                name: 'Capitão Poço',
                uf: 'PA',
                code: 1502301,
            },
            {
                name: 'Castanhal',
                uf: 'PA',
                code: 1502400,
            },
            {
                name: 'Chaves',
                uf: 'PA',
                code: 1502509,
            },
            {
                name: 'Colares',
                uf: 'PA',
                code: 1502608,
            },
            {
                name: 'Conceição do Araguaia',
                uf: 'PA',
                code: 1502707,
            },
            {
                name: 'Concórdia do Pará',
                uf: 'PA',
                code: 1502756,
            },
            {
                name: 'Cumaru do Norte',
                uf: 'PA',
                code: 1502764,
            },
            {
                name: 'Curionópolis',
                uf: 'PA',
                code: 1502772,
            },
            {
                name: 'Curralinho',
                uf: 'PA',
                code: 1502806,
            },
            {
                name: 'Curuá',
                uf: 'PA',
                code: 1502855,
            },
            {
                name: 'Curuçá',
                uf: 'PA',
                code: 1502905,
            },
            {
                name: 'Dom Eliseu',
                uf: 'PA',
                code: 1502939,
            },
            {
                name: 'Eldorado do Carajás',
                uf: 'PA',
                code: 1502954,
            },
            {
                name: 'Faro',
                uf: 'PA',
                code: 1503002,
            },
            {
                name: 'Floresta do Araguaia',
                uf: 'PA',
                code: 1503044,
            },
            {
                name: 'Garrafão do Norte',
                uf: 'PA',
                code: 1503077,
            },
            {
                name: 'Goianésia do Pará',
                uf: 'PA',
                code: 1503093,
            },
            {
                name: 'Gurupá',
                uf: 'PA',
                code: 1503101,
            },
            {
                name: 'Igarapé-Açu',
                uf: 'PA',
                code: 1503200,
            },
            {
                name: 'Igarapé-Miri',
                uf: 'PA',
                code: 1503309,
            },
            {
                name: 'Inhangapi',
                uf: 'PA',
                code: 1503408,
            },
            {
                name: 'Ipixuna do Pará',
                uf: 'PA',
                code: 1503457,
            },
            {
                name: 'Irituia',
                uf: 'PA',
                code: 1503507,
            },
            {
                name: 'Itaituba',
                uf: 'PA',
                code: 1503606,
            },
            {
                name: 'Itupiranga',
                uf: 'PA',
                code: 1503705,
            },
            {
                name: 'Jacareacanga',
                uf: 'PA',
                code: 1503754,
            },
            {
                name: 'Jacundá',
                uf: 'PA',
                code: 1503804,
            },
            {
                name: 'Juruti',
                uf: 'PA',
                code: 1503903,
            },
            {
                name: 'Limoeiro do Ajuru',
                uf: 'PA',
                code: 1504000,
            },
            {
                name: 'Mãe do Rio',
                uf: 'PA',
                code: 1504059,
            },
            {
                name: 'Magalhães Barata',
                uf: 'PA',
                code: 1504109,
            },
            {
                name: 'Marabá',
                uf: 'PA',
                code: 1504208,
            },
            {
                name: 'Maracanã',
                uf: 'PA',
                code: 1504307,
            },
            {
                name: 'Marapanim',
                uf: 'PA',
                code: 1504406,
            },
            {
                name: 'Marituba',
                uf: 'PA',
                code: 1504422,
            },
            {
                name: 'Medicilândia',
                uf: 'PA',
                code: 1504455,
            },
            {
                name: 'Melgaço',
                uf: 'PA',
                code: 1504505,
            },
            {
                name: 'Mocajuba',
                uf: 'PA',
                code: 1504604,
            },
            {
                name: 'Moju',
                uf: 'PA',
                code: 1504703,
            },
            {
                name: 'Mojuí dos Campos',
                uf: 'PA',
                code: 1504752,
            },
            {
                name: 'Monte Alegre',
                uf: 'PA',
                code: 1504802,
            },
            {
                name: 'Muaná',
                uf: 'PA',
                code: 1504901,
            },
            {
                name: 'Nova Esperança do Piriá',
                uf: 'PA',
                code: 1504950,
            },
            {
                name: 'Nova Ipixuna',
                uf: 'PA',
                code: 1504976,
            },
            {
                name: 'Nova Timboteua',
                uf: 'PA',
                code: 1505007,
            },
            {
                name: 'Novo Progresso',
                uf: 'PA',
                code: 1505031,
            },
            {
                name: 'Novo Repartimento',
                uf: 'PA',
                code: 1505064,
            },
            {
                name: 'Óbidos',
                uf: 'PA',
                code: 1505106,
            },
            {
                name: 'Oeiras do Pará',
                uf: 'PA',
                code: 1505205,
            },
            {
                name: 'Oriximiná',
                uf: 'PA',
                code: 1505304,
            },
            {
                name: 'Ourém',
                uf: 'PA',
                code: 1505403,
            },
            {
                name: 'Ourilândia do Norte',
                uf: 'PA',
                code: 1505437,
            },
            {
                name: 'Pacajá',
                uf: 'PA',
                code: 1505486,
            },
            {
                name: 'Palestina do Pará',
                uf: 'PA',
                code: 1505494,
            },
            {
                name: 'Paragominas',
                uf: 'PA',
                code: 1505502,
            },
            {
                name: 'Parauapebas',
                uf: 'PA',
                code: 1505536,
            },
            {
                name: "Pau D'Arco",
                uf: 'PA',
                code: 1505551,
            },
            {
                name: 'Peixe-Boi',
                uf: 'PA',
                code: 1505601,
            },
            {
                name: 'Piçarra',
                uf: 'PA',
                code: 1505635,
            },
            {
                name: 'Placas',
                uf: 'PA',
                code: 1505650,
            },
            {
                name: 'Ponta de Pedras',
                uf: 'PA',
                code: 1505700,
            },
            {
                name: 'Portel',
                uf: 'PA',
                code: 1505809,
            },
            {
                name: 'Porto de Moz',
                uf: 'PA',
                code: 1505908,
            },
            {
                name: 'Prainha',
                uf: 'PA',
                code: 1506005,
            },
            {
                name: 'Primavera',
                uf: 'PA',
                code: 1506104,
            },
            {
                name: 'Quatipuru',
                uf: 'PA',
                code: 1506112,
            },
            {
                name: 'Redenção',
                uf: 'PA',
                code: 1506138,
            },
            {
                name: 'Rio Maria',
                uf: 'PA',
                code: 1506161,
            },
            {
                name: 'Rondon do Pará',
                uf: 'PA',
                code: 1506187,
            },
            {
                name: 'Rurópolis',
                uf: 'PA',
                code: 1506195,
            },
            {
                name: 'Salinópolis',
                uf: 'PA',
                code: 1506203,
            },
            {
                name: 'Salvaterra',
                uf: 'PA',
                code: 1506302,
            },
            {
                name: 'Santa Bárbara do Pará',
                uf: 'PA',
                code: 1506351,
            },
            {
                name: 'Santa Cruz do Arari',
                uf: 'PA',
                code: 1506401,
            },
            {
                name: 'Santa Izabel do Pará',
                uf: 'PA',
                code: 1506500,
            },
            {
                name: 'Santa Luzia do Pará',
                uf: 'PA',
                code: 1506559,
            },
            {
                name: 'Santa Maria das Barreiras',
                uf: 'PA',
                code: 1506583,
            },
            {
                name: 'Santa Maria do Pará',
                uf: 'PA',
                code: 1506609,
            },
            {
                name: 'Santana do Araguaia',
                uf: 'PA',
                code: 1506708,
            },
            {
                name: 'Santarém',
                uf: 'PA',
                code: 1506807,
            },
            {
                name: 'Santarém Novo',
                uf: 'PA',
                code: 1506906,
            },
            {
                name: 'Santo Antônio do Tauá',
                uf: 'PA',
                code: 1507003,
            },
            {
                name: 'São Caetano de Odivelas',
                uf: 'PA',
                code: 1507102,
            },
            {
                name: 'São Domingos do Araguaia',
                uf: 'PA',
                code: 1507151,
            },
            {
                name: 'São Domingos do Capim',
                uf: 'PA',
                code: 1507201,
            },
            {
                name: 'São Félix do Xingu',
                uf: 'PA',
                code: 1507300,
            },
            {
                name: 'São Francisco do Pará',
                uf: 'PA',
                code: 1507409,
            },
            {
                name: 'São Geraldo do Araguaia',
                uf: 'PA',
                code: 1507458,
            },
            {
                name: 'São João da Ponta',
                uf: 'PA',
                code: 1507466,
            },
            {
                name: 'São João de Pirabas',
                uf: 'PA',
                code: 1507474,
            },
            {
                name: 'São João do Araguaia',
                uf: 'PA',
                code: 1507508,
            },
            {
                name: 'São Miguel do Guamá',
                uf: 'PA',
                code: 1507607,
            },
            {
                name: 'São Sebastião da Boa Vista',
                uf: 'PA',
                code: 1507706,
            },
            {
                name: 'Sapucaia',
                uf: 'PA',
                code: 1507755,
            },
            {
                name: 'Senador José Porfírio',
                uf: 'PA',
                code: 1507805,
            },
            {
                name: 'Soure',
                uf: 'PA',
                code: 1507904,
            },
            {
                name: 'Tailândia',
                uf: 'PA',
                code: 1507953,
            },
            {
                name: 'Terra Alta',
                uf: 'PA',
                code: 1507961,
            },
            {
                name: 'Terra Santa',
                uf: 'PA',
                code: 1507979,
            },
            {
                name: 'Tomé-Açu',
                uf: 'PA',
                code: 1508001,
            },
            {
                name: 'Tracuateua',
                uf: 'PA',
                code: 1508035,
            },
            {
                name: 'Trairão',
                uf: 'PA',
                code: 1508050,
            },
            {
                name: 'Tucumã',
                uf: 'PA',
                code: 1508084,
            },
            {
                name: 'Tucuruí',
                uf: 'PA',
                code: 1508100,
            },
            {
                name: 'Ulianópolis',
                uf: 'PA',
                code: 1508126,
            },
            {
                name: 'Uruará',
                uf: 'PA',
                code: 1508159,
            },
            {
                name: 'Vigia',
                uf: 'PA',
                code: 1508209,
            },
            {
                name: 'Viseu',
                uf: 'PA',
                code: 1508308,
            },
            {
                name: 'Vitória do Xingu',
                uf: 'PA',
                code: 1508357,
            },
            {
                name: 'Xinguara',
                uf: 'PA',
                code: 1508407,
            },
            {
                name: 'Água Branca',
                uf: 'PB',
                code: 2500106,
            },
            {
                name: 'Aguiar',
                uf: 'PB',
                code: 2500205,
            },
            {
                name: 'Alagoa Grande',
                uf: 'PB',
                code: 2500304,
            },
            {
                name: 'Alagoa Nova',
                uf: 'PB',
                code: 2500403,
            },
            {
                name: 'Alagoinha',
                uf: 'PB',
                code: 2500502,
            },
            {
                name: 'Alcantil',
                uf: 'PB',
                code: 2500536,
            },
            {
                name: 'Algodão de Jandaíra',
                uf: 'PB',
                code: 2500577,
            },
            {
                name: 'Alhandra',
                uf: 'PB',
                code: 2500601,
            },
            {
                name: 'Amparo',
                uf: 'PB',
                code: 2500734,
            },
            {
                name: 'Aparecida',
                uf: 'PB',
                code: 2500775,
            },
            {
                name: 'Araçagi',
                uf: 'PB',
                code: 2500809,
            },
            {
                name: 'Arara',
                uf: 'PB',
                code: 2500908,
            },
            {
                name: 'Araruna',
                uf: 'PB',
                code: 2501005,
            },
            {
                name: 'Areia',
                uf: 'PB',
                code: 2501104,
            },
            {
                name: 'Areia de Baraúnas',
                uf: 'PB',
                code: 2501153,
            },
            {
                name: 'Areial',
                uf: 'PB',
                code: 2501203,
            },
            {
                name: 'Aroeiras',
                uf: 'PB',
                code: 2501302,
            },
            {
                name: 'Assunção',
                uf: 'PB',
                code: 2501351,
            },
            {
                name: 'Baía da Traição',
                uf: 'PB',
                code: 2501401,
            },
            {
                name: 'Bananeiras',
                uf: 'PB',
                code: 2501500,
            },
            {
                name: 'Baraúna',
                uf: 'PB',
                code: 2501534,
            },
            {
                name: 'Barra de Santa Rosa',
                uf: 'PB',
                code: 2501609,
            },
            {
                name: 'Barra de Santana',
                uf: 'PB',
                code: 2501575,
            },
            {
                name: 'Barra de São Miguel',
                uf: 'PB',
                code: 2501708,
            },
            {
                name: 'Bayeux',
                uf: 'PB',
                code: 2501807,
            },
            {
                name: 'Belém',
                uf: 'PB',
                code: 2501906,
            },
            {
                name: 'Belém do Brejo do Cruz',
                uf: 'PB',
                code: 2502003,
            },
            {
                name: 'Bernardino Batista',
                uf: 'PB',
                code: 2502052,
            },
            {
                name: 'Boa Ventura',
                uf: 'PB',
                code: 2502102,
            },
            {
                name: 'Boa Vista',
                uf: 'PB',
                code: 2502151,
            },
            {
                name: 'Bom Jesus',
                uf: 'PB',
                code: 2502201,
            },
            {
                name: 'Bom Sucesso',
                uf: 'PB',
                code: 2502300,
            },
            {
                name: 'Bonito de Santa Fé',
                uf: 'PB',
                code: 2502409,
            },
            {
                name: 'Boqueirão',
                uf: 'PB',
                code: 2502508,
            },
            {
                name: 'Borborema',
                uf: 'PB',
                code: 2502706,
            },
            {
                name: 'Brejo do Cruz',
                uf: 'PB',
                code: 2502805,
            },
            {
                name: 'Brejo dos Santos',
                uf: 'PB',
                code: 2502904,
            },
            {
                name: 'Caaporã',
                uf: 'PB',
                code: 2503001,
            },
            {
                name: 'Cabaceiras',
                uf: 'PB',
                code: 2503100,
            },
            {
                name: 'Cabedelo',
                uf: 'PB',
                code: 2503209,
            },
            {
                name: 'Cachoeira dos Índios',
                uf: 'PB',
                code: 2503308,
            },
            {
                name: 'Cacimba de Areia',
                uf: 'PB',
                code: 2503407,
            },
            {
                name: 'Cacimba de Dentro',
                uf: 'PB',
                code: 2503506,
            },
            {
                name: 'Cacimbas',
                uf: 'PB',
                code: 2503555,
            },
            {
                name: 'Caiçara',
                uf: 'PB',
                code: 2503605,
            },
            {
                name: 'Cajazeiras',
                uf: 'PB',
                code: 2503704,
            },
            {
                name: 'Cajazeirinhas',
                uf: 'PB',
                code: 2503753,
            },
            {
                name: 'Caldas Brandão',
                uf: 'PB',
                code: 2503803,
            },
            {
                name: 'Camalaú',
                uf: 'PB',
                code: 2503902,
            },
            {
                name: 'Campina Grande',
                uf: 'PB',
                code: 2504009,
            },
            {
                name: 'Capim',
                uf: 'PB',
                code: 2504033,
            },
            {
                name: 'Caraúbas',
                uf: 'PB',
                code: 2504074,
            },
            {
                name: 'Carrapateira',
                uf: 'PB',
                code: 2504108,
            },
            {
                name: 'Casserengue',
                uf: 'PB',
                code: 2504157,
            },
            {
                name: 'Catingueira',
                uf: 'PB',
                code: 2504207,
            },
            {
                name: 'Catolé do Rocha',
                uf: 'PB',
                code: 2504306,
            },
            {
                name: 'Caturité',
                uf: 'PB',
                code: 2504355,
            },
            {
                name: 'Conceição',
                uf: 'PB',
                code: 2504405,
            },
            {
                name: 'Condado',
                uf: 'PB',
                code: 2504504,
            },
            {
                name: 'Conde',
                uf: 'PB',
                code: 2504603,
            },
            {
                name: 'Congo',
                uf: 'PB',
                code: 2504702,
            },
            {
                name: 'Coremas',
                uf: 'PB',
                code: 2504801,
            },
            {
                name: 'Coxixola',
                uf: 'PB',
                code: 2504850,
            },
            {
                name: 'Cruz do Espírito Santo',
                uf: 'PB',
                code: 2504900,
            },
            {
                name: 'Cubati',
                uf: 'PB',
                code: 2505006,
            },
            {
                name: 'Cuité',
                uf: 'PB',
                code: 2505105,
            },
            {
                name: 'Cuité de Mamanguape',
                uf: 'PB',
                code: 2505238,
            },
            {
                name: 'Cuitegi',
                uf: 'PB',
                code: 2505204,
            },
            {
                name: 'Curral de Cima',
                uf: 'PB',
                code: 2505279,
            },
            {
                name: 'Curral Velho',
                uf: 'PB',
                code: 2505303,
            },
            {
                name: 'Damião',
                uf: 'PB',
                code: 2505352,
            },
            {
                name: 'Desterro',
                uf: 'PB',
                code: 2505402,
            },
            {
                name: 'Diamante',
                uf: 'PB',
                code: 2505600,
            },
            {
                name: 'Dona Inês',
                uf: 'PB',
                code: 2505709,
            },
            {
                name: 'Duas Estradas',
                uf: 'PB',
                code: 2505808,
            },
            {
                name: 'Emas',
                uf: 'PB',
                code: 2505907,
            },
            {
                name: 'Esperança',
                uf: 'PB',
                code: 2506004,
            },
            {
                name: 'Fagundes',
                uf: 'PB',
                code: 2506103,
            },
            {
                name: 'Frei Martinho',
                uf: 'PB',
                code: 2506202,
            },
            {
                name: 'Gado Bravo',
                uf: 'PB',
                code: 2506251,
            },
            {
                name: 'Guarabira',
                uf: 'PB',
                code: 2506301,
            },
            {
                name: 'Gurinhém',
                uf: 'PB',
                code: 2506400,
            },
            {
                name: 'Gurjão',
                uf: 'PB',
                code: 2506509,
            },
            {
                name: 'Ibiara',
                uf: 'PB',
                code: 2506608,
            },
            {
                name: 'Igaracy',
                uf: 'PB',
                code: 2502607,
            },
            {
                name: 'Imaculada',
                uf: 'PB',
                code: 2506707,
            },
            {
                name: 'Ingá',
                uf: 'PB',
                code: 2506806,
            },
            {
                name: 'Itabaiana',
                uf: 'PB',
                code: 2506905,
            },
            {
                name: 'Itaporanga',
                uf: 'PB',
                code: 2507002,
            },
            {
                name: 'Itapororoca',
                uf: 'PB',
                code: 2507101,
            },
            {
                name: 'Itatuba',
                uf: 'PB',
                code: 2507200,
            },
            {
                name: 'Jacaraú',
                uf: 'PB',
                code: 2507309,
            },
            {
                name: 'Jericó',
                uf: 'PB',
                code: 2507408,
            },
            {
                name: 'João Pessoa',
                uf: 'PB',
                code: 2507507,
            },
            {
                name: 'Joca Claudino',
                uf: 'PB',
                code: 2513653,
            },
            {
                name: 'Juarez Távora',
                uf: 'PB',
                code: 2507606,
            },
            {
                name: 'Juazeirinho',
                uf: 'PB',
                code: 2507705,
            },
            {
                name: 'Junco do Seridó',
                uf: 'PB',
                code: 2507804,
            },
            {
                name: 'Juripiranga',
                uf: 'PB',
                code: 2507903,
            },
            {
                name: 'Juru',
                uf: 'PB',
                code: 2508000,
            },
            {
                name: 'Lagoa',
                uf: 'PB',
                code: 2508109,
            },
            {
                name: 'Lagoa de Dentro',
                uf: 'PB',
                code: 2508208,
            },
            {
                name: 'Lagoa Seca',
                uf: 'PB',
                code: 2508307,
            },
            {
                name: 'Lastro',
                uf: 'PB',
                code: 2508406,
            },
            {
                name: 'Livramento',
                uf: 'PB',
                code: 2508505,
            },
            {
                name: 'Logradouro',
                uf: 'PB',
                code: 2508554,
            },
            {
                name: 'Lucena',
                uf: 'PB',
                code: 2508604,
            },
            {
                name: "Mãe d'Água",
                uf: 'PB',
                code: 2508703,
            },
            {
                name: 'Malta',
                uf: 'PB',
                code: 2508802,
            },
            {
                name: 'Mamanguape',
                uf: 'PB',
                code: 2508901,
            },
            {
                name: 'Manaíra',
                uf: 'PB',
                code: 2509008,
            },
            {
                name: 'Marcação',
                uf: 'PB',
                code: 2509057,
            },
            {
                name: 'Mari',
                uf: 'PB',
                code: 2509107,
            },
            {
                name: 'Marizópolis',
                uf: 'PB',
                code: 2509156,
            },
            {
                name: 'Massaranduba',
                uf: 'PB',
                code: 2509206,
            },
            {
                name: 'Mataraca',
                uf: 'PB',
                code: 2509305,
            },
            {
                name: 'Matinhas',
                uf: 'PB',
                code: 2509339,
            },
            {
                name: 'Mato Grosso',
                uf: 'PB',
                code: 2509370,
            },
            {
                name: 'Maturéia',
                uf: 'PB',
                code: 2509396,
            },
            {
                name: 'Mogeiro',
                uf: 'PB',
                code: 2509404,
            },
            {
                name: 'Montadas',
                uf: 'PB',
                code: 2509503,
            },
            {
                name: 'Monte Horebe',
                uf: 'PB',
                code: 2509602,
            },
            {
                name: 'Monteiro',
                uf: 'PB',
                code: 2509701,
            },
            {
                name: 'Mulungu',
                uf: 'PB',
                code: 2509800,
            },
            {
                name: 'Natuba',
                uf: 'PB',
                code: 2509909,
            },
            {
                name: 'Nazarezinho',
                uf: 'PB',
                code: 2510006,
            },
            {
                name: 'Nova Floresta',
                uf: 'PB',
                code: 2510105,
            },
            {
                name: 'Nova Olinda',
                uf: 'PB',
                code: 2510204,
            },
            {
                name: 'Nova Palmeira',
                uf: 'PB',
                code: 2510303,
            },
            {
                name: "Olho d'Água",
                uf: 'PB',
                code: 2510402,
            },
            {
                name: 'Olivedos',
                uf: 'PB',
                code: 2510501,
            },
            {
                name: 'Ouro Velho',
                uf: 'PB',
                code: 2510600,
            },
            {
                name: 'Parari',
                uf: 'PB',
                code: 2510659,
            },
            {
                name: 'Passagem',
                uf: 'PB',
                code: 2510709,
            },
            {
                name: 'Patos',
                uf: 'PB',
                code: 2510808,
            },
            {
                name: 'Paulista',
                uf: 'PB',
                code: 2510907,
            },
            {
                name: 'Pedra Branca',
                uf: 'PB',
                code: 2511004,
            },
            {
                name: 'Pedra Lavrada',
                uf: 'PB',
                code: 2511103,
            },
            {
                name: 'Pedras de Fogo',
                uf: 'PB',
                code: 2511202,
            },
            {
                name: 'Pedro Régis',
                uf: 'PB',
                code: 2512721,
            },
            {
                name: 'Piancó',
                uf: 'PB',
                code: 2511301,
            },
            {
                name: 'Picuí',
                uf: 'PB',
                code: 2511400,
            },
            {
                name: 'Pilar',
                uf: 'PB',
                code: 2511509,
            },
            {
                name: 'Pilões',
                uf: 'PB',
                code: 2511608,
            },
            {
                name: 'Pilõezinhos',
                uf: 'PB',
                code: 2511707,
            },
            {
                name: 'Pirpirituba',
                uf: 'PB',
                code: 2511806,
            },
            {
                name: 'Pitimbu',
                uf: 'PB',
                code: 2511905,
            },
            {
                name: 'Pocinhos',
                uf: 'PB',
                code: 2512002,
            },
            {
                name: 'Poço Dantas',
                uf: 'PB',
                code: 2512036,
            },
            {
                name: 'Poço de José de Moura',
                uf: 'PB',
                code: 2512077,
            },
            {
                name: 'Pombal',
                uf: 'PB',
                code: 2512101,
            },
            {
                name: 'Prata',
                uf: 'PB',
                code: 2512200,
            },
            {
                name: 'Princesa Isabel',
                uf: 'PB',
                code: 2512309,
            },
            {
                name: 'Puxinanã',
                uf: 'PB',
                code: 2512408,
            },
            {
                name: 'Queimadas',
                uf: 'PB',
                code: 2512507,
            },
            {
                name: 'Quixabá',
                uf: 'PB',
                code: 2512606,
            },
            {
                name: 'Remígio',
                uf: 'PB',
                code: 2512705,
            },
            {
                name: 'Riachão',
                uf: 'PB',
                code: 2512747,
            },
            {
                name: 'Riachão do Bacamarte',
                uf: 'PB',
                code: 2512754,
            },
            {
                name: 'Riachão do Poço',
                uf: 'PB',
                code: 2512762,
            },
            {
                name: 'Riacho de Santo Antônio',
                uf: 'PB',
                code: 2512788,
            },
            {
                name: 'Riacho dos Cavalos',
                uf: 'PB',
                code: 2512804,
            },
            {
                name: 'Rio Tinto',
                uf: 'PB',
                code: 2512903,
            },
            {
                name: 'Salgadinho',
                uf: 'PB',
                code: 2513000,
            },
            {
                name: 'Salgado de São Félix',
                uf: 'PB',
                code: 2513109,
            },
            {
                name: 'Santa Cecília',
                uf: 'PB',
                code: 2513158,
            },
            {
                name: 'Santa Cruz',
                uf: 'PB',
                code: 2513208,
            },
            {
                name: 'Santa Helena',
                uf: 'PB',
                code: 2513307,
            },
            {
                name: 'Santa Inês',
                uf: 'PB',
                code: 2513356,
            },
            {
                name: 'Santa Luzia',
                uf: 'PB',
                code: 2513406,
            },
            {
                name: 'Santa Rita',
                uf: 'PB',
                code: 2513703,
            },
            {
                name: 'Santa Teresinha',
                uf: 'PB',
                code: 2513802,
            },
            {
                name: 'Santana de Mangueira',
                uf: 'PB',
                code: 2513505,
            },
            {
                name: 'Santana dos Garrotes',
                uf: 'PB',
                code: 2513604,
            },
            {
                name: 'Santo André',
                uf: 'PB',
                code: 2513851,
            },
            {
                name: 'São Bentinho',
                uf: 'PB',
                code: 2513927,
            },
            {
                name: 'São Bento',
                uf: 'PB',
                code: 2513901,
            },
            {
                name: 'São Domingos',
                uf: 'PB',
                code: 2513968,
            },
            {
                name: 'São Domingos do Cariri',
                uf: 'PB',
                code: 2513943,
            },
            {
                name: 'São Francisco',
                uf: 'PB',
                code: 2513984,
            },
            {
                name: 'São João do Cariri',
                uf: 'PB',
                code: 2514008,
            },
            {
                name: 'São João do Rio do Peixe',
                uf: 'PB',
                code: 2500700,
            },
            {
                name: 'São João do Tigre',
                uf: 'PB',
                code: 2514107,
            },
            {
                name: 'São José da Lagoa Tapada',
                uf: 'PB',
                code: 2514206,
            },
            {
                name: 'São José de Caiana',
                uf: 'PB',
                code: 2514305,
            },
            {
                name: 'São José de Espinharas',
                uf: 'PB',
                code: 2514404,
            },
            {
                name: 'São José de Piranhas',
                uf: 'PB',
                code: 2514503,
            },
            {
                name: 'São José de Princesa',
                uf: 'PB',
                code: 2514552,
            },
            {
                name: 'São José do Bonfim',
                uf: 'PB',
                code: 2514602,
            },
            {
                name: 'São José do Brejo do Cruz',
                uf: 'PB',
                code: 2514651,
            },
            {
                name: 'São José do Sabugi',
                uf: 'PB',
                code: 2514701,
            },
            {
                name: 'São José dos Cordeiros',
                uf: 'PB',
                code: 2514800,
            },
            {
                name: 'São José dos Ramos',
                uf: 'PB',
                code: 2514453,
            },
            {
                name: 'São Mamede',
                uf: 'PB',
                code: 2514909,
            },
            {
                name: 'São Miguel de Taipu',
                uf: 'PB',
                code: 2515005,
            },
            {
                name: 'São Sebastião de Lagoa de Roça',
                uf: 'PB',
                code: 2515104,
            },
            {
                name: 'São Sebastião do Umbuzeiro',
                uf: 'PB',
                code: 2515203,
            },
            {
                name: 'São Vicente do Seridó',
                uf: 'PB',
                code: 2515401,
            },
            {
                name: 'Sapé',
                uf: 'PB',
                code: 2515302,
            },
            {
                name: 'Serra Branca',
                uf: 'PB',
                code: 2515500,
            },
            {
                name: 'Serra da Raiz',
                uf: 'PB',
                code: 2515609,
            },
            {
                name: 'Serra Grande',
                uf: 'PB',
                code: 2515708,
            },
            {
                name: 'Serra Redonda',
                uf: 'PB',
                code: 2515807,
            },
            {
                name: 'Serraria',
                uf: 'PB',
                code: 2515906,
            },
            {
                name: 'Sertãozinho',
                uf: 'PB',
                code: 2515930,
            },
            {
                name: 'Sobrado',
                uf: 'PB',
                code: 2515971,
            },
            {
                name: 'Solânea',
                uf: 'PB',
                code: 2516003,
            },
            {
                name: 'Soledade',
                uf: 'PB',
                code: 2516102,
            },
            {
                name: 'Sossêgo',
                uf: 'PB',
                code: 2516151,
            },
            {
                name: 'Sousa',
                uf: 'PB',
                code: 2516201,
            },
            {
                name: 'Sumé',
                uf: 'PB',
                code: 2516300,
            },
            {
                name: 'Tacima',
                uf: 'PB',
                code: 2516409,
            },
            {
                name: 'Taperoá',
                uf: 'PB',
                code: 2516508,
            },
            {
                name: 'Tavares',
                uf: 'PB',
                code: 2516607,
            },
            {
                name: 'Teixeira',
                uf: 'PB',
                code: 2516706,
            },
            {
                name: 'Tenório',
                uf: 'PB',
                code: 2516755,
            },
            {
                name: 'Triunfo',
                uf: 'PB',
                code: 2516805,
            },
            {
                name: 'Uiraúna',
                uf: 'PB',
                code: 2516904,
            },
            {
                name: 'Umbuzeiro',
                uf: 'PB',
                code: 2517001,
            },
            {
                name: 'Várzea',
                uf: 'PB',
                code: 2517100,
            },
            {
                name: 'Vieirópolis',
                uf: 'PB',
                code: 2517209,
            },
            {
                name: 'Vista Serrana',
                uf: 'PB',
                code: 2505501,
            },
            {
                name: 'Zabelê',
                uf: 'PB',
                code: 2517407,
            },
            {
                name: 'Abreu e Lima',
                uf: 'PE',
                code: 2600054,
            },
            {
                name: 'Afogados da Ingazeira',
                uf: 'PE',
                code: 2600104,
            },
            {
                name: 'Afrânio',
                uf: 'PE',
                code: 2600203,
            },
            {
                name: 'Agrestina',
                uf: 'PE',
                code: 2600302,
            },
            {
                name: 'Água Preta',
                uf: 'PE',
                code: 2600401,
            },
            {
                name: 'Águas Belas',
                uf: 'PE',
                code: 2600500,
            },
            {
                name: 'Alagoinha',
                uf: 'PE',
                code: 2600609,
            },
            {
                name: 'Aliança',
                uf: 'PE',
                code: 2600708,
            },
            {
                name: 'Altinho',
                uf: 'PE',
                code: 2600807,
            },
            {
                name: 'Amaraji',
                uf: 'PE',
                code: 2600906,
            },
            {
                name: 'Angelim',
                uf: 'PE',
                code: 2601003,
            },
            {
                name: 'Araçoiaba',
                uf: 'PE',
                code: 2601052,
            },
            {
                name: 'Araripina',
                uf: 'PE',
                code: 2601102,
            },
            {
                name: 'Arcoverde',
                uf: 'PE',
                code: 2601201,
            },
            {
                name: 'Barra de Guabiraba',
                uf: 'PE',
                code: 2601300,
            },
            {
                name: 'Barreiros',
                uf: 'PE',
                code: 2601409,
            },
            {
                name: 'Belém de Maria',
                uf: 'PE',
                code: 2601508,
            },
            {
                name: 'Belém do São Francisco',
                uf: 'PE',
                code: 2601607,
            },
            {
                name: 'Belo Jardim',
                uf: 'PE',
                code: 2601706,
            },
            {
                name: 'Betânia',
                uf: 'PE',
                code: 2601805,
            },
            {
                name: 'Bezerros',
                uf: 'PE',
                code: 2601904,
            },
            {
                name: 'Bodocó',
                uf: 'PE',
                code: 2602001,
            },
            {
                name: 'Bom Conselho',
                uf: 'PE',
                code: 2602100,
            },
            {
                name: 'Bom Jardim',
                uf: 'PE',
                code: 2602209,
            },
            {
                name: 'Bonito',
                uf: 'PE',
                code: 2602308,
            },
            {
                name: 'Brejão',
                uf: 'PE',
                code: 2602407,
            },
            {
                name: 'Brejinho',
                uf: 'PE',
                code: 2602506,
            },
            {
                name: 'Brejo da Madre de Deus',
                uf: 'PE',
                code: 2602605,
            },
            {
                name: 'Buenos Aires',
                uf: 'PE',
                code: 2602704,
            },
            {
                name: 'Buíque',
                uf: 'PE',
                code: 2602803,
            },
            {
                name: 'Cabo de Santo Agostinho',
                uf: 'PE',
                code: 2602902,
            },
            {
                name: 'Cabrobó',
                uf: 'PE',
                code: 2603009,
            },
            {
                name: 'Cachoeirinha',
                uf: 'PE',
                code: 2603108,
            },
            {
                name: 'Caetés',
                uf: 'PE',
                code: 2603207,
            },
            {
                name: 'Calçado',
                uf: 'PE',
                code: 2603306,
            },
            {
                name: 'Calumbi',
                uf: 'PE',
                code: 2603405,
            },
            {
                name: 'Camaragibe',
                uf: 'PE',
                code: 2603454,
            },
            {
                name: 'Camocim de São Félix',
                uf: 'PE',
                code: 2603504,
            },
            {
                name: 'Camutanga',
                uf: 'PE',
                code: 2603603,
            },
            {
                name: 'Canhotinho',
                uf: 'PE',
                code: 2603702,
            },
            {
                name: 'Capoeiras',
                uf: 'PE',
                code: 2603801,
            },
            {
                name: 'Carnaíba',
                uf: 'PE',
                code: 2603900,
            },
            {
                name: 'Carnaubeira da Penha',
                uf: 'PE',
                code: 2603926,
            },
            {
                name: 'Carpina',
                uf: 'PE',
                code: 2604007,
            },
            {
                name: 'Caruaru',
                uf: 'PE',
                code: 2604106,
            },
            {
                name: 'Casinhas',
                uf: 'PE',
                code: 2604155,
            },
            {
                name: 'Catende',
                uf: 'PE',
                code: 2604205,
            },
            {
                name: 'Cedro',
                uf: 'PE',
                code: 2604304,
            },
            {
                name: 'Chã de Alegria',
                uf: 'PE',
                code: 2604403,
            },
            {
                name: 'Chã Grande',
                uf: 'PE',
                code: 2604502,
            },
            {
                name: 'Condado',
                uf: 'PE',
                code: 2604601,
            },
            {
                name: 'Correntes',
                uf: 'PE',
                code: 2604700,
            },
            {
                name: 'Cortês',
                uf: 'PE',
                code: 2604809,
            },
            {
                name: 'Cumaru',
                uf: 'PE',
                code: 2604908,
            },
            {
                name: 'Cupira',
                uf: 'PE',
                code: 2605004,
            },
            {
                name: 'Custódia',
                uf: 'PE',
                code: 2605103,
            },
            {
                name: 'Dormentes',
                uf: 'PE',
                code: 2605152,
            },
            {
                name: 'Escada',
                uf: 'PE',
                code: 2605202,
            },
            {
                name: 'Exu',
                uf: 'PE',
                code: 2605301,
            },
            {
                name: 'Feira Nova',
                uf: 'PE',
                code: 2605400,
            },
            {
                name: 'Fernando de Noronha',
                uf: 'PE',
                code: 2605459,
            },
            {
                name: 'Ferreiros',
                uf: 'PE',
                code: 2605509,
            },
            {
                name: 'Flores',
                uf: 'PE',
                code: 2605608,
            },
            {
                name: 'Floresta',
                uf: 'PE',
                code: 2605707,
            },
            {
                name: 'Frei Miguelinho',
                uf: 'PE',
                code: 2605806,
            },
            {
                name: 'Gameleira',
                uf: 'PE',
                code: 2605905,
            },
            {
                name: 'Garanhuns',
                uf: 'PE',
                code: 2606002,
            },
            {
                name: 'Glória do Goitá',
                uf: 'PE',
                code: 2606101,
            },
            {
                name: 'Goiana',
                uf: 'PE',
                code: 2606200,
            },
            {
                name: 'Granito',
                uf: 'PE',
                code: 2606309,
            },
            {
                name: 'Gravatá',
                uf: 'PE',
                code: 2606408,
            },
            {
                name: 'Iati',
                uf: 'PE',
                code: 2606507,
            },
            {
                name: 'Ibimirim',
                uf: 'PE',
                code: 2606606,
            },
            {
                name: 'Ibirajuba',
                uf: 'PE',
                code: 2606705,
            },
            {
                name: 'Igarassu',
                uf: 'PE',
                code: 2606804,
            },
            {
                name: 'Iguaracy',
                uf: 'PE',
                code: 2606903,
            },
            {
                name: 'Ilha de Itamaracá',
                uf: 'PE',
                code: 2607604,
            },
            {
                name: 'Inajá',
                uf: 'PE',
                code: 2607000,
            },
            {
                name: 'Ingazeira',
                uf: 'PE',
                code: 2607109,
            },
            {
                name: 'Ipojuca',
                uf: 'PE',
                code: 2607208,
            },
            {
                name: 'Ipubi',
                uf: 'PE',
                code: 2607307,
            },
            {
                name: 'Itacuruba',
                uf: 'PE',
                code: 2607406,
            },
            {
                name: 'Itaíba',
                uf: 'PE',
                code: 2607505,
            },
            {
                name: 'Itambé',
                uf: 'PE',
                code: 2607653,
            },
            {
                name: 'Itapetim',
                uf: 'PE',
                code: 2607703,
            },
            {
                name: 'Itapissuma',
                uf: 'PE',
                code: 2607752,
            },
            {
                name: 'Itaquitinga',
                uf: 'PE',
                code: 2607802,
            },
            {
                name: 'Jaboatão dos Guararapes',
                uf: 'PE',
                code: 2607901,
            },
            {
                name: 'Jaqueira',
                uf: 'PE',
                code: 2607950,
            },
            {
                name: 'Jataúba',
                uf: 'PE',
                code: 2608008,
            },
            {
                name: 'Jatobá',
                uf: 'PE',
                code: 2608057,
            },
            {
                name: 'João Alfredo',
                uf: 'PE',
                code: 2608107,
            },
            {
                name: 'Joaquim Nabuco',
                uf: 'PE',
                code: 2608206,
            },
            {
                name: 'Jucati',
                uf: 'PE',
                code: 2608255,
            },
            {
                name: 'Jupi',
                uf: 'PE',
                code: 2608305,
            },
            {
                name: 'Jurema',
                uf: 'PE',
                code: 2608404,
            },
            {
                name: 'Lagoa de Itaenga',
                uf: 'PE',
                code: 2608503,
            },
            {
                name: 'Lagoa do Carro',
                uf: 'PE',
                code: 2608453,
            },
            {
                name: 'Lagoa do Ouro',
                uf: 'PE',
                code: 2608602,
            },
            {
                name: 'Lagoa dos Gatos',
                uf: 'PE',
                code: 2608701,
            },
            {
                name: 'Lagoa Grande',
                uf: 'PE',
                code: 2608750,
            },
            {
                name: 'Lajedo',
                uf: 'PE',
                code: 2608800,
            },
            {
                name: 'Limoeiro',
                uf: 'PE',
                code: 2608909,
            },
            {
                name: 'Macaparana',
                uf: 'PE',
                code: 2609006,
            },
            {
                name: 'Machados',
                uf: 'PE',
                code: 2609105,
            },
            {
                name: 'Manari',
                uf: 'PE',
                code: 2609154,
            },
            {
                name: 'Maraial',
                uf: 'PE',
                code: 2609204,
            },
            {
                name: 'Mirandiba',
                uf: 'PE',
                code: 2609303,
            },
            {
                name: 'Moreilândia',
                uf: 'PE',
                code: 2614303,
            },
            {
                name: 'Moreno',
                uf: 'PE',
                code: 2609402,
            },
            {
                name: 'Nazaré da Mata',
                uf: 'PE',
                code: 2609501,
            },
            {
                name: 'Olinda',
                uf: 'PE',
                code: 2609600,
            },
            {
                name: 'Orobó',
                uf: 'PE',
                code: 2609709,
            },
            {
                name: 'Orocó',
                uf: 'PE',
                code: 2609808,
            },
            {
                name: 'Ouricuri',
                uf: 'PE',
                code: 2609907,
            },
            {
                name: 'Palmares',
                uf: 'PE',
                code: 2610004,
            },
            {
                name: 'Palmeirina',
                uf: 'PE',
                code: 2610103,
            },
            {
                name: 'Panelas',
                uf: 'PE',
                code: 2610202,
            },
            {
                name: 'Paranatama',
                uf: 'PE',
                code: 2610301,
            },
            {
                name: 'Parnamirim',
                uf: 'PE',
                code: 2610400,
            },
            {
                name: 'Passira',
                uf: 'PE',
                code: 2610509,
            },
            {
                name: 'Paudalho',
                uf: 'PE',
                code: 2610608,
            },
            {
                name: 'Paulista',
                uf: 'PE',
                code: 2610707,
            },
            {
                name: 'Pedra',
                uf: 'PE',
                code: 2610806,
            },
            {
                name: 'Pesqueira',
                uf: 'PE',
                code: 2610905,
            },
            {
                name: 'Petrolândia',
                uf: 'PE',
                code: 2611002,
            },
            {
                name: 'Petrolina',
                uf: 'PE',
                code: 2611101,
            },
            {
                name: 'Poção',
                uf: 'PE',
                code: 2611200,
            },
            {
                name: 'Pombos',
                uf: 'PE',
                code: 2611309,
            },
            {
                name: 'Primavera',
                uf: 'PE',
                code: 2611408,
            },
            {
                name: 'Quipapá',
                uf: 'PE',
                code: 2611507,
            },
            {
                name: 'Quixaba',
                uf: 'PE',
                code: 2611533,
            },
            {
                name: 'Recife',
                uf: 'PE',
                code: 2611606,
            },
            {
                name: 'Riacho das Almas',
                uf: 'PE',
                code: 2611705,
            },
            {
                name: 'Ribeirão',
                uf: 'PE',
                code: 2611804,
            },
            {
                name: 'Rio Formoso',
                uf: 'PE',
                code: 2611903,
            },
            {
                name: 'Sairé',
                uf: 'PE',
                code: 2612000,
            },
            {
                name: 'Salgadinho',
                uf: 'PE',
                code: 2612109,
            },
            {
                name: 'Salgueiro',
                uf: 'PE',
                code: 2612208,
            },
            {
                name: 'Saloá',
                uf: 'PE',
                code: 2612307,
            },
            {
                name: 'Sanharó',
                uf: 'PE',
                code: 2612406,
            },
            {
                name: 'Santa Cruz',
                uf: 'PE',
                code: 2612455,
            },
            {
                name: 'Santa Cruz da Baixa Verde',
                uf: 'PE',
                code: 2612471,
            },
            {
                name: 'Santa Cruz do Capibaribe',
                uf: 'PE',
                code: 2612505,
            },
            {
                name: 'Santa Filomena',
                uf: 'PE',
                code: 2612554,
            },
            {
                name: 'Santa Maria da Boa Vista',
                uf: 'PE',
                code: 2612604,
            },
            {
                name: 'Santa Maria do Cambucá',
                uf: 'PE',
                code: 2612703,
            },
            {
                name: 'Santa Terezinha',
                uf: 'PE',
                code: 2612802,
            },
            {
                name: 'São Benedito do Sul',
                uf: 'PE',
                code: 2612901,
            },
            {
                name: 'São Bento do Una',
                uf: 'PE',
                code: 2613008,
            },
            {
                name: 'São Caitano',
                uf: 'PE',
                code: 2613107,
            },
            {
                name: 'São João',
                uf: 'PE',
                code: 2613206,
            },
            {
                name: 'São Joaquim do Monte',
                uf: 'PE',
                code: 2613305,
            },
            {
                name: 'São José da Coroa Grande',
                uf: 'PE',
                code: 2613404,
            },
            {
                name: 'São José do Belmonte',
                uf: 'PE',
                code: 2613503,
            },
            {
                name: 'São José do Egito',
                uf: 'PE',
                code: 2613602,
            },
            {
                name: 'São Lourenço da Mata',
                uf: 'PE',
                code: 2613701,
            },
            {
                name: 'São Vicente Ferrer',
                uf: 'PE',
                code: 2613800,
            },
            {
                name: 'Serra Talhada',
                uf: 'PE',
                code: 2613909,
            },
            {
                name: 'Serrita',
                uf: 'PE',
                code: 2614006,
            },
            {
                name: 'Sertânia',
                uf: 'PE',
                code: 2614105,
            },
            {
                name: 'Sirinhaém',
                uf: 'PE',
                code: 2614204,
            },
            {
                name: 'Solidão',
                uf: 'PE',
                code: 2614402,
            },
            {
                name: 'Surubim',
                uf: 'PE',
                code: 2614501,
            },
            {
                name: 'Tabira',
                uf: 'PE',
                code: 2614600,
            },
            {
                name: 'Tacaimbó',
                uf: 'PE',
                code: 2614709,
            },
            {
                name: 'Tacaratu',
                uf: 'PE',
                code: 2614808,
            },
            {
                name: 'Tamandaré',
                uf: 'PE',
                code: 2614857,
            },
            {
                name: 'Taquaritinga do Norte',
                uf: 'PE',
                code: 2615003,
            },
            {
                name: 'Terezinha',
                uf: 'PE',
                code: 2615102,
            },
            {
                name: 'Terra Nova',
                uf: 'PE',
                code: 2615201,
            },
            {
                name: 'Timbaúba',
                uf: 'PE',
                code: 2615300,
            },
            {
                name: 'Toritama',
                uf: 'PE',
                code: 2615409,
            },
            {
                name: 'Tracunhaém',
                uf: 'PE',
                code: 2615508,
            },
            {
                name: 'Trindade',
                uf: 'PE',
                code: 2615607,
            },
            {
                name: 'Triunfo',
                uf: 'PE',
                code: 2615706,
            },
            {
                name: 'Tupanatinga',
                uf: 'PE',
                code: 2615805,
            },
            {
                name: 'Tuparetama',
                uf: 'PE',
                code: 2615904,
            },
            {
                name: 'Venturosa',
                uf: 'PE',
                code: 2616001,
            },
            {
                name: 'Verdejante',
                uf: 'PE',
                code: 2616100,
            },
            {
                name: 'Vertente do Lério',
                uf: 'PE',
                code: 2616183,
            },
            {
                name: 'Vertentes',
                uf: 'PE',
                code: 2616209,
            },
            {
                name: 'Vicência',
                uf: 'PE',
                code: 2616308,
            },
            {
                name: 'Vitória de Santo Antão',
                uf: 'PE',
                code: 2616407,
            },
            {
                name: 'Xexéu',
                uf: 'PE',
                code: 2616506,
            },
            {
                name: 'Acauã',
                uf: 'PI',
                code: 2200053,
            },
            {
                name: 'Agricolândia',
                uf: 'PI',
                code: 2200103,
            },
            {
                name: 'Água Branca',
                uf: 'PI',
                code: 2200202,
            },
            {
                name: 'Alagoinha do Piauí',
                uf: 'PI',
                code: 2200251,
            },
            {
                name: 'Alegrete do Piauí',
                uf: 'PI',
                code: 2200277,
            },
            {
                name: 'Alto Longá',
                uf: 'PI',
                code: 2200301,
            },
            {
                name: 'Altos',
                uf: 'PI',
                code: 2200400,
            },
            {
                name: 'Alvorada do Gurguéia',
                uf: 'PI',
                code: 2200459,
            },
            {
                name: 'Amarante',
                uf: 'PI',
                code: 2200509,
            },
            {
                name: 'Angical do Piauí',
                uf: 'PI',
                code: 2200608,
            },
            {
                name: 'Anísio de Abreu',
                uf: 'PI',
                code: 2200707,
            },
            {
                name: 'Antônio Almeida',
                uf: 'PI',
                code: 2200806,
            },
            {
                name: 'Aroazes',
                uf: 'PI',
                code: 2200905,
            },
            {
                name: 'Aroeiras do Itaim',
                uf: 'PI',
                code: 2200954,
            },
            {
                name: 'Arraial',
                uf: 'PI',
                code: 2201002,
            },
            {
                name: 'Assunção do Piauí',
                uf: 'PI',
                code: 2201051,
            },
            {
                name: 'Avelino Lopes',
                uf: 'PI',
                code: 2201101,
            },
            {
                name: 'Baixa Grande do Ribeiro',
                uf: 'PI',
                code: 2201150,
            },
            {
                name: "Barra D'Alcântara",
                uf: 'PI',
                code: 2201176,
            },
            {
                name: 'Barras',
                uf: 'PI',
                code: 2201200,
            },
            {
                name: 'Barreiras do Piauí',
                uf: 'PI',
                code: 2201309,
            },
            {
                name: 'Barro Duro',
                uf: 'PI',
                code: 2201408,
            },
            {
                name: 'Batalha',
                uf: 'PI',
                code: 2201507,
            },
            {
                name: 'Bela Vista do Piauí',
                uf: 'PI',
                code: 2201556,
            },
            {
                name: 'Belém do Piauí',
                uf: 'PI',
                code: 2201572,
            },
            {
                name: 'Beneditinos',
                uf: 'PI',
                code: 2201606,
            },
            {
                name: 'Bertolínia',
                uf: 'PI',
                code: 2201705,
            },
            {
                name: 'Betânia do Piauí',
                uf: 'PI',
                code: 2201739,
            },
            {
                name: 'Boa Hora',
                uf: 'PI',
                code: 2201770,
            },
            {
                name: 'Bocaina',
                uf: 'PI',
                code: 2201804,
            },
            {
                name: 'Bom Jesus',
                uf: 'PI',
                code: 2201903,
            },
            {
                name: 'Bom Princípio do Piauí',
                uf: 'PI',
                code: 2201919,
            },
            {
                name: 'Bonfim do Piauí',
                uf: 'PI',
                code: 2201929,
            },
            {
                name: 'Boqueirão do Piauí',
                uf: 'PI',
                code: 2201945,
            },
            {
                name: 'Brasileira',
                uf: 'PI',
                code: 2201960,
            },
            {
                name: 'Brejo do Piauí',
                uf: 'PI',
                code: 2201988,
            },
            {
                name: 'Buriti dos Lopes',
                uf: 'PI',
                code: 2202000,
            },
            {
                name: 'Buriti dos Montes',
                uf: 'PI',
                code: 2202026,
            },
            {
                name: 'Cabeceiras do Piauí',
                uf: 'PI',
                code: 2202059,
            },
            {
                name: 'Cajazeiras do Piauí',
                uf: 'PI',
                code: 2202075,
            },
            {
                name: 'Cajueiro da Praia',
                uf: 'PI',
                code: 2202083,
            },
            {
                name: 'Caldeirão Grande do Piauí',
                uf: 'PI',
                code: 2202091,
            },
            {
                name: 'Campinas do Piauí',
                uf: 'PI',
                code: 2202109,
            },
            {
                name: 'Campo Alegre do Fidalgo',
                uf: 'PI',
                code: 2202117,
            },
            {
                name: 'Campo Grande do Piauí',
                uf: 'PI',
                code: 2202133,
            },
            {
                name: 'Campo Largo do Piauí',
                uf: 'PI',
                code: 2202174,
            },
            {
                name: 'Campo Maior',
                uf: 'PI',
                code: 2202208,
            },
            {
                name: 'Canavieira',
                uf: 'PI',
                code: 2202251,
            },
            {
                name: 'Canto do Buriti',
                uf: 'PI',
                code: 2202307,
            },
            {
                name: 'Capitão de Campos',
                uf: 'PI',
                code: 2202406,
            },
            {
                name: 'Capitão Gervásio Oliveira',
                uf: 'PI',
                code: 2202455,
            },
            {
                name: 'Caracol',
                uf: 'PI',
                code: 2202505,
            },
            {
                name: 'Caraúbas do Piauí',
                uf: 'PI',
                code: 2202539,
            },
            {
                name: 'Caridade do Piauí',
                uf: 'PI',
                code: 2202554,
            },
            {
                name: 'Castelo do Piauí',
                uf: 'PI',
                code: 2202604,
            },
            {
                name: 'Caxingó',
                uf: 'PI',
                code: 2202653,
            },
            {
                name: 'Cocal',
                uf: 'PI',
                code: 2202703,
            },
            {
                name: 'Cocal de Telha',
                uf: 'PI',
                code: 2202711,
            },
            {
                name: 'Cocal dos Alves',
                uf: 'PI',
                code: 2202729,
            },
            {
                name: 'Coivaras',
                uf: 'PI',
                code: 2202737,
            },
            {
                name: 'Colônia do Gurguéia',
                uf: 'PI',
                code: 2202752,
            },
            {
                name: 'Colônia do Piauí',
                uf: 'PI',
                code: 2202778,
            },
            {
                name: 'Conceição do Canindé',
                uf: 'PI',
                code: 2202802,
            },
            {
                name: 'Coronel José Dias',
                uf: 'PI',
                code: 2202851,
            },
            {
                name: 'Corrente',
                uf: 'PI',
                code: 2202901,
            },
            {
                name: 'Cristalândia do Piauí',
                uf: 'PI',
                code: 2203008,
            },
            {
                name: 'Cristino Castro',
                uf: 'PI',
                code: 2203107,
            },
            {
                name: 'Curimatá',
                uf: 'PI',
                code: 2203206,
            },
            {
                name: 'Currais',
                uf: 'PI',
                code: 2203230,
            },
            {
                name: 'Curral Novo do Piauí',
                uf: 'PI',
                code: 2203271,
            },
            {
                name: 'Curralinhos',
                uf: 'PI',
                code: 2203255,
            },
            {
                name: 'Demerval Lobão',
                uf: 'PI',
                code: 2203305,
            },
            {
                name: 'Dirceu Arcoverde',
                uf: 'PI',
                code: 2203354,
            },
            {
                name: 'Dom Expedito Lopes',
                uf: 'PI',
                code: 2203404,
            },
            {
                name: 'Dom Inocêncio',
                uf: 'PI',
                code: 2203453,
            },
            {
                name: 'Domingos Mourão',
                uf: 'PI',
                code: 2203420,
            },
            {
                name: 'Elesbão Veloso',
                uf: 'PI',
                code: 2203503,
            },
            {
                name: 'Eliseu Martins',
                uf: 'PI',
                code: 2203602,
            },
            {
                name: 'Esperantina',
                uf: 'PI',
                code: 2203701,
            },
            {
                name: 'Fartura do Piauí',
                uf: 'PI',
                code: 2203750,
            },
            {
                name: 'Flores do Piauí',
                uf: 'PI',
                code: 2203800,
            },
            {
                name: 'Floresta do Piauí',
                uf: 'PI',
                code: 2203859,
            },
            {
                name: 'Floriano',
                uf: 'PI',
                code: 2203909,
            },
            {
                name: 'Francinópolis',
                uf: 'PI',
                code: 2204006,
            },
            {
                name: 'Francisco Ayres',
                uf: 'PI',
                code: 2204105,
            },
            {
                name: 'Francisco Macedo',
                uf: 'PI',
                code: 2204154,
            },
            {
                name: 'Francisco Santos',
                uf: 'PI',
                code: 2204204,
            },
            {
                name: 'Fronteiras',
                uf: 'PI',
                code: 2204303,
            },
            {
                name: 'Geminiano',
                uf: 'PI',
                code: 2204352,
            },
            {
                name: 'Gilbués',
                uf: 'PI',
                code: 2204402,
            },
            {
                name: 'Guadalupe',
                uf: 'PI',
                code: 2204501,
            },
            {
                name: 'Guaribas',
                uf: 'PI',
                code: 2204550,
            },
            {
                name: 'Hugo Napoleão',
                uf: 'PI',
                code: 2204600,
            },
            {
                name: 'Ilha Grande',
                uf: 'PI',
                code: 2204659,
            },
            {
                name: 'Inhuma',
                uf: 'PI',
                code: 2204709,
            },
            {
                name: 'Ipiranga do Piauí',
                uf: 'PI',
                code: 2204808,
            },
            {
                name: 'Isaías Coelho',
                uf: 'PI',
                code: 2204907,
            },
            {
                name: 'Itainópolis',
                uf: 'PI',
                code: 2205003,
            },
            {
                name: 'Itaueira',
                uf: 'PI',
                code: 2205102,
            },
            {
                name: 'Jacobina do Piauí',
                uf: 'PI',
                code: 2205151,
            },
            {
                name: 'Jaicós',
                uf: 'PI',
                code: 2205201,
            },
            {
                name: 'Jardim do Mulato',
                uf: 'PI',
                code: 2205250,
            },
            {
                name: 'Jatobá do Piauí',
                uf: 'PI',
                code: 2205276,
            },
            {
                name: 'Jerumenha',
                uf: 'PI',
                code: 2205300,
            },
            {
                name: 'João Costa',
                uf: 'PI',
                code: 2205359,
            },
            {
                name: 'Joaquim Pires',
                uf: 'PI',
                code: 2205409,
            },
            {
                name: 'Joca Marques',
                uf: 'PI',
                code: 2205458,
            },
            {
                name: 'José de Freitas',
                uf: 'PI',
                code: 2205508,
            },
            {
                name: 'Juazeiro do Piauí',
                uf: 'PI',
                code: 2205516,
            },
            {
                name: 'Júlio Borges',
                uf: 'PI',
                code: 2205524,
            },
            {
                name: 'Jurema',
                uf: 'PI',
                code: 2205532,
            },
            {
                name: 'Lagoa Alegre',
                uf: 'PI',
                code: 2205557,
            },
            {
                name: 'Lagoa de São Francisco',
                uf: 'PI',
                code: 2205573,
            },
            {
                name: 'Lagoa do Barro do Piauí',
                uf: 'PI',
                code: 2205565,
            },
            {
                name: 'Lagoa do Piauí',
                uf: 'PI',
                code: 2205581,
            },
            {
                name: 'Lagoa do Sítio',
                uf: 'PI',
                code: 2205599,
            },
            {
                name: 'Lagoinha do Piauí',
                uf: 'PI',
                code: 2205540,
            },
            {
                name: 'Landri Sales',
                uf: 'PI',
                code: 2205607,
            },
            {
                name: 'Luís Correia',
                uf: 'PI',
                code: 2205706,
            },
            {
                name: 'Luzilândia',
                uf: 'PI',
                code: 2205805,
            },
            {
                name: 'Madeiro',
                uf: 'PI',
                code: 2205854,
            },
            {
                name: 'Manoel Emídio',
                uf: 'PI',
                code: 2205904,
            },
            {
                name: 'Marcolândia',
                uf: 'PI',
                code: 2205953,
            },
            {
                name: 'Marcos Parente',
                uf: 'PI',
                code: 2206001,
            },
            {
                name: 'Massapê do Piauí',
                uf: 'PI',
                code: 2206050,
            },
            {
                name: 'Matias Olímpio',
                uf: 'PI',
                code: 2206100,
            },
            {
                name: 'Miguel Alves',
                uf: 'PI',
                code: 2206209,
            },
            {
                name: 'Miguel Leão',
                uf: 'PI',
                code: 2206308,
            },
            {
                name: 'Milton Brandão',
                uf: 'PI',
                code: 2206357,
            },
            {
                name: 'Monsenhor Gil',
                uf: 'PI',
                code: 2206407,
            },
            {
                name: 'Monsenhor Hipólito',
                uf: 'PI',
                code: 2206506,
            },
            {
                name: 'Monte Alegre do Piauí',
                uf: 'PI',
                code: 2206605,
            },
            {
                name: 'Morro Cabeça no Tempo',
                uf: 'PI',
                code: 2206654,
            },
            {
                name: 'Morro do Chapéu do Piauí',
                uf: 'PI',
                code: 2206670,
            },
            {
                name: 'Murici dos Portelas',
                uf: 'PI',
                code: 2206696,
            },
            {
                name: 'Nazaré do Piauí',
                uf: 'PI',
                code: 2206704,
            },
            {
                name: 'Nazária',
                uf: 'PI',
                code: 2206720,
            },
            {
                name: 'Nossa Senhora de Nazaré',
                uf: 'PI',
                code: 2206753,
            },
            {
                name: 'Nossa Senhora dos Remédios',
                uf: 'PI',
                code: 2206803,
            },
            {
                name: 'Nova Santa Rita',
                uf: 'PI',
                code: 2207959,
            },
            {
                name: 'Novo Oriente do Piauí',
                uf: 'PI',
                code: 2206902,
            },
            {
                name: 'Novo Santo Antônio',
                uf: 'PI',
                code: 2206951,
            },
            {
                name: 'Oeiras',
                uf: 'PI',
                code: 2207009,
            },
            {
                name: "Olho d'Água do Piauí",
                uf: 'PI',
                code: 2207108,
            },
            {
                name: 'Padre Marcos',
                uf: 'PI',
                code: 2207207,
            },
            {
                name: 'Paes Landim',
                uf: 'PI',
                code: 2207306,
            },
            {
                name: 'Pajeú do Piauí',
                uf: 'PI',
                code: 2207355,
            },
            {
                name: 'Palmeira do Piauí',
                uf: 'PI',
                code: 2207405,
            },
            {
                name: 'Palmeirais',
                uf: 'PI',
                code: 2207504,
            },
            {
                name: 'Paquetá',
                uf: 'PI',
                code: 2207553,
            },
            {
                name: 'Parnaguá',
                uf: 'PI',
                code: 2207603,
            },
            {
                name: 'Parnaíba',
                uf: 'PI',
                code: 2207702,
            },
            {
                name: 'Passagem Franca do Piauí',
                uf: 'PI',
                code: 2207751,
            },
            {
                name: 'Patos do Piauí',
                uf: 'PI',
                code: 2207777,
            },
            {
                name: "Pau D'Arco do Piauí",
                uf: 'PI',
                code: 2207793,
            },
            {
                name: 'Paulistana',
                uf: 'PI',
                code: 2207801,
            },
            {
                name: 'Pavussu',
                uf: 'PI',
                code: 2207850,
            },
            {
                name: 'Pedro II',
                uf: 'PI',
                code: 2207900,
            },
            {
                name: 'Pedro Laurentino',
                uf: 'PI',
                code: 2207934,
            },
            {
                name: 'Picos',
                uf: 'PI',
                code: 2208007,
            },
            {
                name: 'Pimenteiras',
                uf: 'PI',
                code: 2208106,
            },
            {
                name: 'Pio IX',
                uf: 'PI',
                code: 2208205,
            },
            {
                name: 'Piracuruca',
                uf: 'PI',
                code: 2208304,
            },
            {
                name: 'Piripiri',
                uf: 'PI',
                code: 2208403,
            },
            {
                name: 'Porto',
                uf: 'PI',
                code: 2208502,
            },
            {
                name: 'Porto Alegre do Piauí',
                uf: 'PI',
                code: 2208551,
            },
            {
                name: 'Prata do Piauí',
                uf: 'PI',
                code: 2208601,
            },
            {
                name: 'Queimada Nova',
                uf: 'PI',
                code: 2208650,
            },
            {
                name: 'Redenção do Gurguéia',
                uf: 'PI',
                code: 2208700,
            },
            {
                name: 'Regeneração',
                uf: 'PI',
                code: 2208809,
            },
            {
                name: 'Riacho Frio',
                uf: 'PI',
                code: 2208858,
            },
            {
                name: 'Ribeira do Piauí',
                uf: 'PI',
                code: 2208874,
            },
            {
                name: 'Ribeiro Gonçalves',
                uf: 'PI',
                code: 2208908,
            },
            {
                name: 'Rio Grande do Piauí',
                uf: 'PI',
                code: 2209005,
            },
            {
                name: 'Santa Cruz do Piauí',
                uf: 'PI',
                code: 2209104,
            },
            {
                name: 'Santa Cruz dos Milagres',
                uf: 'PI',
                code: 2209153,
            },
            {
                name: 'Santa Filomena',
                uf: 'PI',
                code: 2209203,
            },
            {
                name: 'Santa Luz',
                uf: 'PI',
                code: 2209302,
            },
            {
                name: 'Santa Rosa do Piauí',
                uf: 'PI',
                code: 2209377,
            },
            {
                name: 'Santana do Piauí',
                uf: 'PI',
                code: 2209351,
            },
            {
                name: 'Santo Antônio de Lisboa',
                uf: 'PI',
                code: 2209401,
            },
            {
                name: 'Santo Antônio dos Milagres',
                uf: 'PI',
                code: 2209450,
            },
            {
                name: 'Santo Inácio do Piauí',
                uf: 'PI',
                code: 2209500,
            },
            {
                name: 'São Braz do Piauí',
                uf: 'PI',
                code: 2209559,
            },
            {
                name: 'São Félix do Piauí',
                uf: 'PI',
                code: 2209609,
            },
            {
                name: 'São Francisco de Assis do Piauí',
                uf: 'PI',
                code: 2209658,
            },
            {
                name: 'São Francisco do Piauí',
                uf: 'PI',
                code: 2209708,
            },
            {
                name: 'São Gonçalo do Gurguéia',
                uf: 'PI',
                code: 2209757,
            },
            {
                name: 'São Gonçalo do Piauí',
                uf: 'PI',
                code: 2209807,
            },
            {
                name: 'São João da Canabrava',
                uf: 'PI',
                code: 2209856,
            },
            {
                name: 'São João da Fronteira',
                uf: 'PI',
                code: 2209872,
            },
            {
                name: 'São João da Serra',
                uf: 'PI',
                code: 2209906,
            },
            {
                name: 'São João da Varjota',
                uf: 'PI',
                code: 2209955,
            },
            {
                name: 'São João do Arraial',
                uf: 'PI',
                code: 2209971,
            },
            {
                name: 'São João do Piauí',
                uf: 'PI',
                code: 2210003,
            },
            {
                name: 'São José do Divino',
                uf: 'PI',
                code: 2210052,
            },
            {
                name: 'São José do Peixe',
                uf: 'PI',
                code: 2210102,
            },
            {
                name: 'São José do Piauí',
                uf: 'PI',
                code: 2210201,
            },
            {
                name: 'São Julião',
                uf: 'PI',
                code: 2210300,
            },
            {
                name: 'São Lourenço do Piauí',
                uf: 'PI',
                code: 2210359,
            },
            {
                name: 'São Luis do Piauí',
                uf: 'PI',
                code: 2210375,
            },
            {
                name: 'São Miguel da Baixa Grande',
                uf: 'PI',
                code: 2210383,
            },
            {
                name: 'São Miguel do Fidalgo',
                uf: 'PI',
                code: 2210391,
            },
            {
                name: 'São Miguel do Tapuio',
                uf: 'PI',
                code: 2210409,
            },
            {
                name: 'São Pedro do Piauí',
                uf: 'PI',
                code: 2210508,
            },
            {
                name: 'São Raimundo Nonato',
                uf: 'PI',
                code: 2210607,
            },
            {
                name: 'Sebastião Barros',
                uf: 'PI',
                code: 2210623,
            },
            {
                name: 'Sebastião Leal',
                uf: 'PI',
                code: 2210631,
            },
            {
                name: 'Sigefredo Pacheco',
                uf: 'PI',
                code: 2210656,
            },
            {
                name: 'Simões',
                uf: 'PI',
                code: 2210706,
            },
            {
                name: 'Simplício Mendes',
                uf: 'PI',
                code: 2210805,
            },
            {
                name: 'Socorro do Piauí',
                uf: 'PI',
                code: 2210904,
            },
            {
                name: 'Sussuapara',
                uf: 'PI',
                code: 2210938,
            },
            {
                name: 'Tamboril do Piauí',
                uf: 'PI',
                code: 2210953,
            },
            {
                name: 'Tanque do Piauí',
                uf: 'PI',
                code: 2210979,
            },
            {
                name: 'Teresina',
                uf: 'PI',
                code: 2211001,
            },
            {
                name: 'União',
                uf: 'PI',
                code: 2211100,
            },
            {
                name: 'Uruçuí',
                uf: 'PI',
                code: 2211209,
            },
            {
                name: 'Valença do Piauí',
                uf: 'PI',
                code: 2211308,
            },
            {
                name: 'Várzea Branca',
                uf: 'PI',
                code: 2211357,
            },
            {
                name: 'Várzea Grande',
                uf: 'PI',
                code: 2211407,
            },
            {
                name: 'Vera Mendes',
                uf: 'PI',
                code: 2211506,
            },
            {
                name: 'Vila Nova do Piauí',
                uf: 'PI',
                code: 2211605,
            },
            {
                name: 'Wall Ferraz',
                uf: 'PI',
                code: 2211704,
            },
            {
                name: 'Abatiá',
                uf: 'PR',
                code: 4100103,
            },
            {
                name: 'Adrianópolis',
                uf: 'PR',
                code: 4100202,
            },
            {
                name: 'Agudos do Sul',
                uf: 'PR',
                code: 4100301,
            },
            {
                name: 'Almirante Tamandaré',
                uf: 'PR',
                code: 4100400,
            },
            {
                name: 'Altamira do Paraná',
                uf: 'PR',
                code: 4100459,
            },
            {
                name: 'Alto Paraíso',
                uf: 'PR',
                code: 4128625,
            },
            {
                name: 'Alto Paraná',
                uf: 'PR',
                code: 4100608,
            },
            {
                name: 'Alto Piquiri',
                uf: 'PR',
                code: 4100707,
            },
            {
                name: 'Altônia',
                uf: 'PR',
                code: 4100509,
            },
            {
                name: 'Alvorada do Sul',
                uf: 'PR',
                code: 4100806,
            },
            {
                name: 'Amaporã',
                uf: 'PR',
                code: 4100905,
            },
            {
                name: 'Ampére',
                uf: 'PR',
                code: 4101002,
            },
            {
                name: 'Anahy',
                uf: 'PR',
                code: 4101051,
            },
            {
                name: 'Andirá',
                uf: 'PR',
                code: 4101101,
            },
            {
                name: 'Ângulo',
                uf: 'PR',
                code: 4101150,
            },
            {
                name: 'Antonina',
                uf: 'PR',
                code: 4101200,
            },
            {
                name: 'Antônio Olinto',
                uf: 'PR',
                code: 4101309,
            },
            {
                name: 'Apucarana',
                uf: 'PR',
                code: 4101408,
            },
            {
                name: 'Arapongas',
                uf: 'PR',
                code: 4101507,
            },
            {
                name: 'Arapoti',
                uf: 'PR',
                code: 4101606,
            },
            {
                name: 'Arapuã',
                uf: 'PR',
                code: 4101655,
            },
            {
                name: 'Araruna',
                uf: 'PR',
                code: 4101705,
            },
            {
                name: 'Araucária',
                uf: 'PR',
                code: 4101804,
            },
            {
                name: 'Ariranha do Ivaí',
                uf: 'PR',
                code: 4101853,
            },
            {
                name: 'Assaí',
                uf: 'PR',
                code: 4101903,
            },
            {
                name: 'Assis Chateaubriand',
                uf: 'PR',
                code: 4102000,
            },
            {
                name: 'Astorga',
                uf: 'PR',
                code: 4102109,
            },
            {
                name: 'Atalaia',
                uf: 'PR',
                code: 4102208,
            },
            {
                name: 'Balsa Nova',
                uf: 'PR',
                code: 4102307,
            },
            {
                name: 'Bandeirantes',
                uf: 'PR',
                code: 4102406,
            },
            {
                name: 'Barbosa Ferraz',
                uf: 'PR',
                code: 4102505,
            },
            {
                name: 'Barra do Jacaré',
                uf: 'PR',
                code: 4102703,
            },
            {
                name: 'Barracão',
                uf: 'PR',
                code: 4102604,
            },
            {
                name: 'Bela Vista da Caroba',
                uf: 'PR',
                code: 4102752,
            },
            {
                name: 'Bela Vista do Paraíso',
                uf: 'PR',
                code: 4102802,
            },
            {
                name: 'Bituruna',
                uf: 'PR',
                code: 4102901,
            },
            {
                name: 'Boa Esperança',
                uf: 'PR',
                code: 4103008,
            },
            {
                name: 'Boa Esperança do Iguaçu',
                uf: 'PR',
                code: 4103024,
            },
            {
                name: 'Boa Ventura de São Roque',
                uf: 'PR',
                code: 4103040,
            },
            {
                name: 'Boa Vista da Aparecida',
                uf: 'PR',
                code: 4103057,
            },
            {
                name: 'Bocaiúva do Sul',
                uf: 'PR',
                code: 4103107,
            },
            {
                name: 'Bom Jesus do Sul',
                uf: 'PR',
                code: 4103156,
            },
            {
                name: 'Bom Sucesso',
                uf: 'PR',
                code: 4103206,
            },
            {
                name: 'Bom Sucesso do Sul',
                uf: 'PR',
                code: 4103222,
            },
            {
                name: 'Borrazópolis',
                uf: 'PR',
                code: 4103305,
            },
            {
                name: 'Braganey',
                uf: 'PR',
                code: 4103354,
            },
            {
                name: 'Brasilândia do Sul',
                uf: 'PR',
                code: 4103370,
            },
            {
                name: 'Cafeara',
                uf: 'PR',
                code: 4103404,
            },
            {
                name: 'Cafelândia',
                uf: 'PR',
                code: 4103453,
            },
            {
                name: 'Cafezal do Sul',
                uf: 'PR',
                code: 4103479,
            },
            {
                name: 'Califórnia',
                uf: 'PR',
                code: 4103503,
            },
            {
                name: 'Cambará',
                uf: 'PR',
                code: 4103602,
            },
            {
                name: 'Cambé',
                uf: 'PR',
                code: 4103701,
            },
            {
                name: 'Cambira',
                uf: 'PR',
                code: 4103800,
            },
            {
                name: 'Campina da Lagoa',
                uf: 'PR',
                code: 4103909,
            },
            {
                name: 'Campina do Simão',
                uf: 'PR',
                code: 4103958,
            },
            {
                name: 'Campina Grande do Sul',
                uf: 'PR',
                code: 4104006,
            },
            {
                name: 'Campo Bonito',
                uf: 'PR',
                code: 4104055,
            },
            {
                name: 'Campo do Tenente',
                uf: 'PR',
                code: 4104105,
            },
            {
                name: 'Campo Largo',
                uf: 'PR',
                code: 4104204,
            },
            {
                name: 'Campo Magro',
                uf: 'PR',
                code: 4104253,
            },
            {
                name: 'Campo Mourão',
                uf: 'PR',
                code: 4104303,
            },
            {
                name: 'Cândido de Abreu',
                uf: 'PR',
                code: 4104402,
            },
            {
                name: 'Candói',
                uf: 'PR',
                code: 4104428,
            },
            {
                name: 'Cantagalo',
                uf: 'PR',
                code: 4104451,
            },
            {
                name: 'Capanema',
                uf: 'PR',
                code: 4104501,
            },
            {
                name: 'Capitão Leônidas Marques',
                uf: 'PR',
                code: 4104600,
            },
            {
                name: 'Carambeí',
                uf: 'PR',
                code: 4104659,
            },
            {
                name: 'Carlópolis',
                uf: 'PR',
                code: 4104709,
            },
            {
                name: 'Cascavel',
                uf: 'PR',
                code: 4104808,
            },
            {
                name: 'Castro',
                uf: 'PR',
                code: 4104907,
            },
            {
                name: 'Catanduvas',
                uf: 'PR',
                code: 4105003,
            },
            {
                name: 'Centenário do Sul',
                uf: 'PR',
                code: 4105102,
            },
            {
                name: 'Cerro Azul',
                uf: 'PR',
                code: 4105201,
            },
            {
                name: 'Céu Azul',
                uf: 'PR',
                code: 4105300,
            },
            {
                name: 'Chopinzinho',
                uf: 'PR',
                code: 4105409,
            },
            {
                name: 'Cianorte',
                uf: 'PR',
                code: 4105508,
            },
            {
                name: 'Cidade Gaúcha',
                uf: 'PR',
                code: 4105607,
            },
            {
                name: 'Clevelândia',
                uf: 'PR',
                code: 4105706,
            },
            {
                name: 'Colombo',
                uf: 'PR',
                code: 4105805,
            },
            {
                name: 'Colorado',
                uf: 'PR',
                code: 4105904,
            },
            {
                name: 'Congonhinhas',
                uf: 'PR',
                code: 4106001,
            },
            {
                name: 'Conselheiro Mairinck',
                uf: 'PR',
                code: 4106100,
            },
            {
                name: 'Contenda',
                uf: 'PR',
                code: 4106209,
            },
            {
                name: 'Corbélia',
                uf: 'PR',
                code: 4106308,
            },
            {
                name: 'Cornélio Procópio',
                uf: 'PR',
                code: 4106407,
            },
            {
                name: 'Coronel Domingos Soares',
                uf: 'PR',
                code: 4106456,
            },
            {
                name: 'Coronel Vivida',
                uf: 'PR',
                code: 4106506,
            },
            {
                name: 'Corumbataí do Sul',
                uf: 'PR',
                code: 4106555,
            },
            {
                name: 'Cruz Machado',
                uf: 'PR',
                code: 4106803,
            },
            {
                name: 'Cruzeiro do Iguaçu',
                uf: 'PR',
                code: 4106571,
            },
            {
                name: 'Cruzeiro do Oeste',
                uf: 'PR',
                code: 4106605,
            },
            {
                name: 'Cruzeiro do Sul',
                uf: 'PR',
                code: 4106704,
            },
            {
                name: 'Cruzmaltina',
                uf: 'PR',
                code: 4106852,
            },
            {
                name: 'Curitiba',
                uf: 'PR',
                code: 4106902,
            },
            {
                name: 'Curiúva',
                uf: 'PR',
                code: 4107009,
            },
            {
                name: "Diamante D'Oeste",
                uf: 'PR',
                code: 4107157,
            },
            {
                name: 'Diamante do Norte',
                uf: 'PR',
                code: 4107108,
            },
            {
                name: 'Diamante do Sul',
                uf: 'PR',
                code: 4107124,
            },
            {
                name: 'Dois Vizinhos',
                uf: 'PR',
                code: 4107207,
            },
            {
                name: 'Douradina',
                uf: 'PR',
                code: 4107256,
            },
            {
                name: 'Doutor Camargo',
                uf: 'PR',
                code: 4107306,
            },
            {
                name: 'Doutor Ulysses',
                uf: 'PR',
                code: 4128633,
            },
            {
                name: 'Enéas Marques',
                uf: 'PR',
                code: 4107405,
            },
            {
                name: 'Engenheiro Beltrão',
                uf: 'PR',
                code: 4107504,
            },
            {
                name: 'Entre Rios do Oeste',
                uf: 'PR',
                code: 4107538,
            },
            {
                name: 'Esperança Nova',
                uf: 'PR',
                code: 4107520,
            },
            {
                name: 'Espigão Alto do Iguaçu',
                uf: 'PR',
                code: 4107546,
            },
            {
                name: 'Farol',
                uf: 'PR',
                code: 4107553,
            },
            {
                name: 'Faxinal',
                uf: 'PR',
                code: 4107603,
            },
            {
                name: 'Fazenda Rio Grande',
                uf: 'PR',
                code: 4107652,
            },
            {
                name: 'Fênix',
                uf: 'PR',
                code: 4107702,
            },
            {
                name: 'Fernandes Pinheiro',
                uf: 'PR',
                code: 4107736,
            },
            {
                name: 'Figueira',
                uf: 'PR',
                code: 4107751,
            },
            {
                name: 'Flor da Serra do Sul',
                uf: 'PR',
                code: 4107850,
            },
            {
                name: 'Floraí',
                uf: 'PR',
                code: 4107801,
            },
            {
                name: 'Floresta',
                uf: 'PR',
                code: 4107900,
            },
            {
                name: 'Florestópolis',
                uf: 'PR',
                code: 4108007,
            },
            {
                name: 'Flórida',
                uf: 'PR',
                code: 4108106,
            },
            {
                name: 'Formosa do Oeste',
                uf: 'PR',
                code: 4108205,
            },
            {
                name: 'Foz do Iguaçu',
                uf: 'PR',
                code: 4108304,
            },
            {
                name: 'Foz do Jordão',
                uf: 'PR',
                code: 4108452,
            },
            {
                name: 'Francisco Alves',
                uf: 'PR',
                code: 4108320,
            },
            {
                name: 'Francisco Beltrão',
                uf: 'PR',
                code: 4108403,
            },
            {
                name: 'General Carneiro',
                uf: 'PR',
                code: 4108502,
            },
            {
                name: 'Godoy Moreira',
                uf: 'PR',
                code: 4108551,
            },
            {
                name: 'Goioerê',
                uf: 'PR',
                code: 4108601,
            },
            {
                name: 'Goioxim',
                uf: 'PR',
                code: 4108650,
            },
            {
                name: 'Grandes Rios',
                uf: 'PR',
                code: 4108700,
            },
            {
                name: 'Guaíra',
                uf: 'PR',
                code: 4108809,
            },
            {
                name: 'Guairaçá',
                uf: 'PR',
                code: 4108908,
            },
            {
                name: 'Guamiranga',
                uf: 'PR',
                code: 4108957,
            },
            {
                name: 'Guapirama',
                uf: 'PR',
                code: 4109005,
            },
            {
                name: 'Guaporema',
                uf: 'PR',
                code: 4109104,
            },
            {
                name: 'Guaraci',
                uf: 'PR',
                code: 4109203,
            },
            {
                name: 'Guaraniaçu',
                uf: 'PR',
                code: 4109302,
            },
            {
                name: 'Guarapuava',
                uf: 'PR',
                code: 4109401,
            },
            {
                name: 'Guaraqueçaba',
                uf: 'PR',
                code: 4109500,
            },
            {
                name: 'Guaratuba',
                uf: 'PR',
                code: 4109609,
            },
            {
                name: 'Honório Serpa',
                uf: 'PR',
                code: 4109658,
            },
            {
                name: 'Ibaiti',
                uf: 'PR',
                code: 4109708,
            },
            {
                name: 'Ibema',
                uf: 'PR',
                code: 4109757,
            },
            {
                name: 'Ibiporã',
                uf: 'PR',
                code: 4109807,
            },
            {
                name: 'Icaraíma',
                uf: 'PR',
                code: 4109906,
            },
            {
                name: 'Iguaraçu',
                uf: 'PR',
                code: 4110003,
            },
            {
                name: 'Iguatu',
                uf: 'PR',
                code: 4110052,
            },
            {
                name: 'Imbaú',
                uf: 'PR',
                code: 4110078,
            },
            {
                name: 'Imbituva',
                uf: 'PR',
                code: 4110102,
            },
            {
                name: 'Inácio Martins',
                uf: 'PR',
                code: 4110201,
            },
            {
                name: 'Inajá',
                uf: 'PR',
                code: 4110300,
            },
            {
                name: 'Indianópolis',
                uf: 'PR',
                code: 4110409,
            },
            {
                name: 'Ipiranga',
                uf: 'PR',
                code: 4110508,
            },
            {
                name: 'Iporã',
                uf: 'PR',
                code: 4110607,
            },
            {
                name: 'Iracema do Oeste',
                uf: 'PR',
                code: 4110656,
            },
            {
                name: 'Irati',
                uf: 'PR',
                code: 4110706,
            },
            {
                name: 'Iretama',
                uf: 'PR',
                code: 4110805,
            },
            {
                name: 'Itaguajé',
                uf: 'PR',
                code: 4110904,
            },
            {
                name: 'Itaipulândia',
                uf: 'PR',
                code: 4110953,
            },
            {
                name: 'Itambaracá',
                uf: 'PR',
                code: 4111001,
            },
            {
                name: 'Itambé',
                uf: 'PR',
                code: 4111100,
            },
            {
                name: "Itapejara d'Oeste",
                uf: 'PR',
                code: 4111209,
            },
            {
                name: 'Itaperuçu',
                uf: 'PR',
                code: 4111258,
            },
            {
                name: 'Itaúna do Sul',
                uf: 'PR',
                code: 4111308,
            },
            {
                name: 'Ivaí',
                uf: 'PR',
                code: 4111407,
            },
            {
                name: 'Ivaiporã',
                uf: 'PR',
                code: 4111506,
            },
            {
                name: 'Ivaté',
                uf: 'PR',
                code: 4111555,
            },
            {
                name: 'Ivatuba',
                uf: 'PR',
                code: 4111605,
            },
            {
                name: 'Jaboti',
                uf: 'PR',
                code: 4111704,
            },
            {
                name: 'Jacarezinho',
                uf: 'PR',
                code: 4111803,
            },
            {
                name: 'Jaguapitã',
                uf: 'PR',
                code: 4111902,
            },
            {
                name: 'Jaguariaíva',
                uf: 'PR',
                code: 4112009,
            },
            {
                name: 'Jandaia do Sul',
                uf: 'PR',
                code: 4112108,
            },
            {
                name: 'Janiópolis',
                uf: 'PR',
                code: 4112207,
            },
            {
                name: 'Japira',
                uf: 'PR',
                code: 4112306,
            },
            {
                name: 'Japurá',
                uf: 'PR',
                code: 4112405,
            },
            {
                name: 'Jardim Alegre',
                uf: 'PR',
                code: 4112504,
            },
            {
                name: 'Jardim Olinda',
                uf: 'PR',
                code: 4112603,
            },
            {
                name: 'Jataizinho',
                uf: 'PR',
                code: 4112702,
            },
            {
                name: 'Jesuítas',
                uf: 'PR',
                code: 4112751,
            },
            {
                name: 'Joaquim Távora',
                uf: 'PR',
                code: 4112801,
            },
            {
                name: 'Jundiaí do Sul',
                uf: 'PR',
                code: 4112900,
            },
            {
                name: 'Juranda',
                uf: 'PR',
                code: 4112959,
            },
            {
                name: 'Jussara',
                uf: 'PR',
                code: 4113007,
            },
            {
                name: 'Kaloré',
                uf: 'PR',
                code: 4113106,
            },
            {
                name: 'Lapa',
                uf: 'PR',
                code: 4113205,
            },
            {
                name: 'Laranjal',
                uf: 'PR',
                code: 4113254,
            },
            {
                name: 'Laranjeiras do Sul',
                uf: 'PR',
                code: 4113304,
            },
            {
                name: 'Leópolis',
                uf: 'PR',
                code: 4113403,
            },
            {
                name: 'Lidianópolis',
                uf: 'PR',
                code: 4113429,
            },
            {
                name: 'Lindoeste',
                uf: 'PR',
                code: 4113452,
            },
            {
                name: 'Loanda',
                uf: 'PR',
                code: 4113502,
            },
            {
                name: 'Lobato',
                uf: 'PR',
                code: 4113601,
            },
            {
                name: 'Londrina',
                uf: 'PR',
                code: 4113700,
            },
            {
                name: 'Luiziana',
                uf: 'PR',
                code: 4113734,
            },
            {
                name: 'Lunardelli',
                uf: 'PR',
                code: 4113759,
            },
            {
                name: 'Lupionópolis',
                uf: 'PR',
                code: 4113809,
            },
            {
                name: 'Mallet',
                uf: 'PR',
                code: 4113908,
            },
            {
                name: 'Mamborê',
                uf: 'PR',
                code: 4114005,
            },
            {
                name: 'Mandaguaçu',
                uf: 'PR',
                code: 4114104,
            },
            {
                name: 'Mandaguari',
                uf: 'PR',
                code: 4114203,
            },
            {
                name: 'Mandirituba',
                uf: 'PR',
                code: 4114302,
            },
            {
                name: 'Manfrinópolis',
                uf: 'PR',
                code: 4114351,
            },
            {
                name: 'Mangueirinha',
                uf: 'PR',
                code: 4114401,
            },
            {
                name: 'Manoel Ribas',
                uf: 'PR',
                code: 4114500,
            },
            {
                name: 'Marechal Cândido Rondon',
                uf: 'PR',
                code: 4114609,
            },
            {
                name: 'Maria Helena',
                uf: 'PR',
                code: 4114708,
            },
            {
                name: 'Marialva',
                uf: 'PR',
                code: 4114807,
            },
            {
                name: 'Marilândia do Sul',
                uf: 'PR',
                code: 4114906,
            },
            {
                name: 'Marilena',
                uf: 'PR',
                code: 4115002,
            },
            {
                name: 'Mariluz',
                uf: 'PR',
                code: 4115101,
            },
            {
                name: 'Maringá',
                uf: 'PR',
                code: 4115200,
            },
            {
                name: 'Mariópolis',
                uf: 'PR',
                code: 4115309,
            },
            {
                name: 'Maripá',
                uf: 'PR',
                code: 4115358,
            },
            {
                name: 'Marmeleiro',
                uf: 'PR',
                code: 4115408,
            },
            {
                name: 'Marquinho',
                uf: 'PR',
                code: 4115457,
            },
            {
                name: 'Marumbi',
                uf: 'PR',
                code: 4115507,
            },
            {
                name: 'Matelândia',
                uf: 'PR',
                code: 4115606,
            },
            {
                name: 'Matinhos',
                uf: 'PR',
                code: 4115705,
            },
            {
                name: 'Mato Rico',
                uf: 'PR',
                code: 4115739,
            },
            {
                name: 'Mauá da Serra',
                uf: 'PR',
                code: 4115754,
            },
            {
                name: 'Medianeira',
                uf: 'PR',
                code: 4115804,
            },
            {
                name: 'Mercedes',
                uf: 'PR',
                code: 4115853,
            },
            {
                name: 'Mirador',
                uf: 'PR',
                code: 4115903,
            },
            {
                name: 'Miraselva',
                uf: 'PR',
                code: 4116000,
            },
            {
                name: 'Missal',
                uf: 'PR',
                code: 4116059,
            },
            {
                name: 'Moreira Sales',
                uf: 'PR',
                code: 4116109,
            },
            {
                name: 'Morretes',
                uf: 'PR',
                code: 4116208,
            },
            {
                name: 'Munhoz de Melo',
                uf: 'PR',
                code: 4116307,
            },
            {
                name: 'Nossa Senhora das Graças',
                uf: 'PR',
                code: 4116406,
            },
            {
                name: 'Nova Aliança do Ivaí',
                uf: 'PR',
                code: 4116505,
            },
            {
                name: 'Nova América da Colina',
                uf: 'PR',
                code: 4116604,
            },
            {
                name: 'Nova Aurora',
                uf: 'PR',
                code: 4116703,
            },
            {
                name: 'Nova Cantu',
                uf: 'PR',
                code: 4116802,
            },
            {
                name: 'Nova Esperança',
                uf: 'PR',
                code: 4116901,
            },
            {
                name: 'Nova Esperança do Sudoeste',
                uf: 'PR',
                code: 4116950,
            },
            {
                name: 'Nova Fátima',
                uf: 'PR',
                code: 4117008,
            },
            {
                name: 'Nova Laranjeiras',
                uf: 'PR',
                code: 4117057,
            },
            {
                name: 'Nova Londrina',
                uf: 'PR',
                code: 4117107,
            },
            {
                name: 'Nova Olímpia',
                uf: 'PR',
                code: 4117206,
            },
            {
                name: 'Nova Prata do Iguaçu',
                uf: 'PR',
                code: 4117255,
            },
            {
                name: 'Nova Santa Bárbara',
                uf: 'PR',
                code: 4117214,
            },
            {
                name: 'Nova Santa Rosa',
                uf: 'PR',
                code: 4117222,
            },
            {
                name: 'Nova Tebas',
                uf: 'PR',
                code: 4117271,
            },
            {
                name: 'Novo Itacolomi',
                uf: 'PR',
                code: 4117297,
            },
            {
                name: 'Ortigueira',
                uf: 'PR',
                code: 4117305,
            },
            {
                name: 'Ourizona',
                uf: 'PR',
                code: 4117404,
            },
            {
                name: 'Ouro Verde do Oeste',
                uf: 'PR',
                code: 4117453,
            },
            {
                name: 'Paiçandu',
                uf: 'PR',
                code: 4117503,
            },
            {
                name: 'Palmas',
                uf: 'PR',
                code: 4117602,
            },
            {
                name: 'Palmeira',
                uf: 'PR',
                code: 4117701,
            },
            {
                name: 'Palmital',
                uf: 'PR',
                code: 4117800,
            },
            {
                name: 'Palotina',
                uf: 'PR',
                code: 4117909,
            },
            {
                name: 'Paraíso do Norte',
                uf: 'PR',
                code: 4118006,
            },
            {
                name: 'Paranacity',
                uf: 'PR',
                code: 4118105,
            },
            {
                name: 'Paranaguá',
                uf: 'PR',
                code: 4118204,
            },
            {
                name: 'Paranapoema',
                uf: 'PR',
                code: 4118303,
            },
            {
                name: 'Paranavaí',
                uf: 'PR',
                code: 4118402,
            },
            {
                name: 'Pato Bragado',
                uf: 'PR',
                code: 4118451,
            },
            {
                name: 'Pato Branco',
                uf: 'PR',
                code: 4118501,
            },
            {
                name: 'Paula Freitas',
                uf: 'PR',
                code: 4118600,
            },
            {
                name: 'Paulo Frontin',
                uf: 'PR',
                code: 4118709,
            },
            {
                name: 'Peabiru',
                uf: 'PR',
                code: 4118808,
            },
            {
                name: 'Perobal',
                uf: 'PR',
                code: 4118857,
            },
            {
                name: 'Pérola',
                uf: 'PR',
                code: 4118907,
            },
            {
                name: "Pérola d'Oeste",
                uf: 'PR',
                code: 4119004,
            },
            {
                name: 'Piên',
                uf: 'PR',
                code: 4119103,
            },
            {
                name: 'Pinhais',
                uf: 'PR',
                code: 4119152,
            },
            {
                name: 'Pinhal de São Bento',
                uf: 'PR',
                code: 4119251,
            },
            {
                name: 'Pinhalão',
                uf: 'PR',
                code: 4119202,
            },
            {
                name: 'Pinhão',
                uf: 'PR',
                code: 4119301,
            },
            {
                name: 'Piraí do Sul',
                uf: 'PR',
                code: 4119400,
            },
            {
                name: 'Piraquara',
                uf: 'PR',
                code: 4119509,
            },
            {
                name: 'Pitanga',
                uf: 'PR',
                code: 4119608,
            },
            {
                name: 'Pitangueiras',
                uf: 'PR',
                code: 4119657,
            },
            {
                name: 'Planaltina do Paraná',
                uf: 'PR',
                code: 4119707,
            },
            {
                name: 'Planalto',
                uf: 'PR',
                code: 4119806,
            },
            {
                name: 'Ponta Grossa',
                uf: 'PR',
                code: 4119905,
            },
            {
                name: 'Pontal do Paraná',
                uf: 'PR',
                code: 4119954,
            },
            {
                name: 'Porecatu',
                uf: 'PR',
                code: 4120002,
            },
            {
                name: 'Porto Amazonas',
                uf: 'PR',
                code: 4120101,
            },
            {
                name: 'Porto Barreiro',
                uf: 'PR',
                code: 4120150,
            },
            {
                name: 'Porto Rico',
                uf: 'PR',
                code: 4120200,
            },
            {
                name: 'Porto Vitória',
                uf: 'PR',
                code: 4120309,
            },
            {
                name: 'Prado Ferreira',
                uf: 'PR',
                code: 4120333,
            },
            {
                name: 'Pranchita',
                uf: 'PR',
                code: 4120358,
            },
            {
                name: 'Presidente Castelo Branco',
                uf: 'PR',
                code: 4120408,
            },
            {
                name: 'Primeiro de Maio',
                uf: 'PR',
                code: 4120507,
            },
            {
                name: 'Prudentópolis',
                uf: 'PR',
                code: 4120606,
            },
            {
                name: 'Quarto Centenário',
                uf: 'PR',
                code: 4120655,
            },
            {
                name: 'Quatiguá',
                uf: 'PR',
                code: 4120705,
            },
            {
                name: 'Quatro Barras',
                uf: 'PR',
                code: 4120804,
            },
            {
                name: 'Quatro Pontes',
                uf: 'PR',
                code: 4120853,
            },
            {
                name: 'Quedas do Iguaçu',
                uf: 'PR',
                code: 4120903,
            },
            {
                name: 'Querência do Norte',
                uf: 'PR',
                code: 4121000,
            },
            {
                name: 'Quinta do Sol',
                uf: 'PR',
                code: 4121109,
            },
            {
                name: 'Quitandinha',
                uf: 'PR',
                code: 4121208,
            },
            {
                name: 'Ramilândia',
                uf: 'PR',
                code: 4121257,
            },
            {
                name: 'Rancho Alegre',
                uf: 'PR',
                code: 4121307,
            },
            {
                name: "Rancho Alegre D'Oeste",
                uf: 'PR',
                code: 4121356,
            },
            {
                name: 'Realeza',
                uf: 'PR',
                code: 4121406,
            },
            {
                name: 'Rebouças',
                uf: 'PR',
                code: 4121505,
            },
            {
                name: 'Renascença',
                uf: 'PR',
                code: 4121604,
            },
            {
                name: 'Reserva',
                uf: 'PR',
                code: 4121703,
            },
            {
                name: 'Reserva do Iguaçu',
                uf: 'PR',
                code: 4121752,
            },
            {
                name: 'Ribeirão Claro',
                uf: 'PR',
                code: 4121802,
            },
            {
                name: 'Ribeirão do Pinhal',
                uf: 'PR',
                code: 4121901,
            },
            {
                name: 'Rio Azul',
                uf: 'PR',
                code: 4122008,
            },
            {
                name: 'Rio Bom',
                uf: 'PR',
                code: 4122107,
            },
            {
                name: 'Rio Bonito do Iguaçu',
                uf: 'PR',
                code: 4122156,
            },
            {
                name: 'Rio Branco do Ivaí',
                uf: 'PR',
                code: 4122172,
            },
            {
                name: 'Rio Branco do Sul',
                uf: 'PR',
                code: 4122206,
            },
            {
                name: 'Rio Negro',
                uf: 'PR',
                code: 4122305,
            },
            {
                name: 'Rolândia',
                uf: 'PR',
                code: 4122404,
            },
            {
                name: 'Roncador',
                uf: 'PR',
                code: 4122503,
            },
            {
                name: 'Rondon',
                uf: 'PR',
                code: 4122602,
            },
            {
                name: 'Rosário do Ivaí',
                uf: 'PR',
                code: 4122651,
            },
            {
                name: 'Sabáudia',
                uf: 'PR',
                code: 4122701,
            },
            {
                name: 'Salgado Filho',
                uf: 'PR',
                code: 4122800,
            },
            {
                name: 'Salto do Itararé',
                uf: 'PR',
                code: 4122909,
            },
            {
                name: 'Salto do Lontra',
                uf: 'PR',
                code: 4123006,
            },
            {
                name: 'Santa Amélia',
                uf: 'PR',
                code: 4123105,
            },
            {
                name: 'Santa Cecília do Pavão',
                uf: 'PR',
                code: 4123204,
            },
            {
                name: 'Santa Cruz de Monte Castelo',
                uf: 'PR',
                code: 4123303,
            },
            {
                name: 'Santa Fé',
                uf: 'PR',
                code: 4123402,
            },
            {
                name: 'Santa Helena',
                uf: 'PR',
                code: 4123501,
            },
            {
                name: 'Santa Inês',
                uf: 'PR',
                code: 4123600,
            },
            {
                name: 'Santa Isabel do Ivaí',
                uf: 'PR',
                code: 4123709,
            },
            {
                name: 'Santa Izabel do Oeste',
                uf: 'PR',
                code: 4123808,
            },
            {
                name: 'Santa Lúcia',
                uf: 'PR',
                code: 4123824,
            },
            {
                name: 'Santa Maria do Oeste',
                uf: 'PR',
                code: 4123857,
            },
            {
                name: 'Santa Mariana',
                uf: 'PR',
                code: 4123907,
            },
            {
                name: 'Santa Mônica',
                uf: 'PR',
                code: 4123956,
            },
            {
                name: 'Santa Tereza do Oeste',
                uf: 'PR',
                code: 4124020,
            },
            {
                name: 'Santa Terezinha de Itaipu',
                uf: 'PR',
                code: 4124053,
            },
            {
                name: 'Santana do Itararé',
                uf: 'PR',
                code: 4124004,
            },
            {
                name: 'Santo Antônio da Platina',
                uf: 'PR',
                code: 4124103,
            },
            {
                name: 'Santo Antônio do Caiuá',
                uf: 'PR',
                code: 4124202,
            },
            {
                name: 'Santo Antônio do Paraíso',
                uf: 'PR',
                code: 4124301,
            },
            {
                name: 'Santo Antônio do Sudoeste',
                uf: 'PR',
                code: 4124400,
            },
            {
                name: 'Santo Inácio',
                uf: 'PR',
                code: 4124509,
            },
            {
                name: 'São Carlos do Ivaí',
                uf: 'PR',
                code: 4124608,
            },
            {
                name: 'São Jerônimo da Serra',
                uf: 'PR',
                code: 4124707,
            },
            {
                name: 'São João',
                uf: 'PR',
                code: 4124806,
            },
            {
                name: 'São João do Caiuá',
                uf: 'PR',
                code: 4124905,
            },
            {
                name: 'São João do Ivaí',
                uf: 'PR',
                code: 4125001,
            },
            {
                name: 'São João do Triunfo',
                uf: 'PR',
                code: 4125100,
            },
            {
                name: "São Jorge d'Oeste",
                uf: 'PR',
                code: 4125209,
            },
            {
                name: 'São Jorge do Ivaí',
                uf: 'PR',
                code: 4125308,
            },
            {
                name: 'São Jorge do Patrocínio',
                uf: 'PR',
                code: 4125357,
            },
            {
                name: 'São José da Boa Vista',
                uf: 'PR',
                code: 4125407,
            },
            {
                name: 'São José das Palmeiras',
                uf: 'PR',
                code: 4125456,
            },
            {
                name: 'São José dos Pinhais',
                uf: 'PR',
                code: 4125506,
            },
            {
                name: 'São Manoel do Paraná',
                uf: 'PR',
                code: 4125555,
            },
            {
                name: 'São Mateus do Sul',
                uf: 'PR',
                code: 4125605,
            },
            {
                name: 'São Miguel do Iguaçu',
                uf: 'PR',
                code: 4125704,
            },
            {
                name: 'São Pedro do Iguaçu',
                uf: 'PR',
                code: 4125753,
            },
            {
                name: 'São Pedro do Ivaí',
                uf: 'PR',
                code: 4125803,
            },
            {
                name: 'São Pedro do Paraná',
                uf: 'PR',
                code: 4125902,
            },
            {
                name: 'São Sebastião da Amoreira',
                uf: 'PR',
                code: 4126009,
            },
            {
                name: 'São Tomé',
                uf: 'PR',
                code: 4126108,
            },
            {
                name: 'Sapopema',
                uf: 'PR',
                code: 4126207,
            },
            {
                name: 'Sarandi',
                uf: 'PR',
                code: 4126256,
            },
            {
                name: 'Saudade do Iguaçu',
                uf: 'PR',
                code: 4126272,
            },
            {
                name: 'Sengés',
                uf: 'PR',
                code: 4126306,
            },
            {
                name: 'Serranópolis do Iguaçu',
                uf: 'PR',
                code: 4126355,
            },
            {
                name: 'Sertaneja',
                uf: 'PR',
                code: 4126405,
            },
            {
                name: 'Sertanópolis',
                uf: 'PR',
                code: 4126504,
            },
            {
                name: 'Siqueira Campos',
                uf: 'PR',
                code: 4126603,
            },
            {
                name: 'Sulina',
                uf: 'PR',
                code: 4126652,
            },
            {
                name: 'Tamarana',
                uf: 'PR',
                code: 4126678,
            },
            {
                name: 'Tamboara',
                uf: 'PR',
                code: 4126702,
            },
            {
                name: 'Tapejara',
                uf: 'PR',
                code: 4126801,
            },
            {
                name: 'Tapira',
                uf: 'PR',
                code: 4126900,
            },
            {
                name: 'Teixeira Soares',
                uf: 'PR',
                code: 4127007,
            },
            {
                name: 'Telêmaco Borba',
                uf: 'PR',
                code: 4127106,
            },
            {
                name: 'Terra Boa',
                uf: 'PR',
                code: 4127205,
            },
            {
                name: 'Terra Rica',
                uf: 'PR',
                code: 4127304,
            },
            {
                name: 'Terra Roxa',
                uf: 'PR',
                code: 4127403,
            },
            {
                name: 'Tibagi',
                uf: 'PR',
                code: 4127502,
            },
            {
                name: 'Tijucas do Sul',
                uf: 'PR',
                code: 4127601,
            },
            {
                name: 'Toledo',
                uf: 'PR',
                code: 4127700,
            },
            {
                name: 'Tomazina',
                uf: 'PR',
                code: 4127809,
            },
            {
                name: 'Três Barras do Paraná',
                uf: 'PR',
                code: 4127858,
            },
            {
                name: 'Tunas do Paraná',
                uf: 'PR',
                code: 4127882,
            },
            {
                name: 'Tuneiras do Oeste',
                uf: 'PR',
                code: 4127908,
            },
            {
                name: 'Tupãssi',
                uf: 'PR',
                code: 4127957,
            },
            {
                name: 'Turvo',
                uf: 'PR',
                code: 4127965,
            },
            {
                name: 'Ubiratã',
                uf: 'PR',
                code: 4128005,
            },
            {
                name: 'Umuarama',
                uf: 'PR',
                code: 4128104,
            },
            {
                name: 'União da Vitória',
                uf: 'PR',
                code: 4128203,
            },
            {
                name: 'Uniflor',
                uf: 'PR',
                code: 4128302,
            },
            {
                name: 'Uraí',
                uf: 'PR',
                code: 4128401,
            },
            {
                name: 'Ventania',
                uf: 'PR',
                code: 4128534,
            },
            {
                name: 'Vera Cruz do Oeste',
                uf: 'PR',
                code: 4128559,
            },
            {
                name: 'Verê',
                uf: 'PR',
                code: 4128609,
            },
            {
                name: 'Virmond',
                uf: 'PR',
                code: 4128658,
            },
            {
                name: 'Vitorino',
                uf: 'PR',
                code: 4128708,
            },
            {
                name: 'Wenceslau Braz',
                uf: 'PR',
                code: 4128500,
            },
            {
                name: 'Xambrê',
                uf: 'PR',
                code: 4128807,
            },
            {
                name: 'Angra dos Reis',
                uf: 'RJ',
                code: 3300100,
            },
            {
                name: 'Aperibé',
                uf: 'RJ',
                code: 3300159,
            },
            {
                name: 'Araruama',
                uf: 'RJ',
                code: 3300209,
            },
            {
                name: 'Areal',
                uf: 'RJ',
                code: 3300225,
            },
            {
                name: 'Armação dos Búzios',
                uf: 'RJ',
                code: 3300233,
            },
            {
                name: 'Arraial do Cabo',
                uf: 'RJ',
                code: 3300258,
            },
            {
                name: 'Barra do Piraí',
                uf: 'RJ',
                code: 3300308,
            },
            {
                name: 'Barra Mansa',
                uf: 'RJ',
                code: 3300407,
            },
            {
                name: 'Belford Roxo',
                uf: 'RJ',
                code: 3300456,
            },
            {
                name: 'Bom Jardim',
                uf: 'RJ',
                code: 3300506,
            },
            {
                name: 'Bom Jesus do Itabapoana',
                uf: 'RJ',
                code: 3300605,
            },
            {
                name: 'Cabo Frio',
                uf: 'RJ',
                code: 3300704,
            },
            {
                name: 'Cachoeiras de Macacu',
                uf: 'RJ',
                code: 3300803,
            },
            {
                name: 'Cambuci',
                uf: 'RJ',
                code: 3300902,
            },
            {
                name: 'Campos dos Goytacazes',
                uf: 'RJ',
                code: 3301009,
            },
            {
                name: 'Cantagalo',
                uf: 'RJ',
                code: 3301108,
            },
            {
                name: 'Carapebus',
                uf: 'RJ',
                code: 3300936,
            },
            {
                name: 'Cardoso Moreira',
                uf: 'RJ',
                code: 3301157,
            },
            {
                name: 'Carmo',
                uf: 'RJ',
                code: 3301207,
            },
            {
                name: 'Casimiro de Abreu',
                uf: 'RJ',
                code: 3301306,
            },
            {
                name: 'Comendador Levy Gasparian',
                uf: 'RJ',
                code: 3300951,
            },
            {
                name: 'Conceição de Macabu',
                uf: 'RJ',
                code: 3301405,
            },
            {
                name: 'Cordeiro',
                uf: 'RJ',
                code: 3301504,
            },
            {
                name: 'Duas Barras',
                uf: 'RJ',
                code: 3301603,
            },
            {
                name: 'Duque de Caxias',
                uf: 'RJ',
                code: 3301702,
            },
            {
                name: 'Engenheiro Paulo de Frontin',
                uf: 'RJ',
                code: 3301801,
            },
            {
                name: 'Guapimirim',
                uf: 'RJ',
                code: 3301850,
            },
            {
                name: 'Iguaba Grande',
                uf: 'RJ',
                code: 3301876,
            },
            {
                name: 'Itaboraí',
                uf: 'RJ',
                code: 3301900,
            },
            {
                name: 'Itaguaí',
                uf: 'RJ',
                code: 3302007,
            },
            {
                name: 'Italva',
                uf: 'RJ',
                code: 3302056,
            },
            {
                name: 'Itaocara',
                uf: 'RJ',
                code: 3302106,
            },
            {
                name: 'Itaperuna',
                uf: 'RJ',
                code: 3302205,
            },
            {
                name: 'Itatiaia',
                uf: 'RJ',
                code: 3302254,
            },
            {
                name: 'Japeri',
                uf: 'RJ',
                code: 3302270,
            },
            {
                name: 'Laje do Muriaé',
                uf: 'RJ',
                code: 3302304,
            },
            {
                name: 'Macaé',
                uf: 'RJ',
                code: 3302403,
            },
            {
                name: 'Macuco',
                uf: 'RJ',
                code: 3302452,
            },
            {
                name: 'Magé',
                uf: 'RJ',
                code: 3302502,
            },
            {
                name: 'Mangaratiba',
                uf: 'RJ',
                code: 3302601,
            },
            {
                name: 'Maricá',
                uf: 'RJ',
                code: 3302700,
            },
            {
                name: 'Mendes',
                uf: 'RJ',
                code: 3302809,
            },
            {
                name: 'Mesquita',
                uf: 'RJ',
                code: 3302858,
            },
            {
                name: 'Miguel Pereira',
                uf: 'RJ',
                code: 3302908,
            },
            {
                name: 'Miracema',
                uf: 'RJ',
                code: 3303005,
            },
            {
                name: 'Natividade',
                uf: 'RJ',
                code: 3303104,
            },
            {
                name: 'Nilópolis',
                uf: 'RJ',
                code: 3303203,
            },
            {
                name: 'Niterói',
                uf: 'RJ',
                code: 3303302,
            },
            {
                name: 'Nova Friburgo',
                uf: 'RJ',
                code: 3303401,
            },
            {
                name: 'Nova Iguaçu',
                uf: 'RJ',
                code: 3303500,
            },
            {
                name: 'Paracambi',
                uf: 'RJ',
                code: 3303609,
            },
            {
                name: 'Paraíba do Sul',
                uf: 'RJ',
                code: 3303708,
            },
            {
                name: 'Paraty',
                uf: 'RJ',
                code: 3303807,
            },
            {
                name: 'Paty do Alferes',
                uf: 'RJ',
                code: 3303856,
            },
            {
                name: 'Petrópolis',
                uf: 'RJ',
                code: 3303906,
            },
            {
                name: 'Pinheiral',
                uf: 'RJ',
                code: 3303955,
            },
            {
                name: 'Piraí',
                uf: 'RJ',
                code: 3304003,
            },
            {
                name: 'Porciúncula',
                uf: 'RJ',
                code: 3304102,
            },
            {
                name: 'Porto Real',
                uf: 'RJ',
                code: 3304110,
            },
            {
                name: 'Quatis',
                uf: 'RJ',
                code: 3304128,
            },
            {
                name: 'Queimados',
                uf: 'RJ',
                code: 3304144,
            },
            {
                name: 'Quissamã',
                uf: 'RJ',
                code: 3304151,
            },
            {
                name: 'Resende',
                uf: 'RJ',
                code: 3304201,
            },
            {
                name: 'Rio Bonito',
                uf: 'RJ',
                code: 3304300,
            },
            {
                name: 'Rio Claro',
                uf: 'RJ',
                code: 3304409,
            },
            {
                name: 'Rio das Flores',
                uf: 'RJ',
                code: 3304508,
            },
            {
                name: 'Rio das Ostras',
                uf: 'RJ',
                code: 3304524,
            },
            {
                name: 'Rio de Janeiro',
                uf: 'RJ',
                code: 3304557,
            },
            {
                name: 'Santa Maria Madalena',
                uf: 'RJ',
                code: 3304607,
            },
            {
                name: 'Santo Antônio de Pádua',
                uf: 'RJ',
                code: 3304706,
            },
            {
                name: 'São Fidélis',
                uf: 'RJ',
                code: 3304805,
            },
            {
                name: 'São Francisco de Itabapoana',
                uf: 'RJ',
                code: 3304755,
            },
            {
                name: 'São Gonçalo',
                uf: 'RJ',
                code: 3304904,
            },
            {
                name: 'São João da Barra',
                uf: 'RJ',
                code: 3305000,
            },
            {
                name: 'São João de Meriti',
                uf: 'RJ',
                code: 3305109,
            },
            {
                name: 'São José de Ubá',
                uf: 'RJ',
                code: 3305133,
            },
            {
                name: 'São José do Vale do Rio Preto',
                uf: 'RJ',
                code: 3305158,
            },
            {
                name: 'São Pedro da Aldeia',
                uf: 'RJ',
                code: 3305208,
            },
            {
                name: 'São Sebastião do Alto',
                uf: 'RJ',
                code: 3305307,
            },
            {
                name: 'Sapucaia',
                uf: 'RJ',
                code: 3305406,
            },
            {
                name: 'Saquarema',
                uf: 'RJ',
                code: 3305505,
            },
            {
                name: 'Seropédica',
                uf: 'RJ',
                code: 3305554,
            },
            {
                name: 'Silva Jardim',
                uf: 'RJ',
                code: 3305604,
            },
            {
                name: 'Sumidouro',
                uf: 'RJ',
                code: 3305703,
            },
            {
                name: 'Tanguá',
                uf: 'RJ',
                code: 3305752,
            },
            {
                name: 'Teresópolis',
                uf: 'RJ',
                code: 3305802,
            },
            {
                name: 'Trajano de Moraes',
                uf: 'RJ',
                code: 3305901,
            },
            {
                name: 'Três Rios',
                uf: 'RJ',
                code: 3306008,
            },
            {
                name: 'Valença',
                uf: 'RJ',
                code: 3306107,
            },
            {
                name: 'Varre-Sai',
                uf: 'RJ',
                code: 3306156,
            },
            {
                name: 'Vassouras',
                uf: 'RJ',
                code: 3306206,
            },
            {
                name: 'Volta Redonda',
                uf: 'RJ',
                code: 3306305,
            },
            {
                name: 'Acari',
                uf: 'RN',
                code: 2400109,
            },
            {
                name: 'Açu',
                uf: 'RN',
                code: 2400208,
            },
            {
                name: 'Afonso Bezerra',
                uf: 'RN',
                code: 2400307,
            },
            {
                name: 'Água Nova',
                uf: 'RN',
                code: 2400406,
            },
            {
                name: 'Alexandria',
                uf: 'RN',
                code: 2400505,
            },
            {
                name: 'Almino Afonso',
                uf: 'RN',
                code: 2400604,
            },
            {
                name: 'Alto do Rodrigues',
                uf: 'RN',
                code: 2400703,
            },
            {
                name: 'Angicos',
                uf: 'RN',
                code: 2400802,
            },
            {
                name: 'Antônio Martins',
                uf: 'RN',
                code: 2400901,
            },
            {
                name: 'Apodi',
                uf: 'RN',
                code: 2401008,
            },
            {
                name: 'Areia Branca',
                uf: 'RN',
                code: 2401107,
            },
            {
                name: 'Arês',
                uf: 'RN',
                code: 2401206,
            },
            {
                name: 'Augusto Severo',
                uf: 'RN',
                code: 2401305,
            },
            {
                name: 'Baía Formosa',
                uf: 'RN',
                code: 2401404,
            },
            {
                name: 'Baraúna',
                uf: 'RN',
                code: 2401453,
            },
            {
                name: 'Barcelona',
                uf: 'RN',
                code: 2401503,
            },
            {
                name: 'Bento Fernandes',
                uf: 'RN',
                code: 2401602,
            },
            {
                name: 'Bodó',
                uf: 'RN',
                code: 2401651,
            },
            {
                name: 'Bom Jesus',
                uf: 'RN',
                code: 2401701,
            },
            {
                name: 'Brejinho',
                uf: 'RN',
                code: 2401800,
            },
            {
                name: 'Caiçara do Norte',
                uf: 'RN',
                code: 2401859,
            },
            {
                name: 'Caiçara do Rio do Vento',
                uf: 'RN',
                code: 2401909,
            },
            {
                name: 'Caicó',
                uf: 'RN',
                code: 2402006,
            },
            {
                name: 'Campo Redondo',
                uf: 'RN',
                code: 2402105,
            },
            {
                name: 'Canguaretama',
                uf: 'RN',
                code: 2402204,
            },
            {
                name: 'Caraúbas',
                uf: 'RN',
                code: 2402303,
            },
            {
                name: 'Carnaúba dos Dantas',
                uf: 'RN',
                code: 2402402,
            },
            {
                name: 'Carnaubais',
                uf: 'RN',
                code: 2402501,
            },
            {
                name: 'Ceará-Mirim',
                uf: 'RN',
                code: 2402600,
            },
            {
                name: 'Cerro Corá',
                uf: 'RN',
                code: 2402709,
            },
            {
                name: 'Coronel Ezequiel',
                uf: 'RN',
                code: 2402808,
            },
            {
                name: 'Coronel João Pessoa',
                uf: 'RN',
                code: 2402907,
            },
            {
                name: 'Cruzeta',
                uf: 'RN',
                code: 2403004,
            },
            {
                name: 'Currais Novos',
                uf: 'RN',
                code: 2403103,
            },
            {
                name: 'Doutor Severiano',
                uf: 'RN',
                code: 2403202,
            },
            {
                name: 'Encanto',
                uf: 'RN',
                code: 2403301,
            },
            {
                name: 'Equador',
                uf: 'RN',
                code: 2403400,
            },
            {
                name: 'Espírito Santo',
                uf: 'RN',
                code: 2403509,
            },
            {
                name: 'Extremoz',
                uf: 'RN',
                code: 2403608,
            },
            {
                name: 'Felipe Guerra',
                uf: 'RN',
                code: 2403707,
            },
            {
                name: 'Fernando Pedroza',
                uf: 'RN',
                code: 2403756,
            },
            {
                name: 'Florânia',
                uf: 'RN',
                code: 2403806,
            },
            {
                name: 'Francisco Dantas',
                uf: 'RN',
                code: 2403905,
            },
            {
                name: 'Frutuoso Gomes',
                uf: 'RN',
                code: 2404002,
            },
            {
                name: 'Galinhos',
                uf: 'RN',
                code: 2404101,
            },
            {
                name: 'Goianinha',
                uf: 'RN',
                code: 2404200,
            },
            {
                name: 'Governador Dix-Sept Rosado',
                uf: 'RN',
                code: 2404309,
            },
            {
                name: 'Grossos',
                uf: 'RN',
                code: 2404408,
            },
            {
                name: 'Guamaré',
                uf: 'RN',
                code: 2404507,
            },
            {
                name: 'Ielmo Marinho',
                uf: 'RN',
                code: 2404606,
            },
            {
                name: 'Ipanguaçu',
                uf: 'RN',
                code: 2404705,
            },
            {
                name: 'Ipueira',
                uf: 'RN',
                code: 2404804,
            },
            {
                name: 'Itajá',
                uf: 'RN',
                code: 2404853,
            },
            {
                name: 'Itaú',
                uf: 'RN',
                code: 2404903,
            },
            {
                name: 'Jaçanã',
                uf: 'RN',
                code: 2405009,
            },
            {
                name: 'Jandaíra',
                uf: 'RN',
                code: 2405108,
            },
            {
                name: 'Janduís',
                uf: 'RN',
                code: 2405207,
            },
            {
                name: 'Januário Cicco',
                uf: 'RN',
                code: 2405306,
            },
            {
                name: 'Japi',
                uf: 'RN',
                code: 2405405,
            },
            {
                name: 'Jardim de Angicos',
                uf: 'RN',
                code: 2405504,
            },
            {
                name: 'Jardim de Piranhas',
                uf: 'RN',
                code: 2405603,
            },
            {
                name: 'Jardim do Seridó',
                uf: 'RN',
                code: 2405702,
            },
            {
                name: 'João Câmara',
                uf: 'RN',
                code: 2405801,
            },
            {
                name: 'João Dias',
                uf: 'RN',
                code: 2405900,
            },
            {
                name: 'José da Penha',
                uf: 'RN',
                code: 2406007,
            },
            {
                name: 'Jucurutu',
                uf: 'RN',
                code: 2406106,
            },
            {
                name: 'Jundiá',
                uf: 'RN',
                code: 2406155,
            },
            {
                name: "Lagoa d'Anta",
                uf: 'RN',
                code: 2406205,
            },
            {
                name: 'Lagoa de Pedras',
                uf: 'RN',
                code: 2406304,
            },
            {
                name: 'Lagoa de Velhos',
                uf: 'RN',
                code: 2406403,
            },
            {
                name: 'Lagoa Nova',
                uf: 'RN',
                code: 2406502,
            },
            {
                name: 'Lagoa Salgada',
                uf: 'RN',
                code: 2406601,
            },
            {
                name: 'Lajes',
                uf: 'RN',
                code: 2406700,
            },
            {
                name: 'Lajes Pintadas',
                uf: 'RN',
                code: 2406809,
            },
            {
                name: 'Lucrécia',
                uf: 'RN',
                code: 2406908,
            },
            {
                name: 'Luís Gomes',
                uf: 'RN',
                code: 2407005,
            },
            {
                name: 'Macaíba',
                uf: 'RN',
                code: 2407104,
            },
            {
                name: 'Macau',
                uf: 'RN',
                code: 2407203,
            },
            {
                name: 'Major Sales',
                uf: 'RN',
                code: 2407252,
            },
            {
                name: 'Marcelino Vieira',
                uf: 'RN',
                code: 2407302,
            },
            {
                name: 'Martins',
                uf: 'RN',
                code: 2407401,
            },
            {
                name: 'Maxaranguape',
                uf: 'RN',
                code: 2407500,
            },
            {
                name: 'Messias Targino',
                uf: 'RN',
                code: 2407609,
            },
            {
                name: 'Montanhas',
                uf: 'RN',
                code: 2407708,
            },
            {
                name: 'Monte Alegre',
                uf: 'RN',
                code: 2407807,
            },
            {
                name: 'Monte das Gameleiras',
                uf: 'RN',
                code: 2407906,
            },
            {
                name: 'Mossoró',
                uf: 'RN',
                code: 2408003,
            },
            {
                name: 'Natal',
                uf: 'RN',
                code: 2408102,
            },
            {
                name: 'Nísia Floresta',
                uf: 'RN',
                code: 2408201,
            },
            {
                name: 'Nova Cruz',
                uf: 'RN',
                code: 2408300,
            },
            {
                name: "Olho-d'Água do Borges",
                uf: 'RN',
                code: 2408409,
            },
            {
                name: 'Ouro Branco',
                uf: 'RN',
                code: 2408508,
            },
            {
                name: 'Paraná',
                uf: 'RN',
                code: 2408607,
            },
            {
                name: 'Paraú',
                uf: 'RN',
                code: 2408706,
            },
            {
                name: 'Parazinho',
                uf: 'RN',
                code: 2408805,
            },
            {
                name: 'Parelhas',
                uf: 'RN',
                code: 2408904,
            },
            {
                name: 'Parnamirim',
                uf: 'RN',
                code: 2403251,
            },
            {
                name: 'Passa e Fica',
                uf: 'RN',
                code: 2409100,
            },
            {
                name: 'Passagem',
                uf: 'RN',
                code: 2409209,
            },
            {
                name: 'Patu',
                uf: 'RN',
                code: 2409308,
            },
            {
                name: 'Pau dos Ferros',
                uf: 'RN',
                code: 2409407,
            },
            {
                name: 'Pedra Grande',
                uf: 'RN',
                code: 2409506,
            },
            {
                name: 'Pedra Preta',
                uf: 'RN',
                code: 2409605,
            },
            {
                name: 'Pedro Avelino',
                uf: 'RN',
                code: 2409704,
            },
            {
                name: 'Pedro Velho',
                uf: 'RN',
                code: 2409803,
            },
            {
                name: 'Pendências',
                uf: 'RN',
                code: 2409902,
            },
            {
                name: 'Pilões',
                uf: 'RN',
                code: 2410009,
            },
            {
                name: 'Poço Branco',
                uf: 'RN',
                code: 2410108,
            },
            {
                name: 'Portalegre',
                uf: 'RN',
                code: 2410207,
            },
            {
                name: 'Porto do Mangue',
                uf: 'RN',
                code: 2410256,
            },
            {
                name: 'Pureza',
                uf: 'RN',
                code: 2410405,
            },
            {
                name: 'Rafael Fernandes',
                uf: 'RN',
                code: 2410504,
            },
            {
                name: 'Rafael Godeiro',
                uf: 'RN',
                code: 2410603,
            },
            {
                name: 'Riacho da Cruz',
                uf: 'RN',
                code: 2410702,
            },
            {
                name: 'Riacho de Santana',
                uf: 'RN',
                code: 2410801,
            },
            {
                name: 'Riachuelo',
                uf: 'RN',
                code: 2410900,
            },
            {
                name: 'Rio do Fogo',
                uf: 'RN',
                code: 2408953,
            },
            {
                name: 'Rodolfo Fernandes',
                uf: 'RN',
                code: 2411007,
            },
            {
                name: 'Ruy Barbosa',
                uf: 'RN',
                code: 2411106,
            },
            {
                name: 'Santa Cruz',
                uf: 'RN',
                code: 2411205,
            },
            {
                name: 'Santa Maria',
                uf: 'RN',
                code: 2409332,
            },
            {
                name: 'Santana do Matos',
                uf: 'RN',
                code: 2411403,
            },
            {
                name: 'Santana do Seridó',
                uf: 'RN',
                code: 2411429,
            },
            {
                name: 'Santo Antônio',
                uf: 'RN',
                code: 2411502,
            },
            {
                name: 'São Bento do Norte',
                uf: 'RN',
                code: 2411601,
            },
            {
                name: 'São Bento do Trairí',
                uf: 'RN',
                code: 2411700,
            },
            {
                name: 'São Fernando',
                uf: 'RN',
                code: 2411809,
            },
            {
                name: 'São Francisco do Oeste',
                uf: 'RN',
                code: 2411908,
            },
            {
                name: 'São Gonçalo do Amarante',
                uf: 'RN',
                code: 2412005,
            },
            {
                name: 'São João do Sabugi',
                uf: 'RN',
                code: 2412104,
            },
            {
                name: 'São José de Mipibu',
                uf: 'RN',
                code: 2412203,
            },
            {
                name: 'São José do Campestre',
                uf: 'RN',
                code: 2412302,
            },
            {
                name: 'São José do Seridó',
                uf: 'RN',
                code: 2412401,
            },
            {
                name: 'São Miguel',
                uf: 'RN',
                code: 2412500,
            },
            {
                name: 'São Miguel do Gostoso',
                uf: 'RN',
                code: 2412559,
            },
            {
                name: 'São Paulo do Potengi',
                uf: 'RN',
                code: 2412609,
            },
            {
                name: 'São Pedro',
                uf: 'RN',
                code: 2412708,
            },
            {
                name: 'São Rafael',
                uf: 'RN',
                code: 2412807,
            },
            {
                name: 'São Tomé',
                uf: 'RN',
                code: 2412906,
            },
            {
                name: 'São Vicente',
                uf: 'RN',
                code: 2413003,
            },
            {
                name: 'Senador Elói de Souza',
                uf: 'RN',
                code: 2413102,
            },
            {
                name: 'Senador Georgino Avelino',
                uf: 'RN',
                code: 2413201,
            },
            {
                name: 'Serra Caiada',
                uf: 'RN',
                code: 2410306,
            },
            {
                name: 'Serra de São Bento',
                uf: 'RN',
                code: 2413300,
            },
            {
                name: 'Serra do Mel',
                uf: 'RN',
                code: 2413359,
            },
            {
                name: 'Serra Negra do Norte',
                uf: 'RN',
                code: 2413409,
            },
            {
                name: 'Serrinha',
                uf: 'RN',
                code: 2413508,
            },
            {
                name: 'Serrinha dos Pintos',
                uf: 'RN',
                code: 2413557,
            },
            {
                name: 'Severiano Melo',
                uf: 'RN',
                code: 2413607,
            },
            {
                name: 'Sítio Novo',
                uf: 'RN',
                code: 2413706,
            },
            {
                name: 'Taboleiro Grande',
                uf: 'RN',
                code: 2413805,
            },
            {
                name: 'Taipu',
                uf: 'RN',
                code: 2413904,
            },
            {
                name: 'Tangará',
                uf: 'RN',
                code: 2414001,
            },
            {
                name: 'Tenente Ananias',
                uf: 'RN',
                code: 2414100,
            },
            {
                name: 'Tenente Laurentino Cruz',
                uf: 'RN',
                code: 2414159,
            },
            {
                name: 'Tibau',
                uf: 'RN',
                code: 2411056,
            },
            {
                name: 'Tibau do Sul',
                uf: 'RN',
                code: 2414209,
            },
            {
                name: 'Timbaúba dos Batistas',
                uf: 'RN',
                code: 2414308,
            },
            {
                name: 'Touros',
                uf: 'RN',
                code: 2414407,
            },
            {
                name: 'Triunfo Potiguar',
                uf: 'RN',
                code: 2414456,
            },
            {
                name: 'Umarizal',
                uf: 'RN',
                code: 2414506,
            },
            {
                name: 'Upanema',
                uf: 'RN',
                code: 2414605,
            },
            {
                name: 'Várzea',
                uf: 'RN',
                code: 2414704,
            },
            {
                name: 'Venha-Ver',
                uf: 'RN',
                code: 2414753,
            },
            {
                name: 'Vera Cruz',
                uf: 'RN',
                code: 2414803,
            },
            {
                name: 'Viçosa',
                uf: 'RN',
                code: 2414902,
            },
            {
                name: 'Vila Flor',
                uf: 'RN',
                code: 2415008,
            },
            {
                name: "Alta Floresta D'Oeste",
                uf: 'RO',
                code: 1100015,
            },
            {
                name: 'Alto Alegre dos Parecis',
                uf: 'RO',
                code: 1100379,
            },
            {
                name: 'Alto Paraíso',
                uf: 'RO',
                code: 1100403,
            },
            {
                name: "Alvorada D'Oeste",
                uf: 'RO',
                code: 1100346,
            },
            {
                name: 'Ariquemes',
                uf: 'RO',
                code: 1100023,
            },
            {
                name: 'Buritis',
                uf: 'RO',
                code: 1100452,
            },
            {
                name: 'Cabixi',
                uf: 'RO',
                code: 1100031,
            },
            {
                name: 'Cacaulândia',
                uf: 'RO',
                code: 1100601,
            },
            {
                name: 'Cacoal',
                uf: 'RO',
                code: 1100049,
            },
            {
                name: 'Campo Novo de Rondônia',
                uf: 'RO',
                code: 1100700,
            },
            {
                name: 'Candeias do Jamari',
                uf: 'RO',
                code: 1100809,
            },
            {
                name: 'Castanheiras',
                uf: 'RO',
                code: 1100908,
            },
            {
                name: 'Cerejeiras',
                uf: 'RO',
                code: 1100056,
            },
            {
                name: 'Chupinguaia',
                uf: 'RO',
                code: 1100924,
            },
            {
                name: 'Colorado do Oeste',
                uf: 'RO',
                code: 1100064,
            },
            {
                name: 'Corumbiara',
                uf: 'RO',
                code: 1100072,
            },
            {
                name: 'Costa Marques',
                uf: 'RO',
                code: 1100080,
            },
            {
                name: 'Cujubim',
                uf: 'RO',
                code: 1100940,
            },
            {
                name: "Espigão D'Oeste",
                uf: 'RO',
                code: 1100098,
            },
            {
                name: 'Governador Jorge Teixeira',
                uf: 'RO',
                code: 1101005,
            },
            {
                name: 'Guajará-Mirim',
                uf: 'RO',
                code: 1100106,
            },
            {
                name: 'Itapuã do Oeste',
                uf: 'RO',
                code: 1101104,
            },
            {
                name: 'Jaru',
                uf: 'RO',
                code: 1100114,
            },
            {
                name: 'Ji-Paraná',
                uf: 'RO',
                code: 1100122,
            },
            {
                name: "Machadinho D'Oeste",
                uf: 'RO',
                code: 1100130,
            },
            {
                name: 'Ministro Andreazza',
                uf: 'RO',
                code: 1101203,
            },
            {
                name: 'Mirante da Serra',
                uf: 'RO',
                code: 1101302,
            },
            {
                name: 'Monte Negro',
                uf: 'RO',
                code: 1101401,
            },
            {
                name: "Nova Brasilândia D'Oeste",
                uf: 'RO',
                code: 1100148,
            },
            {
                name: 'Nova Mamoré',
                uf: 'RO',
                code: 1100338,
            },
            {
                name: 'Nova União',
                uf: 'RO',
                code: 1101435,
            },
            {
                name: 'Novo Horizonte do Oeste',
                uf: 'RO',
                code: 1100502,
            },
            {
                name: 'Ouro Preto do Oeste',
                uf: 'RO',
                code: 1100155,
            },
            {
                name: 'Parecis',
                uf: 'RO',
                code: 1101450,
            },
            {
                name: 'Pimenta Bueno',
                uf: 'RO',
                code: 1100189,
            },
            {
                name: 'Pimenteiras do Oeste',
                uf: 'RO',
                code: 1101468,
            },
            {
                name: 'Porto Velho',
                uf: 'RO',
                code: 1100205,
            },
            {
                name: 'Presidente Médici',
                uf: 'RO',
                code: 1100254,
            },
            {
                name: 'Primavera de Rondônia',
                uf: 'RO',
                code: 1101476,
            },
            {
                name: 'Rio Crespo',
                uf: 'RO',
                code: 1100262,
            },
            {
                name: 'Rolim de Moura',
                uf: 'RO',
                code: 1100288,
            },
            {
                name: "Santa Luzia D'Oeste",
                uf: 'RO',
                code: 1100296,
            },
            {
                name: "São Felipe D'Oeste",
                uf: 'RO',
                code: 1101484,
            },
            {
                name: 'São Francisco do Guaporé',
                uf: 'RO',
                code: 1101492,
            },
            {
                name: 'São Miguel do Guaporé',
                uf: 'RO',
                code: 1100320,
            },
            {
                name: 'Seringueiras',
                uf: 'RO',
                code: 1101500,
            },
            {
                name: 'Teixeirópolis',
                uf: 'RO',
                code: 1101559,
            },
            {
                name: 'Theobroma',
                uf: 'RO',
                code: 1101609,
            },
            {
                name: 'Urupá',
                uf: 'RO',
                code: 1101708,
            },
            {
                name: 'Vale do Anari',
                uf: 'RO',
                code: 1101757,
            },
            {
                name: 'Vale do Paraíso',
                uf: 'RO',
                code: 1101807,
            },
            {
                name: 'Vilhena',
                uf: 'RO',
                code: 1100304,
            },
            {
                name: 'Alto Alegre',
                uf: 'RR',
                code: 1400050,
            },
            {
                name: 'Amajari',
                uf: 'RR',
                code: 1400027,
            },
            {
                name: 'Boa Vista',
                uf: 'RR',
                code: 1400100,
            },
            {
                name: 'Bonfim',
                uf: 'RR',
                code: 1400159,
            },
            {
                name: 'Cantá',
                uf: 'RR',
                code: 1400175,
            },
            {
                name: 'Caracaraí',
                uf: 'RR',
                code: 1400209,
            },
            {
                name: 'Caroebe',
                uf: 'RR',
                code: 1400233,
            },
            {
                name: 'Iracema',
                uf: 'RR',
                code: 1400282,
            },
            {
                name: 'Mucajaí',
                uf: 'RR',
                code: 1400308,
            },
            {
                name: 'Normandia',
                uf: 'RR',
                code: 1400407,
            },
            {
                name: 'Pacaraima',
                uf: 'RR',
                code: 1400456,
            },
            {
                name: 'Rorainópolis',
                uf: 'RR',
                code: 1400472,
            },
            {
                name: 'São João da Baliza',
                uf: 'RR',
                code: 1400506,
            },
            {
                name: 'São Luiz',
                uf: 'RR',
                code: 1400605,
            },
            {
                name: 'Uiramutã',
                uf: 'RR',
                code: 1400704,
            },
            {
                name: 'Aceguá',
                uf: 'RS',
                code: 4300034,
            },
            {
                name: 'Água Santa',
                uf: 'RS',
                code: 4300059,
            },
            {
                name: 'Agudo',
                uf: 'RS',
                code: 4300109,
            },
            {
                name: 'Ajuricaba',
                uf: 'RS',
                code: 4300208,
            },
            {
                name: 'Alecrim',
                uf: 'RS',
                code: 4300307,
            },
            {
                name: 'Alegrete',
                uf: 'RS',
                code: 4300406,
            },
            {
                name: 'Alegria',
                uf: 'RS',
                code: 4300455,
            },
            {
                name: 'Almirante Tamandaré do Sul',
                uf: 'RS',
                code: 4300471,
            },
            {
                name: 'Alpestre',
                uf: 'RS',
                code: 4300505,
            },
            {
                name: 'Alto Alegre',
                uf: 'RS',
                code: 4300554,
            },
            {
                name: 'Alto Feliz',
                uf: 'RS',
                code: 4300570,
            },
            {
                name: 'Alvorada',
                uf: 'RS',
                code: 4300604,
            },
            {
                name: 'Amaral Ferrador',
                uf: 'RS',
                code: 4300638,
            },
            {
                name: 'Ametista do Sul',
                uf: 'RS',
                code: 4300646,
            },
            {
                name: 'André da Rocha',
                uf: 'RS',
                code: 4300661,
            },
            {
                name: 'Anta Gorda',
                uf: 'RS',
                code: 4300703,
            },
            {
                name: 'Antônio Prado',
                uf: 'RS',
                code: 4300802,
            },
            {
                name: 'Arambaré',
                uf: 'RS',
                code: 4300851,
            },
            {
                name: 'Araricá',
                uf: 'RS',
                code: 4300877,
            },
            {
                name: 'Aratiba',
                uf: 'RS',
                code: 4300901,
            },
            {
                name: 'Arroio do Meio',
                uf: 'RS',
                code: 4301008,
            },
            {
                name: 'Arroio do Padre',
                uf: 'RS',
                code: 4301073,
            },
            {
                name: 'Arroio do Sal',
                uf: 'RS',
                code: 4301057,
            },
            {
                name: 'Arroio do Tigre',
                uf: 'RS',
                code: 4301206,
            },
            {
                name: 'Arroio dos Ratos',
                uf: 'RS',
                code: 4301107,
            },
            {
                name: 'Arroio Grande',
                uf: 'RS',
                code: 4301305,
            },
            {
                name: 'Arvorezinha',
                uf: 'RS',
                code: 4301404,
            },
            {
                name: 'Augusto Pestana',
                uf: 'RS',
                code: 4301503,
            },
            {
                name: 'Áurea',
                uf: 'RS',
                code: 4301552,
            },
            {
                name: 'Bagé',
                uf: 'RS',
                code: 4301602,
            },
            {
                name: 'Balneário Pinhal',
                uf: 'RS',
                code: 4301636,
            },
            {
                name: 'Barão',
                uf: 'RS',
                code: 4301651,
            },
            {
                name: 'Barão de Cotegipe',
                uf: 'RS',
                code: 4301701,
            },
            {
                name: 'Barão do Triunfo',
                uf: 'RS',
                code: 4301750,
            },
            {
                name: 'Barra do Guarita',
                uf: 'RS',
                code: 4301859,
            },
            {
                name: 'Barra do Quaraí',
                uf: 'RS',
                code: 4301875,
            },
            {
                name: 'Barra do Ribeiro',
                uf: 'RS',
                code: 4301909,
            },
            {
                name: 'Barra do Rio Azul',
                uf: 'RS',
                code: 4301925,
            },
            {
                name: 'Barra Funda',
                uf: 'RS',
                code: 4301958,
            },
            {
                name: 'Barracão',
                uf: 'RS',
                code: 4301800,
            },
            {
                name: 'Barros Cassal',
                uf: 'RS',
                code: 4302006,
            },
            {
                name: 'Benjamin Constant do Sul',
                uf: 'RS',
                code: 4302055,
            },
            {
                name: 'Bento Gonçalves',
                uf: 'RS',
                code: 4302105,
            },
            {
                name: 'Boa Vista das Missões',
                uf: 'RS',
                code: 4302154,
            },
            {
                name: 'Boa Vista do Buricá',
                uf: 'RS',
                code: 4302204,
            },
            {
                name: 'Boa Vista do Cadeado',
                uf: 'RS',
                code: 4302220,
            },
            {
                name: 'Boa Vista do Incra',
                uf: 'RS',
                code: 4302238,
            },
            {
                name: 'Boa Vista do Sul',
                uf: 'RS',
                code: 4302253,
            },
            {
                name: 'Bom Jesus',
                uf: 'RS',
                code: 4302303,
            },
            {
                name: 'Bom Princípio',
                uf: 'RS',
                code: 4302352,
            },
            {
                name: 'Bom Progresso',
                uf: 'RS',
                code: 4302378,
            },
            {
                name: 'Bom Retiro do Sul',
                uf: 'RS',
                code: 4302402,
            },
            {
                name: 'Boqueirão do Leão',
                uf: 'RS',
                code: 4302451,
            },
            {
                name: 'Bossoroca',
                uf: 'RS',
                code: 4302501,
            },
            {
                name: 'Bozano',
                uf: 'RS',
                code: 4302584,
            },
            {
                name: 'Braga',
                uf: 'RS',
                code: 4302600,
            },
            {
                name: 'Brochier',
                uf: 'RS',
                code: 4302659,
            },
            {
                name: 'Butiá',
                uf: 'RS',
                code: 4302709,
            },
            {
                name: 'Caçapava do Sul',
                uf: 'RS',
                code: 4302808,
            },
            {
                name: 'Cacequi',
                uf: 'RS',
                code: 4302907,
            },
            {
                name: 'Cachoeira do Sul',
                uf: 'RS',
                code: 4303004,
            },
            {
                name: 'Cachoeirinha',
                uf: 'RS',
                code: 4303103,
            },
            {
                name: 'Cacique Doble',
                uf: 'RS',
                code: 4303202,
            },
            {
                name: 'Caibaté',
                uf: 'RS',
                code: 4303301,
            },
            {
                name: 'Caiçara',
                uf: 'RS',
                code: 4303400,
            },
            {
                name: 'Camaquã',
                uf: 'RS',
                code: 4303509,
            },
            {
                name: 'Camargo',
                uf: 'RS',
                code: 4303558,
            },
            {
                name: 'Cambará do Sul',
                uf: 'RS',
                code: 4303608,
            },
            {
                name: 'Campestre da Serra',
                uf: 'RS',
                code: 4303673,
            },
            {
                name: 'Campina das Missões',
                uf: 'RS',
                code: 4303707,
            },
            {
                name: 'Campinas do Sul',
                uf: 'RS',
                code: 4303806,
            },
            {
                name: 'Campo Bom',
                uf: 'RS',
                code: 4303905,
            },
            {
                name: 'Campo Novo',
                uf: 'RS',
                code: 4304002,
            },
            {
                name: 'Campos Borges',
                uf: 'RS',
                code: 4304101,
            },
            {
                name: 'Candelária',
                uf: 'RS',
                code: 4304200,
            },
            {
                name: 'Cândido Godói',
                uf: 'RS',
                code: 4304309,
            },
            {
                name: 'Candiota',
                uf: 'RS',
                code: 4304358,
            },
            {
                name: 'Canela',
                uf: 'RS',
                code: 4304408,
            },
            {
                name: 'Canguçu',
                uf: 'RS',
                code: 4304507,
            },
            {
                name: 'Canoas',
                uf: 'RS',
                code: 4304606,
            },
            {
                name: 'Canudos do Vale',
                uf: 'RS',
                code: 4304614,
            },
            {
                name: 'Capão Bonito do Sul',
                uf: 'RS',
                code: 4304622,
            },
            {
                name: 'Capão da Canoa',
                uf: 'RS',
                code: 4304630,
            },
            {
                name: 'Capão do Cipó',
                uf: 'RS',
                code: 4304655,
            },
            {
                name: 'Capão do Leão',
                uf: 'RS',
                code: 4304663,
            },
            {
                name: 'Capela de Santana',
                uf: 'RS',
                code: 4304689,
            },
            {
                name: 'Capitão',
                uf: 'RS',
                code: 4304697,
            },
            {
                name: 'Capivari do Sul',
                uf: 'RS',
                code: 4304671,
            },
            {
                name: 'Caraá',
                uf: 'RS',
                code: 4304713,
            },
            {
                name: 'Carazinho',
                uf: 'RS',
                code: 4304705,
            },
            {
                name: 'Carlos Barbosa',
                uf: 'RS',
                code: 4304804,
            },
            {
                name: 'Carlos Gomes',
                uf: 'RS',
                code: 4304853,
            },
            {
                name: 'Casca',
                uf: 'RS',
                code: 4304903,
            },
            {
                name: 'Caseiros',
                uf: 'RS',
                code: 4304952,
            },
            {
                name: 'Catuípe',
                uf: 'RS',
                code: 4305009,
            },
            {
                name: 'Caxias do Sul',
                uf: 'RS',
                code: 4305108,
            },
            {
                name: 'Centenário',
                uf: 'RS',
                code: 4305116,
            },
            {
                name: 'Cerrito',
                uf: 'RS',
                code: 4305124,
            },
            {
                name: 'Cerro Branco',
                uf: 'RS',
                code: 4305132,
            },
            {
                name: 'Cerro Grande',
                uf: 'RS',
                code: 4305157,
            },
            {
                name: 'Cerro Grande do Sul',
                uf: 'RS',
                code: 4305173,
            },
            {
                name: 'Cerro Largo',
                uf: 'RS',
                code: 4305207,
            },
            {
                name: 'Chapada',
                uf: 'RS',
                code: 4305306,
            },
            {
                name: 'Charqueadas',
                uf: 'RS',
                code: 4305355,
            },
            {
                name: 'Charrua',
                uf: 'RS',
                code: 4305371,
            },
            {
                name: 'Chiapetta',
                uf: 'RS',
                code: 4305405,
            },
            {
                name: 'Chuí',
                uf: 'RS',
                code: 4305439,
            },
            {
                name: 'Chuvisca',
                uf: 'RS',
                code: 4305447,
            },
            {
                name: 'Cidreira',
                uf: 'RS',
                code: 4305454,
            },
            {
                name: 'Ciríaco',
                uf: 'RS',
                code: 4305504,
            },
            {
                name: 'Colinas',
                uf: 'RS',
                code: 4305587,
            },
            {
                name: 'Colorado',
                uf: 'RS',
                code: 4305603,
            },
            {
                name: 'Condor',
                uf: 'RS',
                code: 4305702,
            },
            {
                name: 'Constantina',
                uf: 'RS',
                code: 4305801,
            },
            {
                name: 'Coqueiro Baixo',
                uf: 'RS',
                code: 4305835,
            },
            {
                name: 'Coqueiros do Sul',
                uf: 'RS',
                code: 4305850,
            },
            {
                name: 'Coronel Barros',
                uf: 'RS',
                code: 4305871,
            },
            {
                name: 'Coronel Bicaco',
                uf: 'RS',
                code: 4305900,
            },
            {
                name: 'Coronel Pilar',
                uf: 'RS',
                code: 4305934,
            },
            {
                name: 'Cotiporã',
                uf: 'RS',
                code: 4305959,
            },
            {
                name: 'Coxilha',
                uf: 'RS',
                code: 4305975,
            },
            {
                name: 'Crissiumal',
                uf: 'RS',
                code: 4306007,
            },
            {
                name: 'Cristal',
                uf: 'RS',
                code: 4306056,
            },
            {
                name: 'Cristal do Sul',
                uf: 'RS',
                code: 4306072,
            },
            {
                name: 'Cruz Alta',
                uf: 'RS',
                code: 4306106,
            },
            {
                name: 'Cruzaltense',
                uf: 'RS',
                code: 4306130,
            },
            {
                name: 'Cruzeiro do Sul',
                uf: 'RS',
                code: 4306205,
            },
            {
                name: 'David Canabarro',
                uf: 'RS',
                code: 4306304,
            },
            {
                name: 'Derrubadas',
                uf: 'RS',
                code: 4306320,
            },
            {
                name: 'Dezesseis de Novembro',
                uf: 'RS',
                code: 4306353,
            },
            {
                name: 'Dilermando de Aguiar',
                uf: 'RS',
                code: 4306379,
            },
            {
                name: 'Dois Irmãos',
                uf: 'RS',
                code: 4306403,
            },
            {
                name: 'Dois Irmãos das Missões',
                uf: 'RS',
                code: 4306429,
            },
            {
                name: 'Dois Lajeados',
                uf: 'RS',
                code: 4306452,
            },
            {
                name: 'Dom Feliciano',
                uf: 'RS',
                code: 4306502,
            },
            {
                name: 'Dom Pedrito',
                uf: 'RS',
                code: 4306601,
            },
            {
                name: 'Dom Pedro de Alcântara',
                uf: 'RS',
                code: 4306551,
            },
            {
                name: 'Dona Francisca',
                uf: 'RS',
                code: 4306700,
            },
            {
                name: 'Doutor Maurício Cardoso',
                uf: 'RS',
                code: 4306734,
            },
            {
                name: 'Doutor Ricardo',
                uf: 'RS',
                code: 4306759,
            },
            {
                name: 'Eldorado do Sul',
                uf: 'RS',
                code: 4306767,
            },
            {
                name: 'Encantado',
                uf: 'RS',
                code: 4306809,
            },
            {
                name: 'Encruzilhada do Sul',
                uf: 'RS',
                code: 4306908,
            },
            {
                name: 'Engenho Velho',
                uf: 'RS',
                code: 4306924,
            },
            {
                name: 'Entre Rios do Sul',
                uf: 'RS',
                code: 4306957,
            },
            {
                name: 'Entre-Ijuís',
                uf: 'RS',
                code: 4306932,
            },
            {
                name: 'Erebango',
                uf: 'RS',
                code: 4306973,
            },
            {
                name: 'Erechim',
                uf: 'RS',
                code: 4307005,
            },
            {
                name: 'Ernestina',
                uf: 'RS',
                code: 4307054,
            },
            {
                name: 'Erval Grande',
                uf: 'RS',
                code: 4307203,
            },
            {
                name: 'Erval Seco',
                uf: 'RS',
                code: 4307302,
            },
            {
                name: 'Esmeralda',
                uf: 'RS',
                code: 4307401,
            },
            {
                name: 'Esperança do Sul',
                uf: 'RS',
                code: 4307450,
            },
            {
                name: 'Espumoso',
                uf: 'RS',
                code: 4307500,
            },
            {
                name: 'Estação',
                uf: 'RS',
                code: 4307559,
            },
            {
                name: 'Estância Velha',
                uf: 'RS',
                code: 4307609,
            },
            {
                name: 'Esteio',
                uf: 'RS',
                code: 4307708,
            },
            {
                name: 'Estrela',
                uf: 'RS',
                code: 4307807,
            },
            {
                name: 'Estrela Velha',
                uf: 'RS',
                code: 4307815,
            },
            {
                name: 'Eugênio de Castro',
                uf: 'RS',
                code: 4307831,
            },
            {
                name: 'Fagundes Varela',
                uf: 'RS',
                code: 4307864,
            },
            {
                name: 'Farroupilha',
                uf: 'RS',
                code: 4307906,
            },
            {
                name: 'Faxinal do Soturno',
                uf: 'RS',
                code: 4308003,
            },
            {
                name: 'Faxinalzinho',
                uf: 'RS',
                code: 4308052,
            },
            {
                name: 'Fazenda Vilanova',
                uf: 'RS',
                code: 4308078,
            },
            {
                name: 'Feliz',
                uf: 'RS',
                code: 4308102,
            },
            {
                name: 'Flores da Cunha',
                uf: 'RS',
                code: 4308201,
            },
            {
                name: 'Floriano Peixoto',
                uf: 'RS',
                code: 4308250,
            },
            {
                name: 'Fontoura Xavier',
                uf: 'RS',
                code: 4308300,
            },
            {
                name: 'Formigueiro',
                uf: 'RS',
                code: 4308409,
            },
            {
                name: 'Forquetinha',
                uf: 'RS',
                code: 4308433,
            },
            {
                name: 'Fortaleza dos Valos',
                uf: 'RS',
                code: 4308458,
            },
            {
                name: 'Frederico Westphalen',
                uf: 'RS',
                code: 4308508,
            },
            {
                name: 'Garibaldi',
                uf: 'RS',
                code: 4308607,
            },
            {
                name: 'Garruchos',
                uf: 'RS',
                code: 4308656,
            },
            {
                name: 'Gaurama',
                uf: 'RS',
                code: 4308706,
            },
            {
                name: 'General Câmara',
                uf: 'RS',
                code: 4308805,
            },
            {
                name: 'Gentil',
                uf: 'RS',
                code: 4308854,
            },
            {
                name: 'Getúlio Vargas',
                uf: 'RS',
                code: 4308904,
            },
            {
                name: 'Giruá',
                uf: 'RS',
                code: 4309001,
            },
            {
                name: 'Glorinha',
                uf: 'RS',
                code: 4309050,
            },
            {
                name: 'Gramado',
                uf: 'RS',
                code: 4309100,
            },
            {
                name: 'Gramado dos Loureiros',
                uf: 'RS',
                code: 4309126,
            },
            {
                name: 'Gramado Xavier',
                uf: 'RS',
                code: 4309159,
            },
            {
                name: 'Gravataí',
                uf: 'RS',
                code: 4309209,
            },
            {
                name: 'Guabiju',
                uf: 'RS',
                code: 4309258,
            },
            {
                name: 'Guaíba',
                uf: 'RS',
                code: 4309308,
            },
            {
                name: 'Guaporé',
                uf: 'RS',
                code: 4309407,
            },
            {
                name: 'Guarani das Missões',
                uf: 'RS',
                code: 4309506,
            },
            {
                name: 'Harmonia',
                uf: 'RS',
                code: 4309555,
            },
            {
                name: 'Herval',
                uf: 'RS',
                code: 4307104,
            },
            {
                name: 'Herveiras',
                uf: 'RS',
                code: 4309571,
            },
            {
                name: 'Horizontina',
                uf: 'RS',
                code: 4309605,
            },
            {
                name: 'Hulha Negra',
                uf: 'RS',
                code: 4309654,
            },
            {
                name: 'Humaitá',
                uf: 'RS',
                code: 4309704,
            },
            {
                name: 'Ibarama',
                uf: 'RS',
                code: 4309753,
            },
            {
                name: 'Ibiaçá',
                uf: 'RS',
                code: 4309803,
            },
            {
                name: 'Ibiraiaras',
                uf: 'RS',
                code: 4309902,
            },
            {
                name: 'Ibirapuitã',
                uf: 'RS',
                code: 4309951,
            },
            {
                name: 'Ibirubá',
                uf: 'RS',
                code: 4310009,
            },
            {
                name: 'Igrejinha',
                uf: 'RS',
                code: 4310108,
            },
            {
                name: 'Ijuí',
                uf: 'RS',
                code: 4310207,
            },
            {
                name: 'Ilópolis',
                uf: 'RS',
                code: 4310306,
            },
            {
                name: 'Imbé',
                uf: 'RS',
                code: 4310330,
            },
            {
                name: 'Imigrante',
                uf: 'RS',
                code: 4310363,
            },
            {
                name: 'Independência',
                uf: 'RS',
                code: 4310405,
            },
            {
                name: 'Inhacorá',
                uf: 'RS',
                code: 4310413,
            },
            {
                name: 'Ipê',
                uf: 'RS',
                code: 4310439,
            },
            {
                name: 'Ipiranga do Sul',
                uf: 'RS',
                code: 4310462,
            },
            {
                name: 'Iraí',
                uf: 'RS',
                code: 4310504,
            },
            {
                name: 'Itaara',
                uf: 'RS',
                code: 4310538,
            },
            {
                name: 'Itacurubi',
                uf: 'RS',
                code: 4310553,
            },
            {
                name: 'Itapuca',
                uf: 'RS',
                code: 4310579,
            },
            {
                name: 'Itaqui',
                uf: 'RS',
                code: 4310603,
            },
            {
                name: 'Itati',
                uf: 'RS',
                code: 4310652,
            },
            {
                name: 'Itatiba do Sul',
                uf: 'RS',
                code: 4310702,
            },
            {
                name: 'Ivorá',
                uf: 'RS',
                code: 4310751,
            },
            {
                name: 'Ivoti',
                uf: 'RS',
                code: 4310801,
            },
            {
                name: 'Jaboticaba',
                uf: 'RS',
                code: 4310850,
            },
            {
                name: 'Jacuizinho',
                uf: 'RS',
                code: 4310876,
            },
            {
                name: 'Jacutinga',
                uf: 'RS',
                code: 4310900,
            },
            {
                name: 'Jaguarão',
                uf: 'RS',
                code: 4311007,
            },
            {
                name: 'Jaguari',
                uf: 'RS',
                code: 4311106,
            },
            {
                name: 'Jaquirana',
                uf: 'RS',
                code: 4311122,
            },
            {
                name: 'Jari',
                uf: 'RS',
                code: 4311130,
            },
            {
                name: 'Jóia',
                uf: 'RS',
                code: 4311155,
            },
            {
                name: 'Júlio de Castilhos',
                uf: 'RS',
                code: 4311205,
            },
            {
                name: 'Lagoa Bonita do Sul',
                uf: 'RS',
                code: 4311239,
            },
            {
                name: 'Lagoa dos Três Cantos',
                uf: 'RS',
                code: 4311270,
            },
            {
                name: 'Lagoa Vermelha',
                uf: 'RS',
                code: 4311304,
            },
            {
                name: 'Lagoão',
                uf: 'RS',
                code: 4311254,
            },
            {
                name: 'Lajeado',
                uf: 'RS',
                code: 4311403,
            },
            {
                name: 'Lajeado do Bugre',
                uf: 'RS',
                code: 4311429,
            },
            {
                name: 'Lavras do Sul',
                uf: 'RS',
                code: 4311502,
            },
            {
                name: 'Liberato Salzano',
                uf: 'RS',
                code: 4311601,
            },
            {
                name: 'Lindolfo Collor',
                uf: 'RS',
                code: 4311627,
            },
            {
                name: 'Linha Nova',
                uf: 'RS',
                code: 4311643,
            },
            {
                name: 'Maçambará',
                uf: 'RS',
                code: 4311718,
            },
            {
                name: 'Machadinho',
                uf: 'RS',
                code: 4311700,
            },
            {
                name: 'Mampituba',
                uf: 'RS',
                code: 4311734,
            },
            {
                name: 'Manoel Viana',
                uf: 'RS',
                code: 4311759,
            },
            {
                name: 'Maquiné',
                uf: 'RS',
                code: 4311775,
            },
            {
                name: 'Maratá',
                uf: 'RS',
                code: 4311791,
            },
            {
                name: 'Marau',
                uf: 'RS',
                code: 4311809,
            },
            {
                name: 'Marcelino Ramos',
                uf: 'RS',
                code: 4311908,
            },
            {
                name: 'Mariana Pimentel',
                uf: 'RS',
                code: 4311981,
            },
            {
                name: 'Mariano Moro',
                uf: 'RS',
                code: 4312005,
            },
            {
                name: 'Marques de Souza',
                uf: 'RS',
                code: 4312054,
            },
            {
                name: 'Mata',
                uf: 'RS',
                code: 4312104,
            },
            {
                name: 'Mato Castelhano',
                uf: 'RS',
                code: 4312138,
            },
            {
                name: 'Mato Leitão',
                uf: 'RS',
                code: 4312153,
            },
            {
                name: 'Mato Queimado',
                uf: 'RS',
                code: 4312179,
            },
            {
                name: 'Maximiliano de Almeida',
                uf: 'RS',
                code: 4312203,
            },
            {
                name: 'Minas do Leão',
                uf: 'RS',
                code: 4312252,
            },
            {
                name: 'Miraguaí',
                uf: 'RS',
                code: 4312302,
            },
            {
                name: 'Montauri',
                uf: 'RS',
                code: 4312351,
            },
            {
                name: 'Monte Alegre dos Campos',
                uf: 'RS',
                code: 4312377,
            },
            {
                name: 'Monte Belo do Sul',
                uf: 'RS',
                code: 4312385,
            },
            {
                name: 'Montenegro',
                uf: 'RS',
                code: 4312401,
            },
            {
                name: 'Mormaço',
                uf: 'RS',
                code: 4312427,
            },
            {
                name: 'Morrinhos do Sul',
                uf: 'RS',
                code: 4312443,
            },
            {
                name: 'Morro Redondo',
                uf: 'RS',
                code: 4312450,
            },
            {
                name: 'Morro Reuter',
                uf: 'RS',
                code: 4312476,
            },
            {
                name: 'Mostardas',
                uf: 'RS',
                code: 4312500,
            },
            {
                name: 'Muçum',
                uf: 'RS',
                code: 4312609,
            },
            {
                name: 'Muitos Capões',
                uf: 'RS',
                code: 4312617,
            },
            {
                name: 'Muliterno',
                uf: 'RS',
                code: 4312625,
            },
            {
                name: 'Não-Me-Toque',
                uf: 'RS',
                code: 4312658,
            },
            {
                name: 'Nicolau Vergueiro',
                uf: 'RS',
                code: 4312674,
            },
            {
                name: 'Nonoai',
                uf: 'RS',
                code: 4312708,
            },
            {
                name: 'Nova Alvorada',
                uf: 'RS',
                code: 4312757,
            },
            {
                name: 'Nova Araçá',
                uf: 'RS',
                code: 4312807,
            },
            {
                name: 'Nova Bassano',
                uf: 'RS',
                code: 4312906,
            },
            {
                name: 'Nova Boa Vista',
                uf: 'RS',
                code: 4312955,
            },
            {
                name: 'Nova Bréscia',
                uf: 'RS',
                code: 4313003,
            },
            {
                name: 'Nova Candelária',
                uf: 'RS',
                code: 4313011,
            },
            {
                name: 'Nova Esperança do Sul',
                uf: 'RS',
                code: 4313037,
            },
            {
                name: 'Nova Hartz',
                uf: 'RS',
                code: 4313060,
            },
            {
                name: 'Nova Pádua',
                uf: 'RS',
                code: 4313086,
            },
            {
                name: 'Nova Palma',
                uf: 'RS',
                code: 4313102,
            },
            {
                name: 'Nova Petrópolis',
                uf: 'RS',
                code: 4313201,
            },
            {
                name: 'Nova Prata',
                uf: 'RS',
                code: 4313300,
            },
            {
                name: 'Nova Ramada',
                uf: 'RS',
                code: 4313334,
            },
            {
                name: 'Nova Roma do Sul',
                uf: 'RS',
                code: 4313359,
            },
            {
                name: 'Nova Santa Rita',
                uf: 'RS',
                code: 4313375,
            },
            {
                name: 'Novo Barreiro',
                uf: 'RS',
                code: 4313490,
            },
            {
                name: 'Novo Cabrais',
                uf: 'RS',
                code: 4313391,
            },
            {
                name: 'Novo Hamburgo',
                uf: 'RS',
                code: 4313409,
            },
            {
                name: 'Novo Machado',
                uf: 'RS',
                code: 4313425,
            },
            {
                name: 'Novo Tiradentes',
                uf: 'RS',
                code: 4313441,
            },
            {
                name: 'Novo Xingu',
                uf: 'RS',
                code: 4313466,
            },
            {
                name: 'Osório',
                uf: 'RS',
                code: 4313508,
            },
            {
                name: 'Paim Filho',
                uf: 'RS',
                code: 4313607,
            },
            {
                name: 'Palmares do Sul',
                uf: 'RS',
                code: 4313656,
            },
            {
                name: 'Palmeira das Missões',
                uf: 'RS',
                code: 4313706,
            },
            {
                name: 'Palmitinho',
                uf: 'RS',
                code: 4313805,
            },
            {
                name: 'Panambi',
                uf: 'RS',
                code: 4313904,
            },
            {
                name: 'Pantano Grande',
                uf: 'RS',
                code: 4313953,
            },
            {
                name: 'Paraí',
                uf: 'RS',
                code: 4314001,
            },
            {
                name: 'Paraíso do Sul',
                uf: 'RS',
                code: 4314027,
            },
            {
                name: 'Pareci Novo',
                uf: 'RS',
                code: 4314035,
            },
            {
                name: 'Parobé',
                uf: 'RS',
                code: 4314050,
            },
            {
                name: 'Passa Sete',
                uf: 'RS',
                code: 4314068,
            },
            {
                name: 'Passo do Sobrado',
                uf: 'RS',
                code: 4314076,
            },
            {
                name: 'Passo Fundo',
                uf: 'RS',
                code: 4314100,
            },
            {
                name: 'Paulo Bento',
                uf: 'RS',
                code: 4314134,
            },
            {
                name: 'Paverama',
                uf: 'RS',
                code: 4314159,
            },
            {
                name: 'Pedras Altas',
                uf: 'RS',
                code: 4314175,
            },
            {
                name: 'Pedro Osório',
                uf: 'RS',
                code: 4314209,
            },
            {
                name: 'Pejuçara',
                uf: 'RS',
                code: 4314308,
            },
            {
                name: 'Pelotas',
                uf: 'RS',
                code: 4314407,
            },
            {
                name: 'Picada Café',
                uf: 'RS',
                code: 4314423,
            },
            {
                name: 'Pinhal',
                uf: 'RS',
                code: 4314456,
            },
            {
                name: 'Pinhal da Serra',
                uf: 'RS',
                code: 4314464,
            },
            {
                name: 'Pinhal Grande',
                uf: 'RS',
                code: 4314472,
            },
            {
                name: 'Pinheirinho do Vale',
                uf: 'RS',
                code: 4314498,
            },
            {
                name: 'Pinheiro Machado',
                uf: 'RS',
                code: 4314506,
            },
            {
                name: 'Pinto Bandeira',
                uf: 'RS',
                code: 4314548,
            },
            {
                name: 'Pirapó',
                uf: 'RS',
                code: 4314555,
            },
            {
                name: 'Piratini',
                uf: 'RS',
                code: 4314605,
            },
            {
                name: 'Planalto',
                uf: 'RS',
                code: 4314704,
            },
            {
                name: 'Poço das Antas',
                uf: 'RS',
                code: 4314753,
            },
            {
                name: 'Pontão',
                uf: 'RS',
                code: 4314779,
            },
            {
                name: 'Ponte Preta',
                uf: 'RS',
                code: 4314787,
            },
            {
                name: 'Portão',
                uf: 'RS',
                code: 4314803,
            },
            {
                name: 'Porto Alegre',
                uf: 'RS',
                code: 4314902,
            },
            {
                name: 'Porto Lucena',
                uf: 'RS',
                code: 4315008,
            },
            {
                name: 'Porto Mauá',
                uf: 'RS',
                code: 4315057,
            },
            {
                name: 'Porto Vera Cruz',
                uf: 'RS',
                code: 4315073,
            },
            {
                name: 'Porto Xavier',
                uf: 'RS',
                code: 4315107,
            },
            {
                name: 'Pouso Novo',
                uf: 'RS',
                code: 4315131,
            },
            {
                name: 'Presidente Lucena',
                uf: 'RS',
                code: 4315149,
            },
            {
                name: 'Progresso',
                uf: 'RS',
                code: 4315156,
            },
            {
                name: 'Protásio Alves',
                uf: 'RS',
                code: 4315172,
            },
            {
                name: 'Putinga',
                uf: 'RS',
                code: 4315206,
            },
            {
                name: 'Quaraí',
                uf: 'RS',
                code: 4315305,
            },
            {
                name: 'Quatro Irmãos',
                uf: 'RS',
                code: 4315313,
            },
            {
                name: 'Quevedos',
                uf: 'RS',
                code: 4315321,
            },
            {
                name: 'Quinze de Novembro',
                uf: 'RS',
                code: 4315354,
            },
            {
                name: 'Redentora',
                uf: 'RS',
                code: 4315404,
            },
            {
                name: 'Relvado',
                uf: 'RS',
                code: 4315453,
            },
            {
                name: 'Restinga Seca',
                uf: 'RS',
                code: 4315503,
            },
            {
                name: 'Rio dos Índios',
                uf: 'RS',
                code: 4315552,
            },
            {
                name: 'Rio Grande',
                uf: 'RS',
                code: 4315602,
            },
            {
                name: 'Rio Pardo',
                uf: 'RS',
                code: 4315701,
            },
            {
                name: 'Riozinho',
                uf: 'RS',
                code: 4315750,
            },
            {
                name: 'Roca Sales',
                uf: 'RS',
                code: 4315800,
            },
            {
                name: 'Rodeio Bonito',
                uf: 'RS',
                code: 4315909,
            },
            {
                name: 'Rolador',
                uf: 'RS',
                code: 4315958,
            },
            {
                name: 'Rolante',
                uf: 'RS',
                code: 4316006,
            },
            {
                name: 'Ronda Alta',
                uf: 'RS',
                code: 4316105,
            },
            {
                name: 'Rondinha',
                uf: 'RS',
                code: 4316204,
            },
            {
                name: 'Roque Gonzales',
                uf: 'RS',
                code: 4316303,
            },
            {
                name: 'Rosário do Sul',
                uf: 'RS',
                code: 4316402,
            },
            {
                name: 'Sagrada Família',
                uf: 'RS',
                code: 4316428,
            },
            {
                name: 'Saldanha Marinho',
                uf: 'RS',
                code: 4316436,
            },
            {
                name: 'Salto do Jacuí',
                uf: 'RS',
                code: 4316451,
            },
            {
                name: 'Salvador das Missões',
                uf: 'RS',
                code: 4316477,
            },
            {
                name: 'Salvador do Sul',
                uf: 'RS',
                code: 4316501,
            },
            {
                name: 'Sananduva',
                uf: 'RS',
                code: 4316600,
            },
            {
                name: "Sant'Ana do Livramento",
                uf: 'RS',
                code: 4317103,
            },
            {
                name: 'Santa Bárbara do Sul',
                uf: 'RS',
                code: 4316709,
            },
            {
                name: 'Santa Cecília do Sul',
                uf: 'RS',
                code: 4316733,
            },
            {
                name: 'Santa Clara do Sul',
                uf: 'RS',
                code: 4316758,
            },
            {
                name: 'Santa Cruz do Sul',
                uf: 'RS',
                code: 4316808,
            },
            {
                name: 'Santa Margarida do Sul',
                uf: 'RS',
                code: 4316972,
            },
            {
                name: 'Santa Maria',
                uf: 'RS',
                code: 4316907,
            },
            {
                name: 'Santa Maria do Herval',
                uf: 'RS',
                code: 4316956,
            },
            {
                name: 'Santa Rosa',
                uf: 'RS',
                code: 4317202,
            },
            {
                name: 'Santa Tereza',
                uf: 'RS',
                code: 4317251,
            },
            {
                name: 'Santa Vitória do Palmar',
                uf: 'RS',
                code: 4317301,
            },
            {
                name: 'Santana da Boa Vista',
                uf: 'RS',
                code: 4317004,
            },
            {
                name: 'Santiago',
                uf: 'RS',
                code: 4317400,
            },
            {
                name: 'Santo Ângelo',
                uf: 'RS',
                code: 4317509,
            },
            {
                name: 'Santo Antônio da Patrulha',
                uf: 'RS',
                code: 4317608,
            },
            {
                name: 'Santo Antônio das Missões',
                uf: 'RS',
                code: 4317707,
            },
            {
                name: 'Santo Antônio do Palma',
                uf: 'RS',
                code: 4317558,
            },
            {
                name: 'Santo Antônio do Planalto',
                uf: 'RS',
                code: 4317756,
            },
            {
                name: 'Santo Augusto',
                uf: 'RS',
                code: 4317806,
            },
            {
                name: 'Santo Cristo',
                uf: 'RS',
                code: 4317905,
            },
            {
                name: 'Santo Expedito do Sul',
                uf: 'RS',
                code: 4317954,
            },
            {
                name: 'São Borja',
                uf: 'RS',
                code: 4318002,
            },
            {
                name: 'São Domingos do Sul',
                uf: 'RS',
                code: 4318051,
            },
            {
                name: 'São Francisco de Assis',
                uf: 'RS',
                code: 4318101,
            },
            {
                name: 'São Francisco de Paula',
                uf: 'RS',
                code: 4318200,
            },
            {
                name: 'São Gabriel',
                uf: 'RS',
                code: 4318309,
            },
            {
                name: 'São Jerônimo',
                uf: 'RS',
                code: 4318408,
            },
            {
                name: 'São João da Urtiga',
                uf: 'RS',
                code: 4318424,
            },
            {
                name: 'São João do Polêsine',
                uf: 'RS',
                code: 4318432,
            },
            {
                name: 'São Jorge',
                uf: 'RS',
                code: 4318440,
            },
            {
                name: 'São José das Missões',
                uf: 'RS',
                code: 4318457,
            },
            {
                name: 'São José do Herval',
                uf: 'RS',
                code: 4318465,
            },
            {
                name: 'São José do Hortêncio',
                uf: 'RS',
                code: 4318481,
            },
            {
                name: 'São José do Inhacorá',
                uf: 'RS',
                code: 4318499,
            },
            {
                name: 'São José do Norte',
                uf: 'RS',
                code: 4318507,
            },
            {
                name: 'São José do Ouro',
                uf: 'RS',
                code: 4318606,
            },
            {
                name: 'São José do Sul',
                uf: 'RS',
                code: 4318614,
            },
            {
                name: 'São José dos Ausentes',
                uf: 'RS',
                code: 4318622,
            },
            {
                name: 'São Leopoldo',
                uf: 'RS',
                code: 4318705,
            },
            {
                name: 'São Lourenço do Sul',
                uf: 'RS',
                code: 4318804,
            },
            {
                name: 'São Luiz Gonzaga',
                uf: 'RS',
                code: 4318903,
            },
            {
                name: 'São Marcos',
                uf: 'RS',
                code: 4319000,
            },
            {
                name: 'São Martinho',
                uf: 'RS',
                code: 4319109,
            },
            {
                name: 'São Martinho da Serra',
                uf: 'RS',
                code: 4319125,
            },
            {
                name: 'São Miguel das Missões',
                uf: 'RS',
                code: 4319158,
            },
            {
                name: 'São Nicolau',
                uf: 'RS',
                code: 4319208,
            },
            {
                name: 'São Paulo das Missões',
                uf: 'RS',
                code: 4319307,
            },
            {
                name: 'São Pedro da Serra',
                uf: 'RS',
                code: 4319356,
            },
            {
                name: 'São Pedro das Missões',
                uf: 'RS',
                code: 4319364,
            },
            {
                name: 'São Pedro do Butiá',
                uf: 'RS',
                code: 4319372,
            },
            {
                name: 'São Pedro do Sul',
                uf: 'RS',
                code: 4319406,
            },
            {
                name: 'São Sebastião do Caí',
                uf: 'RS',
                code: 4319505,
            },
            {
                name: 'São Sepé',
                uf: 'RS',
                code: 4319604,
            },
            {
                name: 'São Valentim',
                uf: 'RS',
                code: 4319703,
            },
            {
                name: 'São Valentim do Sul',
                uf: 'RS',
                code: 4319711,
            },
            {
                name: 'São Valério do Sul',
                uf: 'RS',
                code: 4319737,
            },
            {
                name: 'São Vendelino',
                uf: 'RS',
                code: 4319752,
            },
            {
                name: 'São Vicente do Sul',
                uf: 'RS',
                code: 4319802,
            },
            {
                name: 'Sapiranga',
                uf: 'RS',
                code: 4319901,
            },
            {
                name: 'Sapucaia do Sul',
                uf: 'RS',
                code: 4320008,
            },
            {
                name: 'Sarandi',
                uf: 'RS',
                code: 4320107,
            },
            {
                name: 'Seberi',
                uf: 'RS',
                code: 4320206,
            },
            {
                name: 'Sede Nova',
                uf: 'RS',
                code: 4320230,
            },
            {
                name: 'Segredo',
                uf: 'RS',
                code: 4320263,
            },
            {
                name: 'Selbach',
                uf: 'RS',
                code: 4320305,
            },
            {
                name: 'Senador Salgado Filho',
                uf: 'RS',
                code: 4320321,
            },
            {
                name: 'Sentinela do Sul',
                uf: 'RS',
                code: 4320354,
            },
            {
                name: 'Serafina Corrêa',
                uf: 'RS',
                code: 4320404,
            },
            {
                name: 'Sério',
                uf: 'RS',
                code: 4320453,
            },
            {
                name: 'Sertão',
                uf: 'RS',
                code: 4320503,
            },
            {
                name: 'Sertão Santana',
                uf: 'RS',
                code: 4320552,
            },
            {
                name: 'Sete de Setembro',
                uf: 'RS',
                code: 4320578,
            },
            {
                name: 'Severiano de Almeida',
                uf: 'RS',
                code: 4320602,
            },
            {
                name: 'Silveira Martins',
                uf: 'RS',
                code: 4320651,
            },
            {
                name: 'Sinimbu',
                uf: 'RS',
                code: 4320677,
            },
            {
                name: 'Sobradinho',
                uf: 'RS',
                code: 4320701,
            },
            {
                name: 'Soledade',
                uf: 'RS',
                code: 4320800,
            },
            {
                name: 'Tabaí',
                uf: 'RS',
                code: 4320859,
            },
            {
                name: 'Tapejara',
                uf: 'RS',
                code: 4320909,
            },
            {
                name: 'Tapera',
                uf: 'RS',
                code: 4321006,
            },
            {
                name: 'Tapes',
                uf: 'RS',
                code: 4321105,
            },
            {
                name: 'Taquara',
                uf: 'RS',
                code: 4321204,
            },
            {
                name: 'Taquari',
                uf: 'RS',
                code: 4321303,
            },
            {
                name: 'Taquaruçu do Sul',
                uf: 'RS',
                code: 4321329,
            },
            {
                name: 'Tavares',
                uf: 'RS',
                code: 4321352,
            },
            {
                name: 'Tenente Portela',
                uf: 'RS',
                code: 4321402,
            },
            {
                name: 'Terra de Areia',
                uf: 'RS',
                code: 4321436,
            },
            {
                name: 'Teutônia',
                uf: 'RS',
                code: 4321451,
            },
            {
                name: 'Tio Hugo',
                uf: 'RS',
                code: 4321469,
            },
            {
                name: 'Tiradentes do Sul',
                uf: 'RS',
                code: 4321477,
            },
            {
                name: 'Toropi',
                uf: 'RS',
                code: 4321493,
            },
            {
                name: 'Torres',
                uf: 'RS',
                code: 4321501,
            },
            {
                name: 'Tramandaí',
                uf: 'RS',
                code: 4321600,
            },
            {
                name: 'Travesseiro',
                uf: 'RS',
                code: 4321626,
            },
            {
                name: 'Três Arroios',
                uf: 'RS',
                code: 4321634,
            },
            {
                name: 'Três Cachoeiras',
                uf: 'RS',
                code: 4321667,
            },
            {
                name: 'Três Coroas',
                uf: 'RS',
                code: 4321709,
            },
            {
                name: 'Três de Maio',
                uf: 'RS',
                code: 4321808,
            },
            {
                name: 'Três Forquilhas',
                uf: 'RS',
                code: 4321832,
            },
            {
                name: 'Três Palmeiras',
                uf: 'RS',
                code: 4321857,
            },
            {
                name: 'Três Passos',
                uf: 'RS',
                code: 4321907,
            },
            {
                name: 'Trindade do Sul',
                uf: 'RS',
                code: 4321956,
            },
            {
                name: 'Triunfo',
                uf: 'RS',
                code: 4322004,
            },
            {
                name: 'Tucunduva',
                uf: 'RS',
                code: 4322103,
            },
            {
                name: 'Tunas',
                uf: 'RS',
                code: 4322152,
            },
            {
                name: 'Tupanci do Sul',
                uf: 'RS',
                code: 4322186,
            },
            {
                name: 'Tupanciretã',
                uf: 'RS',
                code: 4322202,
            },
            {
                name: 'Tupandi',
                uf: 'RS',
                code: 4322251,
            },
            {
                name: 'Tuparendi',
                uf: 'RS',
                code: 4322301,
            },
            {
                name: 'Turuçu',
                uf: 'RS',
                code: 4322327,
            },
            {
                name: 'Ubiretama',
                uf: 'RS',
                code: 4322343,
            },
            {
                name: 'União da Serra',
                uf: 'RS',
                code: 4322350,
            },
            {
                name: 'Unistalda',
                uf: 'RS',
                code: 4322376,
            },
            {
                name: 'Uruguaiana',
                uf: 'RS',
                code: 4322400,
            },
            {
                name: 'Vacaria',
                uf: 'RS',
                code: 4322509,
            },
            {
                name: 'Vale do Sol',
                uf: 'RS',
                code: 4322533,
            },
            {
                name: 'Vale Real',
                uf: 'RS',
                code: 4322541,
            },
            {
                name: 'Vale Verde',
                uf: 'RS',
                code: 4322525,
            },
            {
                name: 'Vanini',
                uf: 'RS',
                code: 4322558,
            },
            {
                name: 'Venâncio Aires',
                uf: 'RS',
                code: 4322608,
            },
            {
                name: 'Vera Cruz',
                uf: 'RS',
                code: 4322707,
            },
            {
                name: 'Veranópolis',
                uf: 'RS',
                code: 4322806,
            },
            {
                name: 'Vespasiano Correa',
                uf: 'RS',
                code: 4322855,
            },
            {
                name: 'Viadutos',
                uf: 'RS',
                code: 4322905,
            },
            {
                name: 'Viamão',
                uf: 'RS',
                code: 4323002,
            },
            {
                name: 'Vicente Dutra',
                uf: 'RS',
                code: 4323101,
            },
            {
                name: 'Victor Graeff',
                uf: 'RS',
                code: 4323200,
            },
            {
                name: 'Vila Flores',
                uf: 'RS',
                code: 4323309,
            },
            {
                name: 'Vila Lângaro',
                uf: 'RS',
                code: 4323358,
            },
            {
                name: 'Vila Maria',
                uf: 'RS',
                code: 4323408,
            },
            {
                name: 'Vila Nova do Sul',
                uf: 'RS',
                code: 4323457,
            },
            {
                name: 'Vista Alegre',
                uf: 'RS',
                code: 4323507,
            },
            {
                name: 'Vista Alegre do Prata',
                uf: 'RS',
                code: 4323606,
            },
            {
                name: 'Vista Gaúcha',
                uf: 'RS',
                code: 4323705,
            },
            {
                name: 'Vitória das Missões',
                uf: 'RS',
                code: 4323754,
            },
            {
                name: 'Westfalia',
                uf: 'RS',
                code: 4323770,
            },
            {
                name: 'Xangri-lá',
                uf: 'RS',
                code: 4323804,
            },
            {
                name: 'Abdon Batista',
                uf: 'SC',
                code: 4200051,
            },
            {
                name: 'Abelardo Luz',
                uf: 'SC',
                code: 4200101,
            },
            {
                name: 'Agrolândia',
                uf: 'SC',
                code: 4200200,
            },
            {
                name: 'Agronômica',
                uf: 'SC',
                code: 4200309,
            },
            {
                name: 'Água Doce',
                uf: 'SC',
                code: 4200408,
            },
            {
                name: 'Águas de Chapecó',
                uf: 'SC',
                code: 4200507,
            },
            {
                name: 'Águas Frias',
                uf: 'SC',
                code: 4200556,
            },
            {
                name: 'Águas Mornas',
                uf: 'SC',
                code: 4200606,
            },
            {
                name: 'Alfredo Wagner',
                uf: 'SC',
                code: 4200705,
            },
            {
                name: 'Alto Bela Vista',
                uf: 'SC',
                code: 4200754,
            },
            {
                name: 'Anchieta',
                uf: 'SC',
                code: 4200804,
            },
            {
                name: 'Angelina',
                uf: 'SC',
                code: 4200903,
            },
            {
                name: 'Anita Garibaldi',
                uf: 'SC',
                code: 4201000,
            },
            {
                name: 'Anitápolis',
                uf: 'SC',
                code: 4201109,
            },
            {
                name: 'Antônio Carlos',
                uf: 'SC',
                code: 4201208,
            },
            {
                name: 'Apiúna',
                uf: 'SC',
                code: 4201257,
            },
            {
                name: 'Arabutã',
                uf: 'SC',
                code: 4201273,
            },
            {
                name: 'Araquari',
                uf: 'SC',
                code: 4201307,
            },
            {
                name: 'Araranguá',
                uf: 'SC',
                code: 4201406,
            },
            {
                name: 'Armazém',
                uf: 'SC',
                code: 4201505,
            },
            {
                name: 'Arroio Trinta',
                uf: 'SC',
                code: 4201604,
            },
            {
                name: 'Arvoredo',
                uf: 'SC',
                code: 4201653,
            },
            {
                name: 'Ascurra',
                uf: 'SC',
                code: 4201703,
            },
            {
                name: 'Atalanta',
                uf: 'SC',
                code: 4201802,
            },
            {
                name: 'Aurora',
                uf: 'SC',
                code: 4201901,
            },
            {
                name: 'Balneário Arroio do Silva',
                uf: 'SC',
                code: 4201950,
            },
            {
                name: 'Balneário Barra do Sul',
                uf: 'SC',
                code: 4202057,
            },
            {
                name: 'Balneário Camboriú',
                uf: 'SC',
                code: 4202008,
            },
            {
                name: 'Balneário Gaivota',
                uf: 'SC',
                code: 4202073,
            },
            {
                name: 'Balneário Piçarras',
                uf: 'SC',
                code: 4212809,
            },
            {
                name: 'Balneário Rincão',
                uf: 'SC',
                code: 4220000,
            },
            {
                name: 'Bandeirante',
                uf: 'SC',
                code: 4202081,
            },
            {
                name: 'Barra Bonita',
                uf: 'SC',
                code: 4202099,
            },
            {
                name: 'Barra Velha',
                uf: 'SC',
                code: 4202107,
            },
            {
                name: 'Bela Vista do Toldo',
                uf: 'SC',
                code: 4202131,
            },
            {
                name: 'Belmonte',
                uf: 'SC',
                code: 4202156,
            },
            {
                name: 'Benedito Novo',
                uf: 'SC',
                code: 4202206,
            },
            {
                name: 'Biguaçu',
                uf: 'SC',
                code: 4202305,
            },
            {
                name: 'Blumenau',
                uf: 'SC',
                code: 4202404,
            },
            {
                name: 'Bocaina do Sul',
                uf: 'SC',
                code: 4202438,
            },
            {
                name: 'Bom Jardim da Serra',
                uf: 'SC',
                code: 4202503,
            },
            {
                name: 'Bom Jesus',
                uf: 'SC',
                code: 4202537,
            },
            {
                name: 'Bom Jesus do Oeste',
                uf: 'SC',
                code: 4202578,
            },
            {
                name: 'Bom Retiro',
                uf: 'SC',
                code: 4202602,
            },
            {
                name: 'Bombinhas',
                uf: 'SC',
                code: 4202453,
            },
            {
                name: 'Botuverá',
                uf: 'SC',
                code: 4202701,
            },
            {
                name: 'Braço do Norte',
                uf: 'SC',
                code: 4202800,
            },
            {
                name: 'Braço do Trombudo',
                uf: 'SC',
                code: 4202859,
            },
            {
                name: 'Brunópolis',
                uf: 'SC',
                code: 4202875,
            },
            {
                name: 'Brusque',
                uf: 'SC',
                code: 4202909,
            },
            {
                name: 'Caçador',
                uf: 'SC',
                code: 4203006,
            },
            {
                name: 'Caibi',
                uf: 'SC',
                code: 4203105,
            },
            {
                name: 'Calmon',
                uf: 'SC',
                code: 4203154,
            },
            {
                name: 'Camboriú',
                uf: 'SC',
                code: 4203204,
            },
            {
                name: 'Campo Alegre',
                uf: 'SC',
                code: 4203303,
            },
            {
                name: 'Campo Belo do Sul',
                uf: 'SC',
                code: 4203402,
            },
            {
                name: 'Campo Erê',
                uf: 'SC',
                code: 4203501,
            },
            {
                name: 'Campos Novos',
                uf: 'SC',
                code: 4203600,
            },
            {
                name: 'Canelinha',
                uf: 'SC',
                code: 4203709,
            },
            {
                name: 'Canoinhas',
                uf: 'SC',
                code: 4203808,
            },
            {
                name: 'Capão Alto',
                uf: 'SC',
                code: 4203253,
            },
            {
                name: 'Capinzal',
                uf: 'SC',
                code: 4203907,
            },
            {
                name: 'Capivari de Baixo',
                uf: 'SC',
                code: 4203956,
            },
            {
                name: 'Catanduvas',
                uf: 'SC',
                code: 4204004,
            },
            {
                name: 'Caxambu do Sul',
                uf: 'SC',
                code: 4204103,
            },
            {
                name: 'Celso Ramos',
                uf: 'SC',
                code: 4204152,
            },
            {
                name: 'Cerro Negro',
                uf: 'SC',
                code: 4204178,
            },
            {
                name: 'Chapadão do Lageado',
                uf: 'SC',
                code: 4204194,
            },
            {
                name: 'Chapecó',
                uf: 'SC',
                code: 4204202,
            },
            {
                name: 'Cocal do Sul',
                uf: 'SC',
                code: 4204251,
            },
            {
                name: 'Concórdia',
                uf: 'SC',
                code: 4204301,
            },
            {
                name: 'Cordilheira Alta',
                uf: 'SC',
                code: 4204350,
            },
            {
                name: 'Coronel Freitas',
                uf: 'SC',
                code: 4204400,
            },
            {
                name: 'Coronel Martins',
                uf: 'SC',
                code: 4204459,
            },
            {
                name: 'Correia Pinto',
                uf: 'SC',
                code: 4204558,
            },
            {
                name: 'Corupá',
                uf: 'SC',
                code: 4204509,
            },
            {
                name: 'Criciúma',
                uf: 'SC',
                code: 4204608,
            },
            {
                name: 'Cunha Porã',
                uf: 'SC',
                code: 4204707,
            },
            {
                name: 'Cunhataí',
                uf: 'SC',
                code: 4204756,
            },
            {
                name: 'Curitibanos',
                uf: 'SC',
                code: 4204806,
            },
            {
                name: 'Descanso',
                uf: 'SC',
                code: 4204905,
            },
            {
                name: 'Dionísio Cerqueira',
                uf: 'SC',
                code: 4205001,
            },
            {
                name: 'Dona Emma',
                uf: 'SC',
                code: 4205100,
            },
            {
                name: 'Doutor Pedrinho',
                uf: 'SC',
                code: 4205159,
            },
            {
                name: 'Entre Rios',
                uf: 'SC',
                code: 4205175,
            },
            {
                name: 'Ermo',
                uf: 'SC',
                code: 4205191,
            },
            {
                name: 'Erval Velho',
                uf: 'SC',
                code: 4205209,
            },
            {
                name: 'Faxinal dos Guedes',
                uf: 'SC',
                code: 4205308,
            },
            {
                name: 'Flor do Sertão',
                uf: 'SC',
                code: 4205357,
            },
            {
                name: 'Florianópolis',
                uf: 'SC',
                code: 4205407,
            },
            {
                name: 'Formosa do Sul',
                uf: 'SC',
                code: 4205431,
            },
            {
                name: 'Forquilhinha',
                uf: 'SC',
                code: 4205456,
            },
            {
                name: 'Fraiburgo',
                uf: 'SC',
                code: 4205506,
            },
            {
                name: 'Frei Rogério',
                uf: 'SC',
                code: 4205555,
            },
            {
                name: 'Galvão',
                uf: 'SC',
                code: 4205605,
            },
            {
                name: 'Garopaba',
                uf: 'SC',
                code: 4205704,
            },
            {
                name: 'Garuva',
                uf: 'SC',
                code: 4205803,
            },
            {
                name: 'Gaspar',
                uf: 'SC',
                code: 4205902,
            },
            {
                name: 'Governador Celso Ramos',
                uf: 'SC',
                code: 4206009,
            },
            {
                name: 'Grão Pará',
                uf: 'SC',
                code: 4206108,
            },
            {
                name: 'Gravatal',
                uf: 'SC',
                code: 4206207,
            },
            {
                name: 'Guabiruba',
                uf: 'SC',
                code: 4206306,
            },
            {
                name: 'Guaraciaba',
                uf: 'SC',
                code: 4206405,
            },
            {
                name: 'Guaramirim',
                uf: 'SC',
                code: 4206504,
            },
            {
                name: 'Guarujá do Sul',
                uf: 'SC',
                code: 4206603,
            },
            {
                name: 'Guatambú',
                uf: 'SC',
                code: 4206652,
            },
            {
                name: "Herval d'Oeste",
                uf: 'SC',
                code: 4206702,
            },
            {
                name: 'Ibiam',
                uf: 'SC',
                code: 4206751,
            },
            {
                name: 'Ibicaré',
                uf: 'SC',
                code: 4206801,
            },
            {
                name: 'Ibirama',
                uf: 'SC',
                code: 4206900,
            },
            {
                name: 'Içara',
                uf: 'SC',
                code: 4207007,
            },
            {
                name: 'Ilhota',
                uf: 'SC',
                code: 4207106,
            },
            {
                name: 'Imaruí',
                uf: 'SC',
                code: 4207205,
            },
            {
                name: 'Imbituba',
                uf: 'SC',
                code: 4207304,
            },
            {
                name: 'Imbuia',
                uf: 'SC',
                code: 4207403,
            },
            {
                name: 'Indaial',
                uf: 'SC',
                code: 4207502,
            },
            {
                name: 'Iomerê',
                uf: 'SC',
                code: 4207577,
            },
            {
                name: 'Ipira',
                uf: 'SC',
                code: 4207601,
            },
            {
                name: 'Iporã do Oeste',
                uf: 'SC',
                code: 4207650,
            },
            {
                name: 'Ipuaçu',
                uf: 'SC',
                code: 4207684,
            },
            {
                name: 'Ipumirim',
                uf: 'SC',
                code: 4207700,
            },
            {
                name: 'Iraceminha',
                uf: 'SC',
                code: 4207759,
            },
            {
                name: 'Irani',
                uf: 'SC',
                code: 4207809,
            },
            {
                name: 'Irati',
                uf: 'SC',
                code: 4207858,
            },
            {
                name: 'Irineópolis',
                uf: 'SC',
                code: 4207908,
            },
            {
                name: 'Itá',
                uf: 'SC',
                code: 4208005,
            },
            {
                name: 'Itaiópolis',
                uf: 'SC',
                code: 4208104,
            },
            {
                name: 'Itajaí',
                uf: 'SC',
                code: 4208203,
            },
            {
                name: 'Itapema',
                uf: 'SC',
                code: 4208302,
            },
            {
                name: 'Itapiranga',
                uf: 'SC',
                code: 4208401,
            },
            {
                name: 'Itapoá',
                uf: 'SC',
                code: 4208450,
            },
            {
                name: 'Ituporanga',
                uf: 'SC',
                code: 4208500,
            },
            {
                name: 'Jaborá',
                uf: 'SC',
                code: 4208609,
            },
            {
                name: 'Jacinto Machado',
                uf: 'SC',
                code: 4208708,
            },
            {
                name: 'Jaguaruna',
                uf: 'SC',
                code: 4208807,
            },
            {
                name: 'Jaraguá do Sul',
                uf: 'SC',
                code: 4208906,
            },
            {
                name: 'Jardinópolis',
                uf: 'SC',
                code: 4208955,
            },
            {
                name: 'Joaçaba',
                uf: 'SC',
                code: 4209003,
            },
            {
                name: 'Joinville',
                uf: 'SC',
                code: 4209102,
            },
            {
                name: 'José Boiteux',
                uf: 'SC',
                code: 4209151,
            },
            {
                name: 'Jupiá',
                uf: 'SC',
                code: 4209177,
            },
            {
                name: 'Lacerdópolis',
                uf: 'SC',
                code: 4209201,
            },
            {
                name: 'Lages',
                uf: 'SC',
                code: 4209300,
            },
            {
                name: 'Laguna',
                uf: 'SC',
                code: 4209409,
            },
            {
                name: 'Lajeado Grande',
                uf: 'SC',
                code: 4209458,
            },
            {
                name: 'Laurentino',
                uf: 'SC',
                code: 4209508,
            },
            {
                name: 'Lauro Muller',
                uf: 'SC',
                code: 4209607,
            },
            {
                name: 'Lebon Régis',
                uf: 'SC',
                code: 4209706,
            },
            {
                name: 'Leoberto Leal',
                uf: 'SC',
                code: 4209805,
            },
            {
                name: 'Lindóia do Sul',
                uf: 'SC',
                code: 4209854,
            },
            {
                name: 'Lontras',
                uf: 'SC',
                code: 4209904,
            },
            {
                name: 'Luiz Alves',
                uf: 'SC',
                code: 4210001,
            },
            {
                name: 'Luzerna',
                uf: 'SC',
                code: 4210035,
            },
            {
                name: 'Macieira',
                uf: 'SC',
                code: 4210050,
            },
            {
                name: 'Mafra',
                uf: 'SC',
                code: 4210100,
            },
            {
                name: 'Major Gercino',
                uf: 'SC',
                code: 4210209,
            },
            {
                name: 'Major Vieira',
                uf: 'SC',
                code: 4210308,
            },
            {
                name: 'Maracajá',
                uf: 'SC',
                code: 4210407,
            },
            {
                name: 'Maravilha',
                uf: 'SC',
                code: 4210506,
            },
            {
                name: 'Marema',
                uf: 'SC',
                code: 4210555,
            },
            {
                name: 'Massaranduba',
                uf: 'SC',
                code: 4210605,
            },
            {
                name: 'Matos Costa',
                uf: 'SC',
                code: 4210704,
            },
            {
                name: 'Meleiro',
                uf: 'SC',
                code: 4210803,
            },
            {
                name: 'Mirim Doce',
                uf: 'SC',
                code: 4210852,
            },
            {
                name: 'Modelo',
                uf: 'SC',
                code: 4210902,
            },
            {
                name: 'Mondaí',
                uf: 'SC',
                code: 4211009,
            },
            {
                name: 'Monte Carlo',
                uf: 'SC',
                code: 4211058,
            },
            {
                name: 'Monte Castelo',
                uf: 'SC',
                code: 4211108,
            },
            {
                name: 'Morro da Fumaça',
                uf: 'SC',
                code: 4211207,
            },
            {
                name: 'Morro Grande',
                uf: 'SC',
                code: 4211256,
            },
            {
                name: 'Navegantes',
                uf: 'SC',
                code: 4211306,
            },
            {
                name: 'Nova Erechim',
                uf: 'SC',
                code: 4211405,
            },
            {
                name: 'Nova Itaberaba',
                uf: 'SC',
                code: 4211454,
            },
            {
                name: 'Nova Trento',
                uf: 'SC',
                code: 4211504,
            },
            {
                name: 'Nova Veneza',
                uf: 'SC',
                code: 4211603,
            },
            {
                name: 'Novo Horizonte',
                uf: 'SC',
                code: 4211652,
            },
            {
                name: 'Orleans',
                uf: 'SC',
                code: 4211702,
            },
            {
                name: 'Otacílio Costa',
                uf: 'SC',
                code: 4211751,
            },
            {
                name: 'Ouro',
                uf: 'SC',
                code: 4211801,
            },
            {
                name: 'Ouro Verde',
                uf: 'SC',
                code: 4211850,
            },
            {
                name: 'Paial',
                uf: 'SC',
                code: 4211876,
            },
            {
                name: 'Painel',
                uf: 'SC',
                code: 4211892,
            },
            {
                name: 'Palhoça',
                uf: 'SC',
                code: 4211900,
            },
            {
                name: 'Palma Sola',
                uf: 'SC',
                code: 4212007,
            },
            {
                name: 'Palmeira',
                uf: 'SC',
                code: 4212056,
            },
            {
                name: 'Palmitos',
                uf: 'SC',
                code: 4212106,
            },
            {
                name: 'Papanduva',
                uf: 'SC',
                code: 4212205,
            },
            {
                name: 'Paraíso',
                uf: 'SC',
                code: 4212239,
            },
            {
                name: 'Passo de Torres',
                uf: 'SC',
                code: 4212254,
            },
            {
                name: 'Passos Maia',
                uf: 'SC',
                code: 4212270,
            },
            {
                name: 'Paulo Lopes',
                uf: 'SC',
                code: 4212304,
            },
            {
                name: 'Pedras Grandes',
                uf: 'SC',
                code: 4212403,
            },
            {
                name: 'Penha',
                uf: 'SC',
                code: 4212502,
            },
            {
                name: 'Peritiba',
                uf: 'SC',
                code: 4212601,
            },
            {
                name: 'Pescaria Brava',
                uf: 'SC',
                code: 4212650,
            },
            {
                name: 'Petrolândia',
                uf: 'SC',
                code: 4212700,
            },
            {
                name: 'Pinhalzinho',
                uf: 'SC',
                code: 4212908,
            },
            {
                name: 'Pinheiro Preto',
                uf: 'SC',
                code: 4213005,
            },
            {
                name: 'Piratuba',
                uf: 'SC',
                code: 4213104,
            },
            {
                name: 'Planalto Alegre',
                uf: 'SC',
                code: 4213153,
            },
            {
                name: 'Pomerode',
                uf: 'SC',
                code: 4213203,
            },
            {
                name: 'Ponte Alta',
                uf: 'SC',
                code: 4213302,
            },
            {
                name: 'Ponte Alta do Norte',
                uf: 'SC',
                code: 4213351,
            },
            {
                name: 'Ponte Serrada',
                uf: 'SC',
                code: 4213401,
            },
            {
                name: 'Porto Belo',
                uf: 'SC',
                code: 4213500,
            },
            {
                name: 'Porto União',
                uf: 'SC',
                code: 4213609,
            },
            {
                name: 'Pouso Redondo',
                uf: 'SC',
                code: 4213708,
            },
            {
                name: 'Praia Grande',
                uf: 'SC',
                code: 4213807,
            },
            {
                name: 'Presidente Castello Branco',
                uf: 'SC',
                code: 4213906,
            },
            {
                name: 'Presidente Getúlio',
                uf: 'SC',
                code: 4214003,
            },
            {
                name: 'Presidente Nereu',
                uf: 'SC',
                code: 4214102,
            },
            {
                name: 'Princesa',
                uf: 'SC',
                code: 4214151,
            },
            {
                name: 'Quilombo',
                uf: 'SC',
                code: 4214201,
            },
            {
                name: 'Rancho Queimado',
                uf: 'SC',
                code: 4214300,
            },
            {
                name: 'Rio das Antas',
                uf: 'SC',
                code: 4214409,
            },
            {
                name: 'Rio do Campo',
                uf: 'SC',
                code: 4214508,
            },
            {
                name: 'Rio do Oeste',
                uf: 'SC',
                code: 4214607,
            },
            {
                name: 'Rio do Sul',
                uf: 'SC',
                code: 4214805,
            },
            {
                name: 'Rio dos Cedros',
                uf: 'SC',
                code: 4214706,
            },
            {
                name: 'Rio Fortuna',
                uf: 'SC',
                code: 4214904,
            },
            {
                name: 'Rio Negrinho',
                uf: 'SC',
                code: 4215000,
            },
            {
                name: 'Rio Rufino',
                uf: 'SC',
                code: 4215059,
            },
            {
                name: 'Riqueza',
                uf: 'SC',
                code: 4215075,
            },
            {
                name: 'Rodeio',
                uf: 'SC',
                code: 4215109,
            },
            {
                name: 'Romelândia',
                uf: 'SC',
                code: 4215208,
            },
            {
                name: 'Salete',
                uf: 'SC',
                code: 4215307,
            },
            {
                name: 'Saltinho',
                uf: 'SC',
                code: 4215356,
            },
            {
                name: 'Salto Veloso',
                uf: 'SC',
                code: 4215406,
            },
            {
                name: 'Sangão',
                uf: 'SC',
                code: 4215455,
            },
            {
                name: 'Santa Cecília',
                uf: 'SC',
                code: 4215505,
            },
            {
                name: 'Santa Helena',
                uf: 'SC',
                code: 4215554,
            },
            {
                name: 'Santa Rosa de Lima',
                uf: 'SC',
                code: 4215604,
            },
            {
                name: 'Santa Rosa do Sul',
                uf: 'SC',
                code: 4215653,
            },
            {
                name: 'Santa Terezinha',
                uf: 'SC',
                code: 4215679,
            },
            {
                name: 'Santa Terezinha do Progresso',
                uf: 'SC',
                code: 4215687,
            },
            {
                name: 'Santiago do Sul',
                uf: 'SC',
                code: 4215695,
            },
            {
                name: 'Santo Amaro da Imperatriz',
                uf: 'SC',
                code: 4215703,
            },
            {
                name: 'São Bento do Sul',
                uf: 'SC',
                code: 4215802,
            },
            {
                name: 'São Bernardino',
                uf: 'SC',
                code: 4215752,
            },
            {
                name: 'São Bonifácio',
                uf: 'SC',
                code: 4215901,
            },
            {
                name: 'São Carlos',
                uf: 'SC',
                code: 4216008,
            },
            {
                name: 'São Cristovão do Sul',
                uf: 'SC',
                code: 4216057,
            },
            {
                name: 'São Domingos',
                uf: 'SC',
                code: 4216107,
            },
            {
                name: 'São Francisco do Sul',
                uf: 'SC',
                code: 4216206,
            },
            {
                name: 'São João Batista',
                uf: 'SC',
                code: 4216305,
            },
            {
                name: 'São João do Itaperiú',
                uf: 'SC',
                code: 4216354,
            },
            {
                name: 'São João do Oeste',
                uf: 'SC',
                code: 4216255,
            },
            {
                name: 'São João do Sul',
                uf: 'SC',
                code: 4216404,
            },
            {
                name: 'São Joaquim',
                uf: 'SC',
                code: 4216503,
            },
            {
                name: 'São José',
                uf: 'SC',
                code: 4216602,
            },
            {
                name: 'São José do Cedro',
                uf: 'SC',
                code: 4216701,
            },
            {
                name: 'São José do Cerrito',
                uf: 'SC',
                code: 4216800,
            },
            {
                name: 'São Lourenço do Oeste',
                uf: 'SC',
                code: 4216909,
            },
            {
                name: 'São Ludgero',
                uf: 'SC',
                code: 4217006,
            },
            {
                name: 'São Martinho',
                uf: 'SC',
                code: 4217105,
            },
            {
                name: 'São Miguel da Boa Vista',
                uf: 'SC',
                code: 4217154,
            },
            {
                name: 'São Miguel do Oeste',
                uf: 'SC',
                code: 4217204,
            },
            {
                name: 'São Pedro de Alcântara',
                uf: 'SC',
                code: 4217253,
            },
            {
                name: 'Saudades',
                uf: 'SC',
                code: 4217303,
            },
            {
                name: 'Schroeder',
                uf: 'SC',
                code: 4217402,
            },
            {
                name: 'Seara',
                uf: 'SC',
                code: 4217501,
            },
            {
                name: 'Serra Alta',
                uf: 'SC',
                code: 4217550,
            },
            {
                name: 'Siderópolis',
                uf: 'SC',
                code: 4217600,
            },
            {
                name: 'Sombrio',
                uf: 'SC',
                code: 4217709,
            },
            {
                name: 'Sul Brasil',
                uf: 'SC',
                code: 4217758,
            },
            {
                name: 'Taió',
                uf: 'SC',
                code: 4217808,
            },
            {
                name: 'Tangará',
                uf: 'SC',
                code: 4217907,
            },
            {
                name: 'Tigrinhos',
                uf: 'SC',
                code: 4217956,
            },
            {
                name: 'Tijucas',
                uf: 'SC',
                code: 4218004,
            },
            {
                name: 'Timbé do Sul',
                uf: 'SC',
                code: 4218103,
            },
            {
                name: 'Timbó',
                uf: 'SC',
                code: 4218202,
            },
            {
                name: 'Timbó Grande',
                uf: 'SC',
                code: 4218251,
            },
            {
                name: 'Três Barras',
                uf: 'SC',
                code: 4218301,
            },
            {
                name: 'Treviso',
                uf: 'SC',
                code: 4218350,
            },
            {
                name: 'Treze de Maio',
                uf: 'SC',
                code: 4218400,
            },
            {
                name: 'Treze Tílias',
                uf: 'SC',
                code: 4218509,
            },
            {
                name: 'Trombudo Central',
                uf: 'SC',
                code: 4218608,
            },
            {
                name: 'Tubarão',
                uf: 'SC',
                code: 4218707,
            },
            {
                name: 'Tunápolis',
                uf: 'SC',
                code: 4218756,
            },
            {
                name: 'Turvo',
                uf: 'SC',
                code: 4218806,
            },
            {
                name: 'União do Oeste',
                uf: 'SC',
                code: 4218855,
            },
            {
                name: 'Urubici',
                uf: 'SC',
                code: 4218905,
            },
            {
                name: 'Urupema',
                uf: 'SC',
                code: 4218954,
            },
            {
                name: 'Urussanga',
                uf: 'SC',
                code: 4219002,
            },
            {
                name: 'Vargeão',
                uf: 'SC',
                code: 4219101,
            },
            {
                name: 'Vargem',
                uf: 'SC',
                code: 4219150,
            },
            {
                name: 'Vargem Bonita',
                uf: 'SC',
                code: 4219176,
            },
            {
                name: 'Vidal Ramos',
                uf: 'SC',
                code: 4219200,
            },
            {
                name: 'Videira',
                uf: 'SC',
                code: 4219309,
            },
            {
                name: 'Vitor Meireles',
                uf: 'SC',
                code: 4219358,
            },
            {
                name: 'Witmarsum',
                uf: 'SC',
                code: 4219408,
            },
            {
                name: 'Xanxerê',
                uf: 'SC',
                code: 4219507,
            },
            {
                name: 'Xavantina',
                uf: 'SC',
                code: 4219606,
            },
            {
                name: 'Xaxim',
                uf: 'SC',
                code: 4219705,
            },
            {
                name: 'Zortéa',
                uf: 'SC',
                code: 4219853,
            },
            {
                name: 'Amparo de São Francisco',
                uf: 'SE',
                code: 2800100,
            },
            {
                name: 'Aquidabã',
                uf: 'SE',
                code: 2800209,
            },
            {
                name: 'Aracaju',
                uf: 'SE',
                code: 2800308,
            },
            {
                name: 'Arauá',
                uf: 'SE',
                code: 2800407,
            },
            {
                name: 'Areia Branca',
                uf: 'SE',
                code: 2800506,
            },
            {
                name: 'Barra dos Coqueiros',
                uf: 'SE',
                code: 2800605,
            },
            {
                name: 'Boquim',
                uf: 'SE',
                code: 2800670,
            },
            {
                name: 'Brejo Grande',
                uf: 'SE',
                code: 2800704,
            },
            {
                name: 'Campo do Brito',
                uf: 'SE',
                code: 2801009,
            },
            {
                name: 'Canhoba',
                uf: 'SE',
                code: 2801108,
            },
            {
                name: 'Canindé de São Francisco',
                uf: 'SE',
                code: 2801207,
            },
            {
                name: 'Capela',
                uf: 'SE',
                code: 2801306,
            },
            {
                name: 'Carira',
                uf: 'SE',
                code: 2801405,
            },
            {
                name: 'Carmópolis',
                uf: 'SE',
                code: 2801504,
            },
            {
                name: 'Cedro de São João',
                uf: 'SE',
                code: 2801603,
            },
            {
                name: 'Cristinápolis',
                uf: 'SE',
                code: 2801702,
            },
            {
                name: 'Cumbe',
                uf: 'SE',
                code: 2801900,
            },
            {
                name: 'Divina Pastora',
                uf: 'SE',
                code: 2802007,
            },
            {
                name: 'Estância',
                uf: 'SE',
                code: 2802106,
            },
            {
                name: 'Feira Nova',
                uf: 'SE',
                code: 2802205,
            },
            {
                name: 'Frei Paulo',
                uf: 'SE',
                code: 2802304,
            },
            {
                name: 'Gararu',
                uf: 'SE',
                code: 2802403,
            },
            {
                name: 'General Maynard',
                uf: 'SE',
                code: 2802502,
            },
            {
                name: 'Gracho Cardoso',
                uf: 'SE',
                code: 2802601,
            },
            {
                name: 'Ilha das Flores',
                uf: 'SE',
                code: 2802700,
            },
            {
                name: 'Indiaroba',
                uf: 'SE',
                code: 2802809,
            },
            {
                name: 'Itabaiana',
                uf: 'SE',
                code: 2802908,
            },
            {
                name: 'Itabaianinha',
                uf: 'SE',
                code: 2803005,
            },
            {
                name: 'Itabi',
                uf: 'SE',
                code: 2803104,
            },
            {
                name: "Itaporanga d'Ajuda",
                uf: 'SE',
                code: 2803203,
            },
            {
                name: 'Japaratuba',
                uf: 'SE',
                code: 2803302,
            },
            {
                name: 'Japoatã',
                uf: 'SE',
                code: 2803401,
            },
            {
                name: 'Lagarto',
                uf: 'SE',
                code: 2803500,
            },
            {
                name: 'Laranjeiras',
                uf: 'SE',
                code: 2803609,
            },
            {
                name: 'Macambira',
                uf: 'SE',
                code: 2803708,
            },
            {
                name: 'Malhada dos Bois',
                uf: 'SE',
                code: 2803807,
            },
            {
                name: 'Malhador',
                uf: 'SE',
                code: 2803906,
            },
            {
                name: 'Maruim',
                uf: 'SE',
                code: 2804003,
            },
            {
                name: 'Moita Bonita',
                uf: 'SE',
                code: 2804102,
            },
            {
                name: 'Monte Alegre de Sergipe',
                uf: 'SE',
                code: 2804201,
            },
            {
                name: 'Muribeca',
                uf: 'SE',
                code: 2804300,
            },
            {
                name: 'Neópolis',
                uf: 'SE',
                code: 2804409,
            },
            {
                name: 'Nossa Senhora Aparecida',
                uf: 'SE',
                code: 2804458,
            },
            {
                name: 'Nossa Senhora da Glória',
                uf: 'SE',
                code: 2804508,
            },
            {
                name: 'Nossa Senhora das Dores',
                uf: 'SE',
                code: 2804607,
            },
            {
                name: 'Nossa Senhora de Lourdes',
                uf: 'SE',
                code: 2804706,
            },
            {
                name: 'Nossa Senhora do Socorro',
                uf: 'SE',
                code: 2804805,
            },
            {
                name: 'Pacatuba',
                uf: 'SE',
                code: 2804904,
            },
            {
                name: 'Pedra Mole',
                uf: 'SE',
                code: 2805000,
            },
            {
                name: 'Pedrinhas',
                uf: 'SE',
                code: 2805109,
            },
            {
                name: 'Pinhão',
                uf: 'SE',
                code: 2805208,
            },
            {
                name: 'Pirambu',
                uf: 'SE',
                code: 2805307,
            },
            {
                name: 'Poço Redondo',
                uf: 'SE',
                code: 2805406,
            },
            {
                name: 'Poço Verde',
                uf: 'SE',
                code: 2805505,
            },
            {
                name: 'Porto da Folha',
                uf: 'SE',
                code: 2805604,
            },
            {
                name: 'Propriá',
                uf: 'SE',
                code: 2805703,
            },
            {
                name: 'Riachão do Dantas',
                uf: 'SE',
                code: 2805802,
            },
            {
                name: 'Riachuelo',
                uf: 'SE',
                code: 2805901,
            },
            {
                name: 'Ribeirópolis',
                uf: 'SE',
                code: 2806008,
            },
            {
                name: 'Rosário do Catete',
                uf: 'SE',
                code: 2806107,
            },
            {
                name: 'Salgado',
                uf: 'SE',
                code: 2806206,
            },
            {
                name: 'Santa Luzia do Itanhy',
                uf: 'SE',
                code: 2806305,
            },
            {
                name: 'Santa Rosa de Lima',
                uf: 'SE',
                code: 2806503,
            },
            {
                name: 'Santana do São Francisco',
                uf: 'SE',
                code: 2806404,
            },
            {
                name: 'Santo Amaro das Brotas',
                uf: 'SE',
                code: 2806602,
            },
            {
                name: 'São Cristóvão',
                uf: 'SE',
                code: 2806701,
            },
            {
                name: 'São Domingos',
                uf: 'SE',
                code: 2806800,
            },
            {
                name: 'São Francisco',
                uf: 'SE',
                code: 2806909,
            },
            {
                name: 'São Miguel do Aleixo',
                uf: 'SE',
                code: 2807006,
            },
            {
                name: 'Simão Dias',
                uf: 'SE',
                code: 2807105,
            },
            {
                name: 'Siriri',
                uf: 'SE',
                code: 2807204,
            },
            {
                name: 'Telha',
                uf: 'SE',
                code: 2807303,
            },
            {
                name: 'Tobias Barreto',
                uf: 'SE',
                code: 2807402,
            },
            {
                name: 'Tomar do Geru',
                uf: 'SE',
                code: 2807501,
            },
            {
                name: 'Umbaúba',
                uf: 'SE',
                code: 2807600,
            },
            {
                name: 'Adamantina',
                uf: 'SP',
                code: 3500105,
            },
            {
                name: 'Adolfo',
                uf: 'SP',
                code: 3500204,
            },
            {
                name: 'Aguaí',
                uf: 'SP',
                code: 3500303,
            },
            {
                name: 'Águas da Prata',
                uf: 'SP',
                code: 3500402,
            },
            {
                name: 'Águas de Lindóia',
                uf: 'SP',
                code: 3500501,
            },
            {
                name: 'Águas de Santa Bárbara',
                uf: 'SP',
                code: 3500550,
            },
            {
                name: 'Águas de São Pedro',
                uf: 'SP',
                code: 3500600,
            },
            {
                name: 'Agudos',
                uf: 'SP',
                code: 3500709,
            },
            {
                name: 'Alambari',
                uf: 'SP',
                code: 3500758,
            },
            {
                name: 'Alfredo Marcondes',
                uf: 'SP',
                code: 3500808,
            },
            {
                name: 'Altair',
                uf: 'SP',
                code: 3500907,
            },
            {
                name: 'Altinópolis',
                uf: 'SP',
                code: 3501004,
            },
            {
                name: 'Alto Alegre',
                uf: 'SP',
                code: 3501103,
            },
            {
                name: 'Alumínio',
                uf: 'SP',
                code: 3501152,
            },
            {
                name: 'Álvares Florence',
                uf: 'SP',
                code: 3501202,
            },
            {
                name: 'Álvares Machado',
                uf: 'SP',
                code: 3501301,
            },
            {
                name: 'Álvaro de Carvalho',
                uf: 'SP',
                code: 3501400,
            },
            {
                name: 'Alvinlândia',
                uf: 'SP',
                code: 3501509,
            },
            {
                name: 'Americana',
                uf: 'SP',
                code: 3501608,
            },
            {
                name: 'Américo Brasiliense',
                uf: 'SP',
                code: 3501707,
            },
            {
                name: 'Américo de Campos',
                uf: 'SP',
                code: 3501806,
            },
            {
                name: 'Amparo',
                uf: 'SP',
                code: 3501905,
            },
            {
                name: 'Analândia',
                uf: 'SP',
                code: 3502002,
            },
            {
                name: 'Andradina',
                uf: 'SP',
                code: 3502101,
            },
            {
                name: 'Angatuba',
                uf: 'SP',
                code: 3502200,
            },
            {
                name: 'Anhembi',
                uf: 'SP',
                code: 3502309,
            },
            {
                name: 'Anhumas',
                uf: 'SP',
                code: 3502408,
            },
            {
                name: 'Aparecida',
                uf: 'SP',
                code: 3502507,
            },
            {
                name: "Aparecida d'Oeste",
                uf: 'SP',
                code: 3502606,
            },
            {
                name: 'Apiaí',
                uf: 'SP',
                code: 3502705,
            },
            {
                name: 'Araçariguama',
                uf: 'SP',
                code: 3502754,
            },
            {
                name: 'Araçatuba',
                uf: 'SP',
                code: 3502804,
            },
            {
                name: 'Araçoiaba da Serra',
                uf: 'SP',
                code: 3502903,
            },
            {
                name: 'Aramina',
                uf: 'SP',
                code: 3503000,
            },
            {
                name: 'Arandu',
                uf: 'SP',
                code: 3503109,
            },
            {
                name: 'Arapeí',
                uf: 'SP',
                code: 3503158,
            },
            {
                name: 'Araraquara',
                uf: 'SP',
                code: 3503208,
            },
            {
                name: 'Araras',
                uf: 'SP',
                code: 3503307,
            },
            {
                name: 'Arco-Íris',
                uf: 'SP',
                code: 3503356,
            },
            {
                name: 'Arealva',
                uf: 'SP',
                code: 3503406,
            },
            {
                name: 'Areias',
                uf: 'SP',
                code: 3503505,
            },
            {
                name: 'Areiópolis',
                uf: 'SP',
                code: 3503604,
            },
            {
                name: 'Ariranha',
                uf: 'SP',
                code: 3503703,
            },
            {
                name: 'Artur Nogueira',
                uf: 'SP',
                code: 3503802,
            },
            {
                name: 'Arujá',
                uf: 'SP',
                code: 3503901,
            },
            {
                name: 'Aspásia',
                uf: 'SP',
                code: 3503950,
            },
            {
                name: 'Assis',
                uf: 'SP',
                code: 3504008,
            },
            {
                name: 'Atibaia',
                uf: 'SP',
                code: 3504107,
            },
            {
                name: 'Auriflama',
                uf: 'SP',
                code: 3504206,
            },
            {
                name: 'Avaí',
                uf: 'SP',
                code: 3504305,
            },
            {
                name: 'Avanhandava',
                uf: 'SP',
                code: 3504404,
            },
            {
                name: 'Avaré',
                uf: 'SP',
                code: 3504503,
            },
            {
                name: 'Bady Bassitt',
                uf: 'SP',
                code: 3504602,
            },
            {
                name: 'Balbinos',
                uf: 'SP',
                code: 3504701,
            },
            {
                name: 'Bálsamo',
                uf: 'SP',
                code: 3504800,
            },
            {
                name: 'Bananal',
                uf: 'SP',
                code: 3504909,
            },
            {
                name: 'Barão de Antonina',
                uf: 'SP',
                code: 3505005,
            },
            {
                name: 'Barbosa',
                uf: 'SP',
                code: 3505104,
            },
            {
                name: 'Bariri',
                uf: 'SP',
                code: 3505203,
            },
            {
                name: 'Barra Bonita',
                uf: 'SP',
                code: 3505302,
            },
            {
                name: 'Barra do Chapéu',
                uf: 'SP',
                code: 3505351,
            },
            {
                name: 'Barra do Turvo',
                uf: 'SP',
                code: 3505401,
            },
            {
                name: 'Barretos',
                uf: 'SP',
                code: 3505500,
            },
            {
                name: 'Barrinha',
                uf: 'SP',
                code: 3505609,
            },
            {
                name: 'Barueri',
                uf: 'SP',
                code: 3505708,
            },
            {
                name: 'Bastos',
                uf: 'SP',
                code: 3505807,
            },
            {
                name: 'Batatais',
                uf: 'SP',
                code: 3505906,
            },
            {
                name: 'Bauru',
                uf: 'SP',
                code: 3506003,
            },
            {
                name: 'Bebedouro',
                uf: 'SP',
                code: 3506102,
            },
            {
                name: 'Bento de Abreu',
                uf: 'SP',
                code: 3506201,
            },
            {
                name: 'Bernardino de Campos',
                uf: 'SP',
                code: 3506300,
            },
            {
                name: 'Bertioga',
                uf: 'SP',
                code: 3506359,
            },
            {
                name: 'Bilac',
                uf: 'SP',
                code: 3506409,
            },
            {
                name: 'Birigui',
                uf: 'SP',
                code: 3506508,
            },
            {
                name: 'Biritiba-Mirim',
                uf: 'SP',
                code: 3506607,
            },
            {
                name: 'Boa Esperança do Sul',
                uf: 'SP',
                code: 3506706,
            },
            {
                name: 'Bocaina',
                uf: 'SP',
                code: 3506805,
            },
            {
                name: 'Bofete',
                uf: 'SP',
                code: 3506904,
            },
            {
                name: 'Boituva',
                uf: 'SP',
                code: 3507001,
            },
            {
                name: 'Bom Jesus dos Perdões',
                uf: 'SP',
                code: 3507100,
            },
            {
                name: 'Bom Sucesso de Itararé',
                uf: 'SP',
                code: 3507159,
            },
            {
                name: 'Borá',
                uf: 'SP',
                code: 3507209,
            },
            {
                name: 'Boracéia',
                uf: 'SP',
                code: 3507308,
            },
            {
                name: 'Borborema',
                uf: 'SP',
                code: 3507407,
            },
            {
                name: 'Borebi',
                uf: 'SP',
                code: 3507456,
            },
            {
                name: 'Botucatu',
                uf: 'SP',
                code: 3507506,
            },
            {
                name: 'Bragança Paulista',
                uf: 'SP',
                code: 3507605,
            },
            {
                name: 'Braúna',
                uf: 'SP',
                code: 3507704,
            },
            {
                name: 'Brejo Alegre',
                uf: 'SP',
                code: 3507753,
            },
            {
                name: 'Brodowski',
                uf: 'SP',
                code: 3507803,
            },
            {
                name: 'Brotas',
                uf: 'SP',
                code: 3507902,
            },
            {
                name: 'Buri',
                uf: 'SP',
                code: 3508009,
            },
            {
                name: 'Buritama',
                uf: 'SP',
                code: 3508108,
            },
            {
                name: 'Buritizal',
                uf: 'SP',
                code: 3508207,
            },
            {
                name: 'Cabrália Paulista',
                uf: 'SP',
                code: 3508306,
            },
            {
                name: 'Cabreúva',
                uf: 'SP',
                code: 3508405,
            },
            {
                name: 'Caçapava',
                uf: 'SP',
                code: 3508504,
            },
            {
                name: 'Cachoeira Paulista',
                uf: 'SP',
                code: 3508603,
            },
            {
                name: 'Caconde',
                uf: 'SP',
                code: 3508702,
            },
            {
                name: 'Cafelândia',
                uf: 'SP',
                code: 3508801,
            },
            {
                name: 'Caiabu',
                uf: 'SP',
                code: 3508900,
            },
            {
                name: 'Caieiras',
                uf: 'SP',
                code: 3509007,
            },
            {
                name: 'Caiuá',
                uf: 'SP',
                code: 3509106,
            },
            {
                name: 'Cajamar',
                uf: 'SP',
                code: 3509205,
            },
            {
                name: 'Cajati',
                uf: 'SP',
                code: 3509254,
            },
            {
                name: 'Cajobi',
                uf: 'SP',
                code: 3509304,
            },
            {
                name: 'Cajuru',
                uf: 'SP',
                code: 3509403,
            },
            {
                name: 'Campina do Monte Alegre',
                uf: 'SP',
                code: 3509452,
            },
            {
                name: 'Campinas',
                uf: 'SP',
                code: 3509502,
            },
            {
                name: 'Campo Limpo Paulista',
                uf: 'SP',
                code: 3509601,
            },
            {
                name: 'Campos do Jordão',
                uf: 'SP',
                code: 3509700,
            },
            {
                name: 'Campos Novos Paulista',
                uf: 'SP',
                code: 3509809,
            },
            {
                name: 'Cananéia',
                uf: 'SP',
                code: 3509908,
            },
            {
                name: 'Canas',
                uf: 'SP',
                code: 3509957,
            },
            {
                name: 'Cândido Mota',
                uf: 'SP',
                code: 3510005,
            },
            {
                name: 'Cândido Rodrigues',
                uf: 'SP',
                code: 3510104,
            },
            {
                name: 'Canitar',
                uf: 'SP',
                code: 3510153,
            },
            {
                name: 'Capão Bonito',
                uf: 'SP',
                code: 3510203,
            },
            {
                name: 'Capela do Alto',
                uf: 'SP',
                code: 3510302,
            },
            {
                name: 'Capivari',
                uf: 'SP',
                code: 3510401,
            },
            {
                name: 'Caraguatatuba',
                uf: 'SP',
                code: 3510500,
            },
            {
                name: 'Carapicuíba',
                uf: 'SP',
                code: 3510609,
            },
            {
                name: 'Cardoso',
                uf: 'SP',
                code: 3510708,
            },
            {
                name: 'Casa Branca',
                uf: 'SP',
                code: 3510807,
            },
            {
                name: 'Cássia dos Coqueiros',
                uf: 'SP',
                code: 3510906,
            },
            {
                name: 'Castilho',
                uf: 'SP',
                code: 3511003,
            },
            {
                name: 'Catanduva',
                uf: 'SP',
                code: 3511102,
            },
            {
                name: 'Catiguá',
                uf: 'SP',
                code: 3511201,
            },
            {
                name: 'Cedral',
                uf: 'SP',
                code: 3511300,
            },
            {
                name: 'Cerqueira César',
                uf: 'SP',
                code: 3511409,
            },
            {
                name: 'Cerquilho',
                uf: 'SP',
                code: 3511508,
            },
            {
                name: 'Cesário Lange',
                uf: 'SP',
                code: 3511607,
            },
            {
                name: 'Charqueada',
                uf: 'SP',
                code: 3511706,
            },
            {
                name: 'Chavantes',
                uf: 'SP',
                code: 3557204,
            },
            {
                name: 'Clementina',
                uf: 'SP',
                code: 3511904,
            },
            {
                name: 'Colina',
                uf: 'SP',
                code: 3512001,
            },
            {
                name: 'Colômbia',
                uf: 'SP',
                code: 3512100,
            },
            {
                name: 'Conchal',
                uf: 'SP',
                code: 3512209,
            },
            {
                name: 'Conchas',
                uf: 'SP',
                code: 3512308,
            },
            {
                name: 'Cordeirópolis',
                uf: 'SP',
                code: 3512407,
            },
            {
                name: 'Coroados',
                uf: 'SP',
                code: 3512506,
            },
            {
                name: 'Coronel Macedo',
                uf: 'SP',
                code: 3512605,
            },
            {
                name: 'Corumbataí',
                uf: 'SP',
                code: 3512704,
            },
            {
                name: 'Cosmópolis',
                uf: 'SP',
                code: 3512803,
            },
            {
                name: 'Cosmorama',
                uf: 'SP',
                code: 3512902,
            },
            {
                name: 'Cotia',
                uf: 'SP',
                code: 3513009,
            },
            {
                name: 'Cravinhos',
                uf: 'SP',
                code: 3513108,
            },
            {
                name: 'Cristais Paulista',
                uf: 'SP',
                code: 3513207,
            },
            {
                name: 'Cruzália',
                uf: 'SP',
                code: 3513306,
            },
            {
                name: 'Cruzeiro',
                uf: 'SP',
                code: 3513405,
            },
            {
                name: 'Cubatão',
                uf: 'SP',
                code: 3513504,
            },
            {
                name: 'Cunha',
                uf: 'SP',
                code: 3513603,
            },
            {
                name: 'Descalvado',
                uf: 'SP',
                code: 3513702,
            },
            {
                name: 'Diadema',
                uf: 'SP',
                code: 3513801,
            },
            {
                name: 'Dirce Reis',
                uf: 'SP',
                code: 3513850,
            },
            {
                name: 'Divinolândia',
                uf: 'SP',
                code: 3513900,
            },
            {
                name: 'Dobrada',
                uf: 'SP',
                code: 3514007,
            },
            {
                name: 'Dois Córregos',
                uf: 'SP',
                code: 3514106,
            },
            {
                name: 'Dolcinópolis',
                uf: 'SP',
                code: 3514205,
            },
            {
                name: 'Dourado',
                uf: 'SP',
                code: 3514304,
            },
            {
                name: 'Dracena',
                uf: 'SP',
                code: 3514403,
            },
            {
                name: 'Duartina',
                uf: 'SP',
                code: 3514502,
            },
            {
                name: 'Dumont',
                uf: 'SP',
                code: 3514601,
            },
            {
                name: 'Echaporã',
                uf: 'SP',
                code: 3514700,
            },
            {
                name: 'Eldorado',
                uf: 'SP',
                code: 3514809,
            },
            {
                name: 'Elias Fausto',
                uf: 'SP',
                code: 3514908,
            },
            {
                name: 'Elisiário',
                uf: 'SP',
                code: 3514924,
            },
            {
                name: 'Embaúba',
                uf: 'SP',
                code: 3514957,
            },
            {
                name: 'Embu das Artes',
                uf: 'SP',
                code: 3515004,
            },
            {
                name: 'Embu-Guaçu',
                uf: 'SP',
                code: 3515103,
            },
            {
                name: 'Emilianópolis',
                uf: 'SP',
                code: 3515129,
            },
            {
                name: 'Engenheiro Coelho',
                uf: 'SP',
                code: 3515152,
            },
            {
                name: 'Espírito Santo do Pinhal',
                uf: 'SP',
                code: 3515186,
            },
            {
                name: 'Espírito Santo do Turvo',
                uf: 'SP',
                code: 3515194,
            },
            {
                name: 'Estiva Gerbi',
                uf: 'SP',
                code: 3557303,
            },
            {
                name: "Estrela d'Oeste",
                uf: 'SP',
                code: 3515202,
            },
            {
                name: 'Estrela do Norte',
                uf: 'SP',
                code: 3515301,
            },
            {
                name: 'Euclides da Cunha Paulista',
                uf: 'SP',
                code: 3515350,
            },
            {
                name: 'Fartura',
                uf: 'SP',
                code: 3515400,
            },
            {
                name: 'Fernando Prestes',
                uf: 'SP',
                code: 3515608,
            },
            {
                name: 'Fernandópolis',
                uf: 'SP',
                code: 3515509,
            },
            {
                name: 'Fernão',
                uf: 'SP',
                code: 3515657,
            },
            {
                name: 'Ferraz de Vasconcelos',
                uf: 'SP',
                code: 3515707,
            },
            {
                name: 'Flora Rica',
                uf: 'SP',
                code: 3515806,
            },
            {
                name: 'Floreal',
                uf: 'SP',
                code: 3515905,
            },
            {
                name: 'Flórida Paulista',
                uf: 'SP',
                code: 3516002,
            },
            {
                name: 'Florínia',
                uf: 'SP',
                code: 3516101,
            },
            {
                name: 'Franca',
                uf: 'SP',
                code: 3516200,
            },
            {
                name: 'Francisco Morato',
                uf: 'SP',
                code: 3516309,
            },
            {
                name: 'Franco da Rocha',
                uf: 'SP',
                code: 3516408,
            },
            {
                name: 'Gabriel Monteiro',
                uf: 'SP',
                code: 3516507,
            },
            {
                name: 'Gália',
                uf: 'SP',
                code: 3516606,
            },
            {
                name: 'Garça',
                uf: 'SP',
                code: 3516705,
            },
            {
                name: 'Gastão Vidigal',
                uf: 'SP',
                code: 3516804,
            },
            {
                name: 'Gavião Peixoto',
                uf: 'SP',
                code: 3516853,
            },
            {
                name: 'General Salgado',
                uf: 'SP',
                code: 3516903,
            },
            {
                name: 'Getulina',
                uf: 'SP',
                code: 3517000,
            },
            {
                name: 'Glicério',
                uf: 'SP',
                code: 3517109,
            },
            {
                name: 'Guaiçara',
                uf: 'SP',
                code: 3517208,
            },
            {
                name: 'Guaimbê',
                uf: 'SP',
                code: 3517307,
            },
            {
                name: 'Guaíra',
                uf: 'SP',
                code: 3517406,
            },
            {
                name: 'Guapiaçu',
                uf: 'SP',
                code: 3517505,
            },
            {
                name: 'Guapiara',
                uf: 'SP',
                code: 3517604,
            },
            {
                name: 'Guará',
                uf: 'SP',
                code: 3517703,
            },
            {
                name: 'Guaraçaí',
                uf: 'SP',
                code: 3517802,
            },
            {
                name: 'Guaraci',
                uf: 'SP',
                code: 3517901,
            },
            {
                name: "Guarani d'Oeste",
                uf: 'SP',
                code: 3518008,
            },
            {
                name: 'Guarantã',
                uf: 'SP',
                code: 3518107,
            },
            {
                name: 'Guararapes',
                uf: 'SP',
                code: 3518206,
            },
            {
                name: 'Guararema',
                uf: 'SP',
                code: 3518305,
            },
            {
                name: 'Guaratinguetá',
                uf: 'SP',
                code: 3518404,
            },
            {
                name: 'Guareí',
                uf: 'SP',
                code: 3518503,
            },
            {
                name: 'Guariba',
                uf: 'SP',
                code: 3518602,
            },
            {
                name: 'Guarujá',
                uf: 'SP',
                code: 3518701,
            },
            {
                name: 'Guarulhos',
                uf: 'SP',
                code: 3518800,
            },
            {
                name: 'Guatapará',
                uf: 'SP',
                code: 3518859,
            },
            {
                name: 'Guzolândia',
                uf: 'SP',
                code: 3518909,
            },
            {
                name: 'Herculândia',
                uf: 'SP',
                code: 3519006,
            },
            {
                name: 'Holambra',
                uf: 'SP',
                code: 3519055,
            },
            {
                name: 'Hortolândia',
                uf: 'SP',
                code: 3519071,
            },
            {
                name: 'Iacanga',
                uf: 'SP',
                code: 3519105,
            },
            {
                name: 'Iacri',
                uf: 'SP',
                code: 3519204,
            },
            {
                name: 'Iaras',
                uf: 'SP',
                code: 3519253,
            },
            {
                name: 'Ibaté',
                uf: 'SP',
                code: 3519303,
            },
            {
                name: 'Ibirá',
                uf: 'SP',
                code: 3519402,
            },
            {
                name: 'Ibirarema',
                uf: 'SP',
                code: 3519501,
            },
            {
                name: 'Ibitinga',
                uf: 'SP',
                code: 3519600,
            },
            {
                name: 'Ibiúna',
                uf: 'SP',
                code: 3519709,
            },
            {
                name: 'Icém',
                uf: 'SP',
                code: 3519808,
            },
            {
                name: 'Iepê',
                uf: 'SP',
                code: 3519907,
            },
            {
                name: 'Igaraçu do Tietê',
                uf: 'SP',
                code: 3520004,
            },
            {
                name: 'Igarapava',
                uf: 'SP',
                code: 3520103,
            },
            {
                name: 'Igaratá',
                uf: 'SP',
                code: 3520202,
            },
            {
                name: 'Iguape',
                uf: 'SP',
                code: 3520301,
            },
            {
                name: 'Ilha Comprida',
                uf: 'SP',
                code: 3520426,
            },
            {
                name: 'Ilha Solteira',
                uf: 'SP',
                code: 3520442,
            },
            {
                name: 'Ilhabela',
                uf: 'SP',
                code: 3520400,
            },
            {
                name: 'Indaiatuba',
                uf: 'SP',
                code: 3520509,
            },
            {
                name: 'Indiana',
                uf: 'SP',
                code: 3520608,
            },
            {
                name: 'Indiaporã',
                uf: 'SP',
                code: 3520707,
            },
            {
                name: 'Inúbia Paulista',
                uf: 'SP',
                code: 3520806,
            },
            {
                name: 'Ipaussu',
                uf: 'SP',
                code: 3520905,
            },
            {
                name: 'Iperó',
                uf: 'SP',
                code: 3521002,
            },
            {
                name: 'Ipeúna',
                uf: 'SP',
                code: 3521101,
            },
            {
                name: 'Ipiguá',
                uf: 'SP',
                code: 3521150,
            },
            {
                name: 'Iporanga',
                uf: 'SP',
                code: 3521200,
            },
            {
                name: 'Ipuã',
                uf: 'SP',
                code: 3521309,
            },
            {
                name: 'Iracemápolis',
                uf: 'SP',
                code: 3521408,
            },
            {
                name: 'Irapuã',
                uf: 'SP',
                code: 3521507,
            },
            {
                name: 'Irapuru',
                uf: 'SP',
                code: 3521606,
            },
            {
                name: 'Itaberá',
                uf: 'SP',
                code: 3521705,
            },
            {
                name: 'Itaí',
                uf: 'SP',
                code: 3521804,
            },
            {
                name: 'Itajobi',
                uf: 'SP',
                code: 3521903,
            },
            {
                name: 'Itaju',
                uf: 'SP',
                code: 3522000,
            },
            {
                name: 'Itanhaém',
                uf: 'SP',
                code: 3522109,
            },
            {
                name: 'Itaóca',
                uf: 'SP',
                code: 3522158,
            },
            {
                name: 'Itapecerica da Serra',
                uf: 'SP',
                code: 3522208,
            },
            {
                name: 'Itapetininga',
                uf: 'SP',
                code: 3522307,
            },
            {
                name: 'Itapeva',
                uf: 'SP',
                code: 3522406,
            },
            {
                name: 'Itapevi',
                uf: 'SP',
                code: 3522505,
            },
            {
                name: 'Itapira',
                uf: 'SP',
                code: 3522604,
            },
            {
                name: 'Itapirapuã Paulista',
                uf: 'SP',
                code: 3522653,
            },
            {
                name: 'Itápolis',
                uf: 'SP',
                code: 3522703,
            },
            {
                name: 'Itaporanga',
                uf: 'SP',
                code: 3522802,
            },
            {
                name: 'Itapuí',
                uf: 'SP',
                code: 3522901,
            },
            {
                name: 'Itapura',
                uf: 'SP',
                code: 3523008,
            },
            {
                name: 'Itaquaquecetuba',
                uf: 'SP',
                code: 3523107,
            },
            {
                name: 'Itararé',
                uf: 'SP',
                code: 3523206,
            },
            {
                name: 'Itariri',
                uf: 'SP',
                code: 3523305,
            },
            {
                name: 'Itatiba',
                uf: 'SP',
                code: 3523404,
            },
            {
                name: 'Itatinga',
                uf: 'SP',
                code: 3523503,
            },
            {
                name: 'Itirapina',
                uf: 'SP',
                code: 3523602,
            },
            {
                name: 'Itirapuã',
                uf: 'SP',
                code: 3523701,
            },
            {
                name: 'Itobi',
                uf: 'SP',
                code: 3523800,
            },
            {
                name: 'Itu',
                uf: 'SP',
                code: 3523909,
            },
            {
                name: 'Itupeva',
                uf: 'SP',
                code: 3524006,
            },
            {
                name: 'Ituverava',
                uf: 'SP',
                code: 3524105,
            },
            {
                name: 'Jaborandi',
                uf: 'SP',
                code: 3524204,
            },
            {
                name: 'Jaboticabal',
                uf: 'SP',
                code: 3524303,
            },
            {
                name: 'Jacareí',
                uf: 'SP',
                code: 3524402,
            },
            {
                name: 'Jaci',
                uf: 'SP',
                code: 3524501,
            },
            {
                name: 'Jacupiranga',
                uf: 'SP',
                code: 3524600,
            },
            {
                name: 'Jaguariúna',
                uf: 'SP',
                code: 3524709,
            },
            {
                name: 'Jales',
                uf: 'SP',
                code: 3524808,
            },
            {
                name: 'Jambeiro',
                uf: 'SP',
                code: 3524907,
            },
            {
                name: 'Jandira',
                uf: 'SP',
                code: 3525003,
            },
            {
                name: 'Jardinópolis',
                uf: 'SP',
                code: 3525102,
            },
            {
                name: 'Jarinu',
                uf: 'SP',
                code: 3525201,
            },
            {
                name: 'Jaú',
                uf: 'SP',
                code: 3525300,
            },
            {
                name: 'Jeriquara',
                uf: 'SP',
                code: 3525409,
            },
            {
                name: 'Joanópolis',
                uf: 'SP',
                code: 3525508,
            },
            {
                name: 'João Ramalho',
                uf: 'SP',
                code: 3525607,
            },
            {
                name: 'José Bonifácio',
                uf: 'SP',
                code: 3525706,
            },
            {
                name: 'Júlio Mesquita',
                uf: 'SP',
                code: 3525805,
            },
            {
                name: 'Jumirim',
                uf: 'SP',
                code: 3525854,
            },
            {
                name: 'Jundiaí',
                uf: 'SP',
                code: 3525904,
            },
            {
                name: 'Junqueirópolis',
                uf: 'SP',
                code: 3526001,
            },
            {
                name: 'Juquiá',
                uf: 'SP',
                code: 3526100,
            },
            {
                name: 'Juquitiba',
                uf: 'SP',
                code: 3526209,
            },
            {
                name: 'Lagoinha',
                uf: 'SP',
                code: 3526308,
            },
            {
                name: 'Laranjal Paulista',
                uf: 'SP',
                code: 3526407,
            },
            {
                name: 'Lavínia',
                uf: 'SP',
                code: 3526506,
            },
            {
                name: 'Lavrinhas',
                uf: 'SP',
                code: 3526605,
            },
            {
                name: 'Leme',
                uf: 'SP',
                code: 3526704,
            },
            {
                name: 'Lençóis Paulista',
                uf: 'SP',
                code: 3526803,
            },
            {
                name: 'Limeira',
                uf: 'SP',
                code: 3526902,
            },
            {
                name: 'Lindóia',
                uf: 'SP',
                code: 3527009,
            },
            {
                name: 'Lins',
                uf: 'SP',
                code: 3527108,
            },
            {
                name: 'Lorena',
                uf: 'SP',
                code: 3527207,
            },
            {
                name: 'Lourdes',
                uf: 'SP',
                code: 3527256,
            },
            {
                name: 'Louveira',
                uf: 'SP',
                code: 3527306,
            },
            {
                name: 'Lucélia',
                uf: 'SP',
                code: 3527405,
            },
            {
                name: 'Lucianópolis',
                uf: 'SP',
                code: 3527504,
            },
            {
                name: 'Luís Antônio',
                uf: 'SP',
                code: 3527603,
            },
            {
                name: 'Luiziânia',
                uf: 'SP',
                code: 3527702,
            },
            {
                name: 'Lupércio',
                uf: 'SP',
                code: 3527801,
            },
            {
                name: 'Lutécia',
                uf: 'SP',
                code: 3527900,
            },
            {
                name: 'Macatuba',
                uf: 'SP',
                code: 3528007,
            },
            {
                name: 'Macaubal',
                uf: 'SP',
                code: 3528106,
            },
            {
                name: 'Macedônia',
                uf: 'SP',
                code: 3528205,
            },
            {
                name: 'Magda',
                uf: 'SP',
                code: 3528304,
            },
            {
                name: 'Mairinque',
                uf: 'SP',
                code: 3528403,
            },
            {
                name: 'Mairiporã',
                uf: 'SP',
                code: 3528502,
            },
            {
                name: 'Manduri',
                uf: 'SP',
                code: 3528601,
            },
            {
                name: 'Marabá Paulista',
                uf: 'SP',
                code: 3528700,
            },
            {
                name: 'Maracaí',
                uf: 'SP',
                code: 3528809,
            },
            {
                name: 'Marapoama',
                uf: 'SP',
                code: 3528858,
            },
            {
                name: 'Mariápolis',
                uf: 'SP',
                code: 3528908,
            },
            {
                name: 'Marília',
                uf: 'SP',
                code: 3529005,
            },
            {
                name: 'Marinópolis',
                uf: 'SP',
                code: 3529104,
            },
            {
                name: 'Martinópolis',
                uf: 'SP',
                code: 3529203,
            },
            {
                name: 'Matão',
                uf: 'SP',
                code: 3529302,
            },
            {
                name: 'Mauá',
                uf: 'SP',
                code: 3529401,
            },
            {
                name: 'Mendonça',
                uf: 'SP',
                code: 3529500,
            },
            {
                name: 'Meridiano',
                uf: 'SP',
                code: 3529609,
            },
            {
                name: 'Mesópolis',
                uf: 'SP',
                code: 3529658,
            },
            {
                name: 'Miguelópolis',
                uf: 'SP',
                code: 3529708,
            },
            {
                name: 'Mineiros do Tietê',
                uf: 'SP',
                code: 3529807,
            },
            {
                name: 'Mira Estrela',
                uf: 'SP',
                code: 3530003,
            },
            {
                name: 'Miracatu',
                uf: 'SP',
                code: 3529906,
            },
            {
                name: 'Mirandópolis',
                uf: 'SP',
                code: 3530102,
            },
            {
                name: 'Mirante do Paranapanema',
                uf: 'SP',
                code: 3530201,
            },
            {
                name: 'Mirassol',
                uf: 'SP',
                code: 3530300,
            },
            {
                name: 'Mirassolândia',
                uf: 'SP',
                code: 3530409,
            },
            {
                name: 'Mococa',
                uf: 'SP',
                code: 3530508,
            },
            {
                name: 'Mogi das Cruzes',
                uf: 'SP',
                code: 3530607,
            },
            {
                name: 'Mogi Guaçu',
                uf: 'SP',
                code: 3530706,
            },
            {
                name: 'Mogi Mirim',
                uf: 'SP',
                code: 3530805,
            },
            {
                name: 'Mombuca',
                uf: 'SP',
                code: 3530904,
            },
            {
                name: 'Monções',
                uf: 'SP',
                code: 3531001,
            },
            {
                name: 'Mongaguá',
                uf: 'SP',
                code: 3531100,
            },
            {
                name: 'Monte Alegre do Sul',
                uf: 'SP',
                code: 3531209,
            },
            {
                name: 'Monte Alto',
                uf: 'SP',
                code: 3531308,
            },
            {
                name: 'Monte Aprazível',
                uf: 'SP',
                code: 3531407,
            },
            {
                name: 'Monte Azul Paulista',
                uf: 'SP',
                code: 3531506,
            },
            {
                name: 'Monte Castelo',
                uf: 'SP',
                code: 3531605,
            },
            {
                name: 'Monte Mor',
                uf: 'SP',
                code: 3531803,
            },
            {
                name: 'Monteiro Lobato',
                uf: 'SP',
                code: 3531704,
            },
            {
                name: 'Morro Agudo',
                uf: 'SP',
                code: 3531902,
            },
            {
                name: 'Morungaba',
                uf: 'SP',
                code: 3532009,
            },
            {
                name: 'Motuca',
                uf: 'SP',
                code: 3532058,
            },
            {
                name: 'Murutinga do Sul',
                uf: 'SP',
                code: 3532108,
            },
            {
                name: 'Nantes',
                uf: 'SP',
                code: 3532157,
            },
            {
                name: 'Narandiba',
                uf: 'SP',
                code: 3532207,
            },
            {
                name: 'Natividade da Serra',
                uf: 'SP',
                code: 3532306,
            },
            {
                name: 'Nazaré Paulista',
                uf: 'SP',
                code: 3532405,
            },
            {
                name: 'Neves Paulista',
                uf: 'SP',
                code: 3532504,
            },
            {
                name: 'Nhandeara',
                uf: 'SP',
                code: 3532603,
            },
            {
                name: 'Nipoã',
                uf: 'SP',
                code: 3532702,
            },
            {
                name: 'Nova Aliança',
                uf: 'SP',
                code: 3532801,
            },
            {
                name: 'Nova Campina',
                uf: 'SP',
                code: 3532827,
            },
            {
                name: 'Nova Canaã Paulista',
                uf: 'SP',
                code: 3532843,
            },
            {
                name: 'Nova Castilho',
                uf: 'SP',
                code: 3532868,
            },
            {
                name: 'Nova Europa',
                uf: 'SP',
                code: 3532900,
            },
            {
                name: 'Nova Granada',
                uf: 'SP',
                code: 3533007,
            },
            {
                name: 'Nova Guataporanga',
                uf: 'SP',
                code: 3533106,
            },
            {
                name: 'Nova Independência',
                uf: 'SP',
                code: 3533205,
            },
            {
                name: 'Nova Luzitânia',
                uf: 'SP',
                code: 3533304,
            },
            {
                name: 'Nova Odessa',
                uf: 'SP',
                code: 3533403,
            },
            {
                name: 'Novais',
                uf: 'SP',
                code: 3533254,
            },
            {
                name: 'Novo Horizonte',
                uf: 'SP',
                code: 3533502,
            },
            {
                name: 'Nuporanga',
                uf: 'SP',
                code: 3533601,
            },
            {
                name: 'Ocauçu',
                uf: 'SP',
                code: 3533700,
            },
            {
                name: 'Óleo',
                uf: 'SP',
                code: 3533809,
            },
            {
                name: 'Olímpia',
                uf: 'SP',
                code: 3533908,
            },
            {
                name: 'Onda Verde',
                uf: 'SP',
                code: 3534005,
            },
            {
                name: 'Oriente',
                uf: 'SP',
                code: 3534104,
            },
            {
                name: 'Orindiúva',
                uf: 'SP',
                code: 3534203,
            },
            {
                name: 'Orlândia',
                uf: 'SP',
                code: 3534302,
            },
            {
                name: 'Osasco',
                uf: 'SP',
                code: 3534401,
            },
            {
                name: 'Oscar Bressane',
                uf: 'SP',
                code: 3534500,
            },
            {
                name: 'Osvaldo Cruz',
                uf: 'SP',
                code: 3534609,
            },
            {
                name: 'Ourinhos',
                uf: 'SP',
                code: 3534708,
            },
            {
                name: 'Ouro Verde',
                uf: 'SP',
                code: 3534807,
            },
            {
                name: 'Ouroeste',
                uf: 'SP',
                code: 3534757,
            },
            {
                name: 'Pacaembu',
                uf: 'SP',
                code: 3534906,
            },
            {
                name: 'Palestina',
                uf: 'SP',
                code: 3535002,
            },
            {
                name: 'Palmares Paulista',
                uf: 'SP',
                code: 3535101,
            },
            {
                name: "Palmeira d'Oeste",
                uf: 'SP',
                code: 3535200,
            },
            {
                name: 'Palmital',
                uf: 'SP',
                code: 3535309,
            },
            {
                name: 'Panorama',
                uf: 'SP',
                code: 3535408,
            },
            {
                name: 'Paraguaçu Paulista',
                uf: 'SP',
                code: 3535507,
            },
            {
                name: 'Paraibuna',
                uf: 'SP',
                code: 3535606,
            },
            {
                name: 'Paraíso',
                uf: 'SP',
                code: 3535705,
            },
            {
                name: 'Paranapanema',
                uf: 'SP',
                code: 3535804,
            },
            {
                name: 'Paranapuã',
                uf: 'SP',
                code: 3535903,
            },
            {
                name: 'Parapuã',
                uf: 'SP',
                code: 3536000,
            },
            {
                name: 'Pardinho',
                uf: 'SP',
                code: 3536109,
            },
            {
                name: 'Pariquera-Açu',
                uf: 'SP',
                code: 3536208,
            },
            {
                name: 'Parisi',
                uf: 'SP',
                code: 3536257,
            },
            {
                name: 'Patrocínio Paulista',
                uf: 'SP',
                code: 3536307,
            },
            {
                name: 'Paulicéia',
                uf: 'SP',
                code: 3536406,
            },
            {
                name: 'Paulínia',
                uf: 'SP',
                code: 3536505,
            },
            {
                name: 'Paulistânia',
                uf: 'SP',
                code: 3536570,
            },
            {
                name: 'Paulo de Faria',
                uf: 'SP',
                code: 3536604,
            },
            {
                name: 'Pederneiras',
                uf: 'SP',
                code: 3536703,
            },
            {
                name: 'Pedra Bela',
                uf: 'SP',
                code: 3536802,
            },
            {
                name: 'Pedranópolis',
                uf: 'SP',
                code: 3536901,
            },
            {
                name: 'Pedregulho',
                uf: 'SP',
                code: 3537008,
            },
            {
                name: 'Pedreira',
                uf: 'SP',
                code: 3537107,
            },
            {
                name: 'Pedrinhas Paulista',
                uf: 'SP',
                code: 3537156,
            },
            {
                name: 'Pedro de Toledo',
                uf: 'SP',
                code: 3537206,
            },
            {
                name: 'Penápolis',
                uf: 'SP',
                code: 3537305,
            },
            {
                name: 'Pereira Barreto',
                uf: 'SP',
                code: 3537404,
            },
            {
                name: 'Pereiras',
                uf: 'SP',
                code: 3537503,
            },
            {
                name: 'Peruíbe',
                uf: 'SP',
                code: 3537602,
            },
            {
                name: 'Piacatu',
                uf: 'SP',
                code: 3537701,
            },
            {
                name: 'Piedade',
                uf: 'SP',
                code: 3537800,
            },
            {
                name: 'Pilar do Sul',
                uf: 'SP',
                code: 3537909,
            },
            {
                name: 'Pindamonhangaba',
                uf: 'SP',
                code: 3538006,
            },
            {
                name: 'Pindorama',
                uf: 'SP',
                code: 3538105,
            },
            {
                name: 'Pinhalzinho',
                uf: 'SP',
                code: 3538204,
            },
            {
                name: 'Piquerobi',
                uf: 'SP',
                code: 3538303,
            },
            {
                name: 'Piquete',
                uf: 'SP',
                code: 3538501,
            },
            {
                name: 'Piracaia',
                uf: 'SP',
                code: 3538600,
            },
            {
                name: 'Piracicaba',
                uf: 'SP',
                code: 3538709,
            },
            {
                name: 'Piraju',
                uf: 'SP',
                code: 3538808,
            },
            {
                name: 'Pirajuí',
                uf: 'SP',
                code: 3538907,
            },
            {
                name: 'Pirangi',
                uf: 'SP',
                code: 3539004,
            },
            {
                name: 'Pirapora do Bom Jesus',
                uf: 'SP',
                code: 3539103,
            },
            {
                name: 'Pirapozinho',
                uf: 'SP',
                code: 3539202,
            },
            {
                name: 'Pirassununga',
                uf: 'SP',
                code: 3539301,
            },
            {
                name: 'Piratininga',
                uf: 'SP',
                code: 3539400,
            },
            {
                name: 'Pitangueiras',
                uf: 'SP',
                code: 3539509,
            },
            {
                name: 'Planalto',
                uf: 'SP',
                code: 3539608,
            },
            {
                name: 'Platina',
                uf: 'SP',
                code: 3539707,
            },
            {
                name: 'Poá',
                uf: 'SP',
                code: 3539806,
            },
            {
                name: 'Poloni',
                uf: 'SP',
                code: 3539905,
            },
            {
                name: 'Pompéia',
                uf: 'SP',
                code: 3540002,
            },
            {
                name: 'Pongaí',
                uf: 'SP',
                code: 3540101,
            },
            {
                name: 'Pontal',
                uf: 'SP',
                code: 3540200,
            },
            {
                name: 'Pontalinda',
                uf: 'SP',
                code: 3540259,
            },
            {
                name: 'Pontes Gestal',
                uf: 'SP',
                code: 3540309,
            },
            {
                name: 'Populina',
                uf: 'SP',
                code: 3540408,
            },
            {
                name: 'Porangaba',
                uf: 'SP',
                code: 3540507,
            },
            {
                name: 'Porto Feliz',
                uf: 'SP',
                code: 3540606,
            },
            {
                name: 'Porto Ferreira',
                uf: 'SP',
                code: 3540705,
            },
            {
                name: 'Potim',
                uf: 'SP',
                code: 3540754,
            },
            {
                name: 'Potirendaba',
                uf: 'SP',
                code: 3540804,
            },
            {
                name: 'Pracinha',
                uf: 'SP',
                code: 3540853,
            },
            {
                name: 'Pradópolis',
                uf: 'SP',
                code: 3540903,
            },
            {
                name: 'Praia Grande',
                uf: 'SP',
                code: 3541000,
            },
            {
                name: 'Pratânia',
                uf: 'SP',
                code: 3541059,
            },
            {
                name: 'Presidente Alves',
                uf: 'SP',
                code: 3541109,
            },
            {
                name: 'Presidente Bernardes',
                uf: 'SP',
                code: 3541208,
            },
            {
                name: 'Presidente Epitácio',
                uf: 'SP',
                code: 3541307,
            },
            {
                name: 'Presidente Prudente',
                uf: 'SP',
                code: 3541406,
            },
            {
                name: 'Presidente Venceslau',
                uf: 'SP',
                code: 3541505,
            },
            {
                name: 'Promissão',
                uf: 'SP',
                code: 3541604,
            },
            {
                name: 'Quadra',
                uf: 'SP',
                code: 3541653,
            },
            {
                name: 'Quatá',
                uf: 'SP',
                code: 3541703,
            },
            {
                name: 'Queiroz',
                uf: 'SP',
                code: 3541802,
            },
            {
                name: 'Queluz',
                uf: 'SP',
                code: 3541901,
            },
            {
                name: 'Quintana',
                uf: 'SP',
                code: 3542008,
            },
            {
                name: 'Rafard',
                uf: 'SP',
                code: 3542107,
            },
            {
                name: 'Rancharia',
                uf: 'SP',
                code: 3542206,
            },
            {
                name: 'Redenção da Serra',
                uf: 'SP',
                code: 3542305,
            },
            {
                name: 'Regente Feijó',
                uf: 'SP',
                code: 3542404,
            },
            {
                name: 'Reginópolis',
                uf: 'SP',
                code: 3542503,
            },
            {
                name: 'Registro',
                uf: 'SP',
                code: 3542602,
            },
            {
                name: 'Restinga',
                uf: 'SP',
                code: 3542701,
            },
            {
                name: 'Ribeira',
                uf: 'SP',
                code: 3542800,
            },
            {
                name: 'Ribeirão Bonito',
                uf: 'SP',
                code: 3542909,
            },
            {
                name: 'Ribeirão Branco',
                uf: 'SP',
                code: 3543006,
            },
            {
                name: 'Ribeirão Corrente',
                uf: 'SP',
                code: 3543105,
            },
            {
                name: 'Ribeirão do Sul',
                uf: 'SP',
                code: 3543204,
            },
            {
                name: 'Ribeirão dos Índios',
                uf: 'SP',
                code: 3543238,
            },
            {
                name: 'Ribeirão Grande',
                uf: 'SP',
                code: 3543253,
            },
            {
                name: 'Ribeirão Pires',
                uf: 'SP',
                code: 3543303,
            },
            {
                name: 'Ribeirão Preto',
                uf: 'SP',
                code: 3543402,
            },
            {
                name: 'Rifaina',
                uf: 'SP',
                code: 3543600,
            },
            {
                name: 'Rincão',
                uf: 'SP',
                code: 3543709,
            },
            {
                name: 'Rinópolis',
                uf: 'SP',
                code: 3543808,
            },
            {
                name: 'Rio Claro',
                uf: 'SP',
                code: 3543907,
            },
            {
                name: 'Rio das Pedras',
                uf: 'SP',
                code: 3544004,
            },
            {
                name: 'Rio Grande da Serra',
                uf: 'SP',
                code: 3544103,
            },
            {
                name: 'Riolândia',
                uf: 'SP',
                code: 3544202,
            },
            {
                name: 'Riversul',
                uf: 'SP',
                code: 3543501,
            },
            {
                name: 'Rosana',
                uf: 'SP',
                code: 3544251,
            },
            {
                name: 'Roseira',
                uf: 'SP',
                code: 3544301,
            },
            {
                name: 'Rubiácea',
                uf: 'SP',
                code: 3544400,
            },
            {
                name: 'Rubinéia',
                uf: 'SP',
                code: 3544509,
            },
            {
                name: 'Sabino',
                uf: 'SP',
                code: 3544608,
            },
            {
                name: 'Sagres',
                uf: 'SP',
                code: 3544707,
            },
            {
                name: 'Sales',
                uf: 'SP',
                code: 3544806,
            },
            {
                name: 'Sales Oliveira',
                uf: 'SP',
                code: 3544905,
            },
            {
                name: 'Salesópolis',
                uf: 'SP',
                code: 3545001,
            },
            {
                name: 'Salmourão',
                uf: 'SP',
                code: 3545100,
            },
            {
                name: 'Saltinho',
                uf: 'SP',
                code: 3545159,
            },
            {
                name: 'Salto',
                uf: 'SP',
                code: 3545209,
            },
            {
                name: 'Salto de Pirapora',
                uf: 'SP',
                code: 3545308,
            },
            {
                name: 'Salto Grande',
                uf: 'SP',
                code: 3545407,
            },
            {
                name: 'Sandovalina',
                uf: 'SP',
                code: 3545506,
            },
            {
                name: 'Santa Adélia',
                uf: 'SP',
                code: 3545605,
            },
            {
                name: 'Santa Albertina',
                uf: 'SP',
                code: 3545704,
            },
            {
                name: "Santa Bárbara d'Oeste",
                uf: 'SP',
                code: 3545803,
            },
            {
                name: 'Santa Branca',
                uf: 'SP',
                code: 3546009,
            },
            {
                name: "Santa Clara d'Oeste",
                uf: 'SP',
                code: 3546108,
            },
            {
                name: 'Santa Cruz da Conceição',
                uf: 'SP',
                code: 3546207,
            },
            {
                name: 'Santa Cruz da Esperança',
                uf: 'SP',
                code: 3546256,
            },
            {
                name: 'Santa Cruz das Palmeiras',
                uf: 'SP',
                code: 3546306,
            },
            {
                name: 'Santa Cruz do Rio Pardo',
                uf: 'SP',
                code: 3546405,
            },
            {
                name: 'Santa Ernestina',
                uf: 'SP',
                code: 3546504,
            },
            {
                name: 'Santa Fé do Sul',
                uf: 'SP',
                code: 3546603,
            },
            {
                name: 'Santa Gertrudes',
                uf: 'SP',
                code: 3546702,
            },
            {
                name: 'Santa Isabel',
                uf: 'SP',
                code: 3546801,
            },
            {
                name: 'Santa Lúcia',
                uf: 'SP',
                code: 3546900,
            },
            {
                name: 'Santa Maria da Serra',
                uf: 'SP',
                code: 3547007,
            },
            {
                name: 'Santa Mercedes',
                uf: 'SP',
                code: 3547106,
            },
            {
                name: "Santa Rita d'Oeste",
                uf: 'SP',
                code: 3547403,
            },
            {
                name: 'Santa Rita do Passa Quatro',
                uf: 'SP',
                code: 3547502,
            },
            {
                name: 'Santa Rosa de Viterbo',
                uf: 'SP',
                code: 3547601,
            },
            {
                name: 'Santa Salete',
                uf: 'SP',
                code: 3547650,
            },
            {
                name: 'Santana da Ponte Pensa',
                uf: 'SP',
                code: 3547205,
            },
            {
                name: 'Santana de Parnaíba',
                uf: 'SP',
                code: 3547304,
            },
            {
                name: 'Santo Anastácio',
                uf: 'SP',
                code: 3547700,
            },
            {
                name: 'Santo André',
                uf: 'SP',
                code: 3547809,
            },
            {
                name: 'Santo Antônio da Alegria',
                uf: 'SP',
                code: 3547908,
            },
            {
                name: 'Santo Antônio de Posse',
                uf: 'SP',
                code: 3548005,
            },
            {
                name: 'Santo Antônio do Aracanguá',
                uf: 'SP',
                code: 3548054,
            },
            {
                name: 'Santo Antônio do Jardim',
                uf: 'SP',
                code: 3548104,
            },
            {
                name: 'Santo Antônio do Pinhal',
                uf: 'SP',
                code: 3548203,
            },
            {
                name: 'Santo Expedito',
                uf: 'SP',
                code: 3548302,
            },
            {
                name: 'Santópolis do Aguapeí',
                uf: 'SP',
                code: 3548401,
            },
            {
                name: 'Santos',
                uf: 'SP',
                code: 3548500,
            },
            {
                name: 'São Bento do Sapucaí',
                uf: 'SP',
                code: 3548609,
            },
            {
                name: 'São Bernardo do Campo',
                uf: 'SP',
                code: 3548708,
            },
            {
                name: 'São Caetano do Sul',
                uf: 'SP',
                code: 3548807,
            },
            {
                name: 'São Carlos',
                uf: 'SP',
                code: 3548906,
            },
            {
                name: 'São Francisco',
                uf: 'SP',
                code: 3549003,
            },
            {
                name: 'São João da Boa Vista',
                uf: 'SP',
                code: 3549102,
            },
            {
                name: 'São João das Duas Pontes',
                uf: 'SP',
                code: 3549201,
            },
            {
                name: 'São João de Iracema',
                uf: 'SP',
                code: 3549250,
            },
            {
                name: "São João do Pau d'Alho",
                uf: 'SP',
                code: 3549300,
            },
            {
                name: 'São Joaquim da Barra',
                uf: 'SP',
                code: 3549409,
            },
            {
                name: 'São José da Bela Vista',
                uf: 'SP',
                code: 3549508,
            },
            {
                name: 'São José do Barreiro',
                uf: 'SP',
                code: 3549607,
            },
            {
                name: 'São José do Rio Pardo',
                uf: 'SP',
                code: 3549706,
            },
            {
                name: 'São José do Rio Preto',
                uf: 'SP',
                code: 3549805,
            },
            {
                name: 'São José dos Campos',
                uf: 'SP',
                code: 3549904,
            },
            {
                name: 'São Lourenço da Serra',
                uf: 'SP',
                code: 3549953,
            },
            {
                name: 'São Luís do Paraitinga',
                uf: 'SP',
                code: 3550001,
            },
            {
                name: 'São Manuel',
                uf: 'SP',
                code: 3550100,
            },
            {
                name: 'São Miguel Arcanjo',
                uf: 'SP',
                code: 3550209,
            },
            {
                name: 'São Paulo',
                uf: 'SP',
                code: 3550308,
            },
            {
                name: 'São Pedro',
                uf: 'SP',
                code: 3550407,
            },
            {
                name: 'São Pedro do Turvo',
                uf: 'SP',
                code: 3550506,
            },
            {
                name: 'São Roque',
                uf: 'SP',
                code: 3550605,
            },
            {
                name: 'São Sebastião',
                uf: 'SP',
                code: 3550704,
            },
            {
                name: 'São Sebastião da Grama',
                uf: 'SP',
                code: 3550803,
            },
            {
                name: 'São Simão',
                uf: 'SP',
                code: 3550902,
            },
            {
                name: 'São Vicente',
                uf: 'SP',
                code: 3551009,
            },
            {
                name: 'Sarapuí',
                uf: 'SP',
                code: 3551108,
            },
            {
                name: 'Sarutaiá',
                uf: 'SP',
                code: 3551207,
            },
            {
                name: 'Sebastianópolis do Sul',
                uf: 'SP',
                code: 3551306,
            },
            {
                name: 'Serra Azul',
                uf: 'SP',
                code: 3551405,
            },
            {
                name: 'Serra Negra',
                uf: 'SP',
                code: 3551603,
            },
            {
                name: 'Serrana',
                uf: 'SP',
                code: 3551504,
            },
            {
                name: 'Sertãozinho',
                uf: 'SP',
                code: 3551702,
            },
            {
                name: 'Sete Barras',
                uf: 'SP',
                code: 3551801,
            },
            {
                name: 'Severínia',
                uf: 'SP',
                code: 3551900,
            },
            {
                name: 'Silveiras',
                uf: 'SP',
                code: 3552007,
            },
            {
                name: 'Socorro',
                uf: 'SP',
                code: 3552106,
            },
            {
                name: 'Sorocaba',
                uf: 'SP',
                code: 3552205,
            },
            {
                name: 'Sud Mennucci',
                uf: 'SP',
                code: 3552304,
            },
            {
                name: 'Sumaré',
                uf: 'SP',
                code: 3552403,
            },
            {
                name: 'Suzanápolis',
                uf: 'SP',
                code: 3552551,
            },
            {
                name: 'Suzano',
                uf: 'SP',
                code: 3552502,
            },
            {
                name: 'Tabapuã',
                uf: 'SP',
                code: 3552601,
            },
            {
                name: 'Tabatinga',
                uf: 'SP',
                code: 3552700,
            },
            {
                name: 'Taboão da Serra',
                uf: 'SP',
                code: 3552809,
            },
            {
                name: 'Taciba',
                uf: 'SP',
                code: 3552908,
            },
            {
                name: 'Taguaí',
                uf: 'SP',
                code: 3553005,
            },
            {
                name: 'Taiaçu',
                uf: 'SP',
                code: 3553104,
            },
            {
                name: 'Taiúva',
                uf: 'SP',
                code: 3553203,
            },
            {
                name: 'Tambaú',
                uf: 'SP',
                code: 3553302,
            },
            {
                name: 'Tanabi',
                uf: 'SP',
                code: 3553401,
            },
            {
                name: 'Tapiraí',
                uf: 'SP',
                code: 3553500,
            },
            {
                name: 'Tapiratiba',
                uf: 'SP',
                code: 3553609,
            },
            {
                name: 'Taquaral',
                uf: 'SP',
                code: 3553658,
            },
            {
                name: 'Taquaritinga',
                uf: 'SP',
                code: 3553708,
            },
            {
                name: 'Taquarituba',
                uf: 'SP',
                code: 3553807,
            },
            {
                name: 'Taquarivaí',
                uf: 'SP',
                code: 3553856,
            },
            {
                name: 'Tarabai',
                uf: 'SP',
                code: 3553906,
            },
            {
                name: 'Tarumã',
                uf: 'SP',
                code: 3553955,
            },
            {
                name: 'Tatuí',
                uf: 'SP',
                code: 3554003,
            },
            {
                name: 'Taubaté',
                uf: 'SP',
                code: 3554102,
            },
            {
                name: 'Tejupá',
                uf: 'SP',
                code: 3554201,
            },
            {
                name: 'Teodoro Sampaio',
                uf: 'SP',
                code: 3554300,
            },
            {
                name: 'Terra Roxa',
                uf: 'SP',
                code: 3554409,
            },
            {
                name: 'Tietê',
                uf: 'SP',
                code: 3554508,
            },
            {
                name: 'Timburi',
                uf: 'SP',
                code: 3554607,
            },
            {
                name: 'Torre de Pedra',
                uf: 'SP',
                code: 3554656,
            },
            {
                name: 'Torrinha',
                uf: 'SP',
                code: 3554706,
            },
            {
                name: 'Trabiju',
                uf: 'SP',
                code: 3554755,
            },
            {
                name: 'Tremembé',
                uf: 'SP',
                code: 3554805,
            },
            {
                name: 'Três Fronteiras',
                uf: 'SP',
                code: 3554904,
            },
            {
                name: 'Tuiuti',
                uf: 'SP',
                code: 3554953,
            },
            {
                name: 'Tupã',
                uf: 'SP',
                code: 3555000,
            },
            {
                name: 'Tupi Paulista',
                uf: 'SP',
                code: 3555109,
            },
            {
                name: 'Turiúba',
                uf: 'SP',
                code: 3555208,
            },
            {
                name: 'Turmalina',
                uf: 'SP',
                code: 3555307,
            },
            {
                name: 'Ubarana',
                uf: 'SP',
                code: 3555356,
            },
            {
                name: 'Ubatuba',
                uf: 'SP',
                code: 3555406,
            },
            {
                name: 'Ubirajara',
                uf: 'SP',
                code: 3555505,
            },
            {
                name: 'Uchoa',
                uf: 'SP',
                code: 3555604,
            },
            {
                name: 'União Paulista',
                uf: 'SP',
                code: 3555703,
            },
            {
                name: 'Urânia',
                uf: 'SP',
                code: 3555802,
            },
            {
                name: 'Uru',
                uf: 'SP',
                code: 3555901,
            },
            {
                name: 'Urupês',
                uf: 'SP',
                code: 3556008,
            },
            {
                name: 'Valentim Gentil',
                uf: 'SP',
                code: 3556107,
            },
            {
                name: 'Valinhos',
                uf: 'SP',
                code: 3556206,
            },
            {
                name: 'Valparaíso',
                uf: 'SP',
                code: 3556305,
            },
            {
                name: 'Vargem',
                uf: 'SP',
                code: 3556354,
            },
            {
                name: 'Vargem Grande do Sul',
                uf: 'SP',
                code: 3556404,
            },
            {
                name: 'Vargem Grande Paulista',
                uf: 'SP',
                code: 3556453,
            },
            {
                name: 'Várzea Paulista',
                uf: 'SP',
                code: 3556503,
            },
            {
                name: 'Vera Cruz',
                uf: 'SP',
                code: 3556602,
            },
            {
                name: 'Vinhedo',
                uf: 'SP',
                code: 3556701,
            },
            {
                name: 'Viradouro',
                uf: 'SP',
                code: 3556800,
            },
            {
                name: 'Vista Alegre do Alto',
                uf: 'SP',
                code: 3556909,
            },
            {
                name: 'Vitória Brasil',
                uf: 'SP',
                code: 3556958,
            },
            {
                name: 'Votorantim',
                uf: 'SP',
                code: 3557006,
            },
            {
                name: 'Votuporanga',
                uf: 'SP',
                code: 3557105,
            },
            {
                name: 'Zacarias',
                uf: 'SP',
                code: 3557154,
            },
            {
                name: 'Abreulândia',
                uf: 'TO',
                code: 1700251,
            },
            {
                name: 'Aguiarnópolis',
                uf: 'TO',
                code: 1700301,
            },
            {
                name: 'Aliança do Tocantins',
                uf: 'TO',
                code: 1700350,
            },
            {
                name: 'Almas',
                uf: 'TO',
                code: 1700400,
            },
            {
                name: 'Alvorada',
                uf: 'TO',
                code: 1700707,
            },
            {
                name: 'Ananás',
                uf: 'TO',
                code: 1701002,
            },
            {
                name: 'Angico',
                uf: 'TO',
                code: 1701051,
            },
            {
                name: 'Aparecida do Rio Negro',
                uf: 'TO',
                code: 1701101,
            },
            {
                name: 'Aragominas',
                uf: 'TO',
                code: 1701309,
            },
            {
                name: 'Araguacema',
                uf: 'TO',
                code: 1701903,
            },
            {
                name: 'Araguaçu',
                uf: 'TO',
                code: 1702000,
            },
            {
                name: 'Araguaína',
                uf: 'TO',
                code: 1702109,
            },
            {
                name: 'Araguanã',
                uf: 'TO',
                code: 1702158,
            },
            {
                name: 'Araguatins',
                uf: 'TO',
                code: 1702208,
            },
            {
                name: 'Arapoema',
                uf: 'TO',
                code: 1702307,
            },
            {
                name: 'Arraias',
                uf: 'TO',
                code: 1702406,
            },
            {
                name: 'Augustinópolis',
                uf: 'TO',
                code: 1702554,
            },
            {
                name: 'Aurora do Tocantins',
                uf: 'TO',
                code: 1702703,
            },
            {
                name: 'Axixá do Tocantins',
                uf: 'TO',
                code: 1702901,
            },
            {
                name: 'Babaçulândia',
                uf: 'TO',
                code: 1703008,
            },
            {
                name: 'Bandeirantes do Tocantins',
                uf: 'TO',
                code: 1703057,
            },
            {
                name: 'Barra do Ouro',
                uf: 'TO',
                code: 1703073,
            },
            {
                name: 'Barrolândia',
                uf: 'TO',
                code: 1703107,
            },
            {
                name: 'Bernardo Sayão',
                uf: 'TO',
                code: 1703206,
            },
            {
                name: 'Bom Jesus do Tocantins',
                uf: 'TO',
                code: 1703305,
            },
            {
                name: 'Brasilândia do Tocantins',
                uf: 'TO',
                code: 1703602,
            },
            {
                name: 'Brejinho de Nazaré',
                uf: 'TO',
                code: 1703701,
            },
            {
                name: 'Buriti do Tocantins',
                uf: 'TO',
                code: 1703800,
            },
            {
                name: 'Cachoeirinha',
                uf: 'TO',
                code: 1703826,
            },
            {
                name: 'Campos Lindos',
                uf: 'TO',
                code: 1703842,
            },
            {
                name: 'Cariri do Tocantins',
                uf: 'TO',
                code: 1703867,
            },
            {
                name: 'Carmolândia',
                uf: 'TO',
                code: 1703883,
            },
            {
                name: 'Carrasco Bonito',
                uf: 'TO',
                code: 1703891,
            },
            {
                name: 'Caseara',
                uf: 'TO',
                code: 1703909,
            },
            {
                name: 'Centenário',
                uf: 'TO',
                code: 1704105,
            },
            {
                name: 'Chapada da Natividade',
                uf: 'TO',
                code: 1705102,
            },
            {
                name: 'Chapada de Areia',
                uf: 'TO',
                code: 1704600,
            },
            {
                name: 'Colinas do Tocantins',
                uf: 'TO',
                code: 1705508,
            },
            {
                name: 'Colméia',
                uf: 'TO',
                code: 1716703,
            },
            {
                name: 'Combinado',
                uf: 'TO',
                code: 1705557,
            },
            {
                name: 'Conceição do Tocantins',
                uf: 'TO',
                code: 1705607,
            },
            {
                name: 'Couto Magalhães',
                uf: 'TO',
                code: 1706001,
            },
            {
                name: 'Cristalândia',
                uf: 'TO',
                code: 1706100,
            },
            {
                name: 'Crixás do Tocantins',
                uf: 'TO',
                code: 1706258,
            },
            {
                name: 'Darcinópolis',
                uf: 'TO',
                code: 1706506,
            },
            {
                name: 'Dianópolis',
                uf: 'TO',
                code: 1707009,
            },
            {
                name: 'Divinópolis do Tocantins',
                uf: 'TO',
                code: 1707108,
            },
            {
                name: 'Dois Irmãos do Tocantins',
                uf: 'TO',
                code: 1707207,
            },
            {
                name: 'Dueré',
                uf: 'TO',
                code: 1707306,
            },
            {
                name: 'Esperantina',
                uf: 'TO',
                code: 1707405,
            },
            {
                name: 'Fátima',
                uf: 'TO',
                code: 1707553,
            },
            {
                name: 'Figueirópolis',
                uf: 'TO',
                code: 1707652,
            },
            {
                name: 'Filadélfia',
                uf: 'TO',
                code: 1707702,
            },
            {
                name: 'Formoso do Araguaia',
                uf: 'TO',
                code: 1708205,
            },
            {
                name: 'Fortaleza do Tabocão',
                uf: 'TO',
                code: 1708254,
            },
            {
                name: 'Goianorte',
                uf: 'TO',
                code: 1708304,
            },
            {
                name: 'Goiatins',
                uf: 'TO',
                code: 1709005,
            },
            {
                name: 'Guaraí',
                uf: 'TO',
                code: 1709302,
            },
            {
                name: 'Gurupi',
                uf: 'TO',
                code: 1709500,
            },
            {
                name: 'Ipueiras',
                uf: 'TO',
                code: 1709807,
            },
            {
                name: 'Itacajá',
                uf: 'TO',
                code: 1710508,
            },
            {
                name: 'Itaguatins',
                uf: 'TO',
                code: 1710706,
            },
            {
                name: 'Itapiratins',
                uf: 'TO',
                code: 1710904,
            },
            {
                name: 'Itaporã do Tocantins',
                uf: 'TO',
                code: 1711100,
            },
            {
                name: 'Jaú do Tocantins',
                uf: 'TO',
                code: 1711506,
            },
            {
                name: 'Juarina',
                uf: 'TO',
                code: 1711803,
            },
            {
                name: 'Lagoa da Confusão',
                uf: 'TO',
                code: 1711902,
            },
            {
                name: 'Lagoa do Tocantins',
                uf: 'TO',
                code: 1711951,
            },
            {
                name: 'Lajeado',
                uf: 'TO',
                code: 1712009,
            },
            {
                name: 'Lavandeira',
                uf: 'TO',
                code: 1712157,
            },
            {
                name: 'Lizarda',
                uf: 'TO',
                code: 1712405,
            },
            {
                name: 'Luzinópolis',
                uf: 'TO',
                code: 1712454,
            },
            {
                name: 'Marianópolis do Tocantins',
                uf: 'TO',
                code: 1712504,
            },
            {
                name: 'Mateiros',
                uf: 'TO',
                code: 1712702,
            },
            {
                name: 'Maurilândia do Tocantins',
                uf: 'TO',
                code: 1712801,
            },
            {
                name: 'Miracema do Tocantins',
                uf: 'TO',
                code: 1713205,
            },
            {
                name: 'Miranorte',
                uf: 'TO',
                code: 1713304,
            },
            {
                name: 'Monte do Carmo',
                uf: 'TO',
                code: 1713601,
            },
            {
                name: 'Monte Santo do Tocantins',
                uf: 'TO',
                code: 1713700,
            },
            {
                name: 'Muricilândia',
                uf: 'TO',
                code: 1713957,
            },
            {
                name: 'Natividade',
                uf: 'TO',
                code: 1714203,
            },
            {
                name: 'Nazaré',
                uf: 'TO',
                code: 1714302,
            },
            {
                name: 'Nova Olinda',
                uf: 'TO',
                code: 1714880,
            },
            {
                name: 'Nova Rosalândia',
                uf: 'TO',
                code: 1715002,
            },
            {
                name: 'Novo Acordo',
                uf: 'TO',
                code: 1715101,
            },
            {
                name: 'Novo Alegre',
                uf: 'TO',
                code: 1715150,
            },
            {
                name: 'Novo Jardim',
                uf: 'TO',
                code: 1715259,
            },
            {
                name: 'Oliveira de Fátima',
                uf: 'TO',
                code: 1715507,
            },
            {
                name: 'Palmas',
                uf: 'TO',
                code: 1721000,
            },
            {
                name: 'Palmeirante',
                uf: 'TO',
                code: 1715705,
            },
            {
                name: 'Palmeiras do Tocantins',
                uf: 'TO',
                code: 1713809,
            },
            {
                name: 'Palmeirópolis',
                uf: 'TO',
                code: 1715754,
            },
            {
                name: 'Paraíso do Tocantins',
                uf: 'TO',
                code: 1716109,
            },
            {
                name: 'Paranã',
                uf: 'TO',
                code: 1716208,
            },
            {
                name: "Pau D'Arco",
                uf: 'TO',
                code: 1716307,
            },
            {
                name: 'Pedro Afonso',
                uf: 'TO',
                code: 1716505,
            },
            {
                name: 'Peixe',
                uf: 'TO',
                code: 1716604,
            },
            {
                name: 'Pequizeiro',
                uf: 'TO',
                code: 1716653,
            },
            {
                name: 'Pindorama do Tocantins',
                uf: 'TO',
                code: 1717008,
            },
            {
                name: 'Piraquê',
                uf: 'TO',
                code: 1717206,
            },
            {
                name: 'Pium',
                uf: 'TO',
                code: 1717503,
            },
            {
                name: 'Ponte Alta do Bom Jesus',
                uf: 'TO',
                code: 1717800,
            },
            {
                name: 'Ponte Alta do Tocantins',
                uf: 'TO',
                code: 1717909,
            },
            {
                name: 'Porto Alegre do Tocantins',
                uf: 'TO',
                code: 1718006,
            },
            {
                name: 'Porto Nacional',
                uf: 'TO',
                code: 1718204,
            },
            {
                name: 'Praia Norte',
                uf: 'TO',
                code: 1718303,
            },
            {
                name: 'Presidente Kennedy',
                uf: 'TO',
                code: 1718402,
            },
            {
                name: 'Pugmil',
                uf: 'TO',
                code: 1718451,
            },
            {
                name: 'Recursolândia',
                uf: 'TO',
                code: 1718501,
            },
            {
                name: 'Riachinho',
                uf: 'TO',
                code: 1718550,
            },
            {
                name: 'Rio da Conceição',
                uf: 'TO',
                code: 1718659,
            },
            {
                name: 'Rio dos Bois',
                uf: 'TO',
                code: 1718709,
            },
            {
                name: 'Rio Sono',
                uf: 'TO',
                code: 1718758,
            },
            {
                name: 'Sampaio',
                uf: 'TO',
                code: 1718808,
            },
            {
                name: 'Sandolândia',
                uf: 'TO',
                code: 1718840,
            },
            {
                name: 'Santa Fé do Araguaia',
                uf: 'TO',
                code: 1718865,
            },
            {
                name: 'Santa Maria do Tocantins',
                uf: 'TO',
                code: 1718881,
            },
            {
                name: 'Santa Rita do Tocantins',
                uf: 'TO',
                code: 1718899,
            },
            {
                name: 'Santa Rosa do Tocantins',
                uf: 'TO',
                code: 1718907,
            },
            {
                name: 'Santa Tereza do Tocantins',
                uf: 'TO',
                code: 1719004,
            },
            {
                name: 'Santa Terezinha do Tocantins',
                uf: 'TO',
                code: 1720002,
            },
            {
                name: 'São Bento do Tocantins',
                uf: 'TO',
                code: 1720101,
            },
            {
                name: 'São Félix do Tocantins',
                uf: 'TO',
                code: 1720150,
            },
            {
                name: 'São Miguel do Tocantins',
                uf: 'TO',
                code: 1720200,
            },
            {
                name: 'São Salvador do Tocantins',
                uf: 'TO',
                code: 1720259,
            },
            {
                name: 'São Sebastião do Tocantins',
                uf: 'TO',
                code: 1720309,
            },
            {
                name: 'São Valério',
                uf: 'TO',
                code: 1720499,
            },
            {
                name: 'Silvanópolis',
                uf: 'TO',
                code: 1720655,
            },
            {
                name: 'Sítio Novo do Tocantins',
                uf: 'TO',
                code: 1720804,
            },
            {
                name: 'Sucupira',
                uf: 'TO',
                code: 1720853,
            },
            {
                name: 'Taguatinga',
                uf: 'TO',
                code: 1720903,
            },
            {
                name: 'Taipas do Tocantins',
                uf: 'TO',
                code: 1720937,
            },
            {
                name: 'Talismã',
                uf: 'TO',
                code: 1720978,
            },
            {
                name: 'Tocantínia',
                uf: 'TO',
                code: 1721109,
            },
            {
                name: 'Tocantinópolis',
                uf: 'TO',
                code: 1721208,
            },
            {
                name: 'Tupirama',
                uf: 'TO',
                code: 1721257,
            },
            {
                name: 'Tupiratins',
                uf: 'TO',
                code: 1721307,
            },
            {
                name: 'Wanderlândia',
                uf: 'TO',
                code: 1722081,
            },
            {
                name: 'Xambioá',
                uf: 'TO',
                code: 1722107,
            },
        ]
}

export default CITIES
