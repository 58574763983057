import MuiTableCell from '@mui/material/TableCell'
import { TableCellProps, Typography } from '@mui/material'
import { Link } from 'react-router-dom';

type CustomTableCellProps = {
  to?: string,
  ellipsis?: boolean,
  color?: string,
} & TableCellProps

function TableCell(props: CustomTableCellProps) {
  const { to, ellipsis, color, ...rest } = props

  return (
    <MuiTableCell {...rest}>

      {to ? (
        <Link to={to ? to : ''} className={`table-cell-content ${ellipsis ? 'ellipsis-text' : ''}`}
          onClick={(e) => {
            if ((e.target as HTMLElement).classList.contains('table-checkbox') ||
              (e.target as HTMLElement).classList.contains('action-button-table'))
              e.preventDefault()
          }}
        >
          <Typography variant='caption' color={color}>
            {props.children}
          </Typography>
        </Link>
      ) : (
        <div className={`table-cell-content ${ellipsis ? 'ellipsis-text' : ''}`}>
          <Typography variant='caption' color={color}>
            {props.children}
          </Typography>
        </div>
      )}
    </MuiTableCell>
  );
}

export { TableCell };