import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'

import { CustomError, api } from 'utils/axios';

type ActiveCustomerType = {
  value: number,
  date: string,
}

type ActiveCustomerDisplayType = {
  label: Array<string>,
  data: Array<number>
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

const parseActiveCustomers = (response: { data: any, meta: any }) => {

  return response.data.map((data: any) => {
    return {
      value: data.attributes.value,
      date: data.attributes.date.date
    }
  });
}

const getActiveCustomers = async (filters: Array<FilterType>, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  filters.push({
    field: 'granularity',
    comparator: 'eq',
    value: 'MONTHLY'
  })

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/metrics/ending-customer', {
      params,
      signal
    });

    return parseActiveCustomers(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatActiveCustomersToDisplay = (activeCustomerList: Array<ActiveCustomerType>): ActiveCustomerDisplayType => {

  const labels: Array<string> = []
  const values: Array<number> = []

  activeCustomerList.forEach(element => {
    labels.push(dayjs(element.date).locale('pt-br').format('MMM/YY'))
    values.push(element.value)
  });

  return {
    label: labels,
    data: values
  }

}

export { getActiveCustomers, formatActiveCustomersToDisplay };

export type { FilterType, ActiveCustomerType, ActiveCustomerDisplayType };