import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#008037',
    },
    secondary: {
      main: '#393939',
    },
    error: {
      main: '#E04A77',
    },
    warning: {
      main: '#FFF0CE',
    },
    text: {
      primary: '#393939'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Satoshi',
    button: {
      textTransform: 'none',
      fontWeight: 700,
    },
    h1: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.625rem',
      fontWeight: 600,
      lineHeight: 1.32,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 1.32,
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.2,
    },
  },
};