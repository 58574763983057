import {useEffect, useState} from 'react';
import {
  ProductDisplayType,
  formatProductToDisplay,
  ProductType
} from "./services";

export default function ViewGeneralPlanViewModel(productData?: ProductType) {

  const [product] = useState<ProductType | undefined>(productData)
  const [boleto, setBoleto] = useState<boolean>(true);
  const [creditCard, setCreditCard] = useState<boolean>(true);
  const [pix, setPix] = useState<boolean>(true);

  const [formattedProduct, setFormattedProduct] = useState<ProductDisplayType | undefined>(productData ? formatProductToDisplay(productData, creditCard, boleto, pix) : undefined);

  useEffect(() => {
    if (productData)
      setFormattedProduct(formatProductToDisplay(productData, creditCard, boleto, pix))
  }, [boleto, creditCard, pix, product, productData])

  const onChangeBoleto = (selected:boolean) => {
    setBoleto(selected)
    if (productData)
      setFormattedProduct(formatProductToDisplay(productData, creditCard, boleto, pix))
  }

  const onChangeCreditCard = (selected:boolean) => {
    setCreditCard(selected)
    if (productData)
      setFormattedProduct(formatProductToDisplay(productData, creditCard, boleto, pix))
  }

  const onChangePix = (selected:boolean) => {
    setPix(selected)
    if (productData)
      setFormattedProduct(formatProductToDisplay(productData, creditCard, boleto, pix))
  }

  return {
    boleto,
    creditCard,
    pix,
    formattedProduct,
    onChangeBoleto,
    onChangeCreditCard,
    onChangePix
  }
}