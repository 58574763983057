import { CustomError, api } from 'utils/axios';

import { formatMoney } from '../../../../utils/moneyFormatt'
import { formatPercent } from '../../../../utils/percentFormat'

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean,
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type CouponsType = {
  id: string,
  name: string,
  code: string,
  status: string,
  type: string,
  value: number,
  redemptions: number,
}

type CouponsDisplayType = {
  id: string,
  name: string,
  code: string,
  status: string,
  type: string,
  value: string,
  redemptions: number,
  actions: ActionType[]
}


const parseCoupons = (response: any) => {
  const coupons = response.data.map((data: any) => ({
    id: data.id,
    name: data.attributes.name,
    code: data.attributes.code,
    status: data.attributes.status,
    type: data.attributes.type,
    value: data.attributes.value,
    redemptions: data.attributes.redemptions,
  }))

  return {
    coupons,
    pagination: {
      totalCoupons: response.meta.pagination.total
    }
  }
}

const getCoupons = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (filter.value.length)
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/coupons', {
      params,
      signal
    });

    return parseCoupons(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatCouponsToDisplay = (coupons: Array<CouponsType>): Array<CouponsDisplayType> => {

  return coupons.map((coupon: CouponsType) => {

    let actions: ActionType[] = []

    switch (coupon.status) {
      case 'ACTIVE':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'activate', label: 'Ativar', disabled: true },
          { id: 'deactivate', label: 'Desativar' },
        ]
        break;

      case 'INACTIVE':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'activate', label: 'Ativar' },
          { id: 'deactivate', label: 'Desativar', disabled: true },
        ]
        break;
    }

    return {
      id: coupon.id,
      name: coupon.name,
      code: coupon.code,
      status: coupon.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
      type: coupon.type === 'PERCENTAGE' ? 'PORCENTAGEM' : 'FIXO',
      value: coupon.type === 'PERCENTAGE' ? formatPercent(coupon.value) : formatMoney(coupon.value),
      redemptions: coupon.redemptions,
      actions,
    }
  })

}

export { getCoupons, formatCouponsToDisplay };

export type { PaginationType, SortType, CouponsType, CouponsDisplayType, FilterType };
