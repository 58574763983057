import { CheckoutOrderPayloadType } from 'recoil/checkout/states';
import { CustomError, apiCheckout } from 'utils/axios';
import { formatMoney } from 'utils/moneyFormatt';

type SaleType = {
    total: number,
    totalFormatted: string,
    subtotal: number,
    subtotalFormatted: string,
    setup: number,
    setupFormatted: string,
    discount: number,
    discountFormatted: string,
}

type ChargeType = {
    uom: string,
    type: string,
    value: number,
    recurrence: number
}

type CompanyType = {
    company_logo: string,
    name: string,
    email: string,
    registration: string,
    privacy_policy: string,
    term_of_use: string,
}

type ProductType = {
    hash: string,
    plan_name: string,
    product_name: string,
    frequency: number,
    first_payment_term_type: string,
    first_payment_term_value: number,
    payment_term_type: string,
    payment_term_value: number,
    company: CompanyType,
    charges: ChargeType[]
}

type CouponType = {
    hash: string,
    name: string,
    type: string,
    code: string,
    value: number,
};

const parseSale = (product: ProductType, coupon?: CouponType): SaleType => {
    let total: number;
    let subtotal: number = 0;
    let discount: number = 0;
    let setup: number = 0;
    product.charges.forEach(charge => {
        if (charge.recurrence === 1) {
            setup += charge.value;
        } else {
            subtotal += charge.value;
        }
    });

    total = subtotal + setup;

    if (coupon?.hash) {
        switch (coupon.type) {
            case 'FIXED':
              discount = coupon.value ? coupon.value : 0
              break;
      
            case 'PERCENTAGE':
              discount = total * (coupon.value ? coupon.value : 0)
              break;
          }
    }

    total = total - discount
    
    return {
        total: total,
        totalFormatted: formatMoney(total),
        subtotal: subtotal,
        subtotalFormatted: formatMoney(subtotal),
        setup: setup,
        setupFormatted: formatMoney(setup),
        discount: discount,
        discountFormatted: formatMoney(discount)
    }
}

const parseCoupon = (data: any): CouponType => {
    return {
        hash: data.hash,
        name: data.name,
        type: data.type,
        code: data.code,
        value: data.value
    };
}

const getCoupons = async (productHash: string, couponCode: string, signal: AbortSignal): Promise<any> => {
    try {
        const response = await apiCheckout.get(
            '/products/' + productHash + '/coupon/' + couponCode, { signal });
        return parseCoupon(response.data);

    } catch (error) {
        const errorResponse = (error as CustomError).errors
        if (errorResponse)
            return { errors: errorResponse };

        return { errors: [] };
    }
}

const parseProducts = (response: { data: any }): ProductType => {
    return {
        plan_name: response.data.name,
        product_name: response.data.product_name,
        frequency: response.data.frequency,
        hash: response.data.hash,
        first_payment_term_type: response.data.first_payment_term_type,
        first_payment_term_value: response.data.first_payment_term_value,
        payment_term_type: response.data.payment_term_type,
        payment_term_value: response.data.payment_term_value,
        company: {
            name: response.data.company.name,
            email: response.data.company.email,
            registration: response.data.company.registration,
            company_logo: response.data.company.company_logo,
            term_of_use: response.data.company.term_of_use,
            privacy_policy: response.data.company.privacy_policy,
        },
        charges: response.data.charges ? response.data.charges.map((charge: ChargeType) => ({
            uom: charge.uom,
            type: charge.type,
            value: charge.value,
            recurrence: charge.recurrence
        })) : []
    }
}

const getProducts = async (productHash: string, signal: AbortSignal): Promise<any> => {
    try {
        const response = await apiCheckout.get('/products/' + productHash, { signal });
        return parseProducts(response);

    } catch (error) {
        const errorResponse = (error as CustomError).errors
        if (errorResponse)
            return { errors: errorResponse };

        return { errors: [] };
    }
}

const bundlePayloadToRecoil = (product?: ProductType, sale?: SaleType, coupon?: CouponType): CheckoutOrderPayloadType => {
    let productRecoil = {}
    let couponRecoil = {}
    let saleRecoil = {}
    let companyRecoil = {}
    let chargeRecoil: any = []

    if (product) {
        productRecoil = {
            hash: product.hash,
            plan_name: product.plan_name,
            product_name: product.product_name,
            frequency: product.frequency,
            first_payment_term_type: product.first_payment_term_type,
            first_payment_term_value: product.first_payment_term_value,
            payment_term_type: product.payment_term_type,
            payment_term_value: product.payment_term_value
        }

        if (product.company) {
            companyRecoil = {
                company_logo: product.company.company_logo,
                name: product.company.name,
                email: product.company.email,
                registration: product.company.registration,
                privacy_policy: product.company.privacy_policy,
                term_of_use: product.company.term_of_use,
            }
        }
    
        if (product.charges) {
            chargeRecoil = product.charges.map((charge) => ({
                uom: charge.uom,
                type: charge.type,
                value: charge.value,
                recurrence: charge.recurrence
            }))
        }
    }

    if (coupon) {
        couponRecoil = {
            hash: coupon.hash,
            name: coupon.name,
            type: coupon.type,
            code: coupon.code,
            value: coupon.value
        }
    }

    if (sale) {
        saleRecoil = {
            total: sale.total,
            subtotal: sale.subtotal,
            setup: sale.setup,
            discount: sale.discount
        }
    }

    return {
        product: productRecoil,
        company: companyRecoil,
        charges: chargeRecoil,
        coupon: couponRecoil,
        sale: saleRecoil,
    }
}

const formatCheckoutPayloadToDisplayProduct = (values: CheckoutOrderPayloadType): ProductType => {
    let product: any;
    let company: any;
    let charges: any;

    if (values.company)
        company = {
            company_logo: values.company.company_logo,
            name: values.company.name,
            email: values.company.email,
            registration: values.company.registration,
            privacy_policy: values.company.privacy_policy,
            term_of_use: values.company.term_of_use,
        }

    if (values.charges)
        charges = values.charges.map((charge) => ({
            uom: charge.uom,
            type: charge.type,
            value: charge.value,
            recurrence: charge.recurrence
        }))

    if (values.product)
        product = {
            hash: values.product.hash,
            product_name: values.product.product_name,
            plan_name: values.product.plan_name,
            frequency: values.product.frequency,
            first_payment_term_type: values.product.first_payment_term_type,
            first_payment_term_value: values.product.first_payment_term_value,
            payment_term_type: values.product.payment_term_type,
            payment_term_value: values.product.payment_term_value,
            company: company,
            charges: charges,
        }

    return product
}

const formatCheckoutPayloadToDisplayCoupon = (values: CheckoutOrderPayloadType): CouponType | null => {
    let coupon: any;

    if (values.coupon)
        coupon = {
            hash: values.coupon.hash,
            name: values.coupon.name,
            code: values.coupon.code,
            type: values.coupon.type,
            value: values.coupon.value
        }

    return coupon
}

const formatCheckoutPayloadToDisplaySale = (values: CheckoutOrderPayloadType): SaleType | null => {
    let sales: any;

    if (values.sale) {
        sales = {
            total: values.sale.total,
            totalFormatted: formatMoney(values.sale.total ?? 0),
            subtotal: values.sale.subtotal,
            subtotalFormatted: formatMoney(values.sale.subtotal ?? 0),
            setup: values.sale.setup,
            setupFormatted: formatMoney(values.sale.setup ?? 0),
            discount: values.sale.discount,
            discountFormatted: formatMoney(values.sale.discount ?? 0),
        }
    }

    return sales
}

export {
    parseSale,
    getCoupons,
    getProducts,
    bundlePayloadToRecoil,
    formatCheckoutPayloadToDisplayProduct,
    formatCheckoutPayloadToDisplayCoupon,
    formatCheckoutPayloadToDisplaySale
}

export type {
    ProductType,
    CompanyType,
    CouponType,
    SaleType
}