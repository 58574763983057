import { CustomError, api } from 'utils/axios'

type CashierData = {
  id: string,
  name?: string,
  bank_code?: string,
  bank_name?: string,
  routing_number?: string,
  routing_number_digit_verifier?: string,
  account_number?: string,
  account_number_digit_verifier?: string
}

type CashierBankPayload = {
  id: string,
  name: string,
  type: string,
  bank_code?: string,
  bank_name?: string,
  routing_number?: string,
  routing_number_digit_verifier?: string,
  account_number?: string,
  account_number_digit_verifier?: string
}

type ParsedCashierBankPayload = {
  name: string,
  type: string,
  bank_code?: string,
  bank_name?: string,
  routing_number?: string,
  routing_number_digit_verifier?: string,
  account_number?: string,
  account_number_digit_verifier?: string
}

const formatCashierBanksToDisplay = (values: CashierData): CashierBankPayload => {
  let type;

  if (values.bank_code)
    type = 'CASHIER_BANK_ACCOUNT';
  else
    type = 'CASHIER_CASH';

  return {
    id: values.id,
    type,
    name: values.name ? values.name : '',
    bank_code: values.bank_code,
    bank_name: values.bank_name,
    routing_number: values.routing_number,
    routing_number_digit_verifier: values.routing_number_digit_verifier,
    account_number: values.account_number,
    account_number_digit_verifier: values.account_number_digit_verifier
  }
}

const bundlePayload = (payload: CashierBankPayload): ParsedCashierBankPayload => (
  {
    name: payload.name,
    type: payload.type,
    bank_code: payload.bank_code,
    bank_name: payload.bank_name,
    routing_number: payload.routing_number,
    routing_number_digit_verifier: payload.routing_number_digit_verifier,
    account_number: payload.account_number,
    account_number_digit_verifier: payload.account_number_digit_verifier
  }
)

const patchCashierBanks = async (payload: CashierBankPayload): Promise<any> => {
  try {
    const response = await api.patch(`/api-cashone/cashiers/${payload.id}`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { patchCashierBanks, formatCashierBanksToDisplay }

export type { CashierData, ParsedCashierBankPayload, CashierBankPayload }