import { Alert, Box, Button, Modal, Paper, Snackbar, Typography, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


import useViewModel, { AccountsReceivablesType } from './viewModel'

import '../../styles.scss'
import './styles.scss'

type AcknowledgeARModalPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function AcknowledgeARModal({ accountReceivableData, open, onClose, onSubmit }: AcknowledgeARModalPropTypes) {
  const {
    ARLoading,
    ARError,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ accountReceivableData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='acknowledge-ar-modal custom-modal'>
          <div className='modal-header'>
            <Typography variant='h2'>
              {`Dar baixa no conta a receber ${accountReceivableData.key}`}
            </Typography>
            <div className='modal-close-button' onClick={onClose}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body'>
            <Typography>
              Tem certeza que deseja dar baixa deste conta a receber?
            </Typography>
          </div>

          <div className='modal-footer'>
            {ARLoading && <CircularProgress className='loading' color='primary' size={20} />}
            <Button variant='outlined' color='error' disabled={ARLoading} onClick={onClose}>Não</Button>
            <Button variant='contained' disabled={ARLoading} onClick={handleSubmit}>Sim</Button>
          </div>
        </Box>
      </Modal>

      {ARError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!ARError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            {`Pagamento do conta a receber ${accountReceivableData.key} reconhecido!`}
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { AcknowledgeARModal }