import { Dayjs } from 'dayjs';
import { CustomError, api } from 'utils/axios';

const bundleDueDate = (date: Dayjs) => date ? date.format('YYYY-MM-DD') : ''

const postDueDate = async (id: string, values: Record<string, any>): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/accounts-receivables/${id}/change-due-date`, {
      due_date: bundleDueDate(values.due_date)
    })

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postDueDate }