import dayjs from 'dayjs';
import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form';

import { Flexbox } from 'components/Flexbox';
import { DatePickerFieldAdapter } from 'components/FormComponents/Fields/DatePickerFieldAdapter';

import useViewModel, { AccountsReceivablesType } from './viewModel'

import '../../styles.scss'
import './styles.scss'

type CancelARModalPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function DueDateARModal({ accountReceivableData, open, onClose, onSubmit }: CancelARModalPropTypes) {
  const {
    ARError,
    ARLoading,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ accountReceivableData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='due-date-ar-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              due_date: accountReceivableData.due_date ?
                dayjs(accountReceivableData.due_date) :
                ''
            }}
            validate={formValidate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className='modal-header'>
                  <Typography variant='h2'>
                    {`Alterar data de vencimento do conta a receber ${accountReceivableData.key}`}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Flexbox size={12}>
                    <DatePickerFieldAdapter
                      label='Vencimento'
                      loading={ARLoading}
                      name='due_date'
                      minDate={dayjs().add(1, 'day')}
                    />
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  {ARLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' color='error' onClick={onClose}>Cancelar</Button>
                  <Button variant='contained' type='submit' disabled={ARLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {ARError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!ARError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Data de vencimento atualizada!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { DueDateARModal }