import { ChangeEvent, Fragment } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, CheckboxProps, Skeleton } from '@mui/material';

import './styles.scss'

type CheckboxFieldAdapterPropTypes = CheckboxProps & {
  name: string,
  label?: string,
  loading?: boolean,
  options: Array<{
    label: string,
    value: string
  }>,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  validate?: FieldValidator<any>
}

const ComponentField = ({
  input,
  label,
  onChange,
  ...rest
}: FieldRenderProps<any>) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          {...input}
          {...rest}
          onChange={(e) => {
            input.onChange(e);
            if (onChange)
              onChange(e);
          }}
        />
      }
    />
  )
}

function CheckboxFieldAdapter({
  name,
  label,
  loading = false,
  options,
  onChange,
  validate,
  ...rest
}: CheckboxFieldAdapterPropTypes) {

  return (
    <div className='field'>
      <FormControl className='checkbox-field-form-control'>
        {label &&
          <FormLabel>{label}</FormLabel>
        }

        <FormGroup className='checkbox-field-form-group'>
          {options.map((option: any, index: number) => (
            <Fragment key={index}>
              {loading ? (
                <Skeleton width='100%' height='55px' key={index} />
              ) : (
                <Field
                  component={ComponentField as any}
                  key={index}
                  name={name}
                  label={option.label}
                  value={option.value}
                  onChange={onChange}
                  validate={validate}
                  type='checkbox'
                  {...rest}
                />
              )}
            </Fragment>
          ))}
        </FormGroup>

        <FormHelperText></FormHelperText>
      </FormControl>
    </div>
  )
}

export { CheckboxFieldAdapter }