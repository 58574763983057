import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton } from '@mui/material';

import { TableCell } from '../Table/TableCell';
import { TableHead } from '../Table/TableHead';
import { ActionButtonTable } from '../Table/ActionButtonTable';

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  isLoading: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  onClickOption?: (index: number, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function BankListTable({
  hasActions,
  headCells,
  isAsc,
  isLoading,
  page,
  optionsRowsPerPage,
  orderBy,
  rows,
  rowsPerPage,
  total,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {
  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAsc={isAsc}
          optionMenu={hasActions}
          isAnySelected={false}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>
          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headCells.map((cell, index) => (
                <TableCell key={index}><Skeleton /></TableCell>
              ))}

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {
                headCells.map((cell, index) => {
                  if (cell.id === 'status') {
                    let statusClass = ''
                    if (row[cell.id] === 'ATIVO') {
                      statusClass = 'label-status-green'
                    } else if (row[cell.id] === 'INATIVO') {
                      statusClass = 'label-status-red'
                    }

                    return (
                      <TableCell className='table-cell small' key={index} align='left'>
                        <span className={`label-status ${statusClass}`}>{row[cell.id]}</span>
                      </TableCell>
                    )
                  } else if (cell.id === 'name') {
                    return (
                      <TableCell className='table-cell' key={index} align='left'>
                        <div>{row[cell.id]}</div>
                        <div>{row.bank_name}</div>
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell className='table-cell' key={index} align='left'>
                        {row[cell.id]}
                      </TableCell>
                    )
                  }
                })
              }

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left'>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(index, action)
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { BankListTable };