import { Button, Typography } from '@mui/material';

import useViewModel from './viewModel';

import { CheckboxFilter } from 'components/Filters/CheckboxFilter';
import { SearchFilter } from 'components/Filters/SearchFilter';

import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';
import { CouponListTable } from 'components/Tables/CouponListTable';
import { CreateCouponModal } from 'components/Modals/Coupon/Create';
import { ActivateCouponModal } from 'components/Modals/Coupon/Activate';
import { DeactivateCouponModal } from 'components/Modals/Coupon/Deactivate';
import { EditCouponModal } from 'components/Modals/Coupon/Edit';

function Coupons() {
  const {
    actionIndex,
    coupons,
    page,
    optionsRowsPerPage,
    rowsPerPage,
    statusFilter,
    statusOptions,
    sort,
    tableHeadCells,
    couponLoading,
    couponLoadingError,
    formattedCoupons,
    totalCoupons,
    openCreateCoupon,
    openEdit,
    openActivate,
    openDeactivate,
    applyStatusFilter,
    handleTableRowActions,
    onCouponSearchChange,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
    onOpenModal,
    onCloseModal,
    onCloseEdit,
    onCloseActivate,
    onCloseDeactivate,
  } = useViewModel();

  return (
    <div className='listing-page'>
      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Cupons
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>
            <Button variant='contained' size='small' onClick={onOpenModal}>
              <Typography variant='button'>
                Criar cupom
              </Typography>
            </Button>

            <CreateCouponModal
              open={openCreateCoupon}
              onClose={onCloseModal}
            />
          </div>

        </div>
      </div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por Nome ou Código'
            onSubmit={onCouponSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status-coupon'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />
        </div>
      </div>

      {!couponLoading &&
        !couponLoadingError &&
        formattedCoupons?.length === 0 &&
        <EmptyView title={'Nenhum cupom encontrado para mostrar aqui'}></EmptyView>
      }

      {couponLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os cupons. Tente recarregar a página.' />
      }

      {(couponLoading || (formattedCoupons !== undefined && formattedCoupons?.length > 0)) && <>
        <CouponListTable
          hasActions
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedCoupons ? formattedCoupons : []}
          rowsPerPage={rowsPerPage}
          total={totalCoupons}
          isLoading={couponLoading}
          onClickOption={handleTableRowActions}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />

        <EditCouponModal
          couponData={{
            id: actionIndex !== undefined && coupons ? coupons[actionIndex].id : '',
            name: actionIndex !== undefined && coupons ? coupons[actionIndex].name : '',
            code: actionIndex !== undefined && coupons ? coupons[actionIndex].code : '',
            type: actionIndex !== undefined && coupons ? coupons[actionIndex].type : '',
            value: actionIndex !== undefined && coupons ? coupons[actionIndex].value : 0,
          }}
          open={openEdit}
          onClose={onCloseEdit}
        />

        <ActivateCouponModal
          couponData={{
            id: actionIndex !== undefined && coupons ? coupons[actionIndex].id : ''
          }}
          open={openActivate}
          onClose={onCloseActivate}
        />

        <DeactivateCouponModal
          couponData={{
            id: actionIndex !== undefined && coupons ? coupons[actionIndex].id : ''
          }}
          open={openDeactivate}
          onClose={onCloseDeactivate}
        />
      </>}

    </div>
  );
}

export { Coupons };