import { Box, Tab, Tabs, Typography } from '@mui/material';

import { SalesRecurring } from './pages/Recurring';
import { SalesSpot } from './pages/Spot';

import useViewModel from './viewModel';

import '../styles.scss';
import { CreateSaleModal } from 'components/Modals/Sale/Create';
import { OptionsButton } from 'components/OptionsButton';

function Sales() {
  const {
    createSaleType,
    openCreateSale,
    tabSelected,
    onCloseModal,
    onOpenModal,
    onTabChange
  } = useViewModel();

  return (
    <div className='listing-page'>
      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Vendas
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>

            <OptionsButton
              label='Nova venda'
              options={[
                { label: 'Assinatura', id: 'RECURRING' },
                { label: 'Venda Spot', id: 'SPOT' },
              ]}
              onClickOption={onOpenModal}
            />

            <CreateSaleModal
              open={openCreateSale}
              type={createSaleType ? createSaleType : ''}
              onClose={onCloseModal}
            />
          </div>

        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
          onChange={(event, value) => { onTabChange(value) }}
        >
          <Tab
            label='Assinaturas'
            id='tab-recurring'
            aria-controls='tabpanel-recurring'
          />

          <Tab
            label='Vendas Pontuais'
            id='tab-spot'
            aria-controls='tabpanel-spot'
          />
        </Tabs>
      </Box>

      <div
        role="tabpanel"
        hidden={tabSelected !== 0}
        id={'tabpanel-recurring'}
        aria-labelledby={'tab-recurring'}
      >
        <SalesRecurring />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 1}
        id={'tabpanel-spot'}
        aria-labelledby={'tab-spot'}
      >
        <SalesSpot />
      </div>
    </div>
  );
}

export { Sales };