import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import { CashierData } from './services';
import useViewModel from './viewModel'

import '../../styles.scss';

type EditCashierBanksModalPropTypes = {
  cashierData: CashierData,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function EditCashierBankModal({ cashierData, open, onClose, onSubmit }: EditCashierBanksModalPropTypes) {
  const {
    editLoading,
    editError,
    formError,
    initialValues,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  } = useViewModel({ cashierData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    {`Editar ${values.type === 'CASHIER_CASH' ? 'caixa' : 'conta'}`}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  {values.type === 'CASHIER_CASH' && (
                    <Flexbox size={12}>
                      <TextFieldAdapter
                        label={'Nome'}
                        name='name'
                        disabled={editLoading}
                      />
                    </Flexbox>
                  )}

                  {values.type === 'CASHIER_BANK_ACCOUNT' && (
                    <div>
                      <Flexbox size={12}>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            label={'Código do banco'}
                            name='bank_code'
                            disabled={editLoading}
                          />
                        </Flexbox>

                        <Flexbox size={8}>
                          <TextFieldAdapter
                            label={'Nome do banco'}
                            name='bank_name'
                            disabled={editLoading}
                          />
                        </Flexbox>
                      </Flexbox>

                      <Flexbox size={12}>
                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Agência'}
                            name='routing_number'
                            disabled={editLoading}
                          />
                        </Flexbox>

                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Dígito verificador'}
                            name='routing_number_digit_verifier'
                            disabled={editLoading}
                          />
                        </Flexbox>
                      </Flexbox>

                      <Flexbox size={12}>
                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Número da conta'}
                            name='account_number'
                            disabled={editLoading}
                          />
                        </Flexbox>

                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Dígito verificador'}
                            name='account_number_digit_verifier'
                            disabled={editLoading}
                          />
                        </Flexbox>
                      </Flexbox>
                    </div>
                  )}
                </div>

                <div className='modal-footer'>
                  {editLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' onClick={onClose} disabled={editLoading}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={editLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(editError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Conta atualizada!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { EditCashierBankModal }