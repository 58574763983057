import { Dayjs } from 'dayjs';

import { atom } from 'recoil';

type SalePayloadType = {
  sale_id?: string,
  sale_key?: string,
  product_id?: string,
  product_name?: string,
  product_value?: number,
  plan_id?: string,
  plan_name?: string,
  plan_value?: number,
  coupon_id?: string,
  coupon_type?: string,
  coupon_name?: string,
  coupon_value?: number,
  customer_id?: string,
  customer_name?: string,
  charge_value?: number,
  installments?: {
    total_value: number,
    date: Dayjs
  }[],
  start_date?: Dayjs,
  initial_term?: number,
  auto_renewal?: boolean,
  freq?: string,
  payment_terms?: {
    first_payment_term_type?: string,
    first_payment_term_value?: number,
    payment_term_type?: string,
    payment_term_value?: number,
  }
  create_receipt?: boolean,
  auto_receipt_run?: boolean,
  taxes?: {
    withhold?: boolean,
    rate?: number,
    rate_in_receipt?: number,
    withhold_in_receipt?: boolean
  },
  description?: string,
  payment_method_type?: string,
  payment_method_id?: string,
  payment_method_description?: string,
  charge_now?: boolean,
  has_installments?: boolean,
}

const salePayloadAtom = atom<SalePayloadType>({
  key: 'salePayload',
  default: {
    payment_terms: {},
    taxes: {}
  }
})

export { salePayloadAtom };

export type { SalePayloadType }