import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Checkbox, Skeleton, Tooltip } from '@mui/material';

import { TableCell } from '../Table/TableCell';
import { TableHead } from '../Table/TableHead';

import useViewModel from './viewModel';

import '../styles.scss'
import { ActionButtonTable } from '../Table/ActionButtonTable';

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rowLink: string,
  rows: Array<any>,
  rowsPerPage: number,
  rowsSelected: Array<number>,
  total: number,
  isLoading: boolean,
  onCheckboxClick: (newRowsSelected: number[]) => void,
  onClickOption?: (index: number, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function ClientListTable({
  hasActions,
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rowLink,
  rows,
  rowsPerPage,
  rowsSelected,
  total,
  isLoading,
  onCheckboxClick,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {

  const { onCheckboxChange, onHeaderCheckboxChange } = useViewModel({ rows, rowsSelected, onCheckboxClick });

  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={rowsSelected.length === rows.length}
          isAnySelected={rowsSelected.length > 0}
          isAsc={isAsc}
          optionMenu={hasActions}
          onRequestSort={onRequestSort}
          onSelectAll={() => {
            onHeaderCheckboxChange();
          }}
          orderBy={orderBy}
        />

        <TableBody>
          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => (
            <TableRow
              className='table-row linked-table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell to={`${rowLink}/${row.id}`} className='checkbox-cell'>
                <Checkbox
                  color='primary'
                  checked={rowsSelected.includes(index)}
                  inputProps={{
                    className: 'table-checkbox'
                  }}
                  onClick={(e) => {
                    onCheckboxChange(!rowsSelected.includes(index), index);
                  }}
                />
              </TableCell>

              <TableCell to={`${rowLink}/${row.id}`} className='table-cell big' align='left'>{row.name}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell big' align='left'>{row.razao_social}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.document}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} ellipsis className='table-cell big' align='left'>{row.emails}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.created_at}</TableCell>
              {
                <TableCell to={`${rowLink}/${row.id}`} className='table-cell big' align='left'>


                  {
                    row.tags.split(',').length > 2 &&
                    <div className='label-column'>
                      <span className='label-tag-container'>
                        {row.tags && row.tags.split(',').slice(0, 2).map((tag: string, tagIndex: number) => (
                          <span className='label-tag' key={`${index}-${tagIndex}`}>{tag}</span>
                        ))}
                      </span>
                      <span className='label-tag-container-plus'>
                        <Tooltip title={row.tags} arrow>
                          <span className='label-tag'>+{row.tags.split(',').length - 2}</span>
                        </Tooltip>
                      </span>
                    </div>
                  }

                  {
                    row.tags.split(',').length <= 2 &&
                    <span className='label-tag-container'>
                      {row.tags && row.tags.split(',').map((tag: string, tagIndex: number) => (
                        <span className='label-tag' key={`${index}-${tagIndex}`}>{tag}</span>
                      ))}
                    </span>
                  }
                </TableCell>
              }
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>
                <span className={`label-status ${row.status === 'ATIVO' ? 'label-status-green' : 'label-status-red'}`}>
                  {row.status}
                </span>
              </TableCell>

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left'>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(index, action)
                    }}
                  />
                </TableCell>
              )}

            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { ClientListTable };