import { CustomError, api } from 'utils/axios';
import dayjs from 'dayjs';

import { formatPercent } from 'utils/percentFormat';

import { CNAE_LIST } from 'utils/constants/ProductRegistrationConstants';

type DetailsType = {
  id: string,
  name: string,
  status: string,
  internal_code: string,
  description: string,
  sales: number,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: number,
  irrf: number,
  pis: number,
  cofins: number,
  csll: number,
  created_at: string,
  updated_at: string,
}

type DetailsDisplayType = {
  id: string,
  name: string,
  status: string,
  internal_code: string,
  description: string,
  sales: string,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: string,
  irrf: string,
  pis: string,
  cofins: string,
  csll: string,
  created_at: string,
  updated_at: string,
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    name: response.data.attributes.name,
    status: response.data.attributes.status,
    internal_code: response.data.attributes.internal_code,
    description: response.data.attributes.description,
    sales: response.data.attributes.sales,
    cnae_code: response.data.attributes.cnae_code,
    service_code: response.data.attributes.service_code,
    custom_service_code: response.data.attributes.custom_service_code,
    iss: response.data.attributes.iss,
    irrf: response.data.attributes.irrf,
    pis: response.data.attributes.pis,
    cofins: response.data.attributes.cofins,
    csll: response.data.attributes.csll,
    created_at: response.data.attributes.created_at.date,
    updated_at: response.data.attributes.updated_at.date,
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/products-recurring/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  return {
    id: details.id,
    name: details.name,
    status: details.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
    internal_code: details.internal_code ? details.internal_code : 'N/A',
    description: details.description ? details.description : 'N/A',
    sales: details.sales.toString(),
    cnae_code: `${details.cnae_code}${CNAE_LIST[details.cnae_code] ? ' - ' + CNAE_LIST[details.cnae_code] : ''}`,
    service_code: details.service_code,
    custom_service_code: details.custom_service_code ? details.custom_service_code : 'N/A',
    iss: formatPercent(details.iss),
    irrf: formatPercent(details.irrf),
    pis: formatPercent(details.pis),
    cofins: formatPercent(details.cofins),
    csll: formatPercent(details.csll),
    created_at: dayjs(details.created_at).format('DD/MM/YYYY'),
    updated_at: dayjs(details.updated_at).format('DD/MM/YYYY'),
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }