import { CustomError, api } from 'utils/axios';

import { BANKS_CODE } from 'utils/constants/banks';

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type BanksType = {
  id: string,
  name: string,
  bank_code: string
  routing_number: string,
  routing_number_digit_verifier: string,
  account_number: string,
  account_number_digit_verifier: string,
  status: string
}

type BanksToDisplayType = {
  id: string,
  name: string,
  bank_name: string,
  routing_number: string,
  status: string,
  actions: ActionType[]
}

const parseBanks = (response: any) => {
  const banks = response.data.map((data: any) => ({
    id: data.id,
    name: data.attributes.name,
    bank_code: data.attributes.bank_code ? data.attributes.bank_code : '',
    routing_number: data.attributes.routing_number ? data.attributes.routing_number : '',
    routing_number_digit_verifier: data.attributes.routing_number_digit_verifier ? data.attributes.routing_number_digit_verifier : '',
    account_number: data.attributes.account_number ? data.attributes.account_number : '',
    account_number_digit_verifier: data.attributes.account_number_digit_verifier ? data.attributes.account_number_digit_verifier : '',
    status: data.attributes.status
  }))

  return {
    banks,
    pagination: {
      totalBanks: response.meta.pagination.total
    }
  }
}

const getBanks = async (sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get('/api-cashone/cashiers', {
      params,
      signal
    });

    return parseBanks(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatBanksToDisplay = (banks: BanksType[]): BanksToDisplayType[] => {
  return banks.map(bank => ({
    id: bank.id,
    name: bank.name,
    bank_name: bank.bank_code ? `${BANKS_CODE[bank.bank_code]}` : '',
    status: bank.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
    routing_number: bank.routing_number ? `Ag ${bank.routing_number}${bank.routing_number_digit_verifier ? '-' + bank.routing_number_digit_verifier : ''
      } CC ${bank.account_number}${bank.account_number_digit_verifier ? '-' + bank.account_number_digit_verifier : ''}` : 'N/A',
    actions: [
      { id: 'edit', label: 'Editar' },
    ]
  }))
}

export { formatBanksToDisplay, getBanks };

export type { PaginationType, SortType, BanksType, BanksToDisplayType };