import { ChangeEvent } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, FormHelperText } from '@mui/material';
import { Field, FieldRenderProps, useFormState } from 'react-final-form';
import { FieldValidator } from 'final-form';

type RadioFieldAdapterPropTypes = {
  name: string,
  label: string,
  options: Array<{
    label: string,
    value: string
  }>,
  disabled?: boolean,
  row?: boolean,
  defaultValue?: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  validate?: FieldValidator<any>,
};

const ComponentField = ({
  input,
  meta,
  label,
  options,
  disabled,
  row,
  onChange
}: FieldRenderProps<any>) => {

  const formState = useFormState();

  return (
    <FormControl
      error={!!(meta.touched && meta.error)}
    >
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        {...input}
        row={row}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          input.onChange(e.target.value);
          if (onChange)
            onChange(e);
        }}
      >
        {options.map((option: { label: string, value: any }, index: number) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio disabled={disabled} checked={formState.values[input.name] === option.value} />}
            label={option.label}
          />
        ))}
      </RadioGroup>

      <FormHelperText>{meta.touched ? meta.error : ''}</FormHelperText>
    </FormControl>
  );
}

function RadioFieldAdapter({
  name,
  label,
  options,
  disabled,
  row = false,
  onChange,
  validate,
  defaultValue
}: RadioFieldAdapterPropTypes) {

  return (
    <div className='field'>
      <Field
        component={ComponentField}
        name={name}
        type='radio'
        label={label}
        disabled={disabled ?? false}
        options={options}
        onChange={onChange}
        validate={validate}
        defaultValue={defaultValue}
        row={row}
      />
    </div>
  );
}

export { RadioFieldAdapter };