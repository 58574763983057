import { Skeleton, Paper, Typography, Alert } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import QRCode from 'react-qr-code';

import useViewModel from './viewModel';

import './styles.scss'

function Pix() {
  const {
    detailsLoading,
    detailsLoadingError,
    expired,
    expirationTimer,
    formattedDetails,
  } = useViewModel()

  return (
    <div className='pix-page'>

      {!detailsLoading && detailsLoadingError &&
        <>
          Esse link é inválido ou PIX já está pago.
        </>
      }

      {!detailsLoadingError && <>
        {detailsLoading && <Skeleton height={200} width={200} animation='wave'></Skeleton>}
        {!detailsLoading &&
          <div className='logo'>
            <img src={formattedDetails?.company_logo} alt={formattedDetails?.company_name} />
          </div>}

        <div className='company-name'>
          {detailsLoading && <Skeleton width={300} height={50}></Skeleton>}
          {!detailsLoading &&
            <Typography variant='h1' color='secondary'>
              {formattedDetails?.company_name}
            </Typography>}
        </div>

        <div className='charge-details'>
          <div><span>Valor cobrado: </span><span className='value'>{formattedDetails?.total}</span></div>
          <div><span>Data de vencimento: </span><span className='value'>{formattedDetails?.due_date}</span></div>
        </div>

        <Paper className='box custom-modal'>
          <div className='modal-header'>Pagar com PIX</div>

          <div className='modal-body'>
            {(!expirationTimer || detailsLoading) && <>
              <Skeleton width={300} height={50} />

              <Skeleton width={256} height={256} />

              <Skeleton width={450} height={50} />
            </>}

            {expirationTimer && !detailsLoading && <>
              {!expired && <>
                <Alert severity='warning' variant='filled' className='alert'>
                  <b>Atenção:</b> este QR code irá expirar em {expirationTimer?.min}:{expirationTimer?.sec}
                </Alert>

                <QRCode
                  value={formattedDetails?.qr_code ? formattedDetails.qr_code : ''}
                />

                <div className='qr-code-container'>
                  <Typography>{formattedDetails?.qr_code}</Typography>
                </div>
              </>}

              {expired &&
                <Alert severity='error' variant='filled' className='alert'>
                  QR code expirado, tente recarregar a página
                </Alert>
              }

            </>}

          </div>

        </Paper>

        <div className='security-container'>
          <Typography variant='h3' color='primary'>
            <LockIcon />
            Este é um site seguro
          </Typography>
        </div>
      </>}
    </div>
  );
}

export { Pix }