import dayjs from 'dayjs'

import { CustomError, api } from 'utils/axios'
import { formatMoney } from 'utils/moneyFormatt'

type AccountsReceivablesType = {
  id: string,
  customer_name: string,
  key: string,
  total: number,
  net_value: number,
  payment_method: string,
  due_date: string,
  transfer_date: string,
  status: string
}

type AccountsReceivablesDisplayType = {
  id: string,
  customer_name: string,
  key: string,
  total: string,
  net_value: string,
  payment_method: string,
  due_date: string,
  transfer_date: string,
  status: string
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parseAR = (response: { data: any, meta: any }) => {
  const ARs = response.data.map((data: any) => {
    return {
      id: data.id,
      customer_name: data.attributes.customer,
      key: data.attributes.key,
      total: data.attributes.total,
      net_value: data.attributes.net_value,
      payment_method: data.attributes.payment_method,
      due_date: data.attributes.due_date.date,
      transfer_date: data.attributes.transfer_date?.date,
      status: data.attributes.status,
    }
  });

  return {
    accountsReceivables: ARs,
    totals: {
      totalARs: response.meta.pagination.total
    }
  }
}

const getARs = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get(`/api-cashone/clients/${id}/accounts-receivables`, {
      params,
      signal
    })

    return parseAR(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatARsToDisplay = (ARs: Array<AccountsReceivablesType>): Array<AccountsReceivablesDisplayType> => {
  const translateStatus = {
    'CANCELLED': 'CANCELADO',
    'DRAFT': 'RASCUNHO',
    'ERROR': 'ERRO',
    'EXPIRED': 'EXPIRADO',
    'PAID': 'PAGO',
    'PARTIALLY_PAID': 'PARCIALMENTE PAGO',
    'PENDING': 'PENDENTE',
    'RENEGOTIATED': 'RENEGOCIADO',
    'TRANSFERRED': 'TRANSFERIDO',
  }

  const translatePaymentMethod = {
    'BANK_DEPOSIT': 'Depósito Bancário',
    'BOLETO': 'Boleto',
    'CREDIT_CARD': 'Cartão de Crédito',
    'PIX': 'Pix',
  }

  type StatusEnum = keyof typeof translateStatus;
  type PaymentMethodEnum = keyof typeof translatePaymentMethod;

  return ARs.map((ar) => {
    return {
      id: ar.id,
      customer_name: ar.customer_name,
      key: ar.key,
      total: formatMoney(ar.total),
      net_value: formatMoney(ar.net_value),
      payment_method: translatePaymentMethod[ar.payment_method as PaymentMethodEnum],
      due_date: dayjs(ar.due_date).format('DD/MM/YYYY'),
      transfer_date: ar.transfer_date ? dayjs(ar.transfer_date).format('DD/MM/YYYY') : '-',
      status: translateStatus[ar.status as StatusEnum],
    }
  })
}

export { getARs, formatARsToDisplay }

export type {
  AccountsReceivablesDisplayType,
  AccountsReceivablesType,
  SortType,
  PaginationType
}