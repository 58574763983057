import { Typography } from '@mui/material';

import useViewModel from './viewModel';

import { SearchFilter } from '../../components/Filters/SearchFilter';
import { CheckboxFilter } from '../../components/Filters/CheckboxFilter';
import { SupplierListTable } from '../../components/Tables/SupplierListTable';
import { Totals } from '../../components/Totals';

import { EmptyView } from 'components/EmptyView';
import { ApiErrorView } from 'components/ApiErrorView';
import { OptionsButton } from 'components/OptionsButton';

import '../styles.scss';
import {CreateSupplierModal} from "../../components/Modals/Supplier/Create";
import {EditSupplierModal} from "../../components/Modals/Supplier/Edit";
import {ActivateSupplierModal} from "../../components/Modals/Supplier/Activate";
import {DeactivateSupplierModal} from "../../components/Modals/Supplier/Deactivate";

function Suppliers() {
  const {
    suppliers,
    supplierLoading,
    supplierLoadingError,
    formattedSuppliers,
    createSupplierType,
    page,
    actionIndex,
    openCreateSupplier,
    openEditSupplier,
    openActivateSupplier,
    openDeactivateSupplier,
    optionsRowsPerPage,
    rowsPerPage,
    sort,
    statusFilter,
    statusOptions,
    totalSuppliers,
    tableHeadCells,
    applyStatusFilter,
    handleTableRowActions,
    onOpenModal,
    onCloseModal,
    onCloseEditModal,
    onCloseActivateModal,
    onCloseDeactivateModal,
    onChangeSort,
    onSupplierSearchChange,
    onPageChange,
    onRowsPerPageChange
  } = useViewModel();

  return (
    <div className='listing-page customers-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Fornecedores
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>

            <OptionsButton
              disabled={supplierLoading}
              label='Criar fornecedor'
              options={[
                { label: 'Pessoa física', id: 'brlPeopleRegime' },
                { label: 'Pessoa jurídica', id: 'brlCompanyRegime' },
                { label: 'Estrangeiro', id: 'brlInternationalCustomerRegime' },
              ]}
              onClickOption={onOpenModal}
            />

            <CreateSupplierModal
                open={openCreateSupplier}
                type={createSupplierType ? createSupplierType : ''}
                onClose={onCloseModal}
            />
          </div>

        </div>
      </div>

      <Totals
        title='Total de fornecedores'
        value={totalSuppliers}
        isLoading={supplierLoading}
      />

      <div className='filters-container' style={{ display: 'flex' }}>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por Nome, CPF ou CNPJ'
            onSubmit={onSupplierSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

        </div>
      </div>

      {!supplierLoading &&
        !supplierLoadingError &&
        formattedSuppliers?.length === 0 &&
        <EmptyView title={'Nenhum fornecedor encontrado para mostrar aqui'}></EmptyView>
      }

      {supplierLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os fornecedores. Tente recarregar a página ou usar filtros diferentes.' />
      }

      {(supplierLoading || (formattedSuppliers !== undefined && formattedSuppliers.length > 0)) &&
        <>
          <SupplierListTable
            hasActions
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rowLink='/suppliers'
            rows={formattedSuppliers ? formattedSuppliers : []}
            rowsPerPage={rowsPerPage}
            total={totalSuppliers}
            isLoading={supplierLoading}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <EditSupplierModal
              supplierId={suppliers && actionIndex !== undefined ? suppliers[actionIndex].id : ''}
              open={openEditSupplier}
              onClose={onCloseEditModal}
          />

          <ActivateSupplierModal
              supplierData={{
                id: suppliers && actionIndex !== undefined ? suppliers[actionIndex].id : ''
              }}
              open={openActivateSupplier}
              onClose={onCloseActivateModal}
          />

          <DeactivateSupplierModal
              supplierData={{
                id: suppliers && actionIndex !== undefined ? suppliers[actionIndex].id : ''
              }}
              open={openDeactivateSupplier}
              onClose={onCloseDeactivateModal}
          />
        </>
      }
    </div>
  );
}

export { Suppliers };