import { useState } from 'react'

import { activateProduct } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

type ProductType = {
  id: string,
}

type ViewModelPropTypes = {
  productData: ProductType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function ActivateProductViewModel({ productData, onClose, onSubmit }: ViewModelPropTypes) {
  const [productLoading, setProductLoading] = useState(false)
  const [productError, setProductError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onActivateHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setProductError(true)
      setOpenSnackbar(true)
    } else {
      setProductError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async () => {
    setProductLoading(true)

    const response = await activateProduct(productData.id)

    if (response.errors)
      onActivateHandleError(response.errors)

    else {
      setProductError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setProductLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    productError,
    productLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { ProductType }