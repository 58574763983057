import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { Form } from 'react-final-form';
import { TextFieldAdapter } from '../../../FormComponents/Fields/TextFieldAdapter';

import { required } from '../../../../utils/FieldValidations';

import './styles.scss'
import '../../styles.scss'

type ModalCreateTagPropTypes = {
  loading: boolean,
  show: boolean,
  onChangeOpen: () => void,
  onSubmit: (values: { name: string, description: string }) => Promise<void>
}

function ModalCreateTag({
  loading,
  show,
  onChangeOpen,
  onSubmit
}: ModalCreateTagPropTypes) {
  return (
    <Modal
      open={show}
      onClose={onChangeOpen}
      closeAfterTransition
    >
      <Box component={Paper} className='create-tag-modal custom-modal'>
        <div className='modal-header'>
          <Typography variant='h3' fontWeight={700}>Nova Tag</Typography>
        </div>

        <Form
          onSubmit={(values) => { onSubmit({ name: values.name, description: values.description }) }}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
            >
              <div className='modal-body'>
                <div className='text-input tag-name'>
                  <TextFieldAdapter
                    name='name'
                    label='Nome da tag'
                    validate={required}
                    type='text'
                  />
                </div>

                <div className='text-input tag-description'>
                  <TextFieldAdapter
                    name='description'
                    label='Descrição (Opicional)'
                    type='text'
                    multiline
                    rows={2}
                  />
                </div>

              </div>
              <div className='modal-footer'>
                <Button size='large' onClick={onChangeOpen} disabled={loading}>
                  <Typography variant='caption' fontWeight={700}>
                    Cancelar
                  </Typography>
                </Button>
                <Button size='large' variant='contained' type='submit' disabled={loading}>
                  <Typography variant='caption' fontWeight={700}>
                    Criar
                  </Typography>
                </Button>
              </div>

            </form>
          )}
        />

      </Box>
    </Modal>
  )
}

export { ModalCreateTag };