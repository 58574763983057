import { Alert, Button, Paper, Snackbar, Typography } from '@mui/material';
import { Form } from 'react-final-form';

import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import { confirmPassword, required } from 'utils/FieldValidations';

import LogoPath from 'images/logo-cash.png'

import useViewModel from './viewModel'

import './styles.scss'

function ResetPassword() {
  const {
    openSnackbar,
    passwordLoading,
    passwordLoadingError,
    tokenError,
    onCloseSnackbar,
    onSubmit,
  } = useViewModel()

  return (
    <div className='reset-password-page'>
      <div className='logo'>
        <img src={LogoPath} alt='Logo' />
      </div>

      <Paper className='form-box'>
        <Form
          onSubmit={(values) => { onSubmit({ password: values.password }) }}
          validate={confirmPassword}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className='form-container'
            >

              <div className='text-input'>
                <TextFieldAdapter
                  name='password'
                  label='Nova senha'
                  validate={required}
                  type='password'
                />
              </div>

              <div className='text-input'>
                <TextFieldAdapter
                  name='confirmPassword'
                  label='Confirmar senha'
                  validate={required}
                  type='password'
                />
              </div>

              <div className='buttons-container'>
                <Button size='large' variant='contained' type='submit' disabled={passwordLoading}>
                  <Typography variant='body1' fontWeight={700}>
                    Redefinir senha
                  </Typography>
                </Button>
              </div>

            </form>
          )}
        />
      </Paper>

      {!passwordLoadingError && !tokenError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Senha atualizada com sucesso! Você será redirecionado para o login em 5 segundos.
          </Alert>
        </Snackbar>
      }

      {passwordLoadingError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {tokenError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Seu link expirou!
          </Alert>
        </Snackbar>
      }
    </div>
  );
}

export { ResetPassword }