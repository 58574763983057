import './styles.scss'
import useViewModel from './viewModel';
import {Form} from "react-final-form";
import {Alert, Button, CircularProgress, Snackbar, Typography} from "@mui/material";
import { Flexbox } from 'components/Flexbox';
import { DisplayInfoText } from 'components/Info/DisplayInfoText';
import { CheckboxFieldAdapter } from "../../../../components/FormComponents/Fields/CheckboxFieldAdapter";
import React from "react";

type ReviewStepPropTypes = {
    onBack: () => void
    onNext: () => void
}

function ReviewStep({
    onBack,
    onNext
}: ReviewStepPropTypes) {
    const {
        openSnackbar,
        orderLoading,
        orderLoadingError,
        checkoutOrderPayload,
        formattedCheckoutOrderPayload,
        formattedSale,
        acceptedTermsOfUse,
        acceptedPrivacyPolicy,
        onChangeTermsOfUse,
        onChangePrivacyPolicy,
        onCloseSnackbar,
        onSubmit
    } = useViewModel({onBack, onNext})

    return <>
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>

                    <div className='review-view'>
                        <div className='title-row'>
                            <Typography variant='h2' fontWeight={700} className='summary-subtitle' color='primary'>Revisão
                                do Pedido</Typography>
                        </div>

                        <div className='info-row'>
                            <Flexbox size={12}>
                                <Typography variant='h4' fontWeight={700} color='primary' className='subtitle-row'>
                                    Produto
                                </Typography>
                            </Flexbox>

                            <Flexbox size={12}>
                                <Flexbox size={4}>
                                    <DisplayInfoText
                                        title='Produto'
                                        text={formattedCheckoutOrderPayload.product?.product_name ?? ""}
                                    />
                                </Flexbox>

                                <Flexbox size={4}>
                                    <DisplayInfoText
                                        title='Plano'
                                        text={formattedCheckoutOrderPayload.product?.plan_name ?? ""}
                                    />
                                </Flexbox>
                            </Flexbox>
                        </div>

                        <div className='info-row'>
                            <Flexbox size={12}>
                                <Typography variant='h4' fontWeight={700} color='primary' className='subtitle-row'>
                                    Dados cadastrais
                                </Typography>
                            </Flexbox>

                            {formattedCheckoutOrderPayload.customer?.type === 'brlCompanyRegime' &&
                                <Flexbox size={12}>
                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Nome'
                                            text={formattedCheckoutOrderPayload.customer?.name ?? ""}
                                        />
                                    </Flexbox>

                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Razão Social'
                                            text={`${formattedCheckoutOrderPayload.customer?.razao_social ?? ""}`}
                                        />
                                    </Flexbox>
                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='CNPJ'
                                            text={`${formattedCheckoutOrderPayload.customer?.registration ?? ""}`}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            }

                            {formattedCheckoutOrderPayload.customer?.type === 'brlPeopleRegime' &&
                                <Flexbox size={12}>
                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Nome'
                                            text={formattedCheckoutOrderPayload.customer?.first_name + " " + formattedCheckoutOrderPayload.customer?.last_name}
                                        />
                                    </Flexbox>

                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='CPF'
                                            text={`${formattedCheckoutOrderPayload.customer?.registration ?? ""}`}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            }
                        </div>

                        {formattedCheckoutOrderPayload.customer?.type === 'brlCompanyRegime' && <div className='info-row'>
                            <Flexbox size={4}>
                                <DisplayInfoText
                                    title='Optante pelo simples'
                                    text={`${formattedCheckoutOrderPayload.customer?.optante_simples_nacional ? "Sim" : "Não" ?? ""}`}
                                />
                            </Flexbox>
                        </div>}

                        <div className='info-row'>
                            <Flexbox size={12}>
                                <Flexbox size={4}>
                                    <DisplayInfoText
                                        title='Telefone'
                                        text={formattedCheckoutOrderPayload.customer?.phone ?? ""}
                                    />
                                </Flexbox>

                                <Flexbox size={4}>
                                    <DisplayInfoText
                                        title='Email'
                                        text={formattedCheckoutOrderPayload.customer?.email ?? ""}
                                    />
                                </Flexbox>
                            </Flexbox>
                        </div>

                        <div className='info-row'>

                            <div className='multiline-space'>
                                <Flexbox size={12}>
                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='CEP'
                                            text={formattedCheckoutOrderPayload.customer?.address?.postal_code ?? ""}
                                        />
                                    </Flexbox>

                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Estado'
                                            text={formattedCheckoutOrderPayload.customer?.address?.state ?? ""}
                                        />
                                    </Flexbox>

                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Cidade'
                                            text={formattedCheckoutOrderPayload.customer?.address?.city?.label ?? ""}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            </div>

                            <div className='multiline-space'>
                                <Flexbox size={12}>
                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Logradouro'
                                            text={formattedCheckoutOrderPayload.customer?.address?.street ?? ""}
                                        />
                                    </Flexbox>

                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Número'
                                            text={formattedCheckoutOrderPayload.customer?.address?.number ?? ""}
                                        />
                                    </Flexbox>

                                    <Flexbox size={4}>
                                        <DisplayInfoText
                                            title='Complemento'
                                            text={formattedCheckoutOrderPayload.customer?.address?.complement ?? ""}
                                        />
                                    </Flexbox>
                                </Flexbox>
                            </div>
                        </div>

                        <div className='info-row'>
                            <Flexbox size={12}>
                                <Typography variant='h4' fontWeight={700} color='primary' className='subtitle-row'>
                                    Forma de pagamento
                                </Typography>
                            </Flexbox>

                            <Flexbox size={12}>
                                <DisplayInfoText
                                    title='Método escolhido'
                                    text={formattedCheckoutOrderPayload.payment?.payment_method ?? ""}
                                />
                            </Flexbox>
                        </div>

                        <div className='info-row'>
                            <Flexbox size={12}>
                                <Typography variant='h4' fontWeight={700} color='primary'>
                                    Prazo de pagamento
                                </Typography>
                            </Flexbox>

                            <Flexbox size={12}>
                                {formattedCheckoutOrderPayload.product?.first_payment_term_value !== null && formattedCheckoutOrderPayload.product?.first_payment_term_type !== null && <>
                                    {(formattedCheckoutOrderPayload.product?.first_payment_term_type ?? "") === 'NET' ? (
                                        <Flexbox size={6}>
                                            <DisplayInfoText
                                                title='1ª Cobrança'
                                                text={formattedCheckoutOrderPayload.product?.first_payment_term_value + " dias após a cobrança" ?? ""}
                                            />
                                        </Flexbox>
                                    ) : (
                                        <Flexbox size={6}>
                                            <DisplayInfoText
                                                title='1ª Cobrança'
                                                text={formattedCheckoutOrderPayload.product?.first_payment_term_value + "º dia do mês" ?? ""}
                                            />
                                        </Flexbox>
                                    )}
                                </>}

                                {formattedCheckoutOrderPayload.product?.payment_term_value !== null && formattedCheckoutOrderPayload.product?.payment_term_type !== null && <>
                                    {(formattedCheckoutOrderPayload.product?.payment_term_type ?? "") === 'NET' ? (
                                        <Flexbox size={6}>
                                            <DisplayInfoText
                                                title='Cobranças futuras'
                                                text={formattedCheckoutOrderPayload.product?.payment_term_value + " dias após a cobrança" ?? ""}
                                            />
                                        </Flexbox>
                                    ) : (
                                        <Flexbox size={6}>
                                            <DisplayInfoText
                                                title='Cobranças futuras'
                                                text={formattedCheckoutOrderPayload.product?.payment_term_value + "º dia do mês" ?? ""}
                                            />
                                        </Flexbox>
                                    )}
                                </>}
                            </Flexbox>
                        </div>

                        {(checkoutOrderPayload.company?.term_of_use || checkoutOrderPayload.company?.privacy_policy) &&
                            <div className='info-row'>
                                <Flexbox size={12}>
                                    <Typography variant='h4' fontWeight={700} color='primary'>
                                        Termos de uso e Política de privacidade
                                    </Typography>
                                </Flexbox>

                                <div className='row-check-box-container'>
                                    {checkoutOrderPayload.company?.term_of_use && <div className='row-check-box'>
                                        <CheckboxFieldAdapter
                                            name='terms_of_use'
                                            options={[
                                                {label: 'Li e aceito os ', value: 'accepted_term_of_use'},
                                            ]}
                                            disabled={orderLoading}
                                            onClick={(e: any) => {
                                                onChangeTermsOfUse(e.target.checked)
                                            }}
                                        />
                                        <a className='link' href={checkoutOrderPayload.company?.term_of_use}
                                           target='_blank'
                                           rel='noreferrer'>termos de uso</a>
                                    </div>}

                                    {checkoutOrderPayload.company?.privacy_policy && <div className='row-check-box'>
                                        <CheckboxFieldAdapter
                                            name='privacy_policy'
                                            options={[
                                                {
                                                    label: 'Li e aceito a ',
                                                    value: 'accepted_privacy_policy'
                                                }
                                            ]}
                                            disabled={orderLoading}
                                            onClick={(e: any) => {
                                                onChangePrivacyPolicy(e.target.checked)
                                            }}
                                        />
                                        <a className='link' href={checkoutOrderPayload.company?.privacy_policy}
                                           target='_blank' rel='noreferrer'>política de privacidade</a>
                                    </div>}
                                </div>
                            </div>}

                    </div>

                    <div className='total-section'>
                        <span
                            className='row'>Subtotal: {formattedSale?.subtotalFormatted ? formattedSale?.subtotalFormatted : 'R$ 0,00'}</span>
                        <span
                            className='row'>Adesão: {formattedSale?.setupFormatted ? formattedSale?.setupFormatted : 'R$ 0,00'}</span>
                        <span
                            className='row'>Desconto: {formattedSale?.discountFormatted ? formattedSale?.discountFormatted : 'R$ 0,00'}</span>
                        <span
                            className='row'>Total: {formattedSale?.totalFormatted ? formattedSale?.totalFormatted : 'R$ 0,00'}</span>
                    </div>

                    <div className='footer'>
                        {orderLoading && <CircularProgress color='primary' size={30}/>}
                        <Button variant='outlined' onClick={onBack} disabled={orderLoading}>Voltar</Button>
                        <Button variant='contained' type='submit'
                                disabled={!acceptedTermsOfUse || !acceptedPrivacyPolicy || orderLoading}>Finalizar
                            venda</Button>
                    </div>
                </form>
            )}
        />

        {orderLoadingError &&
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
                <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
                    Ops! Algo deu errado, tente de novo mais tarde.
                </Alert>
            </Snackbar>
        }
    </>
}

export {ReviewStep}