import { CustomError, api } from 'utils/axios';

type CouponOption = {
  value: {
    coupon_id: string,
  },
  label: string
}

const parseCoupons = async (response: { data: any[] }) => {
  return response.data.map(data => ({
    value: {
      coupon_id: data.id,
    },
    label: data.attributes.name
  }))
}

const getCoupons = async (search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {
    q: search
  };

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/coupons', {
      params,
      signal
    });

    return parseCoupons(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const postApplyCoupon = async (id: string, couponId: string): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/sales/${id}/discounts/${couponId}/apply`)

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postApplyCoupon, getCoupons }

export type { CouponOption }