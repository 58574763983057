import {CheckoutOrderPayloadType} from 'recoil/checkout/states';
import {apiCheckout} from 'utils/axios';
import {CustomCheckoutError} from "../../../../utils/CustomError";
import { formatMoney } from 'utils/moneyFormatt';

type SaleType = {
    totalFormatted: string,
    subtotalFormatted: string,
    setupFormatted: string,
    discountFormatted: string,
}

type OrderPayload = {
    hash?: string,
    customer: {
        hash?: string, 
    },
    coupon: {
        hash?: string, 
    },
    payment: {
        payment_method_type?: string,
        holder_name?: string,
        digits?: string,
        brand?: string,
        expiration_month?: string,
        expiration_year?: string,
        security_code?: string
    },
    usage_count?: {
        uom?: string,
        type?: string,
        value?: number,
        recurrence?: number
    }[]
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string
}

type OrderResponsePayload = {
    hash: string,
    status: string
}

const bundlePayload = (payload: CheckoutOrderPayloadType): OrderPayload => {

    return {
        hash: payload.product?.hash,
        customer: {
            hash: payload.customer?.hash,
        },
        coupon: {
            hash: payload.coupon?.hash,
        },
        payment: {
            payment_method_type: payload.payment?.payment_method,
            holder_name: payload.payment?.holder_name,
            digits: payload.payment?.digits,
            brand: payload.payment?.brand,
            expiration_month: payload.payment?.expiration_month,
            expiration_year: payload.payment?.expiration_year,
            security_code: payload.payment?.security_code
        },
        usage_count: undefined,
        utm_source: payload.utm_source,
        utm_medium: payload.utm_medium,
        utm_campaign: payload.utm_campaign
    }
}

const postOrder = async (payload: CheckoutOrderPayloadType): Promise<any> => {
    try {
        return await apiCheckout.post('/orders', bundlePayload(payload))
    } catch (error) {
        const errorResponse = (error as CustomCheckoutError).errors
        if (errorResponse)
            return { errors: errorResponse };

        return { errors: [] };
    }
}

const bundlePayloadToRecoil = (order_hash: string, checkoutPayload?: CheckoutOrderPayloadType): CheckoutOrderPayloadType => {
    
    let payload : CheckoutOrderPayloadType = {
        ...checkoutPayload,
        accepted_privacy_policy: true,
        accepted_term_of_use: true
    }

    if (payload.sale) {
        payload.sale = {
            ...payload.sale,
            hash: order_hash
        }
    }
    
    return payload
}

const formatCheckoutOrderPayloadToDisplay = (values: CheckoutOrderPayloadType) => {
    return {
        ...values,
        accepted_privacy_policy: values.accepted_privacy_policy,
        accepted_term_of_use: values.accepted_term_of_use
    }
}

const formatSaleToDisplay = (values: CheckoutOrderPayloadType): SaleType => {
    return {
        totalFormatted: formatMoney(values.sale?.total ?? 0),
        subtotalFormatted: formatMoney(values.sale?.subtotal ?? 0),
        setupFormatted: formatMoney(values.sale?.setup ?? 0),
        discountFormatted: formatMoney(values.sale?.discount ?? 0),
    }
}

export {
    postOrder,
    bundlePayload,
    bundlePayloadToRecoil,
    formatSaleToDisplay,
    formatCheckoutOrderPayloadToDisplay
}

export type {
    SaleType,
    OrderPayload,
    OrderResponsePayload
}