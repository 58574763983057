import useViewModel from './viewModel';

import { ApiErrorView } from 'components/ApiErrorView';
import { CreditCardListTable } from 'components/Tables/CreditCardListTable';
import { EmptyView } from 'components/EmptyView';

type CreditCardViewPropTypes = {
  clientId: string
}

function CreditCardView({
  clientId,
}: CreditCardViewPropTypes) {
  const {
    creditCardsLoading,
    creditCardsLoadingError,
    formattedCreditcards,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalCreditCards,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ clientId });

  return (
    <div className='listing-tab-view'>

      {!creditCardsLoading &&
        !creditCardsLoadingError &&
        formattedCreditcards?.length === 0 &&
        <EmptyView title={'Nenhum cartão de crédito encontrado para mostrar aqui'} />
      }

      {creditCardsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os cartões de crédito. Tente recarregar a página.' />
      }

      {(creditCardsLoading || (formattedCreditcards !== undefined && formattedCreditcards.length > 0)) &&
        <CreditCardListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedCreditcards ? formattedCreditcards : []}
          rowsPerPage={rowsPerPage}
          total={totalCreditCards}
          isLoading={creditCardsLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />}

    </div>
  )
}

export { CreditCardView };