import { useEffect, useState } from 'react';

import { useCreateSaleActions } from 'recoil/createSale/actions';

import { getSale, parsePayloadToRecoil } from './services';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';

type ViewModelPropTypes = {
  id: string,
  open: boolean,
  onClose: () => void
}

export default function EditSaleModalViewModel({
  id,
  open,
  onClose
}: ViewModelPropTypes) {
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [hasInstallments, setHasInstallments] = useState(false)

  const { resetPayload, setSalePayload } = useCreateSaleActions()


  const onGetDetailsHandleError = (errors: ApiError) => {
    if (errors[0]?.status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0]?.status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setLoadingError(true)
    } else {
      setLoadingError(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      if (open) {
        setLoading(true);
        setHasInstallments(false)

        const response = await getSale(id, abortController.signal);

        if (response.errors) {
          onGetDetailsHandleError(response.errors)
        } else {
          const parsed = parsePayloadToRecoil(response);

          if (parsed?.has_installments)
            setHasInstallments(true)
          else {
            setHasInstallments(false)
            setSalePayload(parsed)
          }
        }

        setLoading(false);
      }
    })()

    return () => {
      abortController.abort()
    }
  }, [id, open, setSalePayload])


  const onBack = () => {
    setPage(page - 1)
  }

  const onNext = () => {
    setPage(page + 1)
  }

  const onCloseModal = () => {
    setPage(0)
    resetPayload()
    onClose()
  }

  return {
    hasInstallments,
    loading,
    loadingError,
    page,
    onBack,
    onNext,
    onCloseModal
  }
}