import { Button, Menu, MenuItem, Typography } from '@mui/material';

import useViewModel from './viewModel'

type OptionsButtonPropTypes = {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  disabled?: boolean,
  label: string,
  options: {
    id: string,
    label: string,
  }[],
  variant?: 'contained' | 'text' | 'outlined',
  onClickOption: (id: string) => void
}

function OptionsButton({
  color = 'primary',
  disabled = false,
  label,
  options,
  variant = 'contained',
  onClickOption
}: OptionsButtonPropTypes) {
  const { anchorEl, open, close, handleClick } = useViewModel()

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant={variant}
        color={color}
        size='small'
        disabled={disabled}
        onClick={handleClick}
      >
        <Typography variant='button'>
          {label}
        </Typography>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => { close() }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            onClick={() => {
              onClickOption(option.id);
              close()
            }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export { OptionsButton }