import { CustomError, api } from 'utils/axios';

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean
}

type UsersType = {
  id: string,
  name: string,
  status: string,
  roles: string[],
  phone: string,
  email: string,
}

type UsersDisplayType = {
  id: string,
  name: string,
  status: string,
  roles: string,
  phone: string,
  email: string,
  actions: ActionType[]
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parseUsers = (response: { data: any, meta: any }) => {

  const users = response.data.map((data: any) => {
    return {
      id: data.id,
      name: data.attributes.name,
      status: data.attributes.status,
      phone: data.attributes.phone ? data.attributes.phone : '',
      email: data.attributes.email,
      roles: data.attributes.roles
    }
  });

  return {
    users,
    pagination: {
      totalUsers: response.meta.pagination.total
    }
  };
}

const getUsers = async (sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get('/api-cashone/users', {
      params,
      signal
    });

    return parseUsers(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatUsersToDisplay = (users: Array<UsersType>): Array<UsersDisplayType> => {

  return users.map((user: UsersType) => {
    return {
      id: user.id,
      name: user.name,
      status: user.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
      phone: user.phone ? user.phone : 'N/A',
      email: user.email,
      roles: user.roles.join(', '),
      actions: [
        { id: 'edit', label: 'Editar' }
      ]
    }
  })

}

export { getUsers, formatUsersToDisplay };

export type { UsersType, UsersDisplayType, PaginationType, SortType };