import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper } from '@mui/material';

import { TableCell } from '../Table/TableCell';
import { TableHead } from '../Table/TableHead';

type TablePropTypes = {
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  // isLoading: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  onPageChange: (page: number) => void,
  // onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void
}

function CashierStatementItemTable({
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rows,
  rowsPerPage,
  total,
  onPageChange,
  onRowsPerPageChange
}: TablePropTypes) {
  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAsc={isAsc}
          isAnySelected={false}
          onRequestSort={() => { }}
          orderBy={''}
        />

        <TableBody>
          {rows.slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage,
          ).map((row, index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell className='table-cell small' align='left'>
                <span className={``}>{row.customer_name}</span>
              </TableCell>
              <TableCell className='table-cell small' align='left'>
                <span className={``}>{row.source_key}</span>
              </TableCell>
              <TableCell className='table-cell small' align='left'>
                <span className={``}>{row.value}</span>
              </TableCell>
              <TableCell className='table-cell small' align='left'>
                <span className={``}>{row.interest_and_penalty_value}</span>
              </TableCell>
              <TableCell className='table-cell small' align='left'>
                <span className={``}>{row.tax_value}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { CashierStatementItemTable };