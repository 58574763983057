import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { AutocompleteAdapter } from 'components/FormComponents/Fields/AutocompleteAdapter'
import { CheckboxFieldAdapter } from 'components/FormComponents/Fields/CheckboxFieldAdapter'
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter'
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter'
import { Flexbox } from 'components/Flexbox'

import useViewModel from './viewModel'

import { CpfFieldAdapter } from 'components/FormComponents/Fields/CpfFieldAdapter';
import { CnpjFieldAdapter } from 'components/FormComponents/Fields/CnpjFieldAdapter';
import { CepFieldAdapter } from 'components/FormComponents/Fields/CepFieldAdapter';
import { MultipleEmailFieldAdapter } from 'components/FormComponents/Fields/MultipleEmailFieldAdapter';
import { MultipleTelephoneFieldAdapter } from 'components/FormComponents/Fields/MultipleTelephoneFieldAdapter';
import { MultipleTagFieldAdapter } from 'components/FormComponents/Fields/MultipleTagFieldAdapter';

import '../../styles.scss'
import { MultipleDetailAttachmentUpload } from 'components/MultipleDetailAttachmentUpload';
import { MultipleTextFieldAdapter } from 'components/FormComponents/Fields/MultipleTextFieldAdapter';

type CreateClientModalPropTypes = {
  open: boolean,
  type: string,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function CreateClientModal({ open, type, onClose, onSubmit }: CreateClientModalPropTypes) {
  const {
    citiesError,
    tagsError,
    tagsLoading,
    cityOptions,
    createLoading,
    createError,
    formError,
    openSnackbar,
    searchCityValue,
    searchLoading,
    states,
    formattedTags,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    onSearchCityChange,
    onStateChange
  } = useViewModel({ type, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='create-client-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={{ regime_type: type }}
            validate={formValidate}
            render={({ values, handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Criar Cliente - {type === 'brlPeopleRegime' ? 'Pessoa Física' : (type === 'brlCompanyRegime' ? 'Pessoa Jurídica' : 'Estrangeiro')}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>

                  <Flexbox size={12}>
                    <Flexbox size={8}>
                      <TextFieldAdapter
                        label={'Nome'}
                        name='name'
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={4}>
                      {
                        type === 'brlPeopleRegime' && <CpfFieldAdapter
                          name='document'
                          label='CPF'
                          disabled={createLoading} />
                      }

                      {
                        type === 'brlCompanyRegime' && <CnpjFieldAdapter
                          name='document'
                          label='CNPJ'
                          disabled={createLoading} />
                      }

                      {
                        type === 'brlInternationalCustomerRegime' && <TextFieldAdapter
                          label='Documento'
                          name='document'
                          disabled={createLoading}
                        />
                      }
                    </Flexbox>
                  </Flexbox>

                  {type === 'brlCompanyRegime' &&
                    <>
                      <Flexbox size={12}>
                        <Flexbox size={8}>
                          <TextFieldAdapter
                            label={'Razão Social'}
                            name='regime.razao_social'
                            disabled={createLoading}
                          />
                        </Flexbox>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            label={'Inscrição Municipal'}
                            name='regime.inscricao_municipal'
                            disabled={createLoading}
                          />
                        </Flexbox>
                      </Flexbox>

                      <div className='checkbox-container'>
                        <CheckboxFieldAdapter
                          label=''
                          name='regime.optante_simples_nacional'
                          options={[{ label: 'Optante pelo simples nacional', value: '1' }]}
                          disabled={createLoading}
                        />
                      </div>
                    </>
                  }

                  <Flexbox size={12}>
                    <MultipleEmailFieldAdapter
                      name='emails'
                      label={'Emails'}
                    />
                  </Flexbox>

                  <Flexbox size={12}>
                    {
                      type === 'brlInternationalCustomerRegime' &&
                      <MultipleTextFieldAdapter
                        label='Telefones'
                        name='phones'
                      />
                    }

                    {
                      type !== 'brlInternationalCustomerRegime' &&
                      <MultipleTelephoneFieldAdapter
                        name='phones'
                        label={'Telefones'}
                      />
                    }
                  </Flexbox>


                  <Typography variant='h3' fontWeight={700} className='section-title'>Endereço</Typography>

                  <Flexbox size={12}>
                    <Flexbox size={4}>
                      {
                        type !== 'brlInternationalCustomerRegime' && <CepFieldAdapter
                          label={'CEP'}
                          name='address.postal_code'
                          disabled={createLoading}
                        />
                      }

                      {
                        type === 'brlInternationalCustomerRegime' && <TextFieldAdapter
                          label={'CEP'}
                          name='address.postal_code'
                          disabled={createLoading}
                        />
                      }
                    </Flexbox>
                    <Flexbox size={8}>
                      <TextFieldAdapter
                        label={'Logradouro'}
                        name='address.street'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  </Flexbox>

                  <Flexbox size={12}>
                    <Flexbox size={4}>
                      <TextFieldAdapter
                        label={'Número'}
                        name='address.number'
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={4}>
                      <TextFieldAdapter
                        label={'Bairro'}
                        name='address.neighbourhood'
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={4}>
                      <TextFieldAdapter
                        label={'Complemento'}
                        name='address.complement'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  </Flexbox>

                  {type === 'brlInternationalCustomerRegime' ? (
                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          label={'País'}
                          name='address.country'
                          disabled={createLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          label={'Estado'}
                          name='address.state_province'
                          disabled={createLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          label={'Cidade'}
                          name='address.city'
                          disabled={createLoading}
                        />
                      </Flexbox>
                    </Flexbox>
                  ) : (
                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <SelectFieldAdapter
                          label={'Estado'}
                          name='address.state_abbreviation'
                          options={states}
                          onChange={(e) => {
                            onStateChange(e, form)
                          }}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <AutocompleteAdapter
                          label={'Cidade'}
                          name='address.city'
                          disabled={createLoading || !values.address?.state_abbreviation}
                          options={cityOptions}
                          value={searchCityValue}
                          searchLoading={searchLoading}
                          onChange={onSearchCityChange}
                        />
                      </Flexbox>
                    </Flexbox>
                  )}


                  <Typography variant='h3' fontWeight={700} className='section-title'>Informações adicionais</Typography>

                  <Flexbox size={12}>
                    <MultipleTagFieldAdapter
                      label={'Tags'}
                      name='tags'
                      loading={createLoading || tagsLoading}
                      tags={formattedTags}
                    />
                  </Flexbox>

                  <Flexbox size={12}>
                    <TextFieldAdapter
                      label={'Observações adicionais'}
                      name='description'
                      disabled={createLoading}
                      multiline
                      rows={3}
                    />
                  </Flexbox>

                  <Typography variant='h3' fontWeight={700} className='section-title'>Anexos</Typography>

                  <Flexbox size={12}>
                    <MultipleDetailAttachmentUpload
                      label={'Anexos'}
                      name={'details'}
                      initialDetails={[]}
                      loading={createLoading}
                    />
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained'>Salvar</Button>
                </div>
              </form>

            )}
          />
        </Box>
      </Modal>

      {(createError || citiesError || tagsError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="warning" variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!createError && !citiesError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Cliente criado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { CreateClientModal }