import { CustomError, api } from 'utils/axios'
import { parseToFloat } from 'utils/moneyFormatt'

type PlanType = {
  name: string,
  freq: string,
  initial_term: number,
  payment_term_type: string,
  payment_term_value: number,
  value: number
}

type PlanToDisplayType = {
  name: string,
  freq: string,
  initial_term: string,
  payment_term_type: string,
  payment_term_value: string,
  value: string
}

type BundlePlanPayload = {
  name: string,
  freq: string,
  initial_term: number,
  payment_term_type: string,
  payment_term_value: number,
  value: number
}

const parsePlan = (response: any) => {
  return {
    name: response.data.attributes.name,
    freq: response.data.attributes.freq,
    initial_term: response.data.attributes.initial_term,
    payment_term_type: response.data.attributes.payment_term_type,
    payment_term_value: response.data.attributes.payment_term_value,
    value: response.data.attributes.value.value,
  }
}

const getPlan = async (planId: string, productId: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/products/${productId}/plans/${planId}`, { signal })

    return parsePlan(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatPlanToDisplay = (plan: PlanType): PlanToDisplayType => {
  return {
    name: plan.name,
    freq: plan.freq,
    initial_term: plan.initial_term.toString(),
    payment_term_type: plan.payment_term_type,
    payment_term_value: plan.payment_term_value.toString(),
    value: plan.value.toString().replace('.', ','),
  }
}

const bundlePlan = (plan: PlanToDisplayType): BundlePlanPayload => {
  return {
    name: plan.name,
    freq: plan.freq,
    initial_term: parseInt(plan.initial_term),
    payment_term_type: plan.payment_term_type,
    payment_term_value: parseInt(plan.payment_term_value),
    value: parseToFloat(plan.value),
  }
}

const patchPlan = async (planId: string, productId: string, payload: PlanToDisplayType): Promise<any> => {
  try {
    const response = await api.patch(`/api-cashone/products/${productId}/plans/${planId}`, bundlePlan(payload))

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getPlan, formatPlanToDisplay, patchPlan }

export type { PlanType, PlanToDisplayType }