import { useEffect, useState } from 'react';

import { ProductDisplayType, ProductType, formatProductToDisplay, getProduct, patchProduct } from './services';

import { notAuthenticatedRedirect } from 'utils/auth';
import { formatForInputConcatingKey } from 'utils/formatConstantForInput';
import { CNAE_LIST, SERVICE_CODE_LIST } from 'utils/constants/ProductRegistrationConstants';
import { ApiError } from 'utils/CustomError';
import { ValidationErrors } from 'final-form';

type ViewModelProps = {
  id: string,
  type: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function ProductEditViewModel({ id, type, onClose, onSubmit }: ViewModelProps) {
  const [product, setProduct] = useState<ProductType>();
  const [formattedProduct, setFormattedProduct] = useState<ProductDisplayType>();
  const [productLoading, setProductLoading] = useState(true)
  const [productLoadingError, setProductLoadingError] = useState(false)

  const [editError, setEditError] = useState(false)
  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const cnaeList = formatForInputConcatingKey(CNAE_LIST)
  const [valueCnae, setValueCnae] = useState('')

  const serviceList = formatForInputConcatingKey(SERVICE_CODE_LIST)
  const [valueService, setValueService] = useState('')


  const onGetProductHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setProductLoadingError(true)
    } else {
      setProductLoadingError(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setProductLoading(true);

      if (id) {

        const response = await getProduct(id, type, abortController.signal);

        if (response.errors) {
          onGetProductHandleError(response.errors)
        }

        else {
          setProduct(response)
          setFormattedProduct(formatProductToDisplay(response))
          setProductLoadingError(false)
        }
      }

      setProductLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [id, type])


  const onPatchProductHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }


  const handleSubmit = async (values: Record<string, any>) => {
    setProductLoading(true)
    const response = await patchProduct(id, type, values as ProductDisplayType)

    if (response.errors)
      onPatchProductHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setProductLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const searchCNAE = (search: string) => {
    setValueCnae(search)
  }

  const searchService = (search: string) => {
    setValueService(search)
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.name)
      errors.name = 'Obrigatório'

    if (type === 'spot')
      if (!values.value)
        errors.value = 'Obrigatório'

    if (!values.cnae_code)
      errors.cnae_code = 'Obrigatório'

    if (!values.service_code)
      errors.service_code = 'Obrigatório'

    return errors
  }

  return {
    cnaeList,
    editError,
    formattedProduct,
    formError,
    openSnackbar,
    product,
    productLoading,
    productLoadingError,
    serviceList,
    valueCnae,
    valueService,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    searchCNAE,
    searchService,
  }
}