import dayjs from 'dayjs';

import { CustomError, api } from 'utils/axios';
import { formatMoney } from 'utils/moneyFormatt';

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type CashierStatementType = {
  id: number,
  value: number,
  description?: string,
  cashier_id: number,
  cashier_name: string,
  effective_date: string
}

type CashierStatementDisplayType = {
  id: number,
  value: string,
  value_is_positive: boolean,
  description?: string,
  cashier_id: number,
  cashier_name: string,
  effective_date: string
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type BanksType = {
  value: string,
  label: string,
}

type TotalsType = {
  cash_inflow: number,
  initial_balance: number,
  final_balance: number,
  period_start: string,
  period_end: string
}

type TotalsDisplayType = {
  cash_inflow: string,
  initial_balance: string,
  final_balance: string,
  period_start: string,
  period_end: string
}

const parseCashierStatement = async (response: { data: any, meta: any }) => {
  const cashiers = response.data.map((data: any) => {
    return {
      id: data.id,
      value: data.attributes.value,
      description: data.attributes.description,
      cashier_id: data.attributes.cashier_id,
      cashier_name: data.attributes.cashier_name,
      effective_date: data.attributes.effective_date.date,
    }
  });

  return {
    cashiers,
    pagination: {
      totalCashiers: response.meta.pagination.total
    },
    totals: {
      cash_inflow: response.meta.aggregate.cash_inflow,
      initial_balance: response.meta.aggregate.initial_balance,
      final_balance: response.meta.aggregate.final_balance,
      period_start: response.meta.aggregate.period_start.date,
      period_end: response.meta.aggregate.period_end.date,
    }
  }
}

const getCashierStatement = async (filters: FilterType[], search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get(`/api-cashone/cashier-statement`, {
      params,
      signal
    });

    return parseCashierStatement(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatCashierStatementToDisplay = (cashiers: Array<CashierStatementType>): Array<CashierStatementDisplayType> => {

  return cashiers.map((cashier: CashierStatementType) => {
    return {
      id: cashier.id,
      value: formatMoney(cashier.value),
      value_is_positive: cashier.value >= 0,
      description: cashier.description,
      cashier_id: cashier.cashier_id,
      cashier_name: cashier.cashier_name,
      effective_date: dayjs(cashier.effective_date).format('DD/MM/YYYY'),
    }
  })

}

const formatTotalsToDisplay = (totalizers: TotalsType): TotalsDisplayType => {
  return {
    cash_inflow: formatMoney(totalizers.cash_inflow),
    final_balance: formatMoney(totalizers.final_balance),
    initial_balance: formatMoney(totalizers.initial_balance),
    period_end: dayjs(totalizers.period_end).format('DD/MM/YYYY'),
    period_start: dayjs(totalizers.period_start).format('DD/MM/YYYY'),
  }
}

const parseBanks = (response: { data: Array<any> }) => {
  return response.data.map((data) => ({
    value: data.id,
    label: data.attributes.name
  }))
}

const getBanks = async (search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {
    limit: 10
  };

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/cashiers', {
      params,
      signal
    });

    return parseBanks(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getBanks, getCashierStatement, formatCashierStatementToDisplay, formatTotalsToDisplay };

export type {
  BanksType,
  PaginationType,
  SortType,
  CashierStatementType,
  CashierStatementDisplayType,
  FilterType,
  TotalsType,
  TotalsDisplayType,
};