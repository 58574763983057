import { Box, Tab, Tabs, Typography } from '@mui/material';

import { ProductRecurring } from './pages/Recurring';
import { ProductSpot } from './pages/Spot';

import { OptionsButton } from 'components/OptionsButton';

import useViewModel from './viewModel';

import '../styles.scss';
import { CreateProductModal } from 'components/Modals/Product/Create';

function Products() {
  const {
    createProductType,
    openCreateProduct,
    tabSelected,
    onTabChange,
    onCloseModal,
    onOpenModal
  } = useViewModel();

  return (
    <div className='listing-page products-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Produtos
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>
            <OptionsButton
              label='Criar produto'
              options={[
                { label: 'Assinatura', id: 'recurring' },
                { label: 'Spot', id: 'spot' },
              ]}
              onClickOption={onOpenModal}
            />

            <CreateProductModal
              open={openCreateProduct}
              type={createProductType ? createProductType : ''}
              onClose={onCloseModal}
            />
          </div>

        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
          onChange={(event, value) => { onTabChange(value) }}
        >

          <Tab
            label='Assinatura'
            id='tab-recurring'
            aria-controls='tabpanel-recurring'
          />
          <Tab
            label='Spot'
            id='tab-spot'
            aria-controls='tabpanel-spot'
          />

        </Tabs>
      </Box>

      <div
        role='tabpanel'
        hidden={tabSelected !== 0}
        id={'tabpanel-recurring'}
        aria-labelledby={'tab-recurring'}
      >
        <ProductRecurring />
      </div>

      <div
        role='tabpanel'
        hidden={tabSelected !== 1}
        id={'tabpanel-spot'}
        aria-labelledby={'tab-spot'}
      >
        <ProductSpot />
      </div>

    </div>
  );
}

export { Products };