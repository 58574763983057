import { Alert, Box, Button, Modal, Paper, Snackbar, Typography, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form';

import useViewModel, { SaleType } from './viewModel'

import { Flexbox } from 'components/Flexbox';
import { AutocompleteAdapter } from 'components/FormComponents/Fields/AutocompleteAdapter';

import '../../styles.scss'
import './styles.scss'

type ApplyCouponModalPropTypes = {
  saleData: SaleType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function ApplyCouponModal({ saleData, open, onClose, onSubmit }: ApplyCouponModalPropTypes) {
  const {
    coupons,
    couponsLoading,
    couponsLoadingError,
    saleLoading,
    saleError,
    searchCoupons,
    openSnackbar,
    formValidate,
    onCouponSearchChange,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ saleData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='cancel-sale-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
              >
                <div className='modal-header'>
                  <Typography variant='h2'>
                    Aplicar cupom a venda {saleData.key}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Flexbox size={12}>
                    <AutocompleteAdapter
                      label='Cupom de desconto'
                      name='coupon'
                      value={searchCoupons}
                      onChange={onCouponSearchChange}
                      options={coupons ? coupons : []}
                      searchLoading={couponsLoading}
                    />
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  {saleLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' color='error' disabled={saleLoading} onClick={onClose}>Cancelar</Button>
                  <Button variant='contained' disabled={saleLoading} onClick={handleSubmit}>Aplicar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(saleError || couponsLoadingError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!saleError && !couponsLoadingError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Cupom aplicado com sucesso!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { ApplyCouponModal }