import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'

import './styles.scss'
import { ReactElement } from 'react'

type ConfigurationItemPropTypes = {
  icon: ReactElement,
  title: string,
  description: string,
  link: string
}

function ConfigurationItem({
  icon,
  title,
  description,
  link
}: ConfigurationItemPropTypes) {
  return (
    <Link to={link} className='configuration-item'>
      <div className='icon'>
        {icon}
      </div>

      <div className='text'>
        <div className='title'>
          <Typography variant='h4' color={'primary'}>
            {title}
          </Typography>
        </div>
        <div className='description'>
          <Typography variant='body1'>
            {description}
          </Typography>
        </div>
      </div>
    </Link>
  )
}

export { ConfigurationItem }