import { Form } from 'react-final-form'
import { Alert, Button, Snackbar, Typography } from '@mui/material'

import { ApiErrorView } from 'components/ApiErrorView'
import { ImageFileInput } from 'components/ImageFileInput'
import { Flexbox } from 'components/Flexbox'
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter'
import { TelephoneFieldAdapter } from 'components/FormComponents/Fields/TelephoneFieldAdapter'
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter'

import useViewModel from './viewModel'

import './styles.scss'

function Company() {
  const {
    companyLoading,
    companyLoadingError,
    editError,
    formattedCompany,
    formattedRegime,
    formattedRegimeEspecial,
    formError,
    logoLoading,
    openSnackbar,
    postLogoError,
    handleSubmit,
    onCancel,
    onCloseSnackbar,
    onLogoChange,
  } = useViewModel()

  return (
    <div className='configuration-company'>

      {companyLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as configurações da empresa. Tente recarregar a página.' />
      }

      {!companyLoadingError &&
        <Form
          onSubmit={handleSubmit}
          initialValues={formattedCompany}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant='h1' className='configuration-title'>Configurações da sua empresa</Typography>

              <Flexbox size={12}>
                <Flexbox size={6}>
                  <Flexbox size={6}>

                    <div className='config-logo-container'>
                      <ImageFileInput
                        imageUrl={formattedCompany?.logo}
                        loading={companyLoading || logoLoading}
                        onChange={onLogoChange}
                      />
                    </div>

                  </Flexbox>

                  <Flexbox size={6} column>
                    <TextFieldAdapter
                      name='name'
                      label='Nome da empresa'
                      loading={companyLoading}
                    />
                    <TextFieldAdapter
                      name='cnpj'
                      label='CNPJ'
                      disabled
                      loading={companyLoading}
                    />
                  </Flexbox>
                </Flexbox>

                <Flexbox size={6} column>
                  <TelephoneFieldAdapter
                    name='telephone'
                    label='Telefone'
                    loading={companyLoading}
                  />
                  <TextFieldAdapter
                    name='email'
                    label='Email'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>

              </Flexbox>

              <Flexbox size={12}>
                <Flexbox size={6}>
                  <SelectFieldAdapter
                    name='regime_especial'
                    label='Regime especial de tributação'
                    loading={companyLoading}
                    options={formattedRegimeEspecial}
                  />
                </Flexbox>
                <Flexbox size={6}>
                  <TextFieldAdapter
                    name='inscricao_municipal'
                    label='Inscrição municipal'
                    loading={companyLoading}
                  />
                </Flexbox>
              </Flexbox>

              <Flexbox size={12}>
                <Flexbox size={6}>
                  <SelectFieldAdapter
                    name='regime'
                    label='Regime'
                    loading={companyLoading}
                    options={formattedRegime}
                  />
                </Flexbox>
              </Flexbox>

              <Typography variant='h3' fontWeight={700} className='section-title'>Endereço</Typography>

              <Flexbox size={12}>
                <Flexbox size={4}>
                  <TextFieldAdapter
                    name='postal_code'
                    label='CEP'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>
                <Flexbox size={4}>
                  <TextFieldAdapter
                    name='state_province'
                    label='Estado'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>
                <Flexbox size={4}>
                  <TextFieldAdapter
                    name='city'
                    label='Cidade'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>
              </Flexbox>

              <Flexbox size={12}>
                <Flexbox size={4}>
                  <TextFieldAdapter
                    name='address'
                    label='Endereço'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>
                <Flexbox size={4}>
                  <TextFieldAdapter
                    name='neighbourhood'
                    label='Bairro'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>
                <Flexbox size={4}>
                  <TextFieldAdapter
                    name='number'
                    label='Número'
                    disabled
                    loading={companyLoading}
                  />
                </Flexbox>
              </Flexbox>

              <Flexbox size={12}>
                <TextFieldAdapter
                  name='complement'
                  label='Complemento'
                  disabled
                  loading={companyLoading}
                />
              </Flexbox>

              <div className='config-buttons-container'>
                <Button variant='outlined' disabled={companyLoading || logoLoading} onClick={onCancel}>Cancelar</Button>
                <Button type='submit' variant='contained' disabled={companyLoading || logoLoading}>Salvar</Button>
              </div>
            </form>
          )}
        />
      }


      {editError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {postLogoError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Erro ao enviar a nova imagem! Tente novamente mais tarde.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError && !postLogoError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Configurações do sistema foram atualizadas!
          </Alert>
        </Snackbar>
      }
    </div>
  )
}

export { Company }