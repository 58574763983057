import { SnackbarProps } from '@mui/material';
import { atom } from 'recoil';

type CustomSnackbarProps = SnackbarProps & {
  severity?: string,
}

const snackbarAtom = atom<CustomSnackbarProps>({
  key: 'snackbar',
  default: {
    open: false,
    autoHideDuration: 5000
  }
})

export { snackbarAtom }