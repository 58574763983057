import useViewModel from './viewModel';

import { EmailListTable } from 'components/Tables/EmailListTable';
import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';

type EmailViewPropTypes = {
  clientId: string
}

function EmailView({
  clientId,
}: EmailViewPropTypes) {
  const {
    emailsLoading,
    emailsLoadingError,
    formattedEmails,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalEmails,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ clientId });

  return (
    <div className='listing-tab-view'>

      {!emailsLoading &&
        !emailsLoadingError &&
        formattedEmails?.length === 0 &&
        <EmptyView title={'Nenhum email encontrado para mostrar aqui'} />
      }

      {emailsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os emails. Tente recarregar a página.' />
      }

      {(emailsLoading || (formattedEmails !== undefined && formattedEmails.length > 0)) &&
        <EmailListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedEmails ? formattedEmails : []}
          rowsPerPage={rowsPerPage}
          total={totalEmails}
          isLoading={emailsLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />}

    </div>
  )
}

export { EmailView };