import { Button, IconButton, Skeleton, CircularProgress } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { Field, FieldRenderProps } from 'react-final-form';

import useViewModel from './viewModel'

import './styles.scss'
import { DetailAttachment } from 'components/DetailAttachment';

type MultipelDetailAttachmentUploadPropTypes = {
  label: string,
  name: string,
  initialDetails: string[],
  loading: boolean
}

const componentField = ({ input, detailList, loading, uploading, uploadingError, onChange, onDelete }: FieldRenderProps<any>) => (
  <>
    <div className='attachment-list'>
      {
        loading && detailList.length === 0 && <Skeleton width='100%' height='30px' />
      }

      {
        detailList && <div>
          {detailList.map((detail: string, index: number) => (
            <div className='attachment-item' key={index}>
              {
                loading && <Skeleton width='100%' height='30px' />
              }

              {!loading && <>
                <DetailAttachment key={index} url={detail} />
                <IconButton
                  className='add-button'
                  color='secondary'
                  size='small'
                  onClick={() => {
                    const allDetails = onDelete(index);
                    input.onChange(allDetails)
                  }}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </>}
            </div>
          ))}
        </div>
      }
    </div>

    {
      uploading && <CircularProgress color='primary' />
    }
  
    {
      !uploading && uploadingError && <p className='error-message'>Erro ao enviar o anexo. Tente novamente.</p>
    }

    {!uploading && <Button className='add-button' variant='text' color='secondary' component='label' disabled={loading || uploading}>
      + Anexar arquivo
      <input
        hidden
        type='file'
        accept='image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt'
        onChange={async ({ target }) => {
          if (target.files) {
            const file = target.files[0];

            if (file) {
              const allDetails = await onChange(file);
              input.onChange(allDetails)
            }
          }
        }}
      />
    </Button>}
  </>
);

function MultipleDetailAttachmentUpload({
  label,
  name,
  initialDetails,
  loading
}: MultipelDetailAttachmentUploadPropTypes) {

  const {
    uploading,
    uploadingError,
    detailList,
    onChange,
    onDeleteAttachment
  } = useViewModel({ initialDetails })

  return (
    <div className='field'>
      <div className='attachment-container'>
        <Field
          component={componentField}
          detailList={detailList}
          name={name}
          label={label}
          loading={loading}
          uploading={uploading}
          uploadingError={uploadingError}
          onChange={onChange}
          onDelete={onDeleteAttachment}
        />
      </div>
    </div>
  )
}

export { MultipleDetailAttachmentUpload }