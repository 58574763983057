import { useState } from 'react'

import { CashierBankPayload, postCashierBanks } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { FormApi, ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CreateCashierBankModalViewModel({ onClose, onSubmit }: ViewModelPropTypes) {

  const [createLoading, setCreateLoading] = useState(false)
  const [createError, setCreateError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [cashierBankType, setCashierBankType] = useState('')
  const cashierBankTypeOptions = [
    { label: 'Caixa', value: 'CASHIER_CASH' },
    { label: 'Conta bancária', value: 'CASHIER_BANK_ACCOUNT' }
  ]

  const onPostCashierBankHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setCreateError(true)
      setOpenSnackbar(true)
    } else {
      setCreateError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setCreateLoading(true)
    const response = await postCashierBanks(values as CashierBankPayload)

    if (response.errors)
      onPostCashierBankHandleError(response.errors)

    else {
      setCreateError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setCreateLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const onTypeChange = (type: string, form: FormApi) => {
    if (type === 'CASHIER_CASH') {
      form.change('bank_code', undefined)
      form.change('bank_name', undefined)
      form.change('routing_number', undefined)
      form.change('routing_number_digit_verifier', undefined)
      form.change('account_number', undefined)
      form.change('account_number_digit_verifier', undefined)
    } else if (type === 'CASHIER_BANK_ACCOUNT') {
      form.change('name', undefined)
    }
    
    setCashierBankType(type)
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    const codeRegex = /^\d+$/

    if (!values.type){
      errors.type = 'Obrigatório'

    } else if (values.type === 'CASHIER_CASH') {
      if (!values.name){
        errors.name = 'Obrigatório'
      }

    } else if (values.type === 'CASHIER_BANK_ACCOUNT') {
      if (!values.bank_code)
        errors.bank_code = 'Obrigatório'

      if (!values.bank_name)
        errors.bank_name = 'Obrigatório'

      if (!values.routing_number)
        errors.routing_number = 'Obrigatório'
      else if (!codeRegex.test(values.routing_number))
        errors.routing_number = 'Apenas números'

      if (values.routing_number_digit_verifier && !codeRegex.test(values.routing_number_digit_verifier))
        errors.routing_number_digit_verifier = 'Apenas números'

      if (!values.account_number)
        errors.account_number = 'Obrigatório'
      else if (!codeRegex.test(values.account_number))
        errors.account_number = 'Apenas números'

      if (values.account_number_digit_verifier && !codeRegex.test(values.account_number_digit_verifier))
        errors.account_number_digit_verifier = 'Apenas números'
    }

    return errors
  }

  return {
    createLoading,
    createError,
    formError,
    openSnackbar,
    cashierBankTypeOptions,
    cashierBankType,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    setCashierBankType,
    onTypeChange
  }
}