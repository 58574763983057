import { CustomError, api } from 'utils/axios';

import { formatMoney } from 'utils/moneyFormatt';

type PaginationType = {
  limit: number,
  page: number
}

type PlansType = {
  id: string,
  product_id: string,
  name: string,
  freq: string,
  initial_term: number,
  value: number
}

type PlansDisplayType = {
  id: string,
  product_id: string,
  name: string,
  freq: string,
  initial_term: string,
  value: string
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parsePlans = (productId: string, response: { data: any, meta: any }) => {
  const plans = response.data.map((data: any) => ({
    id: data.id,
    product_id: productId,
    name: data.attributes.name,
    freq: data.attributes.freq,
    initial_term: data.attributes.initial_term,
    value: data.attributes.value.value,
  }))

  return {
    plans,
    pagination: { totalPlans: response.meta.pagination.total }
  }
}

const getPlans = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get(`/api-cashone/products/${id}/plans`, {
      params,
      signal
    })

    return parsePlans(id, response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatPlansToDisplay = (plans: PlansType[]): PlansDisplayType[] => {
  const translateFreq = {
    'YEARLY': 'Por ano',
    'SEMIANNUAL': 'Por semestre',
    'QUARTERLY': 'Por trimestre',
    'MONTHLY': 'Por mês',
  }

  type FreqEnum = keyof typeof translateFreq;

  return plans.map(plan => {
    return {
      id: plan.id,
      product_id: plan.product_id,
      name: plan.name,
      freq: translateFreq[plan.freq as FreqEnum],
      initial_term: `${plan.initial_term} meses`,
      value: formatMoney(plan.value),
    }
  })
}

export { formatPlansToDisplay, getPlans };

export type { PaginationType, PlansType, PlansDisplayType, SortType }