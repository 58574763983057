import { CustomError } from 'utils/CustomError';
import { api } from 'utils/axios';

const resetPassword = async (email: string) => {
  try {
    const response = await api.post('/cashone/users/passwords/reset', {
      data: {
        type: 'user',
        attributes: { email }
      }
    })

    return response.data;
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { resetPassword }