import { CustomError, api } from 'utils/axios'

import { parseToFloat, parseToString } from 'utils/moneyFormatt'

type CouponPayload = {
  id: string,
  name: string,
  code: string,
  type: string,
  value: string
}

type ParsedCouponPayload = {
  id: string,
  name: string,
  code: string,
  type: string,
  value: number
}

const formatCouponToDisplay = (values: ParsedCouponPayload): CouponPayload => {
  let formattedValue;

  if (values.type === 'FIXED')
    formattedValue = values.value
  else
    formattedValue = values.value * 100

  return {
    id: values.id,
    name: values.name,
    code: values.code,
    type: values.type,
    value: parseToString(formattedValue)
  }
}

const bundlePayload = (payload: CouponPayload): ParsedCouponPayload => {

  let formattedValue;

  if (payload.type === 'FIXED')
    formattedValue = parseToFloat(payload.value)
  else
    formattedValue = Math.round(parseToFloat(payload.value) * 10000) / 1000000

  return {
    id: payload.id,
    name: payload.name,
    code: payload.code,
    type: payload.type,
    value: formattedValue
  }
}

const patchCoupon = async (payload: CouponPayload): Promise<any> => {
  try {
    const response = await api.patch(`/api-cashone/coupons/${payload.id}`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { patchCoupon, formatCouponToDisplay }

export type { ParsedCouponPayload, CouponPayload }