import { useCallback, useEffect, useState } from 'react';

import { DetailsDisplayType, DetailsType, formatDetailsToDisplay, getDetails } from './services';

import { useTitleName } from 'recoil/viewTitleName/actions';
import { notAuthenticatedRedirect } from 'utils/auth';
import { ApiError } from 'utils/CustomError';

type ViewModelProps = {
  cashierStatementId: string
}

export default function CashierStatementGeneralViewModel({ cashierStatementId }: ViewModelProps) {
  const [details, setDetails] = useState<DetailsType>();
  const [formattedDetails, setFormattedDetails] = useState<DetailsDisplayType>();


  const [detailsLoading, setDetailsLoading] = useState(true)
  const [detailsLoadingError, setDetailsLoadingError] = useState(false)

  const { titleName, updateTitleName } = useTitleName();

  // cashier statement items pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente', sortable: false },
    { id: 'source', label: 'Origem', sortable: false },
    { id: 'value', label: 'Valor líquido', sortable: false },
    { id: 'interest_and_penalty_value', label: 'Juros e multas', sortable: false },
    { id: 'tax_value', label: 'Taxas', sortable: false }
  ]

  const memoizedUpdateTitleName = useCallback(
    (name: string, loading = false) => {
      updateTitleName(name, loading);
    },
    [updateTitleName]
  );

  const onGetDetailsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setDetailsLoadingError(true)

      setDetails(undefined);
      setFormattedDetails(undefined)
    } else {
      setDetailsLoadingError(true)

      setDetails(undefined);
      setFormattedDetails(undefined)
    }
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setDetailsLoading(true);

      const response = await getDetails(cashierStatementId, abortController.signal);

      if (response.errors) {
        onGetDetailsHandleError(response.errors)
      } else {
        setDetails(response)
        setFormattedDetails(formatDetailsToDisplay(response))
      }

      setDetailsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [cashierStatementId])

  useEffect(() => {
    memoizedUpdateTitleName(formattedDetails?.description || '', detailsLoading);
  }, [detailsLoading, formattedDetails?.description, memoizedUpdateTitleName]);

  return {
    details,
    detailsLoading,
    detailsLoadingError,
    formattedDetails,
    titleName,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    tableHeadCells,
    onPageChange,
    onRowsPerPageChange
  }
}