import { CustomError, api } from 'utils/axios'

type ClientType = {
  emails: string[]
}

type InvoiceReceiptPayload = {
  emails: string[]
}

type ParsedInvoiceReceiptPayload = {
  emails: string[]
}

const parseClient = (response: { data: any }): ClientType => {
  return {
    emails: response.data.attributes.emails,
  }
}

const getClient = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/clients/${id}`, { signal })

    return parseClient(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const bundlePayload = (payload: InvoiceReceiptPayload): ParsedInvoiceReceiptPayload => (
  {
    emails: payload.emails
  }
)

const emailInvoiceReceipt = async (id: string, payload: InvoiceReceiptPayload): Promise<any> => {
  try {
    return await api.post(`/api-cashone/invoices-receipts/${id}/send-email`, bundlePayload(payload))
    
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { emailInvoiceReceipt, getClient }

export type { ParsedInvoiceReceiptPayload, InvoiceReceiptPayload, ClientType }