import { ClickAwayListener, Tooltip, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useViewModel from './viewModel'

import './styles.scss'

type DetailAttachmentPropTypes = {
  description?: string,
  text?: string,
  url?: string,
}

function DetailAttachment({ description, text, url }: DetailAttachmentPropTypes) {
  const { isTooltipOpen, handleClose, handleOpen } = useViewModel({ url: url ? url : '' })

  return (
    <div className='detail-attachment'>

      {description &&
        <div className='detail-description'>
          <Typography>
            {description}
          </Typography>
        </div>
      }

      {url &&
        <div className='detail-url'>
          <div className={`url ${text ? '' : 'fixed-width'}`}>
            <a href={url} target='_blank' rel='noreferrer'>
              <Typography fontWeight={700} component='span'>
                {text ? text : url}
              </Typography>
            </a>
          </div>

          <ClickAwayListener
            onClickAway={handleClose}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleClose}
              open={isTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClick={handleOpen}
              title='Link copiado!'
              placement='top'
            >
              <ContentCopyIcon className='copy-link-icon' />
            </Tooltip>
          </ClickAwayListener>
        </div>
      }
    </div>
  )
}

export { DetailAttachment }