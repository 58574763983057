import { useEffect, useState } from 'react';

import { EventDisplayType, EventType, formatEventsToDisplay, getEvents } from './services';
import { notAuthenticatedRedirect } from 'utils/auth';

type ViewModelProps = {
  receiptId: string
}

const getEventsServiceHandler = async (id: string, signal: AbortSignal) => {
  const response = await getEvents(id, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect()

    return []
  }

  return response;
}

export default function ProductRecurringGeneralViewModel({ receiptId }: ViewModelProps) {
  const [events, setEvents] = useState<EventType[]>();
  const [formattedEvents, setFormattedEvents] = useState<EventDisplayType[]>()
  const [eventsLoading, setEventsLoading] = useState(true)

  const [expandedEvent, setExpandedEvent] = useState<number | null>(null);

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setEventsLoading(true);

      const response = await getEventsServiceHandler(receiptId, abortController.signal);

      if (response && !abortController.signal.aborted) {
        setEvents(response)
        setFormattedEvents(formatEventsToDisplay(response))
      }

      setEventsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [receiptId])


  const onEventClick = (index: number) => {
    if (index === expandedEvent)
      setExpandedEvent(null)
    else
      setExpandedEvent(index)
  }

  return {
    events,
    eventsLoading,
    expandedEvent,
    formattedEvents,
    onEventClick
  }
}