import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Flexbox } from 'components/Flexbox'

import './styles.scss'

type EventRegisterPropTypes = {
  expanded: boolean,
  payloads: {
    sent: string | null,
    received: string | null,
  },
  timestamp: string,
  title: string,
  onChange: () => void
}

function EventRegister({
  expanded,
  payloads,
  timestamp,
  title,
  onChange,
}: EventRegisterPropTypes) {
  return (
    <Accordion
      expanded={expanded}
      className='event-register-accordion'
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className='event-title'
      >
        <Typography fontWeight={700}>
          {timestamp} - {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='event-content'>

        {payloads.sent && (
          <Flexbox size={12}>
            <Flexbox size={2}>
              <Typography variant='body1' className='event-info-label'>
                Enviado
              </Typography>
            </Flexbox>
            <Flexbox size={10}>
              <div className='event-info-text'>
                <pre>
                  {JSON.stringify(JSON.parse(payloads.sent), null, 2)}
                </pre>
              </div>
            </Flexbox>
          </Flexbox>
        )}

        {payloads.received && (
          <Flexbox size={12}>
            <Flexbox size={2}>
              <Typography variant='body1' className='event-info-label'>
                Recebido
              </Typography>
            </Flexbox>
            <Flexbox size={10}>
              <div className='event-info-text'>
                <pre>
                  {JSON.stringify(JSON.parse(payloads.received), null, 2)}
                </pre>
              </div>
            </Flexbox>
          </Flexbox>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export { EventRegister }