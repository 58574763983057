import { ChangeEvent } from 'react';

import MUITableHead from '@mui/material/TableHead';
import { Box, Checkbox, TableRow, TableSortLabel, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { TableCell } from '../TableCell';

type TableHeadPropTypes = {
  headCells: Array<{
    id: string,
    label: string,
    sortable?: boolean
  }>,
  isAllSelected: boolean,
  isAnySelected: boolean,
  isAsc: boolean,
  optionMenu?: boolean,
  orderBy: string,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onSelectAll?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
}

function TableHead({
  headCells,
  isAllSelected,
  isAnySelected,
  isAsc,
  optionMenu = false,
  orderBy,
  onRequestSort,
  onSelectAll,
}: TableHeadPropTypes) {

  return (
    <MUITableHead>
      <TableRow className='table-head'>

        {onSelectAll && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              checked={isAllSelected}
              indeterminate={isAllSelected !== isAnySelected}
              onChange={onSelectAll}
            />
          </TableCell>
        )}


        {headCells.map((cell) => (
          <TableCell
            className='table-cell'
            key={cell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === cell.id ? (isAsc ? 'asc' : 'desc') : false}
          >

            {cell.sortable === false ? (

              <Typography fontWeight={'bold'} variant='caption'>
                {cell.label}
              </Typography>

            ) : (

              <TableSortLabel
                active={orderBy === cell.id}
                direction={isAsc ? 'asc' : 'desc'}
                onClick={() => {
                  onRequestSort({
                    field: cell.id,
                    isAsc: orderBy === cell.id ? !isAsc : false
                  })
                }}
              >
                <Typography fontWeight={'bold'} variant='caption'>
                  {cell.label}
                </Typography>

                {orderBy === cell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {isAsc ? 'sorted ascending' : 'sorted descending'}
                  </Box>
                ) : null}

              </TableSortLabel>
            )}

          </TableCell>
        ))}

        {optionMenu && <TableCell />}

      </TableRow>
    </MUITableHead>
  );
}

export { TableHead };