import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from '../../utils/auth';

import { AccountsReceivablesDisplayType, AccountsReceivablesType, FilterType, PaginationType, SortType, formatARsToDisplay, getARs, formatTotalsToDisplay } from './services';

const getARsServiceHandler = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal) => {
  const response = await getARs(filters, search, sort, pagination, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect()


    return {
      accountsReceivables: []
    }
  }

  return response;
}

export default function AccountsReceivablesViewModel() {
  const [accountsReceivables, setAccountsReceivables] = useState<Array<AccountsReceivablesType>>();
  const [formattedARs, setFormattedARs] = useState<Array<AccountsReceivablesDisplayType>>();
  const [searchARs, setSearchARs] = useState('');

  const [ARsLoading, setARsLoading] = useState(true);

  // filters
  const [dueDate, setDueDate] = useState<FilterType[]>([{
    field: 'due_date',
    comparator: 'gte',
    value: []
  }, {
    field: 'due_date',
    comparator: 'lte',
    value: []
  }]);

  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: []
  });

  const [paymentFilter, setPaymentFilter] = useState<FilterType>({
    field: 'payment_method',
    comparator: 'in',
    value: []
  });

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // totals
  const [totalARs, setTotalARs] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [formattedTotalValue, setFormattedTotalValue] = useState('');

  const [actionARIndex, setActionARIndex] = useState<number>()

  const [openCancel, setOpenCancel] = useState(false)
  const [openDueDate, setOpenDueDate] = useState(false)
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false)
  const [openSendEmail, setOpenSendEmail] = useState(false)
  const [openRetry, setOpenRetry] = useState(false)
  const [openAcknowledge, setOpenAcknowledge] = useState(false)

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'total', label: 'Cobrança' },
    { id: 'due_date', label: 'Vencimento' },
    { id: 'net_value', label: 'Valor transferido' },
    { id: 'transfer_date', label: 'Data da transferência' },
    { id: 'status', label: 'Status' },
  ]

  const statusOptions = [
    { label: 'Cancelados', value: 'CANCELLED' },
    { label: 'Rascunhos', value: 'DRAFT' },
    { label: 'Erro', value: 'ERROR' },
    { label: 'Expirados', value: 'EXPIRED' },
    { label: 'Pagos', value: 'PAID' },
    { label: 'Parcialmente pagos', value: 'PARTIALLY_PAID' },
    { label: 'Pendente', value: 'PENDING' },
    { label: 'Renegociados', value: 'RENEGOTIATED' },
    { label: 'Transferidos', value: 'TRANSFERRED' },
  ]

  const paymentOptions = [
    { label: 'Boleto', value: 'BOLETO' },
    { label: 'Cartão de Crédito', value: 'CREDIT_CARD' },
    { label: 'Depósito Bancário', value: 'BANK_DEPOSIT' },
    { label: 'Pix', value: 'PIX' },
  ]

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setARsLoading(true);

      const response = await getARsServiceHandler([...dueDate, paymentFilter, statusFilter], searchARs, sort, { page, limit: rowsPerPage }, abortController.signal);
      setAccountsReceivables(response.accountsReceivables as Array<AccountsReceivablesType>);
      setFormattedARs(formatARsToDisplay(response.accountsReceivables))

      if (response.totals) {
        setTotalARs(response.totals.totalARs)
        setTotalValue(response.totals.total)

        const formattedTotals = formatTotalsToDisplay(response.totals);
        setFormattedTotalValue(formattedTotals.total)
      }

      setARsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }
  }, [dueDate, page, paymentFilter, rowsPerPage, searchARs, sort, statusFilter]);


  const applyDueDateFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...dueDate];

    newDateFilter[0].value = startDate ? [startDate.format('YYYY-MM-DD')] : [];
    newDateFilter[1].value = endDate ? [endDate.format('YYYY-MM-DD')] : [];

    setDueDate(newDateFilter);
  }

  const applyPaymentFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setPaymentFilter({ ...paymentFilter, value: optionsSelected });
  }

  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'due_date':
        setActionARIndex(index);
        setOpenDueDate(true)
        break;
      case 'payment_method':
        setActionARIndex(index)
        setOpenPaymentMethod(true)
        break;
      case 'cancel':
        setActionARIndex(index)
        setOpenCancel(true)
        break;
      case 'send_email':
        setActionARIndex(index)
        setOpenSendEmail(true)
        break;
      case 'retry':
        setActionARIndex(index)
        setOpenRetry(true)
        break;
      case 'acknowledge':
        setActionARIndex(index)
        setOpenAcknowledge(true)
        break;
    }
  }

  const onARsSearchChange = (search: string) => {
    setPage(1)
    setSearchARs(search);
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseCancel = () => {
    setOpenCancel(false)
  }

  const onCloseDueDate = () => {
    setOpenDueDate(false)
  }

  const onClosePaymentMethod = () => {
    setOpenPaymentMethod(false)
  }

  const onCloseSendEmail = () => {
    setOpenSendEmail(false)
  }

  const onCloseRetry = () => {
    setOpenRetry(false)
  }

  const onCloseAcknowledge = () => {
    setOpenAcknowledge(false)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    accountsReceivables,
    actionARIndex,
    ARsLoading,
    dueDate,
    totalValue,
    formattedARs,
    formattedTotalValue,
    openCancel,
    openDueDate,
    openPaymentMethod,
    openSendEmail,
    openRetry,
    openAcknowledge,
    optionsRowsPerPage,
    page,
    paymentFilter,
    paymentOptions,
    rowsPerPage,
    searchARs,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalARs,
    applyDueDateFilter,
    applyPaymentFilter,
    applyStatusFilter,
    handleTableRowActions,
    onARsSearchChange,
    onChangeSort,
    onCloseCancel,
    onCloseSendEmail,
    onCloseDueDate,
    onClosePaymentMethod,
    onCloseRetry,
    onCloseAcknowledge,
    onPageChange,
    onRowsPerPageChange,
  }
}