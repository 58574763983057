import dayjs from 'dayjs';
import { CustomError, api } from 'utils/axios';

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type CreditCardType = {
  holder_name: string,
  last_digits: string,
  brand: string,
  expiration_month: string,
  expiration_year: string,
  created_at: string,
  status: string,
}

type CreditCardDisplayType = {
  holder_name: string,
  last_digits: string,
  brand: string,
  expiration_date: string,
  created_at: string,
  status: string,
}

const parseCreditCards = async (response: { data: any, meta: any }) => {
  const creditCards = response.data.map((data: any) => {
    return {
      holder_name: data.attributes.holder_name,
      last_digits: data.attributes.last_digits,
      brand: data.attributes.brand,
      expiration_month: data.attributes.expiration_month,
      expiration_year: data.attributes.expiration_year,
      created_at: data.attributes.created_at.date,
      status: data.attributes.status,
    }
  });

  return {
    creditCards,
    pagination: {
      totalCreditCards: response.meta.pagination.total
    }
  }
}

const getCreditCards = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get(`/api-cashone/clients/${id}/credit-cards`, {
      params,
      signal
    });

    return parseCreditCards(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatCreditCardsToDisplay = (creditCards: Array<CreditCardType>): Array<CreditCardDisplayType> => {

  return creditCards.map((creditCard: CreditCardType) => {
    return {
      holder_name: creditCard.holder_name,
      last_digits: `***${creditCard.last_digits}`,
      brand: creditCard.brand,
      expiration_date: `${creditCard.expiration_month}/${creditCard.expiration_year}`,
      created_at: dayjs(creditCard.created_at).format('DD/MM/YYYY'),
      status: creditCard.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
    }
  })

}

export { formatCreditCardsToDisplay, getCreditCards };

export type { PaginationType, SortType, CreditCardType, CreditCardDisplayType };