import { Skeleton, Typography } from '@mui/material';
import { Fragment } from 'react';

import './styles.scss'

type DisplayInfoTextMultLinePropTypes = {
  title: string,
  text: string[],
  isLoading?: boolean
}

function DisplayInfoTextMultLine({ title, text, isLoading = false }: DisplayInfoTextMultLinePropTypes) {
  return (
    <div className='display-info-text-mult-line'>
      <div className='title'>
        <Typography variant='caption'>
          {title}
        </Typography>
      </div>
      <div className='text'>
        <Typography fontWeight={700}>
          {isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              {text.map((t, index) => (
                <Fragment key={index}>
                  {t}
                  <br />
                </Fragment>
              ))}
            </>
          )}
        </Typography>
      </div>
    </div>
  );
}

export { DisplayInfoTextMultLine }