import { useEffect } from 'react';

import { auth, notAuthenticatedRedirect, setAuthOnStorage } from 'utils/auth';
import { ApiError } from 'utils/CustomError';

import { pingMe, postLogout } from './services';

export default function PrivateMenuViewModel() {

  const onPingMeHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException')
      notAuthenticatedRedirect();
  }

  useEffect(() => {
    const { token } = auth;

    if (!token)
      notAuthenticatedRedirect();

    const abortController = new AbortController();

    (async () => {
      const response = await pingMe(abortController.signal)

      if (response.errors) {
        onPingMeHandleError(response.errors)
      }
    })()

    return () => {
      abortController.abort()
    }
  }, []);


  const onLogout = async () => {
    await postLogout()

    setAuthOnStorage({})
    notAuthenticatedRedirect();
  }

  return { onLogout }
}