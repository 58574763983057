import dayjs from 'dayjs';

import { CustomError, api } from 'utils/axios';
import { formatMoney } from 'utils/moneyFormatt'

type InvoicesType = {
  id: string,
  customer_name: string,
  total_value: number,
  receipt_number: string,
  processed_date: string,
  payment_status: string,
  status: string,
  receipt_url: string
}

type InvoicesDisplayType = {
  id: string,
  customer_name: string,
  total_value: string,
  receipt_number: string,
  processed_date: string,
  payment_status: string,
  status: string,
  receipt_url: string
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parseInvoices = (response: { data: any[], meta: any }) => {
  const invoices = response.data.map(data => {
    return {
      id: data.id,
      customer_name: data.attributes.customer_name,
      total_value: data.attributes.total_value,
      receipt_number: data.attributes.receipt_number,
      processed_date: data.attributes.processed_date?.date,
      payment_status: data.attributes.payment_status,
      status: data.attributes.status,
      receipt_url: data.attributes.receipt_url,
    }
  })

  return {
    invoices,
    pagination: {
      totalInvoices: response.meta.pagination.total
    }
  }
}

const getInvoices = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get(`/api-cashone/clients/${id}/invoices-receipts`, {
      params,
      signal
    })

    return parseInvoices(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatInvoicesToDisplay = (invoices: InvoicesType[]): InvoicesDisplayType[] => {
  const translateStatus = {
    'DRAFT': 'RASCUNHO',
    'AUTHORIZED': 'AUTORIZADO',
    'CANCELLED': 'CANCELADO',
    'CANCELLING': 'CANCELANDO',
    'ERROR': 'ERRO',
    'PENDING': 'PENDENTE',
    'PROCESSING': 'PROCESSANDO'
  }

  const translatePaymentStatus = {
    'CANCELED': 'Cancelado',
    'EXPIRED': 'Expirado',
    'FAILED': 'Falhou',
    'PAID': 'Pago',
    'PENDING': 'Pendente',
  }

  type StatusEnum = keyof typeof translateStatus;
  type PaymentStatusEnum = keyof typeof translatePaymentStatus

  return invoices.map(invoice => {
    return {
      id: invoice.id,
      customer_name: invoice.customer_name,
      total_value: formatMoney(invoice.total_value),
      receipt_number: invoice.receipt_number ? invoice.receipt_number : '-',
      processed_date: invoice.processed_date ? dayjs(invoice.processed_date).format('DD/MM/YYYY') : '-',
      payment_status: translatePaymentStatus[invoice.payment_status as PaymentStatusEnum],
      status: translateStatus[invoice.status as StatusEnum],
      receipt_url: invoice.receipt_url
    }
  })
}

export { getInvoices, formatInvoicesToDisplay };

export type { InvoicesDisplayType, InvoicesType, PaginationType, SortType };