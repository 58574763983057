import { Outlet } from 'react-router-dom';

import { Sidebar } from './Sidebar';

import useViewModel from './viewModel'

import './styles.scss';

function PrivateMenu() {
  const { onLogout } = useViewModel()

  return (
    <div className='flex-container'>
      <div className='sidebar-container'>
        <Sidebar
          onLogout={onLogout}
        />
      </div>
      <div className='page-container'>
        <Outlet />
      </div>
    </div>
  );
}

export { PrivateMenu };