import { Alert, Box, Button, Modal, Paper, Snackbar, Typography, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


import useViewModel, { SaleType } from './viewModel'

import '../../styles.scss'
import './styles.scss'

type CancelSaleModalPropTypes = {
  saleData: SaleType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function CancelSaleModal({ saleData, open, onClose, onSubmit }: CancelSaleModalPropTypes) {
  const {
    saleLoading,
    saleError,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ saleData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='cancel-sale-modal custom-modal'>
          <div className='modal-header'>
            <Typography variant='h2'>
              Cancelar a venda {saleData.key}
            </Typography>
            <div className='modal-close-button' onClick={onClose}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body'>
            <Typography>
              Tem certeza que deseja cancelar esta venda?
            </Typography>
          </div>

          <div className='modal-footer'>
            {saleLoading && <CircularProgress className='loading' color='primary' size={20} />}
            <Button variant='outlined' disabled={saleLoading} onClick={onClose}>Não</Button>
            <Button variant='contained' color='error' disabled={saleLoading} onClick={handleSubmit}>Sim</Button>
          </div>
        </Box>
      </Modal>

      {saleError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!saleError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Venda cancelada com sucesso!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { CancelSaleModal }