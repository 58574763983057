import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import { CustomError, api } from '../../utils/axios';

type PlanRevenueType = {
  name: string,
  values: Array<{ value: number, date: string }>
}

type PlanRevenueDisplayType = {
  label: Array<string>,
  series: Array<{label: string, stack: string, data: Array<number> }>
}

type ReceiptRevenueType = {
  value: number,
  date: string,
}

type ReceiptRevenueDisplayType = {
  label: Array<string>,
  data: Array<number>,
  valueFormatter: any
}

type ActiveCustomerType = {
  value: number,
  date: string,
}

type ActiveCustomerDisplayType = {
  label: Array<string>,
  data: Array<number>
}

type ChurnedCustomerType = {
  value: number,
  date: string,
}

type ChurnedCustomerDisplayType = {
  label: Array<string>,
  data: Array<number>
}

type CashierFlowType = {
  value: number,
  date: string,
}

type CashierFlowDisplayType = {
  label: Array<string>,
  data: Array<number>
  valueFormatter: any
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format;

// Plan revenue

const parsePlanRevenue = (response: { data: any, meta: any }) => {

  const planRevenue = response.data.map((data: any) => {
    return {
      name: data.attributes.label,
      values: data.attributes.data.map((revenuePoint: any) => {
        return {
          value: revenuePoint.attributes.value,
          date: revenuePoint.attributes.date.date
        }
      })
    }
  });

  return planRevenue.slice(1, planRevenue.length - 1);
}

const getPlanRevenue = async (filters: Array<FilterType>, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/metrics/revenue-value', {
      params,
      signal
    });

    return parsePlanRevenue(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatPlanRevenueToDisplay = (planRevenueList: Array<PlanRevenueType>): PlanRevenueDisplayType => {
  if (planRevenueList.length === 0) {
    return {
      label: [],
      series: []
    }
  }
  
  const labels = planRevenueList[0].values.map((currentValue) => {
    return dayjs(currentValue.date).locale('pt-br').format('MMM/YY')
  })

  const series = planRevenueList.map((planRevenue: PlanRevenueType) => {
    return {
      label: planRevenue.name,
      stack: 'A',
      data: planRevenue.values.map(currentValue => {
        return currentValue.value
      }),
      valueFormatter: currencyFormatter
    }
  })

  return {
    label: labels,
    series: series
  }

}

// Receipt revenue

const parseReceiptRevenue = (response: { data: any, meta: any }) => {

  const receiptValue = response.data.map((data: any) => {
    return {
      value: data.attributes.value,
      date: data.attributes.date.date
    }
  });

  return receiptValue;
}

const getReceiptRevenue = async (filters: Array<FilterType>, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/metrics/receipt-value', {
      params,
      signal
    });

    return parseReceiptRevenue(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatReceiptRevenueToDisplay = (receiptValueList: Array<ReceiptRevenueType>): ReceiptRevenueDisplayType => {

  const labels: Array<string> = []
  const values: Array<number> = []

  receiptValueList.forEach(element => {
    labels.push(dayjs(element.date).locale('pt-br').format('MMM/YY'))
    values.push(element.value)
  });

  return {
    label: labels,
    data: values,
    valueFormatter: currencyFormatter
  }

}

// Active customers

const parseActiveCustomers = (response: { data: any, meta: any }) => {

  return response.data.map((data: any) => {
    return {
      value: data.attributes.value,
      date: data.attributes.date.date
    }
  });
}

const getActiveCustomers = async (filters: Array<FilterType>, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  filters.push({
    field: 'granularity',
    comparator: 'eq',
    value: 'MONTHLY'
  })

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/metrics/ending-customer', {
      params,
      signal
    });

    return parseActiveCustomers(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatActiveCustomersToDisplay = (activeCustomerList: Array<ActiveCustomerType>): ActiveCustomerDisplayType => {

  const labels: Array<string> = []
  const values: Array<number> = []

  activeCustomerList.forEach(element => {
    labels.push(dayjs(element.date).locale('pt-br').format('MMM/YY'))
    values.push(element.value)
  });

  return {
    label: labels,
    data: values
  }

}

// Churned customers

const parseChurnedCustomers = (response: { data: any, meta: any }) => {

  return response.data.map((data: any) => {
    return {
      value: data.attributes.value,
      date: data.attributes.date.date
    }
  });
}

const getChurnedCustomers = async (filters: Array<FilterType>, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  filters.push({
    field: 'granularity',
    comparator: 'eq',
    value: 'MONTHLY'
  })

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/metrics/churned-customer', {
      params,
      signal
    });

    return parseChurnedCustomers(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatChurnedCustomersToDisplay = (churnedCustomerList: Array<ChurnedCustomerType>): ChurnedCustomerDisplayType => {

  const labels: Array<string> = []
  const values: Array<number> = []

  churnedCustomerList.forEach(element => {
    labels.push(dayjs(element.date).locale('pt-br').format('MMM/YY'))
    values.push(element.value)
  });

  return {
    label: labels,
    data: values
  }

}

// Cashier flow

const parseCashierFlow = (response: { data: any, meta: any }) => {

  const cashierFlow = response.data[2].attributes.data.map(((revenue: any) => {
    return {
      date: revenue.attributes.date.date,
      value: revenue.attributes.value
    }
  }))

  return cashierFlow;
}

const getCashierFlow = async (filters: Array<FilterType>, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/metrics/cashier-flow', {
      params,
      signal
    });

    return parseCashierFlow(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatCashierFlowToDisplay = (cashierFlowList: Array<CashierFlowType>): CashierFlowDisplayType => {

  const labels: Array<string> = []
  const values: Array<number> = []

  cashierFlowList.forEach(element => {
    labels.push(dayjs(element.date).locale('pt-br').format('MMM/YY'))
    values.push(element.value)
  });

  return {
    label: labels,
    data: values,
    valueFormatter: currencyFormatter
  }

}

export { getPlanRevenue, formatPlanRevenueToDisplay, getReceiptRevenue, formatReceiptRevenueToDisplay, getActiveCustomers, formatActiveCustomersToDisplay, getChurnedCustomers, formatChurnedCustomersToDisplay, getCashierFlow, formatCashierFlowToDisplay };

export type { PlanRevenueType, PlanRevenueDisplayType, ReceiptRevenueType, ReceiptRevenueDisplayType, ActiveCustomerType, ActiveCustomerDisplayType, ChurnedCustomerType, ChurnedCustomerDisplayType, CashierFlowType, CashierFlowDisplayType };