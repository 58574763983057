import { Typography } from '@mui/material';

import useViewModel from './viewModel';

import { CheckboxFilter } from '../../components/Filters/CheckboxFilter';
import { DateRangeFilter } from '../../components/Filters/DateRangeFilter';
import { SearchFilter } from '../../components/Filters/SearchFilter';
import { AccountReceivableListTable } from '../../components/Tables/AccountReceivableListTable';
import { Totals } from '../../components/Totals';

import { ReactComponent as WalletLineIcon } from '../../images/icons/wallet-line.svg'

import './styles.scss'
import '../styles.scss'
import { EmptyView } from 'components/EmptyView';
import { CancelARModal } from 'components/Modals/AccountReceivable/Cancel';
import { DueDateARModal } from 'components/Modals/AccountReceivable/DueDate';
import { PaymentMethodARModal } from 'components/Modals/AccountReceivable/PaymentMethod';
import { SendEmailARModal } from 'components/Modals/AccountReceivable/SendEmail';
import { RetryARModal } from 'components/Modals/AccountReceivable/Retry';
import { AcknowledgeARModal } from 'components/Modals/AccountReceivable/Acknowledge';

function AccountsReceivables() {
  const {
    accountsReceivables,
    actionARIndex,
    formattedARs,
    formattedTotalValue,
    openCancel,
    openDueDate,
    openPaymentMethod,
    openSendEmail,
    openRetry,
    openAcknowledge,
    optionsRowsPerPage,
    page,
    paymentFilter,
    paymentOptions,
    rowsPerPage,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalARs,
    ARsLoading,
    applyDueDateFilter,
    applyPaymentFilter,
    applyStatusFilter,
    handleTableRowActions,
    onARsSearchChange,
    onChangeSort,
    onCloseCancel,
    onCloseDueDate,
    onClosePaymentMethod,
    onCloseSendEmail,
    onCloseRetry,
    onCloseAcknowledge,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel();

  return (
    <div className='listing-page accounts-receivables-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>Contas a receber</Typography>
        </div>
      </div>

      <div className='totals-container'>
        <Totals
          icon={
            <div className='total-icon paid-icon'>
              <WalletLineIcon />
            </div>
          }
          title='Total das cobranças'
          value={formattedTotalValue}
          isLoading={ARsLoading}
        />

        <Totals
          title='Itens'
          value={totalARs}
          isLoading={ARsLoading}
        />

      </div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por cliente'
            onSubmit={onARsSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status Pag.'
            id='status'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <DateRangeFilter
            id='dueDate'
            buttonLabel='Vencimento'
            onApplyFilter={applyDueDateFilter}
          />

          <CheckboxFilter
            buttonLabel='Método de Pag.'
            id='payment_method'
            initialSelected={paymentFilter.value}
            options={paymentOptions}
            onApplyFilter={applyPaymentFilter}
          />
        </div>
      </div>

      {!ARsLoading &&
        formattedARs?.length === 0 &&
        <EmptyView title={'Nenhum conta a receber encontrado para mostrar aqui'}></EmptyView>
      }

      {(ARsLoading || (formattedARs !== undefined && formattedARs.length > 0)) && <>
        <AccountReceivableListTable
          hasActions
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedARs ? formattedARs : []}
          rowsPerPage={rowsPerPage}
          total={totalARs}
          isLoading={ARsLoading}
          rowLink={'/accounts-receivables'}
          onClickOption={handleTableRowActions}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />

        <CancelARModal
          accountReceivableData={{
            id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].id : '',
            key: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].key : '',
          }}
          open={openCancel}
          onClose={onCloseCancel}
        />

        <DueDateARModal
          accountReceivableData={{
            id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].id : '',
            key: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].key : '',
            due_date: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].due_date : '',
          }}
          open={openDueDate}
          onClose={onCloseDueDate}
        />

        <PaymentMethodARModal
          accountReceivableData={{
            id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].id : '',
            key: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].key : '',
            customer_id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].customer_id : '',
            customer_payment_info_id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].customer_payment_info_id : '',
          }}
          open={openPaymentMethod}
          onClose={onClosePaymentMethod}
        />

        <SendEmailARModal
          accountReceivableData={{
            id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].id : '',
            key: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].key : '',
            customer_id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].customer_id : ''
          }}
          open={openSendEmail}
          onClose={onCloseSendEmail}
        />

        <RetryARModal
          accountReceivableData={{
            id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].id : '',
            key: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].key : '',
          }}
          open={openRetry}
          onClose={onCloseRetry}
        />

        <AcknowledgeARModal
          accountReceivableData={{
            id: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].id : '',
            key: accountsReceivables && actionARIndex !== undefined ? accountsReceivables[actionARIndex].key : '',
          }}
          open={openAcknowledge}
          onClose={onCloseAcknowledge}
        />
      </>
      }

    </div>
  );
}

export { AccountsReceivables };