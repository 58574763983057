import './styles.scss'

type ApiErrorViewPropType = {
  title: string
}

function ApiErrorView({ title }: ApiErrorViewPropType) {
  return (
    <div className='api-error-view'>
      <div className='title'>
          {title}
      </div>
    </div>
  );
}

export { ApiErrorView };