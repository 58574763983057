import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton } from '@mui/material';

import { TableCell } from '../Table/TableCell';
import { TableHead } from '../Table/TableHead';

import '../styles.scss'
import { ActionButtonTable } from '../Table/ActionButtonTable';

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rowLink: string,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  isLoading: boolean,
  onClickOption?: (index: number, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function SupplierListTable({
  hasActions,
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rowLink,
  rows,
  rowsPerPage,
  total,
  isLoading,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {

  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAsc={isAsc}
          isAnySelected={false}
          optionMenu={hasActions}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>
          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => (
            <TableRow
              className='table-row linked-table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell big' align='left'>{row.name}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.document}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} ellipsis className='table-cell big' align='left'>{row.emails}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.created_at}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>
                <span className={`label-status ${row.status === 'ATIVO' ? 'label-status-green' : 'label-status-red'}`}>
                  {row.status}
                </span>
              </TableCell>

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left'>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(index, action)
                    }}
                  />
                </TableCell>
              )}

            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { SupplierListTable };