import {useEffect, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import {useCreateCheckoutOrderActions} from "../../recoil/checkout/actions";
import {CompanyPayloadToDisplay, formatCheckoutCompanyPayloadToDisplay} from "./services";

export default function CheckoutViewModel() {
    const params = useParams();
    const [queryParams] = useSearchParams();

    const {checkoutOrderPayload} = useCreateCheckoutOrderActions()
    const [formattedCheckoutCompany, setFormattedCheckoutCompany] = useState<CompanyPayloadToDisplay>()

    const [page, setPage] = useState(0)

    useEffect(() => {
        setFormattedCheckoutCompany(formatCheckoutCompanyPayloadToDisplay(checkoutOrderPayload))
    }, [checkoutOrderPayload]);

    useEffect(() => {

    }, [queryParams]);

    const onBack = () => {
        setPage(page - 1)
    }

    const onNext = () => {
        setPage(page + 1)
    }

    return {
        params,
        queryParams,
        page,
        formattedCheckoutCompany,
        onBack,
        onNext
    };
}