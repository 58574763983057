import { CustomError, api } from 'utils/axios'

type CashierBankPayload = {
  name: string,
  type: string,
  bank_code?: string,
  bank_name?: string,
  routing_number?: string,
  routing_number_digit_verifier?: string,
  account_number?: string,
  account_number_digit_verifier?: string
}


type ParsedCashierBankPayload = {
  name: string,
  type: string,
  bank_code?: string,
  bank_name?: string,
  routing_number?: string,
  routing_number_digit_verifier?: string,
  account_number?: string,
  account_number_digit_verifier?: string
}

const bundlePayload = (payload: CashierBankPayload): ParsedCashierBankPayload => (
  {
    name: payload.name,
    type: payload.type,
    bank_code: payload.bank_code,
    bank_name: payload.bank_name,
    routing_number: payload.routing_number,
    routing_number_digit_verifier: payload.routing_number_digit_verifier,
    account_number: payload.account_number,
    account_number_digit_verifier: payload.account_number_digit_verifier
  }
)

const postCashierBanks = async (payload: CashierBankPayload): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/cashiers`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postCashierBanks }

export type { ParsedCashierBankPayload, CashierBankPayload }