import './styles.scss'
import useViewModel from './viewModel';
import {Button, LinearProgress, Typography} from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
import { DisplayInfoCopy } from 'components/Info/DisplayInfoCopy'

type FinalStepPropTypes = {
    onBack: () => void
}

function FinalStep({
    onBack
}: FinalStepPropTypes) {
    const {
        order,
        loadingProgress,
        orderLoading,
        orderLoadingError,
        formattedOrder
    } = useViewModel({onBack})

    return <>

        {(!order || orderLoading) && <>
            <div className='section-title-final'>
                <Typography variant='h3' fontWeight={700} color='primary'>Enviando pedido</Typography>
            </div>

            <div className='section-progress'>
                {loadingProgress < 25 && <span className='row'>Cadastrando seus dados...</span>}
                {loadingProgress >= 25 && loadingProgress < 50 && <span className='row'>Gerando nova venda...</span>}
                {loadingProgress >= 50 && loadingProgress < 75 && <span className='row'>Processando método de pagamento...</span>}
                {loadingProgress >= 75 && <span className='row'>Finalizando...</span>}
                <LinearProgress className='progress-bar' variant="determinate" value={loadingProgress} />
            </div>
        </>}

        {order && !orderLoading && !orderLoadingError && <>
            <div className='section-title-final'>
                <Typography variant='h3' fontWeight={700} color='primary'>Pedido enviado</Typography>
            </div>

            {formattedOrder && formattedOrder.boleto_url &&
                <div className='payment-description-section'>
                    <div className='row'>A venda foi concluída com sucesso.</div>
                    <div>Acesse o boleto para efetuar o pagamento</div>
                    <Button className='button' variant='contained' target='_blank' href={formattedOrder?.boleto_url ?? ''}>VER BOLETO</Button>
                </div>
            }

            {formattedOrder && formattedOrder.credit_card_description &&
                <div className='payment-description-section'>
                    <div>A venda foi concluída com sucesso</div>
                    <div>A cobrança será feita no cartão {formattedOrder?.credit_card_description}</div>
                </div>
            }

            {formattedOrder && formattedOrder.pix_qr_code &&
                <div className='payment-description-section'>
                    <div>A venda foi concluída com sucesso</div>
                    <div>Efetue o pagamento do PIX pelo QR code abaixo</div>
                    <div className='qr-code-container'>
                        <QRCode
                            className='qr-code'
                            value={formattedOrder?.pix_qr_code ? formattedOrder.pix_qr_code : ''}
                        />
                        <div>
                            <DisplayInfoCopy title={''} text={formattedOrder?.pix_qr_code ?? ''} />
                        </div>
                    </div>
                </div>
            }
        </>}

        {!orderLoading && orderLoadingError && <>
            <div className='section-title'>
                <Typography variant='h3' fontWeight={700} color='primary'>Falha ao efetuar a venda</Typography>
            </div>

            <div className='error-description-section'>
                <div>Houve uma falha ao efetuar a venda. Tente novamente em alguns instantes.</div>
                <div>Caso o problema persista, entre em contato com a gente.</div>
            </div>
        </>}
    </>
}

export {FinalStep}