import { useCallback, useEffect, useState } from 'react';

import { DetailsDisplayType, DetailsType, formatDetailsToDisplay, getDetails } from './services';

import { useTitleName } from 'recoil/viewTitleName/actions';
import { notAuthenticatedRedirect } from 'utils/auth';
import { ApiError } from 'utils/CustomError';

type ViewModelProps = {
  saleId: string
}

export default function ProductRecurringGeneralViewModel({ saleId }: ViewModelProps) {
  const [details, setDetails] = useState<DetailsType>();
  const [formattedDetails, setFormattedDetails] = useState<DetailsDisplayType>();
  const [detailsLoading, setDetailsLoading] = useState(true)

  const [detailsLoadingError, setDetailsLoadingError] = useState(false)

  const { titleName, updateTitleName } = useTitleName();

  const memoizedUpdateTitleName = useCallback(
    (name: string, loading = false) => {
      updateTitleName(name, loading);
    },
    [updateTitleName]
  );


  const onGetDetailsHandleError = (errors: ApiError) => {

    if (errors[0]?.status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0]?.status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setDetailsLoadingError(true)
      setDetails(undefined);
      setFormattedDetails(undefined)
    } else {
      setDetailsLoadingError(true)
      setDetails(undefined);
      setFormattedDetails(undefined)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setDetailsLoading(true);

      const response = await getDetails(saleId, abortController.signal);

      if (response.errors) {
        onGetDetailsHandleError(response.errors)
      } else {
        setDetails(response)
        setFormattedDetails(formatDetailsToDisplay(response))
      }

      setDetailsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [saleId])

  useEffect(() => {
    memoizedUpdateTitleName(details?.key || '', detailsLoading);
  }, [details?.key, detailsLoading, memoizedUpdateTitleName]);

  return {
    details,
    detailsLoading,
    detailsLoadingError,
    formattedDetails,
    titleName
  }
}