import { CustomError } from 'utils/CustomError';
import { api } from 'utils/axios';

const resetPassword = async (password: string, token: string | null) => {
  try {
    const response = await api.post('/api-cashone/password/reset', {
      data: {
        type: 'user',
        attributes: { password }
      }
    },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

    return response.data;
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { resetPassword }