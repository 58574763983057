import { Form } from 'react-final-form';

import { Button } from '@mui/material';

import useViewModel from './viewModel'

import { Flexbox } from 'components/Flexbox';
import { AutocompleteAdapter } from 'components/FormComponents/Fields/AutocompleteAdapter';
import { ApiErrorView } from 'components/ApiErrorView';
import { CreateClientModal } from 'components/Modals/Client/Create';
import { OptionsButton } from 'components/OptionsButton';

import './styles.scss'

type ChooseStepPropTypes = {
  type: string,
  onBack: () => void
  onNext: () => void,
}

function ChooseStep({
  type,
  onBack,
  onNext
}: ChooseStepPropTypes) {
  const {
    clients,
    clientsLoading,
    clientsLoadingError,
    clientType,
    openClientModal,
    plans,
    plansLoading,
    plansLoadingError,
    products,
    productsLoading,
    productsLoadingError,
    formattedSalePayload,
    searchClients,
    searchProducts,
    searchPlans,
    selectedProductId,
    formValidate,
    onClientSearchChange,
    onCloseClientModal,
    onNewClient,
    onOpenClientModal,
    onPlanSearchChange,
    onProductChange,
    onProductSearchChange,
    onSubmit,
  } = useViewModel({ type, onNext })

  return (
    <Form
      onSubmit={onSubmit}
      validate={formValidate}
      initialValues={formattedSalePayload}
      render={({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit}>

          <div className='modal-body choose-step'>
            {(clientsLoadingError || plansLoadingError || productsLoadingError) &&
              <ApiErrorView title='Uh-oh! Algo inesperado aconteceu. Tente recarregar a página.' />
            }

            {!(clientsLoadingError || plansLoadingError || productsLoadingError) && <>
              <Flexbox size={12}>
                <Flexbox size={6}>
                  <AutocompleteAdapter
                    label='Produto'
                    name='product_option'
                    value={searchProducts}
                    onChange={onProductSearchChange}
                    options={products ? products : []}
                    searchLoading={productsLoading}
                    onOptionChange={(value) => {
                      onProductChange(value, form)
                    }}
                  />
                </Flexbox>
              </Flexbox>

              {type === 'RECURRING' &&
                <Flexbox size={6}>
                  <AutocompleteAdapter
                    label='Plano'
                    name='plan_option'
                    value={searchPlans}
                    onChange={onPlanSearchChange}
                    options={plans ? plans : []}
                    disabled={!selectedProductId}
                    searchLoading={plansLoading}
                  />
                </Flexbox>
              }

              <div className='field-button'>
                <Flexbox size={6}>
                  <AutocompleteAdapter
                    label='Cliente'
                    name='client_option'
                    value={searchClients}
                    onChange={onClientSearchChange}
                    options={clients ? clients : []}
                    searchLoading={clientsLoading}
                  />
                </Flexbox>

                <OptionsButton
                  color='secondary'
                  label='Criar cliente'
                  options={[
                    { label: 'Pessoa física', id: 'brlPeopleRegime' },
                    { label: 'Pessoa jurídica', id: 'brlCompanyRegime' },
                    { label: 'Estrangeiro', id: 'brlInternationalCustomerRegime' },
                  ]}
                  variant='outlined'
                  onClickOption={onOpenClientModal}
                />

                <CreateClientModal
                  onClose={onCloseClientModal}
                  open={openClientModal}
                  type={clientType}
                  onSubmit={(response) => { onNewClient(response, form) }}
                />
              </div>

            </>}
          </div>

          <div className='modal-footer'>
            <Button variant='outlined' onClick={onBack}>Cancelar</Button>
            <Button variant='contained' type='submit'>Próximo</Button>
          </div>
        </form>
      )}
    />
  )
}

export { ChooseStep }