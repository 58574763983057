import { useState } from 'react'

import { deactivateClient } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

type ClientType = {
  id: string,
}

type ViewModelPropTypes = {
  clientData: ClientType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function DeactivateClientViewModel({ clientData, onClose, onSubmit }: ViewModelPropTypes) {
  const [clientLoading, serClientLoading] = useState(false)
  const [clientError, setClientError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onActivateHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientError(true)
      setOpenSnackbar(true)
    } else {
      setClientError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async () => {
    serClientLoading(true)

    const response = await deactivateClient(clientData.id)

    if (response.errors)
      onActivateHandleError(response.errors)

    else {
      setClientError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    serClientLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    clientError,
    clientLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { ClientType }