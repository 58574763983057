import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { featureFlagService, FormValuesTypes, loginService, ServiceLoginTypes } from "./services";

import { FEATURE_FLAG_LIST } from "../../utils/constants/flagsmith";
import { auth, setAuthOnStorage } from '../../utils/auth';
import { useFeatureFlagProvider } from "../../utils/featureFlag";
import { required } from "../../utils/FieldValidations";
import { ApiError } from "utils/CustomError";

type LoginDataType = {
  email: string,
  password: string,
  hash: string,
  url: string,
  accept_term_of_use?: boolean,
}

export default function LoginViewModel() {
  const [isLoading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false)
  const [invalidLogin, setInvalidLogin] = useState(false)

  const [loginData, setLoginData] = useState<LoginDataType>()

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openTermsOfUse, setOpenTermsOfuse] = useState(false)

  const { saveFeatureFlags } = useFeatureFlagProvider();
  const navigate = useNavigate();

  useEffect(() => {
    const { token } = auth;

    if (token)
      return navigate('/');
  }, [navigate]);


  const onPostLoginHandleError = (values: FormValuesTypes, errors: ApiError) => {
    if (errors[0].status === 400 && errors[0].type === 'UserException') {
      if (errors[0].detail?.hash) {
        setOpenTermsOfuse(true)
        setLoginData({
          ...values,
          hash: errors[0].detail.hash,
          url: errors[0].detail.url,
        })
      }
      else {
        setInvalidLogin(true);
        setOpenSnackbar(true);
      }
    }
    else {
      setLoadingError(true);
      setOpenSnackbar(true);
    }
  }

  const onSubmit = async (values: FormValuesTypes) => {
    setLoading(true);

    const response = await loginService(values);

    if (response.errors) {
      onPostLoginHandleError(values, response.errors)
    }

    else {
      const loginResponse = response as ServiceLoginTypes
      setAuthOnStorage({ user: loginResponse.user, token: loginResponse.token });

      // Fallback feature flags
      saveFeatureFlags(FEATURE_FLAG_LIST)

      // Connect to Flagsmith to get the feature flags
      const token = process.env.REACT_APP_FLAGSMITH_TOKEN
      if (token) {
        const updatedFeatureFlags = await featureFlagService({
          token,
          user_id: loginResponse.user.id,
          company_id: loginResponse.user.company_id
        });

        let parsedUpdatedFeatureFlags = []
        for (const key in updatedFeatureFlags) {
          parsedUpdatedFeatureFlags.push({ name: key, value: updatedFeatureFlags[key].enabled })
        }

        saveFeatureFlags(parsedUpdatedFeatureFlags)
      }

      navigate('/');
    }

    setLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const onCloseTermsOfUse = () => {
    setOpenTermsOfuse(false);
  }

  const onAcceptTermsOfUse = async (accept_term_of_use: boolean) => {
    setOpenTermsOfuse(false)
    if (accept_term_of_use && loginData)
      await onSubmit({ ...loginData, accept_term_of_use })
  }

  return {
    invalidLogin,
    isLoading,
    loadingError,
    loginData,
    openSnackbar,
    openTermsOfUse,
    onAcceptTermsOfUse,
    onCloseSnackbar,
    onCloseTermsOfUse,
    onSubmit,
    required,
  };
}
