import './styles.scss'

type EmptyViewPropType = {
  title: string
}

function EmptyView({title}: EmptyViewPropType) {
  return (
    <div className='empty-view'>
      <div className='title'>
          {title}
      </div>
    </div>
  );
}

export { EmptyView };