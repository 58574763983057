import { useState } from 'react'

import { postCancel } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

type InvoiceGroupsType = {
  id: string,
  key: string
}

type ViewModelPropTypes = {
  invoiceGroupData: InvoiceGroupsType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CancelInvoiceViewModel({ invoiceGroupData, onClose, onSubmit }: ViewModelPropTypes) {
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceError, setInvoiceError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onPostCancelHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setInvoiceError(true)
      setOpenSnackbar(true)
    } else {
      setInvoiceError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async () => {
    setInvoiceLoading(true)

    const response = await postCancel(invoiceGroupData.id)

    if (response.errors)
      onPostCancelHandleError(response.errors)

    else {
      setInvoiceError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setInvoiceLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    invoiceError,
    invoiceLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { InvoiceGroupsType }