import {CheckoutOrderPayloadType} from "../../recoil/checkout/states";

type CompanyPayloadToDisplay = {
    company_logo: string,
    name: string,
    email: string,
    registration: string,
}

const formatCheckoutCompanyPayloadToDisplay = (values: CheckoutOrderPayloadType) => {
    return {
        company_logo: values.company?.company_logo ?? '',
        name: values.company?.name ?? '',
        email: values.company?.email ?? '',
        registration: values.company?.registration ?? ''
    }
}

export {
    formatCheckoutCompanyPayloadToDisplay
}

export type {
    CompanyPayloadToDisplay
}