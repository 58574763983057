import dayjs from 'dayjs';
import { SalePayloadType } from 'recoil/createSale/states';

import { CustomError, api } from 'utils/axios';

const parsePayloadToRecoil = (response: { data: any }): SalePayloadType => {
  const parsedInstallments = []

  if (response.data.attributes.installments) {
    for (const installment of response.data.attributes.installments) {
      parsedInstallments.push({
        total_value: installment.value,
        date: dayjs(installment.due_date.date)
      })
    }
  }

  return {
    sale_id: response.data.id,
    sale_key: response.data.attributes.key,
    description: response.data.attributes.description,
    customer_id: response.data.attributes.customer_id,
    customer_name: response.data.attributes.customer_name,
    plan_id: response.data.attributes.plan_id,
    plan_name: response.data.attributes.plan_name,
    product_id: response.data.attributes.product_id,
    product_name: response.data.attributes.product_name,
    start_date: response.data.attributes.start_date ? dayjs(response.data.attributes.start_date.date) : undefined,
    initial_term: response.data.attributes.initial_term,
    auto_renewal: response.data.attributes.auto_renewal,
    charge_value: response.data.attributes.value,
    product_value: response.data.attributes.value,
    plan_value: response.data.attributes.value,
    freq: response.data.attributes.frequency,
    has_installments: parsedInstallments.length ? true : false,
    installments: parsedInstallments,
    payment_terms: response.data.attributes.payment_terms,
    payment_method_id: response.data.attributes.payment_method.payment_method_id,
    payment_method_type: response.data.attributes.payment_method.payment_method_type,
    taxes: {
      withhold: response.data.attributes.withhold ? true : false,
      rate: response.data.attributes.iss_rate,
      withhold_in_receipt: response.data.attributes.withhold_in_receipt ? true : false,
      rate_in_receipt: response.data.attributes.iss_rate_in_receipt,
    },
    create_receipt: response.data.attributes.create_receipt,
    auto_receipt_run: response.data.attributes.auto_receipt_run,
  }
}

const getSale = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/sales/${id}`, { signal });

    return response.data;
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { parsePayloadToRecoil, getSale }