import { formatMoney } from '../../utils/moneyFormatt'

const formatToDisplay = (rawInstallments: any): string[] => {
  if (!rawInstallments) return []

  return rawInstallments.map((installment: any) => {
    return formatMoney(installment.total_value) + ' com vencimento em ' + installment.date.format('DD/MM/YYYY')
  })
}

export { formatToDisplay }