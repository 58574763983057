import dayjs from 'dayjs';
import { CustomError, api } from '../../../../utils/axios';

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean,
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type PaginationType = {
  limit: number,
  page: number
}

type ProductsType = {
  value: string,
  label: string,
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type SaleType = {
  id: string,
  customer_name: string,
  key: string,
  period_end: string,
  period_start: string,
  product_name: string,
  status: string,
  tags: Array<string>,
}

type SaleDisplayType = {
  id: string,
  customer_name: string,
  key: string,
  period_end: string,
  period_start: string,
  product_name: string,
  status: string,
  tags: string,
}

type TagsType = {
  value: string,
  label: string,
}

const parseSales = async (response: { data: any, meta: any }) => {
  const sales = response.data.map((data: any) => {
    return {
      id: data.id,
      customer_name: data.attributes.customer_name,
      key: data.attributes.key,
      period_end: data.attributes.period_end.date,
      period_start: data.attributes.period_start.date,
      product_name: data.attributes.product_name,
      status: data.attributes.status,
      tags: data.attributes.tags ? data.attributes.tags : [],
    }
  });

  return {
    sales,
    pagination: {
      totalSales: response.meta.pagination.total
    }
  }
}

const parseProducts = (response: { data: Array<any> }) => {
  return response.data.map((data) => ({
    value: data.id,
    label: data.attributes.name
  }))
}

const parseTags = (response: Array<any>) => {
  return response.map((data) => ({
    value: data.attributes.name,
    label: data.attributes.name
  }))
}

const getSales = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (filter.value.length)
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/sales-spot', {
      params,
      signal
    });

    return parseSales(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const getProducts = async (search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {
    'filter[type]': 'eq:SPOT'
  };

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/products/search', {
      params,
      signal
    });

    return parseProducts(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const getTags = async (search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/tags', {
      params,
      signal
    })

    return parseTags(response.data.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatSalesToDisplay = (sales: Array<SaleType>): Array<SaleDisplayType> => {

  return sales.map((sale: SaleType) => {
    let actions: ActionType[];

    switch (sale.status) {
      case 'ACTIVE':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'applyCoupon', label: 'Aplicar cupom', disabled: false },
          { id: 'cancel', label: 'Cancelar', disabled: false },
        ]
        break;

      default:
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'applyCoupon', label: 'Aplicar cupom', disabled: true },
          { id: 'cancel', label: 'Cancelar', disabled: true },
        ]
        break;
    }

    return {
      id: sale.id,
      customer_name: sale.customer_name,
      key: sale.key,
      period_start: dayjs(sale.period_start).format('DD/MM/YYYY'),
      period_end: dayjs(sale.period_end).format('DD/MM/YYYY'),
      status: sale.status === 'ACTIVE' ? 'ATIVO' :
        sale.status === 'TERMINATED' || sale.status === 'STANDBY_CANCEL' ? 'CANCELADO' :
          sale.status === 'EXPIRED' || sale.status === 'STANDBY_EXPIRE' ? 'EXPIRADO' : '-',
      product_name: sale.product_name,
      tags: sale.tags ? sale.tags.join(', ') : '',
      actions,
    }
  })

}

export { getProducts, getSales, getTags, formatSalesToDisplay };

export type { FilterType, PaginationType, ProductsType, SortType, SaleType, SaleDisplayType, TagsType };