import { CustomError, api } from 'utils/axios';

type DetailsType = {
  id: string,
  name: string,
  status: string,
  internal_code: string,
  description: string,
  sales: number,
  value: number,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: number,
  irrf: number,
  pis: number,
  cofins: number,
  csll: number,
  checkout_product_hash?: string
  created_at: string,
  updated_at: string,
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    name: response.data.attributes.name,
    status: response.data.attributes.status,
    internal_code: response.data.attributes.internal_code,
    description: response.data.attributes.description,
    sales: response.data.attributes.sales,
    value: response.data.attributes.value.value,
    cnae_code: response.data.attributes.cnae_code,
    service_code: response.data.attributes.service_code,
    custom_service_code: response.data.attributes.custom_service_code,
    iss: response.data.attributes.iss,
    irrf: response.data.attributes.irrf,
    pis: response.data.attributes.pis,
    cofins: response.data.attributes.cofins,
    csll: response.data.attributes.csll,
    checkout_product_hash: response.data.attributes.checkout_product_hash,
    created_at: response.data.attributes.created_at.date,
    updated_at: response.data.attributes.updated_at.date,
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/products-spot/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getDetails }

export type { DetailsType }