import { Button, CircularProgress } from '@mui/material';
import { Form } from 'react-final-form';

import { ApiErrorView } from 'components/ApiErrorView';
import { Flexbox } from 'components/Flexbox';
import { RadioFieldAdapter } from 'components/FormComponents/Fields/RadioFieldAdapter';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';

import useViewModel from './viewModel'

type PaymentStepPropTypes = {
  onBack: () => void
  onNext: () => void,
}

function PaymentStep({
  onBack,
  onNext
}: PaymentStepPropTypes) {
  const {
    formattedPaymentMethods,
    loadingError,
    paymentLoading,
    paymentMethodTypeOptions,
    formattedSalePayload,
    formValidate,
    onPaymentMethodTypeChange,
    onSubmit
  } = useViewModel({ onNext })

  return (
    <Form
      onSubmit={onSubmit}
      validate={formValidate}
      initialValues={formattedSalePayload}
      render={({ form, values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className='modal-body'>

            {loadingError &&
              <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os métodos de pagamento.' />
            }

            {!loadingError &&
              <>
                <Flexbox size={6}>
                  <RadioFieldAdapter
                    label='Método de pagamento'
                    name='payment_method_type'
                    options={paymentMethodTypeOptions}
                    disabled={paymentLoading}
                    onChange={(e) => { onPaymentMethodTypeChange(e.target.value, form) }}
                  />
                </Flexbox>

                <Flexbox size={12}>
                  {values.payment_method_type === 'CREDIT_CARD' &&
                    <SelectFieldAdapter
                      label='Cartão de crédito'
                      name='payment_method_id'
                      options={formattedPaymentMethods?.CREDIT_CARD ? formattedPaymentMethods.CREDIT_CARD : []}
                    />
                  }

                  {values.payment_method_type === 'BANK_DEPOSIT' &&
                    <SelectFieldAdapter
                      label='Depósito Bancário'
                      name='payment_method_id'
                      options={formattedPaymentMethods?.BANK_DEPOSIT ? formattedPaymentMethods.BANK_DEPOSIT : []}
                    />
                  }
                </Flexbox>
              </>
            }


          </div>
          <div className='modal-footer'>
            {paymentLoading && <CircularProgress className='loading' color='primary' size={20} />}
            <Button variant='outlined' onClick={onBack} disabled={paymentLoading}>Voltar</Button>
            <Button variant='contained' type='submit' disabled={paymentLoading}>Próximo</Button>
          </div>
        </form>
      )}
    />
  )
}

export { PaymentStep }