import { atom } from 'recoil';

type CheckoutOrderPayloadType = {
    company?: {
        company_logo?: string,
        name?: string,
        email?: string,
        registration?: string,
        privacy_policy?: string,
        term_of_use?: string,
    },
    product?: {
        hash?: string,
        plan_name?: string,
        product_name?: string,
        frequency?: number,
        first_payment_term_type?: string,
        first_payment_term_value?: number,
        payment_term_type?: string,
        payment_term_value?: number
    },
    charges?: {
        uom?: string,
        type?: string,
        value?: number,
        recurrence?: number,
    }[]
    coupon?: {
        hash?: string,
        name?: string,
        type?: string,
        code?: string,
        value?: number,
    },
    customer?: {
        hash?: string,
        type?: string,
        registration?: string,
        email?: string,
        phone?: string,
        name?: string,
        first_name?: string,
        last_name?: string,
        razao_social?: string,
        optante_simples_nacional?: boolean,
        address?: {
            postal_code?: string,
            city?: {
                value: number,
                label: string
            },
            state?: string,
            neighbourhood?: string,
            street?: string,
            number?: string,
            complement?: string,
        }
    },
    payment?: {
        payment_method?: string,
        holder_name?: string,
        digits?: string,
        brand?: string,
        expiration_month?: string,
        expiration_year?: string,
        security_code?: string,
    },
    sale?: {
        hash?: string,
        subtotal?: number,
        setup?: number,
        discount?: number,
        total?: number
    }
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string
    captcha?: string,
    accepted_privacy_policy?: boolean
    accepted_term_of_use?: boolean
}

const checkoutOrderPayloadAtom = atom<CheckoutOrderPayloadType>({
    key: 'checkoutOrder',
    default: {
        company: {},
        product: {},
        charges: [],
        payment: {},
        customer: {},
        coupon: {}
    }
})

export { checkoutOrderPayloadAtom };

export type { CheckoutOrderPayloadType }