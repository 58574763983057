import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'
import { Flexbox } from 'components/Flexbox';
import { MultipleEmailFieldAdapter } from 'components/FormComponents/Fields/MultipleEmailFieldAdapter';

import useViewModel, { InvoiceGroupsType } from './viewModel'

import '../../styles.scss'

type EmailInvoiceModalPropTypes = {
  invoiceGroupData: InvoiceGroupsType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function EmailInvoiceGroupModal({ invoiceGroupData, open, onClose, onSubmit }: EmailInvoiceModalPropTypes) {
  const {
    invoiceError,
    clientLoading,
    invoiceLoading,
    formError,
    openSnackbar,
    client,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ invoiceGroupData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='email-invoices-group-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            initialValues={{ emails: client?.emails }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Enviar emails
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>

                  <Flexbox size={12}>
                    <MultipleEmailFieldAdapter
                      label={'Emails'}
                      name='emails'
                      loading={clientLoading || invoiceLoading}
                    />
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  {invoiceLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' disabled={invoiceLoading} onClick={onClose}>Cancelar</Button>
                  <Button type='submit' disabled={invoiceLoading} variant='contained'>Enviar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(invoiceError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!invoiceError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Emails enviados!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { EmailInvoiceGroupModal }