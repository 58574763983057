import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTitleName } from 'recoil/viewTitleName/actions';

export default function ViewClientViewModel() {
  const [tabSelected, setTabSelected] = useState<number>(0);

  const params = useParams();

  const { loadingTitleName, titleName } = useTitleName()

  const onTabChange = (value: number) => {
    setTabSelected(value);
  }

  return { params, tabSelected, loadingTitleName, titleName, onTabChange };
}