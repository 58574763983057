import { useState } from "react";

const localStorageKey = 'featureFlags';

const loadFeatureFlagsFromStorage = () => {
  const featureFlagStorage = localStorage.getItem(localStorageKey);

  if (featureFlagStorage) {
    const featureFlagList = JSON.parse(featureFlagStorage);
    return featureFlagList;
  }

  return [];
}

function useFeatureFlagProvider() {
  const [featureFlags, setFeatureFlags] = useState(loadFeatureFlagsFromStorage());

  const getFeatureFlag = (featureFlag: string) => {
    if (featureFlags.length > 0) {
      return featureFlags.find((element: { name: string; value: any; }) => element.name === featureFlag && element.value) ? true : false;
    }

    return false;
  }

  const saveFeatureFlags = (values: Array<{ name: string, value: any }>) => {
    localStorage.setItem(localStorageKey, JSON.stringify(values));
    setFeatureFlags(values);
  }

  return { getFeatureFlag, saveFeatureFlags };
}

export { useFeatureFlagProvider };