import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton } from '@mui/material';

import { TableHead } from '../Table/TableHead';
import { TableCell } from '../Table/TableCell';

import { ReactComponent as FileIcon } from '../../../images/icons/file.svg'

import './styles.scss'
import '../styles.scss'
import { ActionButtonTable } from '../Table/ActionButtonTable';

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rowLink?: string,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  isLoading: boolean,
  onClickOption?: (nf: any, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function InvoiceReceiptListTable({
  hasActions,
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rowLink,
  rows,
  rowsPerPage,
  total,
  isLoading,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {
  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAsc={isAsc}
          isAnySelected={false}
          optionMenu={hasActions}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>

          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => (
            <TableRow
              className={`table-row ${rowLink ? 'linked-table-row' : ''}`}
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {
                headCells.map((cell, index) => {
                  if (cell.id === 'status') {
                    let statusClass = ''
                    if (row[cell.id] === 'RASCUNHO') {
                      statusClass = 'label-status-blue'
                    } else if (row[cell.id] === 'AUTORIZADO') {
                      statusClass = 'label-status-green'
                    } else if (row[cell.id] === 'CANCELADO') {
                      statusClass = 'label-status-red'
                    } else if (row[cell.id] === 'CANCELANDO') {
                      statusClass = 'label-status-yellow'
                    } else if (row[cell.id] === 'ERRO') {
                      statusClass = 'label-status-red'
                    } else if (row[cell.id] === 'PENDENTE') {
                      statusClass = 'label-status-yellow'
                    } else if (row[cell.id] === 'PROCESSANDO') {
                      statusClass = 'label-status-blue'
                    }

                    return <TableCell className='table-cell' key={index} align='left' to={rowLink ? `${rowLink}/${row.id}` : undefined}>
                      <span className={`label-status ${statusClass}`}>
                        {row[cell.id]}
                      </span>
                    </TableCell>
                  } else if (cell.id === 'receipt_url') {
                    return (
                      <TableCell className='table-cell' key={index} align='left'>
                        <a href={row[cell.id]} target='blank' className={`icon-link ${row[cell.id] ? 'red-icon' : 'disabled-icon'}`}>
                          <FileIcon />
                        </a>
                      </TableCell>
                    )
                  } else {
                    return <TableCell className='table-cell' key={index} align='left' to={rowLink ? `${rowLink}/${row.id}` : undefined}>
                      {row[cell.id]}
                    </TableCell>
                  }
                })
              }

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left' to={`${rowLink}/${row.id}`}>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(row, action)
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { InvoiceReceiptListTable };