import { Box, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import { InvoiceGroupGeneralView } from './pages/General';

import useViewModel from './viewModel'

import './styles.scss'

function InvoiceGroupView() {
  const {
    loadingTitleName,
    params,
    tabSelected,
    titleName,
  } = useViewModel();

  return (
    <div className='invoice-group-view'>

      <div className='view-header'>
        <div className='view-title'>

          {!loadingTitleName ? (

            <Typography variant='h3' fontWeight={700}>
              Fatura - {titleName}
            </Typography>

          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
        >

          <Tab
            label='Visão Geral'
            id='general'
            aria-controls='tabpanel-general'
          />

        </Tabs>
      </Box>

      <div
        role='tabpanel'
        hidden={tabSelected !== 0}
        id={'tabpanel-general'}
        aria-labelledby={'general'}
      >
        <InvoiceGroupGeneralView
          invoiceGroupId={params.id ? params.id : ''}
        />
      </div>

      <div
        role='tabpanel'
        hidden={tabSelected !== 1}
        id={'tabpanel-events'}
        aria-labelledby={'events'}
      />

    </div>
  );
}

export { InvoiceGroupView }