import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { getBanks, SortType, BanksType, BanksToDisplayType, formatBanksToDisplay } from './services';
import { ApiError } from 'utils/CustomError';

export default function CashierBanksViewModel() {
  const [banks, setBanks] = useState<Array<BanksType>>();
  const [formattedBanks, setFormattedBanks] = useState<Array<BanksToDisplayType>>();

  const [bankLoading, setBankLoading] = useState(true);
  const [bankLoadingError, setBankLoadingError] = useState(false);

  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const [actionIndex, setActionIndex] = useState<number>()

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalBanks, setTotalBanks] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'name', label: 'Nome/Banco' },
    { id: 'routing_number', label: 'Info bancária' },
    { id: 'status', label: 'Status' },
  ];


  const onGetBanksHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setBankLoadingError(true)

      setBanks([]);
      setFormattedBanks([]);
      setTotalBanks(0);
    } else {
      setBankLoadingError(true)

      setBanks([]);
      setFormattedBanks([]);
      setTotalBanks(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setBankLoading(true);
      setBankLoadingError(false)

      const response = await getBanks(sort, { page, limit: rowsPerPage }, abortController.signal);
      if (response.errors) {
        onGetBanksHandleError(response.errors)
      } else {
        setBankLoadingError(false)
        setBanks(response.banks as Array<BanksType>);
        setFormattedBanks(formatBanksToDisplay(response.banks))

        setTotalBanks(response.pagination.totalBanks);
      }

      setBankLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [page, rowsPerPage, sort])


  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseEditModal = () => {
    setOpenEdit(false)
  }

  const onCloseCreateModal = () => {
    setOpenCreate(false)
  }

  const onOpenCreateModal = () => {
    setOpenCreate(true)
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'edit':
        setActionIndex(index);
        setOpenEdit(true)
        break;
    }
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    actionIndex,
    bankLoading,
    bankLoadingError,
    banks,
    formattedBanks,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    totalBanks,
    tableHeadCells,
    openCreate,
    openEdit,
    handleTableRowActions,
    onChangeSort,
    onCloseEditModal,
    onPageChange,
    onRowsPerPageChange,
    onOpenCreateModal,
    onCloseCreateModal
  };
}