import { useEffect, useState } from 'react'

import { postPaymentMethod, PaymentMethodsType, PaymentMethodsToDisplayType, getPaymentMethods, formatPaymentMethodsToDisplay } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

type AccountsReceivablesType = {
  id: string,
  key: string,
  customer_id: string,
  customer_payment_info_id?: string,
}

type ViewModelPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function PaymentMethodARViewModel({ accountReceivableData, open, onClose, onSubmit }: ViewModelPropTypes) {
  const [initialPayment, setInitialPayment] = useState<string>()

  const [ARLoading, setARLoading] = useState(false)
  const [ARError, setARError] = useState(false)

  const [paymentOptions, setPaymentOptions] = useState<PaymentMethodsType>()
  const [formattedPaymentOptions, setFormattedPaymentOptions] = useState<PaymentMethodsToDisplayType>()

  const [getError, setGetError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);


  const onGetPaymentMethodHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setGetError(true)
      setPaymentOptions([])
      setFormattedPaymentOptions([])
    } else {
      setGetError(true)
      setPaymentOptions([])
      setFormattedPaymentOptions([])
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setARLoading(true)

      if (accountReceivableData.customer_id && open) {
        const response = await getPaymentMethods(accountReceivableData.customer_id, abortController.signal)

        if (response.errors) {
          onGetPaymentMethodHandleError(response.errors)
        } else {
          setPaymentOptions(response)
          setFormattedPaymentOptions(formatPaymentMethodsToDisplay(response))

          const option = response.find((pm: any) => pm.customer_payment_info_id === accountReceivableData.customer_payment_info_id)

          if (option)
            setInitialPayment(option.id)
        }
      }

      setARLoading(false)
    })()

    return () => {
      abortController.abort()
    }
  }, [accountReceivableData, open])


  const onPostPaymentMethodHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setARError(true)
      setOpenSnackbar(true)
    } else {
      setARError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setARLoading(true)

    const response = await postPaymentMethod(accountReceivableData.id, values, paymentOptions ? paymentOptions : [])

    if (response.errors)
      onPostPaymentMethodHandleError(response.errors)

    else {
      setARError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setARLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }


  const formValidate = (values: Record<string, any>) => {
    const errors: any = {}

    if (!values.hash)
      errors.hash = 'Obrigatório'

    return errors;
  }

  return {
    ARError,
    ARLoading,
    formattedPaymentOptions,
    getError,
    initialPayment,
    openSnackbar,
    paymentOptions,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { AccountsReceivablesType }