import { useEffect, useState } from 'react'

import { SelectChangeEvent } from '@mui/material'

import { CouponPayload, formatCouponToDisplay, patchCoupon } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { FormApi, ValidationErrors } from 'final-form'

type CouponDataType = {
  id: string,
  name: string,
  code: string,
  type: string,
  value: number
}

type ViewModelPropTypes = {
  couponData: CouponDataType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EditCouponModalViewModel({ couponData, onClose, onSubmit }: ViewModelPropTypes) {

  const [editLoading, setEditLoading] = useState(false)
  const [editError, setEditError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const typeList = [
    { value: 'PERCENTAGE', label: 'Porcentagem' },
    { value: 'FIXED', label: 'Valor fixo' }
  ]
  const [valueType, setValueType] = useState(couponData.type)

  const formattedCouponData = formatCouponToDisplay(couponData);


  useEffect(() => {
    setValueType(couponData.type)
  }, [couponData.type])

  const onPatchCouponHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setEditLoading(true)
    const response = await patchCoupon(values as CouponPayload)

    if (response.errors)
      onPatchCouponHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setEditLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    const codeRegex = /^(\w|-)*$/

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.code)
      errors.code = 'Obrigatório'

    if (values.code && values.code.length > 30)
      errors.code = 'Máximo de 30 caracteres'

    if (values.code && !codeRegex.test(values.code))
      errors.code = 'Apenas letras, números, undescore e hífens'

    if (!values.type)
      errors.type = 'Obrigatório'

    if (!values.value)
      errors.value = 'Obrigatório'

    return errors
  }

  const onTypeChange = (e: SelectChangeEvent, form: FormApi) => {
    const field = form.getFieldState('value')
    field?.change(null)
    setValueType(e.target.value)
  }

  return {
    editLoading,
    editError,
    formattedCouponData,
    formError,
    openSnackbar,
    typeList,
    valueType,
    onTypeChange,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}

export type { CouponDataType }