import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton } from '@mui/material';

import { TableHead } from '../Table/TableHead';
import { TableCell } from '../Table/TableCell';
import { ActionButtonTable } from '../Table/ActionButtonTable';

import { ReactComponent as FileIcon } from 'images/icons/file.svg'

import '../styles.scss'

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rows: Array<any>,
  rowsPerPage: number,
  rowLink: string,
  total: number,
  isLoading: boolean,
  onClickOption?: (index: number, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function InvoiceGroupListTable({
  hasActions,
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rows,
  rowsPerPage,
  rowLink,
  total,
  isLoading,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {
  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAsc={isAsc}
          isAnySelected={false}
          optionMenu={hasActions}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>
          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headCells.map((cell, index) => (
                <TableCell key={index}><Skeleton /></TableCell>
              ))}

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => {
            let statusClass = ''
            if (row.status === 'PROCESSANDO') {
              statusClass = 'label-status-blue'
            } else if (row.status === 'APROVADO') {
              statusClass = 'label-status-green'
            } else if (row.status === 'RASCUNHO') {
              statusClass = 'label-status-blue'
            } else if (row.status === 'DESCARTADO') {
              statusClass = 'label-status-yellow'
            } else if (row.status === 'CANCELADO') {
              statusClass = 'label-status-red'
            } else if (row.status === 'ERRO') {
              statusClass = 'label-status-red'
            }

            let iconClass = row.path_to_file ? 'red-icon' : 'disabled-icon'

            return <TableRow
              className='table-row linked-table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'><div>{row.customer_name}</div><div>{row.key}</div></TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'>{row.total}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'>{row.payment_status}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'>{row.receipt_status}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'>{row.created_at}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'>{row.due_date}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell' align='left'>
                <span className={`label-status ${statusClass}`}>
                  {row.status}
                </span>
              </TableCell>
              <TableCell className='table-cell' align='left'>
                <a href={row.path_to_file} target='blank' className={`icon-link ${iconClass}`}>
                  <FileIcon />
                </a>
              </TableCell>

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left'>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(index, action)
                    }}
                  />
                </TableCell>
              )}

            </TableRow>
          })}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer >
  );
}

export { InvoiceGroupListTable };