type ProductType = {
  checkout_product_hash: string
}

type ProductDisplayType = {
  link: string,
  iframe: string
}

const formatProductToDisplay = (product: ProductType, creditCard: boolean, boleto: boolean, pix: boolean): ProductDisplayType => {
  const hash = product.checkout_product_hash
  let paymentOptions = []

  if (creditCard)
    paymentOptions.push('CREDIT_CARD')
  if (boleto)
    paymentOptions.push('BOLETO')
  if (pix)
    paymentOptions.push('PIX')

  let paymentOptionsString = ''
  if (paymentOptions.length > 0 && paymentOptions.length < 3)
    paymentOptionsString = '?payment_options=' + paymentOptions.join(',')

  let link = `${process.env.REACT_APP_SELF_URL}/checkout/${hash}${paymentOptionsString}`

  const iframe = `<iframe
    allowfullscreen
    frameborder="0"
    height="700"
    src="${process.env.REACT_APP_SELF_URL}/checkout/${hash}${paymentOptionsString}"
    width="890"/>`

  return {
    link: link,
    iframe: iframe
  }
}



export { formatProductToDisplay };

export type { ProductType, ProductDisplayType }