import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {ApiError} from "../../../../../utils/CustomError";
import {notAuthenticatedRedirect} from "../../../../../utils/auth";
import {getDetails, DetailsType} from "./services";

export default function ViewProductSpotViewModel() {
  const [tabSelected, setTabSelected] = useState<number>(0);

  const [product, setProduct] = useState<DetailsType | undefined>(undefined)
  const [productLoading, setProductLoading] = useState(true)
  const [productLoadingError, setProductLoadingError] = useState(false)

  const params = useParams();

  const onTabChange = (value: number) => {
    setTabSelected(value);
  }

  const onGetProductHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setProductLoadingError(true)
      setProduct(undefined);
    } else {
      setProductLoadingError(true)
      setProduct(undefined);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setProductLoading(true);

      const response = await getDetails(params?.id ?? '', abortController.signal);

      if (response.errors) {
        onGetProductHandleError(response.errors)
      } else {
        setProduct(response)
      }

      setProductLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [params])

  return { tabSelected, product, productLoading, productLoadingError, onTabChange };
}