import dayjs from 'dayjs';
import { CustomError, api } from '../../utils/axios';

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean
}

type SuppliersType = {
  id: string,
  name: string,
  status: string,
  document: string,
  emails: Array<string>,
  created_at: string
}

type SuppliersDisplayType = {
  id: string,
  name: string,
  status: string,
  document: string,
  emails: string,
  created_at: string,
  actions: ActionType[]
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parseSuppliers = (response: { data: any, meta: any }) => {

  const suppliers = response.data.map((data: any) => {
    return {
      id: data.id,
      name: data.attributes.name,
      status: data.attributes.status,
      document: data.attributes.document,
      emails: data.attributes.contacts ? data.attributes.contacts : [],
      created_at: data.attributes.created_at.date
    }
  });

  return {
    suppliers,
    pagination: {
      totalSuppliers: response.meta.pagination.total
    }
  };
}

const getSuppliers = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    if (filter.value.length)
      params[`filter[${filter.field}]`] = `${filter.comparator}:${filter.value.join()}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/suppliers', {
      params,
      signal
    });

    return parseSuppliers(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatSuppliersToDisplay = (suppliers: Array<SuppliersType>): Array<SuppliersDisplayType> => {

  return suppliers.map((supplier: SuppliersType) => {
    let actions: ActionType[] = [];

    switch (supplier.status) {
      case 'ACTIVE':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'activate', label: 'Ativar', disabled: true },
          { id: 'deactivate', label: 'Desativar' },
        ];
        break;

      case 'CANCELED':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'activate', label: 'Ativar' },
          { id: 'deactivate', label: 'Desativar', disabled: true },
        ];
        break;
    }

    return {
      id: supplier.id,
      name: supplier.name,
      status: supplier.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
      document: supplier.document,
      emails: supplier.emails ? supplier.emails.join(', ') : '',
      created_at: dayjs(supplier.created_at).format('DD/MM/YYYY'),
      actions
    }
  })

}

export { getSuppliers, formatSuppliersToDisplay };

export type { SuppliersType, SuppliersDisplayType, FilterType, PaginationType, SortType };