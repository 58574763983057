import { CustomError, api } from 'utils/axios';

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type TagsType = {
  id: string,
  name: string,
  description: string
}


const parseTags = (response: any) => {
  const tags = response.data.map((data: any) => ({
    id: data.id,
    name: data.attributes.name,
  }))

  return {
    tags,
    pagination: {
      totalTags: response.meta.pagination.total
    }
  }
}

const getTags = async (sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  try {
    const response = await api.get('/api-cashone/tags', {
      params,
      signal
    });

    return parseTags(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getTags };

export type { PaginationType, SortType, TagsType };