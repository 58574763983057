import { Button, Typography } from '@mui/material';

import useViewModel from './viewModel';

import { EmptyView } from 'components/EmptyView';
import { ApiErrorView } from 'components/ApiErrorView';
import { UserListTable } from 'components/Tables/UserListTable';

import { CreateUserModal } from 'components/Modals/User/Create';
import { EditUserModal } from 'components/Modals/User/Edit';

import '../../styles.scss';

function Users() {
  const {
    actionUserIndex,
    users,
    userLoading,
    userLoadingError,
    formattedUsers,
    page,
    openCreate,
    openEdit,
    optionsRowsPerPage,
    rowsPerPage,
    sort,
    totalUsers,
    tableHeadCells,
    handleTableRowActions,
    onChangeSort,
    onCloseCreateModal,
    onCloseEdit,
    onOpenCreateModal,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel();

  return (
    <div className='listing-page users-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Usuários
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>
            <Button
              variant='contained'
              size='small'
              onClick={onOpenCreateModal}
            >
              Criar Usuário
            </Button>

            <CreateUserModal
              onClose={onCloseCreateModal}
              open={openCreate}
            />
          </div>
        </div>
      </div>

      {!userLoading &&
        !userLoadingError &&
        formattedUsers?.length === 0 &&
        <EmptyView title={'Nenhum usuário encontrado para mostrar aqui'}></EmptyView>
      }

      {userLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os usuários. Tente recarregar a página.' />
      }

      {(userLoading || (formattedUsers !== undefined && formattedUsers.length > 0)) && <>
        <UserListTable
          hasActions
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedUsers ? formattedUsers : []}
          rowsPerPage={rowsPerPage}
          total={totalUsers}
          isLoading={userLoading}
          onClickOption={handleTableRowActions}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />

        <EditUserModal
          userData={{
            id: actionUserIndex !== undefined && users ? users[actionUserIndex].id : '',
            email: actionUserIndex !== undefined && users ? users[actionUserIndex].email : '',
            name: actionUserIndex !== undefined && users ? users[actionUserIndex].name : '',
            phone: actionUserIndex !== undefined && users ? users[actionUserIndex].phone : '',
          }}
          open={openEdit}
          onClose={onCloseEdit}
        />
      </>}
    </div>
  );
}

export { Users };