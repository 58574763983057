import { useState, useEffect } from "react";
import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';

import { uploadFile } from './services'

type ViewModelProps = {
  initialDetails: string[]
}

export default function MultipleDetailAttachmentUploadViewModel({ initialDetails }: ViewModelProps) {

  const [uploading, setUploading] = useState(false);
  const [uploadingError, setUploadingError] = useState(false)

  const [detailList, setDetailList] = useState(initialDetails)

  useEffect(() => {
  }, [detailList])

  useEffect(() => {
    if (initialDetails.length > 0)
      setDetailList(initialDetails)
  }, [initialDetails])

  const onUploadHandleError = (errors: ApiError) => {
    if (errors[0]?.status === 401 && errors[0]?.type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0]?.status === 200 && errors[0]?.type === 'ERR_CANCELED') {

    } else if (errors[0]?.type === 'ERR_NETWORK') {
      setUploadingError(true)
    } else {
      setUploadingError(true)
    }
  }

  const onChange = async (file: File) => {
    setUploading(true)
    setUploadingError(false)

    const response = await uploadFile(file)

    if (response.errors)
      onUploadHandleError(response.errors)

    else {
      setDetailList([...detailList, response])
      setUploadingError(false)
    }

    setUploading(false);
    return [...detailList, response];
  }

  const onDeleteAttachment = (index: number) => {
    var copyDetailList = [...detailList];
    copyDetailList.splice(index, 1);
    setDetailList(copyDetailList);

    return copyDetailList;
  }

  return {
    uploading,
    uploadingError,
    detailList,
    onChange,
    onDeleteAttachment
  }
}