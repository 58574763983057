import { useCallback, useEffect, useState } from 'react';

import { useTitleName } from 'recoil/viewTitleName/actions';

import { DetailsDisplayType, DetailsType, formatDetailsToDisplay, getDetails } from './services';
import { notAuthenticatedRedirect } from 'utils/auth';

type ViewModelProps = {
  receiptId: string
}

const getDetailsServiceHandler = async (id: string, signal: AbortSignal) => {
  const response = await getDetails(id, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect()


    return null
  }

  return response;
}

export default function InvoiceReceiptGeneralViewModel({ receiptId }: ViewModelProps) {
  const [details, setDetails] = useState<DetailsType>();
  const [formattedDetails, setFormattedDetails] = useState<DetailsDisplayType>();
  const [detailsLoading, setDetailsLoading] = useState(true)

  const { titleName, updateTitleName } = useTitleName();

  const memoizedUpdateTitleName = useCallback(
    (name: string, loading = false) => {
      updateTitleName(name, loading);
    },
    [updateTitleName]
  );

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setDetailsLoading(true);

      const response = await getDetailsServiceHandler(receiptId, abortController.signal);

      if (response && !abortController.signal.aborted) {
        setDetails(response)
        setFormattedDetails(formatDetailsToDisplay(response))
      }

      setDetailsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [receiptId])

  useEffect(() => {
    memoizedUpdateTitleName(formattedDetails?.receipt_number || '', detailsLoading);
  }, [detailsLoading, formattedDetails?.receipt_number, memoizedUpdateTitleName]);

  return {
    details,
    detailsLoading,
    formattedDetails,
    titleName
  }
}