const REGIME: any = {
  'REGIME_LUCRO_PRESUMIDO': 'Lucro Presumido',
  'REGIME_LUCRO_REAL': 'Lucro Real',
  'REGIME_MICROEMPRESARIO_E_PEQUENO_PORTE': 'Microempresa',
  'REGIME_MICROEMPRESARIO_INDIVIDUAL': 'Microempresário Individual',
  'REGIME_SIMPLES_NACIONAL': 'Optante pelo Simples Nacional',
}

const REGIME_ESPECIAL: any = {
  'SPECIAL_REGIME_COOPERATIVA': 'Cooperativa',
  'SPECIAL_REGIME_LUCRO_PRESUMIDO': 'Lucro Presumido',
  'SPECIAL_REGIME_LUCRO_REAL': 'Lucro Real',
  'SPECIAL_REGIME_MICROEMPRESARIO_E_EMPRESA_DE_PEQUENO_PORTE': 'Microempresa',
  'SPECIAL_REGIME_MICROEMPRESARIO_INDIVIDUAL': 'Microempresário Individual',
  'SPECIAL_REGIME_MICROEMPRESA_MUNICIPAL': 'Microempresa Municipal',
  'SPECIAL_REGIME_ESTIMATIVA': 'Regime de Estimativa',
  'SPECIAL_REGIME_SOCIEDADE_DE_PROFISSIONAIS': 'Sociedade de Profissionais',
  'SPECIAL_REGIME_NENHUM': 'Nenhum',
}

export { REGIME, REGIME_ESPECIAL }