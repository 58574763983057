type viewModelPropTypes = {
  rows: any[],
  rowsSelected: number[],
  onCheckboxClick: (newRowsSelected: number[]) => void
}

export default function ClientsListTableViewModel({ rows, rowsSelected, onCheckboxClick }: viewModelPropTypes) {
  const onCheckboxChange = (checked: boolean, index: number) => {
    if (checked) {
      rowsSelected.push(index);
      onCheckboxClick(rowsSelected)
    } else {
      const removeIndex = rowsSelected.indexOf(index);
      rowsSelected.splice(removeIndex, 1)
      onCheckboxClick(rowsSelected);
    }
  };

  const onHeaderCheckboxChange = () => {
    if (rowsSelected.length === 0)
      onCheckboxClick(Array.from(Array(rows.length).keys()))
    else
      onCheckboxClick([])
  };

  return { onCheckboxChange, onHeaderCheckboxChange };
}