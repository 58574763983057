import { useState } from 'react';

export default function SubscriptionPlansViewModel() {
  const [tabSelected, setTabSelected] = useState<number>(0);

  const [openCreateSale, setOpenCreateSale] = useState(false);
  const [createSaleType, setCreateSaleType] = useState<string>()

  const onTabChange = (value: number) => {
    setTabSelected(value);
  }

  const onCloseModal = () => {
    setOpenCreateSale(false)
  }

  const onOpenModal = (id: string) => {
    setCreateSaleType(id)
    setOpenCreateSale(true)
  }

  return {
    createSaleType,
    openCreateSale,
    tabSelected,
    onTabChange,
    onOpenModal,
    onCloseModal,
  };
}