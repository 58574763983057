const EMAIL_TYPES: any = {
  "ACCOUNT_PAYABLE_REMINDER": "Lembrete de vencimento",
  "AUTHORIZE_CREDIT_CARD_CHARGE": "Autorização de cobrança",
  "BANK_DEPOSIT_COPY": "Segunda via - instruções para depósito",
  "BANK_DEPOSIT": "Instruções para depósito",
  "BILL_RUN_FINISHED": "Billrun concluído",
  "BOLETO_COPY": "Segunda via - Boleto",
  "BOLETO_EXPIRED": "Boleto expirado",
  "BOLETO_PENDING": "Boleto disponível",
  "BOLETO_REMINDER": "Lembrete de boleto pendente",
  "CREDIT_CARD_ABOUT_TO_EXPIRE": "Lembrete de vencimento",
  "CREDIT_CARD_BILLING_NOTICE": "Aviso de cobrança",
  "CREDIT_CARD_PAYMENT_SCHEDULE_COPY": "Segunda via - agendamento de pagamento",
  "CREDIT_CARD_PAYMENT_SCHEDULE": "Agendamento de pagamento",
  "CREDIT_CARD_REGISTRATION_AND_CHARGE": "Link para cadastro e cobrança de cartão",
  "CREDIT_CARD_REGISTRATION_COPY": "Segunda via - link para cadastro de cartão",
  "CREDIT_CARD_SIGN_UP": "Link para cadastro de cartão",
  "DAILY_AUTO_INVOICE_RECEIPT_RUN_FINISHED": "NFrun diário automático concluído",
  "INVOICE_COPY": "Segunda via - fatura",
  "INVOICE_PAID": "Pagamento recebido",
  "INVOICE_RECEIPT_INFORMATION_COPY": "Segunda via de NFSe",
  "INVOICE_RECEIPT_INFORMATION": "Envio de NFSe",
  "INVOICE_RECEIPT_RUN_FINISHED": "NFrun concluído",
  "INVOICE_SENT": "Envio de fatura",
  "INVOICE_SINGLE_EMAIL": "Fatura disponível com novos arquivos",
  "NEW_COMPANY_CREATED": "Nova empresa",
  "PASSWORD_RESET_TOKEN_CONFIRMATION": "",
  "PASSWORD_RESET_TOKEN": "Requisição de alteração de senha",
  "PASSWORD_WAS_RESET_BY_USER": "Senha alterada pelo usuário",
  "PAYMENT_ATTEMPT_FAILED": "Falha na tentativa de pagamento",
  "PAYMENT_METHOD_CHANGED": "Mudança no método de pagamento",
  "PAYMENT_RUN_FINISHED": "Payrun concluído",
  "SIGN_ON_PAYMENT_METHOD": "Pedido para cadastrar meio de pagamento",
  "SUBSCRIPTION_ABOUT_TO_BE_RENEWED": "Lembrete de renovação",
  "SUBSCRIPTION_CANCELED": "Cancelamento",
  "TRIAL_ABOUT_TO_EXPIRE": "Lembrete de término do trial",
  "WELCOME_CUSTOMER": "Boas vindas"
}

export { EMAIL_TYPES }