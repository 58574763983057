import useViewModel from './viewModel'
import {PlanType} from './services'
import {Typography} from "@mui/material";
import {Flexbox} from "../../../../../../../../../../components/Flexbox";
import {DisplayInfoText} from "../../../../../../../../../../components/Info/DisplayInfoText";
import '../../styles.scss'
import {ApiErrorView} from "../../../../../../../../../../components/ApiErrorView";

type PlanViewGeneralViewPropTypes = {
    loading: boolean,
    loadingError: boolean,
    planData?: PlanType
}

function PlanGeneralView({loading, loadingError, planData}: PlanViewGeneralViewPropTypes) {
  const {
      planLoading,
      planLoadingError,
      formattedPlan
  } = useViewModel(loading, loadingError, planData)

  return (
      <div className='view-tab'>

          {planLoadingError &&
              <ApiErrorView
                  title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar o plano. Tente recarregar a página.'/>
          }

          {!planLoadingError && <>
              <div className='segment-info'>
                  <Typography variant='h4' fontWeight={700} className='general-subtitle'>Informações
                      básicas</Typography>

                  <div className='info-row'>
                      <Flexbox size={3}>
                          <DisplayInfoText
                              isLoading={planLoading}
                              title='Nome'
                              text={formattedPlan?.name ? formattedPlan.name : ''}
                          />
                      </Flexbox>
                      <Flexbox size={3}>
                          <DisplayInfoText
                              isLoading={planLoading}
                              title='Valor'
                              text={formattedPlan?.value ? formattedPlan.value : ''}
                          />
                      </Flexbox>
                      <Flexbox size={3}>
                          <DisplayInfoText
                              isLoading={planLoading}
                              title='Termo de contrato'
                              text={formattedPlan?.initial_term ? formattedPlan.initial_term : ''}
                          />
                      </Flexbox>
                  </div>
                  <div className='info-row'>
                      <Flexbox size={3}>
                          <DisplayInfoText
                              isLoading={planLoading}
                              title='Frequência'
                              text={formattedPlan?.freq ? formattedPlan.freq : ''}
                          />
                      </Flexbox>
                      <Flexbox size={6}>
                          <DisplayInfoText
                              isLoading={planLoading}
                              title='Data/Prazo de vencimento das cobranças'
                              text={formattedPlan?.payment_term ? formattedPlan.payment_term : ''}
                          />
                      </Flexbox>
                  </div>

              </div>
          </>}

      </div>
  );
}

export {PlanGeneralView}