import { CustomError, api } from 'utils/axios'
import { STATES } from 'utils/constants/states'

type CityType = {
  attributes: {
    name: string,
  },
  id: string
}

type ParsedCityType = {
  id: string,
  label: string,
}

type TagType = {
  attributes: {
    name: string,
  },
  id: string
}

type ParsedTagType = {
  id: string,
  name: string,
}

type ClientPayload = {
  name: string,
  document: string,
  description?: string,
  regime_type: string,
  tags?: string[],
  emails?: string[],
  phones?: string[],
  address: {
    street: string,
    number: string,
    complement?: string,
    neighbourhood: string,
    city: {
      id: string,
      label: string,
    } | string,
    postal_code?: string,
    state_abbreviation?: string,
    state_province?: string,
    country?: string
  },
  regime?: {
    razao_social: string,
    inscricao_municipal?: string,
    optante_simples_nacional?: string[]
  },
  details: string[]
}

type ParsedClientPayload = {
  name: string,
  document: string,
  description: string,
  regime_type: string,
  tags: string[],
  emails: string[],
  phones: string[],
  address: {
    street: string,
    number: string,
    complement: string,
    neighbourhood: string,
    brl_city_id: string,
    city: string,
    postal_code?: string,
    state_province: string,
    state_abbreviation: string,
    country?: string
  },
  regime?: {
    razao_social: string,
    inscricao_municipal: string,
    optante_simples_nacional: number
  },
  details: string[]
}

const bundlePayload = (payload: ClientPayload): ParsedClientPayload => (
  {
    ...payload,
    description: payload.description ? payload.description : '',
    tags: payload.tags ? payload.tags : [],
    phones: payload.phones ? payload.phones : [],
    emails: payload.emails ? payload.emails : [],
    regime: payload.regime && {
      ...payload.regime,
      inscricao_municipal: payload.regime?.inscricao_municipal ? payload.regime.inscricao_municipal : '',
      optante_simples_nacional: payload.regime?.optante_simples_nacional ? 1 : 0
    },
    address: {
      ...payload.address,
      city: typeof payload.address.city === 'string' ? payload.address.city : payload.address.city.label,
      brl_city_id: typeof payload.address.city === 'string' ? '' : payload.address.city.id,
      state_province: payload.address.state_province ? payload.address.state_province : STATES[payload.address.state_abbreviation ? payload.address.state_abbreviation : ''],
      state_abbreviation: payload.address.state_abbreviation ? payload.address.state_abbreviation : '',
      complement: payload.address.complement ? payload.address.complement : '',
      postal_code: payload.address.postal_code ? payload.address.postal_code : '',
    },
    details: payload.details ? payload.details : []
  }
)

const postClient = async (payload: ClientPayload): Promise<any> => {
  try {
    const response = await api.post('/api-cashone/clients', bundlePayload(payload))

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const parseCities = (cities: CityType[]): ParsedCityType[] =>
  cities.map((city) => ({
    id: city.id,
    label: city.attributes.name
  }));

const getCities = async (search: string, state: string, signal: AbortSignal): Promise<ParsedCityType[] | any> => {
  try {
    const response = await api.get(`/api/taxes/brl/cities?limit=10&q=${search}&filter[uf_abbreviation]=eq:${state}`, {
      signal
    });

    return parseCities(response.data.data);
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
};

const parseTags = (tags: TagType[]): ParsedTagType[] =>
  tags.map((tag) => ({
    id: tag.id,
    name: tag.attributes.name
  }));

const getTags = async (signal: AbortSignal): Promise<ParsedTagType[] | any> => {
  try {
    const response = await api.get(`/api-cashone/tags?limit=1000`, {
      signal
    });

    return parseTags(response.data.data);
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
};

const formatTagsToDisplay = (tags: ParsedTagType[]): Array<string> => {
  return tags.map((tag) => {
    return tag.name
  });
}

export { getCities, getTags, formatTagsToDisplay, postClient }

export type { ClientPayload }