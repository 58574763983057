type BundleTermsOfUsePayload = {
  accept_term_of_use: boolean,
}

const bundleTermsOfUsePayload = (values: Record<string, any>): BundleTermsOfUsePayload => {
  return {
    accept_term_of_use: values.accept_term_of_use?.length ? true : false,
  }
}

export { bundleTermsOfUsePayload };
