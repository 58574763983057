import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form';

import { Flexbox } from 'components/Flexbox';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';

import useViewModel, { AccountsReceivablesType } from './viewModel'

import './styles.scss'
import '../../styles.scss'
import { ApiErrorView } from 'components/ApiErrorView';

type PaymentMethodARModalPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function PaymentMethodARModal({ accountReceivableData, open, onClose, onSubmit }: PaymentMethodARModalPropTypes) {
  const {
    ARError,
    ARLoading,
    getError,
    formattedPaymentOptions,
    initialPayment,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ accountReceivableData, open, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='payment-method-ar-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={{ hash: initialPayment }}
            validate={formValidate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className='modal-header'>
                  <Typography variant='h2'>
                    Alterar método de pagamento do conta a receber {accountReceivableData.key}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                {!getError &&
                  <div className='modal-body'>
                    <Flexbox size={12}>
                      <SelectFieldAdapter
                        name='hash'
                        label='Método de pagamento'
                        options={formattedPaymentOptions ? formattedPaymentOptions : []}
                        loading={ARLoading}
                      />
                    </Flexbox>
                  </div>
                }

                {getError &&
                  <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os métodos de pagamento.' />
                }

                <div className='modal-footer'>
                  {ARLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' color='error' onClick={onClose}>Cancelar</Button>
                  <Button variant='contained' type='submit' disabled={ARLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {ARError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!ARError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Conta a receber cancelado!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { PaymentMethodARModal }