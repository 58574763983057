import { Alert, Box, Button, Modal, Paper, Snackbar, Typography, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


import useViewModel, { InvoiceGroupsType } from './viewModel'

import '../../styles.scss'
import './styles.scss'

type CancelInvoiceModalPropTypes = {
  invoiceGroupData: InvoiceGroupsType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function CancelInvoiceGroupModal({ invoiceGroupData, open, onClose, onSubmit }: CancelInvoiceModalPropTypes) {
  const {
    invoiceLoading,
    invoiceError,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ invoiceGroupData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='cancel-invoices-group-modal custom-modal'>
          <div className='modal-header'>
            <Typography variant='h2'>
              Cancelar a fatura {invoiceGroupData.key}
            </Typography>
            <div className='modal-close-button' onClick={onClose}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body'>
            <Typography>
              Tem certeza que deseja cancelar esta fatura?
            </Typography>
          </div>

          <div className='modal-footer'>
            {invoiceLoading && <CircularProgress className='loading' color='primary' size={20} />}
            <Button variant='outlined' disabled={invoiceLoading} onClick={onClose}>Não</Button>
            <Button variant='contained' color='error' disabled={invoiceLoading} onClick={handleSubmit}>Sim</Button>
          </div>
        </Box>
      </Modal>

      {invoiceError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!invoiceError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Fatura cancelada com sucesso!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { CancelInvoiceGroupModal }