import { useEffect, useState } from 'react'

import { getClient, emailInvoiceReceipt, ClientType, InvoiceReceiptPayload } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type InvoiceReceiptsType = {
  id: string,
  customer_id: string
}

type ViewModelPropTypes = {
  invoiceReceiptData: InvoiceReceiptsType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EmailReceiptViewModel({ invoiceReceiptData, onClose, onSubmit }: ViewModelPropTypes) {
  const [receiptLoading, setReceiptLoading] = useState(false)
  const [receiptError, setReceiptError] = useState(false)

  const [client, setClient] = useState<ClientType>()
  const [clientLoading, setClientLoading] = useState(true)
  const [clientLoadingError, setClientLoadingError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onGetClientHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientLoadingError(true)
      setClient(undefined);
    } else {
      setClientLoadingError(true)
      setClient(undefined);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setClientLoading(true);

      if (invoiceReceiptData.customer_id) {
        const response = await getClient(invoiceReceiptData.customer_id, abortController.signal);

        if (response.errors) {
          onGetClientHandleError(response.errors)
        } else {
          setClient(response)
        }
      }

      setClientLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [invoiceReceiptData])

  const onPostEmailHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setReceiptError(true)
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setReceiptError(true)
      setOpenSnackbar(true)
    } else {
      setReceiptError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setReceiptLoading(true)

    const response = await emailInvoiceReceipt(invoiceReceiptData.id, values as InvoiceReceiptPayload)

    if (response.errors)
      onPostEmailHandleError(response.errors)

    else {
      setReceiptError(false)
      setOpenSnackbar(true)
      setFormError(false)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setReceiptLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.emails || values.emails.length === 0) {
      errors.emails = 'Obrigatório'
    } else {
      values.emails.forEach((email: string) => {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
          errors.emails = 'Existem emails inválidos'
      })
    }

    return errors
  }

  return {
    receiptLoading,
    receiptError,
    clientLoading,
    clientLoadingError,
    formError,
    openSnackbar,
    client,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}

export type { InvoiceReceiptsType }