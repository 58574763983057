import { ReactElement } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import './styles.scss';

type ItemsType = {
  url: string,
  title: string,
}

type CollapseMenuItemPropTypes = {
  icon: ReactElement,
  currentUrl: string,
  title: string,
  items: ItemsType[],
  onSubitemClick: (url: string) => void,
}

function CollapseMenuItem({
  icon,
  currentUrl,
  title,
  items,
  onSubitemClick
}: CollapseMenuItemPropTypes) {

  return (
    <div className='collapse-menu'>
      <Accordion
        className='accordion'
        disableGutters
      >
        <AccordionSummary
          className='accordion-summary'
          expandIcon={<ExpandMore stroke='white' />}
        >
          {icon}
          <Typography color='white' className='accordion-summary-text'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className='items'>

          {items.map((item, index) => (
            <div
              key={index}
              className={`menu-subitem ${currentUrl.includes(item.url) ? 'selected' : ''}`}
              onClick={() => { onSubitemClick(item.url) }}
            >
              <Typography color='white'>
                {item.title}
              </Typography>
            </div>
          ))}

        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export { CollapseMenuItem };