import dayjs from 'dayjs'

import { CustomError, api } from '../../utils/axios'
import { formatMoney } from '../../utils/moneyFormatt'

type AccountsReceivablesType = {
  id: string,
  customer_id: string,
  customer_name: string,
  key: string,
  total: number,
  net_value: number,
  customer_payment_info_id?: string,
  payment_method: string,
  due_date: string,
  transfer_date: string,
  status: string
}

type AccountsReceivablesDisplayType = {
  id: string,
  customer_id: string,
  customer_name: string,
  customer_payment_info_id?: string,
  key: string,
  total: string,
  net_value: string,
  payment_method: string,
  due_date: string,
  transfer_date: string,
  status: string,
  actions: {
    id: string,
    label: string,
    disabled?: boolean,
  }
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type TotalsType = {
  total: number,
  items: number
}

type TotalsDisplayType = {
  total: string,
  items: number
}

const parseAR = (response: { data: any, meta: any }) => {
  const ARs = response.data.map((data: any) => {
    return {
      id: data.id,
      customer_id: data.attributes.customer_id,
      customer_name: data.attributes.customer,
      key: data.attributes.key,
      total: data.attributes.total,
      net_value: data.attributes.net_value,
      customer_payment_info_id: data.attributes.customer_payment_info_id,
      payment_method: data.attributes.payment_method,
      due_date: data.attributes.due_date.date,
      transfer_date: data.attributes.transfer_date?.date,
      status: data.attributes.status,
    }
  });

  return {
    accountsReceivables: ARs,
    totals: {
      total: response.meta.aggregate.totals.total_value,
      totalARs: response.meta.pagination.total
    }
  }
}

const getARs = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (filter.value.length)
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/accounts-receivables', {
      params,
      signal
    })

    return parseAR(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatARsToDisplay = (ARs: Array<AccountsReceivablesType>): Array<AccountsReceivablesDisplayType> => {
  const translateStatus = {
    'CANCELLED': 'CANCELADO',
    'DRAFT': 'RASCUNHO',
    'ERROR': 'ERRO',
    'EXPIRED': 'EXPIRADO',
    'PAID': 'PAGO',
    'PARTIALLY_PAID': 'PARCIALMENTE PAGO',
    'PENDING': 'PENDENTE',
    'RENEGOTIATED': 'RENEGOCIADO',
    'TRANSFERRED': 'TRANSFERIDO',
  }

  const translatePaymentMethod = {
    'BANK_DEPOSIT': 'Depósito Bancário',
    'BOLETO': 'Boleto',
    'CREDIT_CARD': 'Cartão de Crédito',
    'PIX': 'Pix',
  }

  type StatusEnum = keyof typeof translateStatus;
  type PaymentMethodEnum = keyof typeof translatePaymentMethod;


  return ARs.map((ar) => {
    let actions: any = []

    switch (ar.status) {
      case 'CANCELLED':
      case 'RENEGOTIATED':
        actions = [
          { id: 'due_date', label: 'Alterar data de vencimento', disabled: true },
          { id: 'payment_method', label: 'Alterar método de pagamento', disabled: true },
          { id: 'cancel', label: 'Cancelar cobrança', disabled: true },
          { id: 'send_email', label: 'Enviar email com cobrança', disabled: true },
          { id: 'retry', label: 'Reexecutar cobrança', disabled: true },
          { id: 'acknowledge', label: 'Dar baixa', disabled: true },
        ];
        break;
      case 'PAID':
      case 'PARTIALLY_PAID':
      case 'TRANSFERRED':
        actions = [
          { id: 'due_date', label: 'Alterar data de vencimento', disabled: true },
          { id: 'payment_method', label: 'Alterar método de pagamento', disabled: true },
          { id: 'cancel', label: 'Cancelar cobrança' },
          { id: 'send_email', label: 'Enviar email com cobrança', disabled: true },
          { id: 'retry', label: 'Reexecutar cobrança', disabled: true },
          { id: 'acknowledge', label: 'Dar baixa', disabled: true },
        ];
        break;
      case 'PENDING':
      case 'EXPIRED':
        actions = [
          { id: 'due_date', label: 'Alterar data de vencimento' },
          { id: 'payment_method', label: 'Alterar método de pagamento' },
          { id: 'cancel', label: 'Cancelar cobrança' },
          { id: 'send_email', label: 'Enviar email com cobrança' },
          { id: 'retry', label: 'Reexecutar cobrança', disabled: true },
          { id: 'acknowledge', label: 'Dar baixa', disabled: ar.payment_method !== 'BANK_DEPOSIT' },
        ];
        break;
      case 'DRAFT':
        actions = [
          { id: 'due_date', label: 'Alterar data de vencimento' },
          { id: 'payment_method', label: 'Alterar método de pagamento' },
          { id: 'cancel', label: 'Cancelar cobrança' },
          { id: 'send_email', label: 'Enviar email com cobrança', disabled: true },
          { id: 'retry', label: 'Reexecutar cobrança', disabled: true },
          { id: 'acknowledge', label: 'Dar baixa', disabled: true },
        ];
        break;
      case 'ERROR':
        actions = [
          { id: 'due_date', label: 'Alterar data de vencimento' },
          { id: 'payment_method', label: 'Alterar método de pagamento' },
          { id: 'cancel', label: 'Cancelar cobrança' },
          { id: 'send_email', label: 'Enviar email com cobrança', disabled: true },
          { id: 'retry', label: 'Reexecutar cobrança' },
          { id: 'acknowledge', label: 'Dar baixa', disabled: true },
        ];
        break;
    }

    return {
      id: ar.id,
      customer_id: ar.customer_id,
      customer_name: ar.customer_name,
      customer_payment_info_id: ar.customer_payment_info_id,
      key: ar.key,
      total: formatMoney(ar.total),
      net_value: formatMoney(ar.net_value),
      payment_method: translatePaymentMethod[ar.payment_method as PaymentMethodEnum],
      due_date: dayjs(ar.due_date).format('DD/MM/YYYY'),
      transfer_date: ar.transfer_date ? dayjs(ar.transfer_date).format('DD/MM/YYYY') : '-',
      status: translateStatus[ar.status as StatusEnum],
      actions
    }
  })
}

const formatTotalsToDisplay = (totals: TotalsType): TotalsDisplayType => {
  return {
    total: formatMoney(totals.total),
    items: totals.items
  }
}

export { getARs, formatARsToDisplay, formatTotalsToDisplay }

export type {
  AccountsReceivablesDisplayType,
  AccountsReceivablesType,
  FilterType,
  SortType,
  PaginationType,
  TotalsType,
  TotalsDisplayType,
}