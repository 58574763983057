import dayjs from 'dayjs';
import { CustomError, api } from 'utils/axios';
import { formatMoney } from 'utils/moneyFormatt';

import { formatPercent } from 'utils/percentFormat';

type DetailsType = {
  id: string,
  key: string,
  status: string,
  description: string,
  customer_id: number,
  customer_name: string,
  plan_id: number,
  plan_name: string,
  product_id: number,
  product_name: string,
  iss_rate: number,
  iss_rate_in_receipt: number,
  create_receipt: boolean,
  auto_receipt_run: boolean,
  value?: number,
  frequency?: string,
  next_charge_date?: string,
  start_date?: string,
  end_date?: string,
  initial_term?: number,
  auto_renewal?: boolean,
  payment_method?: {
    name: string,
    last_digits: string,
    brand: string,
    bank_name: string,
    routing_number: string,
    routing_number_digit_verifier: string,
    account_number: string,
    account_number_digit_verifier: string,
  },
  installments: {
    value: number,
    due_date: {
      date: string
    },
  }[],
  payment_terms: {
    payment_term_type: string,
    payment_term_value: number,
    first_payment_term_type: string,
    first_payment_term_value: number,
  }
}

type DetailsDisplayType = {
  id: string,
  key: string,
  status: string,
  description: string,
  customer_id: number,
  customer_name: string,
  plan_id: number,
  plan_name: string,
  product_id: number,
  product_name: string,
  iss_rate: string,
  iss_rate_in_receipt: string,
  emission_receipt: string,
  value?: string,
  frequency?: string,
  next_charge_date?: string,
  start_date?: string,
  end_date?: string,
  initial_term?: string,
  auto_renewal?: string,
  payment_method?: string,
  installments: string[],
  payment_term: string,
  first_payment_term: string,
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    key: response.data.attributes.key,
    status: response.data.attributes.status,
    description: response.data.attributes.description,
    customer_id: response.data.attributes.customer_id,
    customer_name: response.data.attributes.customer_name,
    plan_id: response.data.attributes.plan_id,
    plan_name: response.data.attributes.plan_name,
    product_id: response.data.attributes.product_id,
    product_name: response.data.attributes.product_name,
    iss_rate: response.data.attributes.iss_rate,
    iss_rate_in_receipt: response.data.attributes.iss_rate_in_receipt,
    create_receipt: response.data.attributes.create_receipt,
    auto_receipt_run: response.data.attributes.auto_receipt_run,
    value: response.data.attributes.value,
    frequency: response.data.attributes.frequency,
    next_charge_date: response.data.attributes.next_charge_date?.date,
    start_date: response.data.attributes.start_date.date,
    end_date: response.data.attributes.end_date.date,
    initial_term: response.data.attributes.initial_term,
    auto_renewal: response.data.attributes.auto_renewal,
    payment_method: response.data.attributes.payment_method,
    installments: response.data.attributes.installments,
    payment_terms: response.data.attributes.payment_terms,
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/sales/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  const translateStatus = {
    'ACTIVE': 'ATIVO',
    'TERMINATED': 'CANCELADO',
    'STANDBY_CANCEL': 'CANCELADO',
    'EXPIRED': 'EXPIRADO',
    'STANDBY_EXPIRED': 'EXPIRADO',
  }
  type translateEnum = keyof typeof translateStatus

  const translateFreq = {
    'YEARLY': 'Anual',
    'SEMIANNUAL': 'Semestral',
    'QUARTERLY': 'Trimestral',
    'MONTHLY': 'Mensal',
  }
  type freqEnum = keyof typeof translateFreq;

  let emission_receipt;
  if (details.create_receipt) {
    if (details.auto_receipt_run)
      emission_receipt = 'Sim, emitindo automaticamente'
    else
      emission_receipt = 'Sim, emissão manual'
  }
  else
    emission_receipt = 'Não emitir NF'

  let installments: any = [];
  if (details.installments) {
    installments = details.installments.map(installment => (
      `${formatMoney(installment.value)} com vencimento em ${dayjs(installment.due_date.date).format('DD/MM/YYYY')}`
    ))
  }

  let payment_term = '';
  if (details.payment_terms.payment_term_type === 'FIXED')
    payment_term = `Todo dia ${details.payment_terms.payment_term_value}`
  else
    payment_term = `Até ${details.payment_terms.payment_term_value} dias após a cobrança`

  let first_payment_term = '';
  if (details.payment_terms.first_payment_term_type === 'FIXED')
    first_payment_term = `Todo dia ${details.payment_terms.first_payment_term_value}`
  else
    first_payment_term = `Até ${details.payment_terms.first_payment_term_value} dias após a cobrança`

  let payment_method = '';
  if (details.payment_method?.name === 'BOLETO')
    payment_method = 'Boleto';
  else if (details.payment_method?.name === 'PIX')
    payment_method = 'Pix';
  else if (details.payment_method?.name === 'BANK_DEPOSIT')
    payment_method = `Depósito em conta ${details.payment_method.bank_name} Ag ${details.payment_method.routing_number}-${details.payment_method.routing_number_digit_verifier} CC ${details.payment_method.account_number}-${details.payment_method.account_number_digit_verifier}`
  else if (details.payment_method?.name === 'CREDIT_CARD') {
    if (details.payment_method.brand && details.payment_method.last_digits)
      payment_method = `Cartão ${details.payment_method.brand} final ${details.payment_method.last_digits}`
    else
      payment_method = `Cartão (ainda não cadastrado)`
  }

  return {
    id: details.id,
    status: translateStatus[details.status as translateEnum],
    key: details.key,
    description: details.description ? details.description : 'N/A',
    customer_id: details.customer_id,
    customer_name: details.customer_name,
    plan_id: details.plan_id,
    plan_name: details.plan_name,
    product_id: details.product_id,
    product_name: details.product_name,
    iss_rate: formatPercent(details.iss_rate),
    iss_rate_in_receipt: formatPercent(details.iss_rate_in_receipt),
    emission_receipt,
    value: details.value ? formatMoney(details.value) : '',
    frequency: details.frequency ? translateFreq[details.frequency as freqEnum] : '',
    next_charge_date: details.next_charge_date ? dayjs(details.next_charge_date).format('DD/MM/YYYY') : 'N/A',
    start_date: details.start_date ? dayjs(details.start_date).format('DD/MM/YYYY') : '',
    end_date: details.end_date ? dayjs(details.end_date).format('DD/MM/YYYY') : '',
    initial_term: details.initial_term ? `${details.initial_term} meses` : '',
    auto_renewal: details.auto_renewal ? 'Sim' : 'Não',
    payment_method,
    installments,
    payment_term,
    first_payment_term,
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }