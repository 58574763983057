import { useRef } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Popover, Typography } from '@mui/material';

import useViewModel from './viewModel';

import { ClientsType, TagsType } from '../../../pages/Clients/services';
import { ModalCreateTag } from '../../Modals/Tag/Create';

type BulkClientsTagsPropTypes = {
  clients: ClientsType[],
  clientsSelected: number[],
  tags: TagsType[],
  onSubmitCreate: (values: { name: string, description: string }) => Promise<void>,
  onSubmitEdit: (addTags: string[], removeTags: string[]) => Promise<void>
}

function BulkClientsTags({
  clients,
  clientsSelected,
  tags,
  onSubmitCreate,
  onSubmitEdit
}: BulkClientsTagsPropTypes) {
  const {
    isOpen,
    showCreateModal,
    tagsFromClients,
    hasChanges,
    onButtonClick,
    onChangeCreateModal,
    onCheckboxChange,
    onSubmitEditHandler
  } = useViewModel({ clients, clientsSelected, tags, onSubmitEdit });

  const buttonRef = useRef(null);
  const isChanged = hasChanges()

  return (
    <div className='bulk-action bulk-clients-tags'>
      <Button variant='outlined' onClick={onButtonClick} size='small' color='secondary'>
        <Typography fontWeight={700} variant='caption' ref={buttonRef}>
          Tags
        </Typography>
      </Button>

      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onButtonClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='bulk-action-modal'>

          <div className='options'>
            <FormGroup>
              {tags &&
                tags.map(tag => (
                  <FormControlLabel
                    key={tag.value}
                    label={tag.label}
                    control={
                      <Checkbox
                        checked={tagsFromClients[tag.label] === clientsSelected.length}
                        indeterminate={
                          tagsFromClients[tag.label] > 0
                          && tagsFromClients[tag.label] < clientsSelected.length
                        }
                        onChange={(e) => {
                          onCheckboxChange(e.target.checked, tag)
                        }}
                      />
                    }
                  />
                ))
              }
            </FormGroup>
          </div>

          <div
            className='buttons-container'
            style={{ display: isChanged ? 'none' : 'flex' }}
          >
            <Button
              size='small'
              onClick={() => { }}
            >
              <Typography fontWeight={700} variant='caption'>
                Gerenciar tags
              </Typography>
            </Button>

            <Button
              variant='contained'
              size='small'
              onClick={onChangeCreateModal}
            >
              <Typography fontWeight={700} variant='caption'>
                Criar nova
              </Typography>
            </Button>
          </div>

          <div
            className='buttons-container'
            style={{ display: isChanged ? 'flex' : 'none' }}
          >
            <Button
              size='small'
              variant='contained'
              fullWidth
              onClick={async () => {
                onButtonClick();
                await onSubmitEditHandler()
              }}
            >
              <Typography fontWeight={700} variant='caption'>
                Aplicar
              </Typography>
            </Button>
          </div>

        </div>
      </Popover>

      <ModalCreateTag
        loading={false}
        show={showCreateModal}
        onChangeOpen={onChangeCreateModal}
        onSubmit={async (values) => {
          onChangeCreateModal();
          await onSubmitCreate(values);
        }}
      />
    </div>
  )
}

export { BulkClientsTags };