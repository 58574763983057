import './styles.scss'
import useViewModel from './viewModel';
import {Form} from "react-final-form";
import {
    Alert,
    Button,
    CircularProgress,
    Snackbar,
    Typography
} from "@mui/material";
import {Flexbox} from "../../../../components/Flexbox";
import {TextFieldAdapter} from "../../../../components/FormComponents/Fields/TextFieldAdapter";
import {CpfFieldAdapter} from "../../../../components/FormComponents/Fields/CpfFieldAdapter";
import {CnpjFieldAdapter} from "../../../../components/FormComponents/Fields/CnpjFieldAdapter";
import {CheckboxFieldAdapter} from "../../../../components/FormComponents/Fields/CheckboxFieldAdapter";
import {CepFieldAdapter} from "../../../../components/FormComponents/Fields/CepFieldAdapter";
import {SelectFieldAdapter} from "../../../../components/FormComponents/Fields/SelectFieldAdapter";
import {AutocompleteAdapter} from "../../../../components/FormComponents/Fields/AutocompleteAdapter";
import {TelephoneFieldAdapter} from "../../../../components/FormComponents/Fields/TelephoneFieldAdapter";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import {RadioFieldAdapter} from "../../../../components/FormComponents/Fields/RadioFieldAdapter";

type CustomerStepPropTypes = {
    productHash: string,
    onBack: () => void
    onNext: () => void
}

function CustomerStep({
    productHash,
    onBack,
    onNext
} : CustomerStepPropTypes) {
    const {
        clientLoading,
        clientLoadingError,
        searchCityValue,
        cityOptions,
        states,
        customerTypeOptions,
        captcha,
        openSnackbar,
        formattedCheckoutOrderPayload,
        formValidate,
        onStateChange,
        onChangeCaptcha,
        onCloseSnackbar,
        onSubmit
    } = useViewModel({productHash, onBack, onNext})

    return <>
    <Form
        onSubmit={onSubmit}
        validate={formValidate}
        initialValues={formattedCheckoutOrderPayload}
        render={({ values, handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>

                <div className='customer-type-container'>
                    <RadioFieldAdapter
                        label='Tipo de pessoa'
                        name='type'
                        options={customerTypeOptions}
                        row={true}
                        defaultValue='brlCompanyRegime'
                    />
                </div>

                <div className='section-title'>
                    <Typography variant='h3' fontWeight={700} color='primary'>Informações básicas</Typography>
                </div>

                {
                    values.type === 'brlPeopleRegime' &&
                    <Flexbox size={12}>
                        <Flexbox size={6}>
                            <TextFieldAdapter
                                label={'Nome'}
                                name='first_name'
                                disabled={clientLoading}
                            />
                        </Flexbox>

                        <Flexbox size={6}>
                            <TextFieldAdapter
                                label={'Sobrenome'}
                                name='last_name'
                                disabled={clientLoading}
                            />
                        </Flexbox>

                    </Flexbox>
                }

                {
                    values.type === 'brlCompanyRegime' &&
                    <Flexbox size={12}>
                        <TextFieldAdapter
                            label={'Razão Social'}
                            name='regime.razao_social'
                            disabled={clientLoading}
                        />
                    </Flexbox>
                }

                <Flexbox size={12}>
                    {values.type === 'brlCompanyRegime' &&
                        <Flexbox size={6}>
                            <TextFieldAdapter
                                label={'Nome'}
                                name='name'
                                disabled={clientLoading}
                            />
                        </Flexbox>
                    }

                    <Flexbox size={6}>
                        {
                            values.type === 'brlPeopleRegime' && <CpfFieldAdapter
                                name='document'
                                label='CPF'
                                disabled={clientLoading}/>
                        }

                        {
                            values.type === 'brlCompanyRegime' && <CnpjFieldAdapter
                                name='document'
                                label='CNPJ'
                                disabled={clientLoading}/>
                        }
                    </Flexbox>
                </Flexbox>

                {values.type === 'brlCompanyRegime' &&
                    <Flexbox size={12}>
                        <Flexbox size={6}>
                            <CheckboxFieldAdapter
                                label=''
                                name='regime.optante_simples_nacional'
                                options={[{label: 'Optante pelo simples nacional', value: '1'}]}
                                disabled={clientLoading}
                            />
                        </Flexbox>
                    </Flexbox>
                }

                <div className='section-title'>
                    <Typography variant='h3' fontWeight={700} color='primary'>Informações para contato</Typography>
                </div>

                <Flexbox size={12}>
                    <Flexbox size={4}>
                        <TextFieldAdapter
                            name='email'
                            label={'Email'}
                            disabled={clientLoading}
                        />
                    </Flexbox>
                    <Flexbox size={4}>
                        <TelephoneFieldAdapter
                            label='Telefone'
                            name='phone'
                            disabled={clientLoading}
                        />
                    </Flexbox>
                </Flexbox>

                <div className='section-title'>
                    <Typography variant='h3' fontWeight={700} color='primary'>Endereço</Typography>
                </div>

                <Flexbox size={12}>
                    <Flexbox size={4}>

                        <CepFieldAdapter
                            label={'CEP'}
                            name='address.postal_code'
                            disabled={clientLoading}
                        />


                    </Flexbox>
                    <Flexbox size={8}>
                        <TextFieldAdapter
                            label={'Logradouro'}
                            name='address.street'
                            disabled={clientLoading}
                        />
                    </Flexbox>
                </Flexbox>

                <Flexbox size={12}>
                    <Flexbox size={4}>
                        <TextFieldAdapter
                            label={'Número'}
                            name='address.number'
                            disabled={clientLoading}
                        />
                    </Flexbox>
                    <Flexbox size={4}>
                        <TextFieldAdapter
                            label={'Bairro'}
                            name='address.neighbourhood'
                            disabled={clientLoading}
                        />
                    </Flexbox>
                    <Flexbox size={4}>
                        <TextFieldAdapter
                            label={'Complemento'}
                            name='address.complement'
                            disabled={clientLoading}
                        />
                    </Flexbox>
                </Flexbox>


                <Flexbox size={12}>
                    <Flexbox size={4}>
                        <SelectFieldAdapter
                            disabled={clientLoading}
                            label={'Estado'}
                            name='address.state_abbreviation'
                            options={states}
                            onChange={(e) => {
                                onStateChange(e, form)
                            }}
                        />
                    </Flexbox>
                    <Flexbox size={8}>
                        <AutocompleteAdapter
                            label={'Cidade'}
                            name='address.city'
                            disabled={clientLoading}
                            options={cityOptions}
                            value={searchCityValue}
                            searchLoading={false}
                            onChange={() => {}}
                        />
                    </Flexbox>
                </Flexbox>

                <div className='captcha'>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_KEY ?? ''}
                        onChange={(value:any) => { onChangeCaptcha(value)  }}
                    />
                </div>

                <div className='footer'>
                    {clientLoading && <CircularProgress color='primary' size={30} />}
                    <Button variant='outlined' onClick={onBack} disabled={clientLoading}>Voltar</Button>
                    <Button variant='contained' type='submit' disabled={clientLoading || !captcha}>Próximo</Button>
                </div>
            </form>
            )}
        />

        {clientLoadingError &&
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
                <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
                    Ops! Algo deu errado, tente de novo mais tarde.
                </Alert>
            </Snackbar>
        }
    </>
}

export {CustomerStep }