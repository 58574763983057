import useViewModel from './viewModel'
import {PlanType} from './services'
import {FormControlLabel, FormGroup, Typography} from "@mui/material";
import '../../styles.scss'
import Checkbox from "@mui/material/Checkbox";
import {DisplayInfoTextOpenNewTab} from "../../../../../../../../../../components/Info/DisplayInfoTextOpenNewTab";
import {DisplayInfoCopy} from "../../../../../../../../../../components/Info/DisplayInfoCopy";

type PlanViewCheckoutViewPropTypes = {
    planData?: PlanType
}

function PlanCheckoutView({planData}: PlanViewCheckoutViewPropTypes) {
  const {
      onChangeCreditCard,
      onChangeBoleto,
      onChangePix,
      formattedPlan
  } = useViewModel(planData)

  return (
      <div className='view-tab'>
          <div className='segment-info'>
              <Typography variant='h4' fontWeight={700} className='general-subtitle'>Métodos de pagamento
                  disponíveis</Typography>

              <div className='info-row'>
                  <FormGroup>
                      <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => {onChangeBoleto(e.target.checked)}} />} label='Boleto' />
                      <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => {onChangeCreditCard(e.target.checked)}}/>} label='Cartão de crédito' />
                      <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => {onChangePix(e.target.checked)}}/>} label="Pix" />
                  </FormGroup>
              </div>
          </div>

          <div className='segment-info'>
              <Typography variant='h4' fontWeight={700} className='general-subtitle'>Link para produto</Typography>

              <div className='info-row link-row'>
                  <DisplayInfoCopy
                      title=''
                      text={formattedPlan?.link ?? ''}
                  />

                  <div className='open-new-tab'>
                    <DisplayInfoTextOpenNewTab
                        title=''
                        texts={['Visualizar']}
                        links={[formattedPlan?.link ?? '']}
                    />
                  </div>
              </div>
          </div>

          <div className='segment-info'>
              <Typography variant='h4' fontWeight={700} className='general-subtitle'>Código HTML para adicionar em seu próprio site</Typography>

              <div className='info-row'>
                  <DisplayInfoCopy
                      title=''
                      text={formattedPlan?.iframe ?? ''}
                  />
              </div>
          </div>
      </div>
  );
}

export {PlanCheckoutView}