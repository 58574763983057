import { Typography } from '@mui/material'

import { ApiErrorView } from 'components/ApiErrorView'
import { Flexbox } from 'components/Flexbox'
import { DisplayInfoText } from 'components/Info/DisplayInfoText'
import { DisplayInfoTextOpenNewTab } from 'components/Info/DisplayInfoTextOpenNewTab'

import useViewModel from './viewModel'
import { DisplayInfoTextMultLine } from 'components/Info/DisplayInfoTextMultLine'

type SaleGeneralViewProps = {
  saleId: string,
  type: string
}

function SaleGeneralView({ saleId, type }: SaleGeneralViewProps) {
  const {
    formattedDetails,
    detailsLoading,
    detailsLoadingError
  } = useViewModel({ saleId })

  return (
    <div className='details'>

      {detailsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os detalhes da venda. Tente recarregar a página.' />
      }

      {!detailsLoadingError && (detailsLoading || formattedDetails !== undefined) &&
        <>
          <div className='basic-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Status'
                  text={formattedDetails?.status ? formattedDetails.status : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoTextOpenNewTab
                  isLoading={detailsLoading}
                  title='Produto'
                  texts={[formattedDetails?.product_name ? formattedDetails.product_name : '']}
                  links={[`/products/${type}/${formattedDetails?.product_id}`]}
                />
              </Flexbox>
              {type === 'recurring' &&
                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Plano'
                    text={formattedDetails?.plan_name ? formattedDetails.plan_name : ''}
                  />
                </Flexbox>
              }
            </div>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoTextOpenNewTab
                  isLoading={detailsLoading}
                  title='Cliente'
                  texts={[formattedDetails?.customer_name ? formattedDetails.customer_name : '']}
                  links={[`/products/${formattedDetails?.customer_id}`]}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Chave'
                  text={formattedDetails?.key ? formattedDetails.key : ''}
                />
              </Flexbox>
            </div>
          </div>

          <div className='billing-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações de contrato</Typography>

            {type === 'recurring' && formattedDetails?.installments.length !== 0 &&
              <>
                <div className='info-row'>
                  <Flexbox size={6}>
                    <DisplayInfoTextMultLine
                      isLoading={detailsLoading}
                      title='Parcelas'
                      text={formattedDetails?.installments ? formattedDetails.installments : []}
                    />
                  </Flexbox>
                </div>

                <div className='info-row'>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Data de início do contrato'
                      text={formattedDetails?.start_date ? formattedDetails.start_date : ''}
                    />
                  </Flexbox>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Duração do contrato'
                      text={formattedDetails?.initial_term ? formattedDetails.initial_term : ''}
                    />
                  </Flexbox>
                </div>

                <div className='info-row'>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Renovação automática'
                    text={formattedDetails?.auto_renewal ? formattedDetails.auto_renewal : ''}
                  />
                </div>
              </>
            }

            {type === 'recurring' && formattedDetails?.installments.length === 0 &&
              <>
                <div className='info-row'>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Valor'
                      text={formattedDetails?.value ? formattedDetails.value : ''}
                    />
                  </Flexbox>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Frequência'
                      text={formattedDetails?.frequency ? formattedDetails.frequency : ''}
                    />
                  </Flexbox>
                </div>

                <div className='info-row'>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Data de início do contrato'
                      text={formattedDetails?.start_date ? formattedDetails.start_date : ''}
                    />
                  </Flexbox>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Data de fim do contrato'
                      text={formattedDetails?.end_date ? formattedDetails.end_date : ''}
                    />
                  </Flexbox>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Duração do contrato'
                      text={formattedDetails?.initial_term ? formattedDetails.initial_term : ''}
                    />
                  </Flexbox>
                </div>

                <div className='info-row'>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Renovação automática'
                    text={formattedDetails?.auto_renewal ? formattedDetails.auto_renewal : ''}
                  />
                </div>
              </>
            }

            {type === 'spot' && formattedDetails?.installments.length !== 0 &&
              <>
                <div className='info-row'>
                  <Flexbox size={6}>
                    <DisplayInfoTextMultLine
                      isLoading={detailsLoading}
                      title='Parcelas'
                      text={formattedDetails?.installments ? formattedDetails.installments : []}
                    />
                  </Flexbox>
                </div>
                <div className='info-row'>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Data de contrato'
                      text={formattedDetails?.start_date ? formattedDetails.start_date : ''}
                    />
                  </Flexbox>
                </div>
              </>
            }

            {type === 'spot' && formattedDetails?.installments.length === 0 &&
              <>
                <div className='info-row'>
                  <Flexbox size={6}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Valor'
                      text={formattedDetails?.value ? formattedDetails.value : ''}
                    />
                  </Flexbox>
                </div>
                <div className='info-row'>
                  <Flexbox size={4}>
                    <DisplayInfoText
                      isLoading={detailsLoading}
                      title='Data de contrato'
                      text={formattedDetails?.start_date ? formattedDetails.start_date : ''}
                    />
                  </Flexbox>
                </div>
              </>
            }
          </div>

          <div className='payment-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações para cobrança</Typography>

            <div className='info-row'>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Método de pagamento'
                text={formattedDetails?.payment_method ? formattedDetails.payment_method : ''}
              />
            </div>

            {type === 'recurring' && formattedDetails?.installments.length === 0 && <>
              <div className='info-row'>
                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Data/Prazo de vencimento da primeira cobrança'
                    text={formattedDetails?.first_payment_term ? formattedDetails.first_payment_term : ''}
                  />
                </Flexbox>

                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Data/Prazo de vencimento das demais cobranças'
                    text={formattedDetails?.payment_term ? formattedDetails.payment_term : ''}
                  />
                </Flexbox>

                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Próxima cobrança'
                    text={formattedDetails?.next_charge_date ? formattedDetails.next_charge_date : ''}
                  />
                </Flexbox>
              </div>
            </>}

          </div>

          <div className='tax-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações Fiscais</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='ISS na cobrança'
                  text={formattedDetails?.iss_rate ? formattedDetails.iss_rate : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='ISS na NF'
                  text={formattedDetails?.iss_rate_in_receipt ? formattedDetails.iss_rate_in_receipt : ''}
                />
              </Flexbox>
            </div>

            <div className='info-row'>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Emissão de NF'
                text={formattedDetails?.emission_receipt ? formattedDetails.emission_receipt : ''}
              />
            </div>

            <div className='info-row'>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Descrição para cobrança e NF'
                text={formattedDetails?.description ? formattedDetails.description : ''}
              />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export { SaleGeneralView }