import { CustomError, api } from 'utils/axios';

const postCancel = async (id: string): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/sales/${id}/cancel`, {})

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postCancel }