import { useState } from 'react'

import { postRetry } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

type AccountsReceivablesType = {
  id: string,
  key: string,
}

type ViewModelPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function RetryARViewModel({ accountReceivableData, onClose, onSubmit }: ViewModelPropTypes) {
  const [ARLoading, setARLoading] = useState(false)
  const [ARError, setARError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onPostRetryHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setARError(true)
      setOpenSnackbar(true)
    } else {
      setARError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async () => {
    setARLoading(true)

    const response = await postRetry(accountReceivableData.id)

    if (response.errors)
      onPostRetryHandleError(response.errors)

    else {
      setARError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setARLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    ARError,
    ARLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { AccountsReceivablesType }