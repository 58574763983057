import { useState } from "react";

import { useOpenedFilters } from '../../../recoil/openedFilters/actions';

type ViewModelParamsTypes = {
  id: string,
  initialSelected?: Array<string>
}

export default function StatusFilterViewModel({ id, initialSelected }: ViewModelParamsTypes) {
  const [optionsSelected, setOptionsSelected] = useState<Array<string>>(initialSelected ? initialSelected : []);

  const { openedFilter, onCloseFilter, onOpenFilter } = useOpenedFilters();

  const isOpen = openedFilter === id;

  const onClick = () => {
    if (isOpen)
      onCloseFilter();
    else
      onOpenFilter(id)
  };

  const onChange = (value: string) => {
    if (optionsSelected.includes(value))
      setOptionsSelected(optionsSelected.filter(option => option !== value))

    else {
      optionsSelected.push(value);
      setOptionsSelected([...optionsSelected]);
    }
  };

  const onClearFilter = () => {
    setOptionsSelected([]);
  }

  return {
    isOpen,
    optionsSelected,
    openedFilter,
    onChange,
    onClearFilter,
    onClick,
    onCloseFilter,
    onOpenFilter,
  };
}