import { Skeleton, Typography } from "@mui/material";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import './styles.scss'

type DisplayInfoTextPropTypes = {
  title: string,
  texts: Array<string>,
  links: Array<string>
  isLoading?: boolean
}

function DisplayInfoTextOpenNewTab({ title, texts, links, isLoading = false }: DisplayInfoTextPropTypes) {
  return (
    <div className='display-info-text'>
      <div className='title'>
        <Typography variant='caption'>
          {title}
        </Typography>
      </div>
      <div className='container-text'>
        {isLoading ? (
          <Skeleton width={100} />
        ) :
          <div className='text'>
            <Typography fontWeight={700}>
              {texts.map((text, index) => (<span key={index}><a href={links[index]} target='_blank' rel='noreferrer'>{text}</a>{index === (links.length - 1) ? '' : ', '}</span>))}
            </Typography>
          </div>
        }
        {!isLoading && <OpenInNewIcon fontSize="small" className="icon hide-icon" />}
      </div>
    </div>
  );
}

export { DisplayInfoTextOpenNewTab }