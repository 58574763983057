import {useEffect, useState} from "react";
import {formatForInput} from "../../../../utils/formatConstantForInput";
import {STATES} from "../../../../utils/constants/states";
import CITIES from "../../../../utils/constants/cities";
import {SelectChangeEvent} from "@mui/material";
import {FormApi, ValidationErrors} from "final-form";
import {postClient, formatCheckoutCustomerPayloadToDisplay, bundlePayloadToRecoil, ClientPayload} from "./services";
import {ApiCheckoutError} from "../../../../utils/CustomError";
import { useCreateCheckoutOrderActions } from 'recoil/checkout/actions';

type viewModelPropTypes = {
    productHash: string,
    onBack: () => void
    onNext: () => void
}

export default function CustomerStepViewModel({productHash, onBack, onNext} : viewModelPropTypes) {

    const { checkoutOrderPayload, setCheckoutOrderPayload} = useCreateCheckoutOrderActions()
    const [formattedCheckoutOrderPayload, setFormattedCheckoutOrderPayload] = useState<any>()

    const customerTypeOptions = [
        { label: 'Pessoa Jurídica', value: 'brlCompanyRegime' },
        { label: 'Pessoa Física', value: 'brlPeopleRegime' },
    ]

    const states = formatForInput(STATES)

    const [clientLoading, setClientLoading] = useState(false)
    const [clientLoadingError, setClientLoadingError] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [cityOptions, setCityOptions] = useState([])
    const [searchCityValue, setSearchCityValue] = useState<string>('')

    const [captcha, setCaptcha] = useState<string | null>(null)

    useEffect(() => {
        setFormattedCheckoutOrderPayload(formatCheckoutCustomerPayloadToDisplay(checkoutOrderPayload))
    }, [checkoutOrderPayload])

    useEffect(() => {

    }, [captcha, openSnackbar])

    const onStateChange = (e: SelectChangeEvent, form: FormApi) => {
        setSearchCityValue('')
        const fieldCity = form.getFieldState('address.city')
        fieldCity?.change(null)

        const filteredCities = CITIES.ALL_CITIES
            .filter((city:any) => (city.uf === e.target.value))
            .map((city:any) => ({
                label: city.name,
                value: city.code
            }))

        setCityOptions(filteredCities)
    }

    const onChangeCaptcha = (value: string) => {
        setCaptcha(value)
    }

    const onPostClientHandleError = (errors: ApiCheckoutError) => {
        setClientLoadingError(true)
        setOpenSnackbar(true)
    }

    const onSubmit = async (values: Record<string, any>) => {
        setClientLoading(true);
        setCheckoutOrderPayload({ ...checkoutOrderPayload, ...bundlePayloadToRecoil(values, captcha ?? '') })
        const response = await postClient(values as ClientPayload, productHash, captcha)

        if (response.errors)
            onPostClientHandleError(response.errors)
        else {
            setCheckoutOrderPayload({ ...checkoutOrderPayload, ...bundlePayloadToRecoil(values, captcha ?? '', response.data.hash ?? '') })
            onNext()
            setOpenSnackbar(false)
            setClientLoadingError(false)
        }

        setClientLoading(false);
    }

    const onCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const formValidate = (values: any) => {
        const errors: ValidationErrors = {
            address: {},
            regime: {}
        };

        const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
        const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/
        const cepRegex = /\d{5}-\d{3}/


        if (values.type === 'brlPeopleRegime') {
            if (!values.first_name)
                errors.first_name = 'Obrigatório'

            if (!values.last_name)
                errors.last_name = 'Obrigatório'
        } else {
            if (!values.name)
                errors.name = 'Obrigatório'
        }

        if (!values.document)
            errors.document = 'Obrigatório'
        else {
            if (values.type === 'brlPeopleRegime' && !cpfRegex.test(values.document))
                errors.document = 'CPF inválido'
            else if (values.type === 'brlCompanyRegime' && !cnpjRegex.test(values.document))
                errors.document = 'CNPJ inválido'
        }

        if (values.type === 'brlCompanyRegime') {
            if (!values.regime?.razao_social)
                errors.regime.razao_social = 'Obrigatório'
        }

        if (!values.email)
            errors.email = 'Obrigatório'
        else {
            if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email))
                errors.email = 'Existem emails inválidos'
        }

        if (!values.phone) {
            errors.phone = 'Obrigatório'
        } else {
            const onlyNumber = values.phone.replace(/\D/g, '');
            if (!/^(\d{2}|\(\d{2}\)) ?[0-9]{4,5}(-| )?[0-9]{4}$/.test(onlyNumber))
                errors.phone = 'Existem telefones inválidos. O número deve ser (xx) 9xxxx-xxxx ou (xx) xxxx-xxxx'
        }

        if (!values.address?.postal_code)
            errors.address.postal_code = 'Obrigatório'
        else if (!cepRegex.test(values.address?.postal_code))
            errors.address.postal_code = 'CEP inválido'

        if (!values.address?.street)
            errors.address.street = 'Obrigatório'

        if (!values.address?.number)
            errors.address.number = 'Obrigatório'

        if (!values.address?.neighbourhood)
            errors.address.neighbourhood = 'Obrigatório'

        if (!values.address?.state_abbreviation)
            errors.address.state_abbreviation = 'Obrigatório'

        if (!values.address?.city)
            errors.address.city = 'Obrigatório'

        return errors;
    }

    return {
        clientLoading,
        clientLoadingError,
        searchCityValue,
        cityOptions,
        states,
        customerTypeOptions,
        captcha,
        formattedCheckoutOrderPayload,
        openSnackbar,
        formValidate,
        onStateChange,
        onChangeCaptcha,
        onCloseSnackbar,
        onSubmit
    }
}