import { CustomError, api } from 'utils/axios';

type PlanType = {
  id: string,
  name: string,
  hash: string,
  freq: string,
  initial_term: number,
  value: number,
  payment_term_type: string,
  payment_term_value: number
}

type PlanTypeToDisplay = {
  name: string
}

const parsePlan = (response: { data: any}) => {
  return {
    id: response.data.id,
    name: response.data.attributes.name,
    hash: response.data.attributes.checkout_product_hash,
    freq: response.data.attributes.freq,
    initial_term: response.data.attributes.initial_term,
    value: response.data.attributes.value.value,
    payment_term_type: response.data.attributes.payment_term_type,
    payment_term_value: response.data.attributes.payment_term_value
  }
}

const getPlan = async (productId: string, planId: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/products/${productId}/plans/${planId}`, { signal })

    return parsePlan(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatPlanToDisplay = (plan: PlanType): PlanTypeToDisplay => {
  return {
    name: plan.name,
  }
}

export { getPlan, formatPlanToDisplay };

export type { PlanType, PlanTypeToDisplay }