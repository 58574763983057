import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';
import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';
import { PercentFieldAdapter } from 'components/FormComponents/Fields/PercentFieldAdapter';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss'

type CreateCouponModalPropTypes = {
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function CreateCouponModal({ open, onClose, onSubmit }: CreateCouponModalPropTypes) {
  const {
    createLoading,
    createError,
    formError,
    openSnackbar,
    typeList,
    valueType,
    onTypeChange,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='create-coupon-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Criar Cupom
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>
                  <Flexbox size={12}>
                    <Flexbox size={6}>
                      <TextFieldAdapter
                        label={'Nome'}
                        name='name'
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={6}>
                      <TextFieldAdapter
                        label={'Código'}
                        name='code'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  </Flexbox>

                  <Flexbox size={12}>
                    <Flexbox size={6}>
                      <SelectFieldAdapter
                        label='Tipo'
                        name='type'
                        options={typeList}
                        onChange={(e) => {
                          onTypeChange(e, form)
                        }}
                      />
                    </Flexbox>
                    {valueType === 'FIXED' ? (
                      <Flexbox size={6}>
                        <CurrencyFieldAdapter
                          label={'Valor'}
                          name='value'
                          disabled={createLoading}
                        />
                      </Flexbox>
                    ) : (
                      <Flexbox size={6}>
                        <PercentFieldAdapter
                          label='Valor'
                          name='value'
                          disabled={createLoading}
                        />
                      </Flexbox>
                    )}
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained'>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(createError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!createError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Cupom criado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { CreateCouponModal }