import { Typography } from '@mui/material';

import useViewModel from './viewModel';

import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';
import { TagListTable } from 'components/Tables/TagListTable';

function Tags() {
  const {
    page,
    optionsRowsPerPage,
    rowsPerPage,
    sort,
    tableHeadCells,
    tagLoading,
    tagLoadingError,
    tags,
    totalTags,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel();

  return (
    <div className='listing-page customers-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Tags
          </Typography>
        </div>
      </div>

      {!tagLoading &&
        !tagLoadingError &&
        tags?.length === 0 &&
        <EmptyView title={'Nenhuma tag encontrada para mostrar aqui'}></EmptyView>
      }

      {tagLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as tags. Tente recarregar a página.' />
      }

      {(tagLoading || (tags !== undefined && tags?.length > 0)) &&
        <TagListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={tags ? tags : []}
          rowsPerPage={rowsPerPage}
          total={totalTags}
          isLoading={tagLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />}
    </div>
  );
}

export { Tags };