import useViewModel from './viewModel';

import { ApiErrorView } from 'components/ApiErrorView';
import { CashierStatementListTable } from 'components/Tables/CashierStatementListTable';
import { EmptyView } from 'components/EmptyView';
import { Typography } from '@mui/material';
import { SearchFilter } from 'components/Filters/SearchFilter';
import { DateRangeFilter } from 'components/Filters/DateRangeFilter';
import { SearchCheckboxFilter } from 'components/Filters/SearchCheckboxFilter';
import { SimpleRangeFilter } from 'components/Filters/SimpleRangeFilter';
import { Totals } from 'components/Totals';

function CashierStatement() {
  const {
    banks,
    cashiersLoading,
    cashiersLoadingError,
    effectiveDateFilter,
    formattedCashiers,
    formattedTotalizers,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    searchBanks,
    sort,
    tableHeadCells,
    totalCashiers,
    applyBankFilter,
    applyEffectiveDateFilter,
    applyValueFilter,
    onBanksSearchChange,
    onDescriptionSearchChange,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel();

  return (
    <div className='listing-page cashier-statement-listing-page'>
      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Extratos
          </Typography>
        </div>
      </div>

      <div className='totals-container'>
        <Totals
          title='Saldo inicial'
          caption={formattedTotalizers?.period_start}
          value={formattedTotalizers?.initial_balance ? formattedTotalizers.initial_balance : ''}
          isLoading={cashiersLoading}
        />

        <Totals
          title='Entradas'
          caption={totalCashiers ? `+${totalCashiers} movimentações` : undefined}
          value={formattedTotalizers?.cash_inflow ? formattedTotalizers.cash_inflow : ''}
          isLoading={cashiersLoading}
        />

        <Totals
          title='Saldo final'
          caption={formattedTotalizers?.period_end}
          value={formattedTotalizers?.final_balance ? formattedTotalizers.final_balance : ''}
          isLoading={cashiersLoading}
        />

      </div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por descrição'
            onSubmit={onDescriptionSearchChange}
          />
        </div>

        <div className='filters'>
          <DateRangeFilter
            id='effective-date'
            buttonLabel='Data'
            initialStartDate={effectiveDateFilter[0].value}
            initialEndDate={effectiveDateFilter[1].value}
            onApplyFilter={applyEffectiveDateFilter}
          />

          <SearchCheckboxFilter
            id='banks'
            buttonLabel='Conta'
            options={banks ? banks : []}
            onApplyFilter={applyBankFilter}
            onSearchChange={onBanksSearchChange}
            search={searchBanks}
          />

          <SimpleRangeFilter
            id='value'
            buttonLabel='Valor'
            onApplyFilter={applyValueFilter}
          />
        </div>
      </div>

      {!cashiersLoading &&
        !cashiersLoadingError &&
        formattedCashiers?.length === 0 &&
        <EmptyView title={'Nenhum extrato encontrado para mostrar aqui'} />
      }

      {cashiersLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os extratos. Tente recarregar a página.' />
      }

      {(cashiersLoading || (formattedCashiers !== undefined && formattedCashiers.length > 0)) &&
        <CashierStatementListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rowLink='/cashier-statements'
          rows={formattedCashiers ? formattedCashiers : []}
          rowsPerPage={rowsPerPage}
          total={totalCashiers}
          isLoading={cashiersLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      }

    </div>
  )
}

export { CashierStatement };