import { useRecoilState } from 'recoil';

import { snackbarAtom } from './states';

function useSnackbar() {
  const [snackbar, setSnackbar] = useRecoilState(snackbarAtom);

  const onClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  const openSnackbar = (message: string) => {
    setSnackbar({
      ...snackbar,
      open: true,
      message,
      onClose
    })
  }

  return { snackbar, openSnackbar }
}

export { useSnackbar }