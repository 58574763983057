import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { InvoicesDisplayType, InvoicesType, SortType, formatInvoicesToDisplay, getInvoices } from './services';
import { ApiError } from 'utils/CustomError';

type ViewModelPropTypes = {
  clientId: string
}

export default function InvoicesViewModel({ clientId }: ViewModelPropTypes) {
  const [invoices, setInvoices] = useState<InvoicesType[]>();
  const [formattedInvoices, setFormattedInvoices] = useState<InvoicesDisplayType[]>()

  //loading states
  const [invoicesLoading, setInvoicesLoading] = useState(true);

  const [invoicesLoadingError, setInvoicesLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalInvoices, setTotalInvoices] = useState(0)

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'total_value', label: 'Valor' },
    { id: 'receipt_number', label: 'Número da nota' },
    { id: 'processed_date', label: 'Emitida em' },
    { id: 'payment_status', label: 'Status de Pgto' },
    { id: 'status', label: 'Status' },
    { id: 'receipt_url', label: '', sortable: false },
  ];


  const onGetInvoicesReceiptsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setInvoicesLoadingError(true)

      setInvoices([]);
      setFormattedInvoices([])
      setTotalInvoices(0);
    } else {
      setInvoicesLoadingError(true)

      setInvoices([]);
      setFormattedInvoices([])
      setTotalInvoices(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setInvoicesLoading(true);

      const response = await getInvoices(
        clientId,
        sort,
        { page, limit: rowsPerPage },
        abortController.signal
      )

      if (response.errors) {
        onGetInvoicesReceiptsHandleError(response.errors)
      }
      else {
        setInvoicesLoadingError(false)
        setInvoices(response.invoices as InvoicesType[])
        setFormattedInvoices(formatInvoicesToDisplay(response.invoices))

        if (response.pagination)
          setTotalInvoices(response.pagination.totalInvoices)
      }

      setInvoicesLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [clientId, page, rowsPerPage, sort])


  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    formattedInvoices,
    invoices,
    invoicesLoading,
    invoicesLoadingError,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalInvoices,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange
  }
}