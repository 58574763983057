import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { RecoilRoot } from 'recoil';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as coreBR } from '@mui/material/locale';

import { GlobalSnackbar } from 'components/GlobalSnackbar';

import Routes from './routes';

import { themeOptions } from './styles/theme';

import './styles/index.scss';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: 0.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={createTheme(themeOptions, ptBR, coreBR)}>
      <LocalizationProvider
        adapterLocale='pt-br'
        dateAdapter={AdapterDayjs}
      >
        <RecoilRoot>
          <GlobalSnackbar>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </GlobalSnackbar>
        </RecoilRoot>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);
