import { useEffect, useState } from 'react';
import { FormApi, ValidationErrors } from 'final-form';

import { useCreateSaleActions } from 'recoil/createSale/actions';

import { notAuthenticatedRedirect } from 'utils/auth';
import { ApiError } from 'utils/CustomError';

import { PaymentMethodsDisplayType, PaymentMethodsType, bundlePayloadToRecoil, formatPaymentMethodToDisplay, getPaymentMethods, formatSalePayloadToDisplay } from './services';

type viewModelPropTypes = {
  onNext: () => void
}

export default function PaymentStepViewModel({ onNext }: viewModelPropTypes) {
  const { salePayload, setSalePayload } = useCreateSaleActions()

  const [formattedSalePayload, setFormattedSalePayload] = useState()

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsType>()
  const [formattedPaymentMethods, setFormattedPaymentMethods] = useState<PaymentMethodsDisplayType>()

  const [paymentLoading, setPaymentLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false);

  const paymentMethodTypeOptions = [
    { label: 'Boleto', value: 'BOLETO' },
    { label: 'Pix', value: 'PIX' },
    { label: 'Cartão', value: 'CREDIT_CARD' },
    { label: 'Depósito Bancário', value: 'BANK_DEPOSIT' },
  ]


  const onGetPaymentMethodsHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setLoadingError(true)

      setPaymentMethods(undefined);
      setFormattedPaymentMethods(undefined);
    } else {
      setLoadingError(true)

      setPaymentMethods(undefined);
      setFormattedPaymentMethods(undefined);
    }
  }

  useEffect(() => {
    (async () => {
      setPaymentLoading(true)

      const response = await getPaymentMethods(salePayload.customer_id ? salePayload.customer_id : '')

      if (response.errors) {
        onGetPaymentMethodsHandleError(response.errors)
      } else {
        setLoadingError(false)
        setPaymentMethods(response)
        setFormattedPaymentMethods(formatPaymentMethodToDisplay(response))

        if (salePayload.payment_method_type) {
          setFormattedSalePayload(formatSalePayloadToDisplay(salePayload))
        }
      }

      setPaymentLoading(false)
    })()
  }, [salePayload])

  const onPaymentMethodTypeChange = (type: string, form: FormApi) => {

    if (type === 'BANK_DEPOSIT' || type === 'CREDIT_CARD')
      form.change('payment_method_id', undefined)

    if (type === 'BOLETO')
      form.change('payment_method_id', paymentMethods ? paymentMethods['BOLETO'][0]?.payment_method_id : null)

    if (type === 'PIX')
      form.change('payment_method_id', paymentMethods ? paymentMethods['PIX'][0]?.payment_method_id : null)
  }

  const onSubmit = (values: Record<string, any>) => {
    setSalePayload({ ...salePayload, ...bundlePayloadToRecoil(values) })
    onNext()
  }

  const formValidate = (values: Record<string, any>) => {
    const errors: ValidationErrors = {}

    if (!values.payment_method_type)
      errors.payment_method_type = 'Obrigatório';

    if (values.payment_method_type !== 'PIX')
      if (!values.payment_method_id)
        errors.payment_method_id = 'Obrigatório';

    return errors;
  }

  return {
    formattedPaymentMethods,
    loadingError,
    paymentLoading,
    paymentMethods,
    paymentMethodTypeOptions,
    salePayload,
    formattedSalePayload,
    formValidate,
    onPaymentMethodTypeChange,
    onSubmit
  }
} 