import dayjs from 'dayjs';
import { CustomError, api } from '../../../../utils/axios';

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type PaginationType = {
  limit: number,
  page: number
}

type ProductsType = {
  id: string,
  name: string,
  status: string,
  sku: string,
  subscriptions_count: number,
  created_at: string,
  updated_at: string,
}

type ProductsDisplayType = {
  id: string,
  name: string,
  status: string,
  sku: string,
  subscriptions_count: number,
  created_at: string,
  updated_at: string,
  actions: ActionType[]
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parseProducts = (response: { data: any, meta: any }) => {
  const products = response.data.map((data: any) => {
    return {
      id: data.id,
      name: data.attributes.name,
      status: data.attributes.status,
      sku: data.attributes.internal_code,
      subscriptions_count: data.attributes.sales,
      created_at: data.attributes.created_at.date,
      updated_at: data.attributes.updated_at.date,
    }
  });

  return {
    products,
    pagination: {
      totalProducts: response.meta.pagination.total
    },
    limits: {
      min: response.meta.aggregate.ranges.min_subscriptions_count,
      max: response.meta.aggregate.ranges.max_subscriptions_count,
    }
  }
}

const getProducts = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (filter.value.length)
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/products-recurring', {
      params,
      signal
    });

    return parseProducts(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatProductsToDisplay = (products: Array<ProductsType>): Array<ProductsDisplayType> => {

  return products.map((product: ProductsType) => {
    let actions: ActionType[];

    if (product.status === 'ACTIVE')
      actions = [
        { id: 'edit', label: 'Editar' },
        { id: 'createPlan', label: 'Criar plano' },
        { id: 'activate', label: 'Ativar', disabled: true },
        { id: 'deactivate', label: 'Desativar' },
      ]
    else
      actions = [
        { id: 'edit', label: 'Editar' },
        { id: 'createPlan', label: 'Criar plano' },
        { id: 'activate', label: 'Ativar' },
        { id: 'deactivate', label: 'Desativar', disabled: true },
      ]

    return {
      id: product.id,
      name: product.name,
      status: product.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
      sku: product.sku ? product.sku : '-',
      subscriptions_count: product.subscriptions_count,
      created_at: dayjs(product.created_at).format('DD/MM/YYYY'),
      updated_at: dayjs(product.updated_at).format('DD/MM/YYYY'),
      actions,
    }
  })

}

export { getProducts, formatProductsToDisplay };

export type { FilterType, PaginationType, ProductsType, ProductsDisplayType, SortType };