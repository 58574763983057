import './styles.scss'
import useViewModel from './viewModel';
import {Alert, Button, Skeleton, Snackbar, Typography} from "@mui/material";
import {Form} from "react-final-form";
import {Flexbox} from "../../../../components/Flexbox";
import {TextFieldAdapter} from "../../../../components/FormComponents/Fields/TextFieldAdapter";
import React from "react";

type ProductStepPropTypes = {
    productHash: string,
    onNext: () => void
}

function ProductStep({
    productHash,
    onNext
}: ProductStepPropTypes) {
    const {
        sales,
        coupons,
        couponsLoading,
        couponsLoadingError,
        products,
        productsLoading,
        productsLoadingError,
        openSnackbar,
        onCloseSnackbar,
        onApplyCoupon,
        onSubmit,
    } = useViewModel({productHash, onNext})

    const couponInput = React.useRef<any>(null);

    return <>
        {!productsLoading && productsLoadingError && <>
            <div className='error-section'>
                <Typography variant='h3' fontWeight={700} color='secundary'>
                    Ops! Não conseguimos carregar os dados do produto.
                </Typography>
                <Typography variant='h3' fontWeight={700} color='secundary'>
                    Tente novamente mais tarde.
                </Typography>
            </div>
        </>}

        {!productsLoadingError && <>
            <div className='product-name-section'>
                {productsLoading && <Skeleton/>}
                {!productsLoading && <>
                    <Typography variant='h3' fontWeight={700} color='primary'>
                        {products?.product_name ?? ''} - {products?.plan_name ?? ''}
                    </Typography>
                </>}
            </div>

            <div className='product-description-section'>
                {productsLoading && <Skeleton/>}
                {!productsLoading && <>
                    {sales?.setupFormatted ? sales?.setupFormatted + ' adesão + ' : ''}
                    {sales?.subtotalFormatted ? sales?.subtotalFormatted + ' fixos' : ''}
                </>}
            </div>

            <Form
                onSubmit={onApplyCoupon}
                initialValues={{
                    coupon: coupons?.code
                }}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <div className='coupon-section'>
                            <span className='title'>Adicionar um cupom de desconto?</span>

                            <div className='input-container'>
                                <Flexbox size={6}>
                                    <TextFieldAdapter
                                        name='coupon'
                                        label='Código de desconto'
                                        type='text'
                                        inputRef={couponInput}
                                        loading={productsLoading || couponsLoading}
                                    />
                                </Flexbox>

                                <Button className='button' disabled={productsLoading || couponsLoading} color='secondary'
                                        variant='outlined' size='small' type='submit'>
                                    Aplicar
                                </Button>
                            </div>
                        </div>

                    </form>
                )}/>

            <div className='total-section'>
                {productsLoading && <Skeleton width={200}/>}
                {productsLoading && <Skeleton width={200}/>}
                {productsLoading && <Skeleton width={200}/>}
                {productsLoading && <Skeleton width={200}/>}
                {!productsLoading && <span className='row'>Subtotal: {sales?.subtotalFormatted ? sales?.subtotalFormatted : 'R$ 0,00'}</span>}
                {!productsLoading && <span className='row'>Adesão: {sales?.setupFormatted ? sales?.setupFormatted : 'R$ 0,00'}</span>}
                {!productsLoading && <span className='row'>Desconto: {sales?.discountFormatted ? sales?.discountFormatted : 'R$ 0,00'}</span>}
                {!productsLoading && <span className='row'>Total: {sales?.totalFormatted ? sales?.totalFormatted : 'R$ 0,00'}</span>}
            </div>

            <div className='footer'>
                <Button variant='contained' disabled={productsLoading || productsLoadingError || couponsLoading} onClick={() => {
                    onSubmit()
                }}>Próximo</Button>
            </div>
        </>}

        {(couponsLoadingError) &&
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
                <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
                    Este cupom não é válido.
                </Alert>
            </Snackbar>
        }
    </>
}

export {ProductStep}