import { CustomError, api } from 'utils/axios'

import { parseToFloat } from 'utils/moneyFormatt'

type CouponPayload = {
  name: string,
  code: string,
  type: string,
  value: string
}

type ParsedCouponPayload = {
  name: string,
  code: string,
  type: string,
  value: number
}

const bundlePayload = (payload: CouponPayload): ParsedCouponPayload => {

  let formattedValue;

  if (payload.type === 'FIXED')
    formattedValue = parseToFloat(payload.value)
  else
    formattedValue = Math.round(parseToFloat(payload.value) * 10000) / 1000000

  return {
    name: payload.name,
    code: payload.code,
    type: payload.type,
    value: formattedValue
  }
}

const postCoupon = async (payload: CouponPayload): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/coupons`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postCoupon }

export type { ParsedCouponPayload, CouponPayload }