type ApiError = {
  code?: number,
  detail?: any,
  status: number,
  type: string
}[]

class CustomError extends Error {
  errors: ApiError

  constructor(message: string, errors: ApiError) {
    super(message)
    this.errors = errors;
  }
}

type ApiCheckoutError = {
  name?: string,
  status?: number,
  message: string
}[]

class CustomCheckoutError extends Error {
  errors: ApiCheckoutError

  constructor(message: string, errors: ApiCheckoutError) {
    super(message)
    this.errors = errors;
  }
}

export { CustomError, CustomCheckoutError }

export type { ApiError, ApiCheckoutError }