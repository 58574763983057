import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { LinearProgress } from '@mui/material';

import useViewModel from './viewModel';

import './styles.scss'

function Home() {
  const {
    name,
    activeCustomerLoading,
    activeCustomerLoadingError,
    formattedActiveCustomer
  } = useViewModel()

  return (
    <div className='home-page'>
      <Typography variant='h1' className='welcome'>
        Bem-vindo, {name}, o que vamos fazer hoje?
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6} minWidth={500}>
          <Paper className='home-section' elevation={3}>
            <Typography className='title' variant='h2'>
              Cadastros
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6} minHeight={60}>
                <Card className='card'>
                  <CardActionArea href='/clients'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-sales.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Clientes
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver todos os clientes cadastrados aqui na plataforma
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={6} minHeight={60}>
                <Card className='card'>
                  <CardActionArea href='/products'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-products.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Produtos
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver o seu catálogo de produtos contendo produtos para vendas recorrentes e pontuais
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6} minWidth={500}>
          <Paper className='home-section' elevation={3}>
            <Typography className='title' variant='h2'>
              Métricas
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              Clientes ativos
            </Typography>

            {activeCustomerLoading && <>
              <LinearProgress color='secondary' />
              <BarChart
                height={160}
                series={[
                  { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                  { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                  { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                  { data: [1, 2, 3, 4, 5, 6].map(() => Math.floor(Math.random() * 100)), color: '#eeeeee' },
                ]}
                xAxis={[{ scaleType: 'band', data: ['-', '-', '-', '-', '-', '-'] }]}
              /></>}

            {!activeCustomerLoading && !activeCustomerLoadingError && formattedActiveCustomer &&
              <BarChart
              height={164}
                series={[
                  { data: formattedActiveCustomer.data, color: '#8FBC8F' }
                ]}
                xAxis={[{ scaleType: 'band', data: formattedActiveCustomer.label }]}
                axisHighlight={{ x: 'band', y: 'line' }}
              />
            }

            {!activeCustomerLoading && activeCustomerLoadingError &&
              <BarChart
              height={164}
                series={[]}
                xAxis={[{ scaleType: 'band', data: ['Ops, não conseguimos carregar esse gráfico'] }]}
              />
            }

            <Button variant='text' size='small' color='secondary' href='/metrics'>
              <Typography fontWeight={700} variant='caption'>
                Ver todas as métricas
              </Typography>
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className='home-section' elevation={3}>
            <Typography className='title' variant='h2'>
              Cobranças
            </Typography>

            <Grid container spacing={2} minWidth={500}>
              <Grid item xs={2}>
                <Card className='card' sx={{ maxWidth: 300, minWidth: 200 }}>
                  <CardActionArea href='/sales'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-sales.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Vendas
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver todas as suas vendas
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={2}>
                <Card className='card' sx={{ maxWidth: 300, minWidth: 200 }}>
                  <CardActionArea href='/invoices-groups'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-invoices.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Faturas
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver todas as suas faturas
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={2}>
                <Card className='card' sx={{ maxWidth: 300, minWidth: 200 }}>
                  <CardActionArea href='/accounts-receivables'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-receivables.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Contas a receber
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver todos os contas a receber
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={2}>
                <Card className='card' sx={{ maxWidth: 300, minWidth: 200 }}>
                  <CardActionArea href='/invoices-receipts'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-invoices.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Notas fiscais
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver todas as notas fiscais
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={2}>
                <Card className='card' sx={{ maxWidth: 300, minWidth: 200 }}>
                  <CardActionArea href='/cashier-statements'>
                    <CardMedia
                      component='img'
                      height='120'
                      image='/public/images/home-receivables.jpg'
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Extratos
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ver os extratos de receitas
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

          </Paper>
        </Grid>

      </Grid>
      
    </div>
  )
}

export { Home };