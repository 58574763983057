import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';

import { getInvoices, formatInvoicesToDisplay, InvoiceGroupType, InvoiceGroupDisplayType, SortType, FilterType } from './services';

export default function InvoiceGroupViewModel() {
  const [invoices, setInvoices] = useState<Array<InvoiceGroupType>>();
  const [formattedInvoices, setFormattedInvoices] = useState<Array<InvoiceGroupDisplayType>>();

  const [searchInvoices, setSearchInvoices] = useState('')

  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [invoicesLoadingError, setInvoicesLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // filters
  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: []
  });

  const [paymentStatusFilter, setPaymentStatusFilter] = useState<FilterType>({
    field: 'payment_status',
    comparator: 'in',
    value: []
  });

  const [receiptStatusFilter, setReceiptStatusFilter] = useState<FilterType>({
    field: 'receipt_status',
    comparator: 'in',
    value: []
  });

  const [dueDateFilter, setDueDateFilter] = useState<FilterType[]>([{
    field: 'due_date',
    comparator: 'gte',
    value: ''
  }, {
    field: 'due_date',
    comparator: 'lte',
    value: ''
  }]);

  const [createdAtFilter, setCreatedAtFilter] = useState<FilterType[]>([{
    field: 'created_at',
    comparator: 'gte',
    value: ''
  }, {
    field: 'created_at',
    comparator: 'lte',
    value: ''
  }]);


  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalInvoices, setTotalInvoices] = useState(0);

  const [actionInvoiceIndex, setActionInvoiceIndex] = useState<number>()

  const [openCancel, setOpenCancel] = useState(false)
  const [openReexecute, setOpenReexecute] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'total', label: 'Valor' },
    { id: 'payment_status', label: 'Pagamento' },
    { id: 'receipt_status', label: 'Nota fiscal' },
    { id: 'created_at', label: 'Gerado em' },
    { id: 'due_date', label: 'Vencimento' },
    { id: 'status', label: 'Status' },
    { id: 'path_to_file', label: '', sortable: false },
  ];

  const statusOptions = [
    { label: 'Rascunhos', value: 'DRAFT' },
    { label: 'Aprovados', value: 'POST' },
    { label: 'Cancelados', value: 'CANCELED' },
    { label: 'Descartados', value: 'DISCARDED' },
    { label: 'Erro', value: 'ERROR' },
    { label: 'Processando', value: 'POSTING' },
  ];

  const paymentStatusOptions = [
    { label: 'Cancelados', value: 'CANCELED' },
    { label: 'Expirados', value: 'EXPIRED' },
    { label: 'Falhou', value: 'FAILED' },
    { label: 'Pagos', value: 'PAID' },
    { label: 'Pendentes', value: 'PENDING' },
  ];

  const receiptStatusOptions = [
    { label: 'Rascunhos', value: 'DRAFT' },
    { label: 'Autorizados', value: 'AUTHORIZED' },
    { label: 'Cancelados', value: 'CANCELLED' },
    { label: 'Cancelando', value: 'CANCELLING' },
    { label: 'Erro', value: 'ERROR' },
    { label: 'Pendentes', value: 'PENDING' },
    { label: 'Processando', value: 'PROCESSING' },
  ];


  const onGetInvoicesHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setInvoicesLoadingError(true)

      setInvoices([]);
      setFormattedInvoices([])
      setTotalInvoices(0);
    } else {
      setInvoicesLoadingError(true)

      setInvoices([]);
      setFormattedInvoices([])
      setTotalInvoices(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setInvoicesLoading(true);

      const response = await getInvoices(
        [
          statusFilter,
          paymentStatusFilter,
          receiptStatusFilter,
          dueDateFilter[0],
          dueDateFilter[1],
          createdAtFilter[0],
          createdAtFilter[1],
        ],
        searchInvoices,
        sort,
        { page, limit: rowsPerPage },
        abortController.signal
      );

      if (response.errors) {
        onGetInvoicesHandleError(response.errors)
      } else {
        setInvoices(response.invoices as Array<InvoiceGroupType>);
        setFormattedInvoices(formatInvoicesToDisplay(response.invoices))

        if (response.pagination)
          setTotalInvoices(response.pagination.totalInvoices);
      }

      setInvoicesLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort();
    }

  }, [createdAtFilter, dueDateFilter, page, paymentStatusFilter, receiptStatusFilter, rowsPerPage, searchInvoices, sort, statusFilter]);


  const applyCreatedAtFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...createdAtFilter];

    newDateFilter[0].value = startDate ? startDate.format('YYYY-MM-DD') : '';
    newDateFilter[1].value = endDate ? endDate.format('YYYY-MM-DD') : '';

    setCreatedAtFilter(newDateFilter);
  }

  const applyDueDateFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...dueDateFilter];

    newDateFilter[0].value = startDate ? startDate.format('YYYY-MM-DD') : '';
    newDateFilter[1].value = endDate ? endDate.format('YYYY-MM-DD') : '';

    setDueDateFilter(newDateFilter);
  }

  const applyPaymentStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setPaymentStatusFilter({ ...paymentStatusFilter, value: optionsSelected });
  }

  const applyReceiptStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setReceiptStatusFilter({ ...receiptStatusFilter, value: optionsSelected });
  }

  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 're-execute':
        setActionInvoiceIndex(index)
        setOpenReexecute(true)
        break;

      case 'cancel':
        setActionInvoiceIndex(index)
        setOpenCancel(true)
        break;
      
      case 'email':
        setActionInvoiceIndex(index)
        setOpenEmail(true)
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseCancel = () => {
    setOpenCancel(false)
  }

  const onCloseReexecute = () => {
    setOpenReexecute(false)
  }

  const onCloseEmail = () => {
    setOpenEmail(false)
  }

  const onInvoiceSearchChange = (search: string) => {
    setPage(1);
    setSearchInvoices(search);
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }


  return {
    actionInvoiceIndex,
    createdAtFilter,
    dueDateFilter,
    invoices,
    invoicesLoading,
    invoicesLoadingError,
    formattedInvoices,
    openCancel,
    openReexecute,
    openEmail,
    optionsRowsPerPage,
    page,
    paymentStatusFilter,
    paymentStatusOptions,
    receiptStatusFilter,
    receiptStatusOptions,
    rowsPerPage,
    searchInvoices,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalInvoices,
    applyCreatedAtFilter,
    applyDueDateFilter,
    applyPaymentStatusFilter,
    applyReceiptStatusFilter,
    applyStatusFilter,
    handleTableRowActions,
    onChangeSort,
    onCloseCancel,
    onCloseReexecute,
    onCloseEmail,
    onInvoiceSearchChange,
    onPageChange,
    onRowsPerPageChange,
  };
}