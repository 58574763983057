import { PlansListTable } from 'components/Tables/PlansListTable'
import { EmptyView } from 'components/EmptyView'
import { EditPlanModal } from 'components/Modals/Plan/Edit'

import useViewModel from './viewModel'

type ProductRecurringPlansViewPropTypes = {
  productId: string
}


function ProductRecurringPlansView({ productId }: ProductRecurringPlansViewPropTypes) {
  const {
    actionPlanId,
    formattedPlans,
    openEdit,
    optionsRowsPerPage,
    page,
    plansLoading,
    rowsPerPage,
    sort,
    tableHeadCells,
    tableRowActions,
    totalPlans,
    handleTableRowActions,
    onChangeSort,
    onCloseEditModal,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ productId })

  return (
    <div className='plans'>
      {!plansLoading &&
        formattedPlans?.length === 0 &&
        <EmptyView title={'Nenhum plano encontrado para mostrar aqui'}></EmptyView>
      }

      {(plansLoading || (formattedPlans !== undefined && formattedPlans.length > 0)) &&
        <>
          <PlansListTable
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rows={formattedPlans ? formattedPlans : []}
            rowsPerPage={rowsPerPage}
            optionsMenu={tableRowActions}
            total={totalPlans}
            isLoading={plansLoading}
            rowLink={'/products/recurring/:product/plans'}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <EditPlanModal
            open={openEdit}
            planId={actionPlanId}
            productId={productId}
            onClose={onCloseEditModal}
          />
        </>
      }
    </div>
  );
}

export { ProductRecurringPlansView }