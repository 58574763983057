import dayjs from 'dayjs';

import { CustomError, api } from '../../utils/axios';
import { formatMoney } from '../../utils/moneyFormatt'

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type InvoicesType = {
  id: string,
  customer_id: string,
  customer_name: string,
  total_value: number,
  receipt_number: string,
  processed_date: string,
  payment_status: string,
  status: string,
  receipt_url: string
}

type InvoicesDisplayType = {
  id: string,
  customer_id: string,
  customer_name: string,
  total_value: string,
  receipt_number: string,
  processed_date: string,
  payment_status: string,
  status: string,
  receipt_url: string,
  actions: ActionType[]
}

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

const parseInvoices = (response: { data: any[], meta: any }) => {
  const invoices = response.data.map(data => {
    return {
      id: data.id,
      customer_id: data.attributes.customer_id,
      customer_name: data.attributes.customer_name,
      total_value: data.attributes.total_value,
      receipt_number: data.attributes.receipt_number,
      processed_date: data.attributes.processed_date?.date,
      payment_status: data.attributes.payment_status,
      status: data.attributes.status,
      receipt_url: data.attributes.receipt_url,
    }
  })

  return {
    invoices,
    pagination: {
      totalInvoices: response.meta.pagination.total
    },
    limits: {
      min: response.meta.aggregate.ranges.min_value,
      max: response.meta.aggregate.ranges.max_value,
    },
    totals: {
      totalValue: response.meta.aggregate.totals.total_value,
      totalNfs: response.meta.aggregate.totals.total_nfs
    }
  }
}

const getInvoices = async (filters: FilterType[], search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (filter.value.length)
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  try {
    const response = await api.get('/api-cashone/invoices-receipts', {
      params,
      signal
    })

    return parseInvoices(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatInvoicesToDisplay = (invoices: InvoicesType[]): InvoicesDisplayType[] => {
  const translateStatus = {
    'DRAFT': 'RASCUNHO',
    'AUTHORIZED': 'AUTORIZADO',
    'CANCELLED': 'CANCELADO',
    'CANCELLING': 'CANCELANDO',
    'ERROR': 'ERRO',
    'PENDING': 'PENDENTE',
    'PROCESSING': 'PROCESSANDO'
  }

  const translatePaymentStatus = {
    'CANCELED': 'Cancelado',
    'EXPIRED': 'Expirado',
    'FAILED': 'Falhou',
    'PAID': 'Pago',
    'PENDING': 'Pendente',
  }

  type StatusEnum = keyof typeof translateStatus;
  type PaymentStatusEnum = keyof typeof translatePaymentStatus

  return invoices.map(invoice => {
    let actions: ActionType[];

    switch (invoice.status) {
      case 'CANCELLED':
        actions = [
          { id: 'send', label: 'Emitir', disabled: true },
          { id: 'reopen', label: 'Reemitir', disabled: false },
          { id: 'cancel', label: 'Cancelar', disabled: true },
        ]
        break;

      case 'ERROR':
      case 'DRAFT':
        actions = [
          { id: 'send', label: 'Emitir', disabled: false },
          { id: 'reopen', label: 'Reemitir', disabled: true },
          { id: 'cancel', label: 'Cancelar', disabled: true },
        ]
        break;

      case 'AUTHORIZED':
        actions = [
          { id: 'send', label: 'Emitir', disabled: true },
          { id: 'reopen', label: 'Reemitir', disabled: true },
          { id: 'cancel', label: 'Cancelar', disabled: false },
          { id: 'email', label: 'Enviar email com nota fiscal', disabled: false },
        ]
        break;

      default:
        actions = [
          { id: 'send', label: 'Emitir', disabled: true },
          { id: 'reopen', label: 'Reemitir', disabled: true },
          { id: 'cancel', label: 'Cancelar', disabled: true },
        ]
        break;
    }

    return {
      id: invoice.id,
      customer_id: invoice.customer_id,
      customer_name: invoice.customer_name,
      total_value: formatMoney(invoice.total_value),
      receipt_number: invoice.receipt_number ? invoice.receipt_number : '-',
      processed_date: invoice.processed_date ? dayjs(invoice.processed_date).format('DD/MM/YYYY') : '-',
      payment_status: translatePaymentStatus[invoice.payment_status as PaymentStatusEnum],
      status: translateStatus[invoice.status as StatusEnum],
      receipt_url: invoice.receipt_url,
      actions
    }
  })
}

const formatTotalsToDisplay = (totalValue: number) => {
  return {
    totalValue: formatMoney(totalValue)
  }
}

export { getInvoices, formatInvoicesToDisplay, formatTotalsToDisplay };

export type { FilterType, InvoicesDisplayType, InvoicesType, PaginationType, SortType };