import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { ApiErrorView } from 'components/ApiErrorView';
import { CepFieldAdapter } from 'components/FormComponents/Fields/CepFieldAdapter';
import { CnpjFieldAdapter } from 'components/FormComponents/Fields/CnpjFieldAdapter';
import { CpfFieldAdapter } from 'components/FormComponents/Fields/CpfFieldAdapter';
import { Flexbox } from 'components/Flexbox'
import { MultipleEmailFieldAdapter } from 'components/FormComponents/Fields/MultipleEmailFieldAdapter';
import { MultipleTelephoneFieldAdapter } from 'components/FormComponents/Fields/MultipleTelephoneFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter'

import useViewModel from './viewModel'

import '../../styles.scss'
import { MultipleTextFieldAdapter } from 'components/FormComponents/Fields/MultipleTextFieldAdapter';

type EditSupplierModalPropTypes = {
  open: boolean,
  supplierId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function EditSupplierModal({ open, supplierId, onClose, onSubmit }: EditSupplierModalPropTypes) {
  const {
    type,
    supplierLoading,
    supplierLoadingError,
    editError,
    formattedSupplier,
    formError,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  } = useViewModel({ supplierId, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={formattedSupplier}
            validate={formValidate}
            render={({ values, handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Editar Fornecedor
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                {!supplierLoadingError &&
                  <div className='modal-body'>
                    <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>

                    <Flexbox size={12}>
                      <Flexbox size={8}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Nome'}
                          name='name'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        {
                          type === 'brlPeopleRegime' && <CpfFieldAdapter
                            loading={supplierLoading}
                            name='document'
                            label='CPF (opcional)'
                          />
                        }

                        {
                          type === 'brlCompanyRegime' && <CnpjFieldAdapter
                            loading={supplierLoading}
                            name='document'
                            label='CNPJ (opcional)'
                          />
                        }

                        {
                          type === 'brlInternationalCustomerRegime' && <TextFieldAdapter
                            loading={supplierLoading}
                            label='Documento (opcional)'
                            name='document'

                          />
                        }
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={12}>
                      <MultipleEmailFieldAdapter
                        name='emails'
                        label={'Emails (opcional)'}
                        loading={supplierLoading}
                      />
                    </Flexbox>

                    <Flexbox size={12}>
                      {
                        type === 'brlInternationalCustomerRegime' &&
                        <MultipleTextFieldAdapter
                          loading={supplierLoading}
                          label='Telefones (opcional)'
                          name='phones'
                        />
                      }

                      {
                        type !== 'brlInternationalCustomerRegime' && <MultipleTelephoneFieldAdapter
                          loading={supplierLoading}
                          name='phones'
                          label={'Telefones (opcional)'}
                        />
                      }
                    </Flexbox>


                    <Typography variant='h3' fontWeight={700} className='section-title'>Endereço (opcional)</Typography>

                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        {
                          type !== 'brlInternationalCustomerRegime' && <CepFieldAdapter
                            loading={supplierLoading}
                            label={'CEP'}
                            name='address.postal_code'
                          />
                        }

                        {
                          type === 'brlInternationalCustomerRegime' && <TextFieldAdapter
                            loading={supplierLoading}
                            label={'CEP'}
                            name='address.postal_code'
                          />
                        }
                      </Flexbox>
                      <Flexbox size={8}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Logradouro'}
                          name='address.street'
                        />
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Número'}
                          name='address.number'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Bairro'}
                          name='address.neighbourhood'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Complemento'}
                          name='address.complement'

                        />
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'País'}
                          name='address.country'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Estado'}
                          name='address.state_province'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={supplierLoading}
                          label={'Cidade'}
                          name='address.city'

                        />
                      </Flexbox>
                    </Flexbox>


                    <Typography variant='h3' fontWeight={700} className='section-title'>Informações adicionais</Typography>

                    <Flexbox size={12}>
                      <TextFieldAdapter
                        loading={supplierLoading}
                        label={'Observações adicionais'}
                        name='description'
                        multiline
                        rows={3}
                      />
                    </Flexbox>

                  </div>
                }

                {supplierLoadingError &&
                  <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as informações do fornecedor.' />
                }


                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={supplierLoadingError ||supplierLoading}>Salvar</Button>
                </div>
              </form>

            )}
          />
        </Box>
      </Modal>

      {(editError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="warning" variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Fornecedor atualizado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { EditSupplierModal }