import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Skeleton } from '@mui/material';

type MultipleTagFieldAdapterProps = {
  name: string,
  label: string,
  loading?: boolean,
  tags: string[]
  validate?: FieldValidator<any>
}

function ComponentField({
  tags,
  label,
  input,
  meta
}: FieldRenderProps<any>) {

  return (
    <Autocomplete
      multiple
      options={tags}
      freeSolo
      fullWidth
      value={input.value ? input.value : []}
      renderTags={(value: readonly string[], getTagProps) => (
        value.map((option: string, index: number) => {
          const color = tags.includes(option) ? 'default' : 'primary'
          return <Chip color={color} label={option} {...getTagProps({ index })} />
        }))
      }
      onChange={(event: any, newValue: string[]) => {
        input.onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={meta.touched && meta.error ? true : false}
          helperText={meta.touched ? meta.error : ''}
          FormHelperTextProps={{ className: 'helper-text' }}
          placeholder='Escolha uma tag ou digite uma nova e pressione ENTER'
        />
      )}
    />
  );
}

function MultipleTagFieldAdapter({ name, label, loading, tags, validate }: MultipleTagFieldAdapterProps) {

  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={ComponentField}
          name={name}
          label={label}
          validate={validate}
          inputName={name}
          tags={tags}
        />
      )}
    </div>
  );
}

export { MultipleTagFieldAdapter };