import { useEffect, useState } from 'react';

import { PlansType, getPlans, SortType, PlansDisplayType, formatPlansToDisplay, PaginationType } from './services';
import { notAuthenticatedRedirect } from 'utils/auth';

type ViewModelProps = {
  productId: string
}

const getPlansServiceHandler = async (id: string, sort: SortType, pagination: PaginationType, signal: AbortSignal) => {
  const response = await getPlans(id, sort, pagination, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect();

    return { plans: [] };
  }

  return response;
}

export default function ProductRecurringGeneralViewModel({ productId }: ViewModelProps) {
  const [plans, setPlans] = useState<PlansType[]>();
  const [formattedPlans, setFormattedPlans] = useState<PlansDisplayType[]>();
  const [plansLoading, setPlansLoading] = useState(true)

  const [openEdit, setOpenEdit] = useState(false)

  const [actionPlanId, setActionPlanId] = useState('')
  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPlans, setTotalPlans] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'name', label: 'Nome' },
    { id: 'value', label: 'Valor' },
    { id: 'freq', label: 'Freq. cobrança' },
    { id: 'initial_term', label: 'Termo de contrato' },
  ];

  const tableRowActions = [
    { id: 'edit', label: 'Editar' },
    { id: 'disable', label: 'Desativar' },
  ]


  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setPlansLoading(true);

      const response = await getPlansServiceHandler(productId, sort, { limit: rowsPerPage, page }, abortController.signal);

      if (response && !abortController.signal.aborted) {
        setPlans(response.plans)
        setFormattedPlans(formatPlansToDisplay(response.plans))

        if (response.pagination)
          setTotalPlans(response.pagination.totalPlans)
      }

      setPlansLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [page, productId, rowsPerPage, sort])


  const handleTableRowActions = (id: string, action: string) => {
    switch (action) {
      case 'edit':
        setActionPlanId(id)
        setOpenEdit(true)
        break;

      case 'disable':
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseEditModal = () => {
    setActionPlanId('')
    setOpenEdit(false)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    actionPlanId,
    formattedPlans,
    openEdit,
    optionsRowsPerPage,
    page,
    plans,
    plansLoading,
    rowsPerPage,
    sort,
    tableHeadCells,
    tableRowActions,
    totalPlans,
    handleTableRowActions,
    onChangeSort,
    onCloseEditModal,
    onPageChange,
    onRowsPerPageChange,
  }
}