import { useState } from 'react';
import dayjs from 'dayjs';

import { useOpenedFilters } from '../../../recoil/openedFilters/actions';

type ViewModelPropTypes = {
  id: string,
  initialStartDate?: string,
  initialEndDate?: string,
  minDate?: string,
  maxDate?: string,
}

export default function DateRangeFilterViewModel({ id, initialStartDate, initialEndDate, minDate, maxDate }: ViewModelPropTypes) {
  const [endDateSelected, setEndDateSelected] = useState<dayjs.Dayjs | null>(initialEndDate ? dayjs(initialEndDate) : null);
  const [startDateSelected, setStartDateSelected] = useState<dayjs.Dayjs | null>(initialStartDate ? dayjs(initialStartDate) : null);

  const minDateSelected = minDate ? dayjs(minDate) : undefined
  const maxDateSelected = maxDate ? dayjs(maxDate) : undefined

  const { openedFilter, onCloseFilter, onOpenFilter } = useOpenedFilters();

  const isOpen = openedFilter === id;

  const onClick = () => {
    if (isOpen)
      onCloseFilter();
    else
      onOpenFilter(id)
  };

  const onEndDateChange = (value: dayjs.Dayjs | null) => {
    setEndDateSelected(value)
  }

  const onStartDateChange = (value: dayjs.Dayjs | null) => {
    setStartDateSelected(value)
  }

  return {
    endDateSelected,
    isOpen,
    startDateSelected,
    openedFilter,
    minDateSelected,
    maxDateSelected,
    onClick,
    onEndDateChange,
    onStartDateChange,
    onCloseFilter,
    onOpenFilter
  }
}