import { CustomError, api } from 'utils/axios';


type DetailsType = {
  id: string,
  name: string,
  document: string,
  status: string,
  regime_type: string,
  tags: string[],
  emails: string[],
  phones: string[],
  description: string,
  details: {
    description: string,
    url: string
  }[],
  address: {
    street: string,
    number: string,
    complement?: string,
    neighbourhood: string,
    city: string,
    postal_code: string,
    state_abbreviation: string,
    country: string,
  },
  regime: {
    razao_social: string,
    inscricao_municipal: string,
    inscricao_estadual: string,
    optante_simples_nacional: number
  },
}

type DetailsDisplayType = {
  id: string,
  name: string,
  document: string,
  status: string,
  regime_type: string,
  tags: string,
  emails: string,
  phones: string,
  description: string,
  details: {
    description: string,
    url: string
  }[],
  address: {
    street: string,
    number: string,
    complement?: string,
    neighbourhood: string,
    city: string,
    postal_code: string,
    state_abbreviation: string,
    country: string,
  },
  regime: {
    razao_social: string,
    inscricao_municipal: string,
    inscricao_estadual: string,
    optante_simples_nacional: string
  },
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    name: response.data.attributes.name,
    document: response.data.attributes.document,
    status: response.data.attributes.status,
    regime_type: response.data.attributes.regime_type,
    tags: response.data.attributes.tags,
    emails: response.data.attributes.emails,
    phones: response.data.attributes.phones,
    description: response.data.attributes.description,
    regime: response.data.attributes.regime,
    address: response.data.attributes.address,
    details: response.data.attributes.details,
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/clients/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  const translateRegimeType = {
    'brlPeopleRegime': 'pf',
    'brlCompanyRegime': 'pj',
    'brlInternationalCustomerRegime': 'estrangeiro',
  }

  type translateEnum = keyof typeof translateRegimeType

  return {
    id: details.id,
    name: details.name,
    document: details.document,
    status: details.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
    regime_type: translateRegimeType[details.regime_type as translateEnum],
    tags: details.tags.length ? details.tags.join(', ') : 'N/A',
    emails: details.emails.join(', '),
    phones: details.phones.length ? details.phones.join(', ') : 'N/A',
    description: details.description,
    regime: {
      ...details.regime,
      optante_simples_nacional: details.regime.optante_simples_nacional ? 'Sim' : 'Não'
    },
    address: {
      ...details.address,
      city: details.address.state_abbreviation ? `${details.address.city} / ${details.address.state_abbreviation}` : details.address.city,
      complement: details.address.complement ? details.address.complement : 'N/A'
    },
    details: details.details
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }