import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import './styles.scss'

type MenuItemPropTypes = {
  icon: ReactElement,
  title: string,
  onClick: () => void
}

function MenuItem({ icon, title, onClick }: MenuItemPropTypes) {
  return (
    <div className='menu-item' onClick={onClick}>
      {icon}
      <Typography className='menu-title'>{title}</Typography>
    </div>
  );
}

export { MenuItem };