import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from '../../utils/auth';

import { ClientsType, FilterType, getClients, getTags, formatClientsToDisplay, SortType, TagsType, ClientsDisplayType, PostTagPayloadType, createTag, editTags } from './services';
import { ApiError } from 'utils/CustomError';

const getTagsServiceHandler = async (search: string, limit: number, signal: AbortSignal): Promise<Array<TagsType> | void> => {
  const response = await getTags(search, limit, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect();


    return [];
  }

  return response;
}

const createTagServiceHandler = async (payload: PostTagPayloadType): Promise<TagsType | void> => {
  const response = await createTag(payload);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect();
  }

  return response;
}

const editTagsServiceHandler = async (clientsSelected: number[], clients: ClientsType[], addTags: string[], removeTags: string[]): Promise<any> => {
  const response = await editTags(clientsSelected, clients, addTags, removeTags);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect();

    return response;
  }

  return response;
}


export default function ClientsViewModel() {

  const [clients, setClients] = useState<Array<ClientsType>>();
  const [formattedClients, setFormattedClients] = useState<Array<ClientsDisplayType>>();
  const [searchClients, setSearchClients] = useState<string>('');
  const [clientsSelected, setClientsSelected] = useState<number[]>([]);

  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [createClientType, setCreateClientType] = useState<string>()

  const [tags, setTags] = useState<Array<TagsType>>();
  const [searchTag, setSearchTag] = useState('');

  const [bulkTags, setBulkTags] = useState<TagsType[]>();

  const [openEditClient, setOpenEditClient] = useState(false)
  const [openActivateClient, setOpenActivateClient] = useState(false)
  const [openDeactivateClient, setOpenDeactivateClient] = useState(false)
  const [actionIndex, setActionIndex] = useState<number>()

  // loading states
  const [clientLoading, setClientLoading] = useState(true);
  const [clientLoadingError, setClientLoadingError] = useState(false);
  const [tagLoading, setTagLoading] = useState(false);

  // filters
  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: ['ACTIVE']
  });
  const [tagsFilter, setTagsFilter] = useState<FilterType>({
    field: 'tags',
    comparator: 'in',
    value: []
  });

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalClients, setTotalClients] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'name', label: 'Cliente' },
    { id: 'razao_social', label: 'Razão Social' },
    { id: 'document', label: 'Documento' },
    { id: 'emails', label: 'Enviar para' },
    { id: 'created_at', label: 'Criado em' },
    { id: 'tags', label: 'Tags' },
    { id: 'status', label: 'Status' },
  ];

  const statusOptions = [
    { label: 'Ativos', value: 'ACTIVE' },
    { label: 'Inativos', value: 'CANCELED' },
  ];


  const onGetClientsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientLoadingError(true)

      setClients([]);
      setFormattedClients([])
      setClientsSelected([])
      setTotalClients(0);
    } else {
      setClientLoadingError(true)

      setClients([]);
      setFormattedClients([])
      setClientsSelected([])
      setTotalClients(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setClientLoading(true);
      setClientLoadingError(false)

      const response = await getClients([statusFilter, tagsFilter], searchClients, sort, { page, limit: rowsPerPage }, abortController.signal);

      if (response.errors) {
        onGetClientsHandleError(response.errors)
      } else {
        setClientLoadingError(false)
        setClients(response.clients as Array<ClientsType>);
        setFormattedClients(formatClientsToDisplay(response.clients))
        setClientsSelected([])

        setTotalClients(response.pagination.totalClients);
      }

      setClientLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [page, rowsPerPage, searchClients, sort, statusFilter, tagsFilter])

  useEffect(() => {
    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {
        setTagLoading(true);

        const response = await getTagsServiceHandler(searchTag, 10, abortController.signal);
        setTags(response as Array<TagsType>);

        setTagLoading(false);
      })();
    }, 500);

    return () => {
      abortController.abort();
      clearTimeout(debounce);
    }
  }, [searchTag])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setTagLoading(true);

      const response = await getTagsServiceHandler('', 100, abortController.signal);
      setBulkTags(response as TagsType[]);

      setTagLoading(false);
    })()

    return () => {
      abortController.abort();
    }
  }, [])


  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const applyTagsFilter = (optionsSelected: Array<{ value: string, label: string }>) => {
    setPage(1);
    setTagsFilter({ ...tagsFilter, value: optionsSelected.map(option => option.label) });
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'edit':
        setActionIndex(index)
        setOpenEditClient(true)
        break;

      case 'activate':
        setActionIndex(index)
        setOpenActivateClient(true)
        break;

      case 'deactivate':
        setActionIndex(index)
        setOpenDeactivateClient(true)
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCheckboxChange = (rowsSelected: number[]) => {
    setClientsSelected([...rowsSelected]);
  }

  const onClientSearchChange = (search: string) => {
    setPage(1);
    setSearchClients(search);
  }

  const onCloseModal = () => {
    setOpenCreateClient(false)
  }

  const onCloseEditModal = () => {
    setActionIndex(undefined)
    setOpenEditClient(false)
  }

  const onCloseActivateModal = () => {
    setActionIndex(undefined)
    setOpenActivateClient(false)
  }

  const onCloseDeactivateModal = () => {
    setActionIndex(undefined)
    setOpenDeactivateClient(false)
  }

  const onOpenModal = (id: string) => {
    setCreateClientType(id)
    setOpenCreateClient(true)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  const onSubmitCreateTag = async (payload: PostTagPayloadType) => {
    setTagLoading(true);
    const response = await createTagServiceHandler(payload)
    if (response) {
      if (bulkTags)
        setBulkTags([...bulkTags, response])
      else
        setBulkTags([response])
    }
    setTagLoading(false);
  }

  const onSubmitEditTags = async (addTags: string[], removeTags: string[]) => {
    if (clients) {
      await editTagsServiceHandler(clientsSelected, clients, addTags, removeTags)

      setStatusFilter({ ...statusFilter });
    }
  }

  const onTagSearchChange = (search: string) => {
    setSearchTag(search);
  }

  return {
    actionIndex,
    bulkTags,
    clients,
    clientLoading,
    clientLoadingError,
    clientsSelected,
    createClientType,
    formattedClients,
    openActivateClient,
    openCreateClient,
    openDeactivateClient,
    openEditClient,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    searchTag,
    sort,
    statusFilter,
    statusOptions,
    totalClients,
    tableHeadCells,
    tagLoading,
    tags,
    tagsFilter,
    applyStatusFilter,
    applyTagsFilter,
    handleTableRowActions,
    onChangeSort,
    onCheckboxChange,
    onClientSearchChange,
    onCloseModal,
    onCloseEditModal,
    onCloseActivateModal,
    onCloseDeactivateModal,
    onPageChange,
    onOpenModal,
    onRowsPerPageChange,
    onSubmitCreateTag,
    onSubmitEditTags,
    onTagSearchChange
  };
}