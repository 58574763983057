import { Button, Checkbox, FormControlLabel, FormGroup, Popover, Typography } from '@mui/material';
import React from 'react';

import useViewModel from './viewModel';

type CheckboxFilterPropTypes = {
  buttonLabel: string,
  id: string,
  initialSelected?: Array<string>,
  options: Array<{
    label: string,
    value: string
  }>,
  onApplyFilter: (optionsSelected: Array<string>) => void,
}

function CheckboxFilter({
  buttonLabel,
  id,
  initialSelected,
  options,
  onApplyFilter,
}: CheckboxFilterPropTypes) {
  const { isOpen, optionsSelected, onChange, onClearFilter, onClick } = useViewModel({ id, initialSelected });
  const buttonRef = React.useRef(null);

  return (
    <div className='filter'>
      <Button variant='outlined' onClick={onClick} size='small' color='secondary' ref={buttonRef}>
        <Typography fontWeight={700} variant='caption'>
          {buttonLabel}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div
          className='filter-modal'
        >
          <FormGroup>
            {
              options.map(option => (
                <FormControlLabel
                  key={option.value}
                  label={option.label}
                  control={
                    <Checkbox
                      checked={optionsSelected.includes(option.value)}
                      onChange={() => { onChange(option.value) }}
                    />
                  }
                />
              ))
            }
          </FormGroup>

          <hr />

          <div className='buttons-container'>
            <Button
              variant='outlined'
              size='small'
              onClick={onClearFilter}
            >
              <Typography fontWeight={700} variant='caption'>
                Limpar Filtro
              </Typography>
            </Button>

            <Button
              variant='contained'
              size='small'
              onClick={() => { onApplyFilter(optionsSelected) }}
            >
              <Typography fontWeight={700} variant='caption'>
                Aplicar Filtro
              </Typography>
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export { CheckboxFilter };