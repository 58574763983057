import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { IconButton, InputAdornment, Skeleton, TextField, TextFieldProps } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ChangeEvent } from 'react';

import useViewModel from './viewModel'

type PasswordFieldAdapterPropTypes = {
  name: string,
  label: string,
  loading?: boolean,
  onChange?: (e: ChangeEvent) => void,
  validate?: FieldValidator<any>
} & TextFieldProps;

const ComponentField = ({ input, meta, ...rest }: FieldRenderProps<any>) => {
  const { showPassword, toggleShow } = useViewModel();

  return (
    <TextField
      {...input}
      {...rest}
      error={meta.touched && meta.error ? true : false}
      FormHelperTextProps={{ className: 'helper-text' }}
      helperText={meta.touched ? meta.error : ''}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={toggleShow}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={(e: ChangeEvent) => {
        input.onChange(e);
        if (rest.onChange)
          rest.onChange(e);
      }}
    />
  )
};

function PasswordFieldAdapter({ name, label, loading = false, onChange, validate, ...rest }: PasswordFieldAdapterPropTypes) {

  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={ComponentField as any}
          name={name}
          label={label}
          validate={validate}
          onChange={onChange}
          {...rest}
        />
      )}
    </div>
  )
}

export { PasswordFieldAdapter };