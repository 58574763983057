import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { resetPassword } from './services'

export default function ForgotPasswordViewModel() {
  const navigate = useNavigate()

  const [passwordLoading, setPasswordLoading] = useState(false)
  const [passwordLoadingError, setPasswordLoadingError] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onSubmit = async ({ email }: { email: string }) => {
    setPasswordLoading(true)
    const response = await resetPassword(email)

    if (response.errors)
      setPasswordLoadingError(true)
    else
      setPasswordLoadingError(false)

    setOpenSnackbar(true)
    setPasswordLoading(false)
  }

  const redirectLogin = () => {
    if (!passwordLoading)
      return navigate('/login')
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return {
    openSnackbar,
    passwordLoading,
    passwordLoadingError,
    onCloseSnackbar,
    onSubmit,
    redirectLogin
  }
}