import { EventRegister } from 'components/EventRegister'

import useViewModel from './viewModel'
import { Skeleton } from '@mui/material'
import { EmptyView } from 'components/EmptyView'

type EventsRegisterViewPropTypes = {
  receiptId: string,
}

function EventsRegisterView({ receiptId }: EventsRegisterViewPropTypes) {
  const { formattedEvents, expandedEvent, eventsLoading, onEventClick } = useViewModel({ receiptId })

  return (
    <div className='events-container'>
      {
        eventsLoading && <span>
          <Skeleton height={80}></Skeleton>
          <Skeleton height={80}></Skeleton>
          <Skeleton height={80}></Skeleton>
          <Skeleton height={80}></Skeleton>
          <Skeleton height={80}></Skeleton>
        </span>
      }

      {
        !eventsLoading && formattedEvents?.map((event, index) => {
          return (
            <EventRegister
              key={index}
              expanded={expandedEvent === index}
              payloads={{ received: event.response, sent: event.sent }}
              timestamp={event.created_at}
              title={event.title}
              onChange={() => { onEventClick(index) }}
            />
          )
        })
      }

      {
        !eventsLoading && formattedEvents?.length === 0 &&
        <EmptyView title={'Nenhum evento registrado por enquanto'}></EmptyView>
      }
    </div>
  )
}

export { EventsRegisterView }