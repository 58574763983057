import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { Alert, Button, Paper, Snackbar, Typography } from '@mui/material';

import { TextFieldAdapter } from '../../components/FormComponents/Fields/TextFieldAdapter';
import { TermsOfUseModal } from 'components/Modals/TermsOfUse';

import useViewModel from './viewModel';

import LogoPath from '../../images/logo-cash.png'

import './styles.scss'
import { PasswordFieldAdapter } from 'components/FormComponents/Fields/PasswordFieldAdapter';

function Login() {
  const {
    invalidLogin,
    isLoading,
    loadingError,
    loginData,
    openSnackbar,
    openTermsOfUse,
    onAcceptTermsOfUse,
    onCloseSnackbar,
    onCloseTermsOfUse,
    onSubmit,
    required
  } = useViewModel();

  return (
    <div className='login-page'>

      <div className='logo'>
        <img src={LogoPath} alt='Logo' />
      </div>

      <Paper className='card'>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
            >
              <div className='input email'>
                <TextFieldAdapter
                  name='email'
                  label='Email'
                  validate={required}
                  type='email'
                />
              </div>

              <div className='input password'>
                <PasswordFieldAdapter
                  name='password'
                  label='Senha'
                  validate={required}
                />
              </div>

              <div className='forgot-password'>
                <Link to={'/password'}>
                  <Typography variant='caption' color='primary'>
                    Esqueceu sua senha?
                  </Typography>
                </Link>
              </div>

              <div className='button'>
                <Button
                  type='submit'
                  disabled={isLoading}
                  variant='contained'
                  fullWidth
                >
                  Continuar
                </Button>
              </div>
            </form>
          )}
        />
      </Paper>

      <TermsOfUseModal
        termUrl={loginData ? loginData.url : ''}
        open={openTermsOfUse}
        onClose={onCloseTermsOfUse}
        onSubmit={onAcceptTermsOfUse}
      />

      {loadingError && !invalidLogin &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!loadingError && invalidLogin &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Email ou senha inválidos.
          </Alert>
        </Snackbar>
      }
    </div>
  );
}

export { Login }