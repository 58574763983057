import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from '../../utils/auth';

import { FilterType, InvoicesDisplayType, InvoicesType, SortType, formatInvoicesToDisplay, formatTotalsToDisplay, getInvoices } from './services';
import { ApiError } from 'utils/CustomError';

export default function InvoicesViewModel() {
  const [invoices, setInvoices] = useState<InvoicesType[]>();
  const [formattedInvoices, setFormattedInvoices] = useState<InvoicesDisplayType[]>()

  const [valueRangeLimits, setValueRangeLimits] = useState<{ min: number, max: number }>()

  //loading states
  const [invoicesLoading, setInvoicesLoading] = useState(true);

  const [invoicesLoadingError, setInvoicesLoadingError] = useState(false);

  // filters
  const [processedDateFilter, setProcessedDateFilter] = useState<FilterType[]>([{
    field: 'processed_date',
    comparator: 'gte',
    value: []
  }, {
    field: 'processed_date',
    comparator: 'lte',
    value: []
  }]);

  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: []
  });

  const [paymentStatusFilter, setPaymentStatusFilter] = useState<FilterType>({
    field: 'payment_status',
    comparator: 'in',
    value: []
  })

  const [lowerValueFilter, setLowerValueFilter] = useState<FilterType>({
    field: 'total_value',
    comparator: 'gte',
    value: []
  });

  const [upperValueFilter, setUpperValueFilter] = useState<FilterType>({
    field: 'total_value',
    comparator: 'lte',
    value: []
  });

  const [searchInvoices, setSearchInvoices] = useState<string>('')

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalInvoices, setTotalInvoices] = useState(0)
  const [totalValue, setTotalValue] = useState(0)
  const [formattedTotalValue, setFormattedTotalValue] = useState('')

  const [actionInvoice, setActionInvoice] = useState<InvoicesType>()

  const [openCancel, setOpenCancel] = useState(false)
  const [openReopen, setOpenReopen] = useState(false)
  const [openSend, setOpenSend] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)

  const [openDescription, setOpenDescription] = useState(false)

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'total_value', label: 'Valor' },
    { id: 'receipt_number', label: 'Número da nota' },
    { id: 'processed_date', label: 'Emitida em' },
    { id: 'payment_status', label: 'Status de Pgto' },
    { id: 'status', label: 'Status' },
    { id: 'receipt_url', label: '', sortable: false },
  ];

  const statusOptions = [
    { label: 'Rascunhos', value: 'DRAFT' },
    { label: 'Autorizados', value: 'AUTHORIZED' },
    { label: 'Cancelados', value: 'CANCELLED' },
    { label: 'Cancelando', value: 'CANCELLING' },
    { label: 'Erro', value: 'ERROR' },
    { label: 'Pendentes', value: 'PENDING' },
    { label: 'Processando', value: 'PROCESSING' },
  ]

  const paymentStatusOptions = [
    { label: 'Cancelados', value: 'CANCELED' },
    { label: 'Expirados', value: 'EXPIRED' },
    { label: 'Falhas', value: 'FAILED' },
    { label: 'Pagos', value: 'PAID' },
    { label: 'Pendentes', value: 'PENDING' },
  ]

  const onGetInvoicesHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setInvoicesLoadingError(true)

      setInvoices([]);
      setFormattedInvoices([])
      setTotalInvoices(0);
    } else {
      setInvoicesLoadingError(true)

      setInvoices([]);
      setFormattedInvoices([])
      setTotalInvoices(0);
    }
  }


  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setInvoicesLoading(true);

      const response = await getInvoices(
        [
          lowerValueFilter,
          upperValueFilter,
          paymentStatusFilter,
          ...processedDateFilter,
          statusFilter
        ],
        searchInvoices,
        sort,
        { page, limit: rowsPerPage },
        abortController.signal
      )

      if (response.errors) {
        onGetInvoicesHandleError(response.errors)
      } else {
        setInvoicesLoadingError(false)
        setInvoices(response.invoices as InvoicesType[])
        setFormattedInvoices(formatInvoicesToDisplay(response.invoices))

        if (response.pagination)
          setTotalInvoices(response.pagination.totalInvoices)

        if (response.totals) {
          setTotalValue(response.totals.totalValue);
          setFormattedTotalValue(formatTotalsToDisplay(response.totals.totalValue)?.totalValue)
        }

        if (response.limits)
          setValueRangeLimits(response.limits)
      }

      setInvoicesLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [lowerValueFilter, page, paymentStatusFilter, processedDateFilter, rowsPerPage, searchInvoices, sort, statusFilter, upperValueFilter])


  const applyPaymentStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setPaymentStatusFilter({ ...paymentStatusFilter, value: optionsSelected });
  }

  const applyProcessedDateFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...processedDateFilter];

    newDateFilter[0].value = startDate ? [startDate.format('YYYY-MM-DD')] : [];
    newDateFilter[1].value = endDate ? [endDate.format('YYYY-MM-DD')] : [];

    setProcessedDateFilter(newDateFilter);
  }

  const applyRangeFilter = (lowerValue: number, upperValue: number) => {
    setPage(1);
    setLowerValueFilter({ ...lowerValueFilter, value: [lowerValue] });
    setUpperValueFilter({ ...upperValueFilter, value: [upperValue] });
  }

  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const handleTableRowActions = (invoice: InvoicesType, action: string) => {
    switch (action) {
      case 'description':
        setActionInvoice(invoice)
        setOpenDescription(true)
        break;

      case 'send':
        setActionInvoice(invoice)
        setOpenSend(true)
        break;

      case 'reopen':
        setActionInvoice(invoice)
        setOpenReopen(true)
        break;

      case 'cancel':
        setActionInvoice(invoice)
        setOpenCancel(true)
        break;
      
      case 'email':
        setActionInvoice(invoice)
        setOpenEmail(true)
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseCancel = () => {
    setOpenCancel(false)
  }

  const onCloseDescription = () => {
    setOpenDescription(false)
  }

  const onCloseReopen = () => {
    setOpenReopen(false)
  }

  const onCloseSend = () => {
    setOpenSend(false)
  }

  const onCloseEmail = () => {
    setOpenEmail(false)
  }

  const onInvoiceSearchChange = (search: string) => {
    setPage(1)
    setSearchInvoices(search)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  }


  return {
    actionInvoice,
    formattedInvoices,
    formattedTotalValue,
    invoices,
    invoicesLoading,
    invoicesLoadingError,
    lowerValueFilter,
    openCancel,
    openDescription,
    openReopen,
    openSend,
    openEmail,
    optionsRowsPerPage,
    page,
    paymentStatusFilter,
    paymentStatusOptions,
    processedDateFilter,
    rowsPerPage,
    valueRangeLimits,
    searchInvoices,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalInvoices,
    totalValue,
    upperValueFilter,
    applyPaymentStatusFilter,
    applyProcessedDateFilter,
    applyRangeFilter,
    applyStatusFilter,
    handleTableRowActions,
    onChangeSort,
    onCloseCancel,
    onCloseDescription,
    onCloseSend,
    onCloseReopen,
    onCloseEmail,
    onInvoiceSearchChange,
    onPageChange,
    onRowsPerPageChange
  }
}