import { useEffect, useState } from 'react';

import { useOpenedFilters } from '../../../recoil/openedFilters/actions';

type ViewModelPropTypes = {
  id: string,
  max?: number,
  min?: number,
}

export default function RangeFilterViewModel({ id, max, min }: ViewModelPropTypes) {
  const [lowerLimit, setLowerLimit] = useState<number>();
  const [upperLimit, setUpperLimit] = useState<number>();

  const { openedFilter, onCloseFilter, onOpenFilter } = useOpenedFilters();

  const isOpen = openedFilter === id;

  useEffect(() => {
    if (min !== undefined)
      if (lowerLimit === undefined)
        setLowerLimit(min)

    if (max !== undefined)
      if (upperLimit === undefined)
        setUpperLimit(max)
  }, [lowerLimit, max, min, upperLimit])

  const onIntervalChange = ([newLower, newUpper]: Array<number>) => {
    setLowerLimit(newLower);
    setUpperLimit(newUpper);
  }

  const onLowerLimitChange = (value: string) => {
    const numberValue = +value;

    if (min !== undefined && max !== undefined)
      if (numberValue < min)
        setLowerLimit(min)
      else if (numberValue > max)
        setLowerLimit(max)
      else
        setLowerLimit(numberValue)

    if (numberValue > (upperLimit as number) && numberValue < (max as number))
      setUpperLimit(numberValue)
  }

  const onUpperLimitChange = (value: string) => {
    const numberValue = +value;

    if (min !== undefined && max !== undefined)
      if (numberValue > max)
        setUpperLimit(max)
      else if (numberValue < min)
        setUpperLimit(min)
      else
        setUpperLimit(numberValue)

    if (numberValue < (lowerLimit as number) && numberValue > (min as number))
      setLowerLimit(numberValue)
  }

  const onClick = () => {
    if (isOpen)
      onCloseFilter();
    else
      onOpenFilter(id)
  };

  return {
    lowerLimit,
    isOpen,
    upperLimit,
    openedFilter,
    onClick,
    onIntervalChange,
    onLowerLimitChange,
    onUpperLimitChange,
    onCloseFilter,
    onOpenFilter
  };
}