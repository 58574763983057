import validator from "card-validator";
import {useEffect, useState} from "react";
import {useCreateCheckoutOrderActions} from "../../../../recoil/checkout/actions";
import {formatCheckoutPaymentPayloadToDisplay, bundlePayloadToRecoil, PaymentPayload} from "./services";
import {ValidationErrors} from "final-form";

type viewModelPropTypes = {
    availablePaymentOptions?: Array<string>,
    onBack: () => void
    onNext: () => void
}

export default function CustomerStepViewModel({availablePaymentOptions, onBack, onNext}: viewModelPropTypes) {

    const [brand, setBrand] = useState('');

    const monthOptions = [...Array(12)].map((value, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))

    const actualYear = (new Date()).getFullYear()
    const yearOptions = [...Array(20)].map((value, index) => ({ label: `${index + actualYear}`, value: `${index + actualYear}` }))

    const { checkoutOrderPayload, setCheckoutOrderPayload} = useCreateCheckoutOrderActions()
    const [formattedCheckoutOrderPayload, setFormattedCheckoutOrderPayload] = useState<any>()

    const paymentMethodOptions = [
        {label: 'Boleto', value: 'BOLETO'},
        {label: 'PIX', value: 'PIX'},
        {label: 'Cartão de crédito', value: 'CREDIT_CARD'}
    ].filter(x => (availablePaymentOptions ? (availablePaymentOptions.includes(x.value) || availablePaymentOptions.includes(x.value.toLowerCase())) : true))

    useEffect(() => {
        setFormattedCheckoutOrderPayload(formatCheckoutPaymentPayloadToDisplay(checkoutOrderPayload))
    }, [availablePaymentOptions, checkoutOrderPayload])

    const onDigitsChange = (digits: string) => {
        const type = validator.number(digits.replaceAll(' ', '')).card?.type;
        setBrand(type ? type : '');
    }

    const onSubmit = (values: Record<string, any>) => {
        setCheckoutOrderPayload({ ...checkoutOrderPayload, ...bundlePayloadToRecoil(values as PaymentPayload) })
        onNext()
    }

    const formValidate = (values: any) => {
        const errors: ValidationErrors = {};

        if (values.payment_method === 'CREDIT_CARD') {
            if (!values.digits)
                errors.digits = 'Obrigatório'

            if (!values.holder_name)
                errors.holder_name = 'Obrigatório'

            if (!values.expiration_month)
                errors.expiration_month = 'Obrigatório'

            if (!values.expiration_year)
                errors.expiration_year = 'Obrigatório'

            if (!values.security_code)
                errors.security_code = 'Obrigatório'
        }

        return errors;
    }

    return {
        monthOptions,
        yearOptions,
        paymentMethodOptions,
        brand,
        checkoutOrderPayload,
        formattedCheckoutOrderPayload,
        onDigitsChange,
        formValidate,
        onSubmit
    }
}