import { Typography } from '@mui/material'

import { DisplayInfoText } from 'components/Info/DisplayInfoText'
import { DisplayInfoLink } from 'components/Info/DisplayInfoLink'
import { DisplayInfoTextOpenNewTab } from 'components/Info/DisplayInfoTextOpenNewTab'
import { DisplayInfoCopy } from 'components/Info/DisplayInfoCopy'
import { Flexbox } from 'components/Flexbox'
import { ApiErrorView } from 'components/ApiErrorView';

import useViewModel from './viewModel'
import { BOLETO, BANK_DEPOSIT, CREDIT_CARD, PIX} from 'utils/constants/paymentMethods'

type AccountReceivableGeneralViewPropTypes = {
  accountReceivableId: string
}

function AccountReceivableGeneralView({ accountReceivableId }: AccountReceivableGeneralViewPropTypes) {
  const { formattedDetails, detailsLoading, detailsLoadingError } = useViewModel({ accountReceivableId })

  return (
    <div className='details'>

      {detailsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar o conta a receber. Tente recarregar a página.' />
      }

      {!detailsLoadingError && <>
        <div className='segment-info'>
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Status'
              text={formattedDetails?.status ? formattedDetails.status : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Valor'
              text={formattedDetails?.total ? formattedDetails.total : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Data de vencimento'
              text={formattedDetails?.due_date ? formattedDetails.due_date : ''}
            />
          </Flexbox>
        </div>
        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoCopy
              isLoading={detailsLoading}
              title='Chave'
              text={formattedDetails?.key ? formattedDetails.key : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoTextOpenNewTab
              isLoading={detailsLoading}
              title='Cliente'
              texts={formattedDetails?.customer ? [formattedDetails.customer] : []}
              links={[`/clients/${formattedDetails?.customer_id}`]}
            />

          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoTextOpenNewTab
              isLoading={detailsLoading}
              title='Fatura associada'
                texts={formattedDetails?.invoice_group_key ? [formattedDetails.invoice_group_key] : []}
                links={[`/invoices-groups/${formattedDetails?.invoice_group_id}`]}
            />
          </Flexbox>
        </div>
          
        {(formattedDetails?.installments_total !== '1') && <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Parcelas'
              text={formattedDetails?.installments ? formattedDetails.installments : ''}
            />
          </Flexbox>
        </div>}
        </div>

        <div className='segment-info'>
          <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações de pagamento</Typography>

          <div className='info-row'>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Data do pagamento'
                text={formattedDetails?.payment_date ? formattedDetails.payment_date : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Data da transferência'
                text={formattedDetails?.transfer_date ? formattedDetails.transfer_date : ''}
              />
            </Flexbox>
          </div>

          <div className='info-row'>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Taxa'
                text={formattedDetails?.tax_value ? formattedDetails.tax_value : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Juros e multas'
                text={formattedDetails?.interest_and_penalty_value ? formattedDetails.interest_and_penalty_value : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Valor líquido'
                text={formattedDetails?.net_value ? formattedDetails.net_value : ''}
              />
            </Flexbox>
          </div>
        </div>

        <div className='segment-info'>
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações do método de pagamento</Typography>

        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Método'
              text={formattedDetails?.payment_method_name ? formattedDetails.payment_method_name : ''}
            />
          </Flexbox>
        </div>

        <div className='info-row'>
          {formattedDetails?.payment_method === BOLETO && <Flexbox size={3}>
            <DisplayInfoCopy
              isLoading={detailsLoading}
              title='Linha digitável'
              text={formattedDetails?.boleto_barcode ? formattedDetails.boleto_barcode : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === BANK_DEPOSIT && <Flexbox size={3}>
            <DisplayInfoCopy
              isLoading={detailsLoading}
              title='Banco'
              text={formattedDetails?.bank_deposit_bank_name ? formattedDetails.bank_deposit_bank_name : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === PIX && <Flexbox size={3}>
            <DisplayInfoCopy
              isLoading={detailsLoading}
              title='Chave copia e cola'
              text={formattedDetails?.pix_url ? formattedDetails.pix_url : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === CREDIT_CARD && <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Final'
              text={formattedDetails?.credit_card_last_digits ? formattedDetails.credit_card_last_digits : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === CREDIT_CARD && <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Vencimento do cartão'
              text={formattedDetails?.credit_card_expiration_date ? formattedDetails.credit_card_expiration_date : ''}
            />
          </Flexbox>}
        </div>

        <div className='info-row'>
          {formattedDetails?.payment_method === BOLETO && <Flexbox size={3}>
            <DisplayInfoLink
              isLoading={detailsLoading}
              title='Arquivo'
              text={formattedDetails?.boleto_url ? formattedDetails?.boleto_url : '-'}
              link={formattedDetails?.boleto_url ? formattedDetails.boleto_url : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === BANK_DEPOSIT && <Flexbox size={3}>
            <DisplayInfoCopy
              isLoading={detailsLoading}
              title='Agência'
              text={formattedDetails?.bank_deposit_routing_number ? formattedDetails.bank_deposit_routing_number : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === BANK_DEPOSIT && <Flexbox size={3}>
            <DisplayInfoCopy
              isLoading={detailsLoading}
              title='Conta'
              text={formattedDetails?.bank_deposit_account_number ? formattedDetails.bank_deposit_account_number : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === CREDIT_CARD && <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Bandeira'
              text={formattedDetails?.credit_card_brand ? formattedDetails.credit_card_brand : ''}
            />
          </Flexbox>}
          {formattedDetails?.payment_method === CREDIT_CARD && <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Nome no cartão'
              text={formattedDetails?.credit_card_name ? formattedDetails.credit_card_name : ''}
            />
          </Flexbox>}
        </div>

        </div>
      </>}

    </div>
  )
}

export { AccountReceivableGeneralView }