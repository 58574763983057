import { useState } from 'react'

import { SelectChangeEvent } from '@mui/material'

import { CouponPayload, postCoupon } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { FormApi, ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CreateCouponModalViewModel({ onClose, onSubmit }: ViewModelPropTypes) {

  const [createLoading, setCreateLoading] = useState(false)
  const [createError, setCreateError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const typeList = [
    {value: 'PERCENTAGE', label: 'Porcentagem'},
    {value: 'FIXED', label: 'Valor fixo'}
  ]
  const [valueType, setValueType] = useState('')

  const onPostCouponHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setCreateError(true)
      setOpenSnackbar(true)
    } else {
      setCreateError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setCreateLoading(true)
    const response = await postCoupon(values as CouponPayload)

    if (response.errors)
      onPostCouponHandleError(response.errors)

    else {
      setCreateError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setCreateLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    const codeRegex = /^(\w|-)*$/

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.code)
      errors.code = 'Obrigatório'

    if (values.code && values.code.length > 30)
      errors.code = 'Máximo de 30 caracteres'

    if (values.code && !codeRegex.test(values.code))
      errors.code = 'Apenas letras, números, undescore e hífens'

    if (!values.type)
      errors.type = 'Obrigatório'

    if (!values.value)
      errors.value = 'Obrigatório'

    return errors
  }

  const onTypeChange = (e: SelectChangeEvent, form: FormApi) => {
    const field = form.getFieldState('value')
    field?.change(null)
    setValueType(e.target.value)
  }

  return {
    createLoading,
    createError,
    formError,
    openSnackbar,
    typeList,
    valueType,
    onTypeChange,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}