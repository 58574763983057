import { ChangeEvent } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { FieldValidator } from 'final-form';
import { Field, FieldRenderProps } from 'react-final-form';
import { Dayjs } from 'dayjs';
import { Skeleton } from '@mui/material';

type DatePickerFieldAdapterPropTypes = {
  disabled?: boolean,
  label: string,
  loading?: boolean,
  minDate?: Dayjs,
  name: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  validate?: FieldValidator<any>
};

const componentField = ({
  input,
  meta,
  label,
  value,
  onChange,
  ...rest
}: FieldRenderProps<any>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...input}
        {...rest}
        label={label}
        format='DD/MM/YYYY'
        value={null}
        onChange={(e) => {
          input.onChange(e);
          if (onChange)
            onChange(e);
        }}
        slotProps={{
          textField: {
            helperText: meta.touched ? meta.error : '',
            error: meta.touched && meta.error ? true : false,
            fullWidth: true,
            FormHelperTextProps: { className: 'helper-text' }
          }
        }}
      />
    </LocalizationProvider>
  );
}

function DatePickerFieldAdapter({
  disabled = false,
  label,
  loading = false,
  minDate,
  name,
  onChange,
  validate
}: DatePickerFieldAdapterPropTypes) {
  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={componentField}
          name={name}
          label={label}
          disabled={disabled}
          onChange={onChange}
          validate={validate}
          minDate={minDate}
        />
      )}
    </div>
  );
}

export { DatePickerFieldAdapter };