import { Button, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { Field, FieldRenderProps } from 'react-final-form';
import { Flexbox } from 'components/Flexbox';

import useViewModel from './viewModel'

import './styles.scss'
import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';
import { DatePickerFieldAdapter } from 'components/FormComponents/Fields/DatePickerFieldAdapter';
import { FormApi } from 'final-form';

type InstallmentsPropTypes = {
  label: string,
  name: string,
  form?: FormApi
}

const componentField = ({
  form,
  input,
  label,
  installmentList,
  onChange,
  onDelete,
  newInstallmentValue,
  newInstallmentDate,
  creatingNewInstallment,
  onChangeTotalValueInstallment,
  onChangeDateNewInstallment,
  onStartCreatingNewInstallment,
  formatToDisplay
}: FieldRenderProps<any>) => (
  <>
    <div className='installment-title'>{label}</div>
    <div className='installment-list'>
      {
        formatToDisplay(input.value) && <div>
          {formatToDisplay(input.value).map((installment: string, index: number) => (
            <div className='installment-item' key={index}>
              {installment}
              <IconButton
                className='add-button'
                color='secondary'
                size='small'
                onClick={() => {
                  const allInstallments = onDelete(index);
                  input.onChange(allInstallments)
                }}>
                <ClearIcon fontSize='small' />
              </IconButton>
            </div>
          ))}
        </div>
      }
    </div>

    {creatingNewInstallment && <div className='new-installment-container'>
      <Flexbox size={6}>
        <CurrencyFieldAdapter
          disabled={false}
          name={'total_value'}
          label={'Valor da parcela'}
          loading={false}
          onChange={(e: any) => { onChangeTotalValueInstallment(e.target.value) }}
        />
      </Flexbox>

      <Flexbox size={4}>
        <DatePickerFieldAdapter
          label={'Data de vencimento da parcela'}
          loading={false}
          name={'date'}
          onChange={(e: any) => { onChangeDateNewInstallment(e) }}
        />
      </Flexbox>

      <Button
        className='remove-button'
        variant='text'
        color='primary'
        component='label'
        onClick={() => {
          if (!newInstallmentValue || !newInstallmentDate) return
          const allInstallments = onChange({ total_value: { newInstallmentValue }, date: newInstallmentDate })
          input.onChange(allInstallments)

          if (form) {
            const fieldValue = form.getFieldState('total_value')
            fieldValue?.change(null)

            const fieldDate = form.getFieldState('date')
            fieldDate?.change(null)
          }
        }}>
        OK
      </Button>
    </div>}

    {!creatingNewInstallment && <div className='add-button'>
      <Button
        variant='text'
        color='primary'
        component='label'
        onClick={(e) => { onStartCreatingNewInstallment() }}>
        + Adicionar parcela
      </Button>
    </div>}
  </>
);

function Installments({
  label,
  name,
  form
}: InstallmentsPropTypes) {

  const {
    formattedInstallmentList,
    newInstallmentValue,
    newInstallmentDate,
    creatingNewInstallment,
    onChange,
    onDeleteInstallment,
    onChangeTotalValueNewInstallment,
    onChangeDateNewInstallment,
    onStartCreatingNewInstallment,
    formatToDisplay
  } = useViewModel()

  return (
    <div className='field'>
      <div>
        <Field
          form={form}
          component={componentField}
          installmentList={formattedInstallmentList}
          name={name}
          label={label}
          onChange={onChange}
          onDelete={onDeleteInstallment}
          creatingNewInstallment={creatingNewInstallment}
          newInstallmentValue={newInstallmentValue}
          newInstallmentDate={newInstallmentDate}
          onChangeTotalValueInstallment={onChangeTotalValueNewInstallment}
          onChangeDateNewInstallment={onChangeDateNewInstallment}
          onStartCreatingNewInstallment={onStartCreatingNewInstallment}
          formatToDisplay={formatToDisplay}
        />
      </div>
    </div>
  )
}

export { Installments }