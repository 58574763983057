import { useState } from 'react'

import { deactivateSupplier } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

type SupplierType = {
  id: string,
}

type ViewModelPropTypes = {
  supplierData: SupplierType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function DeactivateSupplierViewModel({ supplierData, onClose, onSubmit }: ViewModelPropTypes) {
  const [supplierLoading, serSupplierLoading] = useState(false)
  const [supplierError, setSupplierError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onActivateHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setSupplierError(true)
      setOpenSnackbar(true)
    } else {
      setSupplierError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async () => {
    serSupplierLoading(true)

    const response = await deactivateSupplier(supplierData.id)

    if (response.errors)
      onActivateHandleError(response.errors)

    else {
      setSupplierError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    serSupplierLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    supplierError,
    supplierLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar,
  }
}

export type { SupplierType }