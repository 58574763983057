import dayjs from 'dayjs'
import { CustomError, api } from 'utils/axios'
import { formatMoney } from 'utils/moneyFormatt'

type DetailsType = {
  id: string,
  key: string,
  status: string,
  total: number,
  due_date: string,
  company_name: string,
  company_logo: string,
  created_at: string,
  updated_at: string,
  invoice_link: string,
  pix_link: string,
  expires_at: string,
  qr_code: string
}

type DetailsDisplayType = {
  id: string,
  key: string,
  status: string,
  total: string,
  due_date: string,
  company_name: string,
  company_logo: string,
  created_at: string,
  updated_at: string,
  invoice_link: string,
  pix_link: string,
  qr_code: string
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {

  const ACCOUNT_RECEIVABLE_STATUS: any = {
    'DRAFT': 'RASCUNHO',
    'PENDING': 'Pendente',
    'PAID': 'Pago',
    'TRANSFERRED': 'Transferido',
    'EXPIRED': 'Expirado',
    'ERROR': 'Erro',
    'CANCELLED': 'Cancelado',
    'PARTIALLY_PAID': 'Parcialmente pago'
  }

  return {
    id: details.id,
    key: details.key,
    status: ACCOUNT_RECEIVABLE_STATUS[details.status].toUpperCase(),
    total: formatMoney(details.total),
    due_date: dayjs(details.due_date).format('DD/MM/YYYY'),
    company_name: details.company_name,
    company_logo: details.company_logo,
    created_at: dayjs(details.created_at).format('DD/MM/YYYY'),
    updated_at: dayjs(details.updated_at).format('DD/MM/YYYY'),
    invoice_link: details.invoice_link,
    pix_link: details.pix_link,
    qr_code: details.qr_code,
  }
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    key: response.data.attributes.key,
    status: response.data.attributes.status,
    total: response.data.attributes.total,
    due_date: response.data.attributes.due_date?.date,
    company_name: response.data.attributes.company_name,
    company_logo: response.data.attributes.company_logo,
    created_at: response.data.attributes.created_at?.date,
    updated_at: response.data.attributes.updated_at?.date,
    invoice_link: response.data.attributes.invoice_link,
    pix_link: response.data.attributes.pix_link,
    expires_at: response.data.attributes.expires_at?.date,
    qr_code: response.data.attributes.qr_code,
  }
}

const postPix = async (hash: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/pix/qr-code/${hash}`, {}, {
      headers: {
        Authorization: `Bearer ${hash}`
      },
      signal
    })

    return parseDetails(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { formatDetailsToDisplay, postPix }

export type { DetailsType, DetailsDisplayType }