import { CustomError, api } from 'utils/axios'

const pingMe = async (signal: AbortSignal) => {
  try {
    const response = await api.get('/api-cashone/me', { signal })
    return response.data;

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const postLogout = async (): Promise<any> => {
  try {
    const response = await api.post('/logout', { data: { type: 'user' } })
    return response.data
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postLogout, pingMe }