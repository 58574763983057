import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { ApiErrorView } from 'components/ApiErrorView';
import { Flexbox } from 'components/Flexbox';

import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss'

type EditPlanModalPropTypes = {
  planId: string,
  productId: string,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function EditPlanModal({ planId, productId, open, onClose, onSubmit }: EditPlanModalPropTypes) {
  const {
    editError,
    formattedPlan,
    formError,
    freqOptions,
    openSnackbar,
    planLoading,
    planLoadingError,
    paymentTermTypeOptions,
    paymentTermValueOptions,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  } = useViewModel({ planId, productId, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='edit-plan-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            initialValues={formattedPlan}
            render={({ values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Editar plano
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                {!planLoadingError &&
                  <div className='modal-body'>
                    <Flexbox size={12}>
                      <TextFieldAdapter
                        name='name'
                        label='Nome'
                        loading={planLoading}
                      />
                    </Flexbox>
                    <Flexbox size={12}>
                      <Flexbox size={6}>
                        <SelectFieldAdapter
                          name='freq'
                          label='Frequência de cobrança'
                          options={freqOptions}
                          loading={planLoading}
                        />
                      </Flexbox>
                      <Flexbox size={6}>
                        <TextFieldAdapter
                          name='initial_term'
                          label='Termo de contrato'
                          loading={planLoading}
                          type='number'
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      </Flexbox>
                    </Flexbox>
                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <SelectFieldAdapter
                          name='payment_term_type'
                          label='Prazo de pagamento'
                          options={paymentTermTypeOptions}
                          loading={planLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        {values.payment_term_type === 'NET' ? (
                          <TextFieldAdapter
                            name='payment_term_value'
                            label='Data'
                            loading={planLoading}
                            type='number'
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        ) : (
                          <SelectFieldAdapter
                            name='payment_term_value'
                            label='Data'
                            options={paymentTermValueOptions}
                            loading={planLoading}
                          />
                        )}
                      </Flexbox>
                      <Flexbox size={4}>
                        <CurrencyFieldAdapter
                          name='value'
                          label='Valor'
                          loading={planLoading}
                        />
                      </Flexbox>
                    </Flexbox>
                  </div>
                }

                {planLoadingError && !planLoading &&
                  <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as informações do plano.' />
                }

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose} disabled={planLoading}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={planLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(editError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Plano atualizado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { EditPlanModal }