import {useEffect, useState} from 'react';
import {PlanType, PlanDisplayType, formatPlanToDisplay} from "./services";

export default function ViewGeneralPlanViewModel(planData?: PlanType) {

  const [plan] = useState<PlanType | undefined>(planData)
  const [boleto, setBoleto] = useState<boolean>(true);
  const [creditCard, setCreditCard] = useState<boolean>(true);
  const [pix, setPix] = useState<boolean>(true);

  const [formattedPlan, setFormattedPlan] = useState<PlanDisplayType | undefined>(planData ? formatPlanToDisplay(planData, creditCard, boleto, pix) : undefined);

  useEffect(() => {
    if (planData)
      setFormattedPlan(formatPlanToDisplay(planData, creditCard, boleto, pix))
  }, [boleto, creditCard, pix, plan, planData])

  const onChangeBoleto = (selected:boolean) => {
    setBoleto(selected)
    if (planData)
      setFormattedPlan(formatPlanToDisplay(planData, creditCard, boleto, pix))
  }

  const onChangeCreditCard = (selected:boolean) => {
    setCreditCard(selected)
    if (planData)
      setFormattedPlan(formatPlanToDisplay(planData, creditCard, boleto, pix))
  }

  const onChangePix = (selected:boolean) => {
    setPix(selected)
    if (planData)
      setFormattedPlan(formatPlanToDisplay(planData, creditCard, boleto, pix))
  }

  return {
    boleto,
    creditCard,
    pix,
    formattedPlan,
    onChangeBoleto,
    onChangeCreditCard,
    onChangePix
  }
}