import useViewModel from './viewModel';

import { DateRangeFilter } from '../../../../components/Filters/DateRangeFilter';
import { SearchCheckboxFilter } from '../../../../components/Filters/SearchCheckboxFilter';
import { SearchFilter } from '../../../../components/Filters/SearchFilter';
import { CheckboxFilter } from '../../../../components/Filters/CheckboxFilter';
import { SaleListTable } from '../../../../components/Tables/SaleListTable';
import { CancelSaleModal } from 'components/Modals/Sale/Cancel';
import { ApplyCouponModal } from 'components/Modals/Sale/ApplyCoupon';

import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';
import { EditSaleModal } from 'components/Modals/Sale/Edit';

function SalesSpot() {
  const {
    actionSaleIndex,
    openCancel,
    openApplyCoupon,
    openEdit,
    optionsRowsPerPage,
    page,
    products,
    rowsPerPage,
    formattedSales,
    sales,
    salesLoading,
    salesLoadingError,
    searchProducts,
    searchTags,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    tags,
    totalSales,
    applyPeriodEndFilter,
    applyPeriodStartFilter,
    applyProductsFilter,
    applyStatusFilter,
    applyTagsFilter,
    handleTableRowActions,
    onCloseApplyCoupon,
    onCloseCancel,
    onCloseEdit,
    onChangeSort,
    onPageChange,
    onProductSearchChange,
    onRowsPerPageChange,
    onSalesSearchChange,
    onTagSearchChange
  } = useViewModel();

  return (
    <div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por cliente, chave ou produto'
            onSubmit={onSalesSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status-sales-spot'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <SearchCheckboxFilter
            id='products-sales-spot'
            buttonLabel='Produtos'
            options={products ? products : []}
            search={searchProducts}
            onApplyFilter={applyProductsFilter}
            onSearchChange={onProductSearchChange}
          />

          <DateRangeFilter
            id='periodStart-sales-spot'
            buttonLabel='Data de início'
            onApplyFilter={applyPeriodStartFilter}
          />

          <DateRangeFilter
            id='periodEnd-sales-spot'
            buttonLabel='Data de Término'
            onApplyFilter={applyPeriodEndFilter}
          />

          <SearchCheckboxFilter
            id='tags-sales-spot'
            buttonLabel='Tags'
            options={tags ? tags : []}
            search={searchTags}
            onApplyFilter={applyTagsFilter}
            onSearchChange={onTagSearchChange}
          />
        </div>
      </div>

      {!salesLoading &&
        !salesLoadingError &&
        formattedSales?.length === 0 &&
        <EmptyView title={'Nenhuma venda pontual encontrada para mostrar aqui'}></EmptyView>
      }

      {salesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as vendas pontuais. Tente recarregar a página ou usar filtros diferentes.' />
      }

      {(salesLoading || (formattedSales !== undefined && formattedSales.length > 0)) &&
        <>
          <SaleListTable
            hasActions
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rowLink='/sales/spot'
            rows={formattedSales ? formattedSales : []}
            rowsPerPage={rowsPerPage}
            total={totalSales}
            isLoading={salesLoading}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <CancelSaleModal
            onClose={onCloseCancel}
            open={openCancel}
            saleData={{
              id: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].id : '',
              key: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].key : '',
            }}
          />

          <ApplyCouponModal
            onClose={onCloseApplyCoupon}
            open={openApplyCoupon}
            saleData={{
              id: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].id : '',
              key: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].key : '',
            }}
          />

          <EditSaleModal
            onClose={onCloseEdit}
            open={openEdit}
            type='SPOT'
            saleData={{
              id: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].id : '',
              key: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].key : '',
            }}
          />
        </>
      }

    </div>
  )
}

export { SalesSpot };