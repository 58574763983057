import dayjs from 'dayjs';

import { formatMoney } from 'utils/moneyFormatt';
import { formatPercent } from 'utils/percentFormat';

import { CNAE_LIST } from 'utils/constants/ProductRegistrationConstants';

type DetailsType = {
  id: string,
  name: string,
  status: string,
  internal_code: string,
  description: string,
  sales: number,
  value: number,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: number,
  irrf: number,
  pis: number,
  cofins: number,
  csll: number,
  checkout_product_hash?: string,
  created_at: string,
  updated_at: string,
}

type DetailsDisplayType = {
  id: string,
  name: string,
  status: string,
  internal_code: string,
  description: string,
  sales: string,
  value: string,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: string,
  irrf: string,
  pis: string,
  cofins: string,
  csll: string,
  created_at: string,
  updated_at: string,
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  return {
    id: details.id,
    name: details.name,
    status: details.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
    internal_code: details.internal_code ? details.internal_code : 'N/A',
    description: details.description ? details.description : 'N/A',
    sales: details.sales.toString(),
    value: formatMoney(details.value),
    cnae_code: `${details.cnae_code}${CNAE_LIST[details.cnae_code] ? ' - ' + CNAE_LIST[details.cnae_code] : ''}`,
    service_code: details.service_code,
    custom_service_code: details.custom_service_code ? details.custom_service_code : 'N/A',
    iss: formatPercent(details.iss),
    irrf: formatPercent(details.irrf),
    pis: formatPercent(details.pis),
    cofins: formatPercent(details.cofins),
    csll: formatPercent(details.csll),
    created_at: dayjs(details.created_at).format('DD/MM/YYYY'),
    updated_at: dayjs(details.updated_at).format('DD/MM/YYYY'),
  }
}

export { formatDetailsToDisplay }

export type { DetailsType, DetailsDisplayType }