const PAYMENT_METHODS: any = {
  'BOLETO': 'Boleto',
  'BANK_DEPOSIT': 'Depósito bancário',
  'CREDIT_CARD': 'Cartão de crédito',
  'PIX': 'PIX'
}

const BOLETO = 'BOLETO'
const BANK_DEPOSIT = 'BANK_DEPOSIT'
const CREDIT_CARD = 'CREDIT_CARD'
const PIX = 'PIX'

export { PAYMENT_METHODS, BOLETO, BANK_DEPOSIT, CREDIT_CARD, PIX }