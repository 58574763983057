import { useRecoilState } from 'recoil';

import { checkoutOrderPayloadAtom } from './states';

function useCreateCheckoutOrderActions() {
    const [checkoutOrderPayload, setCheckoutOrderPayload] = useRecoilState(checkoutOrderPayloadAtom)

    return {
        checkoutOrderPayload,
        setCheckoutOrderPayload,
    };
}

export { useCreateCheckoutOrderActions };