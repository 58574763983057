import { useState } from "react";

type ViewModelPropTypes = {
  text: string
}

export default function DisplayInfoCopyViewModel({ text }: ViewModelPropTypes) {
  const [isTooltipOpen, setTooltipOpen] = useState(false)

  const handleOpen = async () => {
    await navigator.clipboard.writeText(text)
    setTooltipOpen(true)
  }
  const handleClose = () => {
    setTooltipOpen(false)
  }

  return { isTooltipOpen, handleClose, handleOpen }
}