import { useEffect, useState } from 'react'
import { ValidationErrors } from 'final-form'

import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'

import { getSupplier, SupplierType, formatSupplierToDisplay, SupplierTypeToDisplay, patchSupplier } from './services'

type ViewModelPropTypes = {
  supplierId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EditSupplierViewModel({ supplierId, onClose, onSubmit }: ViewModelPropTypes) {
  const [supplier, setSupplier] = useState<SupplierType>()
  const [formattedSupplier, setFormattedSupplier] = useState<SupplierTypeToDisplay>()
  const [type, setType] = useState<string | undefined>(undefined)

  const [initialDetails, setInitialDetails] = useState<string[]>([])

  const [supplierLoading, setSupplierLoading] = useState(true)
  const [supplierLoadingError, setSupplierLoadingError] = useState(false)

  const [formError, setFormError] = useState(false)
  const [editError, setEditError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onGetSupplierHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setSupplierLoadingError(true)

      setSupplier(undefined);
      setFormattedSupplier(undefined);
    } else {
      setSupplierLoadingError(true)

      setSupplier(undefined);
      setFormattedSupplier(undefined);
    }
  }

  const onPatchSupplierHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setSupplierLoading(true);

      if (supplierId) {
        const response = await getSupplier(supplierId, abortController.signal);

        if (response.errors) {
          onGetSupplierHandleError(response.errors)
        } else {
          let supplierType = 'brlInternationalCustomerRegime'
          const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
          const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/

          if (cpfRegex.test(response.document))
            supplierType = 'brlPeopleRegime'
          else if (cnpjRegex.test(response.document))
            supplierType = 'brlCompanyRegime'

          setType(supplierType)
          setSupplier(response)
          setFormattedSupplier(formatSupplierToDisplay(response))
          setInitialDetails(response.details)
        }
      }

      setSupplierLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [supplierId])

  const handleSubmit = async (values: Record<string, any>) => {
    setSupplierLoading(true);
    const response = await patchSupplier(supplierId, values as SupplierTypeToDisplay)

    if (response.errors)
      onPatchSupplierHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setSupplierLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {
      address: {}
    };

    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
    const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/
    const cepRegex = /\d{5}-\d{3}/

    if (!values.name)
      errors.name = 'Obrigatório'

    if (values.document) {
      if (type === 'brlPeopleRegime' && !cpfRegex.test(values.document))
        errors.document = 'CPF inválido'
      else if (type === 'brlCompanyRegime' && !cnpjRegex.test(values.document))
        errors.document = 'CNPJ inválido'
    }

    if (values.emails && values.emails.length > 0) {
      values.emails.forEach((email: string) => {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
          errors.emails = 'Existem emails inválidos'
      })
    }

    if (type !== 'brlInternationalCustomerRegime' && values.phones) {
      values.phones.forEach((phone: string) => {
        const onlyNumber = phone.replace(/\D/g, '');
        if (!/^(\d{2}|\(\d{2}\)) ?[0-9]{4,5}(-| )?[0-9]{4}$/.test(onlyNumber))
          errors.phones = 'Existem telefones inválidos. O número deve ser (xx) 9xxxx-xxxx ou (xx) xxxx-xxxx'
      })
    }

    if (values.address?.postal_code !== undefined ||
        values.address?.country !== undefined ||
        values.address?.street !== undefined ||
        values.address?.number !== undefined ||
        values.address?.neighbourhood !== undefined ||
        values.address?.state_province !== undefined) {

      if (type !== 'brlInternationalCustomerRegime')
        if (!values.address?.postal_code)
          errors.address.postal_code = 'Obrigatório'
        else if (!cepRegex.test(values.address?.postal_code))
          errors.address.postal_code = 'CEP inválido'

      if (type === 'brlInternationalCustomerRegime')
        if (!values.address?.country)
          errors.address.country = 'Obrigatório'

      if (!values.address?.street)
        errors.address.street = 'Obrigatório'

      if (!values.address?.number)
        errors.address.number = 'Obrigatório'

      if (!values.address?.neighbourhood)
        errors.address.neighbourhood = 'Obrigatório'

      if (type === 'brlInternationalCustomerRegime')
        if (!values.address?.state_province)
          errors.address.state_province = 'Obrigatório'

      if (type !== 'brlInternationalCustomerRegime')
        if (!values.address?.state_abbreviation)
          errors.address.state_abbreviation = 'Obrigatório'

      if (!values.address?.city)
        errors.address.city = 'Obrigatório'
    }

    return errors;
  }


  return {
    type,
    supplier,
    supplierLoading,
    supplierLoadingError,
    editError,
    initialDetails,
    formattedSupplier,
    formError,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}