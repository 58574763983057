import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { AutocompleteAdapter } from 'components/FormComponents/Fields/AutocompleteAdapter';
import { PercentFieldAdapter } from 'components/FormComponents/Fields/PercentFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss'
import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';

type CreateProductModalPropTypes = {
  open: boolean,
  type: string,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function CreateProductModal({ open, type, onClose, onSubmit }: CreateProductModalPropTypes) {
  const {
    cnaeList,
    createLoading,
    createError,
    formError,
    openSnackbar,
    serviceList,
    valueCnae,
    valueService,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    searchCNAE,
    searchService
  } = useViewModel({ type, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='create-product-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Criar Produto - {type === 'recurring' ? 'Assinatura' : 'Spot'}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>

                  {type === 'recurring' ? (
                    <Flexbox size={12}>
                      <Flexbox size={6}>
                        <TextFieldAdapter
                          label={'Nome'}
                          name='name'
                          disabled={createLoading}
                        />
                      </Flexbox>
                      <Flexbox size={6}>
                        <TextFieldAdapter
                          label={'Código interno'}
                          name='internal_code'
                          disabled={createLoading}
                        />
                      </Flexbox>
                    </Flexbox>
                  ) : (
                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          label={'Nome'}
                          name='name'
                          disabled={createLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          label={'Código interno'}
                          name='internal_code'
                          disabled={createLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <CurrencyFieldAdapter
                          label={'Valor'}
                          name='value'
                          disabled={createLoading}
                        />
                      </Flexbox>
                    </Flexbox>
                  )}

                  <Flexbox size={12}>
                    <TextFieldAdapter
                      label={'Descrição'}
                      name='description'
                      disabled={createLoading}
                      multiline
                      rows={3}
                    />
                  </Flexbox>

                  <Typography variant='h3' fontWeight={700} className='section-title'>Características fiscais</Typography>

                  <Flexbox size={12}>
                    <AutocompleteAdapter
                      label='CNAE'
                      name='cnae_code'
                      options={cnaeList}
                      value={valueCnae}
                      onChange={searchCNAE}
                      disabled={createLoading}
                    />
                  </Flexbox>

                  <Flexbox size={12}>
                    <Flexbox size={6}>
                      <AutocompleteAdapter
                        label='Código de serviço'
                        name='service_code'
                        options={serviceList}
                        value={valueService}
                        onChange={searchService}
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={6}>
                      <TextFieldAdapter
                        label='Código de serviço secundário'
                        name='custom_service_code'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  </Flexbox>

                  <Flexbox size={4}>
                    <PercentFieldAdapter
                      label='Alíquota de ISS'
                      name='iss'
                      disabled={createLoading}
                    />
                  </Flexbox>

                  <Typography variant='h3' fontWeight={700} className='section-title'>Características fiscais</Typography>

                  <Flexbox size={12}>
                    <Flexbox size={4}>
                      <PercentFieldAdapter
                        label='IRRF'
                        name='irrf'
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={4}>
                      <PercentFieldAdapter
                        label='PIS/PASEP'
                        name='pis'
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={4}>
                      <PercentFieldAdapter
                        label='COFINS'
                        name='cofins'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  </Flexbox>

                  <Flexbox size={4}>
                    <PercentFieldAdapter
                      label='CSLL'
                      name='csll'
                      disabled={createLoading}
                    />
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained'>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(createError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!createError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Produto criado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { CreateProductModal }