import { useEffect, useState } from 'react';

import { DetailsDisplayType, DetailsType, formatDetailsToDisplay } from './services';

type ViewModelProps = {
  product?: DetailsType,
  loading: boolean,
  loadingError: boolean
}

export default function ProductSpotGeneralViewModel({ product, loading, loadingError }: ViewModelProps) {
  const [details, setDetails] = useState<DetailsType | undefined>(product);
  const [formattedDetails, setFormattedDetails] = useState<DetailsDisplayType>();
  const [detailsLoading, setDetailsLoading] = useState(loading)
  const [detailsLoadingError, setDetailsLoadingError] = useState(loadingError)

  useEffect(() => {
    if (product) {
      setDetails(product);
      setFormattedDetails(formatDetailsToDisplay(product));
      setDetailsLoading(false)
    }

  }, [product, detailsLoading])

  useEffect(() => {
    setDetailsLoadingError(loadingError)
  }, [loadingError])

  return {
    details,
    detailsLoading,
    detailsLoadingError,
    formattedDetails
  }
}