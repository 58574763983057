import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from '../../../../utils/auth';

import { FilterType, getProducts, getSales, formatSalesToDisplay, ProductsType, SaleType, SaleDisplayType, SortType, TagsType, getTags } from './services';

const getProductsServiceHandler = async (search: string, signal: AbortSignal) => {
  const response = await getProducts(search, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect();


    return [];
  }

  return response;
}

const getTagsServiceHandler = async (search: string, signal: AbortSignal): Promise<Array<TagsType> | void> => {
  const response = await getTags(search, signal);

  if (response.errors) {
    if (response.errors[0]?.status === 401)
      notAuthenticatedRedirect();


    return [];
  }

  return response;
}


export default function SalesViewModel() {
  const [sales, setSales] = useState<Array<SaleType>>();
  const [formattedSales, setFormattedSales] = useState<Array<SaleDisplayType>>();
  const [searchSales, setSearchSales] = useState('');

  const [products, setProducts] = useState<Array<ProductsType>>()
  const [searchProducts, setSearchProducts] = useState('');

  const [tags, setTags] = useState<Array<TagsType>>()
  const [searchTags, setSearchTags] = useState('')

  const [salesLoading, setSalesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);

  const [salesLoadingError, setSalesLoadingError] = useState(false);

  // filters
  const [periodEndFilter, setPeriodEndFilter] = useState<FilterType[]>([{
    field: 'period_end',
    comparator: 'gte',
    value: []
  }, {
    field: 'period_end',
    comparator: 'lte',
    value: []
  }])

  const [periodStartFilter, setPeriodStartFilter] = useState<FilterType[]>([{
    field: 'period_start',
    comparator: 'gte',
    value: []
  }, {
    field: 'period_start',
    comparator: 'lte',
    value: []
  }])

  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: ['ACTIVE']
  });

  const [productsFilter, setProductsFilter] = useState<FilterType>({
    field: 'product_id',
    comparator: 'in',
    value: []
  });

  const [tagsFilter, setTagsFilter] = useState<FilterType>({
    field: 'tags',
    comparator: 'in',
    value: []
  });

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalSales, setTotalSales] = useState(0);

  const [actionSaleIndex, setActionSaleIndex] = useState<number>();

  const [openApplyCoupon, setOpenApplyCoupon] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'status', label: 'Status' },
    { id: 'product_name', label: 'Produto' },
    { id: 'period_start', label: 'Início' },
    { id: 'period_end', label: 'Término' },
    { id: 'tags', label: 'Tags' },
  ];

  const statusOptions = [
    { label: 'Ativos', value: 'ACTIVE' },
    { label: 'Expirados', value: 'EXPIRED,STANDBY_EXPIRE' },
    { label: 'Cancelados', value: 'TERMINATED,STANDBY_CANCEL' },
  ];


  const onGetSalesHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setSalesLoadingError(true)

      setSales([]);
      setFormattedSales([])
      setTotalSales(0);
    } else {
      setSalesLoadingError(true)

      setSales([]);
      setFormattedSales([])
      setTotalSales(0);
    }
  }


  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setSalesLoading(true);

      const response = await getSales(
        [...periodEndFilter, ...periodStartFilter, productsFilter, statusFilter, tagsFilter],
        searchSales,
        sort,
        { page, limit: rowsPerPage },
        abortController.signal
      );

      if (response.errors) {
        onGetSalesHandleError(response.errors)
      } else {
        setSalesLoadingError(false)
        setSales(response.sales as Array<SaleType>);
        setFormattedSales(formatSalesToDisplay(response.sales))

        if (response.pagination)
          setTotalSales(response.pagination.totalSales);
      }

      setSalesLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort();
    }

  }, [page, periodEndFilter, periodStartFilter, productsFilter, rowsPerPage, searchSales, sort, statusFilter, tagsFilter]);

  useEffect(() => {
    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {
        setProductsLoading(true);

        const response = await getProductsServiceHandler(searchProducts, abortController.signal);
        setProducts(response as Array<ProductsType>);

        setProductsLoading(false);
      })();
    }, 500)

    return () => {
      abortController.abort();
      clearTimeout(debounce);
    }
  }, [searchProducts]);

  useEffect(() => {
    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {
        setTagsLoading(true);

        const response = await getTagsServiceHandler(searchTags, abortController.signal);
        setTags(response as Array<TagsType>);

        setTagsLoading(false);
      })();
    }, 500)

    return () => {
      abortController.abort();
      clearTimeout(debounce);
    }
  }, [searchTags]);



  const applyPeriodStartFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...periodStartFilter];

    newDateFilter[0].value = startDate ? [startDate.format('YYYY-MM-DD')] : [];
    newDateFilter[1].value = endDate ? [endDate.format('YYYY-MM-DD')] : [];

    setPeriodStartFilter(newDateFilter);
  }

  const applyPeriodEndFilter = (startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null) => {
    setPage(1);
    const newDateFilter = [...periodEndFilter];

    newDateFilter[0].value = startDate ? [startDate.format('YYYY-MM-DD')] : [];
    newDateFilter[1].value = endDate ? [endDate.format('YYYY-MM-DD')] : [];

    setPeriodEndFilter(newDateFilter);
  }

  const applyProductsFilter = (optionsSelected: Array<{ value: string, label: string }>) => {
    setPage(1);
    setProductsFilter({ ...productsFilter, value: optionsSelected.map(option => option.value) });
  }

  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const applyTagsFilter = (optionsSelected: Array<{ value: string, label: string }>) => {
    setPage(1);
    setTagsFilter({ ...tagsFilter, value: optionsSelected.map(option => option.value) })
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {

      case 'edit':
        setActionSaleIndex(index)
        setOpenEdit(true);
        break;

      case 'applyCoupon':
        setActionSaleIndex(index)
        setOpenApplyCoupon(true)
        break;

      case 'cancel':
        setActionSaleIndex(index)
        setOpenCancel(true)
        break;
    }
  }

  const onCloseApplyCoupon = () => {
    setOpenApplyCoupon(false)
    setActionSaleIndex(undefined)
  }

  const onCloseCancel = () => {
    setOpenCancel(false)
  }

  const onCloseEdit = () => {
    setOpenEdit(false)
    setActionSaleIndex(undefined)
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onProductSearchChange = (search: string) => {
    setSearchProducts(search);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  const onSalesSearchChange = (search: string) => {
    setPage(1);
    setSearchSales(search);
  }

  const onTagSearchChange = (search: string) => {
    setSearchTags(search);
  }

  return {
    actionSaleIndex,
    openApplyCoupon,
    openCancel,
    openEdit,
    optionsRowsPerPage,
    page,
    periodEndFilter,
    periodStartFilter,
    products,
    productsFilter,
    productsLoading,
    rowsPerPage,
    sales,
    formattedSales,
    salesLoading,
    salesLoadingError,
    searchProducts,
    searchSales,
    searchTags,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    tags,
    tagsFilter,
    tagsLoading,
    totalSales,
    applyPeriodEndFilter,
    applyPeriodStartFilter,
    applyProductsFilter,
    applyStatusFilter,
    applyTagsFilter,
    handleTableRowActions,
    onCloseApplyCoupon,
    onCloseCancel,
    onChangeSort,
    onCloseEdit,
    onPageChange,
    onProductSearchChange,
    onRowsPerPageChange,
    onSalesSearchChange,
    onTagSearchChange,
  };
}