import {PlanType, formatPlanToDisplay} from "./services";

export default function ViewGeneralPlanViewModel(loading:boolean, loadingError:boolean, planData?: PlanType) {

  const planLoadingError = loadingError;
  const planLoading = loading;

  const formattedPlan = planData ? formatPlanToDisplay(planData) : undefined;

  return {
    planLoading,
    planLoadingError,
    formattedPlan
  }
}