import { useRecoilState } from 'recoil';

import { salePayloadAtom } from './states';

function useCreateSaleActions() {
  const [salePayload, setSalePayload] = useRecoilState(salePayloadAtom)

  const resetPayload = () => {
    setSalePayload({
      payment_terms: {},
      taxes: {}
    })
  }

  return {
    salePayload,
    resetPayload,
    setSalePayload,
  };
}

export { useCreateSaleActions };