import { Alert, Box, Button, Modal, Paper, Snackbar, Typography, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


import useViewModel, { CouponType } from './viewModel'

import '../../styles.scss'
import './styles.scss'

type DeactivateCouponModalPropTypes = {
  couponData: CouponType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function DeactivateCouponModal({ couponData, open, onClose, onSubmit }: DeactivateCouponModalPropTypes) {
  const {
    couponLoading,
    couponError,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ couponData, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='deactivate-coupon-modal custom-modal'>
          <div className='modal-header'>
            <Typography variant='h2'>
              Desativar cupom
            </Typography>
            <div className='modal-close-button' onClick={onClose}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body'>
            <Typography>
              Tem certeza que deseja desativar este cupom?
            </Typography>
          </div>

          <div className='modal-footer'>
            {couponLoading && <CircularProgress className='loading' color='primary' size={20} />}
            <Button variant='outlined' disabled={couponLoading} onClick={onClose}>Não</Button>
            <Button variant='contained' color='error' disabled={couponLoading} onClick={handleSubmit}>Sim</Button>
          </div>
        </Box>
      </Modal>

      {couponError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!couponError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Cupom desativado!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { DeactivateCouponModal }