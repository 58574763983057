import { Typography } from '@mui/material'

import { ApiErrorView } from 'components/ApiErrorView';
import { DisplayInfoText } from 'components/Info/DisplayInfoText'
import { DisplayInfoLink } from 'components/Info/DisplayInfoLink'
import { DisplayInfoCopy } from 'components/Info/DisplayInfoCopy'
import { Flexbox } from 'components/Flexbox'

import useViewModel from './viewModel'
import { DisplayInfoTextOpenNewTab } from 'components/Info/DisplayInfoTextOpenNewTab'

type InvoiceGroupGeneralViewPropTypes = {
  invoiceGroupId: string
}

function InvoiceGroupGeneralView({ invoiceGroupId }: InvoiceGroupGeneralViewPropTypes) {
  const {
    formattedDetails,
    detailsLoading,
    detailsLoadingError
  } = useViewModel({ invoiceGroupId })

  return (
    <div className='details'>

      {detailsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os detalhes da fatura. Tente recarregar a página.' />
      }

      {!detailsLoadingError && (detailsLoading || formattedDetails !== undefined) &&
      <>
        <div className="basic-info">
          <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

          <div className="info-row">
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Status'
                text={formattedDetails?.status ? formattedDetails.status : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoCopy
                isLoading={detailsLoading}
                title='Chave'
                text={formattedDetails?.key ? formattedDetails.key : ''}
              />
            </Flexbox>
            <DisplayInfoTextOpenNewTab
              isLoading={detailsLoading}
              title='Cliente'
              texts={formattedDetails?.customer.name ? [formattedDetails.customer.name] : []}
              links={formattedDetails?.customer.name ? [`/clients/${formattedDetails?.customer.id}`] : []}
            />
          </div>

          <div className="info-row">
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Data de criação'
                text={formattedDetails?.created_at ? formattedDetails.created_at : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Data de vencimento'
                text={formattedDetails?.due_date ? formattedDetails.due_date : ''}
              />
            </Flexbox>
          </div>

          <div className="info-row">
            <DisplayInfoLink
              isLoading={detailsLoading}
              title='Arquivo em PDF'
              text={formattedDetails?.file_url ? formattedDetails?.file_url : 'N/A'}
              link={formattedDetails?.file_url ? formattedDetails.file_url : ''}
            />
          </div>

        </div>

        <div className="charge-info">
          <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações sobre a cobrança</Typography>

          <div className="info-row">
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Total'
                text={formattedDetails?.total_value ? formattedDetails.total_value : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Status do pagamento'
                text={formattedDetails?.payment_status ? formattedDetails.payment_status : ''}
              />
            </Flexbox>
            <DisplayInfoTextOpenNewTab
              isLoading={detailsLoading}
              title='Cobranças associadas'
              texts={formattedDetails?.account_receivable ? formattedDetails.account_receivable.map((ar) => (ar.key)) : []}
              links={formattedDetails?.account_receivable ? formattedDetails.account_receivable.map((ar) => (`/accounts-receivables/${ar.id}`)) : []}
            />
          </div>

          <div className="info-row">
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Subtotal'
                text={formattedDetails?.subtotal_value ? formattedDetails.subtotal_value : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Descontos'
                text={formattedDetails?.discount_value ? formattedDetails.discount_value : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Impostos retidos'
                text={formattedDetails?.tax_value ? formattedDetails.tax_value : ''}
              />
            </Flexbox>
          </div>

          <div className="info-row">
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Produto'
                text={formattedDetails?.product_name ? formattedDetails.product_name : ''}
              />
            </Flexbox>
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Plano'
                text={formattedDetails?.plan_name ? formattedDetails.plan_name : ''}
              />
            </Flexbox>
          </div>
        </div>

        <div className="fiscal-info">
          <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações sobre a nota fiscal</Typography>

          <div className="info-row">
            <Flexbox size={3}>
              <DisplayInfoText
                isLoading={detailsLoading}
                title='Status da nota fiscal'
                text={formattedDetails?.receipt_status ? formattedDetails.receipt_status : ''}
              />
            </Flexbox>
            {formattedDetails?.receipt_id && <DisplayInfoTextOpenNewTab
              isLoading={detailsLoading}
              title='Notas fiscais associadas'
              texts={formattedDetails?.receipt_id ? [`NF-${formattedDetails.receipt_id}`] : []}
              links={formattedDetails?.receipt_id ? [`/invoices-receipts/${formattedDetails?.receipt_id}`] : []}
            />}
            {!formattedDetails?.receipt_id && <DisplayInfoText
              isLoading={detailsLoading}
              title='Notas fiscais associadas'
              text={'N/A'}
            />}
          </div>
        </div>  
      </>
      }
    </div>
  )
}

export { InvoiceGroupGeneralView } 