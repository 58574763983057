import { TextField } from '@mui/material';

type SearchFilterPropTypes = {
  placeholder: string,
  onSubmit: (search: string) => void
}

function SearchFilter({
  placeholder,
  onSubmit,
}: SearchFilterPropTypes) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e.currentTarget.search.value);
      }}
    >
      <TextField
        placeholder={placeholder}
        name='search'
        fullWidth
        size='small'
      />
    </form>
  );
}

export { SearchFilter };