import { CustomError, api } from 'utils/axios';
import dayjs from 'dayjs'

import { formatMoney } from 'utils/moneyFormatt'

type DetailsType = {
  id: string,
  value: number,
  interest_and_penalty_value: number,
  tax_value: number,
  description: string,
  cashier_id: number,
  cashier_name: string,
  customer_id: number,
  customer_name: string,
  effective_date: string,
  source_id: number,
  source_type: string,
  source_key: string,
  cashier_statement_items: {
    value: number,
    interest_and_penalty_value: number,
    tax_value: number,
    customer_id: number,
    customer_name: string,
    source_id: number,
    source_type: string,
    source_key: string
  }[],
  created_at: string,
  updated_at: string
}

type DetailsDisplayType = {
  id: string,
  value: string,
  interest_and_penalty_value: string,
  tax_value: string,
  description: string,
  cashier: {
    id: number,
    cashier_name: string
  },
  customer: {
    id: number,
    name: string,
  },
  effective_date: string,
  source: {
    id: string,
    description: string
  },
  cashier_statement_items: {
    value: string,
    interest_and_penalty_value: string,
    tax_value: string,
    customer_id: number,
    customer_name: string,
    source_id: string,
    source_type: string,
    source_key: string
  }[] | null,
  created_at: string,
  updated_at: string
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    value: response.data.attributes.value,
    interest_and_penalty_value: response.data.attributes.interest_and_penalty_value,
    tax_value: response.data.attributes.tax_value,
    description: response.data.attributes.description,
    cashier_id: response.data.attributes.cashier_id,
    cashier_name: response.data.attributes.cashier_name,
    customer_id: response.data.attributes.customer_id,
    customer_name: response.data.attributes.customer_name,
    effective_date: response.data.attributes.effective_date?.date,
    source_id: response.data.attributes.source_id,
    source_type: response.data.attributes.source_type,
    source_key: response.data.attributes.source_key,
    cashier_statement_items: response.data.attributes.cashier_statement_items.map((csItem: any) => {
      return {
        value: csItem.value,
        interest_and_penalty_value: csItem.interest_and_penalty_value,
        tax_value: csItem.tax_value,
        customer_id: csItem.customer_id,
        customer_name: csItem.customer_name,
        source_id: csItem.source_id,
        source_type: csItem.source_type,
        source_key: csItem.source_key
      }
    }),
    created_at: response.data.attributes.created_at?.date,
    updated_at: response.data.attributes.updated_at?.date
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/cashier-statement/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {

  let sourceDescription = ''
  let sourceId = ''
  switch (details.source_type) {
    case 'SOURCE_SINGLE_RELEASE': sourceDescription = 'Lançamento avulso'; break;
    case 'SOURCE_MULTIPLE_RELEASE': sourceDescription = 'Lançamento agrupado'; break;
    case 'SOURCE_TRANSFER': sourceDescription = 'Transferência entre contas'; break;
    case 'SOURCE_BALANCE': sourceDescription = 'Ajuste de saldo'; break;
    case 'SOURCE_ACCOUNT_RECEIVABLE': sourceDescription = 'Conta a receber ' + details.source_key; sourceId = details.source_id.toString(); break;
    default:
      sourceDescription = '';
  }

  return {
    id: details.id,
    value: formatMoney(details.value),
    interest_and_penalty_value: formatMoney(details.interest_and_penalty_value),
    tax_value: formatMoney(details.tax_value),
    description: details.description,
    cashier: {
      id: details.cashier_id,
      cashier_name: details.cashier_name
    },
    customer: {
      id: details.customer_id,
      name: details.customer_name
    },
    effective_date: details.effective_date ? dayjs(details.effective_date).format('DD/MM/YYYY') : '-',
    source: {
      id: sourceId,
      description: sourceDescription
    },
    cashier_statement_items: details.cashier_statement_items.length === 0 ? null : details.cashier_statement_items.map((csItem) => {
      let itemSourceDescription = ''
      let itemSourceId = ''
      switch (csItem.source_type) {
        case 'SOURCE_SINGLE_RELEASE': itemSourceDescription = 'Lançamento avulso'; break;
        case 'SOURCE_MULTIPLE_RELEASE': itemSourceDescription = 'Lançamento agrupado'; break;
        case 'SOURCE_TRANSFER': itemSourceDescription = 'Transferência entre contas'; break;
        case 'SOURCE_BALANCE': itemSourceDescription = 'Ajuste de saldo'; break;
        case 'SOURCE_ACCOUNT_RECEIVABLE': itemSourceDescription = 'Conta a receber ' + csItem.source_key; itemSourceId = csItem.source_id.toString(); break;
        default:
          sourceDescription = '';
      }

      return {
        value: formatMoney(csItem.value),
        interest_and_penalty_value: formatMoney(csItem.interest_and_penalty_value),
        tax_value: formatMoney(csItem.tax_value),
        customer_id: csItem.customer_id,
        customer_name: csItem.customer_name,
        source_id: itemSourceId,
        source_type: csItem.source_type,
        source_key: itemSourceDescription
      }
    }),
    created_at: details.created_at ? dayjs(details.created_at).format('DD/MM/YYYY') : '-',
    updated_at: details.updated_at ? dayjs(details.updated_at).format('DD/MM/YYYY') : '-'
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }