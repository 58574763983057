import { ReactElement } from 'react';
import { Skeleton, Typography } from '@mui/material';

import './styles.scss'

type TotalsPropType = {
  icon?: ReactElement,
  title: string,
  value: number | string,
  caption?: string,
  isLoading?: boolean
}

function Totals({ caption, icon, title, value, isLoading }: TotalsPropType) {
  return (
    <div className='totals-card'>
      <div className='title'>
        <Typography variant='caption'>
          {title}
        </Typography>
      </div>

      {icon &&
        <div className='icon'>
          {icon}
        </div>
      }

      <div className='content'>
        <Typography variant='h3' fontWeight={700}>
          {isLoading ? <Skeleton /> : value}
        </Typography>
      </div>

      <div className='caption'>
        <Typography variant='caption'>
          {caption}
        </Typography>
      </div>
    </div>
  );
}

export { Totals };