const required = (value: string) => value ? '' : 'Obrigatório';

const confirmPassword = (values: Record<string, any>) => {
  const errors: any = {}

  if (values.password !== values.confirmPassword)
    errors.confirmPassword = 'Os campos de senha precisam ser iguais.'

  return errors
}

export { confirmPassword, required };