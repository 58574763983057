import { CustomError, api } from 'utils/axios';

const parseDescription = (response: any) => response.data.attributes.description;

const getDescription = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/invoices-receipts/${id}`, { signal })

    return parseDescription(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const postReopen = async (id: string, values: Record<string, any>): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/invoices-receipts/${id}/reopen`, {
      description: values.description ? values.description : ''
    })

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getDescription, postReopen }