import { Alert, Snackbar } from '@mui/material';
import { ReactNode } from 'react';

import useViewModel from './viewModel';

type GlobalSnackbarPropTypes = {
  children: ReactNode
}

function GlobalSnackbar({ children }: GlobalSnackbarPropTypes) {
  const { snackbar } = useViewModel()

  return (
    <>
      {children}
      <Snackbar {...snackbar}>
        <Alert onClose={(e) => {
          if (snackbar.onClose)
            snackbar.onClose(e, 'timeout')
        }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export { GlobalSnackbar }