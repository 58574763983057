import { MouseEvent, useState } from 'react';

export default function ActionButtonTableViewModel() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null)
  }

  return { anchorEl, open, close, handleClick }
}