import { useState, Fragment } from 'react';

import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';

import { Skeleton, TextField, Autocomplete, CircularProgress } from '@mui/material';

type OptionsTypes = {
  label: string,
  [key: string]: any
}

type AutocompleteAdapterProps = {
  disabled?: boolean,
  name: string,
  label: string,
  loading?: boolean,
  options: OptionsTypes[],
  searchLoading?: boolean,
  value: string,
  onChange: (value: string) => void,
  onOptionChange?: (value: any) => void,
  validate?: FieldValidator<any>
}

function ComponentField({
  disabled,
  input,
  label,
  meta,
  options,
  value,
  searchLoading,
  onChange,
  onOptionChange,
}: FieldRenderProps<any>) {

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value, reason) => {
        input.onChange(value)
        if (onOptionChange)
          onOptionChange(value)
      }}
      isOptionEqualToValue={(option, value) => (option as OptionsTypes).label === (value as OptionsTypes).label}
      getOptionLabel={(option) => ((option as OptionsTypes).label ? (option as OptionsTypes).label : '')}
      options={options}
      noOptionsText={'Nenhuma opção'}
      disabled={disabled}
      fullWidth
      loading={searchLoading}
      value={input.value ? input.value : null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          value={value}
          error={meta.touched && meta.error ? true : false}
          helperText={meta.touched ? meta.error : ''}
          FormHelperTextProps={{ className: 'helper-text' }}
          onChange={e => {
            onChange(e.target.value)
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {searchLoading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
        />
      )}
    />
  );
}

function AutocompleteAdapter({
  disabled = false,
  name,
  label,
  loading = false,
  options,
  searchLoading = false,
  value,
  onChange,
  onOptionChange,
  validate
}: AutocompleteAdapterProps) {

  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={ComponentField}
          disabled={disabled}
          name={name}
          label={label}
          options={options}
          searchLoading={searchLoading}
          value={value}
          onChange={onChange}
          validate={validate}
          onOptionChange={onOptionChange}
        />
      )}
    </div>
  );
}

export { AutocompleteAdapter };