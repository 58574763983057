import { useEffect, useState } from 'react';
import {ApiCheckoutError} from 'utils/CustomError';

import { useCreateCheckoutOrderActions } from 'recoil/checkout/actions';

import { 
    parseSale,
    getCoupons,
    getProducts,
    bundlePayloadToRecoil,
    formatCheckoutPayloadToDisplayProduct,
    formatCheckoutPayloadToDisplayCoupon,
    formatCheckoutPayloadToDisplaySale,
    ProductType,
    CouponType,
    SaleType
} from './services';

type viewModelPropTypes = {
    productHash: string,
    onNext: () => void
}

export default function ProductStepViewModel({ productHash, onNext }: viewModelPropTypes) {
  const { checkoutOrderPayload, setCheckoutOrderPayload } = useCreateCheckoutOrderActions()

  const [sales, setSales] = useState<SaleType>()

  const [products, setProducts] = useState<ProductType>()
  const [productsLoading, setProductsLoading] = useState(true)
  const [productsLoadingError, setProductsLoadingError] = useState(false)

  const [selectedProductHash, setSelectedProductHash] = useState<string>(productHash)

  const [coupons, setCoupons] = useState<CouponType>()
  const [couponsLoading, setCouponsLoading] = useState(false)
  const [couponsLoadingError, setCouponsLoadingError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onGetCouponsHandleError = (errors: ApiCheckoutError) => {
      setCouponsLoadingError(true)
      setOpenSnackbar(true)
  }

  const onGetProductsHandleError = (errors: ApiCheckoutError) => {
      setProductsLoadingError(true)
      setProductsLoadingError(true)
  }

  useEffect(() => {
    const salesFormatted = formatCheckoutPayloadToDisplaySale(checkoutOrderPayload)
    const productformatted = formatCheckoutPayloadToDisplayProduct(checkoutOrderPayload)
    const couponformatted = formatCheckoutPayloadToDisplayCoupon(checkoutOrderPayload)

    if (productformatted) {
      setProducts(productformatted)
      setSelectedProductHash(productformatted.hash ?? productHash)
    }

    if (salesFormatted)
      setSales(salesFormatted)

    if (couponformatted)
      setCoupons(couponformatted)

  }, [checkoutOrderPayload, productHash])

  useEffect(() => {
    if (checkoutOrderPayload.product?.hash) {
      setProductsLoading(false);
      return
    }

    const abortController = new AbortController();

    const debounce = setTimeout(() => {
        (async () => {
            setProductsLoading(true);

            const response = await getProducts(selectedProductHash, abortController.signal)
  
            if (response.errors) {
              onGetProductsHandleError(response.errors)
            } else {
              setProductsLoadingError(false)
              setProducts(response)
              setSales(parseSale(response))

              setCheckoutOrderPayload(bundlePayloadToRecoil(response, parseSale((response))))
            }
  
            setProductsLoading(false || abortController.signal.aborted);
          })()
    }, 500)

    return () => {
      clearTimeout(debounce)
      abortController.abort()
    }
  }, [selectedProductHash, checkoutOrderPayload, setCheckoutOrderPayload])


  const onApplyCoupon = (values: Record<string, any>) => {
    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {

        setCouponsLoading(true);

        const response = await getCoupons(selectedProductHash, values.coupon, abortController.signal)

        if (response.errors) {
          onGetCouponsHandleError(response.errors)
          setCoupons(undefined)
          if (products)
            setSales(parseSale(products, response))
        } else {
          setCouponsLoadingError(false)
          setOpenSnackbar(false)
          setCoupons(response)
          if (products)
            setSales(parseSale(products, response))
        }

        setCouponsLoading(false || abortController.signal.aborted);
      })()
    }, 500)

    return () => {
      clearTimeout(debounce)
      abortController.abort()
    }
  }

  const onSubmit = () => {
    setCheckoutOrderPayload({ ...checkoutOrderPayload, ...bundlePayloadToRecoil(products, sales, coupons) })
    onNext()
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    sales,
    coupons,
    couponsLoading,
    couponsLoadingError,
    products,
    productsLoading,
    productsLoadingError,
    checkoutOrderPayload,
    selectedProductHash,
    openSnackbar,
    setSales,
    setCoupons,
    setProducts,
    onApplyCoupon,
    onCloseSnackbar,
    onSubmit,
  }
} 