import { useEffect, useState } from 'react'

import { CouponOption, getCoupons, postApplyCoupon } from './services'

import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type SaleType = {
  id: string,
  key: string
}

type ViewModelPropTypes = {
  saleData: SaleType,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function ApplyCouponViewModel({ saleData, onClose, onSubmit }: ViewModelPropTypes) {
  const [saleLoading, setSaleLoading] = useState(false)
  const [saleError, setSaleError] = useState(false)

  const [coupons, setCoupons] = useState<CouponOption[]>()
  const [couponsLoading, setCouponsLoading] = useState(true)
  const [searchCoupons, setSearchCoupons] = useState<string>('')
  const [couponsLoadingError, setCouponsLoadingError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onpostApplyCouponHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setSaleError(true)
      setOpenSnackbar(true)
    } else {
      setSaleError(true)
      setOpenSnackbar(true)
    }
  }

  const onGetCouponsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCouponsLoadingError(true)
      setCoupons([]);
      setOpenSnackbar(true)
    } else {
      setCouponsLoadingError(true)
      setCoupons([]);
      setOpenSnackbar(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    const debounce = setTimeout(() => {
      (async () => {

        setCouponsLoading(true);

        const response = await getCoupons(searchCoupons, abortController.signal)
        if (response.errors) {
          onGetCouponsHandleError(response.errors)
        } else {
          setCouponsLoadingError(false)
          setCoupons(response)
        }

        setCouponsLoading(false || abortController.signal.aborted);
      })()
    }, 500)

    return () => {
      clearTimeout(debounce)
      abortController.abort()
    }
  }, [searchCoupons])


  const onCouponSearchChange = (search: string) => {
    setSearchCoupons(search);
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setSaleLoading(true)

    const response = await postApplyCoupon(saleData.id, values.coupon.value.coupon_id)

    if (response.errors)
      onpostApplyCouponHandleError(response.errors)

    else {
      setSaleError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setSaleLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: Record<string, any>) => {
    const errors: ValidationErrors = {}

    if (!values.coupon)
      errors.coupon = 'Obrigatório'

    return errors
  }

  return {
    coupons,
    couponsLoading,
    couponsLoadingError,
    saleError,
    saleLoading,
    searchCoupons,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    onCouponSearchChange,
  }
}

export type { SaleType }