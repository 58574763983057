import useViewModel from './viewModel';

import { ProductListTable } from '../../../../components/Tables/ProductListTable';
import { RangeFilter } from '../../../../components/Filters/RangeFilter';
import { SearchFilter } from '../../../../components/Filters/SearchFilter';
import { CheckboxFilter } from '../../../../components/Filters/CheckboxFilter';
import { EmptyView } from 'components/EmptyView';
import { ApiErrorView } from 'components/ApiErrorView';
import { EditProductModal } from 'components/Modals/Product/Edit';
import { ActivateProductModal } from 'components/Modals/Product/Activate';
import { DeactivateProductModal } from 'components/Modals/Product/Deactivate';

function ProductSpot() {
  const {
    actionProductIndex,
    formattedProducts,
    openEdit,
    openActivate,
    openDeactivate,
    optionsRowsPerPage,
    page,
    products,
    rowsPerPage,
    salesRangeLimits,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalProducts,
    productsLoading,
    productsLoadingError,
    applyRangeFilter,
    applyStatusFilter,
    handleTableRowActions,
    onChangeSort,
    onCloseEditModal,
    onCloseActivateModal,
    onCloseDeactivateModal,
    onPageChange,
    onProductSearchChange,
    onRowsPerPageChange
  } = useViewModel();

  return (
    <div className='listing-page'>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por Nome'
            onSubmit={onProductSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status-products-spot'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <RangeFilter
            id='range-products-spot'
            buttonLabel='Compras'
            label='Quantidade de compras'
            min={salesRangeLimits?.min}
            max={salesRangeLimits?.max}
            onApplyFilter={applyRangeFilter}
          />
        </div>
      </div>

      {!productsLoading &&
        !productsLoadingError &&
        formattedProducts?.length === 0 &&
        <EmptyView title={'Nenhum produto encontrado para mostrar aqui'}></EmptyView>
      }

      {productsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os produtos. Tente recarregar a página ou usar filtros diferentes.' />
      }

      {(productsLoading || (formattedProducts !== undefined && formattedProducts.length > 0)) &&
        <>
          <ProductListTable
            hasActions
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rowLink='/products/spot'
            rows={formattedProducts ? formattedProducts : []}
            rowsPerPage={rowsPerPage}
            total={totalProducts}
            isLoading={productsLoading}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <EditProductModal
            id={products && actionProductIndex !== undefined ? products[actionProductIndex].id : ''}
            type='spot'
            open={openEdit}
            onClose={onCloseEditModal}
          />

          <ActivateProductModal
            productData={{
              id: products && actionProductIndex !== undefined ? products[actionProductIndex].id : ''
            }}
            open={openActivate}
            onClose={onCloseActivateModal}
          />

          <DeactivateProductModal
            productData={{
              id: products && actionProductIndex !== undefined ? products[actionProductIndex].id : ''
            }}
            open={openDeactivate}
            onClose={onCloseDeactivateModal}
          />
        </>
      }
    </div>
  )
}

export { ProductSpot };