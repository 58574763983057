import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { getTags, SortType, TagsType } from './services';
import { ApiError } from 'utils/CustomError';

export default function TagsViewModel() {
  const [tags, setTags] = useState<Array<TagsType>>();

  const [tagLoading, setTagLoading] = useState(true);
  const [tagLoadingError, setTagLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTags, setTotalTags] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'name', label: 'Nome' },
    { id: 'description', label: 'Descrição' },
  ];


  const onGetTagsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setTagLoadingError(true)

      setTags([]);
      setTotalTags(0);
    } else {
      setTagLoadingError(true)

      setTags([]);
      setTotalTags(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setTagLoading(true);
      setTagLoadingError(false)

      const response = await getTags(sort, { page, limit: rowsPerPage }, abortController.signal);
      if (response.errors) {
        onGetTagsHandleError(response.errors)
      } else {
        setTagLoadingError(false)
        setTags(response.tags as Array<TagsType>);

        setTotalTags(response.pagination.totalTags);
      }

      setTagLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [page, rowsPerPage, sort])


  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    totalTags,
    tableHeadCells,
    tagLoading,
    tagLoadingError,
    tags,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  };
}