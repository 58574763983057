import { Alert, Button, CircularProgress, Skeleton, Paper, Snackbar, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Form } from 'react-final-form';

import { Flexbox } from 'components/Flexbox';
import { CreditCardDigitsFieldAdapter } from 'components/FormComponents/Fields/CreditCardDigitsFieldAdapter';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import { ReactComponent as AmexIcon } from 'images/icons/amex.svg'
import { ReactComponent as DinersIcon } from 'images/icons/diners.svg'
import { ReactComponent as DiscoverIcon } from 'images/icons/discover.svg'
import { ReactComponent as MastercardIcon } from 'images/icons/mastercard.svg'
import { ReactComponent as VisaIcon } from 'images/icons/visa.svg'

import useViewModel from './viewModel';

import './styles.scss'

function CreditCard() {
  const {
    detailsLoading,
    formattedDetails,
    detailsLoadingError,
    brand,
    creditCardLoading,
    creditCardLoadingError,
    finished,
    hashError,
    monthOptions,
    openSnackbar,
    yearOptions,
    formValidate,
    onSubmit,
    onCloseSnackbar,
    onDigitsChange,
  } = useViewModel()

  return (
    <div className='credit-card-page'>

      {!detailsLoading && detailsLoadingError &&
        <>
          Esse link é inválido ou o cartão de crédito já foi cadastrado.
        </>
      }

      {!detailsLoadingError && <>
        {detailsLoading && <Skeleton height={200} width={200} animation='wave'></Skeleton>}    
        {!detailsLoading &&
        <div className='logo'>
          <img src={formattedDetails?.company_logo} alt={formattedDetails?.company_name} />
        </div>}

        <div className='company-name'>
          {detailsLoading && <Skeleton width={300} height={50}></Skeleton>}
          {!detailsLoading &&
          <Typography variant='h1' color='secondary'>
            {formattedDetails?.company_name}
          </Typography>}
        </div>

        <div className='charge-details'>
          <div><span>Valor cobrado:</span><span className='value'>{formattedDetails?.total}</span></div>
          <div><span>Data de vencimento:</span><span className='value'>{formattedDetails?.due_date}</span></div>
        </div>

        <Paper className='form-box custom-modal'>
          <Form
            onSubmit={onSubmit}
            validate={formValidate}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                className='form-container'
              >
                <div className='modal-header'>
                  <Typography variant='h2'>Cadastro do cartão</Typography>
                </div>

                <Flexbox size={12}>
                  <CreditCardDigitsFieldAdapter
                    brandIcon={(
                      <>
                        {brand === 'american-express' && <AmexIcon />}
                        {brand === 'diners-club' && <DinersIcon />}
                        {brand === 'discover' && <DiscoverIcon />}
                        {brand === 'mastercard' && <MastercardIcon />}
                        {brand === 'visa' && <VisaIcon />}
                      </>
                    )}
                    name='digits'
                    label='Número do cartão'
                    disabled={creditCardLoading || detailsLoading || finished}
                    onChange={(e) => { onDigitsChange(e.target.value) }}
                  />
                </Flexbox>

                <Flexbox size={12}>
                  <TextFieldAdapter
                    name='holder_name'
                    label='Nome impresso no cartão'
                    disabled={creditCardLoading || detailsLoading || finished}
                  />
                </Flexbox>

                <Flexbox size={12}>
                  <Flexbox size={4}>
                    <SelectFieldAdapter
                      label='Mês'
                      name='expiration_month'
                      options={monthOptions}
                      disabled={creditCardLoading || detailsLoading || finished}
                    />
                  </Flexbox>
                  <Flexbox size={4}>
                    <SelectFieldAdapter
                      label='Ano'
                      name='expiration_year'
                      options={yearOptions}
                      disabled={creditCardLoading || detailsLoading || finished}
                    />
                  </Flexbox>
                  <Flexbox size={4}>
                    <TextFieldAdapter
                      label='CVC'
                      name='security_code'
                      inputProps={{ type: 'number', className: 'hide-input-controls' }}
                      disabled={creditCardLoading || detailsLoading || finished}
                    />
                  </Flexbox>
                </Flexbox>

                <div className='text-container'>
                  <Typography>*A cobrança será realizada apenas no dia do vencimento</Typography>
                </div>

                <div className='modal-footer'>
                  {creditCardLoading && <CircularProgress size={30}></CircularProgress>}
                  <Button size='large' variant='contained' type='submit' disabled={creditCardLoading || detailsLoading || finished}>
                    <Typography variant='body1' fontWeight={700}>
                      Enviar
                    </Typography>
                  </Button>
                </div>
              </form>
            )}
          />
        </Paper>

        <div className='security-container'>
          <Typography variant='h3' color='primary'>
            <LockIcon />
            Este é um site seguro
          </Typography>
        </div>

        {!creditCardLoadingError && !hashError &&
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
              Cartão de crédito cadastrado com sucesso!
            </Alert>
          </Snackbar>
        }

        {creditCardLoadingError &&
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
              Ops! Algo deu errado, tente de novo mais tarde.
            </Alert>
          </Snackbar>
        }</>
      }
    </div>
  );
}

export { CreditCard }