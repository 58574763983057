import { ClickAwayListener, Skeleton, Tooltip, Typography } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useViewModel from './viewModel'

import './styles.scss'

type DisplayInfoCopyPropTypes = {
  title: string,
  text: string,
  isLoading?: boolean
}

function DisplayInfoCopy({ title, text, isLoading = false }: DisplayInfoCopyPropTypes) {
  const { isTooltipOpen, handleClose, handleOpen } = useViewModel({text})

  return (
    <div className='display-info-copy'>
      <div className='title'>
        <Typography variant='caption'>
          {title}
        </Typography>
      </div>
      <div className='text-container'>
        {isLoading ? (
          <Skeleton className='skeleton-loading' />
        ) : <>
          <div className='text'>
            <Typography fontWeight={700} component='span'>
              {text}
            </Typography>
          </div>

          <ClickAwayListener
            onClickAway={handleClose}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleClose}
              open={isTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClick={handleOpen}
              title='Texto copiado!'
              placement='top'
            >
              <ContentCopyIcon className='copy-icon' />
            </Tooltip>
          </ClickAwayListener>

        </>}
      </div>
    </div>
  );
}

export { DisplayInfoCopy }