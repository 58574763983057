import { useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';
import { STATES } from 'utils/constants/states'
import { formatForInput } from 'utils/formatConstantForInput';

import { getCities, getTags, formatTagsToDisplay, ClientPayload, postClient } from './services'
import { FormApi, ValidationErrors } from 'final-form';

type ViewModelPropTypes = {
  type: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CreateClientModalViewModel({ type, onClose, onSubmit }: ViewModelPropTypes) {

  const [citiesError, setCitiesError] = useState(false)
  const [tagsLoading, setTagsLoading] = useState(false)
  const [tagsError, setTagsError] = useState(false)
  const [formattedTags, setFormattedTags] = useState(Array<string>)

  const [createLoading, setCreateLoading] = useState(false)
  const [createError, setCreateError] = useState(false)

  const [formError, setFormError] = useState(false)

  const states = formatForInput(STATES)
  const [selectedState, setSelectedState] = useState('')

  const [cityOptions, setCityOptions] = useState([])
  const [searchCityValue, setSearchCityValue] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState(true)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setTagsLoading(true);
      setTagsError(false)

      const response = await getTags(abortController.signal);

      if (response.errors) {
        onGetTagsHandleError(response.errors)
      } else {
        setTagsError(false)
        setFormattedTags(formatTagsToDisplay(response))
      }

      setTagsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }
  }, [])

  const onGetCitiesHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCityOptions([])
      setCitiesError(true)
      setOpenSnackbar(true)
    } else {
      setCityOptions([])
      setCitiesError(true)
      setOpenSnackbar(true)
    }

    return [];
  }

  const onGetTagsHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setTagsError(true)
      setOpenSnackbar(true)
    } else {
      setTagsError(true)
      setOpenSnackbar(true)
    }

    return [];
  }

  useEffect(() => {
    const abortController = new AbortController()


    setSearchLoading(true)

    const debounce = setTimeout(() => {
      if (selectedState) {
        (async () => {
          const response = await getCities(searchCityValue, selectedState, abortController.signal);

          if (response.errors) {
            return onGetCitiesHandleError(response.errors)
          }
          else {
            setCityOptions(response)
          }

        })()
      }
      setSearchLoading(false || abortController.signal.aborted);
    }, 500)

    return () => {
      abortController.abort()
      clearTimeout(debounce)
    }
  }, [searchCityValue, selectedState])

  const onPostClientHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setCreateError(true)
      setOpenSnackbar(true)
    } else {
      setCreateError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setCreateLoading(true);
    const response = await postClient(values as ClientPayload)

    if (response.errors)
      onPostClientHandleError(response.errors)

    else {
      setCreateError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setCreateLoading(false);
  }

  const onSearchCityChange = (value: string) => {
    setSearchCityValue(value)
  }

  const onStateChange = (e: SelectChangeEvent, form: FormApi) => {
    setSearchCityValue('')
    const fieldState = form.getFieldState('address.city')
    fieldState?.change(null)
    setSelectedState(e.target.value)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {
      address: {},
      regime: {}
    };

    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
    const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/
    const cepRegex = /\d{5}-\d{3}/

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.document)
      errors.document = 'Obrigatório'
    else {
      if (type === 'brlPeopleRegime' && !cpfRegex.test(values.document))
        errors.document = 'CPF inválido'
      else if (type === 'brlCompanyRegime' && !cnpjRegex.test(values.document))
        errors.document = 'CNPJ inválido'
    }

    if (type === 'brlCompanyRegime') {
      if (!values.regime?.razao_social)
        errors.regime.razao_social = 'Obrigatório'
    }

    if (!values.emails || values.emails.length === 0)
      errors.emails = 'Obrigatório'
    else {
      values.emails.forEach((email: string) => {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
          errors.emails = 'Existem emails inválidos'
      })
    }

    if (type !== 'brlInternationalCustomerRegime' && values.phones) {
      values.phones.forEach((phone: string) => {
        const onlyNumber = phone.replace(/\D/g, '');
        if (!/^(\d{2}|\(\d{2}\)) ?[0-9]{4,5}(-| )?[0-9]{4}$/.test(onlyNumber))
          errors.phones = 'Existem telefones inválidos. O número deve ser (xx) 9xxxx-xxxx ou (xx) xxxx-xxxx'
      })
    }

    if (type !== 'brlInternationalCustomerRegime')
      if (!values.address?.postal_code)
        errors.address.postal_code = 'Obrigatório'
      else if (!cepRegex.test(values.address?.postal_code))
        errors.address.postal_code = 'CEP inválido'

    if (type === 'brlInternationalCustomerRegime')
      if (!values.address?.country)
        errors.address.country = 'Obrigatório'

    if (!values.address?.street)
      errors.address.street = 'Obrigatório'

    if (!values.address?.number)
      errors.address.number = 'Obrigatório'

    if (!values.address?.neighbourhood)
      errors.address.neighbourhood = 'Obrigatório'

    if (type === 'brlInternationalCustomerRegime')
      if (!values.address?.state_province)
        errors.address.state_province = 'Obrigatório'

    if (type !== 'brlInternationalCustomerRegime')
      if (!values.address?.state_abbreviation)
        errors.address.state_abbreviation = 'Obrigatório'

    if (!values.address?.city)
      errors.address.city = 'Obrigatório'

    return errors;
  }

  return {
    citiesError,
    tagsError,
    tagsLoading,
    cityOptions,
    createLoading,
    createError,
    formError,
    openSnackbar,
    searchCityValue,
    searchLoading,
    selectedState,
    states,
    formattedTags,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    onSearchCityChange,
    onStateChange
  }
}