import { Button, Typography } from '@mui/material';
import { Form } from 'react-final-form';

import { Flexbox } from 'components/Flexbox';
import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';
import { DatePickerFieldAdapter } from 'components/FormComponents/Fields/DatePickerFieldAdapter';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';
import { CheckboxFieldAdapter } from 'components/FormComponents/Fields/CheckboxFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';
import { PercentFieldAdapter } from 'components/FormComponents/Fields/PercentFieldAdapter';
import { RadioFieldAdapter } from 'components/FormComponents/Fields/RadioFieldAdapter';
import { DisplayInfoTextMultLine } from 'components/Info/DisplayInfoTextMultLine';

import useViewModel from './viewModel'

import './styles.scss'

type ConfigStepPropTypes = {
  loading: boolean,
  type: string,
  onBack: () => void
  onNext: () => void,
}

function ConfigStep({
  loading = false,
  type,
  onBack,
  onNext
}: ConfigStepPropTypes) {
  const {
    autoReceiptRunOptions,
    freqOptions,
    hasInstallmentsOptions,
    initialTermOptions,
    paymentTermTypeOptions,
    paymentTermValueOptions,
    formattedSalePayload,
    formValidate,
    onCreateReceiptChange,
    onSubmit,
    onWithHoldInReceiptChange,
  } = useViewModel({ type, onNext })

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formattedSalePayload}
      validate={formValidate}
      render={({ form, values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>

          <div className='modal-body config-step'>

            <Typography variant='h4' fontWeight={700} className='config-subtitle-first' color='primary'>Informações sobre valores</Typography>

            <div className='radio-input-container'>
              <RadioFieldAdapter
                label=''
                disabled={true}
                name='has_installments'
                options={hasInstallmentsOptions}
                row={true}
              />
            </div>

            {values.has_installments === '0' &&
              <>
                <Flexbox size={6}>
                  <CurrencyFieldAdapter
                    name={type === 'RECURRING' ? 'plan_value' : 'product_value'}
                    label='Valor bruto'
                    loading={loading}
                    disabled
                  />
                </Flexbox>
              </>
            }

            {values.has_installments === '1' &&
              <div className='config-installments-container'>
                <DisplayInfoTextMultLine
                  title='Parcelas '
                  text={formattedSalePayload.formattedInstallments}
                />
              </div>
            }

            {type === 'RECURRING' && (
              <>
                <Typography variant='h4' fontWeight={700} className='config-subtitle' color='primary'>Informações sobre período do contrato e frequência de cobrança</Typography>

                <Flexbox size={12}>
                  <Flexbox size={6}>
                    <DatePickerFieldAdapter
                      label='Data de início do contrato'
                      loading={loading}
                      name='start_date'
                      disabled
                    />
                  </Flexbox>

                  <Flexbox size={6}>
                    <SelectFieldAdapter
                      label={'Duração do contrato'}
                      loading={loading}
                      name='initial_term'
                      options={initialTermOptions}
                    />
                  </Flexbox>
                </Flexbox>

                {values.has_installments === '0' &&
                  <>
                    <Flexbox size={6}>
                      <SelectFieldAdapter
                        label={'Frequência da cobrança'}
                        loading={loading}
                        name='freq'
                        options={freqOptions}
                        disabled
                      />
                    </Flexbox>

                    {values.initial_term !== '0' &&
                      <div className='checkbox-container'>
                        <CheckboxFieldAdapter
                          disabled={loading}
                          name='auto_renewal'
                          options={[
                            { label: 'Renovar automaticamente', value: '1' },
                          ]}
                        />
                      </div>
                    }

                    <Typography variant='h4' fontWeight={700} className='config-subtitle' color='primary'>Informações sobre prazos de pagamento</Typography>

                    <Flexbox size={12}>
                      <Flexbox size={6}>
                        <SelectFieldAdapter
                          label={'Prazo do primeiro pagamento'}
                          loading={loading}
                          name='payment_terms.first_payment_term_type'
                          options={paymentTermTypeOptions}
                        />
                      </Flexbox>

                      <Flexbox size={6}>
                        {values.payment_terms.first_payment_term_type === 'NET' ? (
                          <TextFieldAdapter
                            name='payment_terms.first_payment_term_value'
                            label='Dias após a cobrança'
                            loading={loading}
                            type='number'
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        ) : (
                          <SelectFieldAdapter
                            name='payment_terms.first_payment_term_value'
                            label='Dia do mês'
                            loading={loading}
                            options={paymentTermValueOptions}
                          />
                        )}
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={12}>
                      <Flexbox size={6}>
                        <SelectFieldAdapter
                          label={'Prazo dos demais pagamentos'}
                          loading={loading}
                          name='payment_terms.payment_term_type'
                          options={paymentTermTypeOptions}
                        />
                      </Flexbox>

                      <Flexbox size={6}>
                        {values.payment_terms.payment_term_type === 'NET' ? (
                          <TextFieldAdapter
                            name='payment_terms.payment_term_value'
                            label='Dias após a cobrança'
                            loading={loading}
                            type='number'
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        ) : (
                          <SelectFieldAdapter
                            name='payment_terms.payment_term_value'
                            label='Dia do mês'
                            loading={loading}
                            options={paymentTermValueOptions}
                          />
                        )}
                      </Flexbox>
                    </Flexbox>
                  </>
                }
              </>
            )}

            {type === 'SPOT' && values.has_installments === '0' &&
              <>
                <Typography variant='h4' fontWeight={700} className='config-subtitle' color='primary'>Informações sobre prazos de pagamento</Typography>

                <Flexbox size={12}>
                  <Flexbox size={6}>
                    <SelectFieldAdapter
                      label={'Prazo do pagamento'}
                      loading={loading}
                      name='payment_terms.payment_term_type'
                      options={paymentTermTypeOptions}
                    />
                  </Flexbox>

                  <Flexbox size={6}>
                    {values.payment_terms.payment_term_type === 'NET' ? (
                      <TextFieldAdapter
                        name='payment_terms.payment_term_value'
                        label='Dias após a cobrança'
                        loading={loading}
                        type='number'
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    ) : (
                      <SelectFieldAdapter
                        name='payment_terms.payment_term_value'
                        label='Dia do mês'
                        loading={loading}
                        options={paymentTermValueOptions}
                      />
                    )}
                  </Flexbox>
                </Flexbox>
              </>
            }

            <Typography variant='h4' fontWeight={700} className='config-subtitle' color='primary'>Informações fiscais</Typography>

            <div className='checkbox-container'>
              <CheckboxFieldAdapter
                disabled={loading}
                name='create_receipt'
                onChange={(e) => {
                  onCreateReceiptChange(e, form)
                }}
                options={[
                  { label: 'Gerar notas fiscais para essa venda', value: '1' },
                ]}
              />

              <Flexbox size={6}>
                <SelectFieldAdapter
                  name='auto_receipt_run'
                  label=''
                  loading={loading}
                  disabled={!values.create_receipt?.length}
                  options={autoReceiptRunOptions}
                />
              </Flexbox>
            </div>

            <div className='checkbox-container'>
              <CheckboxFieldAdapter
                disabled={loading}
                name='taxes.withhold'
                options={[
                  { label: 'Reter ISS na cobrança', value: '1' },
                ]}
              />

              <Flexbox size={3}>
                <PercentFieldAdapter
                  name='taxes.rate'
                  label=''
                  loading={loading}
                  disabled={!values.taxes?.withhold?.length}
                />
              </Flexbox>
            </div>

            <div className='checkbox-container'>
              <CheckboxFieldAdapter
                disabled={loading}
                name='taxes.withhold_in_receipt'
                onChange={(e) => {
                  onWithHoldInReceiptChange(e, form)
                }}
                options={[
                  { label: 'Reter ISS na nota fiscal', value: '1' },
                ]}
              />

              <Flexbox size={3}>
                <PercentFieldAdapter
                  name='taxes.rate_in_receipt'
                  label=''
                  loading={loading}
                  disabled={!values.taxes?.withhold_in_receipt?.length}
                />
              </Flexbox>
            </div>

            <Flexbox size={12}>
              <TextFieldAdapter
                label='Descrição para a fatura e nota fiscal'
                loading={loading}
                name='description'
                multiline
                rows={3}
              />
            </Flexbox>
          </div>

          <div className='modal-footer'>
            <Button variant='outlined' onClick={onBack}>Cancelar</Button>
            <Button variant='contained' type='submit' disabled={loading}>Próximo</Button>
          </div>
        </form>
      )}
    />
  )
}

export { ConfigStep }