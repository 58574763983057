import { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { FormApi, ValidationErrors } from 'final-form'

import { ApiError } from 'utils/CustomError'
import { formatForInput } from 'utils/formatConstantForInput'
import { notAuthenticatedRedirect } from 'utils/auth'
import { STATES } from 'utils/constants/states'

import { getClient, ClientType, getTags, formatClientToDisplay, formatTagsToDisplay, ClientTypeToDisplay, getCities, patchClient } from './services'

type ViewModelPropTypes = {
  clientId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EditClientViewModel({ clientId, onClose, onSubmit }: ViewModelPropTypes) {
  const [client, setClient] = useState<ClientType>()
  const [formattedClient, setFormattedClient] = useState<ClientTypeToDisplay>()

  const [initialDetails, setInitialDetails] = useState<string[]>([])

  const [clientLoading, setClientLoading] = useState(true)
  const [clientLoadingError, setClientLoadingError] = useState(false)

  const [formattedTags, setFormattedTags] = useState<string[]>([])
  const [tagsLoading, setTagsLoading] = useState(false)
  const [tagsError, setTagsError] = useState(false)

  const states = formatForInput(STATES)
  const [selectedState, setSelectedState] = useState('')

  const [cityOptions, setCityOptions] = useState([])
  const [searchCityValue, setSearchCityValue] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState(true)
  const [citiesError, setCitiesError] = useState(false)

  const [formError, setFormError] = useState(false)
  const [editError, setEditError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onGetCitiesHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCityOptions([])
      setCitiesError(true)
      setOpenSnackbar(true)
    } else {
      setCityOptions([])
      setCitiesError(true)
      setOpenSnackbar(true)
    }
  }

  const onGetClientHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setClientLoadingError(true)

      setClient(undefined);
      setFormattedClient(undefined);
    } else {
      setClientLoadingError(true)

      setClient(undefined);
      setFormattedClient(undefined);
    }
  }

  const onGetTagsHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setTagsError(true)
    } else {
      setTagsError(true)
    }

    return [];
  }

  const onPatchClientHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setClientLoading(true);

      if (clientId) {
        const response = await getClient(clientId, abortController.signal);

        if (response.errors) {
          onGetClientHandleError(response.errors)
        } else {
          setClient(response)
          setFormattedClient(formatClientToDisplay(response))
          setInitialDetails(response.details)
          if (response.regime_type !== 'brlInternationalCustomerRegime') {
            setSelectedState(response.address.state_abbreviation)
            setSearchCityValue(response.address.city)
          }
        }
      }

      setClientLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort()
    }
  }, [clientId])

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setTagsLoading(true);
      setTagsError(false)

      const response = await getTags(abortController.signal);

      if (response.errors) {
        onGetTagsHandleError(response.errors)
      } else {
        setTagsError(false)
        setFormattedTags(formatTagsToDisplay(response))
      }

      setTagsLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const debounce = setTimeout(() => {
      if (selectedState) {
        (async () => {
          setSearchLoading(true)
          const response = await getCities(searchCityValue, selectedState, abortController.signal);

          if (response.errors) {
            onGetCitiesHandleError(response.errors)
          }
          else {
            setCityOptions(response)
          }

          setSearchLoading(false || abortController.signal.aborted);
        })()
      }
    }, 500)

    return () => {
      abortController.abort()
      clearTimeout(debounce)
    }
  }, [searchCityValue, selectedState])


  const handleSubmit = async (values: Record<string, any>) => {
    setClientLoading(true);
    const response = await patchClient(clientId, values as ClientTypeToDisplay)

    if (response.errors)
      onPatchClientHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setClientLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const onSearchCityChange = (value: string) => {
    setSearchCityValue(value)
  }

  const onStateChange = (e: SelectChangeEvent, form: FormApi) => {
    setSearchCityValue('')
    const fieldState = form.getFieldState('address.city')
    fieldState?.change(null)
    setSelectedState(e.target.value)
  }


  const formValidate = (values: any) => {
    const errors: ValidationErrors = {
      address: {},
      regime: {}
    };

    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
    const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/
    const cepRegex = /\d{5}-\d{3}/

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.document)
      errors.document = 'Obrigatório'
    else {
      if (client?.regime_type === 'brlPeopleRegime' && !cpfRegex.test(values.document))
        errors.document = 'CPF inválido'
      else if (client?.regime_type === 'brlCompanyRegime' && !cnpjRegex.test(values.document))
        errors.document = 'CNPJ inválido'
    }

    if (client?.regime_type === 'brlCompanyRegime') {
      if (!values.regime?.razao_social)
        errors.regime.razao_social = 'Obrigatório'
    }

    if (!values.emails || values.emails.length === 0)
      errors.emails = 'Obrigatório'
    else {
      values.emails.forEach((email: string) => {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
          errors.emails = 'Existem emails inválidos'
      })
    }

    if (client?.regime_type !== 'brlInternationalCustomerRegime' && values.phones) {
      values.phones.forEach((phone: string) => {
        const onlyNumber = phone.replace(/\D/g, '');
        if (!/^(\d{2}|\(\d{2}\)) ?[0-9]{4,5}(-| )?[0-9]{4}$/.test(onlyNumber))
          errors.phones = 'Existem telefones inválidos. O número deve ser (xx) 9xxxx-xxxx ou (xx) xxxx-xxxx'
      })
    }

    if (client?.regime_type !== 'brlInternationalCustomerRegime')
      if (!values.address?.postal_code)
        errors.address.postal_code = 'Obrigatório'
      else if (!cepRegex.test(values.address?.postal_code))
        errors.address.postal_code = 'CEP inválido'

    if (client?.regime_type === 'brlInternationalCustomerRegime')
      if (!values.address?.country)
        errors.address.country = 'Obrigatório'

    if (!values.address?.street)
      errors.address.street = 'Obrigatório'

    if (!values.address?.number)
      errors.address.number = 'Obrigatório'

    if (!values.address?.neighbourhood)
      errors.address.neighbourhood = 'Obrigatório'

    if (client?.regime_type === 'brlInternationalCustomerRegime')
      if (!values.address?.state_province)
        errors.address.state_province = 'Obrigatório'

    if (client?.regime_type !== 'brlInternationalCustomerRegime')
      if (!values.address?.state_abbreviation)
        errors.address.state_abbreviation = 'Obrigatório'

    if (!values.address?.city)
      errors.address.city = 'Obrigatório'

    return errors;
  }


  return {
    citiesError,
    cityOptions,
    client,
    clientLoading,
    clientLoadingError,
    editError,
    initialDetails,
    formattedClient,
    formError,
    formattedTags,
    openSnackbar,
    selectedState,
    searchCityValue,
    searchLoading,
    states,
    tagsError,
    tagsLoading,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    onSearchCityChange,
    onStateChange,
  }
}