import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form';

import { CheckboxFieldAdapter } from 'components/FormComponents/Fields/CheckboxFieldAdapter';

import useViewModel from './viewModel';

import './styles.scss'

type TermsOfUsePropTypes = {
  termUrl: string,
  open: boolean,
  onClose: () => void,
  onSubmit: (accept_term_of_use: boolean) => void
}

function TermsOfUseModal({ termUrl, open, onClose, onSubmit }: TermsOfUsePropTypes) {
  const {
    handleSubmit
  } = useViewModel({ onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='terms-of-use custom-modal'>
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div className='modal-header'>
                  <Typography variant='h2'>
                    Termos de uso do sistema
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Typography className='read-terms'>Para prosseguir, leia e aceite os termos de uso.</Typography>

                  <iframe
                    title='terms-of-use'
                    src={termUrl}
                  />

                  <CheckboxFieldAdapter
                    name='accept_term_of_use'
                    options={[{ label: 'Li e aceito os termos de uso', value: '1' }]}
                  />
                </div>

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={!values?.accept_term_of_use?.length}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>
    </>
  )
}

export { TermsOfUseModal }