import { useState } from 'react'

import { UserPayload, patchUser } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  userId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EditUserModalViewModel({ userId, onClose, onSubmit }: ViewModelPropTypes) {

  const [editLoading, setEditLoading] = useState(false)
  const [editError, setEditError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);


  const onPatchUserHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setEditLoading(true)
    const response = await patchUser(userId, values as UserPayload)

    if (response.errors)
      onPatchUserHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setEditLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).+/

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.email)
      errors.email = 'Obrigatório'
    else {
      if (!emailRegex.test(values.email))
        errors.email = 'Email inválido'
    }

    if (values.password)
      if (!passwordRegex.test(values.password) || values.password.length < 12)
        errors.password = 'Senha inválida'

    return errors
  }

  return {
    editLoading,
    editError,
    formError,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}