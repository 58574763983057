import { FormApi, ValidationErrors } from 'final-form';
import { ChangeEvent, useEffect, useState } from 'react';

import { useCreateSaleActions } from 'recoil/createSale/actions';

import { bundlePayloadToRecoil, formatSalePayloadToDisplay } from './services';

type viewModelPropTypes = {
  type: string,
  onNext: () => void
}

export default function ConfigStepViewModel({ type, onNext }: viewModelPropTypes) {
  const { salePayload, setSalePayload } = useCreateSaleActions()
  const [formattedSalePayload, setFormattedSalePayload] = useState<any>()

  const autoReceiptRunOptions = [
    { label: 'Automaticamente junto a cobrança', value: '1' },
    { label: 'Manualmente', value: '0' },
  ]

  const freqOptions = [
    { label: 'Por mês', value: 'MONTHLY' },
    { label: 'Trimestral', value: 'QUARTERLY' },
    { label: 'Semestral', value: 'SEMIANNUAL' },
    { label: 'Anual', value: 'YEARLY' },
  ]

  const initialTermOptions = [...Array(120)].map((value, index) => {
    const label = index === 0 ? 'mês' : 'meses'
    return { label: `${index + 1} ${label}`, value: `${index + 1}` }
  })
  initialTermOptions.unshift({ label: 'Indeterminado', value: '0' })

  const paymentTermTypeOptions = [
    { label: 'Dia fixo do mês', value: 'FIXED' },
    { label: 'Dias após a cobrança', value: 'NET' },
  ]

  const paymentTermValueOptions = [...Array(28)].map((value, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
  paymentTermValueOptions.unshift({ label: 'Último dia do mês', value: '0' })

  const hasInstallmentsOptions = [
    { label: 'Valor bruto', value: '0' },
    { label: 'Valor parcelado', value: '1' },
  ]

  useEffect(() => {
    const formatted = formatSalePayloadToDisplay(salePayload)
    setFormattedSalePayload(formatted)
  }, [salePayload])


  const onSubmit = (values: Record<string, any>) => {
    setSalePayload({ ...salePayload, ...bundlePayloadToRecoil(values) })
    onNext()
  }

  const onCreateReceiptChange = (e: ChangeEvent<HTMLInputElement>, form: FormApi) => {
    if (!e.target.checked) {
      const fieldState = form.getFieldState('auto_receipt_run')
      fieldState?.change(undefined)
    }
  }

  const onWithHoldInReceiptChange = (e: ChangeEvent<HTMLInputElement>, form: FormApi) => {
    if (!e.target.checked) {
      const fieldState = form.getFieldState('rate_in_receipt')
      fieldState?.change(undefined)
    }
  }

  const formValidate = (values: Record<string, any>): ValidationErrors => {
    const errors: ValidationErrors = {
      payment_terms: {},
      taxes: {},
    }

    if (type === 'RECURRING') {
      if (!values.plan_value)
        errors.plan_value = 'Obrigatório'

      if (!values.start_date)
        errors.start_date = 'Obrigatório'

      if (!values.initial_term)
        errors.initial_term = 'Obrigatório'

      if (values.has_installments === '0') {
        if (!values.freq)
          errors.freq = 'Obrigatório'

        if (!values.payment_terms.first_payment_term_type)
          errors.payment_terms.first_payment_term_type = 'Obrigatório'

        if (!values.payment_terms.first_payment_term_value)
          errors.payment_terms.first_payment_term_value = 'Obrigatório'
      }
    }

    if (type === 'SPOT')
      if (!values.product_value)
        errors.product_value = 'Obrigatório'

    if (values.has_installments === '0') {
      if (!values.payment_terms.payment_term_type)
        errors.payment_terms.payment_term_type = 'Obrigatório'

      if (!values.payment_terms.payment_term_value)
        errors.payment_terms.payment_term_value = 'Obrigatório'
    }

    if (values.create_receipt?.length) {
      if (!values.auto_receipt_run)
        errors.auto_receipt_run = 'Obrigatório'
    }

    if (values.taxes?.withhold_in_receipt?.length) {
      if (!values.taxes.rate_in_receipt)
        errors.taxes.rate_in_receipt = 'Obrigatório'
    }

    if (values.taxes?.withhold?.length) {
      if (!values.taxes.rate)
        errors.taxes.rate = 'Obrigatório'
    }

    return errors
  }

  return {
    autoReceiptRunOptions,
    freqOptions,
    hasInstallmentsOptions,
    initialTermOptions,
    paymentTermTypeOptions,
    paymentTermValueOptions,
    salePayload,
    formattedSalePayload,
    formValidate,
    onCreateReceiptChange,
    onSubmit,
    onWithHoldInReceiptChange,
  }
}