import {CheckoutOrderPayloadType} from 'recoil/checkout/states';

type PaymentPayload = {
    payment_method: string,
    holder_name?: string,
    digits?: string,
    expiration_month?: string,
    expiration_year?: string,
    security_code?: string
}

const bundlePayloadToRecoil = (payload: PaymentPayload): CheckoutOrderPayloadType => {

    return {
        payment: {
            payment_method: payload.payment_method,
            holder_name: payload.holder_name,
            digits: payload?.digits?.replaceAll(' ', ''),
            expiration_month: payload.expiration_month,
            expiration_year: payload.expiration_year,
            security_code: payload.security_code
        }
    }
}

const formatCheckoutPaymentPayloadToDisplay = (values: CheckoutOrderPayloadType) => {
    return {
        ...values.payment
    }
}

export {
    bundlePayloadToRecoil,
    formatCheckoutPaymentPayloadToDisplay
}

export type {
    PaymentPayload
}