import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton } from '@mui/material';

import { ActionButtonTable } from '../Table/ActionButtonTable';
import { TableCell } from '../Table/TableCell';
import { TableHead } from '../Table/TableHead';

import '../styles.scss'

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rows: Array<any>,
  rowLink: string,
  rowsPerPage: number,
  total: number,
  isLoading: boolean,
  onClickOption?: (index: number, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function AccountReceivableListTable({
  hasActions = false,
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rows,
  rowLink,
  rowsPerPage,
  total,
  isLoading,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {

  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAnySelected={false}
          isAsc={isAsc}
          optionMenu={hasActions}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>
          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>
              <TableCell><Skeleton /></TableCell>

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => {
            let statusClass = 'label-status-blue'

            switch (row.status) {
              case 'PARCIALMENTE PAGO':
              case 'RENEGOCIADO':
              case 'EXPIRADO':
                statusClass = 'label-status-yellow'
                break;
              case 'CANCELADO':
              case 'ERRO':
                statusClass = 'label-status-red'
                break;
              case 'PAGO':
              case 'TRANSFERIDO':
                statusClass = 'label-status-green'
                break;
            }

            return <TableRow
              className='table-row linked-table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              <TableCell to={`${rowLink}/${row.id}`} className='table-cell big' align='left'><div>{row.customer_name}</div><div>{row.key}</div></TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell medium' align='left'><div>{row.total}</div><div>{row.payment_method}</div></TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.due_date}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.net_value}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell small' align='left'>{row.transfer_date}</TableCell>
              <TableCell to={`${rowLink}/${row.id}`} className='table-cell medium' align='left'>
                <span className={`label-status ${statusClass}`}>{row.status}</span>
              </TableCell>

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left'>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(index, action)
                    }}
                  />
                </TableCell>
              )}

            </TableRow>
          })}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { AccountReceivableListTable };