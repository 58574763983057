import { bundleTermsOfUsePayload } from "./services";

type ViewModelPropTypes = {
  onSubmit: (accept_term_of_use: boolean) => void
}

export default function TermsOfUseViewModel({ onSubmit }: ViewModelPropTypes) {


  const handleSubmit = (values: Record<string, any>) => {
    onSubmit(bundleTermsOfUsePayload(values).accept_term_of_use)
  }

  return {
    handleSubmit,
  };
}
