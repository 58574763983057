import useViewModel from './viewModel';

import { InvoiceGroupListTable } from 'components/Tables/InvoiceGroupListTable';
import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';

type InvoiceGroupsViewPropTypes = {
  clientId: string
}

function InvoiceGroupsView({
  clientId,
}: InvoiceGroupsViewPropTypes) {
  const {
    formattedInvoices,
    invoicesLoading,
    invoicesLoadingError,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalInvoices,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ clientId });

  return (
    <div className='listing-tab-view'>

      {!invoicesLoading &&
        !invoicesLoadingError &&
        formattedInvoices?.length === 0 &&
        <EmptyView title={'Nenhuma fatura encontrada para mostrar aqui'} />
      }

      {invoicesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as faturas. Tente recarregar a página.' />
      }

      {(invoicesLoading || (formattedInvoices !== undefined && formattedInvoices.length > 0)) &&
        <InvoiceGroupListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedInvoices ? formattedInvoices : []}
          rowLink='/invoices-groups'
          rowsPerPage={rowsPerPage}
          total={totalInvoices}
          isLoading={invoicesLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      }

    </div>
  )
}

export { InvoiceGroupsView };