import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form';

import { ApiErrorView } from 'components/ApiErrorView';
import { Flexbox } from 'components/Flexbox';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import useViewModel, { InvoicesReceiptsType } from './viewModel'

import '../../styles.scss'
import './styles.scss'

type SendInvoicesReceiptsModalPropTypes = {
  invoicesReceiptsData: InvoicesReceiptsType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

function SendInvoicesReceiptsModal({ invoicesReceiptsData, open, onClose, onSubmit }: SendInvoicesReceiptsModalPropTypes) {
  const {
    getError,
    initialDescription,
    invoiceError,
    invoiceLoading,
    openSnackbar,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ invoicesReceiptsData, open, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='send-invoices-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              description: initialDescription
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className='modal-header'>
                  <Typography variant='h2'>
                    Emitir nota fiscal
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                {!getError &&
                  <div className='modal-body'>
                    <Flexbox size={12}>
                      <TextFieldAdapter
                        label={'Descrição'}
                        loading={invoiceLoading}
                        name='description'
                      />
                    </Flexbox>
                  </div>
                }

                {getError &&
                  <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar a nota fiscal.' />
                }

                <div className='modal-footer'>
                  {invoiceLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' color='error' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={invoiceLoading || getError}>Confirmar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {invoiceError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {!invoiceError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Pedido de emissão enviado!
          </Alert>
        </Snackbar>
      }

    </>
  )
}

export { SendInvoicesReceiptsModal }