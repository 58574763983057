import { CustomError, api } from 'utils/axios'

import { parseToFloat } from 'utils/moneyFormatt'

type ProductPayload = {
  name: string,
  description?: string,
  value?: string,
  internal_code?: string,
  cnae_code: {
    label: string,
    value: string,
  },
  service_code: {
    label: string,
    value: string,
  },
  custom_service_code?: string,
  iss?: string,
  irrf?: string,
  pis?: string,
  cofins?: string,
  csll?: string
}

type ParsedProductPayload = {
  name: string,
  description: string,
  value?: number,
  internal_code: string,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: number,
  irrf: number,
  pis: number,
  cofins: number,
  csll: number
}

const bundlePayload = (payload: ProductPayload): ParsedProductPayload => (
  {
    name: payload.name,
    description: payload.description ? payload.description : '',
    value: payload.value ? parseToFloat(payload.value) : 0,
    internal_code: payload.internal_code ? payload.internal_code : '',
    cnae_code: payload.cnae_code.value,
    service_code: payload.service_code.value,
    custom_service_code: payload.custom_service_code ? payload.custom_service_code : '',
    iss: payload.iss ? parseToFloat(payload.iss) / 100 : 0,
    irrf: payload.irrf ? parseToFloat(payload.irrf) / 100 : 0,
    pis: payload.pis ? parseToFloat(payload.pis) / 100 : 0,
    cofins: payload.cofins ? parseToFloat(payload.cofins) / 100 : 0,
    csll: payload.csll ? parseToFloat(payload.csll) / 100 : 0
  }
)

const postProduct = async (type: 'recurring' | 'spot', payload: ProductPayload): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/products-${type}`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postProduct }

export type { ParsedProductPayload, ProductPayload }