import { Typography } from '@mui/material'

import { DisplayInfoText } from 'components/Info/DisplayInfoText'
import { Flexbox } from 'components/Flexbox'

import useViewModel from './viewModel'

type ProductRecurringGeneralViewPropTypes = {
  productId: string
}

function ProductRecurringGeneralView({ productId }: ProductRecurringGeneralViewPropTypes) {
  const { formattedDetails, detailsLoading } = useViewModel({ productId })

  return (
    <div className='details'>

      <div className='basic-info'>
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Código interno'
              text={formattedDetails?.internal_code ? formattedDetails.internal_code : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Status'
              text={formattedDetails?.status ? formattedDetails.status : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Compras'
              text={formattedDetails?.sales ? `${formattedDetails.sales}` : ''}
            />
          </Flexbox>
        </div>

        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Criado em'
              text={formattedDetails?.created_at ? formattedDetails.created_at : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Última atualização'
              text={formattedDetails?.updated_at ? formattedDetails.updated_at : ''}
            />
          </Flexbox>
        </div>

        <div className='info-row'>
          <Flexbox size={4}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Observações para fatura e nota fiscal'
              text={formattedDetails?.description ? formattedDetails.description : ''}
            />
          </Flexbox>
        </div>

      </div>

      <div className='fiscal-info'>
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Características fiscais</Typography>

        <div className='info-row'>
          <DisplayInfoText
            isLoading={detailsLoading}
            title='CNAE'
            text={formattedDetails?.cnae_code ? formattedDetails.cnae_code : ''}
          />
        </div>

        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Código de serviço'
              text={formattedDetails?.service_code ? formattedDetails.service_code : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Código de serviço secundário'
              text={formattedDetails?.custom_service_code ? formattedDetails.custom_service_code : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Alíquota de ISS'
              text={formattedDetails?.iss ? formattedDetails.iss : ''}
            />
          </Flexbox>
        </div>

      </div>

      <div className='retention-info'>
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Configurações de retenção</Typography>

        <div className='info-row'>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='IRRF'
              text={formattedDetails?.irrf ? formattedDetails.irrf : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='PIS'
              text={formattedDetails?.pis ? formattedDetails.pis : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='COFINS'
              text={formattedDetails?.cofins ? formattedDetails.cofins : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='CSLL'
              text={formattedDetails?.csll ? formattedDetails.csll : ''}
            />
          </Flexbox>
        </div>
      </div>

    </div>
  )
}

export { ProductRecurringGeneralView }