import { CustomError, api } from 'utils/axios';

import { CNAE_LIST, SERVICE_CODE_LIST } from 'utils/constants/ProductRegistrationConstants';
import { parseToFloat } from 'utils/moneyFormatt';
import { formatPercent } from 'utils/percentFormat';

type ProductType = {
  name: string,
  internal_code: string,
  description: string,
  value?: number,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: number,
  irrf: number,
  pis: number,
  cofins: number,
  csll: number,
}

type ProductDisplayType = {
  name: string,
  description?: string,
  value?: string,
  internal_code?: string,
  cnae_code: {
    label: string,
    value: string,
  },
  service_code: {
    label: string,
    value: string,
  },
  custom_service_code?: string,
  iss?: string,
  irrf?: string,
  pis?: string,
  cofins?: string,
  csll?: string
}

type BundleProductPayload = {
  name: string,
  description: string,
  value?: number,
  internal_code: string,
  cnae_code: string,
  service_code: string,
  custom_service_code: string,
  iss: number,
  irrf: number,
  pis: number,
  cofins: number,
  csll: number
}

const parseProduct = (response: { data: any }): ProductType => {
  return {
    name: response.data.attributes.name,
    internal_code: response.data.attributes.internal_code,
    description: response.data.attributes.description,
    value: response.data.attributes.value?.value,
    cnae_code: response.data.attributes.cnae_code,
    service_code: response.data.attributes.service_code,
    custom_service_code: response.data.attributes.custom_service_code,
    iss: response.data.attributes.iss,
    irrf: response.data.attributes.irrf,
    pis: response.data.attributes.pis,
    cofins: response.data.attributes.cofins,
    csll: response.data.attributes.csll,
  }
}

const getProduct = async (id: string, type: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/products-${type}/${id}`, { signal })

    return parseProduct(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatProductToDisplay = (details: ProductType): ProductDisplayType => {
  return {
    name: details.name,
    internal_code: details.internal_code,
    description: details.description,
    value: details.value ? details.value?.toString().replace('.', ',') : '',
    cnae_code: {
      label: `${details.cnae_code} - ${CNAE_LIST[details.cnae_code]}`,
      value: details.cnae_code
    },
    service_code: {
      label: `${details.service_code} - ${SERVICE_CODE_LIST[details.service_code]}`,
      value: details.service_code
    },
    custom_service_code: details.custom_service_code,
    iss: formatPercent(details.iss),
    irrf: formatPercent(details.irrf),
    pis: formatPercent(details.pis),
    cofins: formatPercent(details.cofins),
    csll: formatPercent(details.csll),
  }
}

const bundlePayload = (payload: ProductDisplayType): BundleProductPayload => (
  {
    name: payload.name,
    description: payload.description ? payload.description : '',
    value: payload.value ? parseToFloat(payload.value) : 0,
    internal_code: payload.internal_code ? payload.internal_code : '',
    cnae_code: payload.cnae_code.value,
    service_code: payload.service_code.value,
    custom_service_code: payload.custom_service_code ? payload.custom_service_code : '',
    iss: payload.iss ? parseToFloat(payload.iss) / 100 : 0,
    irrf: payload.irrf ? parseToFloat(payload.irrf) / 100 : 0,
    pis: payload.pis ? parseToFloat(payload.pis) / 100 : 0,
    cofins: payload.cofins ? parseToFloat(payload.cofins) / 100 : 0,
    csll: payload.csll ? parseToFloat(payload.csll) / 100 : 0
  }
)

const patchProduct = async (id: string, type: string, payload: ProductDisplayType): Promise<any> => {
  try {
    const response = await api.patch(`/api-cashone/products-${type}/${id}`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { formatProductToDisplay, getProduct, patchProduct }

export type { ProductType, ProductDisplayType }