import { Button, Slider, TextField, Popover, Typography } from '@mui/material';
import React from 'react';

import useViewModel from './viewModel';
import './styles.scss'

type RangeFilterPropTypes = {
  buttonLabel: string,
  id: string,
  label: string,
  max?: number,
  min?: number,
  onApplyFilter: (lower: number, upper: number) => void
}

function RangeFilter({
  buttonLabel,
  id,
  label,
  max,
  min,
  onApplyFilter,
}: RangeFilterPropTypes) {

  const {
    lowerLimit,
    isOpen,
    upperLimit,
    onClick,
    onIntervalChange,
    onLowerLimitChange,
    onUpperLimitChange,
  } = useViewModel({ id, max, min });
  const buttonRef = React.useRef(null);

  return (
    <div className='filter'>
      <Button variant='outlined' color='secondary' onClick={onClick} size='small' ref={buttonRef}>
        <Typography variant='caption' fontWeight={700}>
          {buttonLabel}
        </Typography>
      </Button>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div
          className='range-filter-modal'
        >
          <div>
            <Typography variant='body1' fontWeight={700}>
              {label}
            </Typography>
          </div>

          <div className='slider'>
            <Slider
              value={[
                lowerLimit ? lowerLimit : 0,
                upperLimit ? upperLimit : 0,
              ]}
              min={min}
              max={max}
              onChange={(e, value) => {
                onIntervalChange(value as number[]);
              }}
            />
          </div>

          <div className='range-container'>
            <TextField
              className='range-text'
              type='number'
              value={lowerLimit ? lowerLimit : 0}
              size='small'
              onChange={(e) => {
                onLowerLimitChange(e.target.value);
              }}
            />

            <span className='range-divisor'><hr /></span>

            <TextField
              className='range-text'
              type='number'
              value={upperLimit ? upperLimit : 0}
              size='small'
              onChange={(e) => {
                onUpperLimitChange(e.target.value);
              }}
            />
          </div>

          <hr />

          <div className='buttons-container'>
            <Button
              variant='contained'
              size='small'
              onClick={() => {
                if (lowerLimit !== undefined && upperLimit !== undefined)
                  onApplyFilter(lowerLimit, upperLimit)
              }}
            >
              <Typography variant='caption' fontWeight={700}>
                Aplicar filtro
              </Typography>
            </Button>
          </div>

        </div>
      </Popover>
    </div>
  );
}

export { RangeFilter };