import { Typography } from '@mui/material'

import { DisplayInfoTextOpenNewTab } from 'components/Info/DisplayInfoTextOpenNewTab'
import { ApiErrorView } from 'components/ApiErrorView';
import { DisplayInfoText } from 'components/Info/DisplayInfoText';
import { Flexbox } from 'components/Flexbox';
import { CashierStatementItemTable } from 'components/Tables/CashierStatementItemTable';

import useViewModel from './viewModel'

type CashierStatementGeneralViewPropTypes = {
  cashierStatementId: string
}

function CashierStatementGeneralView({ cashierStatementId }: CashierStatementGeneralViewPropTypes) {
  const {
    formattedDetails,
    detailsLoading,
    detailsLoadingError,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    tableHeadCells,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ cashierStatementId })

  return (
    <div className='details'>

      {detailsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os detalhes do lançamento de caixa. Tente recarregar a página.' />
      }

      {!detailsLoadingError && (detailsLoading || formattedDetails !== undefined) &&
        <>
          <div className='basic-info segment-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Valor'
                  text={formattedDetails?.value ? formattedDetails.value : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title={'Data'}
                  text={formattedDetails?.effective_date ? formattedDetails.effective_date : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title={'Conta'}
                  text={formattedDetails?.cashier.cashier_name ? formattedDetails.cashier.cashier_name : ''}
                />
              </Flexbox>
            </div>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Descrição'
                  text={formattedDetails?.description ? formattedDetails.description : ''}
                />
              </Flexbox>
            </div>
          </div>

          <div className='segment-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Detalhamento do valor</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Juros e multas'
                  text={formattedDetails?.interest_and_penalty_value ? formattedDetails.interest_and_penalty_value : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Taxas'
                  text={formattedDetails?.tax_value ? formattedDetails.tax_value : ''}
                />
              </Flexbox>
            </div>

          </div>
        
          <div className='segment-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Detalhamento da origem</Typography>

            {!formattedDetails?.cashier_statement_items && <div className='info-row'>
              {formattedDetails?.customer.id && <Flexbox size={4}>
                <DisplayInfoTextOpenNewTab
                  isLoading={detailsLoading}
                  title='Cliente'
                  texts={formattedDetails?.customer.name ? [formattedDetails.customer.name] : []}
                  links={[`/clients/${formattedDetails?.customer.id}`]}
                />
              </Flexbox>}
              <Flexbox size={4}>
                {formattedDetails?.source.id && <DisplayInfoTextOpenNewTab
                  isLoading={detailsLoading}
                  title='Origem'
                  texts={formattedDetails?.source.description ? [formattedDetails.source.description] : []}
                  links={[`/accounts-receivables/${formattedDetails.source.id}`]}
                />}
                {!formattedDetails?.source.id && <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Origem'
                  text={formattedDetails?.source.description ? formattedDetails.source.description : ''}
                />}
              </Flexbox>
            </div>}

            {formattedDetails?.cashier_statement_items && <div className='info-row'>
              <CashierStatementItemTable
                isAsc={true}
                headCells={tableHeadCells}
                page={page}
                optionsRowsPerPage={optionsRowsPerPage}
                orderBy={''}
                rows={formattedDetails?.cashier_statement_items}
                rowsPerPage={rowsPerPage}
                total={formattedDetails?.cashier_statement_items.length}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </div>}
          </div>
        </>
      }
    </div>
  )
}

export { CashierStatementGeneralView }