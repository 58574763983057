import React from 'react';
import { IMaskInput } from 'react-imask';

import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Skeleton } from '@mui/material';

type MultipleTelephoneFieldAdapterProps = {
  name: string,
  label: string,
  loading?: boolean,
  validate?: FieldValidator<any>
}

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={
          [{
            mask: '(00) 0000-0000'
          }, {
            mask: '(00) 00000-0000'
          }]
        }
        inputRef={ref as any}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } })
        }}
        overwrite
      />
    );
  },
);

const TelephoneMaskedInput = ({ input, label, meta, name }: any) => {

  return (
    <TextField
      {...input}
      meta={meta}
      input={input}
      label={label}
      placeholder='Pressione ENTER ao terminar de digitar o telefone'
      name={name}
      error={meta.touched && meta.error ? true : false}
      FormHelperTextProps={{ className: 'helper-text' }}
      helperText={meta.touched ? meta.error : ''}
      fullWidth
      InputProps={{
        ...input.InputProps,
        inputComponent: TextMaskCustom
      }}
    />)

};

function ComponentField({
  label,
  name,
  input,
  meta
}: FieldRenderProps<any>) {

  return (
    <Autocomplete
      multiple
      options={[]}
      freeSolo
      fullWidth
      value={input.value ? input.value : []}
      renderTags={(value: readonly string[], getTagProps) => {
        return value.map((option: string, index: number) => {
          let onlyNumber = option.replace(/\D/g, '');
          const color = onlyNumber.length === 11 || onlyNumber.length === 10 ? 'default' : 'error';
          if (onlyNumber.length === 11) {
            onlyNumber = onlyNumber.replace(/^(\d{2})(\d{5})(\d{4})$/g, '($1) $2-$3')
          } else if (onlyNumber.length === 10) {
            onlyNumber = onlyNumber.replace(/^(\d{2})(\d{4})(\d{4})$/g, '($1) $2-$3')
          }

            const tagOptions = getTagProps({ index })

          return <Chip color={color} label={onlyNumber} className={tagOptions.className} disabled={tagOptions.disabled} tabIndex={tagOptions.tabIndex} onDelete={tagOptions.onDelete} key={option + tagOptions.tabIndex} />
        })
      }
      }
      onChange={(event: any, newValue: string[]) => {
        input.onChange(newValue)
      }}
      renderInput={(params) => (
        <TelephoneMaskedInput
          {...params}
          meta={meta}
          name={name}
          input={params}
          label={label}
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
}

function MultipleTelephoneFieldAdapter({ name, label, loading, validate }: MultipleTelephoneFieldAdapterProps) {

  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={ComponentField}
          name={name}
          label={label}
          validate={validate}
          inputName={name}
        />
      )}
    </div>
  );
}

export { MultipleTelephoneFieldAdapter };