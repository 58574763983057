import { Button, Skeleton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

import useViewModel from './viewModel'

import PlaceholderPath from 'images/placeholder-image.png'

import './styles.scss'

type ImageFileInputPropTypes = {
  imageUrl?: string,
  loading?: boolean,
  onChange: (file: File) => void
}

function ImageFileInput({
  imageUrl,
  loading = false,
  onChange
}: ImageFileInputPropTypes) {
  const {
    newImage,
    updateImage
  } = useViewModel()

  return (
    <div className='image-container'>
      {loading ? (
        <Skeleton variant='rectangular' width={'150px'} height={'150px'} />
      ) : (
        <>
          {imageUrl || newImage ? (
            <div className='overflow-hidden'>
              <img src={newImage ? newImage : imageUrl} alt='Logo' className='image-file' />
            </div>
          ) : (
            <img src={PlaceholderPath} alt='Logo' className='image-file' />
          )}
          <Button className='edit-button' variant='contained' component='label'>
            <EditIcon />
            <input
              hidden
              type='file'
              accept='image/*'
              onChange={({ target }) => {
                if (target.files) {
                  const file = target.files[0];

                  if (file) {
                    updateImage(file)
                    onChange(file);
                  }
                }
              }}
            />
          </Button>
        </>
      )}
    </div>
  )
}

export { ImageFileInput }