import { Field, FieldRenderProps } from 'react-final-form';
import { FieldValidator } from 'final-form';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Skeleton } from '@mui/material';

type MultipleTextFieldAdapterProps = {
  name: string,
  label: string,
  loading?: boolean,
  validate?: FieldValidator<any>
}

function ComponentField({
  label,
  input,
  meta
}: FieldRenderProps<any>) {

  return (
    <Autocomplete
      multiple
      options={[]}
      freeSolo
      fullWidth
      value={input.value ? input.value : []}
      renderTags={(value: readonly string[], getTagProps) => (
        value.map((option: string, index: number) => {
          const tagOptions = getTagProps({ index })
          return <Chip label={option} className={tagOptions.className} disabled={tagOptions.disabled} tabIndex={tagOptions.tabIndex} onDelete={tagOptions.onDelete} key={option + tagOptions.tabIndex} />
        }))
      }
      onChange={(event: any, newValue: string[]) => {
        input.onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={meta.touched && meta.error ? true : false}
          helperText={meta.touched ? meta.error : ''}
          FormHelperTextProps={{ className: 'helper-text' }}
          placeholder='Pressione ENTER ao terminar de digitar'
        />
      )}
    />
  );
}

function MultipleTextFieldAdapter({ name, label, loading, validate }: MultipleTextFieldAdapterProps) {

  return (
    <div className='field'>
      {loading ? (
        <Skeleton width='100%' height='55px' />
      ) : (
        <Field
          component={ComponentField}
          name={name}
          label={label}
          validate={validate}
          inputName={name}
        />
      )}
    </div>
  );
}

export { MultipleTextFieldAdapter };