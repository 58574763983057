import { CustomError, api } from 'utils/axios';
import dayjs from 'dayjs';

import { formatMoney } from 'utils/moneyFormatt';
import { PAYMENT_METHODS } from 'utils/constants/paymentMethods';

type DetailsType = {
  id: string,
  key: string,
  status: string,
  customer: string,
  customer_id: number,
  total: number,
  tax_value: number,
  interest_and_penalty_value: number,
  net_value: number,
  due_date: string,
  payment_date: string,
  transfer_date: string,
  installment_index: number,
  installment_total: number,
  invoice_group_id: string,
  invoice_group_key: string,
  payment_method: string,
  boleto_barcode: string,
  boleto_url: string,
  credit_card_last_digits: string,
  credit_card_expiration_date: string,
  credit_card_brand: string,
  credit_card_name: string,
  pix_url: string,
  bank_deposit_bank_name: string,
  bank_deposit_account_number: string,
  bank_deposit_routing_number: string,
  created_at: string,
  updated_at: string,
}

type DetailsDisplayType = {
  id: string,
  key: string,
  status: string,
  customer: string,
  customer_id: string,
  total: string,
  tax_value: string,
  interest_and_penalty_value: string,
  net_value: string,
  due_date: string,
  payment_date: string,
  transfer_date: string,
  installments_total: string,
  installments: string,
  invoice_group_id: string,
  invoice_group_key: string,
  payment_method: string,
  payment_method_name: string,
  boleto_barcode: string,
  boleto_url: string,
  credit_card_last_digits: string,
  credit_card_expiration_date: string,
  credit_card_brand: string,
  credit_card_name: string,
  pix_url: string,
  bank_deposit_bank_name: string,
  bank_deposit_account_number: string,
  bank_deposit_routing_number: string,
  created_at: string,
  updated_at: string,
}

const parseDetails = (response: { data: any }): DetailsType => {
  return {
    id: response.data.id,
    key: response.data.attributes.key,
    status: response.data.attributes.status,
    customer: response.data.attributes.customer,
    customer_id: response.data.attributes.customer_id,
    total: response.data.attributes.total,
    tax_value: response.data.attributes.tax_value,
    interest_and_penalty_value: response.data.attributes.interest_and_penalty_value,
    net_value: response.data.attributes.net_value,
    due_date: response.data.attributes.due_date.date,
    payment_date: response.data.attributes.payment_date?.date,
    transfer_date: response.data.attributes.transfer_date?.date,
    installment_index: response.data.attributes.installment_index,
    installment_total: response.data.attributes.installment_total,
    invoice_group_id: response.data.attributes.invoice_group_id,
    invoice_group_key: response.data.attributes.invoice_group_key,
    payment_method: response.data.attributes.payment_method?.method,
    boleto_barcode: response.data.attributes.payment_method?.barcode,
    boleto_url: response.data.attributes.payment_method?.url,
    credit_card_last_digits: response.data.attributes.payment_method?.last_digits,
    credit_card_expiration_date: response.data.attributes.payment_method?.expiration_date,
    credit_card_brand: response.data.attributes.payment_method?.brand,
    credit_card_name: response.data.attributes.payment_method?.name,
    pix_url: response.data.attributes.payment_method?.url,
    bank_deposit_bank_name: response.data.attributes.payment_method?.bank_name,
    bank_deposit_account_number: response.data.attributes.payment_method?.account_number,
    bank_deposit_routing_number: response.data.attributes.payment_method?.routing_number,
    created_at: response.data.attributes.created_at.date,
    updated_at: response.data.attributes.updated_at.date,
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/accounts-receivables/${id}`, { signal });

    return parseDetails(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {

  const ACCOUNT_RECEIVABLE_STATUS:any = {
    'DRAFT': 'RASCUNHO',
    'PENDING': 'Pendente',
    'PAID': 'Pago',
    'TRANSFERRED': 'Transferido',
    'EXPIRED': 'Expirado',
    'ERROR': 'Erro',
    'CANCELLED': 'Cancelado',
    'PARTIALLY_PAID': 'Parcialmente pago'
  }

  return {
    id: details.id,
    key: details.key,
    status: ACCOUNT_RECEIVABLE_STATUS[details.status].toUpperCase(),
    customer: details.customer,
    customer_id: details.customer_id.toString(),
    total: formatMoney(details.total),
    tax_value: formatMoney(details.tax_value),
    interest_and_penalty_value: formatMoney(details.interest_and_penalty_value),
    net_value: formatMoney(details.net_value),
    due_date: dayjs(details.due_date).format('DD/MM/YYYY'),
    payment_date: details.payment_date ? dayjs(details.payment_date).format('DD/MM/YYYY') : 'N/A',
    transfer_date: details.transfer_date ? dayjs(details.transfer_date).format('DD/MM/YYYY') : 'N/A',
    installments_total: details.installment_total.toString(),
    installments: details.installment_index.toString() + ' / ' + details.installment_total.toString(),
    invoice_group_id: details.invoice_group_id,
    invoice_group_key: details.invoice_group_key,
    payment_method: details.payment_method ? details.payment_method : 'N/A',
    payment_method_name: details.payment_method ? PAYMENT_METHODS[details.payment_method] : 'N/A',
    boleto_barcode: details.boleto_barcode ? details.boleto_barcode : 'N/A',
    boleto_url: details.boleto_url ? details.boleto_url : 'N/A',
    credit_card_last_digits: details.credit_card_last_digits ? details.credit_card_last_digits : 'N/A',
    credit_card_expiration_date: details.credit_card_expiration_date ? details.credit_card_expiration_date : 'N/A',
    credit_card_brand: details.credit_card_brand ? details.credit_card_brand : 'N/A',
    credit_card_name: details.credit_card_name ? details.credit_card_name : 'N/A',
    pix_url: details.pix_url ? details.pix_url : 'N/A',
    bank_deposit_bank_name: details.bank_deposit_bank_name ? details.bank_deposit_bank_name : 'N/A',
    bank_deposit_account_number: details.bank_deposit_account_number ? details.bank_deposit_account_number : 'N/A',
    bank_deposit_routing_number: details.bank_deposit_routing_number ? details.bank_deposit_routing_number : 'N/A',
    created_at: dayjs(details.created_at).format('DD/MM/YYYY'),
    updated_at: dayjs(details.updated_at).format('DD/MM/YYYY'),
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }