import { CustomError, api } from 'utils/axios'

type UserPayload = {
  name: string,
  email: string,
  phone: string,
  password: string,
  roles: string[]
}

type ParsedUserPayload = {
  name: string,
  email: string,
  phone: string,
  password: string,
  roles: string[]
}

const bundlePayload = (payload: UserPayload): ParsedUserPayload => (
  {
    name: payload.name,
    email: payload.email,
    phone: payload.phone,
    password: payload.password,
    roles: ['ADMIN'],
  }
)

const postUser = async (payload: UserPayload): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/users`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postUser }

export type { ParsedUserPayload, UserPayload }