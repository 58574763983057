import { useState } from 'react'

import { CNAE_LIST, SERVICE_CODE_LIST } from 'utils/constants/ProductRegistrationConstants'
import { formatForInputConcatingKey } from 'utils/formatConstantForInput'

import { ProductPayload, postProduct } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  type: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CreateProductrModalViewModel({ type, onClose, onSubmit }: ViewModelPropTypes) {

  const [createLoading, setCreateLoading] = useState(false)
  const [createError, setCreateError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const cnaeList = formatForInputConcatingKey(CNAE_LIST)
  const [valueCnae, setValueCnae] = useState('')

  const serviceList = formatForInputConcatingKey(SERVICE_CODE_LIST)
  const [valueService, setValueService] = useState('')

  const searchCNAE = (search: string) => {
    setValueCnae(search)
  }

  const searchService = (search: string) => {
    setValueService(search)
  }

  const onPostProductHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setCreateError(true)
      setOpenSnackbar(true)
    } else {
      setCreateError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setCreateLoading(true)
    const response = await postProduct(type as any, values as ProductPayload)

    if (response.errors)
      onPostProductHandleError(response.errors)

    else {
      setCreateError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setCreateLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.name)
      errors.name = 'Obrigatório'

    if (type === 'spot')
      if (!values.value)
        errors.value = 'Obrigatório'

    if (!values.cnae_code)
      errors.cnae_code = 'Obrigatório'

    if (!values.service_code)
      errors.service_code = 'Obrigatório'

    return errors
  }

  return {
    cnaeList,
    createLoading,
    createError,
    formError,
    openSnackbar,
    serviceList,
    valueCnae,
    valueService,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    searchCNAE,
    searchService
  }
}