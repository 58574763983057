import { useState } from 'react';

import { useOpenedFilters } from '../../../recoil/openedFilters/actions';

type ViewModelPropTypes = {
  id: string,
}

export default function RangeFilterViewModel({ id }: ViewModelPropTypes) {
  const [lowerLimit, setLowerLimit] = useState<number>();
  const [upperLimit, setUpperLimit] = useState<number>();

  const { openedFilter, onCloseFilter, onOpenFilter } = useOpenedFilters();

  const isOpen = openedFilter === id;

  const onLowerLimitChange = (value: string) => {
    const numberValue = +value;

    setLowerLimit(numberValue)

    if (numberValue > (upperLimit as number))
      setUpperLimit(numberValue)
  }

  const onUpperLimitChange = (value: string) => {
    const numberValue = +value;

    setUpperLimit(numberValue)

    if (numberValue < (lowerLimit as number))
      setLowerLimit(numberValue)
  }

  const onClick = () => {
    if (isOpen)
      onCloseFilter();
    else
      onOpenFilter(id)
  };

  return {
    lowerLimit,
    isOpen,
    upperLimit,
    openedFilter,
    onClick,
    onLowerLimitChange,
    onUpperLimitChange,
    onCloseFilter,
    onOpenFilter
  };
}