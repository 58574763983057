import useViewModel from './viewModel';

import { AccountReceivableListTable } from 'components/Tables/AccountReceivableListTable';
import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';

type AccountsRceivablesViewPropTypes = {
  clientId: string
}

function AccountsReceivablesView({ clientId }: AccountsRceivablesViewPropTypes) {
  const {
    ARsLoading,
    ARsLoadingError,
    formattedARs,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalARs,
    onPageChange,
    onChangeSort,
    onRowsPerPageChange,
  } = useViewModel({ clientId });

  return (
    <div className='listing-tab-view'>

      {!ARsLoading &&
        !ARsLoadingError &&
        formattedARs?.length === 0 &&
        <EmptyView title={'Nenhum conta a receber encontrado para mostrar aqui'}></EmptyView>
      }

      {ARsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os contas a receber. Tente recarregar a página.' />
      }

      {(ARsLoading || (formattedARs !== undefined && formattedARs.length > 0)) &&
        <AccountReceivableListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedARs ? formattedARs : []}
          rowLink={'/accounts-receivables'}
          rowsPerPage={rowsPerPage}
          total={totalARs}
          isLoading={ARsLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />}

    </div>
  );
}

export { AccountsReceivablesView };