import { useEffect, useState } from 'react';

import {ApiCheckoutError} from 'utils/CustomError';
import { useCreateCheckoutOrderActions } from 'recoil/checkout/actions';

import {
    formatSaleToDisplay,
    formatCheckoutOrderPayloadToDisplay
} from './services';
import {bundlePayloadToRecoil, postOrder} from "./services";

type viewModelPropTypes = {
    onBack: () => void
    onNext: () => void
}

export default function CustomerStepViewModel({onBack, onNext}: viewModelPropTypes) {
    const { checkoutOrderPayload, setCheckoutOrderPayload } = useCreateCheckoutOrderActions()

    const formattedCheckoutOrderPayload = formatCheckoutOrderPayloadToDisplay(checkoutOrderPayload)
    const formattedSale = formatSaleToDisplay(checkoutOrderPayload)

    const [orderLoading, setOrderLoading] = useState(false)
    const [orderLoadingError, setOrderLoadingError] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false)
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false)

    useEffect(() => {
        setAcceptedTermsOfUse(!checkoutOrderPayload.company?.term_of_use)
        setAcceptedPrivacyPolicy(!checkoutOrderPayload.company?.privacy_policy)

    }, [checkoutOrderPayload])

    const onPostOrderHandleError = (errors: ApiCheckoutError) => {
        setOrderLoadingError(true)
        setOpenSnackbar(true)
    }

    const onSubmit = async () => {
        setOrderLoading(true);

        const response = await postOrder(checkoutOrderPayload)

        if (response.errors)
            onPostOrderHandleError(response.errors)
        else {
            setCheckoutOrderPayload(bundlePayloadToRecoil(response.data.hash, checkoutOrderPayload))

            onNext()
            setOpenSnackbar(false)
            setOrderLoadingError(false)
        }

        setOrderLoading(false);
    }

    const onCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const onChangeTermsOfUse = (selected: boolean) => {
        setAcceptedTermsOfUse(selected)
    }

    const onChangePrivacyPolicy = (selected: boolean) => {
        setAcceptedPrivacyPolicy(selected)
    }

    return {
        openSnackbar,
        orderLoading,
        orderLoadingError,
        checkoutOrderPayload,
        formattedCheckoutOrderPayload,
        formattedSale,
        acceptedTermsOfUse,
        acceptedPrivacyPolicy,
        onCloseSnackbar,
        onChangeTermsOfUse,
        onChangePrivacyPolicy,
        onSubmit
    }
}