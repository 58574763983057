import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { ApiErrorView } from 'components/ApiErrorView';
import { AutocompleteAdapter } from 'components/FormComponents/Fields/AutocompleteAdapter';
import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';
import { Flexbox } from 'components/Flexbox';
import { PercentFieldAdapter } from 'components/FormComponents/Fields/PercentFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss'

type EditProductModalPropTypes = {
  open: boolean,
  id: string,
  type: string,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function EditProductModal({ open, id, type, onClose, onSubmit }: EditProductModalPropTypes) {
  const {
    cnaeList,
    editError,
    formattedProduct,
    formError,
    openSnackbar,
    productLoading,
    productLoadingError,
    serviceList,
    valueCnae,
    valueService,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    searchCNAE,
    searchService,
  } = useViewModel({ id, type, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='edit-product-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={formattedProduct}
            validate={formValidate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Editar Produto - {type === 'recurring' ? 'Assinatura' : 'Spot'}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                {!productLoadingError &&
                  <div className='modal-body'>
                    <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>

                    {type === 'recurring' ? (
                      <Flexbox size={12}>
                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Nome'}
                            name='name'
                            loading={productLoading}
                          />
                        </Flexbox>
                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Código interno'}
                            name='internal_code'
                            loading={productLoading}
                          />
                        </Flexbox>
                      </Flexbox>
                    ) : (
                      <Flexbox size={12}>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            label={'Nome'}
                            name='name'
                            loading={productLoading}
                          />
                        </Flexbox>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            label={'Código interno'}
                            name='internal_code'
                            loading={productLoading}
                          />
                        </Flexbox>
                        <Flexbox size={4}>
                          <CurrencyFieldAdapter
                            label={'Valor'}
                            name='value'
                            loading={productLoading}
                          />
                        </Flexbox>
                      </Flexbox>
                    )}

                    <Flexbox size={12}>
                      <TextFieldAdapter
                        label={'Descrição'}
                        name='description'
                        loading={productLoading}
                        multiline
                        rows={3}
                      />
                    </Flexbox>

                    <Typography variant='h3' fontWeight={700} className='section-title'>Características fiscais</Typography>

                    <Flexbox size={12}>
                      <AutocompleteAdapter
                        label='CNAE'
                        name='cnae_code'
                        options={cnaeList}
                        value={valueCnae}
                        onChange={searchCNAE}
                        loading={productLoading}
                      />
                    </Flexbox>

                    <Flexbox size={12}>
                      <Flexbox size={6}>
                        <AutocompleteAdapter
                          label='Código de serviço'
                          name='service_code'
                          options={serviceList}
                          value={valueService}
                          onChange={searchService}
                          loading={productLoading}
                        />
                      </Flexbox>
                      <Flexbox size={6}>
                        <TextFieldAdapter
                          label='Código de serviço secundário'
                          name='custom_service_code'
                          loading={productLoading}
                        />
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={4}>
                      <PercentFieldAdapter
                        label='Alíquota de ISS'
                        name='iss'
                        loading={productLoading}
                      />
                    </Flexbox>

                    <Typography variant='h3' fontWeight={700} className='section-title'>Características fiscais</Typography>

                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <PercentFieldAdapter
                          label='IRRF'
                          name='irrf'
                          loading={productLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <PercentFieldAdapter
                          label='PIS/PASEP'
                          name='pis'
                          loading={productLoading}
                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <PercentFieldAdapter
                          label='COFINS'
                          name='cofins'
                          loading={productLoading}
                        />
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={4}>
                      <PercentFieldAdapter
                        label='CSLL'
                        name='csll'
                        loading={productLoading}
                      />
                    </Flexbox>
                  </div>
                }

                {productLoadingError && !productLoading &&
                  <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as informações do produto.' />
                }

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={productLoading || productLoadingError}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(editError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Produto atualizado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { EditProductModal }