import { CustomError, api } from "../../utils/axios";
import flagsmith from 'flagsmith';

type FormValuesTypes = {
  email: string,
  password: string,
  hash?: string,
  url?: string,
  accept_term_of_use?: boolean
}

type FlagsmithTypes = {
  token: string,
  user_id: string,
  company_id: number,
}

type ServiceLoginTypes = {
  token: string,
  user: any,
  permissions: any[]
}

const bundleLoginServicePayload = (values: FormValuesTypes) => ({
  data: {
    email: values.email,
    password: values.password,
    hash: values.hash,
    accept_term_of_use: values.accept_term_of_use
  }
})

const loginService = async (values: FormValuesTypes): Promise<any> => {
  try {
    const response = (await api.post(`/login`, bundleLoginServicePayload(values))).data;
    return {
      token: `Bearer ${response.data.attributes.api_token}`,
      user: response.data.attributes,
      permissions: response.included
    }

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const featureFlagService = async (values: FlagsmithTypes) => {
  await flagsmith.init({
    environmentID: values.token,
    identity: values.user_id,
    traits: { 'company_id': values.company_id },
  });

  return flagsmith.getAllFlags()
}

export { featureFlagService, loginService };

export type { FormValuesTypes, ServiceLoginTypes };
