import { Button, Typography } from '@mui/material';

import useViewModel from './viewModel';

import { ApiErrorView } from 'components/ApiErrorView';
import { BankListTable } from 'components/Tables/BanksListTable';
import { EmptyView } from 'components/EmptyView';

import { CreateCashierBankModal } from 'components/Modals/CashierBanks/Create';
import { EditCashierBankModal } from 'components/Modals/CashierBanks/Edit';

function Banks() {
  const {
    actionIndex,
    bankLoading,
    bankLoadingError,
    banks,
    formattedBanks,
    page,
    optionsRowsPerPage,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalBanks,
    openCreate,
    openEdit,
    handleTableRowActions,
    onChangeSort,
    onCloseEditModal,
    onPageChange,
    onRowsPerPageChange,
    onOpenCreateModal,
    onCloseCreateModal
  } = useViewModel();

  return (
    <div className='listing-page customers-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Contas bancárias
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>
            <Button
              variant='contained'
              size='small'
              onClick={onOpenCreateModal}
            >
              Criar Conta
            </Button>

            <CreateCashierBankModal
              onClose={onCloseCreateModal}
              open={openCreate}
            />
          </div>
        </div>
      </div>

      {!bankLoading &&
        !bankLoadingError &&
        formattedBanks?.length === 0 &&
        <EmptyView title={'Nenhum caixa ou conta bancária encontrada para mostrar aqui'}></EmptyView>
      }

      {bankLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as contas bancárias. Tente recarregar a página.' />
      }

      {(bankLoading || (formattedBanks !== undefined && formattedBanks?.length > 0)) && <>
        <BankListTable
          hasActions
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedBanks ? formattedBanks : []}
          rowsPerPage={rowsPerPage}
          total={totalBanks}
          isLoading={bankLoading}
          onClickOption={handleTableRowActions}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />

        <EditCashierBankModal
          cashierData={{
            id: banks && actionIndex !== undefined ? banks[actionIndex].id : '',
            account_number: banks && actionIndex !== undefined ? banks[actionIndex].account_number : '',
            account_number_digit_verifier: banks && actionIndex !== undefined ? banks[actionIndex].account_number_digit_verifier : '',
            bank_code: banks && actionIndex !== undefined ? banks[actionIndex].bank_code : '',
            bank_name: formattedBanks && actionIndex !== undefined ? formattedBanks[actionIndex].bank_name : '',
            name: banks && actionIndex !== undefined ? banks[actionIndex].name : '',
            routing_number: banks && actionIndex !== undefined ? banks[actionIndex].routing_number : '',
            routing_number_digit_verifier: banks && actionIndex !== undefined ? banks[actionIndex].routing_number_digit_verifier : '',
          }}
          onClose={onCloseEditModal}
          open={openEdit}
        />
      </>
      }
    </div>
  );
}

export { Banks };