import { Box, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import { AccountsReceivablesView } from './pages/AccountsReceivables';
import { ClientGeneralView } from './pages/General';
import { CreditCardView } from './pages/CreditCard';
import { EmailView } from './pages/Email';
import { InvoicesReceiptsView } from './pages/InvoicesReceipts';
import { SalesRecurringView } from './pages/Recurring';
import { SalesSpotView } from './pages/Spot';

import useViewModel from './viewModel'

import './styles.scss'
import { InvoiceGroupsView } from './pages/InvoiceGroups';

function ClientView() {
  const {
    loadingTitleName,
    params,
    tabSelected,
    titleName,
    onTabChange
  } = useViewModel();

  return (
    <div className='client-view'>

      <div className='view-header'>
        <div className='view-title'>

          {!loadingTitleName ? (

            <Typography variant='h3' fontWeight={700}>
              {titleName}
            </Typography>

          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
          onChange={(event, value) => { onTabChange(value) }}
        >

          <Tab
            label='Visão Geral'
            id='general'
            aria-controls='tabpanel-general'
          />
          <Tab
            label='Assinaturas'
            id='recurring'
            aria-controls='tabpanel-recurring'
          />
          <Tab
            label='Spot'
            id='spot'
            aria-controls='tabpanel-spot'
          />
          <Tab
            label='Faturas Geradas'
            id='invoice-groups'
            aria-controls='tabpanel-invoice-groups'
          />
          <Tab
            label='Contas a receber'
            id='accounts-receivables'
            aria-controls='tabpanel-accounts-receivables'
          />
          <Tab
            label='Notas fiscais'
            id='invoices-receipts'
            aria-controls='tabpanel-invoices-receipts'
          />
          <Tab
            label='Cartão de Crédito'
            id='credit-card'
            aria-controls='tabpanel-credit-card'
          />
          <Tab
            label='Emails'
            id='emails'
            aria-controls='tabpanel-emails'
          />

        </Tabs>
      </Box>

      <div
        role="tabpanel"
        hidden={tabSelected !== 0}
        id={'tabpanel-general'}
        aria-labelledby={'tab-general'}
      >
        <ClientGeneralView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 1}
        id={'tabpanel-recurring'}
        aria-labelledby={'tab-recurring'}
      >
        <SalesRecurringView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 2}
        id={'tabpanel-spot'}
        aria-labelledby={'tab-spot'}
      >
        <SalesSpotView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 3}
        id={'tabpanel-invoice-groups'}
        aria-labelledby={'tab-invoice-groups'}
      >
        <InvoiceGroupsView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 4}
        id={'tabpanel-accounts-receivables'}
        aria-labelledby={'tab-accounts-receivables'}
      >
        <AccountsReceivablesView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 5}
        id={'tabpanel-invoices-receipts'}
        aria-labelledby={'tab-invoices-receipts'}
      >
        <InvoicesReceiptsView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 6}
        id={'tabpanel-credit-card'}
        aria-labelledby={'tab-credit-card'}
      >
        <CreditCardView
          clientId={params.id ? params.id : ''}
        />
      </div>

      <div
        role="tabpanel"
        hidden={tabSelected !== 7}
        id={'tabpanel-emails'}
        aria-labelledby={'tab-emails'}
      >
        <EmailView
          clientId={params.id ? params.id : ''}
        />
      </div>

    </div>
  );
}

export { ClientView }