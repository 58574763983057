import { useCreateSaleActions } from 'recoil/createSale/actions';

import { useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { formatSalePayloadToDisplay, postSale } from './services';
import { ApiError } from 'utils/CustomError';

type viewModelPropTypes = {
  type: string,
  onNext: () => void
}

export default function SummaryStepViewModel({ type, onNext }: viewModelPropTypes) {
  const { salePayload } = useCreateSaleActions()

  const formattedSalePayload = formatSalePayloadToDisplay(salePayload)

  const [saleLoading, setSaleLoading] = useState(false)
  const [saleLoadingError, setSaleLoadingError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onPostSaleHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setSaleLoadingError(true)
      setOpenSnackbar(true)
    } else {
      setSaleLoadingError(true)
      setOpenSnackbar(true)
    }
  }

  const onSubmit = async (values: Record<string, any>) => {
    setSaleLoading(true)
    const response = await postSale(type, salePayload, values)

    if (response.errors)
      onPostSaleHandleError(response.errors)

    else {
      setSaleLoadingError(false)
      setOpenSnackbar(true)
      onNext()
    }

    setSaleLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  return {
    formattedSalePayload,
    openSnackbar,
    saleLoading,
    saleLoadingError,
    salePayload,
    onCloseSnackbar,
    onSubmit
  }
} 