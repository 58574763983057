import { useRecoilState } from 'recoil';

import { loadingTitleNameAtom, titleNameAtom } from './states';

function useTitleName() {
  const [loadingTitleName, setLoadingTitleName] = useRecoilState(loadingTitleNameAtom);
  const [titleName, setTitleName] = useRecoilState(titleNameAtom);

  const updateTitleName = (title: string, loading = false) => {
    setTitleName(title)
    setLoadingTitleName(loading)
  }

  return { loadingTitleName, titleName, updateTitleName }
}

export { useTitleName }