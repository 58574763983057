const BANKS_CODE: any = {
  "100": "Planner Corretora",
  "101": "Renascença Corretora",
  "102": "XP Investimentos Corretora",
  "104": "Caixa Econômica Federal",
  "105": "Lecca Crédito",
  "107": "Banco Bocom BBM",
  "108": "PortoCred",
  "111": "Oliveira Trust Corretora",
  "113": "Magliano Corretora",
  "114": "CECOOP",
  "117": "Advanced Corretora",
  "118": "Standard Chartered Bank",
  "119": "Banco Western Union do Brasil",
  "120": "Banco Rodobens",
  "121": "Banco Agibank",
  "122": "Banco Bradesco BERJ",
  "124": "Banco Woori Bank do Brasil",
  "125": "Banco Brasil Plural",
  "126": "BR Partners Banco de Investimento",
  "127": "Codepe Corretora",
  "128": "MS Bank Banco de Câmbio",
  "129": "UBS Brasil Banco de Investimento",
  "130": "Caruana Financeira",
  "131": "Tullett Prebon Brasil Corretora",
  "132": "ICBC do Brasil",
  "133": "Cresol",
  "134": "BGC Liquidez Corretora",
  "135": "Gradual Corretora",
  "136": "UNICRED",
  "137": "Multimoney Corretora",
  "138": "Get Money Corretora",
  "139": "Intesa Sanpaolo Brasil",
  "140": "Easynvest Corretora",
  "142": "Broker Brasil Corretora",
  "143": "Treviso Corretora",
  "144": "Bexs Banco de Câmbio",
  "145": "Levycam Corretora",
  "146": "Guitta Corretora",
  "149": "Facta Financeira",
  "151": "Banco Nossa Caixa",
  "157": "ICAP do Brasil Corretora",
  "159": "Casa do Crédito",
  "163": "Commerzbank Brasil",
  "168": "HSBC Finance",
  "169": "Banco Olé Bonsucesso Consignado",
  "172": "Albatross Corretora",
  "173": "BRL Trust Corretora",
  "174": "Pernambucanas Financiadora",
  "177": "Guide Investimentos Corretora",
  "180": "CM CAPITAL MARKETS",
  "182": "Dacasa Financeira",
  "183": "Socred",
  "184": "Banco Itaú BBA",
  "188": "Ativa Investimentos Corretora",
  "189": "HS Financeira",
  "190": "Servicoop",
  "191": "Nova Futura Corretora",
  "194": "Parmetal Corretora",
  "196": "Fair Corretora",
  "197": "Stone Pagamentos",
  "204": "Banco Bradesco Cartões",
  "208": "Banco BTG Pactual",
  "212": "Banco Original",
  "213": "Banco Arbi",
  "214": "Banco Dibens",
  "215": "Banco Sudameris",
  "217": "Banco John Deere",
  "218": "BCO BS2 / Banco Bonsucesso",
  "222": "Banco Credit Agricole Brasil",
  "224": "Banco Fibra",
  "225": "Banco Brascan",
  "229": "Banco Cruzeiro do Sul",
  "230": "Unicard",
  "233": "Banco GE Capital",
  "237": "Banco Bradesco",
  "241": "Banco Clássico",
  "243": "Banco Máxima",
  "246": "Banco ABC Brasil",
  "248": "Banco Boavista Interatlântico",
  "249": "Banco Investcred Unibanco",
  "250": "Banco Schahin",
  "253": "Bexs Corretora",
  "254": "Paraná Banco",
  "260": "Nu Pagamentos",
  "263": "Banco Cacique",
  "265": "Banco Fator",
  "266": "Banco Cédula",
  "268": "Barigui Companhia Hipotecária",
  "269": "HSBC Brasil Banco de Investimento",
  "271": "IB Corretora de Câmbio",
  "283": "RB Capital Investimentos Dtvm Ltda",
  "290": "Banco Pagbank / PagSeguro Internet SA",
  "300": "Banco de La Nacion Argentina",
  "318": "Banco BMG",
  "320": "Banco Industrial e Comercial",
  "336": "Banco C6 S.A",
  "341": "Itaú Unibanco",
  "356": "Banco Real",
  "366": "Banco Société Générale Brasil",
  "370": "Banco West LB do Brasil",
  "376": "Banco J P Morgan",
  "389": "Banco Mercantil do Brasil",
  "394": "Banco Bradesco Financiamentos",
  "399": "HSBC Bank Brasil",
  "403": "CORA SCD S.A",
  "409": "UNIBANCO",
  "412": "Banco Capital",
  "422": "Banco Safra",
  "453": "Banco Rural",
  "456": "Banco de Tokyo-Mitsubishi UFJ Brasil",
  "464": "Banco Sumitomo Mitsui Brasileiro",
  "473": "Banco Caixa Geral Brasil",
  "477": "Citibank",
  "487": "Deutsche Bank",
  "488": "JPMorgan Chase Bank",
  "492": "ING Bank",
  "505": "Banco Credit Suisse",
  "545": "Senso Corretora",
  "600": "Banco Luso Brasileiro",
  "604": "Banco Industrial do Brasil",
  "610": "Banco VR",
  "611": "Banco Paulista",
  "612": "Banco Guanabara",
  "613": "Banco Pecúnia / Omni Banco",
  "623": "Banco Panamericano",
  "626": "Banco Ficsa",
  "630": "Banco Intercap",
  "633": "Banco Rendimento",
  "634": "Banco Triângulo",
  "637": "Banco Sofisa",
  "638": "Banco Prosper",
  "641": "Banco Alvorada",
  "643": "Banco Pine",
  "652": "Itaú Unibanco Holding",
  "653": "Banco Indusval",
  "654": "Banco A J Renner",
  "655": "Banco Votorantim",
  "707": "Banco Daycoval",
  "712": "Banco Ourinvest",
  "719": "Banif",
  "721": "Banco Credibel",
  "724": "Banco Porto Seguro",
  "734": "Banco Gerdau",
  "735": "Banco Neon",
  "738": "Banco Morada",
  "739": "Banco Cetelem",
  "740": "Banco Barclays",
  "741": "Banco Ribeirão Preto",
  "743": "Banco Semear",
  "744": "BankBoston",
  "745": "Banco Citibank",
  "746": "Banco Modal",
  "747": "Banco Rabobank",
  "748": "Banco Cooperativo Sicredi",
  "749": "Banco Simples",
  "751": "Scotiabank Brasil / Dresdner Bank Brasil",
  "752": "Banco BNP Paribas Brasil",
  "753": "NBC Bank Brasil Banco Múltiplo",
  "754": "Banco Sistema",
  "755": "Bank of America Merrill Lynch",
  "756": "BANCOOB",
  "757": "Banco Keb Hana do Brasil",
  "075": "Banco ABN Amro",
  "025": "Banco Alfa",
  "065": "Banco Andbank",
  "019": "Banco Azteca do Brasil",
  "024": "Banco Bandepe",
  "029": "Banco Banerj / Banco Itaú Consignado",
  "038": "Banco Banestado",
  "021": "Banco BANESTES",
  "041": "Banco BANRISUL",
  "031": "Banco Beg",
  "096": "Banco BM&F",
  "063": "Banco Bradescard / Banco Ibi",
  "036": "Banco Bradesco BBI",
  "081": "Banco Brasileiro de Negócios BBN",
  "M15": "Banco BRJ",
  "044": "Banco BVA",
  "040": "Banco Cargill",
  "M08": "Banco Citicard",
  "M19": "Banco CNH Capital",
  "095": "Banco Confidence de Câmbio",
  "069": "BPN Brasil",
  "003": "Banco da Amazônia",
  "083": "Banco da China Brasil",
  "M21": "Banco Daimlerchrysler",
  "M06": "Banco de Lage Landen Brasil",
  "001": "Banco do Brasil",
  "047": "Banco do Estado de Sergipe",
  "037": "Banco do Estado do Pará",
  "039": "Banco do Estado do PiauíBEP",
  "004": "Banco do Nordeste do Brasil",
  "M03": "Banco Fiat",
  "094": "Banco Finaxis",
  "M18": "Banco Ford",
  "M07": "Banco GMAC",
  "M22": "Banco Honda",
  "M11": "Banco IBM",
  "012": "Banco Inbursa",
  "077": "Banco Inter",
  "M09": "Banco Itaucred",
  "074": "Banco J Safra",
  "076": "Banco KDB",
  "M12": "Banco Maxinvest",
  "M10": "Banco Moneo",
  "066": "Banco Morgan Stanley",
  "045": "Banco Opportunity",
  "079": "Banco Original do Agronegócio",
  "M17": "Banco Ourinvest",
  "M24": "Banco PSA Finance Brasil",
  "088": "Banco Randon",
  "M16": "Banco Rodobens",
  "072": "Banco Rural Mais",
  "033": "Banco Santander",
  "082": "Banco Topázio",
  "M20": "Banco Toyota do Brasil",
  "018": "Banco Tricury",
  "M13": "Banco Tricury",
  "M14": "Banco Volkswagen",
  "M23": "Banco Volvo",
  "073": "BB Banco Popular do Brasil",
  "078": "BES Investimento do Brasil",
  "007": "BNDES",
  "017": "BNY Mellon Banco",
  "070": "BRBBanco de Brasília",
  "092": "Brickell Crédito",
  "080": "BT Corretora",
  "085": "Cecred",
  "097": "CentralCredi",
  "060": "Confidence Corretora",
  "098": "CREDIALIANÇA CCR",
  "010": "CREDICOAMO",
  "089": "CREDISAN",
  "011": "Credit Suisse Hedging-Griffo Corretora",
  "016": "CREDITRAN",
  "064": "Goldman Sachs do Brasil",
  "062": "Hipercard",
  "014": "Natixis Brasil",
  "093": "Pólocred",
  "015": "UBS Brasil Corretora",
  "091": "Unicred Central RS",
  "099": "Uniprime Central",
  "084": "Uniprime Norte do Paraná"
}

export { BANKS_CODE }