import { CustomError, api } from 'utils/axios'

import { parseToFloat } from 'utils/moneyFormatt'

type PlanPayload = {
  name: string,
  freq: string,
  initial_term: string,
  payment_term_type: string,
  payment_term_value: string,
  value: string
}

type ParsedPlanPayload = {
  name: string,
  freq: string,
  initial_term: number,
  payment_term_type: string,
  payment_term_value: number,
  value: number
}

const bundlePayload = (payload: PlanPayload): ParsedPlanPayload => (
  {
    name: payload.name,
    freq: payload.freq,
    initial_term: parseInt(payload.initial_term),
    payment_term_type: payload.payment_term_type,
    payment_term_value: parseInt(payload.payment_term_value),
    value: parseToFloat(payload.value)
  }
)

const postPlan = async (productId: string, payload: PlanPayload): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/products/${productId}/plans`, bundlePayload(payload))

    return response
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { postPlan }

export type { PlanPayload }