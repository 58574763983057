import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ApiError } from 'utils/CustomError'

import { formatDetailsToDisplay, postPix, DetailsType, DetailsDisplayType } from './services'
import dayjs from 'dayjs';

export default function ForgotPasswordViewModel() {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const hash = searchParams.get('hash') || '';

  const [details, setDetails] = useState<DetailsType>();
  const [formattedDetails, setFormattedDetails] = useState<DetailsDisplayType>();
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [detailsLoadingError, setDetailsLoadingError] = useState(false);

  const [expirationTimer, setExpirationTimer] = useState<{ min: string, sec: string }>();
  const [expired, setExpired] = useState(false)


  useEffect(() => {
    const interval = setInterval(() => {
      if (details && !expired) {
        const now = dayjs();
        const expiresAt = dayjs(details.expires_at);

        const min = expiresAt.diff(now, 'minute');
        const sec = (expiresAt.diff(now, 'second') % 60);

        if (min < 0 || sec < 0) {
          setExpired(true)
          return;
        }

        let formattedMin = min.toFixed(0)
        let formattedSec = sec.toFixed(0)

        if (formattedMin.length < 2)
          formattedMin = `0${formattedMin}`

        if (formattedSec.length < 2)
          formattedSec = `0${formattedSec}`

        setExpirationTimer({ min: formattedMin, sec: formattedSec })
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [expired, details])

  useEffect(() => {
    if (!hash) {
      return;
    }

    const abortController = new AbortController();

    (async () => {
      setDetailsLoading(true);

      const response = await postPix(hash, abortController.signal);

      if (response.errors) {
        onPostPixHandleError(response.errors)

      } else {
        setDetailsLoadingError(false)
        setDetails(response);
        setFormattedDetails(formatDetailsToDisplay(response))
      }

      setDetailsLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort()
    }
  }, [hash])

  const onPostPixHandleError = (errors: ApiError) => {

    if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {
    } else if (errors[0].type === 'ERR_NETWORK') {
      setDetailsLoadingError(true)

      setDetails(undefined);
      setFormattedDetails(undefined)
    } else {
      setDetailsLoadingError(true)

      setDetails(undefined);
      setFormattedDetails(undefined)
    }
  }

  return {
    details,
    detailsLoading,
    detailsLoadingError,
    expired,
    expirationTimer,
    formattedDetails,
    hash,
  }
}