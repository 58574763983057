import { ApiErrorView } from 'components/ApiErrorView';
import useViewModel from './viewModel';

import { EmptyView } from 'components/EmptyView';
import { InvoiceReceiptListTable } from 'components/Tables/InvoiceReceiptListTable';

type InvoicesReceiptsViewPropTypes = {
  clientId: string
}

function InvoicesReceiptsView({ clientId }: InvoicesReceiptsViewPropTypes) {
  const {
    formattedInvoices,
    invoicesLoading,
    invoicesLoadingError,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    tableHeadCells,
    totalInvoices,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ clientId });

  return (
    <div className='listing-tab-view'>

      {!invoicesLoading &&
        !invoicesLoadingError &&
        formattedInvoices?.length === 0 &&
        <EmptyView title={'Nenhuma nota fiscal encontrada para mostrar aqui'}></EmptyView>
      }

      {invoicesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as notas fiscais. Tente recarregar a página.' />
      }

      {(invoicesLoading || (formattedInvoices !== undefined && formattedInvoices.length > 0)) &&
        <InvoiceReceiptListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rowLink='/invoices-receipts'
          rows={formattedInvoices ? formattedInvoices : []}
          rowsPerPage={rowsPerPage}
          total={totalInvoices}
          isLoading={invoicesLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />}
    </div>
  );
}

export { InvoicesReceiptsView }