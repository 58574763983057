import MuiTable from '@mui/material/Table';
import { TableBody, TableContainer, TablePagination, TableRow, Paper, Skeleton, Tooltip } from '@mui/material';

import { TableCell } from '../Table/TableCell';
import { TableHead } from '../Table/TableHead';

import '../styles.scss'
import { ActionButtonTable } from '../Table/ActionButtonTable';

type TablePropTypes = {
  hasActions?: boolean,
  headCells: Array<{
    id: string,
    label: string
  }>,
  isAsc: boolean,
  page: number,
  optionsRowsPerPage: Array<number>,
  orderBy: string,
  rowLink?: string,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  isLoading: boolean,
  onClickOption?: (index: number, action: string) => void,
  onPageChange: (page: number) => void,
  onRequestSort: (params: { field: string, isAsc: boolean }) => void,
  onRowsPerPageChange: (value: number) => void,
}

function SaleListTable({
  hasActions,
  headCells,
  isAsc,
  page,
  optionsRowsPerPage,
  orderBy,
  rowLink,
  rows,
  rowsPerPage,
  total,
  isLoading,
  onClickOption,
  onPageChange,
  onRequestSort,
  onRowsPerPageChange,
}: TablePropTypes) {
  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead
          headCells={headCells}
          isAllSelected={false}
          isAnySelected={false}
          optionMenu={hasActions}
          isAsc={isAsc}
          onRequestSort={onRequestSort}
          orderBy={orderBy}
        />

        <TableBody>

          {isLoading && [1, 2, 3, 4, 5].map((index) => (
            <TableRow
              className='table-row'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headCells.map((cell, index) => (
                <TableCell key={index}><Skeleton /></TableCell>
              ))}

              {hasActions &&
                <TableCell><Skeleton /></TableCell>
              }
            </TableRow>
          ))}

          {!isLoading && rows.map((row, index) => (
            <TableRow
              className={`table-row ${rowLink && 'linked-table-row'}`}
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {
                headCells.map((cell, index) => {
                  if (cell.id === 'customer_name') {
                    return <TableCell to={rowLink ? `${rowLink}/${row.id}` : undefined} className='table-cell' key={index} align='left'>
                      <div>{row[cell.id]}</div>
                      <div>{row['key']}</div>
                    </TableCell>
                  }
                  else if (cell.id === 'status') {
                    let statusClass = ''
                    if (row[cell.id] === 'ATIVO') {
                      statusClass = 'label-status-green'
                    } else if (row[cell.id] === 'CANCELADO') {
                      statusClass = 'label-status-red'
                    } else if (row[cell.id] === 'EXPIRADO') {
                      statusClass = 'label-status-yellow'
                    }

                    return <TableCell to={rowLink ? `${rowLink}/${row.id}` : undefined} className='table-cell' key={index} align='left'><span className={`label-status ${statusClass}`}>{row[cell.id]}</span></TableCell>
                  }
                  else if (cell.id === 'tags') {
                    let tags = null
                    if (row[cell.id]) {
                      tags = row[cell.id]

                      return <TableCell to={rowLink ? `${rowLink}/${row.id}` : undefined} className='table-cell' key={index} align='left'>
                        {tags.split(',').length > 2 &&
                          <div className='label-column'>
                            <span className='label-tag-container'>
                              {row.tags && row.tags.split(',').slice(0, 2).map((tag: string, tagIndex: number) => (
                                <span className='label-tag' key={`${index}-${tagIndex}`}>{tag}</span>
                              ))}
                            </span>
                            <span className='label-tag-container-plus'>
                              <Tooltip title={row.tags} arrow>
                                <span className='label-tag'>+{row.tags.split(',').length - 2}</span>
                              </Tooltip>
                            </span>
                          </div>
                        }

                        {
                          tags.split(',').length <= 2 &&
                          <span className='label-tag-container'>
                            {row.tags && row.tags.split(',').map((tag: string, tagIndex: number) => (
                              <span className='label-tag' key={`${index}-${tagIndex}`}>{tag}</span>
                            ))}
                          </span>
                        }
                      </TableCell>
                    }

                    return <TableCell to={rowLink ? `${rowLink}/${row.id}` : undefined} className='table-cell' key={index} align='left'></TableCell>
                  }
                  else {
                    return <TableCell to={rowLink ? `${rowLink}/${row.id}` : undefined} className='table-cell' key={index} align='left'>{row[cell.id]}</TableCell>
                  }
                })
              }

              {hasActions && (
                <TableCell className='table-cell menu-options-cell' align='left'>
                  <ActionButtonTable
                    options={row.actions}
                    onClickOption={(action) => {
                      if (onClickOption)
                        onClickOption(index, action)
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <TablePagination
        labelRowsPerPage='Itens por página'
        rowsPerPageOptions={optionsRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(e, page) => {
          onPageChange(page + 1)
        }}
        onRowsPerPageChange={(e) => {
          onRowsPerPageChange(parseInt(e.target.value))
        }}
      />

    </TableContainer>
  );
}

export { SaleListTable };