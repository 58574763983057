import { Skeleton, Typography } from '@mui/material'

import { ApiErrorView } from 'components/ApiErrorView';
import { DisplayInfoText } from 'components/Info/DisplayInfoText';
import { Flexbox } from 'components/Flexbox';

import useViewModel from './viewModel'
import { DetailAttachment } from 'components/DetailAttachment';

type ClientGeneralViewPropTypes = {
  clientId: string
}

function ClientGeneralView({ clientId }: ClientGeneralViewPropTypes) {
  const {
    formattedDetails,
    detailsLoading,
    detailsLoadingError,
  } = useViewModel({ clientId })

  return (
    <div className='details'>

      {detailsLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os detalhes do cliente. Tente recarregar a página.' />
      }

      {!detailsLoadingError && (detailsLoading || formattedDetails !== undefined) &&
        <>
          <div className='basic-info segment-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Status do cliente'
                  text={formattedDetails?.status ? formattedDetails.status : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title={formattedDetails?.regime_type === 'pj' ? 'Nome fantasia' : 'Nome'}
                  text={formattedDetails?.name ? formattedDetails.name : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title={
                    formattedDetails?.regime_type === 'pj' ? 'CNPJ' :
                      formattedDetails?.regime_type === 'pf' ? 'CPF' :
                        'Documento'
                  }
                  text={formattedDetails?.document ? formattedDetails.document : ''}
                />
              </Flexbox>
            </div>

            <div className='info-row'>
              {formattedDetails?.regime.razao_social &&
                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Razão social'
                    text={formattedDetails?.regime.razao_social ? formattedDetails.regime.razao_social : ''}
                  />
                </Flexbox>
              }
              {formattedDetails?.regime_type === 'pj' &&
                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='Optante pelo Simples Nacional'
                    text={formattedDetails?.regime.optante_simples_nacional ? formattedDetails.regime.optante_simples_nacional : ''}
                  />
                </Flexbox>
              }
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Tags'
                  text={formattedDetails?.tags ? formattedDetails.tags : ''}
                />
              </Flexbox>
            </div>
          </div>

          <div className='address-info segment-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Endereço</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='CEP'
                  text={formattedDetails?.address ? formattedDetails.address.postal_code : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Cidade'
                  text={formattedDetails?.address ? formattedDetails.address.city : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Bairro'
                  text={formattedDetails?.address ? formattedDetails.address.neighbourhood : ''}
                />
              </Flexbox>
            </div>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Logradouro'
                  text={formattedDetails?.address ? formattedDetails.address.street : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Número'
                  text={formattedDetails?.address ? formattedDetails.address.number : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Complemento'
                  text={
                    formattedDetails?.address ?
                      formattedDetails.address.complement ?
                        formattedDetails.address.complement :
                        ''
                      : ''
                  }
                />
              </Flexbox>
            </div>

            {formattedDetails?.regime_type === 'estrangeiro' &&
              <div className='info-row'>
                <Flexbox size={4}>
                  <DisplayInfoText
                    isLoading={detailsLoading}
                    title='País'
                    text={formattedDetails?.address ? formattedDetails.address.country : ''}
                  />
                </Flexbox>
              </div>
            }
          </div>

          <div className='segment-info contact-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Contato</Typography>

            <div className='info-row'>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Emails'
                  text={formattedDetails?.emails ? formattedDetails.emails : ''}
                />
              </Flexbox>
              <Flexbox size={4}>
                <DisplayInfoText
                  isLoading={detailsLoading}
                  title='Telefones'
                  text={formattedDetails?.phones ? formattedDetails.phones : ''}
                />
              </Flexbox>
            </div>

          </div>

          <div className='segment-info observation-info'>
            <Typography variant='h4' fontWeight={700} className='details-subtitle'>Observações</Typography>

            {(!detailsLoading && formattedDetails?.details.length === 0 && !formattedDetails?.description) &&
              <Typography fontWeight={700}>Nenhuma observação registrada.</Typography>
            }

            {detailsLoading && <Skeleton />}

            {!detailsLoading && formattedDetails?.description &&
              <div className='description-info'>
                <Typography fontWeight={700}>{formattedDetails?.description}</Typography>
              </div>
            }

            {(!detailsLoading && formattedDetails?.details.length !== 0) &&
              formattedDetails?.details.map((detail, index) => (
                <DetailAttachment
                  key={index}
                  description={detail.description}
                  url={detail.url}
                />
              ))
            }
          </div>
        </>
      }
    </div>
  )
}

export { ClientGeneralView }