import { Typography } from '@mui/material';

import useViewModel from './viewModel';

import { ApiErrorView } from 'components/ApiErrorView';
import { CheckboxFilter } from '../../components/Filters/CheckboxFilter';
import { DateRangeFilter } from '../../components/Filters/DateRangeFilter';
import { EmptyView } from 'components/EmptyView';
import { InvoiceReceiptListTable } from '../../components/Tables/InvoiceReceiptListTable';
import { RangeFilter } from '../../components/Filters/RangeFilter';
import { SearchFilter } from '../../components/Filters/SearchFilter';
import { Totals } from '../../components/Totals';

import { CancelInvoiceModal } from 'components/Modals/InvoiceReceipt/Cancel';
import { DescriptionInvoicesReceiptsModal } from 'components/Modals/InvoiceReceipt/Description';

import '../styles.scss'
import { SendInvoicesReceiptsModal } from 'components/Modals/InvoiceReceipt/Send';
import { ReopenInvoicesReceiptsModal } from 'components/Modals/InvoiceReceipt/Reopen';
import { EmailInvoiceReceiptModal } from 'components/Modals/InvoiceReceipt/SendEmail';

function InvoicesReceipts() {
  const {
    actionInvoice,
    formattedInvoices,
    formattedTotalValue,
    invoicesLoading,
    invoicesLoadingError,
    openCancel,
    openDescription,
    openReopen,
    openSend,
    openEmail,
    optionsRowsPerPage,
    page,
    paymentStatusFilter,
    paymentStatusOptions,
    rowsPerPage,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalInvoices,
    valueRangeLimits,
    applyPaymentStatusFilter,
    applyProcessedDateFilter,
    applyRangeFilter,
    applyStatusFilter,
    handleTableRowActions,
    onChangeSort,
    onCloseCancel,
    onCloseDescription,
    onCloseReopen,
    onCloseSend,
    onCloseEmail,
    onInvoiceSearchChange,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel();

  return (
    <div className='listing-page invoices-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Notas Fiscais
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>

          </div>
        </div>
      </div>

      <div className='totals-container'>
        <Totals
          title='Valor total'
          value={formattedTotalValue}
          isLoading={invoicesLoading}
        />
        <Totals
          title='Qtd. Nfs'
          value={totalInvoices}
          isLoading={invoicesLoading}
        />
      </div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por cliente ou número da nota'
            onSubmit={onInvoiceSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status NF'
            id='status'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <DateRangeFilter
            id='processed_date'
            buttonLabel='Data de emissão'
            onApplyFilter={applyProcessedDateFilter}
          />

          <CheckboxFilter
            buttonLabel='Status Pgto.'
            id='payment-status'
            initialSelected={paymentStatusFilter.value}
            options={paymentStatusOptions}
            onApplyFilter={applyPaymentStatusFilter}
          />

          <RangeFilter
            id='value'
            buttonLabel='Valor'
            label='Valor total'
            min={valueRangeLimits?.min}
            max={valueRangeLimits?.max}
            onApplyFilter={applyRangeFilter}
          />
        </div>
      </div>

      {!invoicesLoading &&
        !invoicesLoadingError &&
        formattedInvoices?.length === 0 &&
        <EmptyView title={'Nenhuma nota fiscal encontrada para mostrar aqui'}></EmptyView>
      }

      {invoicesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as notas fiscais. Tente recarregar a página ou usar filtros diferentes.' />
      }

      {(invoicesLoading || (formattedInvoices !== undefined && formattedInvoices.length > 0)) &&
        <>
          <InvoiceReceiptListTable
            hasActions
            headCells={tableHeadCells}
            isAsc={sort.order === 'asc'}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rowLink='/invoices-receipts'
            rows={formattedInvoices ? formattedInvoices : []}
            rowsPerPage={rowsPerPage}
            total={totalInvoices}
            isLoading={invoicesLoading}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <CancelInvoiceModal
            invoiceReceiptData={{
              id: actionInvoice?.id ? actionInvoice?.id : '',
            }}
            open={openCancel}
            onClose={onCloseCancel}
          />

          <DescriptionInvoicesReceiptsModal
            invoicesReceiptsData={{
              id: actionInvoice?.id ? actionInvoice.id : '',
            }}
            open={openDescription}
            onClose={onCloseDescription}
          />

          <ReopenInvoicesReceiptsModal
            invoicesReceiptsData={{
              id: actionInvoice?.id ? actionInvoice.id : '',
            }}
            open={openReopen}
            onClose={onCloseReopen}
          />

          <SendInvoicesReceiptsModal
            invoicesReceiptsData={{
              id: actionInvoice?.id ? actionInvoice?.id : ''
            }}
            open={openSend}
            onClose={onCloseSend}
          />
        
          <EmailInvoiceReceiptModal
            invoiceReceiptData={{
              id: actionInvoice?.id ? actionInvoice?.id : '',
              customer_id: actionInvoice?.customer_id ? actionInvoice?.customer_id : ''
            }}
            open={openEmail}
            onClose={onCloseEmail}
          />

        </>
      }
    </div>
  );
}

export { InvoicesReceipts }