import { Routes as Router, Route } from 'react-router-dom';

import { Login } from './pages/Login';
import { ForgotPassword } from 'pages/ForgotPassword';
import { ResetPassword } from 'pages/ResetPassword';
import { CreditCard } from 'pages/CreditCard';
import { Pix } from 'pages/Pix';
import { Checkout } from 'pages/Checkout';

import { AccountsReceivables } from './pages/AccountsReceivables';
import { Banks } from 'pages/Configurations/pages/CashierBanks';
import { CashierStatement } from 'pages/CashierStatement';
import { Clients } from './pages/Clients';
import { Company } from 'pages/Configurations/pages/Company';
import { ConfigurationPage } from 'pages/Configurations';
import { Home } from './pages/Home';
import { InvoicesReceipts } from './pages/InvoicesReceipts';
import { Products } from './pages/Products';
import { Sales } from './pages/Sales';
import { Page404 } from 'pages/404';
import { Tags } from 'pages/Configurations/pages/Tags';
import { Users } from 'pages/Configurations/pages/Users';
import { System } from 'pages/Configurations/pages/System';
import { Coupons } from 'pages/Configurations/pages/Coupons';
import { InvoiceGroups } from 'pages/InvoicesGroups';

import { AccountReceivableView } from './pages/AccountsReceivables/View';
import { ClientView } from 'pages/Clients/View';
import { ProductSpotView } from './pages/Products/pages/Spot/View';
import { ProductRecurringView } from 'pages/Products/pages/Recurring/View';
import { InvoiceReceiptView } from 'pages/InvoicesReceipts/View';
import { InvoiceGroupView } from 'pages/InvoicesGroups/View';
import { SaleView } from 'pages/Sales/pages/View';
import { CashierStatementView } from 'pages/CashierStatement/View';
import { Metrics } from 'pages/Metrics';

import { PrivateMenu } from './components/PrivateMenu';
import {PlanView} from "./pages/Products/pages/Recurring/View/pages/Plans/View";
import {Suppliers} from "./pages/Suppliers";
import {SupplierView} from "./pages/Suppliers/View";

export default function Routes() {
  return (
    <Router>
      <Route path='/login' element={<Login />} />
      <Route path='/password' element={<ForgotPassword />} />
      <Route path='/password/reset' element={<ResetPassword />} />
      <Route path='/credit-card/charge' element={<CreditCard />} />
      <Route path='/pix/charge' element={<Pix />} />
      <Route path='/checkout/:hash' element={<Checkout />} />

      <Route element={<PrivateMenu />} >
        <Route path='/' element={<Home />} />
        <Route path='/accounts-receivables' element={<AccountsReceivables />} />
        <Route path='/accounts-receivables/:id' element={<AccountReceivableView />} />
        <Route path='/cashier-statements' element={<CashierStatement />} />
        <Route path='/cashier-statements/:id' element={<CashierStatementView />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/clients/:id' element={<ClientView />} />
        <Route path='/invoices-groups' element={<InvoiceGroups />} />
        <Route path='/invoices-groups/:id' element={<InvoiceGroupView />} />
        <Route path='/invoices-receipts' element={<InvoicesReceipts />} />
        <Route path='/invoices-receipts/:id' element={<InvoiceReceiptView />} />
        <Route path='/products' element={<Products />} />
        <Route path='/products/spot/:id' element={<ProductSpotView />} />
        <Route path='/products/recurring/:id' element={<ProductRecurringView />} />
        <Route path='/products/recurring/:product/plans/:id' element={<PlanView />} />
        <Route path='/sales' element={<Sales />} />
        <Route path='/sales/:type/:id' element={<SaleView />} />
        <Route path='/suppliers' element={<Suppliers />} />
        <Route path='/suppliers/:id' element={<SupplierView />} />
        <Route path='/configurations' element={<ConfigurationPage />} />
        <Route path='/configurations/users' element={<Users />} />
        <Route path='/configurations/tags' element={<Tags />} />
        <Route path='/configurations/cashiers-and-bank-accounts' element={<Banks />} />
        <Route path='/configurations/company' element={<Company />} />
        <Route path='/configurations/system' element={<System />} />
        <Route path='/configurations/coupons' element={<Coupons />} />
        <Route path='/metrics' element={<Metrics />} />
        <Route path='*' element={<Page404 />} />
      </Route>

    </Router>
  );
}