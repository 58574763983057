import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';
import { SaleListTable } from 'components/Tables/SaleListTable';

import useViewModel from './viewModel';

type SalesRecurringViewPropTypes = {
  clientId: string
}

function SalesRecurringView({
  clientId,
}: SalesRecurringViewPropTypes) {
  const {
    optionsRowsPerPage,
    page,
    rowsPerPage,
    formattedSales,
    sort,
    tableHeadCells,
    totalSales,
    salesLoading,
    salesLoadingError,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel({ clientId });

  return (
    <div className='listing-tab-view'>

      {!salesLoading &&
        !salesLoadingError &&
        formattedSales?.length === 0 &&
        <EmptyView title={'Nenhuma assinatura encontrada para mostrar aqui'}></EmptyView>
      }

      {salesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as assinaturas. Tente recarregar a página.' />
      }
      {(salesLoading || (formattedSales !== undefined && formattedSales.length > 0)) &&
        <SaleListTable
          isAsc={sort.order === 'asc'}
          headCells={tableHeadCells}
          page={page}
          optionsRowsPerPage={optionsRowsPerPage}
          orderBy={sort.field}
          rows={formattedSales ? formattedSales : []}
          rowsPerPage={rowsPerPage}
          total={totalSales}
          isLoading={salesLoading}
          onPageChange={onPageChange}
          onRequestSort={onChangeSort}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      }

    </div>
  )
}

export { SalesRecurringView };