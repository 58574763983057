import { useState } from 'react';

export default function ProductsViewModel() {
  const [tabSelected, setTabSelected] = useState<number>(0);

  const [openCreateProduct, setOpenCreateProduct] = useState(false);
  const [createProductType, setCreateProductType] = useState<string>()

  const onTabChange = (value: number) => {
    setTabSelected(value);
  }

  const onCloseModal = () => {
    setOpenCreateProduct(false)
  }

  const onOpenModal = (id: string) => {
    setCreateProductType(id)
    setOpenCreateProduct(true)
  }

  return {
    createProductType,
    openCreateProduct,
    tabSelected,
    onCloseModal,
    onOpenModal,
    onTabChange
  };
}