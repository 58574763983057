import dayjs from 'dayjs';
import { CustomError, api } from '../../utils/axios';

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean
}

type ClientsType = {
  id: string,
  name: string,
  status: string,
  razao_social: string,
  document: string,
  emails: Array<string>,
  created_at: string,
  tags: Array<string>
}

type ClientsDisplayType = {
  id: string,
  name: string,
  status: string,
  razao_social: string,
  document: string,
  emails: string,
  created_at: string,
  tags: string,
  actions: ActionType[]
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}

type PaginationType = {
  limit: number,
  page: number
}

type PostTagPayloadType = {
  name: string,
  description: string
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type TagsType = {
  value: string,
  label: string
}

const parseClients = (response: { data: any, meta: any }) => {

  const clients = response.data.map((data: any) => {
    return {
      id: data.id,
      name: data.attributes.name,
      status: data.attributes.status,
      razao_social: data.attributes.razao_social ? data.attributes.razao_social : '',
      document: data.attributes.document,
      emails: data.attributes.contacts ? data.attributes.contacts : [],
      created_at: data.attributes.created_at.date,
      tags: data.attributes.tags ? data.attributes.tags : [],
    }
  });

  return {
    clients,
    pagination: {
      totalClients: response.meta.pagination.total
    }
  };
}

const parseTags = (response: Array<any>) => {
  return response.map((data) => ({
    value: data.id,
    label: data.attributes.name
  }))
}

const parsePostResponseTag = (response: { data: any }) => {
  return {
    value: response.data.id,
    label: response.data.attributes.name
  };
}

const getClients = async (filters: Array<FilterType>, search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    if (filter.value.length)
      params[`filter[${filter.field}]`] = `${filter.comparator}:${filter.value.join()}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/clients', {
      params,
      signal
    });

    return parseClients(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const getTags = async (search: string, limit: number, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  if (search)
    params.q = search;

  if (limit)
    params.limit = limit

  try {
    const response = await api.get('/api-cashone/tags', {
      params,
      signal
    });

    return parseTags(response.data.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const createTag = async (payload: PostTagPayloadType): Promise<any> => {
  try {
    const response = await api.post('/api-cashone/tags', payload);

    return parsePostResponseTag(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors;
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const editTags = async (clientsSelected: number[], clients: ClientsType[], addTags: string[], removeTags: string[]) => {
  const payload = {
    customers: [] as string[],
    add_tags: addTags,
    remove_tags: removeTags,
  }

  payload.customers = clientsSelected.map(value => clients[value].id);

  try {
    const response = await api.patch('/api-cashone/clients/tag/batch', payload);

    return response.data.data;

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatClientsToDisplay = (clients: Array<ClientsType>): Array<ClientsDisplayType> => {

  return clients.map((client: ClientsType) => {
    let actions: ActionType[] = [];

    switch (client.status) {
      case 'ACTIVE':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'activate', label: 'Ativar', disabled: true },
          { id: 'deactivate', label: 'Desativar' },
        ];
        break;

      case 'CANCELED':
        actions = [
          { id: 'edit', label: 'Editar' },
          { id: 'activate', label: 'Ativar' },
          { id: 'deactivate', label: 'Desativar', disabled: true },
        ];
        break;
    }

    return {
      id: client.id,
      name: client.name,
      status: client.status === 'ACTIVE' ? 'ATIVO' : 'INATIVO',
      razao_social: client.razao_social ? client.razao_social : '-',
      document: client.document,
      emails: client.emails ? client.emails.join(', ') : '',
      created_at: dayjs(client.created_at).format('DD/MM/YYYY'),
      tags: client.tags ? client.tags.join(', ') : '',
      actions
    }
  })

}

export { createTag, editTags, getClients, getTags, formatClientsToDisplay };

export type { ClientsType, ClientsDisplayType, FilterType, PaginationType, PostTagPayloadType, SortType, TagsType };