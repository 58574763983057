import { Typography } from '@mui/material'

import { DisplayInfoText } from 'components/Info/DisplayInfoText'
import { DisplayInfoLink } from 'components/Info/DisplayInfoLink'
import { Flexbox } from 'components/Flexbox'

import useViewModel from './viewModel'
import { DisplayInfoTextOpenNewTab } from 'components/Info/DisplayInfoTextOpenNewTab'

type InvoiceReceiptGeneralViewPropTypes = {
  receiptId: string
}

function InvoiceReceiptGeneralView({ receiptId }: InvoiceReceiptGeneralViewPropTypes) {
  const {
    formattedDetails,
    detailsLoading
  } = useViewModel({ receiptId })

  return (
    <div className='details'>

      <div className="basic-info">
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Informações básicas</Typography>

        <div className="info-row">
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Status NF'
              text={formattedDetails?.status ? formattedDetails.status : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoTextOpenNewTab
              isLoading={detailsLoading}
              title='Cliente'
              texts={formattedDetails?.customer.name ? [formattedDetails.customer.name] : []}
              links={[`/clients/${formattedDetails?.customer.id}`]}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Emitida em'
              text={formattedDetails?.processed_date ? formattedDetails.processed_date : ''}
            />
          </Flexbox>
        </div>

        <div className="info-row">
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Status Pagamento'
              text={formattedDetails?.payment_status ? formattedDetails.payment_status : ''}
            />
          </Flexbox>
          <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Valor'
              text={formattedDetails?.total_value ? formattedDetails.total_value : ''}
            />
          </Flexbox>
          {/* <Flexbox size={3}>
            <DisplayInfoText
              isLoading={detailsLoading}
              title='Cobrança associada'
              text={formattedDetails?.account_receivable ? formattedDetails.account_receivable : ''}
            />
          </Flexbox> */}
          <DisplayInfoTextOpenNewTab
            isLoading={detailsLoading}
            title='Cobrança associada'
            texts={formattedDetails?.account_receivable ? formattedDetails.account_receivable.split(',') : []}
            links={formattedDetails?.account_receivable_id ? formattedDetails?.account_receivable_id.map(id => (`/accounts-receivables/${id}`)) : []}
          />
        </div>
      </div>

      <div className="invoice-receipt-info">
        <Typography variant='h4' fontWeight={700} className='details-subtitle'>Nota Fiscal</Typography>

        <div className="info-row">
          <DisplayInfoLink
            isLoading={detailsLoading}
            title='Nota Fiscal em PDF'
            text={formattedDetails?.receipt_url ? formattedDetails?.receipt_url : 'N/A'}
            link={formattedDetails?.receipt_url ? formattedDetails.receipt_url : ''}
          />
        </div>
        <div className="info-row">
          <DisplayInfoLink
            isLoading={detailsLoading}
            title='Nota Fiscal na prefeitura'
            text={formattedDetails?.original_url ? formattedDetails?.original_url : 'N/A'}
            link={formattedDetails?.original_url ? formattedDetails.original_url : ''}
          />
        </div>
        <div className="info-row">
          <DisplayInfoText
            isLoading={detailsLoading}
            title='Observações da Nota Fiscal'
            text={formattedDetails?.description ? formattedDetails.description : ''}
          />
        </div>
      </div>
    </div>
  )
}

export { InvoiceReceiptGeneralView } 