import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { MultipleEmailFieldAdapter } from 'components/FormComponents/Fields/MultipleEmailFieldAdapter';

import useViewModel, { AccountsReceivablesType } from './viewModel'

import '../../styles.scss'

type CreateAccountReceivableModalPropTypes = {
  accountReceivableData: AccountsReceivablesType,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function SendEmailARModal({ accountReceivableData, open, onClose, onSubmit }: CreateAccountReceivableModalPropTypes) {
  const {
    accountReceivableError,
    clientLoading,
    accountReceivableLoading,
    formError,
    openSnackbar,
    client,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  } = useViewModel({ accountReceivableData, open, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            initialValues={{ emails: client?.emails }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Enviar emails
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>

                  <Flexbox size={12}>
                    <MultipleEmailFieldAdapter
                      label={'Emails'}
                      name='emails'
                      loading={clientLoading}
                    />
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  {accountReceivableLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' disabled={accountReceivableLoading} onClick={onClose}>Cancelar</Button>
                  <Button type='submit' disabled={accountReceivableLoading} variant='contained'>Enviar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(accountReceivableError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!accountReceivableError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Emails enviados!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { SendEmailARModal }