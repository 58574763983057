import { AxiosResponse } from 'axios';
import { SalePayloadType } from 'recoil/createSale/states';
import { CustomError, api } from 'utils/axios';

type ClientOption = {
  value: {
    customer_id: string,
    customer_name: string
  },
  label: string
}

type PlanOption = {
  value: {
    freq: string,
    initial_term: number,
    plan_name: string,
    payment_term_type: string,
    payment_term_value: number,
    plan_value: number,
    plan_id: string,
  },
  label: string,
}

type ProductOption = {
  value: {
    product_id: string,
    product_name: string,
    product_value: number
  },
  label: string
}

const parseProducts = (response: { data: Array<any> }) => {
  return response.data.map((data) => ({
    value: {
      product_id: data.id,
      product_name: data.attributes.name,
      product_value: data.attributes.value,
    },
    label: data.attributes.name
  }))
}

const getProducts = async (search: string, type: string, signal: AbortSignal): Promise<any> => {
  const params: any = {
    'filter[type]': `eq:${type}`
  };

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/products/search', {
      params,
      signal
    });

    return parseProducts(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const parsePlans = async (response: { data: any[] }) => {
  return response.data.map(data => ({
    value: {
      freq: data.attributes.freq,
      initial_term: data.attributes.initial_term,
      plan_name: data.attributes.name,
      payment_term_type: data.attributes.payment_term_type,
      payment_term_value: data.attributes.payment_term_value,
      plan_value: data.attributes.value,
      plan_id: data.id,
    },
    label: data.attributes.name,
  }))
}

const getPlans = async (productId: string, search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {};

  if (search)
    params.q = search;

  if (productId)
    params[`filter[product_id]`] = `in:${productId}`


  try {
    const response = await api.get('/api-cashone/plans/search', {
      params,
      signal
    });

    return parsePlans(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const parseClients = async (response: { data: any[] }) => {
  return response.data.map(data => ({
    value: {
      customer_id: data.id,
      customer_name: data.attributes.name
    },
    label: data.attributes.razao_social ? (data.attributes.name + ` (Razão social: ${data.attributes.razao_social})`) : data.attributes.name,
  }))
}

const getClients = async (search: string, signal: AbortSignal): Promise<any> => {
  const params: any = {
    'filter[status]': 'in:ACTIVE'
  };

  if (search)
    params.q = search;

  try {
    const response = await api.get('/api-cashone/clients/search', {
      params,
      signal
    });

    return parseClients(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const bundlePayloadToRecoil = (values: Record<string, any>, salePayload?: SalePayloadType): SalePayloadType => {
  let planData;

  if (values.plan_option)
    planData = {
      freq: values.plan_option.value.freq,
      initial_term: values.plan_option.value.initial_term,
      plan_name: values.plan_option.value.plan_name,
      payment_terms: {
        payment_term_type: values.plan_option.value.payment_term_type,
        payment_term_value: values.plan_option.value.payment_term_value,
        first_payment_term_type: salePayload?.payment_terms?.first_payment_term_type,
        first_payment_term_value: salePayload?.payment_terms?.first_payment_term_value,
      },
      plan_value: values.plan_option.value.plan_value,
      plan_id: values.plan_option.value.plan_id,
    }

  return {
    product_id: values.product_option.value.product_id,
    product_name: values.product_option.value.product_name,
    product_value: values.product_option.value.product_value,
    customer_id: values.client_option.value.customer_id,
    customer_name: values.client_option.value.customer_name,
    ...planData
  }
}

const formatSalePayloadToDisplay = (values: SalePayloadType) => {

  let response: any = {}

  if (values.product_id) {
    response.product_option = {
      label: values.product_name,
      value: {
        product_id: values.product_id,
        product_name: values.product_name,
        product_value: values.product_value
      }
    }
  }

  if (values.plan_id) {
    response.plan_option = {
      label: values.plan_name,
      value: {
        freq: values.freq,
        initial_term: values.initial_term,
        payment_term_type: values.payment_terms?.payment_term_type,
        payment_term_value: values.payment_terms?.payment_term_value,
        plan_id: values.plan_id,
        plan_name: values.plan_name,
        plan_value: values.plan_value
      }
    }
  }

  if (values.customer_id) {
    response.client_option = {
      label: values.customer_name,
      value: {
        customer_id: values.customer_id,
        customer_name: values.customer_name
      }
    }
  }

  return response
}

const formatNewClientToDisplay = (response: AxiosResponse | CustomError) => {
  if ((response as CustomError).errors)
    return null

  else
    return {
      value: {
        customer_id: (response as AxiosResponse).data.data.id,
        customer_name: (response as AxiosResponse).data.data.attributes.name
      },
      label: (response as AxiosResponse).data.data.attributes.name
    }
}

export {
  getClients,
  getPlans,
  getProducts,
  bundlePayloadToRecoil,
  formatSalePayloadToDisplay,
  formatNewClientToDisplay,
}

export type {
  ClientOption,
  PlanOption,
  ProductOption,
}