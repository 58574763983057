import { SyntheticEvent, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { resetPassword } from './services'

import { ApiError } from 'utils/CustomError'

export default function ForgotPasswordViewModel() {
  const { search } = useLocation()
  const token = (new URLSearchParams(search)).get('token')

  const navigate = useNavigate()

  const [passwordLoading, setPasswordLoading] = useState(false)
  const [passwordLoadingError, setPasswordLoadingError] = useState(false)
  const [tokenError, setTokenError] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onPostPasswordHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'UserException') {
      setTokenError(true);
    } else {
      setPasswordLoadingError(true)
    }
  }

  const onSubmit = async ({ password }: { password: string }) => {
    setPasswordLoading(true)
    const response = await resetPassword(password, token)

    if (response.errors)
      onPostPasswordHandleError(response.errors)

    setOpenSnackbar(true)
    setPasswordLoading(false)
  }

  const onCloseSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'timeout') {
      if (!passwordLoadingError && !tokenError)
        return navigate('/login')
    }
  }

  return {
    openSnackbar,
    passwordLoading,
    passwordLoadingError,
    tokenError,
    onCloseSnackbar,
    onSubmit,
  }
}