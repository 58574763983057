import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { getSales, formatSalesToDisplay, SaleType, SaleDisplayType, SortType } from './services';
import { ApiError } from 'utils/CustomError';

type ViewModelProps = {
  clientId: string
}

export default function SalesViewModel({ clientId }: ViewModelProps) {
  const [sales, setSales] = useState<Array<SaleType>>();
  const [formattedSales, setFormattedSales] = useState<Array<SaleDisplayType>>();

  const [salesLoading, setSalesLoading] = useState(true);
  const [salesLoadingError, setSalesLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalSales, setTotalSales] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'customer_name', label: 'Cliente' },
    { id: 'status', label: 'Status' },
    { id: 'product_name', label: 'Produto' },
    { id: 'period_start', label: 'Início' },
    { id: 'period_end', label: 'Término' },
    { id: 'tags', label: 'Tags' },
  ];

  const onGetSalesHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setSalesLoadingError(true)

      setSales([]);
      setFormattedSales([])
      setTotalSales(0);
    } else {
      setSalesLoadingError(true)

      setSales([]);
      setFormattedSales([])
      setTotalSales(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setSalesLoading(true);

      const response = await getSales(
        clientId,
        sort,
        { page, limit: rowsPerPage },
        abortController.signal
      );

      if (response.errors) {
        onGetSalesHandleError(response.errors)
      } else {
        setSalesLoadingError(false)
        setSales(response.sales as Array<SaleType>);
        setFormattedSales(formatSalesToDisplay(response.sales))

        if (response.pagination)
          setTotalSales(response.pagination.totalSales);
      }

      setSalesLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort();
    }

  }, [clientId, page, rowsPerPage, sort]);


  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sales,
    formattedSales,
    salesLoading,
    salesLoadingError,
    sort,
    tableHeadCells,
    totalSales,
    onChangeSort,
    onPageChange,
    onRowsPerPageChange,
  };
}