import { CustomError, api } from 'utils/axios';
import dayjs from 'dayjs';

import { formatMoney } from 'utils/moneyFormatt';

type DetailsType = {
  id: string,
  status: string,
  key: string,
  customer: {
    id: number,
    name: string
  },
  created_at: string,
  due_date: string,
  file_url: string,
  total_value: number,
  subtotal_value: number,
  discount_value: number,
  tax_value: number,
  payment_status?: string,
  receipt_status?: string,
  account_receivable: {
    id: number,
    key: string
  }[],
  product_name: string,
  plan_name: string,
  receipt_id?: number
}

type DetailsDisplayType = {
  id: string,
  status: string,
  key: string,
  customer: {
    id: number,
    name: string
  },
  created_at: string,
  due_date: string,
  file_url: string,
  total_value: string,
  subtotal_value: string,
  discount_value: string,
  tax_value: string,
  payment_status: string,
  receipt_status: string,
  account_receivable: {
    id: number,
    key: string
  }[],
  product_name: string,
  plan_name: string,
  receipt_id: string
}

const parseDetails = (response: { data: any }) => {
  return {
    id: response.data.id,
    status: response.data.attributes.status,
    key: response.data.attributes.key,
    customer: {
      id: response.data.attributes.customer_id,
      name: response.data.attributes.customer_name,
    },
    created_at: response.data.attributes.created_at.date,
    due_date: response.data.attributes.due_date.date,
    file_url: response.data.attributes.path_to_file,
    total_value: response.data.attributes.total,
    subtotal_value: response.data.attributes.invoice_item_subtotal,
    discount_value: response.data.attributes.invoice_item_discount_value,
    tax_value: response.data.attributes.invoice_item_tax_value,
    payment_status: response.data.attributes.payment_status,
    receipt_status: response.data.attributes.receipt_status,
    account_receivable: response.data.attributes.account_receivables.map((accountReceivable: { id: number, key: string }) => {
      return { id: accountReceivable.id, key: accountReceivable.key}
    }),
    product_name: response.data.attributes.product_name,
    plan_name: response.data.attributes.plan_name,
    receipt_id: response.data.attributes.receipt_id
  }
}

const getDetails = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/invoice-groups/${id}`, { signal })

    return parseDetails(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatDetailsToDisplay = (details: DetailsType): DetailsDisplayType => {
  const translateStatus = {
    'DRAFT': 'RASCUNHO',
    'POST': 'APROVADO',
    'CANCELED': 'CANCELADO',
    'ERROR': 'ERRO',
    'DISCARDED': 'RASCUNHODESCARTADO',
    'POSTING': 'APROVANDO RASCUNHO'
  }

  const translatePaymentStatus = {
    'CANCELED': 'Cancelado',
    'EXPIRED': 'Expirado',
    'FAILED': 'Falhou',
    'PAID': 'Pago',
    'PENDING': 'Pendente',
  }

  const translateReceiptStatus = {
    'DRAFT': 'Rascunho',
    'AUTHORIZED': 'Autorizado',
    'CANCELLED': 'Cancelado',
    'CANCELLING': 'Cancelando',
    'ERROR': 'Erro',
    'PENDING': 'Pendente',
    'PROCESSING': 'Processando'
  }

  type StatusEnum = keyof typeof translateStatus;
  type PaymentStatusEnum = keyof typeof translatePaymentStatus
  type ReceiptStatusEnum = keyof typeof translateReceiptStatus

  return {
    id: details.id,
    status: translateStatus[details.status as StatusEnum],
    key: details.key,
    customer: {
      id: details.customer.id,
      name: details.customer.name
    },
    created_at: details.created_at ? dayjs(details.created_at).format('DD/MM/YYYY') : 'N/A',
    due_date: details.due_date ? dayjs(details.due_date).format('DD/MM/YYYY') : 'N/A',
    file_url: details.file_url,
    total_value: formatMoney(details.total_value),
    subtotal_value: formatMoney(details.subtotal_value),
    discount_value: formatMoney(details.discount_value),
    tax_value: formatMoney(details.tax_value),
    payment_status: details?.payment_status ? translatePaymentStatus[details.payment_status as PaymentStatusEnum] : 'N/A',
    receipt_status: details?.receipt_status ? translateReceiptStatus[details.receipt_status as ReceiptStatusEnum] : 'N/A',
    account_receivable: details.account_receivable.map(ar => ({id: ar.id, key: ar.key})),
    product_name: details.product_name,
    plan_name: details.plan_name,
    receipt_id: details?.receipt_id ? details.receipt_id?.toString() : ''
  }
}

export { formatDetailsToDisplay, getDetails }

export type { DetailsType, DetailsDisplayType }