import {useCreateCheckoutOrderActions} from "../../../../recoil/checkout/actions";
import {useEffect, useState} from "react";
import {formatOrderToDisplay, getOrder, OrderType, OrderTypeToDisplay} from "./services";
import {ApiCheckoutError} from "../../../../utils/CustomError";

type viewModelPropTypes = {
    onBack: () => void
}

export default function CustomerStepViewModel({onBack} : viewModelPropTypes) {

    const { checkoutOrderPayload} = useCreateCheckoutOrderActions()

    const [order, setOrder] = useState<OrderType>()
    const [formattedOrder, setFormattedOrder] = useState<OrderTypeToDisplay>()

    const [loadingProgress, setLoadingProgress] = useState<number>(0)
    const [orderLoading, setOrderLoading] = useState(true)
    const [orderLoadingError, setOrderLoadingError] = useState(false)

    const [timer, setTimer] = useState<any>()

    const onGetOrderHandleError = (errors: ApiCheckoutError) => {

        if (errors[0].status === 400 && errors[0].name === 'CanceledError') {

        } else {
            setOrderLoadingError(true)
        }
    }

    useEffect(() => {
        setInterval(() => {
            setLoadingProgress((loadingProgress + 25 >= 100) ? 90 : loadingProgress + 25)
        }, 4000)
    }, [loadingProgress]);

    useEffect(() => {
        if (order && order?.status !== 'PROCESSING' && order?.status !== 'RECEIVED') {
            clearInterval(timer)
            setOrderLoading(false)
        }
    }, [order, timer]);

    useEffect(() => {
        const abortController = new AbortController();

        const interval = setInterval(async () => {

            if (!order || (order?.status === 'PROCESSING' || order?.status === 'RECEIVED')) {

                if (checkoutOrderPayload.sale?.hash) {
                    setOrder(undefined)
                    setOrderLoading(true);
                    const response = await getOrder(checkoutOrderPayload.sale.hash, abortController.signal);

                    if (response.errors) {
                        onGetOrderHandleError(response.errors)
                    } else {
                        setOrder(response)
                        setFormattedOrder(formatOrderToDisplay(response))
                    }
                }
            }

        }, 5000);

        setTimer(interval)

        return () => {
            clearInterval(interval);
            abortController.abort()
        }
    }, [checkoutOrderPayload, order]);

    return {
        order,
        loadingProgress,
        orderLoading,
        orderLoadingError,
        formattedOrder
    }
}