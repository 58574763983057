import { ClickAwayListener, Skeleton, Tooltip, Typography } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useViewModel from './viewModel'

import './styles.scss'

type DisplayInfoLinkPropTypes = {
  title: string,
  text?: string,
  link: string,
  isLoading?: boolean
}

function DisplayInfoLink({ title, text, link, isLoading = false }: DisplayInfoLinkPropTypes) {
  const { isTooltipOpen, handleClose, handleOpen } = useViewModel({ link })

  return (
    <div className='display-info-link'>
      <div className='title'>
        <Typography variant='caption'>
          {title}
        </Typography>
      </div>
      <div className='link-container'>
        {isLoading ? (
          <Skeleton className='skeleton-loading' />
        ) : <>
            {link && <div className='link'>
              <a href={link} target='_blank' rel='noreferrer'>
                <Typography fontWeight={700} component='span'>
                  {text ? text : link}
                </Typography>
              </a>
            </div>
            }

            {!link && <div className='text'>
                <Typography fontWeight={700} component='span'>
                  {text ? text : link}
                </Typography>
              </div>
            }
          {link ? (

            <ClickAwayListener
              onClickAway={handleClose}
            >
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleClose}
                open={isTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClick={handleOpen}
                title='Link copiado!'
                placement='top'
              >
                <ContentCopyIcon className='copy-link-icon' />
              </Tooltip>
            </ClickAwayListener>
          ) : <></>
          }
        </>}
      </div>
    </div>
  );
}

export { DisplayInfoLink }