import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ValidationErrors } from 'final-form'

import validator from 'card-validator';

import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth';

import { getDetails, formatDetailsToDisplay, postCreditCard, DetailsType, DetailsDisplayType } from './services'

export default function ForgotPasswordViewModel() {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const hash = searchParams.get('hash') || '';
  const accountReceivableKey = searchParams.get('key') || '';

  const [hashError, setHashError] = useState(false)

  const [details, setDetails] = useState<DetailsType>();
  const [formattedDetails, setFormattedDetails] = useState<DetailsDisplayType>();
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [detailsLoadingError, setDetailsLoadingError] = useState(false);

  const [creditCardLoading, setCreditCardLoading] = useState(false)
  const [creditCardLoadingError, setCreditCardLoadingError] = useState(false)
  const [finished, setFinished] = useState(false)

  const [brand, setBrand] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const monthOptions = [...Array(12)].map((value, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))

  const actualYear = (new Date()).getFullYear()
  const yearOptions = [...Array(20)].map((value, index) => ({ label: `${index + actualYear}`, value: `${index + actualYear}` }))

  useEffect(() => {
    if (!hash && !accountReceivableKey) {
      return;
    }

    const abortController = new AbortController();

    (async () => {
      setDetailsLoading(true);

      const response = await getDetails(accountReceivableKey, hash, abortController.signal);

      if (response.errors) {
        onGetDetailsHandleError(response.errors)

      } else {
        setDetailsLoadingError(false)
        setDetails(response);
        setFormattedDetails(formatDetailsToDisplay(response))
      }

      setDetailsLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort()
    }
  }, [hash, accountReceivableKey])

  const onGetDetailsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setDetailsLoadingError(true)

      setDetails(undefined);
      setFormattedDetails(undefined)
    } else {
      setDetailsLoadingError(true)

      setDetails(undefined);
      setFormattedDetails(undefined)
    }
  }

  const onPostCreditCardHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'UserException') {
      setHashError(true);
    } else {
      setCreditCardLoadingError(true)
      setOpenSnackbar(true)
    }
  }

  const onSubmit = async (values: Record<string, any>) => {
    setCreditCardLoading(true)
    const response = await postCreditCard(accountReceivableKey, hash, values)

    if (response.errors)
      onPostCreditCardHandleError(response.errors)
    else {
      setCreditCardLoadingError(false)
      setFinished(true)
    }

    setOpenSnackbar(true)
    setCreditCardLoading(false)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const onDigitsChange = (digits: string) => {
    const type = validator.number(digits.replaceAll(' ', '')).card?.type;
    setBrand(type ? type : '');
  }

  const formValidate = (values: Record<string, any>) => {
    const errors: ValidationErrors = {}

    if (!values.digits)
      errors.digits = 'Obrigatório'
    else {
      const result = validator.number(values.digits.replaceAll(' ', ''))
      if (!result.isValid)
        errors.digits = 'Número inválido'
    }

    if (!values.holder_name)
      errors.holder_name = 'Obrigatório'

    if (!values.expiration_month)
      errors.expiration_month = 'Obrigatório'

    if (!values.expiration_year)
      errors.expiration_year = 'Obrigatório'

    if (!values.security_code)
      errors.security_code = 'Obrigatório'
    else {
      if (values.security_code.length < 3)
        errors.security_code = 'Código inválido'

      if (values.security_code.length > 4)
        errors.security_code = 'Código inválido'
    }

    return errors;
  }

  return {
    hash,
    accountReceivableKey,
    details,
    detailsLoading,
    formattedDetails,
    detailsLoadingError,
    brand,
    creditCardLoading,
    creditCardLoadingError,
    finished,
    hashError,
    monthOptions,
    openSnackbar,
    yearOptions,
    formValidate,
    onCloseSnackbar,
    onDigitsChange,
    onSubmit,
  }
}