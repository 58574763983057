import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { ApiErrorView } from 'components/ApiErrorView';
import { AutocompleteAdapter } from 'components/FormComponents/Fields/AutocompleteAdapter';
import { CepFieldAdapter } from 'components/FormComponents/Fields/CepFieldAdapter';
import { CnpjFieldAdapter } from 'components/FormComponents/Fields/CnpjFieldAdapter';
import { CheckboxFieldAdapter } from 'components/FormComponents/Fields/CheckboxFieldAdapter'
import { CpfFieldAdapter } from 'components/FormComponents/Fields/CpfFieldAdapter';
import { Flexbox } from 'components/Flexbox'
import { MultipleEmailFieldAdapter } from 'components/FormComponents/Fields/MultipleEmailFieldAdapter';
import { MultipleTelephoneFieldAdapter } from 'components/FormComponents/Fields/MultipleTelephoneFieldAdapter';
import { MultipleTagFieldAdapter } from 'components/FormComponents/Fields/MultipleTagFieldAdapter';
import { MultipleDetailAttachmentUpload } from 'components/MultipleDetailAttachmentUpload';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter'

import useViewModel from './viewModel'

import '../../styles.scss'
import { MultipleTextFieldAdapter } from 'components/FormComponents/Fields/MultipleTextFieldAdapter';

type EditClientModalPropTypes = {
  open: boolean,
  clientId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function EditClientModal({ open, clientId, onClose, onSubmit }: EditClientModalPropTypes) {
  const {
    citiesError,
    cityOptions,
    clientLoading,
    clientLoadingError,
    editError,
    initialDetails,
    formattedClient,
    formError,
    formattedTags,
    openSnackbar,
    searchCityValue,
    searchLoading,
    states,
    tagsError,
    tagsLoading,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    onSearchCityChange,
    onStateChange,
  } = useViewModel({ clientId, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='edit-client-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            initialValues={formattedClient}
            validate={formValidate}
            render={({ values, handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Editar Cliente - {formattedClient?.regime_type === 'brlPeopleRegime' ? 'Pessoa Física' : (formattedClient?.regime_type === 'brlCompanyRegime' ? 'Pessoa Jurídica' : 'Estrangeiro')}
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                {!clientLoadingError &&
                  <div className='modal-body'>
                    <Typography variant='h3' fontWeight={700} className='section-title'>Informações básicas</Typography>

                    <Flexbox size={12}>
                      <Flexbox size={8}>
                        <TextFieldAdapter
                          loading={clientLoading}
                          label={'Nome'}
                          name='name'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        {
                          formattedClient?.regime_type === 'brlPeopleRegime' && <CpfFieldAdapter
                            loading={clientLoading}
                            name='document'
                            label='CPF'
                          />
                        }

                        {
                          formattedClient?.regime_type === 'brlCompanyRegime' && <CnpjFieldAdapter
                            loading={clientLoading}
                            name='document'
                            label='CNPJ'
                          />
                        }

                        {
                          formattedClient?.regime_type === 'brlInternationalCustomerRegime' && <TextFieldAdapter
                            loading={clientLoading}
                            label='Documento'
                            name='document'

                          />
                        }
                      </Flexbox>
                    </Flexbox>

                    {formattedClient?.regime_type === 'brlCompanyRegime' &&
                      <>
                        <Flexbox size={12}>
                          <Flexbox size={8}>
                            <TextFieldAdapter
                              loading={clientLoading}
                              label={'Razão Social'}
                              name='regime.razao_social'
                            />
                          </Flexbox>
                          <Flexbox size={4}>
                            <TextFieldAdapter
                              loading={clientLoading}
                              label={'Inscrição Municipal'}
                              name='regime.inscricao_municipal'
                            />
                          </Flexbox>
                        </Flexbox>

                        <div className='checkbox-container'>
                          <CheckboxFieldAdapter
                            label=''
                            name='regime.optante_simples_nacional'
                            loading={clientLoading}
                            options={[{ label: 'Optante pelo simples nacional', value: '1' }]}
                          />
                        </div>
                      </>
                    }

                    <Flexbox size={12}>
                      <MultipleEmailFieldAdapter
                        name='emails'
                        label={'Emails'}
                        loading={clientLoading}
                      />
                    </Flexbox>

                    <Flexbox size={12}>
                      {
                        formattedClient?.regime_type === 'brlInternationalCustomerRegime' &&
                        <MultipleTextFieldAdapter
                          loading={clientLoading}
                          label='Telefones'
                          name='phones'
                        />
                      }

                      {
                        formattedClient?.regime_type !== 'brlInternationalCustomerRegime' && <MultipleTelephoneFieldAdapter
                          loading={clientLoading}
                          name='phones'
                          label={'Telefones'}
                        />
                      }
                    </Flexbox>


                    <Typography variant='h3' fontWeight={700} className='section-title'>Endereço</Typography>

                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        {
                          formattedClient?.regime_type !== 'brlInternationalCustomerRegime' && <CepFieldAdapter
                            loading={clientLoading}
                            label={'CEP'}
                            name='address.postal_code'
                          />
                        }

                        {
                          formattedClient?.regime_type === 'brlInternationalCustomerRegime' && <TextFieldAdapter
                            loading={clientLoading}
                            label={'CEP'}
                            name='address.postal_code'
                          />
                        }
                      </Flexbox>
                      <Flexbox size={8}>
                        <TextFieldAdapter
                          loading={clientLoading}
                          label={'Logradouro'}
                          name='address.street'
                        />
                      </Flexbox>
                    </Flexbox>

                    <Flexbox size={12}>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={clientLoading}
                          label={'Número'}
                          name='address.number'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={clientLoading}
                          label={'Bairro'}
                          name='address.neighbourhood'

                        />
                      </Flexbox>
                      <Flexbox size={4}>
                        <TextFieldAdapter
                          loading={clientLoading}
                          label={'Complemento'}
                          name='address.complement'

                        />
                      </Flexbox>
                    </Flexbox>

                    {formattedClient?.regime_type === 'brlInternationalCustomerRegime' ? (
                      <Flexbox size={12}>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            loading={clientLoading}
                            label={'País'}
                            name='address.country'

                          />
                        </Flexbox>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            loading={clientLoading}
                            label={'Estado'}
                            name='address.state_province'

                          />
                        </Flexbox>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            loading={clientLoading}
                            label={'Cidade'}
                            name='address.city'

                          />
                        </Flexbox>
                      </Flexbox>
                    ) : (
                      <Flexbox size={12}>
                        <Flexbox size={4}>
                          <SelectFieldAdapter
                            label={'Estado'}
                            name='address.state_abbreviation'
                            options={states}
                            loading={clientLoading}
                            onChange={(e) => {
                              onStateChange(e, form)
                            }}
                          />
                        </Flexbox>
                        <Flexbox size={4}>
                          <AutocompleteAdapter
                            label={'Cidade'}
                            name='address.city'
                            disabled={clientLoading || !values.address?.state_abbreviation}
                            options={cityOptions}
                            value={searchCityValue}
                            searchLoading={searchLoading}
                            loading={clientLoading}
                            onChange={onSearchCityChange}
                          />
                        </Flexbox>
                      </Flexbox>
                    )}


                    <Typography variant='h3' fontWeight={700} className='section-title'>Informações adicionais</Typography>

                    <Flexbox size={12}>
                      <MultipleTagFieldAdapter
                        label={'Tags'}
                        name='tags'
                        loading={clientLoading || tagsLoading}
                        tags={formattedTags}
                      />
                    </Flexbox>

                    <Flexbox size={12}>
                      <TextFieldAdapter
                        loading={clientLoading}
                        label={'Observações adicionais'}
                        name='description'
                        multiline
                        rows={3}
                      />
                    </Flexbox>

                    <Typography variant='h3' fontWeight={700} className='section-title'>Anexos</Typography>

                    <Flexbox size={12}>
                      <MultipleDetailAttachmentUpload
                        label={'Anexos'}
                        name={'details'}
                        initialDetails={initialDetails}
                        loading={clientLoading}
                      />
                    </Flexbox>
                  </div>
                }

                {clientLoadingError &&
                  <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as informações do cliente.' />
                }


                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={clientLoadingError || clientLoading}>Salvar</Button>
                </div>
              </form>

            )}
          />
        </Box>
      </Modal>

      {(editError || citiesError || tagsError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="error" variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="warning" variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!editError && !citiesError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity="success" variant='filled'>
            Cliente atualizado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { EditClientModal }