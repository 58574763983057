import { useState } from 'react'

import { CashierBankPayload, CashierData, formatCashierBanksToDisplay, patchCashierBanks } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  cashierData: CashierData,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function EditCashierBankModalViewModel({ cashierData, onClose, onSubmit }: ViewModelPropTypes) {

  const [editLoading, setEditLoading] = useState(false)
  const [editError, setEditError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const initialValues = formatCashierBanksToDisplay(cashierData)


  const onPatchCashierBankHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setEditLoading(true)
    const response = await patchCashierBanks(values as CashierBankPayload)

    if (response.errors)
      onPatchCashierBankHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setEditLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    const codeRegex = /^\d+$/

    if (!values.type) {
      errors.type = 'Obrigatório'

    } else if (values.type === 'CASHIER_CASH') {
      if (!values.name) {
        errors.name = 'Obrigatório'
      }

    } else if (values.type === 'CASHIER_BANK_ACCOUNT') {
      if (!values.bank_code)
        errors.bank_code = 'Obrigatório'

      if (!values.bank_name)
        errors.bank_name = 'Obrigatório'

      if (!values.routing_number)
        errors.routing_number = 'Obrigatório'
      else if (!codeRegex.test(values.routing_number))
        errors.routing_number = 'Apenas números'

      if (values.routing_number_digit_verifier && !codeRegex.test(values.routing_number_digit_verifier))
        errors.routing_number_digit_verifier = 'Apenas números'

      if (!values.account_number)
        errors.account_number = 'Obrigatório'
      else if (!codeRegex.test(values.account_number))
        errors.account_number = 'Apenas números'

      if (values.account_number_digit_verifier && !codeRegex.test(values.account_number_digit_verifier))
        errors.account_number_digit_verifier = 'Apenas números'
    }

    return errors
  }

  return {
    editLoading,
    editError,
    formError,
    initialValues,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  }
}