import dayjs from 'dayjs';
import React from 'react';

import { Button, DialogActions, Popover, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers'

import useViewModel from './viewModel';

import './styles.scss'

type DateRangeFilterPropTypes = {
  buttonLabel: string,
  id: string,
  initialStartDate?: string,
  initialEndDate?: string,
  onlyMonth?: boolean,
  minDate?: string,
  maxDate?: string
  onApplyFilter: (startDateSelected: dayjs.Dayjs | null, endDateSelected: dayjs.Dayjs | null) => void
}

function DateRangeFilter({
  buttonLabel,
  id,
  initialEndDate,
  initialStartDate,
  onlyMonth,
  minDate,
  maxDate,
  onApplyFilter
}: DateRangeFilterPropTypes) {
  const {
    endDateSelected,
    isOpen,
    startDateSelected,
    minDateSelected,
    maxDateSelected,
    onClick,
    onEndDateChange,
    onStartDateChange,
  } = useViewModel({ id, initialStartDate, initialEndDate, minDate, maxDate });
  const buttonRef = React.useRef(null);

  return (
    <div className='filter date-range-filter'>
      <Button variant='outlined' color='secondary' onClick={onClick} size='small' ref={buttonRef}>
        <Typography variant='caption' fontWeight={700}>
          {buttonLabel}
        </Typography>
      </Button>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={onClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='date-range-filter-modal'>

          <div className='date-pickers-container'>
            <DesktopDatePicker
              label={'Posterior a'}
              value={startDateSelected}
              format={onlyMonth ? 'MM/YY' : 'DD/MM/YYYY'}
              views={onlyMonth ? ['year', 'month'] : ['day', 'month', 'year']}
              slots={{
                actionBar: ({ className, onClear }) => {
                  return (
                    <DialogActions className={className}>
                      <Button onClick={onClear}> Limpar </Button>
                    </DialogActions>
                  );
                }
              }}
              onChange={(value) => { onStartDateChange(value || null) }}
              slotProps={{
                actionBar: {
                  actions: ['clear']
                },
                textField: {
                  required: false,
                  error: false,
                  size: 'small'
                }
              }}
              minDate={minDateSelected}
              maxDate={maxDateSelected}
            />

            <span className='separator'>-</span>

            <DesktopDatePicker
              label={'Anterior a'}
              value={endDateSelected}
              format={onlyMonth ? 'MM/YY' : 'DD/MM/YYYY'}
              views={onlyMonth ? ['year', 'month'] : ['day', 'month', 'year']}
              slots={{
                actionBar: ({ className, onClear }) => {
                  return (
                    <DialogActions className={className}>
                      <Button onClick={onClear}> Limpar </Button>
                    </DialogActions>
                  );
                }
              }}
              onChange={(value) => { onEndDateChange(value || null) }}
              slotProps={{
                textField: {
                  required: false,
                  error: false,
                  size: 'small'
                }
              }}
              minDate={minDateSelected}
              maxDate={maxDateSelected}
            />
          </div>

          <hr />

          <div className='buttons-container'>

            <Button
              variant='contained'
              size='small'
              className='font-caption'
              onClick={() => { onApplyFilter(startDateSelected, endDateSelected) }}
            >
              <Typography variant='caption' fontWeight={700}>
                Aplicar Filtro
              </Typography>
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export { DateRangeFilter };