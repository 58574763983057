import {api, CustomError} from 'utils/axios';

const activateSupplier = async (id: string): Promise<any> => {
  try {
    return await api.post(`/api-cashone/suppliers/${id}/activate`)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { activateSupplier }