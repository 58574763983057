import { Alert, Box, Button, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { SelectFieldAdapter } from 'components/FormComponents/Fields/SelectFieldAdapter';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss'
import { CurrencyFieldAdapter } from 'components/FormComponents/Fields/CurrencyFieldAdapter';

type CreatePlanModalPropTypes = {
  productId: string,
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function CreatePlanModal({ productId, open, onClose, onSubmit }: CreatePlanModalPropTypes) {
  const {
    createLoading,
    createError,
    formError,
    freqOptions,
    paymentTermTypeOptions,
    paymentTermValueOptions,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
  } = useViewModel({ productId, onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='create-plan-modal custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            render={({ values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Criar plano
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  <Flexbox size={12}>
                    <TextFieldAdapter
                      name='name'
                      label='Nome'
                      disabled={createLoading}
                    />
                  </Flexbox>
                  <Flexbox size={12}>
                    <Flexbox size={6}>
                      <SelectFieldAdapter
                        name='freq'
                        label='Frequência de cobrança'
                        options={freqOptions}
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={6}>
                      <TextFieldAdapter
                        name='initial_term'
                        label='Termo de contrato'
                        disabled={createLoading}
                        type='number'
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                    </Flexbox>
                  </Flexbox>
                  <Flexbox size={12}>
                    <Flexbox size={4}>
                      <SelectFieldAdapter
                        name='payment_term_type'
                        label='Prazo de pagamento'
                        options={paymentTermTypeOptions}
                        disabled={createLoading}
                      />
                    </Flexbox>
                    <Flexbox size={4}>
                      {values.payment_term_type === 'NET' ? (
                        <TextFieldAdapter
                          name='payment_term_value'
                          label='Data'
                          disabled={createLoading}
                          type='number'
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                      ) : (
                        <SelectFieldAdapter
                          name='payment_term_value'
                          label='Data'
                          options={paymentTermValueOptions}
                          disabled={createLoading}
                        />
                      )}
                    </Flexbox>
                    <Flexbox size={4}>
                      <CurrencyFieldAdapter
                        name='value'
                        label='Valor'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  </Flexbox>
                </div>

                <div className='modal-footer'>
                  <Button variant='outlined' onClick={onClose} disabled={createLoading}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={createLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(createError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!createError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Plano criado!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { CreatePlanModal }