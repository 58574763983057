import dayjs from 'dayjs';
import { CustomError, api } from 'utils/axios';

type EventType = {
  id: string,
  action: string,
  sent: string | null,
  response: string | null,
  created_at: string
}

type EventDisplayType = {
  id: string,
  title: string,
  sent: string | null,
  response: string | null,
  created_at: string
}


const parseEvents = (response: { log_list: any }) => {
  return response.log_list.map((data: any) => ({
    id: data.focus_nota_fiscal_id,
    action: data.action,
    sent: data.sent,
    response: data.response,
    created_at: data.created_at.date,
  }))
}

const getEvents = async (id: string, signal: AbortSignal): Promise<any> => {

  try {
    const response = await api.get(`/api-cashone/invoices-receipts/${id}/events`, {
      signal
    })

    return parseEvents(response.data.data.attributes)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatEventsToDisplay = (events: EventType[]): EventDisplayType[] => {

  const translateTitleSent = {
    'CANCEL': 'Dados enviados para cancelamento da nota fiscal',
    'SEND': 'Dados enviados para geração da nota fiscal',
    'VIEW': 'Consulta de nota fiscal',
    'WEBHOOK': 'Atualização realizada pela prefeitura',
  }

  const translateTitleReceived = {
    'CANCEL': 'Dados recebidos do cancelamento da nota fiscal',
    'SEND': 'Dados recebidos da geração da nota fiscal',
    'VIEW': 'Consulta de nota fiscal',
    'WEBHOOK': 'Atualização realizada pela prefeitura',
  }

  type actionEnum = keyof typeof translateTitleReceived;

  return events.map(event => {

    if (event.response)
      return {
        id: event.id,
        title: translateTitleReceived[event.action as actionEnum],
        sent: event.action === 'WEBHOOK' ? null : event.sent,
        response: event.response,
        created_at: dayjs(event.created_at).format('DD/MM/YYYY HH:mm:ss'),
      }

    else
      return {
        id: event.id,
        title: translateTitleSent[event.action as actionEnum],
        sent: event.action === 'WEBHOOK' ? null : event.sent,
        response: event.response,
        created_at: dayjs(event.created_at).format('DD/MM/YYYY HH:mm:ss'),
      }

  })

}

export { formatEventsToDisplay, getEvents }

export type { EventType, EventDisplayType }