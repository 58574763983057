import { atom } from 'recoil';

const titleNameAtom = atom({
  key: 'titleName',
  default: ''
})

const loadingTitleNameAtom = atom({
  key: 'loadingTitleName',
  default: false
})

export { loadingTitleNameAtom, titleNameAtom }