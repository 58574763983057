import { auth } from '../../utils/auth';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'

import {
  getActiveCustomers,
  ActiveCustomerType,
  ActiveCustomerDisplayType,
  formatActiveCustomersToDisplay
} from './services';

import { notAuthenticatedRedirect } from '../../utils/auth';

import { ApiError } from 'utils/CustomError';
// import { FilterType } from 'pages/Home/services';

export default function HomeViewModel() {

  const name = auth.user?.name;


  const [activeCustomer, setActiveCustomer] = useState<Array<ActiveCustomerType>>();
  const [formattedActiveCustomer, setFormattedActiveCustomer] = useState<ActiveCustomerDisplayType | null>();

  const [activeCustomerLoading, setActiveCustomerLoading] = useState(true);
  const [activeCustomerLoadingError, setActiveCustomerLoadingError] = useState(false);

  // // filter
  // const [effectiveDateFilter, setEffectiveDateFilter] = useState<FilterType[]>([{
  //   field: 'period',
  //   comparator: 'gte',
  //   value: dayjs().subtract(90, 'days').date(1).format('YYYY-MM-DD')
  // }, {
  //   field: 'period',
  //   comparator: 'lte',
  //   value: dayjs().endOf('month').format('YYYY-MM-DD')
  // }]);

  const onGetActiveCustomerHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setActiveCustomerLoadingError(true)

      setActiveCustomer([]);
      setFormattedActiveCustomer(null)
    } else {
      setActiveCustomerLoadingError(true)

      setActiveCustomer([]);
      setFormattedActiveCustomer(null)
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {

      const effectiveDateFilter = [{
        field: 'period',
        comparator: 'gte',
        value: dayjs().subtract(90, 'days').date(1).format('YYYY-MM-DD')
      }, {
        field: 'period',
        comparator: 'lte',
        value: dayjs().endOf('month').format('YYYY-MM-DD')
      }]

      setActiveCustomerLoading(true);
      setActiveCustomerLoadingError(false)

      const response = await getActiveCustomers([effectiveDateFilter[0], effectiveDateFilter[1]], abortController.signal);

      if (response.errors) {
        onGetActiveCustomerHandleError(response.errors)
      } else {
        setActiveCustomerLoadingError(false)
        setActiveCustomer(response as Array<ActiveCustomerType>);
        setFormattedActiveCustomer(formatActiveCustomersToDisplay(response))
      }

      setActiveCustomerLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [])

  return {
    name,
    activeCustomer,
    formattedActiveCustomer,
    activeCustomerLoading,
    activeCustomerLoadingError
  };
}