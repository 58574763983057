import {Paper, Skeleton, Step, StepLabel, Stepper} from '@mui/material';

import useViewModel from './viewModel';

import './styles.scss';
import {ProductStep} from "./pages/ProductStep";
import {CustomerStep} from "./pages/CustomerStep";
import {ReviewStep} from "./pages/ReviewStep";
import {FinalStep} from "./pages/FinalStep";
import {PaymentMethodStep} from "./pages/PaymentMethodStep";

function Checkout() {
    const {
        params,
        queryParams,
        page,
        formattedCheckoutCompany,
        onBack,
        onNext,
    } = useViewModel();

    return <>
        <div className='checkout-page'>
            <div className='header'>
                {formattedCheckoutCompany?.company_logo && <>
                    <span className='logo'>
                        {formattedCheckoutCompany.company_logo &&
                            <img className='image' alt={formattedCheckoutCompany.name} src={formattedCheckoutCompany.company_logo}/>}
                    </span>
                    <span className='divisor'></span>
                    <span className='logo-description'>
                        <div>{formattedCheckoutCompany.name}</div>
                        <div>{formattedCheckoutCompany.registration}</div>
                    </span>
                </>}
                {!formattedCheckoutCompany?.company_logo && <>
                    <span className='loading'><Skeleton width={250} /></span>
                </>}
            </div>
            <div className='sub-header'>
                {!formattedCheckoutCompany?.company_logo && <Skeleton />}
                {formattedCheckoutCompany?.company_logo && <>Dúvidas? Entre em contato pelo email {formattedCheckoutCompany?.email}</>}
            </div>


            <Paper elevation={3}>
                <div className='checkout-body'>

                    <div className='steps'>
                        <Stepper activeStep={page} alternativeLabel>
                            {['Carrinho de compras', 'Dados para cadastro', 'Forma de pagamento', 'Revisar pedido e enviar'].map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>

                    {page === 0 &&
                        <ProductStep
                            productHash={params.hash ?? ''}
                            onNext={onNext}
                        />
                    }

                    {page === 1 &&
                        <CustomerStep
                            productHash={params.hash ?? ''}
                            onBack={onBack}
                            onNext={onNext}
                        />
                    }

                    {page === 2 &&
                        <PaymentMethodStep
                            availablePaymentOptions={queryParams ? queryParams.get('payment_options')?.split(',') : []}
                            onBack={onBack}
                            onNext={onNext}
                        />
                    }

                    {page === 3 &&
                        <ReviewStep
                            onBack={onBack}
                            onNext={onNext}
                        />
                    }

                    {page >= 4 &&
                        <FinalStep
                            onBack={onBack}
                        />
                    }
            </div>
        </Paper>

    </div>
    </>
}

export {Checkout};