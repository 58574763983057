import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from 'utils/auth';

import { formatUsersToDisplay, getUsers, SortType, UsersDisplayType, UsersType } from './services';
import { ApiError } from 'utils/CustomError';


export default function UsersViewModel() {

  const [users, setUsers] = useState<Array<UsersType>>();
  const [formattedUsers, setFormattedUsers] = useState<Array<UsersDisplayType>>();

  // loading states
  const [userLoading, setUserLoading] = useState(true);
  const [userLoadingError, setUserLoadingError] = useState(false);

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const [actionUserIndex, setActionUserIndex] = useState<number>()

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'name', label: 'Nome' },
    { id: 'email', label: 'Email/Telefone' },
    { id: 'roles', label: 'Perfis de acesso' },
    { id: 'status', label: 'Status' },
  ];

  const onGetUsersHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setUserLoadingError(true)

      setUsers([]);
      setFormattedUsers([])
      setTotalUsers(0);
    } else {
      setUserLoadingError(true)

      setUsers([]);
      setFormattedUsers([])
      setTotalUsers(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setUserLoading(true);
      setUserLoadingError(false)

      const response = await getUsers(sort, { page, limit: rowsPerPage }, abortController.signal);

      if (response.errors) {
        onGetUsersHandleError(response.errors)
      } else {
        setUserLoadingError(false)
        setUsers(response.users as Array<UsersType>);
        setFormattedUsers(formatUsersToDisplay(response.users))

        setTotalUsers(response.pagination.totalUsers);
      }

      setUserLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [page, rowsPerPage, sort])


  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'edit':
        setActionUserIndex(index);
        setOpenEdit(true)
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseCreateModal = () => {
    setOpenCreate(false)
  }

  const onOpenCreateModal = () => {
    setOpenCreate(true)
  }

  const onCloseEdit = () => {
    setOpenEdit(false)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    actionUserIndex,
    users,
    userLoading,
    userLoadingError,
    formattedUsers,
    openCreate,
    openEdit,
    optionsRowsPerPage,
    page,
    rowsPerPage,
    sort,
    totalUsers,
    tableHeadCells,
    handleTableRowActions,
    onChangeSort,
    onCloseCreateModal,
    onCloseEdit,
    onOpenCreateModal,
    onPageChange,
    onRowsPerPageChange,
  };
}