import dayjs from 'dayjs';

import { CustomError, api } from 'utils/axios';
import { formatMoney } from 'utils/moneyFormatt';

type PaginationType = {
  limit: number,
  page: number
}

type SortType = {
  field: string,
  order: 'asc' | 'desc'
}

type ActionType = {
  id: string,
  label: string,
  disabled?: boolean,
}

type InvoiceGroupType = {
  id: string,
  customer_id: string,
  customer_name: string,
  key: string,
  total: number,
  payment_status: string,
  receipt_status: string,
  created_at: string,
  due_date: string,
  status: string,
  path_to_file: string,
}

type InvoiceGroupDisplayType = {
  id: string,
  customer_id: string,
  customer_name: string,
  key: string,
  total: string,
  payment_status: string,
  receipt_status: string,
  created_at: string,
  due_date: string,
  status: string,
  path_to_file: string,
  actions: ActionType[]
}

type FilterType = {
  field: string,
  comparator: string,
  value: any
}


const parseInvoices = async (response: { data: any, meta: any }) => {
  const invoices = response.data.map((data: any) => {
    return {
      id: data.id,
      customer_id: data.attributes.customer_id,
      customer_name: data.attributes.customer_name,
      key: data.attributes.key,
      total: data.attributes.total,
      payment_status: data.attributes.payment_status,
      receipt_status: data.attributes.receipt_status,
      created_at: data.attributes.created_at.date,
      due_date: data.attributes.due_date.date,
      status: data.attributes.status,
      path_to_file: data.attributes.path_to_file,
    }
  });

  return {
    invoices,
    pagination: {
      totalInvoices: response.meta.pagination.total
    }
  }
}

const getInvoices = async (filters: FilterType[], search: string, sort: SortType, pagination: PaginationType, signal: AbortSignal): Promise<any> => {
  const params: any = { ...pagination };

  for (const filter of filters) {
    const filterAttribute = `filter[${filter.field}]`;
    let filterValue;

    if (filter.value === null || filter.value === undefined || filter.value.length === 0)
      continue;

    if (Array.isArray(filter.value))
      filterValue = `${filter.comparator}:${filter.value.join()}`;
    else
      filterValue = `${filter.comparator}:${filter.value}`;

    if (params[filterAttribute])
      params[filterAttribute] = `${params[filterAttribute]},${filterValue}`;
    else
      params[filterAttribute] = `${filterValue}`;
  }

  params.sort = `${sort.field}:${sort.order}`;

  if (search)
    params.q = search;

  try {
    const response = await api.get(`/api-cashone/invoice-groups`, {
      params,
      signal
    });

    return parseInvoices(response.data);

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatInvoicesToDisplay = (invoices: Array<InvoiceGroupType>): Array<InvoiceGroupDisplayType> => {

  const translateStatus = {
    'DRAFT': 'RASCUNHO',
    'POST': 'APROVADO',
    'CANCELED': 'CANCELADO',
    'DISCARDED': 'DESCARTADO',
    'ERROR': 'ERRO',
    'POSTING': 'PROCESSANDO',
  }

  const translateReceiptStatus = {
    'DRAFT': 'Rascunho',
    'AUTHORIZED': 'Autorizado',
    'CANCELLED': 'Cancelado',
    'CANCELLING': 'Cancelando',
    'ERROR': 'Erro',
    'PENDING': 'Pendente',
    'PROCESSING': 'Processando'
  }

  const translatePaymentStatus = {
    'CANCELED': 'Cancelado',
    'EXPIRED': 'Expirado',
    'FAILED': 'Falhou',
    'PAID': 'Pago',
    'PENDING': 'Pendente',
  }

  type StatusEnum = keyof typeof translateStatus;
  type ReceiptStatusEnum = keyof typeof translateReceiptStatus;
  type PaymentStatusEnum = keyof typeof translatePaymentStatus;

  return invoices.map((invoice: InvoiceGroupType) => {
    let actions: ActionType[] = [];

    switch (invoice.status) {
      case 'CANCELED':
        actions = [
          { id: 're-execute', label: 'Reemitir', disabled: false },
          { id: 'cancel', label: 'Cancelar', disabled: true },
        ]
        break;

      case 'POST':
        actions = [
          { id: 're-execute', label: 'Reemitir', disabled: true },
          { id: 'cancel', label: 'Cancelar', disabled: false },
          { id: 'email', label: 'Enviar email com fatura', disabled: false },
        ]
        break;

      default:
        actions = [
          { id: 're-execute', label: 'Reemitir', disabled: true },
          { id: 'cancel', label: 'Cancelar', disabled: true },
        ]
        break;
    }

    return {
      id: invoice.id.toString(),
      customer_id: invoice.customer_id,
      customer_name: invoice.customer_name,
      key: invoice.key,
      total: formatMoney(invoice.total),
      receipt_status: invoice.receipt_status ?
        translateReceiptStatus[invoice.receipt_status as ReceiptStatusEnum] :
        'N/A',
      payment_status: invoice.payment_status ?
        translatePaymentStatus[invoice.payment_status as PaymentStatusEnum] :
        'N/A',
      status: translateStatus[invoice.status as StatusEnum],
      path_to_file: invoice.path_to_file,
      due_date: dayjs(invoice.due_date).format('DD/MM/YYYY'),
      created_at: dayjs(invoice.created_at).format('DD/MM/YYYY'),
      actions
    }
  })

}

export { getInvoices, formatInvoicesToDisplay };

export type { PaginationType, SortType, InvoiceGroupType, InvoiceGroupDisplayType, FilterType };