import { Box, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import { AccountReceivableGeneralView } from './pages/General';

import useViewModel from './viewModel'

import './styles.scss'

function AccountReceivableView() {
  const {
    loadingTitleName,
    params,
    tabSelected,
    titleName,
    onTabChange
  } = useViewModel();

  return (
    <div className='accounts-receivables-view'>

      <div className='view-header'>
        <div className='view-title'>

          {!loadingTitleName ? (

            <Typography variant='h3' fontWeight={700}>
              {titleName ? `Conta a receber - ${titleName}` : 'Conta a receber' }
            </Typography>

          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
          onChange={(event, value) => { onTabChange(value) }}
        >

          <Tab
            label='Visão Geral'
            id='general'
            aria-controls='tabpanel-general'
          />

        </Tabs>
      </Box>

      <div
        role="tabpanel"
        hidden={tabSelected !== 0}
        id={'tabpanel-general'}
        aria-labelledby={'tab-general'}
      >
        <AccountReceivableGeneralView
          accountReceivableId={params.id ? params.id : ''}
        />
      </div>

    </div>
  );
}

export { AccountReceivableView }