import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from '../../utils/auth';

import { SuppliersType, FilterType, getSuppliers, formatSuppliersToDisplay, SortType, SuppliersDisplayType } from './services';
import { ApiError } from 'utils/CustomError';


export default function SuppliersViewModel() {

  const [suppliers, setSuppliers] = useState<Array<SuppliersType>>();
  const [formattedSuppliers, setFormattedSuppliers] = useState<Array<SuppliersDisplayType>>();
  const [searchSuppliers, setSearchSuppliers] = useState<string>('');

  const [actionIndex, setActionIndex] = useState<number>()

  const [openEditSupplier, setOpenEditSupplier] = useState(false)
  const [openCreateSupplier, setOpenCreateSupplier] = useState(false);
  const [createSupplierType, setCreateSupplierType] = useState<string>()
  const [openActivateSupplier, setOpenActivateSupplier] = useState(false)
  const [openDeactivateSupplier, setOpenDeactivateSupplier] = useState(false)

  // loading states
  const [supplierLoading, setSupplierLoading] = useState(true);
  const [supplierLoadingError, setSupplierLoadingError] = useState(false);

  // filters
  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: ['ACTIVE']
  });

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalSuppliers, setTotalSuppliers] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100]

  const tableHeadCells = [
    { id: 'name', label: 'Fornecedor' },
    { id: 'document', label: 'Documento' },
    { id: 'emails', label: 'Emails' },
    { id: 'created_at', label: 'Criado em' },
    { id: 'status', label: 'Status' },
  ];

  const statusOptions = [
    { label: 'Ativos', value: 'ACTIVE' },
    { label: 'Inativos', value: 'CANCELED' },
  ];


  const onGetSuppliersHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setSupplierLoadingError(true)

      setSuppliers([]);
      setFormattedSuppliers([])
      setTotalSuppliers(0);
    } else {
      setSupplierLoadingError(true)

      setSuppliers([]);
      setFormattedSuppliers([])
      setTotalSuppliers(0);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setSupplierLoading(true);
      setSupplierLoadingError(false)

      const response = await getSuppliers([statusFilter], searchSuppliers, sort, { page, limit: rowsPerPage }, abortController.signal);

      if (response.errors) {
        onGetSuppliersHandleError(response.errors)
      } else {
        setSupplierLoadingError(false)
        setSuppliers(response.suppliers as Array<SuppliersType>);
        setFormattedSuppliers(formatSuppliersToDisplay(response.suppliers))

        setTotalSuppliers(response.pagination.totalSuppliers);
      }

      setSupplierLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }

  }, [page, rowsPerPage, searchSuppliers, sort, statusFilter])

  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'edit':
        setActionIndex(index)
        setOpenEditSupplier(true)
        break;

      case 'activate':
        setActionIndex(index)
        setOpenActivateSupplier(true)
        break;

      case 'deactivate':
        setActionIndex(index)
        setOpenDeactivateSupplier(true)
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onSupplierSearchChange = (search: string) => {
    setPage(1);
    setSearchSuppliers(search);
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  const onOpenModal = (id: string) => {
    setCreateSupplierType(id)
    setOpenCreateSupplier(true)
  }

  const onCloseModal = () => {
    setOpenCreateSupplier(false)
  }

  const onCloseEditModal = () => {
    setActionIndex(undefined)
    setOpenEditSupplier(false)
  }

  const onCloseActivateModal = () => {
    setActionIndex(undefined)
    setOpenActivateSupplier(false)
  }

  const onCloseDeactivateModal = () => {
    setActionIndex(undefined)
    setOpenDeactivateSupplier(false)
  }

  return {
    actionIndex,
    suppliers,
    supplierLoading,
    supplierLoadingError,
    formattedSuppliers,
    optionsRowsPerPage,
    openCreateSupplier,
    openEditSupplier,
    openActivateSupplier,
    openDeactivateSupplier,
    createSupplierType,
    page,
    rowsPerPage,
    sort,
    statusFilter,
    statusOptions,
    totalSuppliers,
    tableHeadCells,
    applyStatusFilter,
    handleTableRowActions,
    onOpenModal,
    onCloseModal,
    onCloseEditModal,
    onCloseActivateModal,
    onCloseDeactivateModal,
    onChangeSort,
    onSupplierSearchChange,
    onPageChange,
    onRowsPerPageChange
  };
}