import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiError } from 'utils/CustomError';
import { notAuthenticatedRedirect } from 'utils/auth';
import { REGIME, REGIME_ESPECIAL } from 'utils/constants/regime';
import { formatForInput } from 'utils/formatConstantForInput';

import { getCompanyConfigurations, postLogo, CompanyType, CompanyToDisplayType, formatCompanyToDisplay, patchCompanyConfigurations } from './services';

export default function CompanyViewModel() {
  const [company, setCompany] = useState<CompanyType>()
  const [formattedCompany, setFormattedCompany] = useState<CompanyToDisplayType>()

  const [companyLoading, setCompanyLoading] = useState(true);
  const [companyLoadingError, setCompanyLoadingError] = useState(false);

  const formattedRegime = formatForInput(REGIME)
  const formattedRegimeEspecial = formatForInput(REGIME_ESPECIAL)

  const [newLogo, setNewLogo] = useState<string>();
  const [logoLoading, setLogoLoading] = useState(false);

  const [editError, setEditError] = useState(false)
  const [formError, setFormError] = useState(false)
  const [postLogoError, setPostLogoError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate()


  const onGetCompanyConfigurationHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setCompanyLoadingError(true)

      setCompany({} as any);
      setFormattedCompany({} as any);
    } else {
      setCompanyLoadingError(true)

      setCompany({} as any);
      setFormattedCompany({} as any);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setCompanyLoading(true);
      setCompanyLoadingError(false)

      const response = await getCompanyConfigurations(abortController.signal);

      if (response.errors) {
        onGetCompanyConfigurationHandleError(response.errors)
      } else {
        setCompanyLoadingError(false)
        setCompany(response.company);
        setFormattedCompany(formatCompanyToDisplay(response.company))
      }

      setCompanyLoading(false || abortController.signal.aborted);
    })()

    return () => {
      abortController.abort();
    }
  }, [])


  const onPatchSystemConfigurationHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setEditError(false);
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setEditError(true)
      setFormError(false);
      setOpenSnackbar(true)
    } else {
      setEditError(true)
      setFormError(false);
      setOpenSnackbar(true)
    }
  }

  const onPostLogoHandleError = (errors: ApiError) => {
    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    } else if (errors[0].type === 'ERR_NETWORK') {
      setPostLogoError(true)
      setOpenSnackbar(true)
    } else {
      setPostLogoError(true)
      setOpenSnackbar(true)
    }
  }


  const handleSubmit = async (values: Record<string, any>) => {
    setCompanyLoading(true)
    const response = await patchCompanyConfigurations(values as CompanyToDisplayType, newLogo)

    if (response.errors)
      onPatchSystemConfigurationHandleError(response.errors)

    else {
      setEditError(false)
      setFormError(false)
      setPostLogoError(false)
      setOpenSnackbar(true)
    }
    setCompanyLoading(false)
  }

  const onCancel = () => {
    navigate('/configurations')
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const onLogoChange = async (file: File) => {
    setLogoLoading(true);
    const response = await postLogo(file)

    if (response.errors)
      onPostLogoHandleError(response.errors)

    else {
      setNewLogo(response)
      setPostLogoError(false)
    }
    setLogoLoading(false);
  }

  return {
    company,
    companyLoading,
    companyLoadingError,
    editError,
    formattedCompany,
    formattedRegime,
    formattedRegimeEspecial,
    formError,
    logoLoading,
    newLogo,
    openSnackbar,
    postLogoError,
    handleSubmit,
    onCancel,
    onCloseSnackbar,
    onLogoChange,
  }
}