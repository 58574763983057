import { useState } from "react";

export default function ImageFileInputViewModel() {
  const [newImage, setNewImage] = useState<string>()

  const updateImage = (file: File) => {
    setNewImage(URL.createObjectURL(file))
  }

  return {
    newImage,
    updateImage
  }
}