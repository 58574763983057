import { CustomError, api } from 'utils/axios';

type CompanyType = {
  name: string,
  razao_social: string,
  cnpj: string,
  logo: string,
  inscricao_municipal: string,
  regime: string,
  regime_especial: string,
  optante_simples_nacional: boolean,
  email: string,
  telephone: string,
  address: string,
  number: string,
  neighbourhood: string,
  city: string,
  state_province: string,
  state_abbreviation: string,
  postal_code: string,
  complement: string,
}

type CompanyToDisplayType = {
  name: string,
  razao_social: string,
  cnpj: string,
  logo: string,
  inscricao_municipal: string,
  regime: string,
  regime_especial: string,
  email: string,
  telephone: string,
  address: string,
  number: string,
  neighbourhood: string,
  city: string,
  state_province: string,
  state_abbreviation: string,
  postal_code: string,
  complement: string,
}

type CompanyBundle = {
  name: string,
  logo: string,
  telephone: string,
  regime: {
    inscricao_municipal: string,
    regime: string,
    regime_especial_tributacao: string
  }
}

const getCompanyConfigurations = async (signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get('/api-cashone/settings/company', { signal });

    return { company: response.data.data.attributes }

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatCompanyToDisplay = (company: CompanyType): CompanyToDisplayType => {
  return {
    ...company,
    regime: company.optante_simples_nacional ? 'REGIME_SIMPLES_NACIONAL' : company.regime
  }
}

const postLogo = async (file: File): Promise<any> => {
  try {
    const response = await api.post('/api-cashone/settings/company/logo', {
      attachments: file
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data.data.attributes.url;

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const bundleCompany = (values: CompanyToDisplayType, newLogo?: string): CompanyBundle => {
  return {
    name: values.name,
    logo: newLogo ? newLogo : values.logo,
    telephone: values.telephone,
    regime: {
      inscricao_municipal: values.inscricao_municipal,
      regime: values.regime,
      regime_especial_tributacao: values.regime_especial
    }
  }
}

const patchCompanyConfigurations = async (values: CompanyToDisplayType, newLogo?: string): Promise<any> => {
  try {
    const response = await api.patch('/api-cashone/settings/company', bundleCompany(values, newLogo))

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getCompanyConfigurations, patchCompanyConfigurations, postLogo, formatCompanyToDisplay }

export type { CompanyType, CompanyToDisplayType }