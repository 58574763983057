import useViewModel from './viewModel';

import { DateRangeFilter } from '../../../../components/Filters/DateRangeFilter';
import { SearchCheckboxFilter } from '../../../../components/Filters/SearchCheckboxFilter';
import { SearchFilter } from '../../../../components/Filters/SearchFilter';
import { CheckboxFilter } from '../../../../components/Filters/CheckboxFilter';
import { SaleListTable } from '../../../../components/Tables/SaleListTable';

import { EmptyView } from 'components/EmptyView';
import { ApiErrorView } from 'components/ApiErrorView';
import { CancelSaleModal } from 'components/Modals/Sale/Cancel';
import { ApplyCouponModal } from 'components/Modals/Sale/ApplyCoupon';
import { EditSaleModal } from 'components/Modals/Sale/Edit';

function SalesRecurring() {
  const {
    actionSaleIndex,
    openCancel,
    openApplyCoupon,
    openEdit,
    optionsRowsPerPage,
    page,
    plans,
    products,
    productsFilter,
    rowsPerPage,
    formattedSales,
    salesLoading,
    sales,
    salesLoadingError,
    searchPlans,
    searchProducts,
    searchTags,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    tags,
    totalSales,
    applyPeriodEndFilter,
    applyPeriodStartFilter,
    applyPlansFilter,
    applyProductsFilter,
    applyStatusFilter,
    applyTagsFilter,
    handleTableRowActions,
    onCloseApplyCoupon,
    onCloseCancel,
    onCloseEdit,
    onChangeSort,
    onPageChange,
    onPlanSearchChange,
    onProductSearchChange,
    onRowsPerPageChange,
    onSalesSearchChange,
    onTagSearchChange,
  } = useViewModel();

  return (
    <div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por cliente, chave, produto ou plano'
            onSubmit={onSalesSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status-sales-recurring'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <SearchCheckboxFilter
            id='products'
            buttonLabel='Produtos'
            options={products ? products : []}
            search={searchProducts}
            onApplyFilter={applyProductsFilter}
            onSearchChange={onProductSearchChange}
          />

          <SearchCheckboxFilter
            id='plans-sales-recurring'
            buttonLabel='Planos'
            disabled={productsFilter.value.length === 0}
            options={plans ? plans : []}
            search={searchPlans}
            onApplyFilter={applyPlansFilter}
            onSearchChange={onPlanSearchChange}
          />

          <DateRangeFilter
            id='periodStart-sales-recurring'
            buttonLabel='Data de início'
            onApplyFilter={applyPeriodStartFilter}
          />

          <DateRangeFilter
            id='periodEnd-sales-recurring'
            buttonLabel='Data de Término'
            onApplyFilter={applyPeriodEndFilter}
          />

          <SearchCheckboxFilter
            id='tags-sales-recurring'
            buttonLabel='Tags'
            options={tags ? tags : []}
            search={searchTags}
            onApplyFilter={applyTagsFilter}
            onSearchChange={onTagSearchChange}
          />
        </div>
      </div>

      {!salesLoading &&
        !salesLoadingError &&
        formattedSales?.length === 0 &&
        <EmptyView title={'Nenhuma assinatura encontrada para mostrar aqui'}></EmptyView>
      }

      {salesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as assinaturas. Tente recarregar a página ou usar filtros diferentes.' />
      }

      {(salesLoading || (formattedSales !== undefined && formattedSales.length > 0)) &&
        <>
          <SaleListTable
            hasActions
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rowLink='/sales/recurring'
            rows={formattedSales ? formattedSales : []}
            rowsPerPage={rowsPerPage}
            total={totalSales}
            isLoading={salesLoading}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <CancelSaleModal
            onClose={onCloseCancel}
            open={openCancel}
            saleData={{
              id: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].id : '',
              key: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].key : '',
            }}
          />

          <ApplyCouponModal
            onClose={onCloseApplyCoupon}
            open={openApplyCoupon}
            saleData={{
              id: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].id : '',
              key: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].key : '',
            }}
          />

          <EditSaleModal
            onClose={onCloseEdit}
            open={openEdit}
            type='RECURRING'
            saleData={{
              id: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].id : '',
              key: sales && actionSaleIndex !== undefined ? sales[actionSaleIndex].key : '',
            }}
          />
        </>
      }

    </div>
  )
}

export { SalesRecurring };