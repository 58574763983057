import { useState } from 'react'

import { PlanPayload, postPlan } from './services'
import { ApiError } from 'utils/CustomError'
import { notAuthenticatedRedirect } from 'utils/auth'
import { ValidationErrors } from 'final-form'

type ViewModelPropTypes = {
  productId: string,
  onClose: () => void,
  onSubmit?: (response: any) => void
}

export default function CreatePlanModalViewModel({ productId, onClose, onSubmit }: ViewModelPropTypes) {

  const [createLoading, setCreateLoading] = useState(false)
  const [createError, setCreateError] = useState(false)

  const [formError, setFormError] = useState(false)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const freqOptions = [
    { label: 'Por mês', value: 'MONTHLY' },
    { label: 'Trimestral', value: 'QUARTERLY' },
    { label: 'Semestral', value: 'SEMIANNUAL' },
    { label: 'Anual', value: 'YEARLY' },
  ]

  const paymentTermTypeOptions = [
    { label: 'Dia fixo do mês', value: 'FIXED' },
    { label: 'Dias após emissão', value: 'NET' },
  ]

  const paymentTermValueOptions = [...Array(28)].map((value, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
  paymentTermValueOptions.unshift({ label: 'Último dia do mês', value: '0' })

  const onPostPlanHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 400) {
      setFormError(true);
      setOpenSnackbar(true)
    } else if (errors[0].type === 'ERR_NETWORK') {
      setCreateError(true)
      setOpenSnackbar(true)
    } else {
      setCreateError(true)
      setOpenSnackbar(true)
    }
  }

  const handleSubmit = async (values: Record<string, any>) => {
    setCreateLoading(true)
    const response = await postPlan(productId, values as PlanPayload)

    if (response.errors)
      onPostPlanHandleError(response.errors)

    else {
      setCreateError(false)
      setFormError(false)
      setOpenSnackbar(true)

      if (onSubmit)
        onSubmit(response)

      onClose()
    }
    setCreateLoading(false);
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const formValidate = (values: any) => {
    const errors: ValidationErrors = {}

    if (!values.name)
      errors.name = 'Obrigatório'

    if (!values.freq)
      errors.freq = 'Obrigatório'

    if (!values.initial_term)
      errors.initial_term = 'Obrigatório'

    if (values.initial_term < 1)
      errors.initial_term = 'Termo de contrato deve ser maior que 1 mês'

    if (!values.payment_term_type)
      errors.payment_term_type = 'Obrigatório'

    if (!values.payment_term_value)
      errors.payment_term_value = 'Obrigatório'

    if (values.payment_term_value < 0)
      errors.payment_term_value = 'Dia não é válido'

    if (!values.value)
      errors.value = 'Obrigatório'

    return errors
  }

  return {
    createLoading,
    createError,
    formError,
    freqOptions,
    paymentTermTypeOptions,
    paymentTermValueOptions,
    openSnackbar,
    formValidate,
    handleSubmit,
    onCloseSnackbar
  }
}