import React, { useState } from 'react';

export default function PasswordFieldAdapterViewModel() {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  return { showPassword, toggleShow }
}
