import { Alert, Box, Button, CircularProgress, Modal, Paper, Snackbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { Form } from 'react-final-form'

import { Flexbox } from 'components/Flexbox';
import { TextFieldAdapter } from 'components/FormComponents/Fields/TextFieldAdapter';
import { RadioFieldAdapter } from 'components/FormComponents/Fields/RadioFieldAdapter';

import useViewModel from './viewModel'

import '../../styles.scss';

type CreateCashierBanksModalPropTypes = {
  open: boolean,
  onClose: () => void,
  onSubmit?: (response: any) => void,
}

function CreateCashierBankModal({ open, onClose, onSubmit }: CreateCashierBanksModalPropTypes) {
  const {
    createLoading,
    createError,
    formError,
    openSnackbar,
    cashierBankTypeOptions,
    cashierBankType,
    formValidate,
    handleSubmit,
    onCloseSnackbar,
    onTypeChange
  } = useViewModel({ onClose, onSubmit })

  return (
    <>
      <Modal
        open={open}
        className='center-modal'
        onClose={onClose}
        closeAfterTransition
      >
        <Box component={Paper} className='custom-modal'>
          <Form
            onSubmit={handleSubmit}
            validate={formValidate}
            initialValues={{ type: cashierBankType }}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>

                <div className='modal-header'>
                  <Typography variant='h2'>
                    Criar Conta
                  </Typography>
                  <div className='modal-close-button' onClick={onClose}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body'>
                  
                  <Flexbox size={6}>
                    <RadioFieldAdapter
                      label='Tipo de conta'
                      name='type'
                      options={cashierBankTypeOptions}
                      onChange={(e) => { onTypeChange(e.target.value, form) }}
                    />
                  </Flexbox>

                  {cashierBankType === 'CASHIER_CASH' && (
                    <Flexbox size={12}>
                      <TextFieldAdapter
                        label={'Nome'}
                        name='name'
                        disabled={createLoading}
                      />
                    </Flexbox>
                  )}

                  {cashierBankType === 'CASHIER_BANK_ACCOUNT' && (
                    <div>
                      <Flexbox size={12}>
                        <Flexbox size={4}>
                          <TextFieldAdapter
                            label={'Código do banco'}
                            name='bank_code'
                            disabled={createLoading}
                          />
                        </Flexbox>

                        <Flexbox size={8}>
                          <TextFieldAdapter
                            label={'Nome do banco'}
                            name='bank_name'
                            disabled={createLoading}
                          />
                        </Flexbox>
                      </Flexbox>

                      <Flexbox size={12}>
                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Agência'}
                            name='routing_number'
                            disabled={createLoading}
                          />
                        </Flexbox>

                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Dígito verificador'}
                            name='routing_number_digit_verifier'
                            disabled={createLoading}
                          />
                        </Flexbox>
                      </Flexbox>

                      <Flexbox size={12}>
                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Número da conta'}
                            name='account_number'
                            disabled={createLoading}
                          />
                        </Flexbox>

                        <Flexbox size={6}>
                          <TextFieldAdapter
                            label={'Dígito verificador'}
                            name='account_number_digit_verifier'
                            disabled={createLoading}
                          />
                        </Flexbox>
                      </Flexbox>
                  </div>
                  )}  
                </div>

                <div className='modal-footer'>
                  {createLoading && <CircularProgress className='loading' color='primary' size={20} />}
                  <Button variant='outlined' onClick={onClose} disabled={createLoading}>Cancelar</Button>
                  <Button type='submit' variant='contained' disabled={createLoading}>Salvar</Button>
                </div>
              </form>
            )}
          />
        </Box>
      </Modal>

      {(createError) &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='error' variant='filled'>
            Ops! Algo deu errado, tente de novo mais tarde.
          </Alert>
        </Snackbar>
      }

      {formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='warning' variant='filled'>
            Parece que existem campos que precisam da sua atenção.
          </Alert>
        </Snackbar>
      }

      {!createError && !formError &&
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={onCloseSnackbar}>
          <Alert onClose={onCloseSnackbar} severity='success' variant='filled'>
            Conta criada!
          </Alert>
        </Snackbar>
      }
    </>
  )
}

export { CreateCashierBankModal }