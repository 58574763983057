import { SalePayloadType } from 'recoil/createSale/states';
import { CustomError, api } from 'utils/axios';

type SinglePaymentMethodsType = {
  payment_method_type: string,
  payment_method_id: number | null,
  payment_method_description: string,
}

type PaymentMethodsType = {
  'BANK_DEPOSIT': SinglePaymentMethodsType[],
  'BOLETO': SinglePaymentMethodsType[],
  'CREDIT_CARD': SinglePaymentMethodsType[],
  'PIX': SinglePaymentMethodsType[],
}

type PaymentMethodOptionType = {
  label: string,
  value: string,
}

type PaymentMethodsDisplayType = {
  'BANK_DEPOSIT': PaymentMethodOptionType[],
  'BOLETO': PaymentMethodOptionType[],
  'CREDIT_CARD': PaymentMethodOptionType[],
  'PIX': PaymentMethodOptionType[],
}

const parsePaymentMethods = (response: { data: any[] }) => {
  const result: PaymentMethodsType = {
    'BANK_DEPOSIT': [],
    'BOLETO': [],
    'CREDIT_CARD': [],
    'PIX': [],
  }

  type typeEnum = keyof typeof result;

  response.data.forEach(data => {
    result[data.attributes.payment_method_type as typeEnum].push({
      payment_method_type: data.attributes.payment_method_type,
      payment_method_id: data.attributes.payment_method_id,
      payment_method_description: data.attributes.payment_method_description
    })
  })

  return result
}

const getPaymentMethods = async (id: string): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/clients/${id}/payment-methods`);

    return parsePaymentMethods(response.data)

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatPaymentMethodToDisplay = (paymentMethods: PaymentMethodsType): PaymentMethodsDisplayType => {
  const result: PaymentMethodsDisplayType = {
    'BANK_DEPOSIT': [],
    'BOLETO': [],
    'CREDIT_CARD': [{
      label: 'Novo cartão',
      value: '0'
    }],
    'PIX': [],
  }

  type typeEnum = keyof typeof result;

  for (const index in paymentMethods) {
    for (const pm of paymentMethods[index as typeEnum]) {
      if (pm.payment_method_id && pm.payment_method_description)
        result[pm.payment_method_type as typeEnum].push({
          label: pm.payment_method_description,
          value: `${pm.payment_method_id}`
        })
    }
  }

  return result;
}

const formatSalePayloadToDisplay = (values: SalePayloadType) => {
  let response: any = {}

  if (values.payment_method_type)
    response.payment_method_type = values.payment_method_type

  if (values.payment_method_id)
    response.payment_method_id = values.payment_method_id

  if (!values.payment_method_id && values.payment_method_type === 'CREDIT_CARD')
    response.payment_method_id = '0'

  
  if (values.payment_method_type === 'BANK_DEPOSIT') {
    response.payment_method_option = formatPaymentMethodToDisplay({
      'BANK_DEPOSIT': [
        { payment_method_type: 'BANK_DEPOSIT', payment_method_id: parseInt(values.payment_method_id ?? ''), payment_method_description: values.payment_method_description ?? '' }],
      'BOLETO': [],
      'CREDIT_CARD': [],
      'PIX': [],
    })
  } else if (values.payment_method_type === 'CREDIT_CARD') {
    response.payment_method_option = formatPaymentMethodToDisplay({
      'BANK_DEPOSIT': [],
      'BOLETO': [],
      'CREDIT_CARD': [
        { payment_method_type: 'CREDIT_CARD', payment_method_id: parseInt(values.payment_method_id ?? ''), payment_method_description: values.payment_method_description ?? '' }
      ],
      'PIX': [],
    })
  }

  return response
}

const bundlePayloadToRecoil = (values: Record<string, any>): SalePayloadType => {
  return {
    payment_method_type: values.payment_method_type,
    payment_method_id: values.payment_method_id && values.payment_method_id !== '0' ? values.payment_method_id : undefined,
    payment_method_description: values.payment_method_description
  }
}

export {
  bundlePayloadToRecoil,
  formatPaymentMethodToDisplay,
  getPaymentMethods,
  formatSalePayloadToDisplay
}

export type {
  PaymentMethodsType,
  PaymentMethodsDisplayType,
}