import { CustomError, api } from 'utils/axios';

type PaymentMethodsType = {
  hash: string,
  payment_method_id: string,
  customer_payment_info_id: string,
  payment_method_type: string,
  payment_method_description: string,
}[]

type PaymentMethodsToDisplayType = {
  value: string,
  label: string
}[]

const parsePaymentMethods = (response: any) => {
  return response.data.map((data: any) => ({
    hash: data.id,
    payment_method_id: data.attributes.payment_method_id,
    customer_payment_info_id: data.attributes.customer_payment_info_id,
    payment_method_type: data.attributes.payment_method_type,
    payment_method_description: data.attributes.payment_method_description,
  }))
}

const getPaymentMethods = async (id: string, signal: AbortSignal): Promise<any> => {
  try {
    const response = await api.get(`/api-cashone/clients/${id}/payment-methods`, { signal })

    return parsePaymentMethods(response.data);
  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

const formatPaymentMethodsToDisplay = (paymentMethods: PaymentMethodsType): PaymentMethodsToDisplayType => {
  const translateType = {
    'BOLETO': 'Boleto',
    'BANK_DEPOSIT': 'Depósito Bancário',
    'CREDIT_CARD': 'Cartão de Crédito',
    'PIX': 'Pix',
  }
  type translateEnum = keyof typeof translateType

  return paymentMethods.map((pm) => ({
    value: pm.hash,
    label: pm.payment_method_type !== 'PIX' && pm.payment_method_type !== 'BOLETO' ?
      `${translateType[pm.payment_method_type as translateEnum]} (${pm.payment_method_description})` :
      translateType[pm.payment_method_type as translateEnum]
  }))
}

const bundlePaymentMethod = (values: Record<string, any>, paymentMethods: PaymentMethodsType) => {
  const paymentMethod = paymentMethods.find(pm => pm.hash === values.hash)

  return {
    customer_payment_info_id: paymentMethod?.customer_payment_info_id,
    payment_method_id: paymentMethod?.payment_method_id,
    payment_method_type: paymentMethod?.payment_method_type,
  }
}

const postPaymentMethod = async (id: string, values: Record<string, any>, paymentMethods: PaymentMethodsType): Promise<any> => {
  try {
    const response = await api.post(`/api-cashone/accounts-receivables/${id}/change-payment-method`, bundlePaymentMethod(values, paymentMethods))

    return response

  } catch (error) {
    const errorResponse = (error as CustomError).errors
    if (errorResponse)
      return { errors: errorResponse };

    return { errors: [] };
  }
}

export { getPaymentMethods, postPaymentMethod, formatPaymentMethodsToDisplay }

export type { PaymentMethodsType, PaymentMethodsToDisplayType }