import { useRecoilState } from 'recoil';

import { openedFilterAtom } from './states';

function useOpenedFilters() {
  const [openedFilter, setOpenedFilter] = useRecoilState(openedFilterAtom);

  const onCloseFilter = () => {
    setOpenedFilter('');
  }

  const onOpenFilter = (id: string) => {
    setOpenedFilter(id);
  }

  return { openedFilter, onCloseFilter, onOpenFilter };
}

export { useOpenedFilters };