import { Typography } from '@mui/material';

import useViewModel from './viewModel';

import { SearchCheckboxFilter } from '../../components/Filters/SearchCheckboxFilter';
import { SearchFilter } from '../../components/Filters/SearchFilter';
import { CheckboxFilter } from '../../components/Filters/CheckboxFilter';
import { ClientListTable } from '../../components/Tables/ClientListTable';
import { Totals } from '../../components/Totals';
import { BulkClientsTags } from '../../components/BulkActions/ClientsTags';

import { EmptyView } from 'components/EmptyView';
import { ApiErrorView } from 'components/ApiErrorView';
import { OptionsButton } from 'components/OptionsButton';
import { CreateClientModal } from 'components/Modals/Client/Create';
import { EditClientModal } from 'components/Modals/Client/Edit';
import { ActivateClientModal } from 'components/Modals/Client/Activate';
import { DeactivateClientModal } from 'components/Modals/Client/Deactivate';

import '../styles.scss';

function Clients() {
  const {
    actionIndex,
    bulkTags,
    clients,
    clientLoading,
    clientLoadingError,
    clientsSelected,
    createClientType,
    formattedClients,
    page,
    openActivateClient,
    openCreateClient,
    openDeactivateClient,
    openEditClient,
    optionsRowsPerPage,
    rowsPerPage,
    searchTag,
    sort,
    statusFilter,
    statusOptions,
    totalClients,
    tableHeadCells,
    tags,
    applyStatusFilter,
    applyTagsFilter,
    handleTableRowActions,
    onChangeSort,
    onCheckboxChange,
    onClientSearchChange,
    onCloseActivateModal,
    onCloseDeactivateModal,
    onCloseModal,
    onCloseEditModal,
    onOpenModal,
    onPageChange,
    onRowsPerPageChange,
    onSubmitCreateTag,
    onSubmitEditTags,
    onTagSearchChange
  } = useViewModel();

  return (
    <div className='listing-page customers-listing-page'>

      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Clientes
          </Typography>
        </div>

        <div className='button-container'>
          <div className='action-button'>

            <OptionsButton
              disabled={clientLoading}
              label='Criar cliente'
              options={[
                { label: 'Pessoa física', id: 'brlPeopleRegime' },
                { label: 'Pessoa jurídica', id: 'brlCompanyRegime' },
                { label: 'Estrangeiro', id: 'brlInternationalCustomerRegime' },
              ]}
              onClickOption={onOpenModal}
            />

            <CreateClientModal
              open={openCreateClient}
              type={createClientType ? createClientType : ''}
              onClose={onCloseModal}
            />
          </div>

        </div>
      </div>

      <Totals
        title='Total de clientes'
        value={totalClients}
        isLoading={clientLoading}
      />

      <div className='filters-container' style={{ display: clientsSelected.length ? 'none' : 'flex' }}>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por Nome, Razão social, CPF ou CNPJ'
            onSubmit={onClientSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <SearchCheckboxFilter
            id='searchCheckbox'
            buttonLabel='Tags'
            options={tags ? tags : []}
            search={searchTag}
            onApplyFilter={applyTagsFilter}
            onSearchChange={onTagSearchChange}
          />
        </div>
      </div>

      <div className='bulk-actions-container' style={{ display: clientsSelected.length ? 'flex' : 'none' }} >
        <BulkClientsTags
          key={'teste'}
          clients={clients ? clients : []}
          clientsSelected={clientsSelected}
          tags={bulkTags ? bulkTags : []}
          onSubmitCreate={onSubmitCreateTag}
          onSubmitEdit={onSubmitEditTags}
        />
      </div>

      {!clientLoading &&
        !clientLoadingError &&
        formattedClients?.length === 0 &&
        <EmptyView title={'Nenhum cliente encontrado para mostrar aqui'}></EmptyView>
      }

      {clientLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar os clientes. Tente recarregar a página ou usar filtros diferentes.' />
      }

      {(clientLoading || (formattedClients !== undefined && formattedClients.length > 0)) &&
        <>
          <ClientListTable
            hasActions
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rowLink='/clients'
            rows={formattedClients ? formattedClients : []}
            rowsSelected={clientsSelected}
            rowsPerPage={rowsPerPage}
            total={totalClients}
            isLoading={clientLoading}
            onCheckboxClick={onCheckboxChange}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <EditClientModal
            clientId={clients && actionIndex !== undefined ? clients[actionIndex].id : ''}
            open={openEditClient}
            onClose={onCloseEditModal}
          />

          <ActivateClientModal
            clientData={{
              id: clients && actionIndex !== undefined ? clients[actionIndex].id : ''
            }}
            open={openActivateClient}
            onClose={onCloseActivateModal}
          />

          <DeactivateClientModal
            clientData={{
              id: clients && actionIndex !== undefined ? clients[actionIndex].id : ''
            }}
            open={openDeactivateClient}
            onClose={onCloseDeactivateModal}
          />
        </>
      }
    </div>
  );
}

export { Clients };