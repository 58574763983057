import useViewModel from './viewModel';

import { InvoiceGroupListTable } from 'components/Tables/InvoiceGroupListTable';
import { ApiErrorView } from 'components/ApiErrorView';
import { EmptyView } from 'components/EmptyView';
import { Typography } from '@mui/material';
import { CheckboxFilter } from 'components/Filters/CheckboxFilter';
import { DateRangeFilter } from 'components/Filters/DateRangeFilter';
import { SearchFilter } from 'components/Filters/SearchFilter';
import { CancelInvoiceGroupModal } from 'components/Modals/InvoicesGroups/Cancel';
import { ReexecuteInvoiceGroupModal } from 'components/Modals/InvoicesGroups/Reexecute';
import { EmailInvoiceGroupModal } from 'components/Modals/InvoicesGroups/SendEmail';

function InvoiceGroups() {
  const {
    actionInvoiceIndex,
    formattedInvoices,
    invoices,
    invoicesLoading,
    invoicesLoadingError,
    openCancel,
    openReexecute,
    openEmail,
    optionsRowsPerPage,
    page,
    paymentStatusFilter,
    paymentStatusOptions,
    rowsPerPage,
    receiptStatusFilter,
    receiptStatusOptions,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalInvoices,
    applyCreatedAtFilter,
    applyDueDateFilter,
    applyPaymentStatusFilter,
    applyReceiptStatusFilter,
    applyStatusFilter,
    handleTableRowActions,
    onChangeSort,
    onCloseCancel,
    onCloseReexecute,
    onCloseEmail,
    onInvoiceSearchChange,
    onPageChange,
    onRowsPerPageChange,
  } = useViewModel();

  return (
    <div className='listing-page invoices-groups-listing-page'>
      <div className='action-header-container'>
        <div className='title'>
          <Typography variant='h1'>
            Faturas
          </Typography>
        </div>
      </div>

      <div className='filters-container'>
        <div className='search'>
          <SearchFilter
            placeholder='Busque por chave da fatura ou cliente'
            onSubmit={onInvoiceSearchChange}
          />
        </div>

        <div className='filters'>
          <CheckboxFilter
            buttonLabel='Status'
            id='status'
            initialSelected={statusFilter.value}
            options={statusOptions}
            onApplyFilter={applyStatusFilter}
          />

          <CheckboxFilter
            buttonLabel='Status de pagamento'
            id='payment-status'
            initialSelected={paymentStatusFilter.value}
            options={paymentStatusOptions}
            onApplyFilter={applyPaymentStatusFilter}
          />

          <CheckboxFilter
            buttonLabel='Status NF'
            id='receipt-status'
            initialSelected={receiptStatusFilter.value}
            options={receiptStatusOptions}
            onApplyFilter={applyReceiptStatusFilter}
          />

          <DateRangeFilter
            id='due-date'
            buttonLabel='Vencimento'
            onApplyFilter={applyDueDateFilter}
          />

          <DateRangeFilter
            id='created-at'
            buttonLabel='Criado em'
            onApplyFilter={applyCreatedAtFilter}
          />
        </div>
      </div>

      {!invoicesLoading &&
        !invoicesLoadingError &&
        formattedInvoices?.length === 0 &&
        <EmptyView title={'Nenhuma fatura encontrada para mostrar aqui'} />
      }

      {invoicesLoadingError &&
        <ApiErrorView title='Uh-oh! Algo inesperado aconteceu e não conseguimos carregar as faturas. Tente recarregar a página.' />
      }

      {(invoicesLoading || (formattedInvoices !== undefined && formattedInvoices.length > 0)) &&
        <>
          <InvoiceGroupListTable
            hasActions
            isAsc={sort.order === 'asc'}
            headCells={tableHeadCells}
            page={page}
            optionsRowsPerPage={optionsRowsPerPage}
            orderBy={sort.field}
            rows={formattedInvoices ? formattedInvoices : []}
            rowsPerPage={rowsPerPage}
            rowLink='/invoices-groups'
            total={totalInvoices}
            isLoading={invoicesLoading}
            onClickOption={handleTableRowActions}
            onPageChange={onPageChange}
            onRequestSort={onChangeSort}
            onRowsPerPageChange={onRowsPerPageChange}
          />

          <CancelInvoiceGroupModal
            invoiceGroupData={{
              id: actionInvoiceIndex !== undefined && invoices ? invoices[actionInvoiceIndex].id : '',
              key: actionInvoiceIndex !== undefined && invoices ? invoices[actionInvoiceIndex].key : '',
            }}
            onClose={onCloseCancel}
            open={openCancel}
          />

          <ReexecuteInvoiceGroupModal
            invoiceGroupData={{
              id: actionInvoiceIndex !== undefined && invoices ? invoices[actionInvoiceIndex].id : '',
              key: actionInvoiceIndex !== undefined && invoices ? invoices[actionInvoiceIndex].key : '',
            }}
            onClose={onCloseReexecute}
            open={openReexecute}
          />
        
          <EmailInvoiceGroupModal
            invoiceGroupData={{
              id: actionInvoiceIndex !== undefined && invoices ? invoices[actionInvoiceIndex].id : '',
              customer_id: actionInvoiceIndex !== undefined && invoices ? invoices[actionInvoiceIndex].customer_id : ''
            }}
            onClose={onCloseEmail}
            open={openEmail}
          />
        </>
      }

    </div>
  )
}

export { InvoiceGroups };