import { useEffect, useState } from 'react';

import { notAuthenticatedRedirect } from '../../../../utils/auth';

import { FilterType, getProducts, formatProductsToDisplay, ProductsType, ProductsDisplayType, SortType } from './services';
import { ApiError } from 'utils/CustomError';

export default function ProductSpotViewModel() {
  const [products, setProducts] = useState<Array<ProductsType>>();
  const [formattedProducts, setFormattedProducts] = useState<Array<ProductsDisplayType>>();
  const [searchProducts, setSearchProducts] = useState<string>('');

  const [salesRangeLimits, setSalesRangeLimits] = useState<{ min: number, max: number }>()

  const [actionProductIndex, setActionProductIndex] = useState<number>()
  const [openEdit, setOpenEdit] = useState(false)
  const [openActivate, setOpenActivate] = useState(false)
  const [openDeactivate, setOpenDeactivate] = useState(false)

  const [productsLoading, setProductsLoading] = useState(true);
  const [productsLoadingError, setProductsLoadingError] = useState(false);

  // filters
  const [statusFilter, setStatusFilter] = useState<FilterType>({
    field: 'status',
    comparator: 'in',
    value: ['ACTIVE']
  });

  const [lowerSaleFilter, setLowerSaleFilter] = useState<FilterType>({
    field: 'subscriptions_count',
    comparator: 'gte',
    value: []
  });

  const [upperSaleFilter, setUpperSaleFilter] = useState<FilterType>({
    field: 'subscriptions_count',
    comparator: 'lte',
    value: []
  });

  // sort object
  const [sort, setSort] = useState<SortType>({
    field: 'id',
    order: 'desc'
  });

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);

  const optionsRowsPerPage = [10, 25, 50, 75, 100];

  const tableHeadCells = [
    { id: 'name', label: 'Nome' },
    { id: 'sku', label: 'Código Interno' },
    { id: 'value', label: 'Valor' },
    { id: 'subscriptions_count', label: 'Compras' },
    { id: 'created_at', label: 'Criado em' },
    { id: 'updated_at', label: 'Última att' },
    { id: 'status', label: 'Status' },
  ];

  const statusOptions = [
    { label: 'Ativos', value: 'ACTIVE' },
    { label: 'Inativos', value: 'INACTIVE' },
  ];


  const onGetProductsHandleError = (errors: ApiError) => {

    if (errors[0].status === 401 && errors[0].type === 'CustomAuthenticationException') {
      notAuthenticatedRedirect();
    }
    else if (errors[0].status === 200 && errors[0].type === 'ERR_CANCELED') {

    } else if (errors[0].type === 'ERR_NETWORK') {
      setProductsLoadingError(true)

      setProducts([]);
      setFormattedProducts([])
      setTotalProducts(0);
    } else {
      setProductsLoadingError(true)

      setProducts([]);
      setFormattedProducts([])
      setTotalProducts(0);
    }
  }


  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setProductsLoading(true);

      const response = await getProducts([statusFilter, lowerSaleFilter, upperSaleFilter], searchProducts, sort, { page, limit: rowsPerPage }, abortController.signal);

      if (response.errors) {
        onGetProductsHandleError(response.errors)
      } else {
        setProducts(response.products as Array<ProductsType>);
        setFormattedProducts(formatProductsToDisplay(response.products))

        if (response.pagination)
          setTotalProducts(response.pagination.totalProducts);

        if (response.limits)
          setSalesRangeLimits(response.limits)
      }

      setProductsLoading(false || abortController.signal.aborted);
    })();

    return () => {
      abortController.abort();
    }
  }, [lowerSaleFilter, page, rowsPerPage, searchProducts, sort, statusFilter, upperSaleFilter]);


  const applyRangeFilter = (lowerValue: number, upperValue: number) => {
    setPage(1);
    setLowerSaleFilter({ ...lowerSaleFilter, value: [lowerValue] });
    setUpperSaleFilter({ ...upperSaleFilter, value: [upperValue] });
  }

  const applyStatusFilter = (optionsSelected: Array<string>) => {
    setPage(1);
    setStatusFilter({ ...statusFilter, value: optionsSelected });
  }

  const handleTableRowActions = (index: number, action: string) => {
    switch (action) {
      case 'edit':
        setOpenEdit(true)
        setActionProductIndex(index)
        break;

      case 'deactivate':
        setActionProductIndex(index)
        setOpenDeactivate(true)
        break;

      case 'activate':
        setActionProductIndex(index)
        setOpenActivate(true)
        break;
    }
  }

  const onChangeSort = (params: { field: string, isAsc: boolean }) => {
    setPage(1);
    setSort({
      field: params.field,
      order: params.isAsc ? 'asc' : 'desc'
    });
  }

  const onCloseEditModal = () => {
    setActionProductIndex(undefined)
    setOpenEdit(false)
  }

  const onCloseActivateModal = () => {
    setActionProductIndex(undefined)
    setOpenActivate(false)
  }

  const onCloseDeactivateModal = () => {
    setActionProductIndex(undefined)
    setOpenDeactivate(false)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  }

  const onProductSearchChange = (search: string) => {
    setPage(1);
    setSearchProducts(search);
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPage(1)
    setRowsPerPage(newRowsPerPage);
  }

  return {
    actionProductIndex,
    lowerSaleFilter,
    optionsRowsPerPage,
    page,
    products,
    formattedProducts,
    openEdit,
    openActivate,
    openDeactivate,
    productsLoading,
    productsLoadingError,
    rowsPerPage,
    salesRangeLimits,
    searchProducts,
    sort,
    statusFilter,
    statusOptions,
    tableHeadCells,
    totalProducts,
    upperSaleFilter,
    applyRangeFilter,
    applyStatusFilter,
    handleTableRowActions,
    onChangeSort,
    onCloseEditModal,
    onCloseActivateModal,
    onCloseDeactivateModal,
    onPageChange,
    onProductSearchChange,
    onRowsPerPageChange,
  };
}